import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/_services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';
import { UserService } from 'src/app/_services/user.service';
import * as shape from 'd3-shape';
import { select } from 'd3';
import { ClockService } from 'src/app/_services/clock.service';



@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.pug',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit, OnDestroy {
  isDark: any;
  optionsSub: any;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    public modal: ModalService,
    public storage: StorageService,
    private clockService: ClockService,
    // private _iconService: IconService,
    private user: UserService
  ) {
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    })
  }

  dropdownOpen = {
    time: false,
    options: false,
    graphView: false,
  };

  updated = 0;
  dnsStats;
  dnsMap;
  worldDNSStats;
  adnsStats;
  ndnsStats;
  cdnsStats;
  locations;
  plan;
  planName;
  monDisabled = false;
  dnsView = 1;
  colorScheme = {
    domain: ['#0091f3', '#00e093', '#1c60bd', '#008ef3']
  };

  jobs;
  failed;

  projectName;

  subscriptions;

  clock: number;
  selectedId;
  selectedMonitor;
  monitorUpdate;
  periodCount = 0;
  monitor: any;
  stop;
  monCost;
  project;
  mapLoading = true;
  jobsLoading = true;
  errorsLoading = true;
  statsLoading = true;
  dataLoading = true;
  type;
  verbs;

  timeframes = [
    {
      interval: '6 months',
      scale: '2 week',
      label: '6m'
    },
    {
      interval: '3 months',
      scale: '1 week',
      label: '3m'
    },
    {
      interval: '1 month',
      scale: '2 day',
      label: '1m'
    },
    {
      interval: '14 days',
      scale: '1 day',
      label: '14d'
    },
    {
      interval: '7 days',
      scale: '12 hour',
      label: '7d'
    },
    {
      interval: '3 days',
      scale: '2 hour',
      label: '3d'
    },
    {
      interval: '24 hours',
      scale: '1 hour',
      label: '24hr'
    }
  ];

  jobTypes = {
    ping: {
      m0: "Uptime",
      u0: "%",
      m1: "Ping",
      m2: "ms"
    },
    hls: {
      m0: "Uptime",
      u0: "%",
      m1: "Response",
      m2: "ms"
    },
    load_time: {
      m0: "Uptime",
      u0: "%",
      m1: "Load Time",
      m2: "ms"
    },
    http_verbs: {
      m0: "Status",
      u0: "%",
      m1: "Response",
      m2: "ms"
    },
    dns_propagation: {
      m0: "Status",
      u0: "%",
      m1: "Response",
      m2: "ms"
    }
  };

  currentTimeframe = this.timeframes[3];

  updateTimeframe(timeframe) {
    this.storage.updateOptions({ timeframe: timeframe });
    this.loadMonitor(this.selectedId, this.selectedMonitor, this.currentTimeframe.interval, this.currentTimeframe.scale);
  }

  // curve = curveBasis;
  readonly curve: any = shape.curveMonotoneX;
  loading = false;


  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  timeline = true;
  showXAxisLabel: boolean = true;
  yAxisLabel: string = 'Record';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Count';


  editModal() {
    if (this.monitor.jobGroup === "http_verbs") {
      this.modal.open('verbsmonitor', { project: this.selectedId, monitor: this.monitor })
    } else if (this.monitor.jobGroup === 'selenium') {
      this.modal.open('seleniummonitor', { project: this.selectedId, monitor: this.monitor })
    } else {
      this.modal.open('standardmonitor', { type: this.monitor.type, project: this.selectedId, monitor: this.monitor })
    }
  }

  ngOnInit() {
    this.getSubscription();
    this.subscriptions = [
      this.clockService.clock.subscribe(t => {
        this.clock = t;
      }),
      this.storage.options.subscribe(options => {
        if (options.timeframe) {
          this.currentTimeframe = options.timeframe;
        } else {
          this.currentTimeframe = this.timeframes[3];
        }
      }),
      this.route.params.subscribe(params => {
        this.selectedId = params.project;
        this.selectedMonitor = params.id;
      }),
      this.projectService.projects.subscribe(projects => {
        if (!this.selectedId || !this.selectedMonitor || !projects) return;
        const project = projects.find(project => project.id == this.selectedId);
        if (project) {
          this.projectName = project.name;
        }
        if (project) {
          const m = project.monitors.find(m2 => m2.id == this.selectedMonitor);
          let monitor: any = {
            id: m.id,
            endpoint: m.endpoint,
            name: m.name,
            regions: m.regions,
            frequency: m.frequency,
            configuration: m.configuration ? m.configuration : {},
            status: m.status,
            colour: { domain: [m.colour] },
            type: m.type,
            jobGroup: m.jobGroup,
            created: m.created,
            loaded: false,
            jobs: [],
            failed: [],
            stats: {},
            data: [],
            updated: 0
          }
          this.monitor = monitor;
          this.monitorCost(this.selectedId, this.monitor.type, this.monitor.frequency);
          console.log(this.monitor)
        }
        this.loadMonitor(this.selectedId, this.selectedMonitor, this.currentTimeframe.interval, this.currentTimeframe.scale);
        clearInterval(this.monitorUpdate);
        this.monitorUpdate = setInterval(() => { this.updateMonitor(); }, 5000);
      })
    ];
  }

  updateMonitor() {
    if (this.updated < new Date().getTime()) {
      this.loadMonitor(this.selectedId, this.selectedMonitor, this.currentTimeframe.interval, this.currentTimeframe.scale);
    }
  }

  async pauseMonitor() {
    const x = await this.projectService.pauseMonitor(this.selectedId, this.selectedMonitor);
  }

  async startMonitor() {
    const x = await this.projectService.startMonitor(this.selectedId, this.selectedMonitor);
  }

  async monitorCost(projectId, type, freq) {
    var monCost = await this.projectService.monitorCost(projectId, type, freq)
    this.monCost = monCost
  }

  seekPeriod() {
    if (this.periodCount < 2) {
      this.periodCount += 1;
    }
    else {
      this.periodCount = 0;
    }
  }

  async exportCSV() {
    await this.projectService.getRawData(this.selectedId, this.selectedMonitor, this.monitor.name, this.currentTimeframe.interval);
  }

  async loadMonitor(project, monitor, timeframe = '24hr', timescale = '1 hour') {
    if (this.monitor) {
      this.loadMonitorStats(project, monitor, timeframe, timescale);
      this.loadMonitorMap(project, monitor);
      this.loadMonitorJobs(project, monitor);
      if (this.monitor.jobGroup === "dns_propagation") {
        this.loadDnsStats(project, monitor);
      }
      this.loadMonitorFailedJobs(project, monitor);
    }
  }

  async loadMonitorJobs(project, monitor) {
    let j: any = await this.projectService.getMonitorJobs(project, monitor);
    let update = new Date().getTime() + 15000;
    if (j.length) {
      j = j.map(j1 => { j1.id = new Date(j1.time).getTime().toString(16); return j1 });
      update = new Date(j[0].time).getTime() + Math.min(this.monitor.frequency, 60000);
    }
    //If there are jobs
    if (j && j.length) {
      //If there are no existing jobs, or a new job has been found, set time to job[0] + frequency
      if (!this.jobs || this.jobs && this.jobs.length && new Date(this.jobs[0].time).getTime() < new Date(j[0].time).getTime()) {
        this.jobs = j;
      }
    }
    this.updated = update;
    this.jobsLoading = false;
  }

  async loadMonitorFailedJobs(project, monitor) {
    let j: any = await this.projectService.getMonitorFailedJobs(project, monitor);
    //If there are jobs
    if (j && j.length) {
      j = j.map(j1 => { j1.id = new Date(j1.time).getTime().toString(16); return j1 });
      //If there are no existing jobs, or a new job has been found, set time to job[0] + frequency
      if (!this.failed || this.failed && this.failed.length && new Date(this.failed[0].time).getTime() < new Date(j[0].time).getTime()) {
        this.failed = j;
      }
    }
    this.failed = j;
    this.errorsLoading = false;
  }

  async loadMonitorStats(project, monitor, timeframe, timescale) {
    const m = await this.projectService.getMonitorData(project, monitor, timeframe, timescale);
    const s = await this.projectService.getMonitorStats(project, monitor, timeframe);
    this.monitor.data = this.prepareData(this.monitor, m);
    this.monitor.stats = s;
    this.statsLoading = false;
  }

  async forceTest() {
    await this.projectService.forceTest(this.selectedId, this.selectedMonitor);
    this.updated = new Date().getTime() + 5000;
  }

  async loadMonitorMap(project, monitor) {
    const s = await this.projectService.getMonitorRegion(project, monitor);
    const r = await this.projectService.getMonitorRegion(project, monitor);
    this.monitor.regionalData = r;
    this.mapLoading = false;
  }

  async loadDnsStats(project, monitor) {

    if (this.monitor.type !== "dns_propagation") {
      return;
    }

    const a = await this.projectService.getARecordStats(project, monitor);
    const n = await this.projectService.getNsStats(project, monitor);
    const c = await this.projectService.getCNameStats(project, monitor);

    let worldStats = {
      a,
      c,
      n,
    };
    this.worldDNSStats = worldStats;
  }

  dateToMillis(d) {
    return Math.floor(new Date(d).getTime() / 1000) * 1000;
  }


  async getSubscription() {
    const subscription: any = await this.user.getSubscription();
    this.plan = subscription.plan;
  }

  prepareData(l, d) {
    let data = [
      {
        name: l.name,
        regions: l.regions,
        series: d.map(x => {
          return { name: new Date(x.name), value: (x.value == null) ? -2 : x.value }
        })
      }
    ];
    return data;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
    clearInterval(this.monitorUpdate);
  }
}
