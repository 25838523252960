import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProjectService } from 'src/app/_services/project.service';
import { PlanInfoService } from 'src/app/_services/planInfo.service';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.pug',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  typeVar = "Slack";
  step = 0;
  monType;
  requestObject;
  webhookForm;
  loader;
  fakeLoader = [0,1,2,3]
  testSuccess = false;
  phoneCode;

  dropDownOpen = {
    project: false,
    monitor:false
  }

  services = [
    {
      label: "Email",
      image: "email.svg",
      value: "Email",
      type: "generic",
      message: "Get outage updates directly in your inbox.",
      valueType: "Email Address"
    },
    {
      label: "SMS",
      image: "sms.svg",
      value: "SMS",
      type: "generic",
      message: "Receive outage alerts via text messages.",
      valueType: "International Phone Number",
      enterpriseLock: true
    },
    {
      label: "Webhooks",
      image: "webhooks.svg",
      value: "GenericWebhook",
      type: "generic",
      message: "Real-time data outages for your applications.",
      valueType: "Webhook URL"

    },
    {
      label: "Discord",
      image: "discord.svg",
      value: "Discord",
      type: "application",
      message: "Instant notifications for your Discord server.",
      valueType: "Discord Webhook URL"
    },
    {
      label: "Microsoft Teams",
      image: "microsoftteams.svg",
      value: "MicrosoftTeams",
      type: "application",
      message: "Timely updates for your Microsoft Teams organization.",
      valueType: "Microsoft Teams Incoming Webhook"
    },
    {
      label: "Slack",
      image: "slack.svg",
      value: "Slack",
      type: "application",
      message: "Outage notifications within your Slack workspace.",
      valueType: "Slack Webhook"
    },

  ];

  testStatus = 0;
  selectedType = this.services[0];

  selectedService = this.services[0].label;

  submitted = false;
  notificationSettings: any;
  userProjects;
  selectedProject;
  selectedMonitor;
  globalSettings;
  webhookError = null;
  submittedCreate = false;
  submittedTest = false;
  formValid = false;
  currentUser;
  SMSEnabled = false;
  selectedPhoneCode: string = "+1";
  selectedIntegration: string;
  currentUserLoad;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private planInfo: PlanInfoService,
    ) { }

  ngOnInit() {
    this.getCurrentUser()
    this.projectService.projects.subscribe(projects => {
      this.userProjects = projects.map(project => {return project})
      this.selectedProject;
    })
    this.getNotificationSettings();
    this.webhookForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['Email', Validators.required],
      destination: ['', Validators.required]
    },

    { validator: this.formValidator});   

  }

    async getCurrentUser() {
    this.planInfo.getUserInfo().subscribe((userInfo) => {
        this.currentUserLoad = userInfo;
    });
    this.currentUser = this.currentUserLoad.email;
    this.SMSEnabled = this.currentUserLoad.plan.sms_enabled
    if(this.currentUserLoad?.role === 'admin') { 
      this.services[1].enterpriseLock = false
    } else {
      this.services[1].enterpriseLock = !this.currentUserLoad?.plan.sms_enabled
    }
  }

  setSelectedProject(project) {
    this.selectedProject = project;
  }

  setSelectedMonitor(monitor) {
    this.selectedMonitor = monitor;
  }

  formValidator = (f: UntypedFormGroup) => {
    if(f){
      const regexes = {
        Slack: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){1}(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+(?![0-9])(\w|\-)+(\:[0-9]{1,5})?(\/.+)*(\/?)$/,
        Email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        GenericWebhook:/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){1}(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+(?![0-9])(\w|\-)+(\:[0-9]{1,5})?(\/.+)*(\/?)$/,
        Discord: /https:\/\/.+\/api\/webhooks\/([^\/]+)\/([^\/]+)/,
        SMS: /^\+[1-9]{1}[0-9]{3,14}$/,
        MicrosoftTeams: /^https:\/\/(?:[a-z0-9]+\.)*webhook\.office\.com\/webhookb2\/[a-zA-Z0-9]+@[^\/]+\/IncomingWebhook\/[a-zA-Z0-9]+\/[a-zA-Z0-9-]+$/
      };

      const name = f.get('name').value;

      if(!name.length){
        f.controls.name.setErrors({'invalid': true});
      }

      const type = f.get('type').value;
      let destination = f.get("destination").value;

      if (type === "SMS" && this.selectedPhoneCode) {
        destination = this.selectedPhoneCode + destination;
      }

      // f.controls.host.setErrors({'invalidType': true});
      if (destination.length) {
        if (!regexes[type].test(destination)) {
            f.controls.destination.setErrors({'invalid': true});
        } else {
            f.controls.destination.setErrors(null);
        }
      }
    }
  }

  selectIntegration(t) {
    this.selectedType = this.services[t];
    this.selectedIntegration = this.services[t].value
    console.log(this.selectedType)
  }

  selectType(t){
    this.webhookForm.controls['type'].setValue(t);
  }

  updateDestinationWithPhoneCode() {
    const phoneCode = this.selectedPhoneCode || '';
    const destination = this.webhookForm.get('destination').value || '';
    const newDestination = `${phoneCode}${destination}`;
    this.webhookForm.patchValue({ destination: newDestination });
  }

  async deleteWebHoook(i,id) {
    this.notificationSettings.splice(i, 1)
    await this.userService.deleteNotificationWebHook(id);
    await this.userService.getNotificationSettings();
  }
  

  async getNotificationSettings(){
    const notificationSettings = await this.userService.getNotificationSettings();
    this.notificationSettings = notificationSettings;
  }


  testWebHook = async () => {
    if (this.webhookForm.invalid) {
      console.log("The form is incorrect.")
      return;
    }
    try {
      this.requestObject = {
        name: this.m.name.value,
        type: this.m.type.value,
        monitorId: this.selectedMonitor || undefined,
        projectId: this.selectedMonitor ? null : this.selectedProject || undefined,
        phone_number: this.m.type.value === 'SMS' ? this.selectedPhoneCode + this.m.destination.value : undefined,
        recipient: this.m.type.value === "Email" ? this.m.destination.value : undefined,
        endpoint: ["Slack", "GenericWebhook", "Discord", "MicrosoftTeams"].indexOf(this.m.type.value) > -1 ?this.m.destination.value : undefined,
      };
      let n = await this.userService.testNotificationWebhook(this.requestObject);
      this.submittedTest = false;
      this.testSuccess = true;
      setTimeout(() => {
        this.testSuccess = false
      }, 5000)
    } catch(e){
      this.webhookError = e;
      this.submittedTest = false;
      setTimeout(() => {
        this.webhookError = null;
      }, 7500)
  }
}

  submittedCreateTrue() {
    if(!this.m.name.errors && !this.m.destination.errors) {
    this.submittedCreate = true;
    }
  }
  submittedTestTrue() {
    if(!this.m.name.errors && !this.m.destination.errors) {
    this.submittedTest = true;
    }
  }


  onPhoneCodeSelected(selectedValue: string) {
    this.selectedPhoneCode = selectedValue;
  }

  createWebHook = async () => {
    console.log(this.selectedPhoneCode)
    if (this.webhookForm.invalid) {
      console.log("The form is incorrect.")
      return;
    }

    this.submitted = true;
    try {
      this.requestObject = {
        name: this.m.name.value,
        type: this.m.type.value,
        monitorId: this.selectedMonitor || null,
        projectId: this.selectedMonitor ? null : this.selectedProject || null,
        phone_number: this.m.type.value === 'SMS' ? this.selectedPhoneCode + this.m.destination.value : null,
        recipient: this.m.type.value === "Email" ? this.m.destination.value : undefined,
        endpoint: ["Slack", "GenericWebhook", "Discord", "MicrosoftTeams"].indexOf(this.m.type.value) > -1 ?this.m.destination.value : undefined,
      };
      let n = await this.userService.createNewNotificationWebHook(this.requestObject);
      this.getNotificationSettings()
      this.submittedCreate = false;
    } catch(e){
      console.log(e);
      this.webhookError = e;
      this.submittedCreate = false;
      setTimeout(() => {
        this.webhookError = null;
      }, 7500)

    }
    }

get m() { return this.webhookForm.controls; }

}

