import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../../_services/auth.service';
import { AddMfaResponse, User } from '../../../_models/user';
import { UserService } from '../../../_services/user.service';
import { first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { StorageService } from 'src/app/_services/storage.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'src/app/_services/modal.service';

const languages = [
  {
    id: 'en',
    flag: 'gb',
    name: 'English'
  },
  {
    id: 'ja',
    flag: 'jp',
    name: '日本語'
  },
  {
    id: 'ko',
    flag: 'kr',
    name: '한국어'
  },
  {
    id: 'zh-CN',
    flag: 'cn',
    name: '簡體中文'
  },
  {
    id: 'zh-HK',
    flag: 'hk',
    name: '繁體中文'
  }
];

@Component({
  selector: 'account',
  templateUrl: './account.component.pug',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {


  nameForm: UntypedFormGroup;
  emailForm: UntypedFormGroup;
  mfaConfirmError;
  addMfaSubmitted = false;
  passwordForm: UntypedFormGroup;
  currentUser: User;
  authSubscription: Subscription;
  nameError;
  nameMessage: string;
  nameLoading = false;
  nameSubmitted = false;
  emailError;
  emailMessage: string;
  emailLoading = false;
  emailSubmitted = false;
  passwordError: string;
  passwordMessage;
  passwordLoading = false;
  passwordSubmitted = false;
  payoutLoading = false;
  payoutSubmitted = false;
  payoutMessage: string;
  payoutError: string;
  optionsSub;
  newMfa: AddMfaResponse;

  darkToggleState: boolean;
  selectedLanguage: (typeof languages)[0];
  accountType: string;

  notificationSettings: any;
  languages = languages;
  
  constructor(public auth: AuthenticationService, 
              private userService: UserService,
              private formBuilder: UntypedFormBuilder,
              public storage: StorageService,
              private http: HttpClient,
              public modal: ModalService,
              ) { 
    this.storage.options.subscribe(x => {
      this.darkToggleState = x.darkToggleState;
      this.selectedLanguage = this.languages.find(x => x.id === this.storage.optionsValue.selectedLanguage);
      this.accountType = x.accountType;
    })
  }

  async getUserPreferences() {
    const me: any = await this.userService.me();
    this.storage.updateOptions({
      darkToggleState: me?.preferences?.darkMode,
      selectedLanguage: me?.preferences?.language,
      accountType: me?.preferences?.accountType
    });
  }

  async getNotificationSettings(){
    const notificationSettings = await this.userService.getNotificationSettings();
    this.notificationSettings = notificationSettings;
  }

  async ngOnInit() {
    await this.getUserPreferences();
    this.authSubscription = this.auth.currentUser.subscribe(x => this.currentUser = x);

    this.nameForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(24)]]
    });

    this.passwordForm = this.formBuilder.group({
      passwordOld: ['', [Validators.required, Validators.minLength(8)]],
      passwordNew: ['', [Validators.required, Validators.minLength(8)]],
      passwordConfirm: ['', [Validators.required, Validators.minLength(8)]]
    });

    // this.payoutForm = this.formBuilder.group({
    //   payout: ['', [Validators.required]]
    // });

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });

    this.nameForm.setValue({
      name: this.currentUser.name
    });

    this.emailForm.setValue({
      email: this.currentUser.email
    });

    // if(this.currentUser.handle){
    //   this.payoutForm.setValue({
    //     payout: this.currentUser.handle
    //   });
    // }
    this.getNotificationSettings();
    this.userService.notificationSettingSubject.subscribe(      
      x => {
        this.notificationSettings = x;
      }
    );
  }

  async addMfa() {
    if(!this.addMfaSubmitted) {
      this.addMfaSubmitted = true;
      this.newMfa = await this.userService.addMfa();
      this.addMfaSubmitted = false;
    }
  }

  mfaError = (error: string) => {
    this.mfaConfirmError = error;
  }

  mfaUpdate = (code: string) => {
    this.mfaConfirmError = ""
  }

  submitOtp = async (code: string) => {
    try {
      await this.userService.confirmMfa(code);
      this.auth.updateUser({...this.currentUser, mfa: true});
    } catch(error) {
      throw new Error(error['error']['message'] || "INVALID_MFA_CODE");
    }
  }

  removeMfa = async (code: string) => {
    try {
      await this.userService.removeMfa(code);
      this.auth.refresh();
    } catch(e) {
      console.log(e);
      throw e
    }
  }

  async disableMfa() {
    this.modal.open("removemfa", { title: "settings.removeMfa", description: "settings.removeMfaDescription"})
  }

  async deleteWebHoook(i,id) {
    this.notificationSettings.splice(i, 1)

    await this.userService.deleteNotificationWebHook(id);
    await this.userService.getNotificationSettings();
  }
  
  async changePassword(){
    this.passwordSubmitted = true;
    if(this.passwordForm.invalid){
      console.log(this.p);
      return;
    }
    try {
      const data = await this.userService.changePassword(this.p.passwordOld.value, this.p.passwordNew.value, this.p.passwordConfirm.value);
      this.auth.updateUser(data);
      this.passwordMessage = 'PASSWORD_UPDATED';
    } catch (e) {
      this.passwordError = e;
    }
    this.clearMessages('password');
  }

  // async changePayout(){
  //   this.payoutSubmitted = true;
  //   if(this.payoutForm.invalid){
  //     console.log(this.w);
  //     return;
  //   }
  //   try {
  //     const data = await this.userService.changePayout(this.w.payout.value);
  //     this.auth.updateUser(data);
  //     this.payoutMessage = 'PAYOUT_UPDATED';
  //   } catch (e) {
  //     this.payoutError = e;
  //   }
  //   this.clearMessages('payout');
  // }

  async changeName(){
    this.nameSubmitted = true;
    if(this.nameForm.invalid){
      console.log(this.n);
      return;
    }
    try {
      const data = await this.userService.changeName(this.n.name.value);
      this.auth.updateUser(data);
      this.nameMessage = 'NAME_UPDATED';
    } catch (e) {
      this.nameError = e;
    }
    this.clearMessages('name');
  }

  async changeEmail(){
    this.emailSubmitted = true;
    if(this.emailForm.invalid){
      console.log(this.e);
      return;
    }
    try {
      const data = await this.userService.changeEmail(this.e.email.value);
      this.auth.updateUser(data);
      this.emailMessage = 'EMAIL_UPDATED';
    } catch (e) {
      this.emailError = e;
    }
    this.clearMessages('email');
  }

  async changeTheme() {
    const t = await this.http.post(`${environment.apiUrl}/v2/user/preferences`, { darkMode: this.darkToggleState, language: this.selectedLanguage.id, accountType: this.accountType }).toPromise();
    this.storage.updateOptions({ darkToggleState: this.darkToggleState });
    return t;
  }

  async changeAccountType() {
    const t = await this.http.post(`${environment.apiUrl}/v2/user/preferences`, { darkMode: this.darkToggleState, language: this.selectedLanguage.id, accountType: this.accountType }).toPromise();
    this.storage.updateOptions({ accountType: this.accountType });
    return t;
  }

  async changeLanguage() {
    const p = await this.http.post(`${environment.apiUrl}/v2/user/preferences`, { darkMode: this.darkToggleState, language: this.selectedLanguage.id, accountType: this.accountType }).toPromise();
    this.storage.updateOptions({ selectedLanguage: this.selectedLanguage.id });
    return p;
  }

  async deleteAccount() {
    this.modal.open("deleteaccount", { cb: this.logout })
  }

  logout = () => {
    this.auth.logout()
  }

  clearMessages(m){
    setTimeout(() => {
      this[m+'Message'] = '';
      this[m+'Error'] = '';
      this[m+'Loading'] = false;
    }, 5000);
  }


  ngOnDestroy() {
    if(this.authSubscription) {
    this.authSubscription.unsubscribe();
    }
  }

  checkPasswords(group: UntypedFormGroup) { // here we have the 'passwords' group
  let pass = group.get('password').value;
  let confirmPass = group.get('confirm').value;

  return pass === confirmPass ? null : { notSame: true }
  }


    get n() { return this.nameForm.controls; }
    get e() { return this.emailForm.controls; }
    get p() { return this.passwordForm.controls; }

}
