import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/auth.service';
import { StorageService } from '../_services/storage.service';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private storage: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        const r = route.queryParams.r || null;
        if(r){
          this.storage.updateOptions({r:r});
        }

        if (!currentUser) {
            // logged in so return true
            return true;
        }
        console.log(route.queryParams);
        if(route.queryParams && route.queryParams.referrer && route.queryParams.referrer==='desktop'){
          console.log('node login');
          window.open('bitping://' + currentUser.token, '_blank');
          this.router.navigate(['/node/loggedin']);
          return false;
        } else {
          // Logged in so redirect to dashboard page
          this.router.navigate(['/']);
          return false;
        }
    }
}
