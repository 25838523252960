import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanInfoService {
  private userInfo: any;

  constructor(private http: HttpClient) {}

  getUserInfo(): Observable<any> {
    if (this.userInfo) {
      return of(this.userInfo);
    } else {
      return this.http.get<any[]>(`${environment.apiUrl}/v2/user/me`).pipe(
        tap((userInfo) => {
          this.userInfo = userInfo;
        })
      );
    }
  }
}