import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/auth.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.pug',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  message = '';
  error = '';
  mfa = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }

  get f() { return this.forgotForm.controls; }

  async forgot() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
        return;
    }
    this.message = '';
    this.error = '';

    this.loading = true;
    try {
      const data:any = await this.authenticationService.forgot(this.f.email.value);
      this.message = data.message;
      // this.message = `Password reset email has been sent to ${this.f.email.value}`;
    } catch(error) {
      if(error && error.error && error.error.message) {
        if (error.error.message === "NO_MFA_CODE_PROVIDED") {
          this.mfa = true;
        } else {
          this.error = error.error.message
        }
      } else {
        this.error = "SOMETHING_WENT_WRONG";
      }
    }
    this.loading = false;
  }

  mfaError = (error: string) => {
    this.error = error;
    this.loading = false;
  }

  forgotMfa = async (code: string) => {
    this.message = '';
    this.error = '';
    this.loading = true;
    try {
      const data:any = await this.authenticationService.forgot(this.f.email.value, code);
      this.message = data.message;
    } catch(error) {
      this.error = error["error"]["message"] || "SOMETHING_WENT_WRONG";
      throw new Error(this.error)
    }
    this.loading = false;
  }
}