import { Component, OnInit, OnDestroy, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { AdminService } from 'src/app/_services/admin.service';
import { StorageService } from '../../_services/storage.service';
import { NodesService } from 'src/app/_services/nodes.service';
import { ConnectedNodesComponent } from './connected-nodes/connected-nodes.component';
import { ApprovalsComponent } from './approvals/approvals.component';
// import { AllNodesComponent } from './all-nodes/all-nodes.component';
// import { ApprovalsComponent } from './approvals/approvals.component';
// import { AddUserComponent } from './add-user/add-user.component';
import { AccountsComponent } from './accounts/accounts.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
// import { DiagnosticsComponent } from './diagnostics/diagnostics.component';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.pug',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  adminPane = "connectedNodes";
  optionsSub;
  pageOpen = false;


  nodeInterval;
  nodes;
  nodeLoading = true;

  page: any;
  @ViewChild('vc2', { read: ViewContainerRef, static: true}) myRef;

  pages = {
   connectedNodes: ConnectedNodesComponent,
   paymentApprovals: ApprovalsComponent,
   accounts: AccountsComponent,
   diagnostics: DiagnosticsComponent
 };

  constructor(
    public admin:AdminService,
    public nodesservice:NodesService,
    public storage: StorageService,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    // this.getNodes();
    // this.nodeInterval = setInterval(() => {
    //   this.getNodes();
    // }, 5000);

    this.optionsSub = this.storage.options.subscribe(options => {
      if(options.adminPane){
        this.adminPane = options.adminPane;
        this.myRef.clear();
        const ref = this.myRef.createComponent(this.pages[this.adminPane]);
      } else {
        this.updateAdminPaneView("connectedNodes");
      }
    });
  }

  updateAdminPaneView(s){
    this.pageOpen = true;
    this.storage.updateOptions({adminPane: s});
  }

  closePage(){
    this.pageOpen = false;
  }

  ngOnDestroy() {
    clearInterval(this.nodeInterval);
    this.myRef.clear();
  }

  async getNodes() {
    this.nodes = await this.nodesservice.getNodes();
    this.nodeLoading = false;
  }

}
