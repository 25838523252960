import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/_services/admin.service';
import { fromEvent } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {  CreditType, PlanIds,  PlanTypes } from '@uptimesv/bitping-common';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { debounceTime, distinctUntilChanged, tap, filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.pug',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  dropdownOpen = {
    role: false,
    plan: false,
    credit: false,
  }
  registerForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  message = '';
  error = '';
  tab = 0;
  accounts;
  selectedAccount;
  selectedRole;
  selectedPlan;
  selectedCreditType;
  creditAmount: string;

  plans = [
    {
      value: PlanIds.PAYG,
      label: PlanTypes.PAYG
    },
    {
      value: PlanIds.BASIC,
      label: PlanTypes.BASIC
    },
    {
      value: PlanIds.PREMIUM,
      label: PlanTypes.PREMIUM
    },
    {
      value: PlanIds.BUSINESS,
      label: PlanTypes.BUSINESS
    },
    {
      value: PlanIds.MANSION,
      label: PlanTypes.MANSION
    },
    {
      value: 7,
      label: "INFOTRACK_ENTERPRISE_PLAN"
    }
  ];

  creditTypes = [
    {
      value: CreditType.Complementary,
      label: "Complementary"
    },
    {
      value: CreditType.Standard,
      label: "Standard"
    },
    {
      value: CreditType.Trial,
      label: "Trial"
    }
  ];

  roles = [{
    value: 'user',
    label: 'User'
  },
  {
    value: 'trial',
    label: 'Trial'
  },
  {
    value: 'admin',
    label: 'Admin'
  }];

  args = {
    p: 1,
    l: 100,
    q: '',
    o: 'user.email',
    d: 1
  };

  constructor(public admin:AdminService, private authenticationService: AuthenticationService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    this.getAccounts();
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      type: ['user', []],
      balance: [0, [Validators.min(0), Validators.max(500)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  selectRole(r){
    this.selectedRole = r;
  }

  selectPlan(r){
    this.selectedPlan = r;
    console.log(this.selectedPlan)
  }

  selectCreditType(r) {
    this.selectedCreditType = r;
  }

  async updateRole(){
    try {
      if(!this.selectedAccount.id || !this.selectedRole.value) return;
      await this.admin.updateUser(this.selectedAccount.id, { role: this.selectedRole.value });
      this.selectedAccount.role = this.selectedRole.value;
      this.selectedRole = null;
    } catch(e) {
      console.log(e);
    }
  }

  async updatePlan(){
    try {
      if(!this.selectedAccount.id || !this.selectedPlan) return;
      await this.admin.changePlan(this.selectedAccount.id, this.selectedPlan.label);
      // this.selectedAccount.plan = this.selectedPlan.value;
      this.selectedPlan = null;
    } catch(e) {
      console.log(e);
    }
  }

  async deleteUser(){
    try {
      if(!this.selectedAccount.id) return;
      await this.admin.deleteUser(this.selectedAccount.id);
      this.selectAccount = null;
    } catch(e) {
      console.log(e);
    }
  }

  async addCredit(){
    try {
      if(!this.selectedAccount.id || !this.selectedCreditType) return;
      await this.admin.addCredit(this.selectedAccount.id, parseFloat(this.creditAmount), this.selectedCreditType.value);
      // this.selectedAccount.plan = this.selectedCreditType;
      this.selectedCreditType = null;
      this.creditAmount = '';
    } catch(e) {
      console.log(e);
    }
  }


  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    
  }

  ngAfterViewInit() {
    this.watchSearch();
  }

  watchSearch(){
    fromEvent(this.searchInput.nativeElement, 'keyup')
    .pipe(
        filter(Boolean),
        debounceTime(80),
        distinctUntilChanged(),
        tap((text) => {
          console.log(this.searchInput.nativeElement.value);
          this.args.q = this.searchInput.nativeElement.value;
          this.getAccounts();
        })
    )
    .subscribe();
  }

  async getAccounts() {
    this.accounts = await this.admin.getAccounts(this.args);
  }

  deselectAccount(){
    this.selectedAccount = null;
    setTimeout(() => {
      this.watchSearch();
    },200);
  }

  selectAccount(a) {
    this.selectedAccount = a;
  }

  register() {
    // console.log('adding user');
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.addUser(this.f.name.value, this.f.email.value, this.f.type.value, this.f.balance.value, this.f.password.value)
    .pipe(first())
    .subscribe(
      data => {
        this.onReset();
        this.message = "Success!";
        setTimeout(() => {
          this.message = '';
        }, 3000);
      },
      error => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  get f() { return this.registerForm.controls; }

}
