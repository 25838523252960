import { Component, OnInit, OnDestroy } from '@angular/core';
import { NodesService } from '../../_services/nodes.service';
import { ModalService } from '../../_services/modal.service';
import { ProjectService } from '../../_services/project.service';
import { AuthenticationService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { ClockService } from '../../_services/clock.service';
import { SSEObserverService } from '../../_services/sseobserver.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.pug',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

  subscriptions;
  nodeStats;
  now;
  notifications;
  currentUser;
  nodeInterval;
  notiLoader = [1,2,3,4,5,6,7,8,9,10]
  projectsLength: any;
  optionsSub: any;
  isDark: any;
  monitorId;
  
  constructor(public nodesservice:NodesService, private storage: StorageService, public modal: ModalService, private projects: ProjectService, public auth: AuthenticationService, private user: UserService, private clock: ClockService, private sseObserver: SSEObserverService) {
    this.subscriptions = [
      this.auth.currentUser.subscribe(x => this.currentUser = x),
      this.projects.notifications.subscribe(noti => {
        this.notifications = noti.notifications;
      }),
      this.projects.overview.subscribe(overview => {
        this.nodeStats = overview;
      }),
      this.clock.clock.subscribe(n => {
        this.now = n;
      })
    ];
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
    
  }

  async markSingleResolved(i) {
    this.notifications[i].isAcknowledged = true;
    await this.projects.markSingleAsRead(this.notifications[i].id);
  }

  async markSingleUnresolved(i) {
    this.notifications[i].isAcknowledged = false;
    await this.projects.markSingleAsUnread(this.notifications[i].id);
  }

  ngOnInit(){
    this.subscriptions = [
      this.projects.projects.subscribe(projects => {
        this.projectsLength = projects.length;
      })
    ];
  }

  ngOnDestroy() {
    clearInterval(this.nodeInterval);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
