import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from 'src/app/_services/modal.service';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-map-data',
  templateUrl: './map-data.component.pug',
  styleUrls: ['./map-data.component.scss']
})
export class MapDataComponent implements OnInit, OnDestroy {

  constructor(public modal: ModalService, private project: ProjectService) { }

  getData;
  data;
  mapdata;
  breakdown;


  async getBreakdown(region) {
    const b = await this.project.getMonitorNodeBreakdown(this.mapdata.data.countryCode)
    this.breakdown = b;
  }

  ngOnInit() {
    this.getData = this.modal.getData().subscribe(data => {
       this.mapdata = data;
    });
    console.log(this.mapdata)
    this.getBreakdown(this.mapdata.data.countryCode);
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }
  
}
