import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NoAuthGuard } from './_guards/noauth.guard';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectComponent } from './pages/project/project.component';
import { MonitorComponent } from './pages/monitor/monitor.component';
import { EarningsComponent } from './pages/earnings/earnings.component';
import { NodeComponent } from './pages/node/node.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { BillingComponent } from './pages/billing/billing.component';
import { AdminComponent } from './pages/admin/admin.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { MonitorNotificationsComponent } from './pages/monitor/monitor-notifications/monitor-notifications.component';
import { ResetComponent } from './pages/reset/reset.component';
import { LoggedInNodeComponent } from './pages/logged-in-node/logged-in-node.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { TeamsComponent } from './pages/teams/teams.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'dashboard' }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'projects' }
  },
  {
    path: 'project/:id',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'project' }
  },
  {
    path: 'project/:project/monitor/:id',
    component: MonitorComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'monitor' }
  },
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'reports' }
  },
  {
    path: 'integrations',
    component: IntegrationsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'integrations' }
  },
  {
    path: 'earnings',
    component: EarningsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'earnings' }
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'billing' }
  },
  {
    path: 'teams',
    component: TeamsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'teams' }
  },
  {
    path: 'incidents',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'incidents' }
  },
  {
    path: 'project/:project/monitor/:id/notifications',
    component: MonitorNotificationsComponent,
    canActivate: [AuthGuard],
    data: { animationState: 'monitor' }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: {
      animationState: 'settings'
    }
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        'admin'
      ]
    }
  },
  {
    path: 'confirm/:id',
    component: ResetComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'node',
    component: NodeComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        'admin',
        'user',
        'node',
        'trial'
      ]
    }
  },
  {
    path: 'node/loggedin',
    component: LoggedInNodeComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        'admin',
        'user',
        'node'
      ]
    }
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NoAuthGuard]
  },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
