import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import '@stripe/stripe-js';
import { NgxStripeModule } from 'ngx-stripe';
import { ClipboardModule } from 'ngx-clipboard';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OTPComponent } from './common/otp/otp.component';
import { SidebarComponent } from './common/sidebar/sidebar.component';
import { NavbarComponent } from './common/navbar/navbar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CounterPipe } from './_pipes/counter.pipe';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { LoginComponent } from './pages/login/login.component';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { SafeLinkPipe } from './_pipes/safelink.pipe';
import { SearchFilterPipe } from './_pipes/searchfilter.pipe';
import { ClockService } from './_services/clock.service';
import { FilterService } from './_services/filter.service'
import { NgPipesModule } from 'ngx-pipes';
import { LanguageSelectorComponent } from './common/language-selector/language-selector.component';
import { FlagComponent } from './common/flag/flag.component';
import { NotificationsService } from './_services/notifications.service';
import { i18nTimeAgo } from './_pipes/i18ntimeago.pipe';
import { MinuteSecondsPipe } from './_pipes/minute-seconds.pipe';
import { GlobeComponent } from './common/globe/globe.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { ProjectComponent } from './pages/project/project.component';
import { SparklineCustomChartComponent } from './common/charts/sparkline/sparkline-custom-chart.component';
import { TimeChartCustomChartComponent } from './common/charts/timechart/timechart-custom-chart.component';
import { MonitorsCardViewComponent } from './pages/project/monitors-card-view/monitors-card-view.component';
import { MonitorsFullViewComponent } from './pages/project/monitors-full-view/monitors-full-view.component';
import { MonitorsListViewComponent } from './pages/project/monitors-list-view/monitors-list-view.component';
import { MonitorComponent } from './pages/monitor/monitor.component';
import { MonitorJobHistoryComponent } from './pages/monitor/monitor-job-history/monitor-job-history.component';
import { MonitorJobHistorySeleniumComponent } from './pages/monitor/monitor-job-history-selenium/monitor-job-history-selenium.component';
import { MonitorDnsChartComponent } from './pages/monitor/monitor-dns-chart/monitor-dns-chart.component';
import { MonitorJobHistoryHlsComponent } from './pages/monitor/monitor-job-history-hls/monitor-job-history-hls.component';
import { EarningsComponent } from './pages/earnings/earnings.component';
import { NodeComponent } from './pages/node/node.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalService } from './_services/modal.service';
import { AddProjectComponent } from './common/modal/add-project/add-project.component';
import { DeleteProjectComponent } from './common/modal/delete-project/delete-project.component';
import { AddMonitorComponent } from './common/modal/add-monitor/add-monitor.component';
import { SeleniumMonitorComponent } from './common/modal/selenium-monitor/selenium-monitor.component';
import { DeleteAccountComponent } from './common/modal/delete-account/delete-account.component';
import { DeleteMonitorComponent } from './common/modal/delete-monitor/delete-monitor.component';
import { RemoveMfaComponent } from './common/modal/remove-mfa/remove-mfa.component';
import { VerbsMonitorComponent } from './common/modal/verbs-monitor/verbs-monitor.component';
import { AddWebhookComponent } from './common/modal/add-webhook/add-webhook.component';
import { PlansComponent } from './common/modal/plans/plans.component';
import { StandardMonitorComponent } from './common/modal/standard-monitor/standard-monitor.component';
import { ColourPickerComponent } from './common/colour-picker/colour-picker.component';
import { environment } from '../environments/environment';

import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/xml/xml';
import { RegionSelectorComponent } from './common/region-selector/region-selector.component';
import { BrowserSelectorComponent } from './common/browser-selector/browser-selector.component';
import { ModalComponent } from './common/modal/modal.component';
import { AccountComponent } from './pages/settings/account/account.component';
import { NotificationSettingsComponent } from './pages/settings/notification-settings/notification-settings.component';
import { BillingComponent } from './pages/billing/billing.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ConnectedNodesComponent } from './pages/admin/connected-nodes/connected-nodes.component';
import { ApprovalsComponent } from './pages/admin/approvals/approvals.component';
import { AccountsComponent } from './pages/admin/accounts/accounts.component';
import { DiagnosticsComponent } from './pages/admin/diagnostics/diagnostics.component';
import { PreferenceWizardComponent } from './common/modal/preference-wizard/preference-wizard.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotComponent } from './pages/forgot/forgot.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { MonitorJobOverviewComponent } from './pages/monitor/monitor-job-overview/monitor-job-overview.component';
import { MonitorNotificationsComponent } from './pages/monitor/monitor-notifications/monitor-notifications.component';
import { EditMonitorNameComponent } from './common/modal/edit-monitor-name/edit-monitor-name.component';
import { OutsideClickDirective } from './_directives/outside-click-directive.directive';
import { TestDataModalComponent } from './common/modal/test-data-modal/test-data-modal.component';
import { WorldMapComponent } from './common/world-map/world-map.component';
import { MapDataComponent } from './common/modal/map-data/map-data.component';
import { SwiperModule } from 'swiper/angular';
import { ResetComponent } from './pages/reset/reset.component';
import { LoggedInNodeComponent } from './pages/logged-in-node/logged-in-node.component';
import { ProxySelectorComponent } from './common/proxy-selector/proxy-selector.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { IntegrationsComponent } from './pages/integrations/integrations.component';
import { PhoneSelectorComponent } from './common/phone-selector/phone-selector.component';
import { TeamsComponent } from './pages/teams/teams.component';
import { AddTeamMemberComponent } from './common/modal/add-team-member/add-team-member.component';
import { DeleteTeamMemberComponent } from './common/modal/delete-team-member/delete-team-member.component';
import { TurnstileComponent } from './common/turnstile/turnstile.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        OTPComponent,
        SidebarComponent,
        ModalComponent,
        NavbarComponent,
        DashboardComponent,
        CounterPipe,
        i18nTimeAgo,
        LoginComponent,
        SearchFilterPipe,
        SafeLinkPipe,
        LanguageSelectorComponent,
        FlagComponent,
        GlobeComponent,
        ProjectsComponent,
        ProjectComponent,
        MonitorsCardViewComponent,
        MonitorsFullViewComponent,
        MonitorsListViewComponent,
        SparklineCustomChartComponent,
        TimeChartCustomChartComponent,
        MonitorComponent,
        MonitorJobHistoryComponent,
        MonitorJobHistorySeleniumComponent,
        MonitorDnsChartComponent,
        MonitorJobHistoryHlsComponent,
        EarningsComponent,
        NodeComponent,
        SettingsComponent,
        ColourPickerComponent,
        RegionSelectorComponent,
        BrowserSelectorComponent,
        AddProjectComponent,
        DeleteProjectComponent,
        AddMonitorComponent,
        AddTeamMemberComponent,
        DeleteTeamMemberComponent,
        SeleniumMonitorComponent,
        DeleteAccountComponent,
        DeleteMonitorComponent,
        RemoveMfaComponent,
        VerbsMonitorComponent,
        AddTeamMemberComponent,
        AddWebhookComponent,
        PlansComponent,
        StandardMonitorComponent,
        MinuteSecondsPipe,
        AccountComponent,
        NotificationSettingsComponent,
        BillingComponent,
        TurnstileComponent,
        AdminComponent,
        ConnectedNodesComponent,
        ApprovalsComponent,
        AccountsComponent,
        DiagnosticsComponent,
        PreferenceWizardComponent,
        RegisterComponent,
        ForgotComponent,
        TeamsComponent,
        NotificationsComponent,
        MonitorJobOverviewComponent,
        MonitorNotificationsComponent,
        EditMonitorNameComponent,
        OutsideClickDirective,
        TestDataModalComponent,
        WorldMapComponent,
        MapDataComponent,
        ResetComponent,
        LoggedInNodeComponent,
        ProxySelectorComponent,
        ReportsComponent,
        IntegrationsComponent,
        PhoneSelectorComponent,
        TurnstileComponent
    ],
    imports: [
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        VirtualScrollerModule,
        PinchZoomModule,
        FormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode',
        }),
        NgSelectModule,
        CommonModule,
        BrowserModule,
        CodemirrorModule,
        NgPipesModule,
        NgxChartsModule,
        ReactiveFormsModule.withConfig({
            callSetDisabledState: 'whenDisabledForLegacyCode',
        }),
        AppRoutingModule,
        // NgxTurnstileModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ClipboardModule,
        SwiperModule,
        NgxStripeModule.forRoot(environment.stripePublishKey),
        NgScrollbarModule.withConfig({
            autoWidthDisabled: false,
        }),
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                // 'ss': 60,
                's': 60,
                'm': 60,
                'h': 24
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    exports: [
        TranslateModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: APP_BASE_HREF, useValue: "/" },
        ClockService,
        NotificationsService,
        ModalService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
