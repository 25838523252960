import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})

export class MinuteSecondsPipe implements PipeTransform {

    transform(value: number): string {
        let hours = Math.floor(value / 60);
        let minutes = Math.floor(value % 60);
        if(hours > 1) {
        return hours + ' hrs ' + minutes + ' mins';
        } else {
        return minutes + ' mins ';
        }
      }

}
