import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { User } from '../../_models/user';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { StorageService } from 'src/app/_services/storage.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/_services/user.service';
import { SidebarService } from '../../_services/sidebar.service';
import { trigger, transition, style, query, animateChild, group, animate, state } from '@angular/animations';
import { PlanInfoService } from 'src/app/_services/planInfo.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.pug',
  styleUrls: ['./sidebar.component.scss'],
})

export class SidebarComponent implements OnInit, OnDestroy {


  event$;
  route;
  currentUser: any;
  darkToggleState = false;
  firstTimeModal;
  profilePic;
  preferences: any;
  sidebarIsOpen: boolean;
  isDark: boolean;
  menuState: string = 'out';
  rootUrl;
  planLimits: any;
  plan: any;
  planName: any;
  userRole: any;

  constructor(private router: Router, public sidebar: SidebarService, public auth: AuthenticationService, public storage: StorageService, private http: HttpClient, private user: UserService, private planInfo: PlanInfoService) {

    this.auth.currentUser.subscribe(x => this.currentUser = x);
    this.sidebar.isOpen.subscribe(x => this.sidebarIsOpen = x);
    this.event$ = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.route = e.url.split("/")[1];
      }
    });
    this.storage.options.subscribe(x => {
      this.preferences = {
        darkToggleState: x.darkToggleState,
        selectedLanguage: x.selectedLanguage,
        accountType: x.accountType
      },
        this.isDark = x.darkToggleState;
    })


    if (this.preferences.accountType === 'node') {
      this.rootUrl = '/earnings';
    } else {
      this.rootUrl = '/';
    }

  }

  getProfilePic() {
    this.profilePic = this.currentUser.emailhash;
  }

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }

  logOut() {
    this.storage.updateOptions({
      darkToggleState: false,
    });
    this.auth.logout();
  }

  async getSubscription() {
    const subscription: any = await this.user.getSubscription();
    const subscriptionLimits: any = await this.user.getSubscriptionLimits();
    this.planLimits = subscriptionLimits;
    this.plan = subscription.plan;
    this.planName = subscription.plan.name;
    this.userRole = this.currentUser.role;
  }

  async getUserPreferences() {
    const me: any = await this.user.me();

    this.storage.updateOptions({
      darkToggleState: me?.preferences?.darkMode,
      selectedLanguage: me?.preferences?.language,
      accountType: me?.preferences?.accountType
    });
  }


  toggleDarkMode() {
    this.darkToggleState = !this.darkToggleState;
  }

  async ngOnInit() {
    this.getProfilePic();
    await this.getUserPreferences();
    await this.getSubscription();
  }

  ngOnDestroy() {
    this.event$.unsubscribe();
  }
}
