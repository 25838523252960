import { Component, OnInit, Input } from '@angular/core';
import * as shape from 'd3-shape';
import { ModalService } from '../../../_services/modal.service';
import { select } from 'd3';
import { StorageService } from 'src/app/_services/storage.service';
import { ClockService } from 'src/app/_services/clock.service';

@Component({
  selector: 'monitors-list-view',
  templateUrl: './monitors-list-view.component.pug',
  styleUrls: ['./monitors-list-view.component.scss']
})
export class MonitorsListViewComponent implements OnInit {

  @Input() timeframe: any;
  @Input() monDisabled: any;
  @Input() monitors: any;
  @Input() project: any;

  // curve = curveBasis;
  loading = false;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  xAxisLabel = 'Date';
  showYAxisLabel = false;
  yAxisLabel = 'Color Value';
  timeline = true;

  colorScheme = {
    domain: ['#0984E3'] //, '#A10A28', '#C7B42C', '#AAAAAA']
  };
  optionsSub: any;
  isDark: any;

  constructor(
    //public modal: ModalService,
    private clockService: ClockService,
    private storage: StorageService
  ) {
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
   }

  ngOnInit() {
  }


}
