import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EarningsService {

  constructor(private http: HttpClient) { }


  currentEarnings(){
    return this.http.get(`${environment.apiUrl}/payout/earnings`).toPromise()
  }

  getEarningsTotal(){
    return this.http.get(`${environment.apiUrl}/payout/total`).toPromise()
  }

  getJobCount(){
    return this.http.get(`${environment.apiUrl}/payout/jobcount`).toPromise()
  }

  getPayouts(){
    return this.http.get(`${environment.apiUrl}/v2/payouts/me`).toPromise()
  }

  requestPayout(currency_ticker: string, address: string): Promise<any> {
    return this.http.post(`${environment.apiUrl}/v2/payouts/me`, { currency_ticker, address }).toPromise();
  }

  getAllPayouts(paid = 0){
    return this.http.get(`${environment.apiUrl}/payout?paid=${paid}`).toPromise()
  }

  approvePayout(payout, txid){
    return this.http.put(`${environment.apiUrl}/payout/${payout}`, {txid}).toPromise()
  }

}
