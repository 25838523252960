import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { UserService } from '../../../_services/user.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-addwebhook',
  templateUrl: './add-webhook.component.pug',
  styleUrls: ['./add-webhook.component.scss']
})
export class AddWebhookComponent implements OnInit {
  typeVar = "Slack";
  step = 0;
  monType;
  requestObject;
  webhookForm;
  loader;

  services = [
    {
      label: "Slack",
      image: "slack.svg",
      value: "Slack"
    },
    {
      label: "Email",
      image: "email.svg",
      value: "Email"
    },
    {
      label: "Webhooks",
      image: "webhooks.svg",
      value: "GenericWebhook"
    }
  ];

  testStatus = 0;

  selectedService = this.services[0].label;

  submitted = false;
  formValid;

  constructor(
    public modal: ModalService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder
    ) { }

  ngOnInit() {


    this.webhookForm = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['Slack', Validators.required],
      destination: ['', Validators.required]
    },

    { validator: this.formValidator});
  }

  formValidator(f: UntypedFormGroup) {
    if(f){
      const regexes = {
        Slack: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){1}(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+(?![0-9])(\w|\-)+(\:[0-9]{1,5})?(\/.+)*(\/?)$/,
        Email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        GenericWebhook:/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/){1}(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+(?![0-9])(\w|\-)+(\:[0-9]{1,5})?(\/.+)*(\/?)$/
      };

      const name = f.get('name').value;

      if(!name.length){
        f.controls.name.setErrors({'invalid': true});
      }


      const type = f.get('type').value;
      const destination = f.get("destination").value;

      // f.controls.host.setErrors({'invalidType': true});
      if (destination.length) {
        if (!regexes[type].test(destination)) {
          f.controls.destination.setErrors({'invalid': true})
        }
     }
     this.formValid = true;
    }
  }

  selectType(t){
    this.webhookForm.controls['type'].setValue(t);
  }

  testWebHook = async () => {
    if(this.testStatus>0){
      return;
    }
    this.testStatus = 1;
    try {
      this.requestObject = {
        name: this.m.name.value,
        type: this.m.type.value,
        recipient: this.m.type.value === "Email" ?this.m.destination.value : undefined,
        endpoint: ["Slack", "GenericWebhook"].indexOf(this.m.type.value) > -1 ?this.m.destination.value : undefined,
      };
      let n = await this.userService.testNotificationWebhook(this.requestObject);
      this.testStatus = 2;
    } catch(e){
      this.testStatus = 3;
      console.log(e);
    }
    setTimeout(() => {
      this.testStatus = 0;
    }, 5000);
  }

  createWebHook = async () => {
    if(this.formValid) {
    this.submitted = true;
    try {
      this.requestObject = {
        name: this.m.name.value,
        type: this.m.type.value,
        recipient: this.m.type.value === "Email" ?this.m.destination.value : undefined,
        endpoint: ["Slack", "GenericWebhook"].indexOf(this.m.type.value) > -1 ?this.m.destination.value : undefined,
      };
      let n = await this.userService.createNewNotificationWebHook(this.requestObject);
      await this.userService.getNotificationSettings();
      this.modal.close();
    } catch(e){
      console.log(e);
    }
    }
  }

get m() { return this.webhookForm.controls; }

}
