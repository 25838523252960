import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NodesService } from 'src/app/_services/nodes.service';
import { fromEvent } from 'rxjs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'connected-nodes',
  templateUrl: './connected-nodes.component.pug',
  styleUrls: ['./connected-nodes.component.scss']
})
export class ConnectedNodesComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  nodeInterval;
  nodes;
  allNodes;
  nodeAllInterval;
  nodeData;
  sumNodes;
  allNodeLoading = true;
  nodeLoading = true;
  nodeOS;
  mid = false;
  nodeVersions;
  view: any[] = [200, 200];
  args = {
    p: 1,
    l: 100,
    q: '',
    o: 'user.email',
    d: 1
  };


  colorScheme = {
    domain: ['#93C5FD', '#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8', '#1E40AF', '#1E3A8A']
  };

  // pie
  showLabels = true;
  explodeSlices = false;
  doughnut = false;

  onSelect(event) {
    console.log(event);
  }

  constructor(public nodesservice:NodesService) {
  }

  ngOnInit() {
    this.getNodes();
    this.getAllNodes();
    this.nodeInterval = setInterval(() => {this.getNodes();}, 5000);
    this.nodeAllInterval = setInterval(() => {this.getAllNodes();}, 5000);
  }
  ngOnDestroy() {
    clearInterval(this.nodeInterval);
    clearInterval(this.nodeAllInterval);
  }

  updateOrder(x){
    if(this.args.o === x){
      this.args.d = this.args.d===1 ? -1 : 1;
    } else {
      this.args.o = x;
    }
    clearInterval(this.nodeInterval);
    this.getNodes();
    this.nodeInterval = setInterval(() => {this.getNodes();}, 5000);
  }

  ngAfterViewInit() {
    fromEvent(this.searchInput.nativeElement, 'keyup')
    .pipe(
        filter(Boolean),
        debounceTime(80),
        distinctUntilChanged(),
        tap((text) => {
          console.log(this.searchInput.nativeElement.value);
          this.args.q = this.searchInput.nativeElement.value;
          clearInterval(this.nodeInterval);
          this.getAllNodes();
          this.nodeInterval = setInterval(() => {this.getNodes();}, 5000);
        })
    )
    .subscribe();
  }

  async getNodes() {
    let n:any = await this.nodesservice.getNodeMap();
    let map:any = {};
    n.results.map(n1 =>
      map[n1.countrycode] = {
        class: 'blue',
        count: n1.count
      }
    );
    this.nodeData = map;
    this.nodes = n;
    let v: any = await this.nodesservice.getNodeVersions();
    this.nodeOS = v.operatingSystems.map(os => {
      return { name: os.os, value: os.count };
    });
    this.nodeVersions = v.versions.map(ver => {
      return { name: ver.version, value: ver.count };
    });
    console.log(this.nodeVersions)
    this.nodeLoading = false;
  }

  async getAllNodes() {
    let n: any = await this.nodesservice.getAllNodes(this.args);
    this.allNodes = n;
    // this.sumNodes = n.results.map(b => b.nodeinfo.capabilities.bandwidth);
    // let sum = 0;
    // for (let key in this.sumNodes) {
    //   sum += this.sumNodes
    // }
    // console.log(this.sumNodes)
    this.allNodeLoading = false;
  }



}
