import { Injectable, NgZone } from "@angular/core";
import { SSE } from 'sse.js';
import { Subscriber } from "rxjs";
import { Observable } from "rxjs";
import { AuthenticationService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class SSEService {
  constructor(private zone: NgZone, private auth: AuthenticationService) {}

  getServerSentEvent(url: string, withAuth:boolean = false) {
    return new Observable<any>((observer) => {
      this.registerEventSource(url, observer, withAuth);
    });
  }

  private registerEventSource(url: string, observer: Subscriber<any>, withAuth: boolean) {
    let eventSource:SSE;
    if(withAuth){
      const user = this.auth.currentUserValue;
      const options = {
        headers: { Authorization: "Bearer " + user.token }
      }
      eventSource = new SSE(url, options);
    } else {
      eventSource = new SSE(url);
    }

    eventSource.addEventListener("message", (event) => {
      this.zone.run(() => {
        observer.next(event);
      });
    });

    eventSource.addEventListener("error",
      (event) => {
        switch (event.source.readyState) {
          case EventSource.CONNECTING:
            console.log("Reconnecting...");
            break;

          case EventSource.CLOSED:
            console.log("Connection failed, will not reconnect automatically.");
            eventSource.close();
            console.log("Reregistering the EventSource in 5 seconds.")
            setTimeout(() => {
              this.registerEventSource(url, observer, withAuth);
            }, 5000);
            break;
        }
    });
    eventSource.stream();
  }
}
