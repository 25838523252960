import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { get } from 'scriptjs';

@Component({
  selector: 'app-turnstile',
  templateUrl: './turnstile.component.pug',
  styleUrls: ['./turnstile.component.scss']
})

export class TurnstileComponent implements OnInit {

@Input() siteKey: string = '';
@Output() onSuccess: EventEmitter<any> = new EventEmitter();
// @Output() onError: EventEmitter<any> = new EventEmitter();

constructor(){}
    ngOnInit() {
        get("https://challenges.cloudflare.com/turnstile/v0/api.js", () => {
            turnstile.render('#turnstile-container', {
                sitekey: this.siteKey,
                callback: (token) => {
                    this.onSuccess.emit(token)
                }
            });
        });
    }
}
