import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ClockService } from 'src/app/_services/clock.service';
import { HttpClient } from '@angular/common/http';
import { ProjectService } from 'src/app/_services/project.service';
import { SeleniumJob, SeleniumToggle } from './selenium-job-toggle.interface';
import { SSEService } from 'src/app/_services/sse.service';
import { StorageService } from 'src/app/_services/storage.service';
import SwiperCore from 'swiper';

@Component({
  selector: 'monitor-job-history-selenium',
  templateUrl: './monitor-job-history-selenium.component.pug',
  styleUrls: ['./monitor-job-history-selenium.component.scss'],
})
export class MonitorJobHistorySeleniumComponent implements OnInit, OnDestroy {
  @Input() monitor: any;
  @Input() projectId: any;
  jobsLoading: Boolean = true;

  sse;
  selectedTab = 'recent';

  seleniumLoader = [1,2,3,4,5,6,7,8,9,10];
  jobs = [];
  failed = [];
  queued = [];

  clock: number;
  clockSub: any;
  paused = true;
  screenshots: SeleniumToggle = {};
  optionsSub: any;
  isDark: any;

  constructor(
    private clockService: ClockService,
    private sseService: SSEService,
    private projectService: ProjectService,
    private storage: StorageService,
    ) {

      this.optionsSub = this.storage.options.subscribe(options => {
        this.isDark = options.darkToggleState;
      });
  }

  pretty = {
    "safari": {
      name: "Safari",
      icon: "safari"
    },
    "msedge": {
      name: "Edge",
      icon: "microsoft-edge"
    },
    "MicrosoftEdge": {
      name: "Edge",
      icon: "microsoft-edge"
    },
    "firefox": {
      name: "Firefox",
      icon: "firefox"
    },
    "opera": {
      name: "Opera",
      icon: "opera"
    },
    "chrome": {
      name: "Chrome",
      icon: "chrome"
    },
    "chrome-mobile": {
      name: 'Chrome Mobile',
      icon: "chrome"
    },
    // "android": {
    //   name: "Android",
    //   icon: "android"
    // }
  };
  async getScreenshots(job, isError:boolean = false){
    if (!this.screenshots || !this.screenshots[job.job_id]) {
      let screenshot = {
        loaded: false,
        open: true,
        images: []
      } as SeleniumJob;
      this.screenshots[job.job_id] = screenshot;

    } else {
      this.screenshots[job.job_id].open = !this.screenshots[job.job_id].open;
    }
    if (!this.screenshots[job.job_id].loaded) {
      this.screenshots[job.job_id].images = await this.projectService.getScreenshots(job.data.screenshots, this.projectId, this.monitor.id);
    }
  }

  queue(job, type="pending") {
    let j = job;
    Object.assign(job,{type});
    let i = this.queued.map(q => q.id).indexOf(j.id);
    if(i<0){
      if(type==="pending"){
        this.queued.push(j);
      } else {
        this.queued.unshift(j);
      }
    } else {
      this.queued[i] = j;
    }
  }

  unqueue(job, type="done") {
    let i = this.queued.map(q => q.id).indexOf(job.id);
    if(i>-1){
      this.queued.splice(i,1);
    }
    if(type === "done") {
      this.jobs.unshift(job);
      if(this.jobs.length>10) this.jobs.length = 10;
    } else if(type === "error") {
      this.failed.unshift(job);
      if(this.failed.length>10) this.failed.length = 10;
    }
  }

  getJobs() {
    this.sse = this.sseService.getServerSentEvent(`https://api.bitping.com/v2/projects/${this.projectId}/monitor/${this.monitor.id}/selenium/jobs/sse`, true).subscribe(event => {
      if(!event.data) return;
      const data = JSON.parse(event.data);
      console.log(data);
      if(data.eventType === "initial") {
        const jobs = data.jobs.map(j => {
          if(j.status !== "done" && j.status !== "error") {
            this.queue(j);
          } else {
            return j
          }
        });
        this.jobs = jobs;
        if(this.jobs.length>10) this.jobs.length = 10;
        this.failed = data.errored;
        if(this.failed.length>10) this.failed.length = 10;
        this.jobsLoading = false;
      } else if(data.eventType === "queued" || data.eventType === "retry" || data.eventType === "running"){
        this.queue(data.job, data.eventType);
      } else if(data.eventType === "done") {
        this.unqueue(data.job, data.eventType);  
      }
    });
  }

  ngOnInit() {
    this.getJobs();
    this.clockSub = this.clockService.clock.subscribe(t => {
      this.clock = t;
    })
  }

  ngOnDestroy() {
    this.clockSub.unsubscribe();
    this.sse.unsubscribe()
  }
}
