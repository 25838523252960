import { Component, AfterViewInit, OnInit, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.pug',
  styleUrls: ['./otp.component.scss']
})
export class OTPComponent implements OnInit {
  @Input() maxLength: number = 6;
  @Input() allowedKeys: string = "0123456789";
  @Input() onSubmit: Function = async (code: string) => { return true };
  @Input() onUpdate: Function = async (code: string) => { return true };
  @Input() onError: Function = async (error: string) => { return true };
  keySet:Set<String>;
  ngForHack: Array<string>;
  otpError = "";
  currentKey = 0;
  chars = "";
  loading = false;
  submitted = false;
  success = false;

  addChar = ($event: KeyboardEvent) => {
    $event.preventDefault();
    if (!this.loading && !this.success) {
      if($event.key === "Backspace") {
        this.otpError = "";
        this.chars = this.chars.slice(0,-1);
        this.currentKey = this.chars.length;
        this.onUpdate(this.chars);
        return;
      } else if (this.keySet.has($event.key)) {
        this.otpError = "";
        if(this.chars.length < this.maxLength) {
          this.chars = this.chars + $event.key;
          this.onUpdate(this.chars);
        }
        if(this.chars.length === this.maxLength) {
          this.loading = true;
          this.submitOTP();
        }
      }
    }
  }

  submitOTP = async() => {
    this.submitted = true;
    this.loading = true;
    try {
      await this.onSubmit(this.chars);
      this.success = true;
    } catch(e) {
      this.otpError = e.message;
      this.onError(e.message);
    }
    this.loading =false;
  }

  ngOnInit() {
    this.ngForHack = new Array(this.maxLength);
    this.keySet = new Set(this.allowedKeys.split(""));
  }

  constructor() { }
}
