import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { JobPrice } from "../pages/admin/diagnostics/models/JobPrices";
import { SatoshiPrice } from "../pages/admin/diagnostics/models/SatoshiPrice";
import { CreditType, PlanTypes } from "@uptimesv/bitping-common";

@Injectable({
  providedIn: "root"
})
export class AdminService {
  constructor(private http: HttpClient) {}

  async getNodes(args: any = {}) {
    let url = `${environment.apiUrl}/admin/nodes/connected?`;
    let a = [];
    let argList = ["p", "l", "q", "o", "d"];
    for (let arg of argList) {
      if (args[arg]) {
        a.push(arg + "=" + encodeURIComponent(args[arg]));
      }
    }
    url = url + a.join("&");
    console.log(url);
    const n = await this.http.get(url).toPromise();
    return n;
  }

  async getNodeMap() {
    const url = `${environment.apiUrl}/admin/nodes/countries`;
    const n = await this.http.get(url).toPromise();
    return n;
  }

  async getFinancials() {
    let url = `${environment.apiUrl}/admin/income`;
    const n = await this.http.get(url).toPromise();
    return n;
  }

  async getJobPrices() {
    let url = `${environment.apiUrl}/admin/jobprice`;
    const n = await this.http.get<{ data: Array<JobPrice> }>(url).toPromise();
    return n;
  }

  async getSatoshiPrice() {
    let url = `${environment.apiUrl}/admin/satoshiprice`;
    const n = await this.http.get<SatoshiPrice>(url).toPromise();
    return n;
  }

  async getAccounts(args: any = {}) {
    let url = `${environment.apiUrl}/admin/users?`;
    let a = [];
    let argList = ["p", "l", "q", "o", "d"];
    for (let arg of argList) {
      if (args[arg]) {
        a.push(arg + "=" + encodeURIComponent(args[arg]));
      }
    }
    url = url + a.join("&");
    const n = await this.http.get(url).toPromise();
    return n;
  }

  async updateUser(id, body) {
    try {
      const u = await this.http.put(`${environment.apiUrl}/admin/user/${id}`, body).toPromise();
      return u;
    } catch(e) {
      throw(e);
    }
  }

  async deleteUser(id) {
    try {
      await this.http.delete(`${environment.apiUrl}/admin/user/${id}`).toPromise();
    } catch(e) {
      throw(e);
    }
  }

  async changePlan(id: string, planName: PlanTypes) {
    try {
      const u = await this.http.put(`${environment.apiUrl}/admin/user/${id}/plan`, { planName }).toPromise();
      return u;
    } catch(e) {
      throw(e);
    }
  }

  // Do something like this later , expiry: number = Date.now()+86400000*30) {

  async addCredit(id: string, amount: number, type: CreditType) {
    try {
      const u = await this.http.post(`${environment.apiUrl}/admin/user/${id}/credit`, {
        amount,
        type,
        expirty: undefined
      }).toPromise();
      return u;
    } catch(e) {
      throw(e);
    }
  }
}
