import { Component, OnInit, OnDestroy } from '@angular/core';
// import { CountriesData } from 'countries-map';
import { NodesService } from 'src/app/_services/nodes.service';
import { SSEObserverService } from '../../_services/sseobserver.service';
import { AlexaOutages, NodeData } from '../../_models/AlexaOutages';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-node',
  templateUrl: './node.component.pug',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit, OnDestroy {
  
  nodeCoordinates:NodeData[];
  nodeInterval;
  nodes: number;
  nodeList: any[] = []
  nodeData;
  outdatedNodes;
  nodeLoading = true;
  nodelist;
  sse;
  nodelistmap;
  count = {
    nodes: 0,
    countries: 0
  };
  data;
  countries;
  moneyFlowRegions;
  moneyFlowPerHour;
  flowLoader = [1,2,3,4,5,6,7]


  constructor(public nodesservice:NodesService, private sseObserver: SSEObserverService, private http: HttpClient) {
  }

  async myNodes() {
    let nodelist = await this.nodesservice.getNodes();
    this.nodeList = nodelist.results;
    this.outdatedNodes = this.nodeList.filter(n => /^0\.[1-8]\./.test(n.nodeinfo.version)).length;
  }

  async moneyFlowPerRegion() {
    const moneyFlow = await this.nodesservice.getMoneyFlowPerRegion();
    this.moneyFlowRegions = moneyFlow;
  }

  async hourlyMoneyFlow() {
    const moneyFlowPer = await this.nodesservice.getMoneyFlowPerHour();
    this.moneyFlowPerHour = moneyFlowPer;
    console.log(this.moneyFlowPerHour)
  }
  
  
  getNodes() {
    this.sseObserver.getServerSentEvent('https://api.bitping.com/explorer/sse/nodes').subscribe(event => {
      const n = JSON.parse(event.data);
      let map:any = {};
      let coords = [];
      n.forEach(n1 => {
        if(map[n1.countryCode]){
          map[n1.countryCode]++;
        } else {
          map[n1.countryCode] = 1;
        }
        coords.push({lon: n1.lon, lat: n1.lat});
      });
      this.nodeCoordinates = coords;
      this.nodelistmap = Object.keys(map)
      .map((k) => {
        return { countrycode: k, count: Number(map[k]) }
      })
      .sort((a,b) => (Number(a.count) < Number(b.count)) ? 1 : -1);
      this.data = map;
      this.count.nodes = this.nodelistmap.map(n1 => n1.count).reduce((a,b) => { return a+b; });
      this.count.countries = this.nodelistmap.length;
    });
  }

  ngOnInit() {
    this.getNodes();
    this.myNodes();
    console.log(this.moneyFlowRegions)
    this.moneyFlowPerRegion();
    this.hourlyMoneyFlow();
  }

  
  ngOnDestroy() {
    clearInterval(this.nodeInterval);
    if(this.sse) {
      this.sse.close();
    }
  }
  
}
