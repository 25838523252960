
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private isOpenSubject: BehaviorSubject<any>;
  public isOpen: Observable<any>;

  constructor() {
    // const open = (window.innerWidth > 992) ? true : false;
    this.isOpenSubject = new BehaviorSubject<boolean>(false);
    this.isOpen = this.isOpenSubject.asObservable();
  }

  public get isOpenValue(): any {
    return this.isOpenSubject.value;
  }

  toggleSidebar() {
    this.isOpenSubject.next(!this.isOpenSubject.value);
  }

  showSidebar() {
    this.isOpenSubject.next(true);
  }

  hideSidebar() {
    this.isOpenSubject.next(false);
  }
}
