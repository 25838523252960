import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'monitor-job-overview',
  templateUrl: './monitor-job-overview.component.pug',
  styleUrls: ['./monitor-job-overview.component.scss']
})
export class MonitorJobOverviewComponent implements OnInit {

  @Input() monitor: any;
  @Input() projectId: any;
  @Input() timeframe: any;
  
  results;
  overviewData;
  stats;
  downtime;
  statitems;
  createdTime;

  granularPeriods = [
    {
      time: "Last Hour",
      time_data: "1 hours",
      minutes: "60",
    },
    {
      time: "Last 12 hours",
      time_data: "12 hours",
      minutes: "720",
    },
  ];
  periods = 
  [
    {
      time: "Last Day",
      time_data: "1 days",
      minutes: "1440",
    },
    {
      time: "Last Week",
      time_data: "7 days",
      minutes: "10080",
    },
    {
      time: "Last Fortnight",
      time_data: "14 days",
      minutes: "20160"
    },
    {
      time: "Last Month",
      time_data: "30 days",
      minutes: "43200"
    },
  ];
  route: any;
  selectedId: any;
  selectedMonitor: any;
  subscriptions: any[];
  dateNow: any;
  granularResults;

  constructor(private projectService: ProjectService) { }

  async loadMonitorStats() {
    const d = this.granularPeriods.map(stat => this.projectService.getMonitorGranularStats(this.projectId, this.monitor.id, stat.time_data));
    const s = this.periods.map(stat => this.projectService.getMonitorStats(this.projectId, this.monitor.id, stat.time_data));
    this.results = await Promise.all(s)
    this.granularResults = await Promise.all(d)
    console.log(this.results)
    console.log(this.granularResults)
  }

  async ngOnInit() {
    this.loadMonitorStats();
    this.createdTime = new Date(this.monitor.created).getTime();
    this.dateNow = Date.now();
}

}
