import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';
import { ModalService } from '../../../_services/modal.service';
import { BaseMonitorComponent } from '../base-monitor.component';
import { MonitorTypes } from '@uptimesv/bitping-common';

@Component({
  selector: 'seleniu-mmonitor',
  templateUrl: './selenium-monitor.component.pug',
  styleUrls: ['./selenium-monitor.component.scss']
})

export class SeleniumMonitorComponent extends BaseMonitorComponent implements OnInit, OnDestroy {
  locations:any;
  browsers:any;
  file:File;
  fileError: string;
  fileName: string;
  criteria;
  nodeCount: any;
  bandwidth;
  selectedProxyFilter;
  dropdownOpen = {
    time: false,
 };
  selectedBandwidth: any;
  selectedLocations: [];
  apiError = ""

  constructor(
    public projectService: ProjectService,
    public formBuilder: UntypedFormBuilder,
    public modal: ModalService
  ) {
    super(projectService, modal);
  }

  //Frequency override
  frequencies = [{
    interval: 60*this.min,
    label: '1h',
    amount: '1',
    unit: 'h',
    milli: 10000000,
  },
  {
    interval: 3*60*this.min,
    label: '3h',
    amount: '3',
    unit: 'h',
    milli: 10000000,
  },
  {
    interval: 6*60*this.min,
    label: '6h',
    amount: '6',
    unit: 'h',
    milli: 10000000,
  },
  {
    interval: 12*60*this.min,
    label: '12h',
    amount: '12',
    unit: 'h',
    milli: 10000000,
  },
  {
    interval: 24*60*this.min,
    label: '24h',
    amount: '24',
    unit: 'h',   
    milli: 10000000,
  }];

  ngOnInit() {
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
      this.projectId = this.data.project;
      this.type = "selenium" as MonitorTypes;
      if(this.data.monitor && this.data.project){
        this.edit = true;
        this.fileName = this.data.monitor.configuration.fileName;
        this.monitorId = this.data.monitor.id;
        this.colour = this.data.monitor.colour.domain[0];
        this.selectedBandwidth = this.data.monitor.regions?.exclusions?.bandwidth,
        this.locations = this.data.monitor.regions,
        this.frequency = this.frequencies.findIndex(x => x.interval === this.data.monitor.frequency)+1;
        this.selectedProxyFilter = this.data.monitor?.regions?.requirements?.proxy
      }
    });
    
    this.monitorForm = this.formBuilder.group({
      name: [this.data.monitor && this.data.monitor.name || '', Validators.required],
      type: [this.type, Validators.required],
      host: [this.data.monitor && this.data.monitor.endpoint || ''],
      frequency: [this.frequency, Validators.required]
    },{ validator: this.hostValidator});
    this.loaded = true;
  }

  
  getNodeCount($event) {
    this.nodeCount = $event;
  }


  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const fr = new FileReader();
      const file:File = event.target.files[0];
      fr.onloadend = (e) => {
        try {
          const side = this.parseSide(fr.result);
          this.fileName = file.name;
          this.file = file;
          this.fileError = null;
          this.monitorForm.controls.host.setValue(side.url);
        } catch(e) {
          console.log(e)
          this.fileError = e.message;
          this.file = null;
          this.fileName = "";
          this.monitorForm.controls.host.setValue("");
        }
      }
      fr.readAsText(file);
    }
  }

  parseSide(side: any) {
    try {
      let t;
      try {
          t = JSON.parse(side);
      } catch(e) {
          throw new Error("SIDE_INVALID_JSON");
      }
      if(!t.url) {
          throw new Error("SIDE_NO_URL");
      }
      if(!t.tests || !t.tests.length) {
          throw new Error("SIDE_NO_TESTS");
      }
      if(!t.tests[0].commands || !t.tests[0].commands.length) {
          throw new Error("SIDE_NO_COMMANDS");
      }
      console.log("file ok")
      return t;
    } catch(e) {
        console.log(e)
        throw e;
    }
  }

  async addMonitor() {
    this.submitted = true;
    if(this.monitorForm.invalid || this.fileError){
      console.log("contains error")
      return;
    }
    if(!this.loading){
      this.loading = true
      const freq = this.frequencies[this.m.frequency.value-1];
      const monitor = new FormData();
      if(this.selectedBandwidth) {
        monitor.append('bandwidth', JSON.stringify({
          lessThan: this.selectedBandwidth?.lessThan,
          greaterThan: this.selectedBandwidth?.greaterThan,
        }))
      }
      monitor.append('name', this.m.name.value);
      if(this.file && !this.fileError && this.m.host.value){
        monitor.append('file', this.file);
        monitor.append('endpoint', this.m.host.value);
      }
      monitor.append('colour', this.colour);
      if(this.locations){
        const locations = { 
            requirements: {
              countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === false).map(x => x.code),
              continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === false).map(x => x.code),
              cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === false).map(x => x.code),
              operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === false).map(x => x.code),
              isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === false).map(x => x.code),
              proxy: this.selectedProxyFilter?.value || this.selectedProxyFilter
            },
            exclusions: {
              countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === true).map(x => x.code),
              continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === true).map(x => x.code),
              cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === true).map(x => x.code),
              operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === true).map(x => x.code),
              isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === true).map(x => x.code),
              bandwidth: {
                lessThan: this.selectedBandwidth?.lessThan,
                greaterThan: this.selectedBandwidth?.greaterThan
              }
            }
          }
        monitor.append('regions', JSON.stringify(locations))
      } else {
        this.locations = [];
      }
      if(this.browsers){
        monitor.append('browsers', JSON.stringify(this.browsers.map(b => { return b.value })));
      }
      monitor.append('frequency', freq.interval.toString());
      try {
        if(this.edit){
          monitor.append('id', this.monitorId);
          const data = await this.projectService.updateSeleniumMonitor(this.projectId, this.monitorId, monitor);
        } else {
          //If it's a new test, don't allow blank file or hostname
          if(!this.file || !this.m.host.value) {
            console.log("file contains error")
            this.fileError = "SIDE_NO_FILE";
            throw new Error("No SIDE file selected.");
          }
          const data = await this.projectService.addSeleniumMonitor(this.projectId, monitor)
        }
        this.modal.close();
      } catch(error) {
        console.log(error)
        this.apiError = error["error"]["message"];
        this.loading = false;
      }
    }
  }
}
