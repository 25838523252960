import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalService } from 'src/app/_services/modal.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'edit-monitor-name',
  templateUrl: './edit-monitor-name.component.pug',
  styleUrls: ['./edit-monitor-name.component.scss']
})
export class EditMonitorNameComponent implements OnInit, OnDestroy {

  loading = false;
  error;
  items;
  submitted;
  projectNameForm: UntypedFormGroup;
  project: any;
  getData: any;
  data: any;
  id: any;
  selectedId: any;

  constructor(
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public modal: ModalService
  ) {
  }

  async changeName() {
    
    const monitor = {
      name: this.m.name.value
    };
    try {
      const data = await this.projectService.updateProject(this.project.id, monitor);
    } catch(e) {
        console.log(e);
    }
    this.modal.close();
    }

  ngOnInit() {
    this.getData = this.modal.getData().subscribe(data => {
      if(data && data.id){
        this.id = data.id;
      }
    })

    this.projectService.projects.subscribe(projects => {
      const project = projects.find(project => project.id == this.id);
      if(project){
        this.project = project;
  
        this.projectNameForm = this.formBuilder.group({
          name: [this.project.name, Validators.required]
        });
  
    }
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }


   get m() { return this.projectNameForm.controls; }

}
