import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../../../_services/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalService } from 'src/app/_services/modal.service';


@Component({
  selector: 'notification-settings',
  templateUrl: './notification-settings.component.pug',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  webhookForm: UntypedFormGroup;


  requestObject;
  myVar;
  types;

  notificationSettings:any;
  monitorSettings:any;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    public modal: ModalService
  ) { 
  }

  ngOnInit() {
    this.getNotificationSettings();
    this.userService.notificationSettingSubject.subscribe(      
      x => {
        this.notificationSettings = x;
      }
    );

    // this.getNotificationSettings();
    // this.getMonitorNotifications();

    this.webhookForm = this.formBuilder.group({
      name: ["", Validators.required],
      type: ["", Validators.required],
      configValue: ["", Validators.required]
    });

    this.types = this.getTypes();
    this.webhookForm.controls.type.patchValue(this.types[0].value);
  }

  async getNotificationSettings(){
    let notificationSettings = await this.userService.getNotificationSettings();
    this.notificationSettings = notificationSettings
    }

  // async getMonitorNotifications(){
  //   let monitorSettings = await this.userService.getNotificationSettings();
  //   this.monitorSettings = monitorSettings.monitorSettings
  // }

  getTypes() {
    return [
      { value: "GenericWebhook", name: 'Generic Webhook' },
      { value: "Email", name: 'Email' },
      { value: "Slack", name: 'Slack' },
    ];
  }

  iconFunction(type) {
    if (type === "Email") {
      return 'mail'
    }
    else if (type === "Generic_Webhook"){
      return 'api'
    }
    else if (type === "Slack") {
      return 'slack'
    }
  }

  async deleteWebHoook(i,id) {
    this.notificationSettings.splice(i, 1)

    await this.userService.deleteNotificationWebHook(id);
    await this.userService.getNotificationSettings();
  }

  createWebHook = async() => {
      try {
        this.requestObject = {
          name: this.m.name.value,
          type: this.m.type.value,
          recipient: this.m.type.value === "Email" ?this.m.configValue.value : undefined,
          endpoint: ["Slack", "GenericWebhook"].indexOf(this.m.type.value) > -1 ?this.m.configValue.value : undefined,
        };
        let n = await this.userService.createNewNotificationWebHook(this.requestObject);
        // this.getNotificationSettings();

      } catch(e){
        console.log(e);
      }
      }
  get m() { return this.webhookForm.controls; }

}
