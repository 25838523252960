import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../_services/modal.service';
import { UserService } from 'src/app/_services/user.service';
import { PlanInfoService } from 'src/app/_services/planInfo.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TeamMember, TeamsRoles, TeamSSOTypesEnum } from '@uptimesv/bitping-common';

@Component({
  selector: 'app-addteammember',
  templateUrl: './add-team-member.component.pug',
  styleUrls: ['./add-team-member.component.scss']
})
export class AddTeamMemberComponent implements OnInit, OnDestroy{
  data:any;
  memberForm: UntypedFormGroup;
  currentUser;
  loading = false;
  submitted = false;
  plans;
  error = "";
  selected = false;
  sso_type?: TeamSSOTypesEnum;
  member_role: TeamsRoles;

  memberTypes = Object.values(TeamsRoles);

  dropdownOpen = false;

  authMethods: {value: TeamSSOTypesEnum, disabled: boolean }[] = [
    {
      value: TeamSSOTypesEnum.Microsoft,
      disabled: false
    },
    {
      value: TeamSSOTypesEnum.Apple,
      disabled: true
    },
    {
      value: TeamSSOTypesEnum.GitHub,
      disabled: true
    },
    {
      value: TeamSSOTypesEnum.Google,
      disabled: true
    },
    {
      value: TeamSSOTypesEnum.Twitter,
      disabled: true
    },
  ];

  constructor(
    public modal: ModalService,
    private user: UserService,
    private formBuilder: UntypedFormBuilder
  ) {

  }

  async addTeamMember() {
    if (!this.member_role || !this.sso_type || this.loading) return
    
    this.loading = true;
    this.submitted = true;
    try {    
      let member: TeamMember = {
        sso_type: this.sso_type,
        sso_id: this.p.id.value,
        role: this.member_role,
        name: this.p.name.value
      }
      await this.user.addTeamMember(member);
      this.modal.close();
    } catch (e) {
      this.error = e.message;
      this.loading = false;
    }
  }

  selectMemberType(role: TeamsRoles) {
    console.log(role);
    this.member_role = role
  }

  selectSSOType(type?: TeamSSOTypesEnum) {
    this.sso_type = type
  }

  get p() { return this.memberForm.controls; }

  ngOnInit() {
    this.memberForm = this.formBuilder.group({
      name: [''],
      id: ['', Validators.required],
    })
  }

  ngOnDestroy(){
  }

}
