
const cache = {};

export function id(): string {
  let newId = ('0000' + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4);

  // append a 'a' because neo gets mad
  newId = `a${newId}`;

  // ensure not already used
  if(!cache[newId]) {
    cache[newId] = true;
    return newId;
  }

  return id();
}
