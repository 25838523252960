import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'delete-project',
  templateUrl: './delete-project.component.pug',
  styleUrls: ['./delete-project.component.scss']
})

export class DeleteProjectComponent implements OnInit, AfterViewInit, OnDestroy {

  selectedId;
  project;
  error;
  loading;

  private getData:Subscription;

  constructor(
    private projectService: ProjectService,
    private router: Router,
    public modal: ModalService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData = this.modal.getData().subscribe(data => {
      if(data && data.id){
        this.selectedId = data.id;
      }
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  async deleteProject() {
    await this.projectService.removeProject(this.selectedId);
    this.modal.close();
    this.router.navigate(['/projects']);
  }
}
