import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClockService {
  private clockSubject: BehaviorSubject<number>;
  public clock: Observable<any>;
  constructor() {
    this.clockSubject = new BehaviorSubject<number>(new Date().getTime());
    this.clock = this.clockSubject.asObservable();
    setInterval(() => {
      this.clockSubject.next(Math.floor(new Date().getTime()/1000)*1000);
    }, 500);
  }
}
