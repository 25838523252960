import { Component, OnInit, OnDestroy } from "@angular/core";
import { environment } from "../../../environments/environment";
import { User } from '../../_models/user';
import { AuthenticationService } from '../../_services/auth.service';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from '../../_services/user.service';
import { ModalService } from 'src/app/_services/modal.service';
import {loadStripe, Stripe} from '@stripe/stripe-js';


@Component({
  selector: "app-billing",
  templateUrl: "./billing.component.pug",
  styleUrls: ["./billing.component.scss"]
})
export class BillingComponent implements OnInit, OnDestroy {
  // stripeKey = environment.stripePublishKey;
  priceEst;
  periodCount = 0;
  error: any;
  plan: any;
  subscription;
  selectedTab = 'moneybutton';
  complete = false;
  element: any;
  planName: string;
  billingLoader = [1,2,3,4];
  // cardOptions: ElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: "#276fd3",
  //       color: "#31325F",
  //       lineHeight: "40px",
  //       fontWeight: 300,
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSize: "18px",
  //       "::placeholder": {
  //         color: "#CFD7E0"
  //       }
  //     }
  //   }
  // };

  // constructor(private _stripe: StripeService) {}

  currentUser: any;
  billing: any;
  billingInterval:any;
  navMenuOpen = false;
  customerPortal;
  planLimits;
  customer;
  stripe: Stripe;


  constructor(private auth:AuthenticationService,
    private user:UserService,
    private projectService: ProjectService,
    public modal: ModalService
    ) {
    this.updateBalances();
  }

  async onPlanManage() {
    try {
      const session = await this.user.getStripeManage();
      this.customerPortal = session.customerPortalUrl;
      this.customer = session;
      console.log(this.customer);
    } catch(e) {
      this.customer = null;
      this.customerPortal = null;
    }
  }

  ngOnInit(): void {
    this.billingInterval = setInterval(this.updateBalances, 10000);
    this.getPriceEstimate()
    this.getSubscription();
    // this.onPlanManage();
  }

  ngOnDestroy() {
    clearInterval(this.billingInterval);
  }

  async getSubscription() {
    const subscription: any = await this.user.getSubscription();
    const subscriptionLimits: any = await this.user.getSubscriptionLimits();
    this.planLimits = subscriptionLimits;
    this.plan = subscription.plan;
    this.planName = subscription.plan.name;
    if(this.plan.id!==0){
      await this.onPlanManage();
    }
  }


  updateBalances = async () => {
    this.currentUser = await this.user.me();
    this.billing = await this.user.getBilling();
  }

  async getPriceEstimate() {
    const priceEst = await this.projectService.getUserPriceEstimate();
    this.priceEst = priceEst;
  }


  seekPeriod() {
    if (this.periodCount <2) {
      this.periodCount+=1;
    }
    else {
      this.periodCount = 0;
    }
  }

  // cardUpdated(result) {
  //   this.element = result.element;
  //   this.complete = result.card.complete;
  //   this.error = undefined;
  // }

  // keyUpdated() {
  //   this._stripe.changeKey(this.stripeKey);
  // }

  // getCardToken() {
  //   this._stripe
  //     .createToken(this.element, {
  //       name: "tested_ca",
  //       address_line1: "123 A Place",
  //       address_line2: "Suite 100",
  //       address_city: "Irving",
  //       address_state: "BC",
  //       address_zip: "VOE 1H0",
  //       address_country: "CA"
  //     })
  //     .subscribe(result => {
  //       // Pass token to service for purchase.
  //       console.log(result);
  //     });
  // }
}
