import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, first } from 'rxjs/operators';
import { ModalService } from '../../_services/modal.service';
import { StorageService } from '../../_services/storage.service';
import { OutsideClickDirective } from '../../_directives/outside-click-directive.directive'

@Component({
  selector: 'app-project',
  templateUrl: './project.component.pug',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit, OnDestroy {

  monDisabled = false;
  projectNameForm: UntypedFormGroup;
  selectedId;
  project;
  subscriptions = [];
  monitorViewStyle = "cards";
  isList = true;
  textVisible;
  setValue; any;
  monitorUpdate: any;
  monitors = [];
  searchText: string;



  filters = [
    {
      name: 'Name',
      monitor_order_by: 'name'
    },
    {
      name: 'Colour',
      monitor_order_by: 'colour'
    },
    {
      name: 'Type',
      monitor_order_by: 'type'
    },
  ];

  timeframes = [
    {
      interval: '6 months',
      scale: '2 week',
      label: '6m'
    },
    {
      interval: '3 months',
      scale: '1 week',
      label: '3m'
    },
    {
      interval: '1 month',
      scale: '2 day',
      label: '1m'
    },
    {
      interval: '14 days',
      scale: '1 day',
      label: '14d'
    },
    {
      interval: '7 days',
      scale: '12 hour',
      label: '7d'
    },
    {
      interval: '3 days',
      scale: '2 hour',
      label: '3d'
    },
    {
      interval: '24 hours',
      scale: '1 hour',
      label: '24hr'
    }
  ];

  dropdownOpen = {
    time: false,
    options: false,
    filter: false,
    optionsMob: false
  };

  currentTimeframe = this.timeframes[3];
  currentFilter = this.filters[0];

  updateTimeframe(timeframe) {
    this.storage.updateOptions({ timeframe: timeframe });
    this.monitors.forEach(monitor => {
      this.loadMonitor(this.project.id, monitor.id, this.currentTimeframe.interval, this.currentTimeframe.scale);
    });
  }

  async updateFilter(filter) {
    this.currentFilter = filter;
    this.storage.updateOptions({ filter: filter });
    this.projectService.getProjects(this.currentFilter.monitor_order_by);
  }

  private sub: any;

  constructor(
    private formBuilder: UntypedFormBuilder,

    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    public modal: ModalService,
    public storage: StorageService
  ) { }

  ngOnInit() {
    this.subscriptions = [
      this.storage.options.subscribe(options => {
        if (options.monitorViewStyle) {
          this.monitorViewStyle = options.monitorViewStyle;
        } else {
          this.updateMonitorViewStyle("cards");
        }
        if (options.filter) {
          this.currentFilter = options.filter;
        } else {
          this.currentFilter = this.filters[0];
        }
        if (options.timeframe) {
          this.currentTimeframe = options.timeframe;
        } else {
          this.currentTimeframe = this.timeframes[3];
        }
      }),
      this.route.params.subscribe(params => {
        this.selectedId = params.id;
      }),
      this.projectService.projects.subscribe(projects => {
        if (!this.selectedId) return;
        const project = projects.find(project => project.id == this.selectedId);
        if (project) {
          this.project = project;

          this.projectNameForm = this.formBuilder.group({
            name: [this.project.name, Validators.required]
          });


          if (!this.project.monitors) {
            this.project.monitors = [];
          }
          this.monitors = this.project.monitors
            .filter(m => m.isDeleted === false)
            .map(m => {
              let m2 = this.monitors.find(m3 => m3.id === m.id);
              if (m2) {
                m = {
                  id: m.id,
                  endpoint: m.endpoint,
                  name: m.name,
                  regions: m.regions,
                  frequency: m.frequency,
                  jobGroup: m.jobGroup,
                  configuration: m.configuration || null,
                  status: m.status,
                  colour: { domain: [m.colour] },
                  type: m.type,
                  loaded: true,
                  stats: m2.stats,
                  data: m2.data
                }
              } else {
                m = {
                  id: m.id,
                  endpoint: m.endpoint,
                  name: m.name,
                  regions: m.regions,
                  frequency: m.frequency,
                  jobGroup: m.jobGroup,
                  configuration: m.configuration || null,
                  status: m.status,
                  colour: { domain: [m.colour] },
                  type: m.type,
                  loaded: false,
                  stats: {},
                  data: []
                }
              }
              return m;
            });
          this.monitors.forEach(monitor => {
            if (!monitor.loaded) {
              this.loadMonitor(this.project.id, monitor.id, this.currentTimeframe.interval, this.currentTimeframe.scale);
            }
          });
        }
        clearInterval(this.monitorUpdate);
        this.monitorUpdate = setInterval(() => { this.updateAllMonitors(); }, 15000);
      })
    ];
  }


  dateToMillis(d) {
    return Math.floor(new Date(d).getTime() / 1000) * 1000;
  }

  updateAllMonitors() {
    this.monitors.forEach(monitor => { this.loadMonitor(this.project.id, monitor.id, this.currentTimeframe.interval, this.currentTimeframe.scale); });
  }

  async changeName() {
    if (this.projectNameForm.invalid) {
      return;
    }
    const monitor = {
      name: this.m.name.value
    };
    try {
      const data = await this.projectService.updateProject(this.project.id, monitor);
      this.textVisible = false;
    } catch (e) {
      console.log(e);
    }
  }

  async loadMonitor(project, monitor, timeframe, scale = '1 hour') {
    const m = await this.projectService.getMonitorData(project, monitor, timeframe, scale);
    const s = await this.projectService.getMonitorStats(project, monitor, timeframe);
    const i = this.monitors.findIndex(mon => mon.id === monitor);
    this.monitors[i].stats = s;
    this.monitors[i].data = this.prepareData(this.monitors[i], m);
    this.monitors[i].loaded = true;

  }

  generateColour(s) {
    let v = s.split("").map(c => c.charCodeAt(0)).reduce((total, amount) => total + amount);
    const colours = ["0984e3", "#2ED573", "#FF4757", "#FF6348", "#2ED573", "#6C5CE7", "#000000", "#B4B4B4"];
    const c = Math.floor(v % colours.length);
    return { domain: [colours[c]] };
  }

  updateMonitorViewStyle(s) {
    this.storage.updateOptions({ monitorViewStyle: s });
  }

  projectEdit() {
    if (typeof this.textVisible === 'undefined') {
      this.textVisible = true;
    }
    else {
      this.textVisible = !this.textVisible;
    }
  }

  async downloadReport(id, name, timeframe) {
    await this.projectService.getProjectReportPDF(id, name, "24 hours");
  }

  prepareData(l, d) {
    let data = [
      {
        name: l.name,
        series: d.map(x => {
          return { name: new Date(x.name), value: (x.value == null) ? -2 : x.value }
        })
      }
    ];
    return data;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
    clearInterval(this.monitorUpdate);
  }
  get m() { return this.projectNameForm.controls; }
}
