import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/_models/user';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { Observable, Subscription } from 'rxjs';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-logged-in-node',
  templateUrl: './logged-in-node.component.pug',
  styleUrls: ['./logged-in-node.component.scss']
})
export class LoggedInNodeComponent implements OnInit, OnDestroy {

  currentUser: User;
  authSubscription: Subscription;
  launchLink;

  constructor(
    private auth: AuthenticationService,
    protected sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.authSubscription = this.auth.currentUser.subscribe(x => {
      this.currentUser = x;
      this.launchLink = this.sanitizer.bypassSecurityTrustUrl('bitping://' + x.token);
    });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

}

