import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'i18nTimeAgo'
})

export class i18nTimeAgo implements PipeTransform {

  transform(t:any, d2?: number):any {
    const d1 = new Date(t).getTime();
    const dt = Math.floor((d2 - d1)/1000)*1000;
    const ONE_DAY = 86400000;
    const ONE_HOUR = 3600000;
    const ONE_MINUTE = 60000;
    let dateTime = {value:undefined, unit: ''};
    //If days
    if(dt>=ONE_DAY){
      dateTime.value = Math.floor(dt/ONE_DAY);
      dateTime.unit = dt>=(2*ONE_DAY) ? 'days' : 'day';
    }
    //If hours
    else if(dt>=ONE_HOUR){
      dateTime.value = Math.floor(dt/ONE_HOUR);
      dateTime.unit = dt>=(2*ONE_HOUR) ? 'hours' : 'hour';
    }
    //If minutes
    else if(dt>=ONE_MINUTE){
      dateTime.value = Math.floor(dt/ONE_MINUTE);
      dateTime.unit = dt>=(2*ONE_MINUTE) ? 'minutes' : 'minute';
    }
    //If minutes
    else if(dt>=1000){
      dateTime.value = Math.floor(dt/1000);
      dateTime.unit = dt>=(2000) ? 'seconds' : 'second';
    }
    else {
      dateTime.value = '';
      dateTime.unit = 'justnow';
    }
    dateTime.unit = 'datetime.'+dateTime.unit;
    return dateTime;
  }
}
