import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { ProjectService } from 'src/app/_services/project.service';
import { FilterService, DumbFilter } from '../../_services/filter.service';

@Component({
  selector: 'region-selector',
  templateUrl: './region-selector.component.pug',
  styleUrls: ['./region-selector.component.scss']
})
export class RegionSelectorComponent implements OnInit, OnDestroy {
  @Input() selectedLocations;
  @Output() nodeCount = new EventEmitter();
  @Output() selectedLocationsChange = new EventEmitter();
  @Input() selectedBandwidth;
  @Output() selectedBandwidthChange = new EventEmitter();
  @Input() selectedOption;
  @Output() selectedOptionChange = new EventEmitter<string>();
  
  options = [
    {label: "Allow Proxies", value: "allow"},
    {label: "Disallow Proxies", value: "disallow"},
    {label: "Only Proxies", value: "only"}
  ]

  dropdownOpen = {
    allow: false,
  };
  selected;
  selectedb;
  subscriptions: any[];
  @Output() criteria: any;
  selectedCriteria : any[];
  filters;
  filter$: Observable<DumbFilter[]>;
  filterLoading = false;
  filterInput$ = new Subject<string>();
  selectedFilters: DumbFilter[];
  selectedProxyFilter;
  filterType;

  constructor(private projects: ProjectService, private http: HttpClient, private filterService: FilterService) {

  }

  bandwidths = [
    {
      name: "None",
      sub: "Allow all",
    },
    {
      name: "Low",
      greaterThan: 1500000,
      sub: "< 12mbps"
    },
    {
      name: "Normal",
      sub: "> 12 mbps",
      lessThan: 1250000
    },
    {
      name: "Fast",
      sub: "> 50 mbps",
      lessThan: 6250000
    },
    {
      name: "Server",
      sub: "> 100 mbps",
      lessThan: 12500000
    },


]

selectBandwidth(i) {
  this.selectedBandwidth = this.bandwidths[i];
  this.selectedb = i;
  this.selectedBandwidthChange.emit(this.selectedBandwidth);
}


selectOption(o) {
  this.selectedOption = o;
  this.selectedOptionChange.emit(o)
}


toggleItemType(item) {
  item.excluded = !item.excluded
}

  async updateLocations() {
    this.selectedLocations = this.selectedFilters

    this.selectedLocationsChange.emit(this.selectedLocations)
    this.criteria = {
      requirements: {
        countries: this.selectedFilters.filter(f => f.type === 'countries' && f.excluded === false).map(x => x.code) || [],
        continents: this.selectedFilters.filter(f => f.type === 'continents'&& f.excluded === false).map(x => x.code) || [],
        cities:  this.selectedFilters.filter(f => f.type === 'cities' && f.excluded === false).map(x => x.code) || [],
        operatingSystems:  this.selectedFilters.filter(f => f.type === 'operatingSystems' && f.excluded === false).map(x => x.code) || [],
        isps:  this.selectedFilters.filter(f => f.type === 'isps' && f.excluded === false).map(x => x.code) || [],
        proxy: this.selectedOption.value
      },
      exclusions: {
        countries: this.selectedFilters.filter(f => f.type === 'countries' && f.excluded === true).map(x => x.code) || [],
        continents: this.selectedFilters.filter(f => f.type === 'continents'&& f.excluded === true).map(x => x.code) || [],
        cities:  this.selectedFilters.filter(f => f.type === 'cities' && f.excluded === true).map(x => x.code) || [],
        operatingSystems:  this.selectedFilters.filter(f => f.type === 'operatingSystems' && f.excluded === true).map(x => x.code) || [],
        isps:  this.selectedFilters.filter(f => f.type === 'isps' && f.excluded === true).map(x => x.code) || [],
        bandwidth: {
          lessThan: this.selectedBandwidth?.lessThan,
          greaterThan: this.selectedBandwidth?.greaterThan
        }
      }
    };
    const response : any = await this.projects.getNodeCriteria(this.criteria)
    this.nodeCount.emit(response.count)
  }


  // locations = [
  //   {
  //     display: 'Africa',
  //     value: 'AF',
  //     type: 'Continent'
  //   },
  //   {
  //     display: 'Asia',
  //     value: 'AS',
  //     type: 'Continent'
  //   },
  //   {
  //     display: 'Europe',
  //     value: 'EU',
  //     type: 'Continent'

  //   },
  //   {
  //     display: 'Oceania',
  //     value: 'OC',
  //     type: 'Continent'
  //   },
  //   {
  //     display: 'North America',
  //     value: 'NA',
  //     type: 'Continent'
  //   },
  //   {
  //     display: 'South America',
  //     value: 'SA',
  //     type: 'Continent'
  //   },
  //   {
  //     display: 'Australia',
  //     value: 'AU',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Canada',
  //     value: 'CA',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'China',
  //     value: 'CN',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Germany',
  //     value: 'DE',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'India',
  //     value: 'IN',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Japan',
  //     value: 'JP',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'United Kingdom',
  //     value: 'GB',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'United States',
  //     value: 'US',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'South Africa',
  //     value: 'ZA',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Thailand',
  //     value: 'TH',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Vietnam',
  //     value: 'VN',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'Indonesia',
  //     value: 'ID',
  //     type: 'Country'
  //   },
  //   {
  //     display: 'South Korea',
  //     value: 'KR',
  //     type: 'Country'
  //   },
  // ];
 
 
  ngOnInit() {
    if(this.selectedOption) {
      this.selectedOption = this.options.find(option => option.value === this.selectedOption);
    } else {
      this.selectedOption = this.options[0];
    }
  
    console.log(this.selectedOption)
    let index = this.bandwidths.findIndex(x => {
      return (
        x.greaterThan === this.selectedBandwidth?.greaterThan
        && x.lessThan === this.selectedBandwidth?.lessThan
      )
    })
    this.selectedb = index;
    this.selectedLocations = [
        ...this.selectedLocations?.requirements?.countries?.map(x => ({ type: "countries", code: x, excluded: false})) || [],
        ...this.selectedLocations?.requirements?.continents?.map(x => ({ type: 'continents', code: x, excluded: false})) || [],
        ...this.selectedLocations?.requirements?.cities?.map(x => ({ type: 'cities', code: x, excluded: false})) || [],
        ...this.selectedLocations?.requirements?.operatingSystems?.map(x => ({ type: 'operatingSystems', code: x, excluded: false})) || [],
        ...this.selectedLocations?.requirements?.isps?.map(x => ({ type: 'isps', code: x, excluded: false})) || [] ,
        ...this.selectedLocations?.exclusions?.countries?.map(x => ({ type: "countries", code: x, excluded: true})) || [],
        ...this.selectedLocations?.exclusions?.continents?.map(x => ({ type: 'continents', code: x, excluded: true})) || [],
        ...this.selectedLocations?.exclusions?.cities?.map(x => ({ type: 'cities', code: x, excluded: true})) || [],
        ...this.selectedLocations?.exclusions?.operatingSystems?.map(x => ({ type: 'operatingSystems', code: x, excluded: true})) || [],
        ...this.selectedLocations?.exclusions?.isps?.map(x => ({ type: 'isps', code: x, excluded: true})) || [] ,
    ] as DumbFilter[];
    this.selectedFilters = this.selectedLocations;
    this.loadFilters();  
    this.selectedLocationsChange.emit(this.selectedLocations)
    console.log(this.selectedLocations)

  }


   trackByFn(item: DumbFilter) {
    return item.code;
   }

    private loadFilters() {
        this.filter$ = concat(
            of(this.selectedLocations), // default items
            this.filterInput$.pipe(
                distinctUntilChanged(),
                tap(() => this.filterLoading = true),
                switchMap(term => this.filterService.getFilters(term).pipe(
                    catchError(() => of([])), // empty list on error
                    tap(() => this.filterLoading = false)
                ))
            )
        );
    }

  ngOnDestroy() {
    this.subscriptions = [];
  }


}
