import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NodesService {

  constructor(private http: HttpClient) { }

  async getAllNodes(args:any = {}){
    let url = `${environment.apiUrl}/nodes/connected?`;
    let a = [];
    let argList = ['p','l','q','o','d'];
    for(let arg of argList){
      if(args[arg]){
        a.push(arg + '=' + encodeURIComponent(args[arg]));
      }
    }
    url = url + a.join("&");
    console.log(url);
    const n = await this.http.get(url).toPromise();
    return n;
  }

  async getNodeVersions(){
    return await this.http.get(`${environment.apiUrl}/nodes/versions`).toPromise();
  }

  async getNodes(args:any = {}){
    let url = `${environment.apiUrl}/nodes`;
    const n = await this.http.get<{results:any[]}>(url).toPromise();
    return n;
  }

  async getMoneyFlowPerRegion() {
    const moneyFlow = await this.http.get<{ region: string, price_per_hour: number }[]>('https://api.bitping.com/explorer/api/moneyflow/regional').toPromise();
    return moneyFlow;
  }
  
  async getMoneyFlowPerHour() {
    const moneyFlow = await this.http.get<{ price_per_hour: number }>('https://api.bitping.com/explorer/api/moneyflow').toPromise();
    return moneyFlow;
  }

  async getNodeMap(){
    const url = `${environment.apiUrl}/nodes/countries`;
    const n = await this.http.get(url).toPromise();
    return n;
  }
}
