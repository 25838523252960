import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from 'src/app/_services/modal.service';
import { UserService } from 'src/app/_services/user.service';
import { AuthenticationService } from 'src/app/_services/auth.service';

@Component({
  selector: 'remove-otp',
  templateUrl: './remove-mfa.component.pug',
  styleUrls: ['./remove-mfa.component.scss']
})

export class RemoveMfaComponent implements OnInit, AfterViewInit, OnDestroy {

  data;
  error;
  loading;

  private getData:Subscription;

  constructor(
    private user: UserService,
    private auth: AuthenticationService,
    public modal: ModalService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  mfaError = (error: string) => {
    this.error = error;
    this.loading = false;
  }

  mfaUpdate = (error: string) => {
    this.error = "";
    this.loading = false;
  }

  removeMfa = async(code: string) => {
    try {
      this.loading = true;
      await this.user.removeMfa(code)
      await this.auth.refresh();
      this.modal.close();
    } catch(e) {
      throw new Error(e.error.message || "SOMETHING_WENT_WRONG")
    }
  }


}
