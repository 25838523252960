import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'monitor-dns-chart',
  templateUrl: './monitor-dns-chart.component.pug',
  styleUrls: ['./monitor-dns-chart.component.scss']
})
export class MonitorDnsChartComponent implements OnInit {
  private _dns;
  @Input() set dns(value) {
    if (value) {
      this._dns = this.processDNS(value)
    };
  }
  selected = {
    a: '',
    c: '',
    n: '',
    isp: ''
  }

  selectedTab = 'a';

  dnsLoader = [1,2,3,4,5,6,7,8];

  get dns() : any {
    return this._dns;
  }

  processDNS(dns) {
    const a = this.processRecord(dns.a);
    const c = this.processRecord(dns.c);
    const n = this.processRecord(dns.n);
    // console.log(dns);
    return {a,c,n};
  }

  processRecord(rec) {
    let a = {};
    let max = 0;
    Object.values(rec).forEach(da => {
      Object.values(da).forEach(v => {
        let r = ['null'];
        if(v.records){
          r = v.records.replace(/[\[\]\\\'\"]/g,'').split(",").map(s => s.trim());
        }
        r.forEach(r1 => {
          if(!a[r1]){
            a[r1] = {
              count: v.count*1,
              countries: {
                [v.country_code]: v.count*1
              }
            }
          } else {
            a[r1].count = a[r1].count + v.count*1;
            if(a[r1].countries[v.country_code]){
              a[r1].countries[v.country_code]+=v.count*1;
            } else {
              a[r1].countries[v.country_code] = v.count*1;
            }
          }
        });
      });
    });
    Object.keys(a).forEach(k => {
      max = a[k].count > max ? a[k] .count : max;
    })

    let ordered = {};

    Object.keys(a).sort((xa, xb) => { return a[xa].count > a[xb].count ? 1 : a[xa].count == a[xb].count ? 0 : -1 } ).forEach((k) => {
      max = a[k].count > max ? a[k] .count : max;
      ordered[k] = a[k];
    });
    //   return {label:k, ...a[k]}
    // })
    // .map(k => {
    //   let k1 = k;
    //   max = k1.count > max ? k1.count : max;
    //   k1.countries = Object.keys(k1.countries).map(l => {
    //     return {
    //       country: l,
    //       count: k1.countries[l]
    //     }
    //   });
    //   return k1;
    // });
    return {
      results: ordered,
      max
    }
  }

  constructor() { }

  ngOnInit() {

  }
}
