import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../_services/user.service';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.pug',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {

  currentUser:any;
  settingsPage = 'account';
  preferences;
  optionsSub;
  pageOpen = false;
  hasUpdated = false;

  constructor(public auth: AuthenticationService, public storage: StorageService) { }

  ngOnInit() {
    this.optionsSub = this.storage.options.subscribe(options => {
      if(options.settingsPane && !this.hasUpdated){
        this.updatePaneView("account");
      }
    });
  }

  updatePaneView(s){
    this.pageOpen = true;
    this.hasUpdated = true;
    this.storage.updateOptions({settingsPane: s});
    
    
  }

}

