import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { points } from "../../../assets/three/points";
import { SphereBufferGeometry, MeshBasicMaterial, MeshPhongMaterial, MeshStandardMaterial, SphereGeometry, Mesh, HemisphereLight, MeshLambertMaterial, FrontSide, Scene, PerspectiveCamera, WebGLRenderer, BufferGeometry } from 'three';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { NodeData } from '../../_models/AlexaOutages';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'globe',
  templateUrl: './globe.component.pug',
  styleUrls: ['./globe.component.scss']
})
export class GlobeComponent implements AfterViewInit {

  
  private _nodes: NodeData[];
  addedNodes = false;
  pointMaterial: any;
  sphereMaterial: any;
  spotLight: any;
  nodesMaterial: any;
  @Input() set nodes(value: NodeData[]) {
    this._nodes = value;
    this.buildNodes(value);
    if(!this.addedNodes && !!this.nodeShape){
      this.scene.add(this.nodeShape);
      this.addedNodes = true;
    } else {
      this.scene.remove(this.scene.getObjectByName("nodeShape"));
      this.scene.add(this.nodeShape);
    }
  }

  get nodes(): NodeData[] {
    return this._nodes;
  }
  @ViewChild('globeContainer', { static: true }) globeContainer: ElementRef;

  globeRadius = 80;
  globeWidth = 4098 / 2;
  globeHeight = 1968 / 2;

  renderer = new WebGLRenderer({ alpha: true, antialias: false });
  scene;
  camera;
  mesh;
  canvas;
  nodeShape: Mesh;
  isDark: boolean;
  optionsSub;

  constructor (public storage: StorageService) {
    this.scene = new Scene();
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
    this.camera = new PerspectiveCamera(40, 1, 0.1, 10000);
    this.camera.position.z = 1000;
    if(this.isDark) {
    this.spotLight = new HemisphereLight(0x7c86a1, 0x7c86a1, 1);
    } else {
      this.spotLight = new HemisphereLight(0xCAC8CD, 0xCAC8CD, 1);
    }
    this.spotLight.position.set(0, 100, 200);
    this.scene.add(this.spotLight);
  }

  ngAfterViewInit() {
    this.canvas = this.renderer.domElement;
    this.canvas.id = "globeCanvas";
    this.globeContainer.nativeElement.appendChild(this.renderer.domElement);
    this.camera.orbitControls = new OrbitControls(this.camera, this.canvas);
    // this.camera.orbitControls.enablePan = true;
    this.camera.orbitControls.enableZoom = false;
    this.camera.orbitControls.enableRotate = true;
    this.camera.orbitControls.autoRotate = true;
    this.camera.orbitControls.enablePan = false;
    this.camera.position.z = -265;

    this.init();
    this.animate();

  }

  animate() {
    window.requestAnimationFrame(() => this.animate());
    this.resizeCanvasToDisplaySize();
    this.camera.orbitControls.update();
    this.renderer.render(this.scene, this.camera);
  }

  resizeCanvasToDisplaySize() {
    const width = this.globeContainer.nativeElement.clientWidth;
    const height = this.globeContainer.nativeElement.clientHeight;
    const ratio = Math.min(width, height);
    const canvas = this.renderer.domElement;
    // const width = 500; //canvas.clientWidth;
    // const height = 500; //canvas.clientHeight;
    if (canvas.width !== ratio ||canvas.height !== ratio) {
      this.renderer.setSize(ratio, ratio, false);
      this.camera.aspect = 1;
      this.camera.updateProjectionMatrix();
    }
  }


  init() {

    let geometries = [];
  
    this.pointMaterial = new MeshLambertMaterial({
      color: 0xffffff,
      side: FrontSide,
      });


    points.forEach(point => {
      const { x, y, z } = this.convertFlatCoordsToSphereCoords(
        point.x,
        point.y
      );
      let pointGeometry = new SphereBufferGeometry(0.5, 1, 1);
      pointGeometry.translate(x, y, z);
      geometries.push(pointGeometry);
    });
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);

    const sphereGeometry = new SphereGeometry( this.globeRadius - 1, this.globeWidth - 10, this.globeHeight - 10);
    if(this.isDark) {
    this.sphereMaterial = new MeshStandardMaterial( {color: 0x15181c, opacity: 0.8, transparent: true});
    } else {
    this.sphereMaterial = new MeshBasicMaterial( {color: 0xFDFDFD, opacity: 0.85, transparent: true});  
    }
    const sphereGlobe = new Mesh( sphereGeometry, this.sphereMaterial);
    const globeShape = new Mesh(mergedGeometry, this.pointMaterial);
    this.scene.add(globeShape, sphereGlobe);
  }

  buildNodes(points:NodeData[]) {
    if(!points) return;
    let geometries = [];
    if(this.isDark) {
    this.nodesMaterial = new MeshLambertMaterial({
        color: 0x2d6fff,
        emissive: 0x2d6fff,
        reflectivity: 1,
        side: FrontSide,
  
    });
    } else {
      this.nodesMaterial = new MeshBasicMaterial({
        color: 0x007EFF,
        reflectivity: 1.2,
        side: FrontSide,
  
    });
    }
  
    points.forEach(point => {
      const { x, y, z } = this.convertFlatCoordsToSphereCoords(
        (point.lon+180)/360*this.globeWidth*2,
        ((-1*point.lat)+90)/180*this.globeHeight*2
      );
      let pointGeometry = new SphereBufferGeometry(1, 5, 5);
      pointGeometry.translate(x, y, z);
      geometries.push(pointGeometry);
    });
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);

    let nodeShape = new Mesh(mergedGeometry, this.nodesMaterial);
    nodeShape.name = "nodeShape";
    this.nodeShape = nodeShape;
  }

  convertFlatCoordsToSphereCoords(x, y) {
    let latitude = ((x - this.globeWidth) / this.globeWidth) * -180;
    let longitude = ((y - this.globeHeight) / this.globeHeight) * -90;
    latitude = (latitude * Math.PI) / 180;
    longitude = (longitude * Math.PI) / 180;
    const radius = Math.cos(longitude) * this.globeRadius;

    return {
      x: Math.cos(latitude) * radius,
      y: Math.sin(longitude) * this.globeRadius,
      z: Math.sin(latitude) * radius
    };
  }
}
