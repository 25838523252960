import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from 'src/app/_services/modal.service';
import * as shape from 'd3-shape';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-preference-wizard',
  templateUrl: './preference-wizard.component.pug',
  styleUrls: ['./preference-wizard.component.scss']
})
export class PreferenceWizardComponent implements OnInit {

  selectedLanguage = "en";
  stepcounter: [1,2,3]
  darkToggleState = false;
  isLoaded;
  accountType = "hybrid";
  modalComplete = false;
  step = 1;
  languages = [
    {
      id: 'en',
      flag: 'gb',
      name: 'English'
    },
    {
      id: 'ja',
      flag: 'jp',
      name: '日本語'
    },
    {
      id: 'ko',
      flag: 'kr',
      name: '한국어'
    },
    {
      id: 'zh-CN',
      flag: 'cn',
      name: '簡體中文'
    },
    {
      id: 'zh-HK',
      flag: 'hk',
      name: '繁體中文'
    }
  ];

  constructor(
    public modal: ModalService, private storageService: StorageService, private http: HttpClient
  ) {
  }

  async updateProperty(property: string, value: any) {
    this[property] = value;
    this.storageService.updateOptions({ [property]: value });
  }

  async postPreferences() {
    const p = await this.http.post(`${environment.apiUrl}/v2/user/preferences`, {darkMode : this.darkToggleState, accountType: this.accountType, language: this.selectedLanguage, modalComplete: true}).toPromise();
    console.log("Saved preferences:", p)

    this.storageService.updateOptions({darkToggleState: this.darkToggleState, accountType: this.accountType, selectedLanguage: this.selectedLanguage, modalComplete: true})
    return p;
  }

  dismissModal() {
    this.modal.close();
    this.modalComplete = true;
    this.storageService.updateOptions({ modalDismissed: true, modalComplete: true })
  }

  ngOnInit() {
    this.selectedLanguage = this.storageService.optionsValue.selectedLanguage || "en";
    this.darkToggleState = this.storageService.optionsValue.darkToggleState || false;
    this.accountType = this.storageService.optionsValue.accountType || "hybrid";
    this.modalComplete = this.storageService.optionsValue.modalComplete || false;
  }
}
