import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../../_services/modal.service';
import { UserService } from '../../../_services/user.service';
import {loadStripe, Stripe} from '@stripe/stripe-js';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.pug',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  constructor(public modal: ModalService, private user: UserService) {
   }

   async onPlanClicked(planName: string) {
    const stripe =  await loadStripe(environment.stripePublishKey);
    const session = await this.user.getStripeCheckoutID(planName);
    stripe.redirectToCheckout({sessionId: session.id })
  }

  ngOnInit() {
  }

}
