import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project.service';
import { UserService } from 'src/app/_services/user.service';
import * as dayjs from 'dayjs'
  import utc from 'dayjs/plugin/utc';
  import timezone from 'dayjs/plugin/timezone';

  dayjs.extend(utc);
  dayjs.extend(timezone);
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.pug',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  
  routeSub;
  selectedDate;
  project;
  setTime;
  reports;
  selectedId;
  userProjects;
  currentUser;
  selectedProject;
  min = new Date();
  selectedReportType = "excel_summary"
  selectedTime;
  selectedFrequency;
  dropDownOpen = {
    timeframe: false,
    project: false,
    recurring: false,
  };

  dataTimeframe = [
  {
    value: "1 day",
    label: "24 hours"
  },
  {
    value: "7 days",
    label: "7 days"
  },
  {
    value: "1 month",
    label: "1 month"
  },
  {
    value: "3 months",
    label: "3 months"
  },
  {
    value: "6 months",
    label: "6 months"
  },
  {
    value: "1 year",
    label: "1 year"
  },
]

  sendFrequencies = [{
    value: "1 days",
    label: "Daily"
  },
  {
    value: "1 week",
    label: "Weekly"
  },
  {
    value: "1 month",
    label: "Monthly"
  },
]
  parsedFrequency;
  nextSendDate;
  scheduleSubmitted = false;
  downloadSubmitted = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectService: ProjectService,
    private userService: UserService,
  ) { 
    this.getCurrentUser();

}

    async getCurrentUser() {
      const user: any = await this.userService.me();
      this.currentUser = user.email;
    }

    setReportType(type) {
      this.selectedReportType = type;
    }

    setSelectedProject(project) {
      this.selectedProject = project;
    }

    setSelectedTimeFrame(frame) {
      this.selectedTime = frame;
    }

    setSelectedFrequency(frequency) {
      this.selectedFrequency = frequency;
      this.parsedFrequency = this.selectedFrequency.value * 86400000;
    }

    async scheduledReports() {
      const reports = await this.projectService.getScheduledReports();
      this.reports = reports
    }

    async deleteReport(id) {
      await this.projectService.deleteScheduledReport(id);
      this.scheduledReports();
    }

    projectName(project) {
      const userProjects = this.userProjects;
      for (let i = 0; i < userProjects.length; i++) {
        if (userProjects[i].id === project) {
          return userProjects[i].name;
        }
      }
      return null; // return null if no matching object is found
    }

    ngOnInit() {

      this.scheduledReports()
      this.route.params.subscribe(params => {
        this.selectedId = params.project;
      });
      this.projectService.projects.subscribe(projects => {
        this.userProjects = projects.map(project => {return project})
        this.selectedProject = this.userProjects[0];
      })
      this.selectedTime = this.dataTimeframe[0]
      this.selectedFrequency = this.sendFrequencies[1];
    }
    
    
    async downloadOneOffReport(){
      // console.log((`${this.selectedProject.name} ` + dayjs(new Date()).format('DD_MM_YYYY') + ` ${this.selectedTime.label} ` + 'data' + '.xlsx').split(" ").join("_"))
      this.downloadSubmitted = true;

      if(this.selectedReportType === 'excel_summary') {
        await this.projectService.downloadExcelSummary(this.selectedTime.value, this.selectedProject.id, this.selectedProject.name);
        this.downloadSubmitted = false;
      }
      if(this.selectedReportType === 'excel_raw') {
        await this.projectService.downloadExcelRaw(this.selectedTime.value, this.selectedProject.id, this.selectedProject.name);
        this.downloadSubmitted = false;
      }
      if(this.selectedReportType === 'pdf_summary') {
        await this.projectService.getProjectReportPDF(this.selectedTime.value, this.selectedProject.id, this.selectedProject.name);
        this.downloadSubmitted = false;
      }
      if(this.selectedReportType === 'pdf_breakdown') {
        await this.projectService.getProjectBreakdownReportPDF(this.selectedTime.value, this.selectedProject.id, this.selectedProject.name);
        this.downloadSubmitted = false;
      }
    }

    async createScheduledReport() {
      this.scheduleSubmitted = true;
      const date = this.selectedDate;
      const parsedDate = dayjs(date)
      const isoString = parsedDate.utc().format('YYYY-MM-DDTHH:mm:ssZ');
      this.nextSendDate = isoString;
      await this.projectService.createScheduledReport(this.currentUser, this.selectedProject.id, this.selectedTime.value, this.selectedReportType, this.selectedFrequency.value, this.nextSendDate);
      this.scheduleSubmitted = false;
      this.scheduledReports();
  }

}
