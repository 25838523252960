import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project.service';
import { FormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../../../_services/user.service';
import { ModalService } from 'src/app/_services/modal.service';



@Component({
  selector: 'app-monitor-notifications',
  templateUrl: './monitor-notifications.component.pug',
  styleUrls: ['./monitor-notifications.component.scss']
})
export class MonitorNotificationsComponent implements OnInit {
  selectedId;
  selectedMonitor;
  monitorNotifications;
  webhookForm;
  requestObject;
  myVar;
  deleteWebHoook;

  sub;
  monitorSub;
  monitor;


  notificationSettings = [];
  projectName: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    public modal: ModalService
    )
    {

    }

  ngOnInit() {

    this.getMonitorNotifications(this.route.params['_value'].project, this.route.params['_value'].id);

    this.sub = this.route.params.subscribe(params => {
      this.selectedId = params.project;
      this.selectedMonitor = params.id;
      this.monitorSub = this.projectService.projects.subscribe(projects => {
        const project = projects.find(project => project.id == this.selectedId);
        if(project){
          this.projectName = project.name;
          const m = project.monitors.find(m2 => m2.id == this.selectedMonitor);
          this.monitor = {
            id: m.id,
            endpoint: m.endpoint,
            name: m.name,
            regions: m.regions,
            frequency: m.frequency,
            status: m.status,
            colour: { domain: [m.colour ]},
            type: m.type,
            loaded: false,
            jobs: [],
            stats: {},
            data: []
          }
        }
      });
    });

    this.webhookForm = this.formBuilder.group({
      name: ["", Validators.required],
      monitorId: [this.route.params['_value'].id, Validators.required],
      type: ["", Validators.required],
      configValue: ["", Validators.required]
    });

    console.log("id:", this.selectedId)
  }

  async getMonitorNotifications(selectedId, selectedMonitor){
    // console.log(selectedId, selectedMonitor)
    let monitorNotifications = await this.projectService.getNotificationSettings(selectedId, selectedMonitor);
    this.notificationSettings = monitorNotifications
    // console.log(this.monitorNotifications)
  }


  iconFunction(type) {
    if (type === "Email") {
      return 'mail'
    }
    else if (type === "Generic_Webhook"){
      return 'api'
    }
    else if (type === "Slack") {
      return 'slack'
    }
  }

  createWebHook = async() => {
    try {
      this.requestObject = {
        name: this.m.name.value,
        type: this.m.type.value,
        monitorId: this.route.params['_value'].id,
        recipient: this.m.type.value === "Email" ?this.m.configValue.value : undefined,
        endpoint: ["Slack", "GenericWebhook"].indexOf(this.m.type.value) > -1 ?this.m.configValue.value : undefined,
      };

      // console.log(this.requestObject);
      let n = await this.userService.createNewNotificationWebHook(this.requestObject);
      this.getMonitorNotifications(this.route.params['_value'].project, this.route.params['_value'].id);
    } catch(e){
      console.log(e);
    }
  }

  // async getMonitorNotificationSettings(){
  //   let notificationSettings = await this.projectService.getNotificationSettings();
  //   this.notificationSettings = notificationSettings
  // }

  get m() { return this.webhookForm.controls; }

}

