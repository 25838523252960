export const points = [
  {
    x: 1.5,
    y: 2046.5
  },
  {
    x: 1.5,
    y: 2031.5
  },
  {
    x: 1.5,
    y: 2016.5
  },
  {
    x: 1.5,
    y: 2001.5
  },
  {
    x: 1.5,
    y: 1986.5
  },
  {
    x: 1.5,
    y: 276.5
  },
  {
    x: 1.5,
    y: 261.5
  },
  {
    x: 1.5,
    y: 246.5
  },
  {
    x: 1.5,
    y: 216.5
  },
  {
    x: 16.5,
    y: 2046.5
  },
  {
    x: 16.5,
    y: 2031.5
  },
  {
    x: 16.5,
    y: 2016.5
  },
  {
    x: 16.5,
    y: 2001.5
  },
  {
    x: 16.5,
    y: 1986.5
  },
  {
    x: 16.5,
    y: 276.5
  },
  {
    x: 16.5,
    y: 261.5
  },
  {
    x: 16.5,
    y: 246.5
  },
  {
    x: 16.5,
    y: 216.5
  },
  {
    x: 31.5,
    y: 2046.5
  },
  {
    x: 31.5,
    y: 2031.5
  },
  {
    x: 31.5,
    y: 2016.5
  },
  {
    x: 31.5,
    y: 2001.5
  },
  {
    x: 31.5,
    y: 1986.5
  },
  {
    x: 31.5,
    y: 276.5
  },
  {
    x: 31.5,
    y: 261.5
  },
  {
    x: 46.5,
    y: 2046.5
  },
  {
    x: 46.5,
    y: 2031.5
  },
  {
    x: 46.5,
    y: 2016.5
  },
  {
    x: 46.5,
    y: 2001.5
  },
  {
    x: 46.5,
    y: 1986.5
  },
  {
    x: 46.5,
    y: 276.5
  },
  {
    x: 46.5,
    y: 261.5
  },
  {
    x: 61.5,
    y: 2046.5
  },
  {
    x: 61.5,
    y: 2031.5
  },
  {
    x: 61.5,
    y: 2016.5
  },
  {
    x: 61.5,
    y: 2001.5
  },
  {
    x: 61.5,
    y: 1986.5
  },
  {
    x: 61.5,
    y: 426.5
  },
  {
    x: 61.5,
    y: 276.5
  },
  {
    x: 61.5,
    y: 261.5
  },
  {
    x: 76.5,
    y: 2046.5
  },
  {
    x: 76.5,
    y: 2031.5
  },
  {
    x: 76.5,
    y: 2016.5
  },
  {
    x: 76.5,
    y: 2001.5
  },
  {
    x: 76.5,
    y: 1986.5
  },
  {
    x: 76.5,
    y: 291.5
  },
  {
    x: 76.5,
    y: 276.5
  },
  {
    x: 76.5,
    y: 261.5
  },
  {
    x: 91.5,
    y: 2046.5
  },
  {
    x: 91.5,
    y: 2031.5
  },
  {
    x: 91.5,
    y: 2016.5
  },
  {
    x: 91.5,
    y: 2001.5
  },
  {
    x: 91.5,
    y: 1986.5
  },
  {
    x: 91.5,
    y: 336.5
  },
  {
    x: 91.5,
    y: 276.5
  },
  {
    x: 91.5,
    y: 261.5
  },
  {
    x: 106.5,
    y: 2046.5
  },
  {
    x: 106.5,
    y: 2031.5
  },
  {
    x: 106.5,
    y: 2016.5
  },
  {
    x: 106.5,
    y: 2001.5
  },
  {
    x: 106.5,
    y: 1986.5
  },
  {
    x: 106.5,
    y: 1971.5
  },
  {
    x: 106.5,
    y: 276.5
  },
  {
    x: 121.5,
    y: 2046.5
  },
  {
    x: 121.5,
    y: 2031.5
  },
  {
    x: 121.5,
    y: 2016.5
  },
  {
    x: 121.5,
    y: 2001.5
  },
  {
    x: 121.5,
    y: 1986.5
  },
  {
    x: 121.5,
    y: 1971.5
  },
  {
    x: 121.5,
    y: 306.5
  },
  {
    x: 136.5,
    y: 2046.5
  },
  {
    x: 136.5,
    y: 2031.5
  },
  {
    x: 136.5,
    y: 2016.5
  },
  {
    x: 136.5,
    y: 2001.5
  },
  {
    x: 136.5,
    y: 1971.5
  },
  {
    x: 136.5,
    y: 276.5
  },
  {
    x: 151.5,
    y: 2046.5
  },
  {
    x: 151.5,
    y: 2031.5
  },
  {
    x: 151.5,
    y: 2016.5
  },
  {
    x: 151.5,
    y: 2001.5
  },
  {
    x: 151.5,
    y: 1971.5
  },
  {
    x: 151.5,
    y: 411.5
  },
  {
    x: 151.5,
    y: 336.5
  },
  {
    x: 151.5,
    y: 276.5
  },
  {
    x: 166.5,
    y: 2046.5
  },
  {
    x: 166.5,
    y: 2031.5
  },
  {
    x: 166.5,
    y: 2016.5
  },
  {
    x: 166.5,
    y: 2001.5
  },
  {
    x: 166.5,
    y: 1971.5
  },
  {
    x: 166.5,
    y: 321.5
  },
  {
    x: 166.5,
    y: 276.5
  },
  {
    x: 166.5,
    y: 246.5
  },
  {
    x: 181.5,
    y: 2046.5
  },
  {
    x: 181.5,
    y: 2031.5
  },
  {
    x: 181.5,
    y: 2016.5
  },
  {
    x: 181.5,
    y: 2001.5
  },
  {
    x: 181.5,
    y: 1971.5
  },
  {
    x: 181.5,
    y: 1926.5
  },
  {
    x: 181.5,
    y: 336.5
  },
  {
    x: 181.5,
    y: 321.5
  },
  {
    x: 181.5,
    y: 306.5
  },
  {
    x: 181.5,
    y: 276.5
  },
  {
    x: 181.5,
    y: 246.5
  },
  {
    x: 196.5,
    y: 2046.5
  },
  {
    x: 196.5,
    y: 2031.5
  },
  {
    x: 196.5,
    y: 2016.5
  },
  {
    x: 196.5,
    y: 2001.5
  },
  {
    x: 196.5,
    y: 1986.5
  },
  {
    x: 196.5,
    y: 1971.5
  },
  {
    x: 196.5,
    y: 1926.5
  },
  {
    x: 196.5,
    y: 396.5
  },
  {
    x: 196.5,
    y: 336.5
  },
  {
    x: 196.5,
    y: 321.5
  },
  {
    x: 196.5,
    y: 306.5
  },
  {
    x: 196.5,
    y: 291.5
  },
  {
    x: 196.5,
    y: 276.5
  },
  {
    x: 196.5,
    y: 261.5
  },
  {
    x: 196.5,
    y: 246.5
  },
  {
    x: 196.5,
    y: 231.5
  },
  {
    x: 211.5,
    y: 2046.5
  },
  {
    x: 211.5,
    y: 2031.5
  },
  {
    x: 211.5,
    y: 2016.5
  },
  {
    x: 211.5,
    y: 2001.5
  },
  {
    x: 211.5,
    y: 1986.5
  },
  {
    x: 211.5,
    y: 1971.5
  },
  {
    x: 211.5,
    y: 1926.5
  },
  {
    x: 211.5,
    y: 351.5
  },
  {
    x: 211.5,
    y: 336.5
  },
  {
    x: 211.5,
    y: 321.5
  },
  {
    x: 211.5,
    y: 306.5
  },
  {
    x: 211.5,
    y: 291.5
  },
  {
    x: 211.5,
    y: 276.5
  },
  {
    x: 211.5,
    y: 261.5
  },
  {
    x: 211.5,
    y: 246.5
  },
  {
    x: 211.5,
    y: 231.5
  },
  {
    x: 226.5,
    y: 2046.5
  },
  {
    x: 226.5,
    y: 2031.5
  },
  {
    x: 226.5,
    y: 2016.5
  },
  {
    x: 226.5,
    y: 2001.5
  },
  {
    x: 226.5,
    y: 1986.5
  },
  {
    x: 226.5,
    y: 1971.5
  },
  {
    x: 226.5,
    y: 1926.5
  },
  {
    x: 226.5,
    y: 381.5
  },
  {
    x: 226.5,
    y: 351.5
  },
  {
    x: 226.5,
    y: 336.5
  },
  {
    x: 226.5,
    y: 321.5
  },
  {
    x: 226.5,
    y: 306.5
  },
  {
    x: 226.5,
    y: 291.5
  },
  {
    x: 226.5,
    y: 276.5
  },
  {
    x: 226.5,
    y: 261.5
  },
  {
    x: 226.5,
    y: 246.5
  },
  {
    x: 226.5,
    y: 231.5
  },
  {
    x: 241.5,
    y: 2046.5
  },
  {
    x: 241.5,
    y: 2031.5
  },
  {
    x: 241.5,
    y: 2016.5
  },
  {
    x: 241.5,
    y: 2001.5
  },
  {
    x: 241.5,
    y: 1986.5
  },
  {
    x: 241.5,
    y: 1971.5
  },
  {
    x: 241.5,
    y: 1956.5
  },
  {
    x: 241.5,
    y: 381.5
  },
  {
    x: 241.5,
    y: 351.5
  },
  {
    x: 241.5,
    y: 336.5
  },
  {
    x: 241.5,
    y: 321.5
  },
  {
    x: 241.5,
    y: 306.5
  },
  {
    x: 241.5,
    y: 291.5
  },
  {
    x: 241.5,
    y: 276.5
  },
  {
    x: 241.5,
    y: 261.5
  },
  {
    x: 241.5,
    y: 246.5
  },
  {
    x: 241.5,
    y: 231.5
  },
  {
    x: 256.5,
    y: 2046.5
  },
  {
    x: 256.5,
    y: 2031.5
  },
  {
    x: 256.5,
    y: 2016.5
  },
  {
    x: 256.5,
    y: 2001.5
  },
  {
    x: 256.5,
    y: 1986.5
  },
  {
    x: 256.5,
    y: 1971.5
  },
  {
    x: 256.5,
    y: 1911.5
  },
  {
    x: 256.5,
    y: 366.5
  },
  {
    x: 256.5,
    y: 351.5
  },
  {
    x: 256.5,
    y: 336.5
  },
  {
    x: 256.5,
    y: 321.5
  },
  {
    x: 256.5,
    y: 306.5
  },
  {
    x: 256.5,
    y: 291.5
  },
  {
    x: 256.5,
    y: 276.5
  },
  {
    x: 256.5,
    y: 261.5
  },
  {
    x: 256.5,
    y: 246.5
  },
  {
    x: 256.5,
    y: 231.5
  },
  {
    x: 256.5,
    y: 216.5
  },
  {
    x: 271.5,
    y: 2046.5
  },
  {
    x: 271.5,
    y: 2031.5
  },
  {
    x: 271.5,
    y: 2016.5
  },
  {
    x: 271.5,
    y: 2001.5
  },
  {
    x: 271.5,
    y: 1986.5
  },
  {
    x: 271.5,
    y: 1971.5
  },
  {
    x: 271.5,
    y: 1911.5
  },
  {
    x: 271.5,
    y: 366.5
  },
  {
    x: 271.5,
    y: 351.5
  },
  {
    x: 271.5,
    y: 336.5
  },
  {
    x: 271.5,
    y: 321.5
  },
  {
    x: 271.5,
    y: 306.5
  },
  {
    x: 271.5,
    y: 291.5
  },
  {
    x: 271.5,
    y: 276.5
  },
  {
    x: 271.5,
    y: 261.5
  },
  {
    x: 271.5,
    y: 246.5
  },
  {
    x: 271.5,
    y: 231.5
  },
  {
    x: 271.5,
    y: 216.5
  },
  {
    x: 286.5,
    y: 2046.5
  },
  {
    x: 286.5,
    y: 2031.5
  },
  {
    x: 286.5,
    y: 2016.5
  },
  {
    x: 286.5,
    y: 2001.5
  },
  {
    x: 286.5,
    y: 1986.5
  },
  {
    x: 286.5,
    y: 1971.5
  },
  {
    x: 286.5,
    y: 1911.5
  },
  {
    x: 286.5,
    y: 351.5
  },
  {
    x: 286.5,
    y: 336.5
  },
  {
    x: 286.5,
    y: 321.5
  },
  {
    x: 286.5,
    y: 306.5
  },
  {
    x: 286.5,
    y: 291.5
  },
  {
    x: 286.5,
    y: 276.5
  },
  {
    x: 286.5,
    y: 261.5
  },
  {
    x: 286.5,
    y: 246.5
  },
  {
    x: 286.5,
    y: 231.5
  },
  {
    x: 286.5,
    y: 216.5
  },
  {
    x: 301.5,
    y: 2046.5
  },
  {
    x: 301.5,
    y: 2031.5
  },
  {
    x: 301.5,
    y: 2016.5
  },
  {
    x: 301.5,
    y: 2001.5
  },
  {
    x: 301.5,
    y: 1986.5
  },
  {
    x: 301.5,
    y: 1971.5
  },
  {
    x: 301.5,
    y: 1956.5
  },
  {
    x: 301.5,
    y: 1911.5
  },
  {
    x: 301.5,
    y: 366.5
  },
  {
    x: 301.5,
    y: 351.5
  },
  {
    x: 301.5,
    y: 336.5
  },
  {
    x: 301.5,
    y: 321.5
  },
  {
    x: 301.5,
    y: 306.5
  },
  {
    x: 301.5,
    y: 291.5
  },
  {
    x: 301.5,
    y: 276.5
  },
  {
    x: 301.5,
    y: 261.5
  },
  {
    x: 301.5,
    y: 246.5
  },
  {
    x: 301.5,
    y: 231.5
  },
  {
    x: 301.5,
    y: 216.5
  },
  {
    x: 316.5,
    y: 2046.5
  },
  {
    x: 316.5,
    y: 2031.5
  },
  {
    x: 316.5,
    y: 2016.5
  },
  {
    x: 316.5,
    y: 2001.5
  },
  {
    x: 316.5,
    y: 1986.5
  },
  {
    x: 316.5,
    y: 1971.5
  },
  {
    x: 316.5,
    y: 1956.5
  },
  {
    x: 316.5,
    y: 1911.5
  },
  {
    x: 316.5,
    y: 366.5
  },
  {
    x: 316.5,
    y: 336.5
  },
  {
    x: 316.5,
    y: 321.5
  },
  {
    x: 316.5,
    y: 306.5
  },
  {
    x: 316.5,
    y: 291.5
  },
  {
    x: 316.5,
    y: 276.5
  },
  {
    x: 316.5,
    y: 261.5
  },
  {
    x: 316.5,
    y: 246.5
  },
  {
    x: 316.5,
    y: 231.5
  },
  {
    x: 316.5,
    y: 216.5
  },
  {
    x: 331.5,
    y: 2046.5
  },
  {
    x: 331.5,
    y: 2031.5
  },
  {
    x: 331.5,
    y: 2016.5
  },
  {
    x: 331.5,
    y: 2001.5
  },
  {
    x: 331.5,
    y: 1986.5
  },
  {
    x: 331.5,
    y: 1971.5
  },
  {
    x: 331.5,
    y: 1956.5
  },
  {
    x: 331.5,
    y: 1926.5
  },
  {
    x: 331.5,
    y: 1911.5
  },
  {
    x: 331.5,
    y: 1896.5
  },
  {
    x: 331.5,
    y: 336.5
  },
  {
    x: 331.5,
    y: 321.5
  },
  {
    x: 331.5,
    y: 306.5
  },
  {
    x: 331.5,
    y: 291.5
  },
  {
    x: 331.5,
    y: 276.5
  },
  {
    x: 331.5,
    y: 261.5
  },
  {
    x: 331.5,
    y: 246.5
  },
  {
    x: 331.5,
    y: 231.5
  },
  {
    x: 346.5,
    y: 2046.5
  },
  {
    x: 346.5,
    y: 2031.5
  },
  {
    x: 346.5,
    y: 2016.5
  },
  {
    x: 346.5,
    y: 2001.5
  },
  {
    x: 346.5,
    y: 1986.5
  },
  {
    x: 346.5,
    y: 1971.5
  },
  {
    x: 346.5,
    y: 1956.5
  },
  {
    x: 346.5,
    y: 1926.5
  },
  {
    x: 346.5,
    y: 1911.5
  },
  {
    x: 346.5,
    y: 1896.5
  },
  {
    x: 346.5,
    y: 336.5
  },
  {
    x: 346.5,
    y: 321.5
  },
  {
    x: 346.5,
    y: 306.5
  },
  {
    x: 346.5,
    y: 291.5
  },
  {
    x: 346.5,
    y: 276.5
  },
  {
    x: 346.5,
    y: 261.5
  },
  {
    x: 346.5,
    y: 246.5
  },
  {
    x: 346.5,
    y: 231.5
  },
  {
    x: 361.5,
    y: 2046.5
  },
  {
    x: 361.5,
    y: 2031.5
  },
  {
    x: 361.5,
    y: 2016.5
  },
  {
    x: 361.5,
    y: 2001.5
  },
  {
    x: 361.5,
    y: 1986.5
  },
  {
    x: 361.5,
    y: 1971.5
  },
  {
    x: 361.5,
    y: 1956.5
  },
  {
    x: 361.5,
    y: 1941.5
  },
  {
    x: 361.5,
    y: 1926.5
  },
  {
    x: 361.5,
    y: 1911.5
  },
  {
    x: 361.5,
    y: 1896.5
  },
  {
    x: 361.5,
    y: 336.5
  },
  {
    x: 361.5,
    y: 321.5
  },
  {
    x: 361.5,
    y: 306.5
  },
  {
    x: 361.5,
    y: 291.5
  },
  {
    x: 361.5,
    y: 276.5
  },
  {
    x: 361.5,
    y: 261.5
  },
  {
    x: 361.5,
    y: 246.5
  },
  {
    x: 361.5,
    y: 231.5
  },
  {
    x: 376.5,
    y: 2046.5
  },
  {
    x: 376.5,
    y: 2031.5
  },
  {
    x: 376.5,
    y: 2016.5
  },
  {
    x: 376.5,
    y: 2001.5
  },
  {
    x: 376.5,
    y: 1986.5
  },
  {
    x: 376.5,
    y: 1971.5
  },
  {
    x: 376.5,
    y: 1956.5
  },
  {
    x: 376.5,
    y: 1941.5
  },
  {
    x: 376.5,
    y: 1926.5
  },
  {
    x: 376.5,
    y: 1911.5
  },
  {
    x: 376.5,
    y: 1896.5
  },
  {
    x: 376.5,
    y: 336.5
  },
  {
    x: 376.5,
    y: 321.5
  },
  {
    x: 376.5,
    y: 306.5
  },
  {
    x: 376.5,
    y: 291.5
  },
  {
    x: 376.5,
    y: 276.5
  },
  {
    x: 376.5,
    y: 261.5
  },
  {
    x: 376.5,
    y: 246.5
  },
  {
    x: 376.5,
    y: 231.5
  },
  {
    x: 391.5,
    y: 2046.5
  },
  {
    x: 391.5,
    y: 2031.5
  },
  {
    x: 391.5,
    y: 2016.5
  },
  {
    x: 391.5,
    y: 2001.5
  },
  {
    x: 391.5,
    y: 1986.5
  },
  {
    x: 391.5,
    y: 1971.5
  },
  {
    x: 391.5,
    y: 1956.5
  },
  {
    x: 391.5,
    y: 1941.5
  },
  {
    x: 391.5,
    y: 1926.5
  },
  {
    x: 391.5,
    y: 1911.5
  },
  {
    x: 391.5,
    y: 1896.5
  },
  {
    x: 391.5,
    y: 336.5
  },
  {
    x: 391.5,
    y: 321.5
  },
  {
    x: 391.5,
    y: 306.5
  },
  {
    x: 391.5,
    y: 291.5
  },
  {
    x: 391.5,
    y: 276.5
  },
  {
    x: 391.5,
    y: 261.5
  },
  {
    x: 391.5,
    y: 246.5
  },
  {
    x: 391.5,
    y: 231.5
  },
  {
    x: 406.5,
    y: 2046.5
  },
  {
    x: 406.5,
    y: 2031.5
  },
  {
    x: 406.5,
    y: 2016.5
  },
  {
    x: 406.5,
    y: 2001.5
  },
  {
    x: 406.5,
    y: 1986.5
  },
  {
    x: 406.5,
    y: 1971.5
  },
  {
    x: 406.5,
    y: 1956.5
  },
  {
    x: 406.5,
    y: 1941.5
  },
  {
    x: 406.5,
    y: 1926.5
  },
  {
    x: 406.5,
    y: 1911.5
  },
  {
    x: 406.5,
    y: 1896.5
  },
  {
    x: 406.5,
    y: 336.5
  },
  {
    x: 406.5,
    y: 321.5
  },
  {
    x: 406.5,
    y: 306.5
  },
  {
    x: 406.5,
    y: 291.5
  },
  {
    x: 406.5,
    y: 276.5
  },
  {
    x: 406.5,
    y: 261.5
  },
  {
    x: 406.5,
    y: 246.5
  },
  {
    x: 406.5,
    y: 231.5
  },
  {
    x: 421.5,
    y: 2046.5
  },
  {
    x: 421.5,
    y: 2031.5
  },
  {
    x: 421.5,
    y: 2016.5
  },
  {
    x: 421.5,
    y: 2001.5
  },
  {
    x: 421.5,
    y: 1986.5
  },
  {
    x: 421.5,
    y: 1971.5
  },
  {
    x: 421.5,
    y: 1956.5
  },
  {
    x: 421.5,
    y: 1941.5
  },
  {
    x: 421.5,
    y: 1926.5
  },
  {
    x: 421.5,
    y: 1911.5
  },
  {
    x: 421.5,
    y: 1896.5
  },
  {
    x: 421.5,
    y: 336.5
  },
  {
    x: 421.5,
    y: 321.5
  },
  {
    x: 421.5,
    y: 306.5
  },
  {
    x: 421.5,
    y: 291.5
  },
  {
    x: 421.5,
    y: 276.5
  },
  {
    x: 421.5,
    y: 261.5
  },
  {
    x: 421.5,
    y: 246.5
  },
  {
    x: 421.5,
    y: 231.5
  },
  {
    x: 436.5,
    y: 2046.5
  },
  {
    x: 436.5,
    y: 2031.5
  },
  {
    x: 436.5,
    y: 2016.5
  },
  {
    x: 436.5,
    y: 2001.5
  },
  {
    x: 436.5,
    y: 1986.5
  },
  {
    x: 436.5,
    y: 1971.5
  },
  {
    x: 436.5,
    y: 1956.5
  },
  {
    x: 436.5,
    y: 1941.5
  },
  {
    x: 436.5,
    y: 1926.5
  },
  {
    x: 436.5,
    y: 1911.5
  },
  {
    x: 436.5,
    y: 1896.5
  },
  {
    x: 436.5,
    y: 1881.5
  },
  {
    x: 436.5,
    y: 336.5
  },
  {
    x: 436.5,
    y: 321.5
  },
  {
    x: 436.5,
    y: 306.5
  },
  {
    x: 436.5,
    y: 291.5
  },
  {
    x: 436.5,
    y: 276.5
  },
  {
    x: 436.5,
    y: 261.5
  },
  {
    x: 436.5,
    y: 246.5
  },
  {
    x: 436.5,
    y: 231.5
  },
  {
    x: 451.5,
    y: 2046.5
  },
  {
    x: 451.5,
    y: 2031.5
  },
  {
    x: 451.5,
    y: 2016.5
  },
  {
    x: 451.5,
    y: 2001.5
  },
  {
    x: 451.5,
    y: 1986.5
  },
  {
    x: 451.5,
    y: 1971.5
  },
  {
    x: 451.5,
    y: 1956.5
  },
  {
    x: 451.5,
    y: 1941.5
  },
  {
    x: 451.5,
    y: 1926.5
  },
  {
    x: 451.5,
    y: 1911.5
  },
  {
    x: 451.5,
    y: 1896.5
  },
  {
    x: 451.5,
    y: 1881.5
  },
  {
    x: 451.5,
    y: 336.5
  },
  {
    x: 451.5,
    y: 321.5
  },
  {
    x: 451.5,
    y: 306.5
  },
  {
    x: 451.5,
    y: 291.5
  },
  {
    x: 451.5,
    y: 276.5
  },
  {
    x: 451.5,
    y: 261.5
  },
  {
    x: 451.5,
    y: 246.5
  },
  {
    x: 451.5,
    y: 231.5
  },
  {
    x: 466.5,
    y: 2046.5
  },
  {
    x: 466.5,
    y: 2031.5
  },
  {
    x: 466.5,
    y: 2016.5
  },
  {
    x: 466.5,
    y: 2001.5
  },
  {
    x: 466.5,
    y: 1986.5
  },
  {
    x: 466.5,
    y: 1971.5
  },
  {
    x: 466.5,
    y: 1956.5
  },
  {
    x: 466.5,
    y: 1941.5
  },
  {
    x: 466.5,
    y: 1926.5
  },
  {
    x: 466.5,
    y: 1911.5
  },
  {
    x: 466.5,
    y: 1896.5
  },
  {
    x: 466.5,
    y: 1881.5
  },
  {
    x: 466.5,
    y: 336.5
  },
  {
    x: 466.5,
    y: 321.5
  },
  {
    x: 466.5,
    y: 306.5
  },
  {
    x: 466.5,
    y: 291.5
  },
  {
    x: 466.5,
    y: 276.5
  },
  {
    x: 466.5,
    y: 261.5
  },
  {
    x: 466.5,
    y: 246.5
  },
  {
    x: 481.5,
    y: 2046.5
  },
  {
    x: 481.5,
    y: 2031.5
  },
  {
    x: 481.5,
    y: 2016.5
  },
  {
    x: 481.5,
    y: 2001.5
  },
  {
    x: 481.5,
    y: 1986.5
  },
  {
    x: 481.5,
    y: 1971.5
  },
  {
    x: 481.5,
    y: 1956.5
  },
  {
    x: 481.5,
    y: 1941.5
  },
  {
    x: 481.5,
    y: 1926.5
  },
  {
    x: 481.5,
    y: 1911.5
  },
  {
    x: 481.5,
    y: 1896.5
  },
  {
    x: 481.5,
    y: 1881.5
  },
  {
    x: 481.5,
    y: 351.5
  },
  {
    x: 481.5,
    y: 336.5
  },
  {
    x: 481.5,
    y: 321.5
  },
  {
    x: 481.5,
    y: 306.5
  },
  {
    x: 481.5,
    y: 291.5
  },
  {
    x: 481.5,
    y: 276.5
  },
  {
    x: 481.5,
    y: 261.5
  },
  {
    x: 481.5,
    y: 246.5
  },
  {
    x: 496.5,
    y: 2046.5
  },
  {
    x: 496.5,
    y: 2031.5
  },
  {
    x: 496.5,
    y: 2016.5
  },
  {
    x: 496.5,
    y: 2001.5
  },
  {
    x: 496.5,
    y: 1986.5
  },
  {
    x: 496.5,
    y: 1971.5
  },
  {
    x: 496.5,
    y: 1956.5
  },
  {
    x: 496.5,
    y: 1941.5
  },
  {
    x: 496.5,
    y: 1926.5
  },
  {
    x: 496.5,
    y: 1911.5
  },
  {
    x: 496.5,
    y: 1896.5
  },
  {
    x: 496.5,
    y: 1881.5
  },
  {
    x: 496.5,
    y: 366.5
  },
  {
    x: 496.5,
    y: 351.5
  },
  {
    x: 496.5,
    y: 336.5
  },
  {
    x: 496.5,
    y: 321.5
  },
  {
    x: 496.5,
    y: 306.5
  },
  {
    x: 496.5,
    y: 291.5
  },
  {
    x: 496.5,
    y: 276.5
  },
  {
    x: 496.5,
    y: 261.5
  },
  {
    x: 496.5,
    y: 246.5
  },
  {
    x: 511.5,
    y: 2046.5
  },
  {
    x: 511.5,
    y: 2031.5
  },
  {
    x: 511.5,
    y: 2016.5
  },
  {
    x: 511.5,
    y: 2001.5
  },
  {
    x: 511.5,
    y: 1986.5
  },
  {
    x: 511.5,
    y: 1971.5
  },
  {
    x: 511.5,
    y: 1956.5
  },
  {
    x: 511.5,
    y: 1941.5
  },
  {
    x: 511.5,
    y: 1926.5
  },
  {
    x: 511.5,
    y: 1911.5
  },
  {
    x: 511.5,
    y: 1896.5
  },
  {
    x: 511.5,
    y: 1881.5
  },
  {
    x: 511.5,
    y: 366.5
  },
  {
    x: 511.5,
    y: 351.5
  },
  {
    x: 511.5,
    y: 336.5
  },
  {
    x: 511.5,
    y: 321.5
  },
  {
    x: 511.5,
    y: 306.5
  },
  {
    x: 511.5,
    y: 291.5
  },
  {
    x: 511.5,
    y: 276.5
  },
  {
    x: 511.5,
    y: 261.5
  },
  {
    x: 511.5,
    y: 246.5
  },
  {
    x: 526.5,
    y: 2046.5
  },
  {
    x: 526.5,
    y: 2031.5
  },
  {
    x: 526.5,
    y: 2016.5
  },
  {
    x: 526.5,
    y: 2001.5
  },
  {
    x: 526.5,
    y: 1986.5
  },
  {
    x: 526.5,
    y: 1971.5
  },
  {
    x: 526.5,
    y: 1956.5
  },
  {
    x: 526.5,
    y: 1941.5
  },
  {
    x: 526.5,
    y: 1926.5
  },
  {
    x: 526.5,
    y: 1911.5
  },
  {
    x: 526.5,
    y: 1896.5
  },
  {
    x: 526.5,
    y: 1881.5
  },
  {
    x: 526.5,
    y: 396.5
  },
  {
    x: 526.5,
    y: 381.5
  },
  {
    x: 526.5,
    y: 366.5
  },
  {
    x: 526.5,
    y: 351.5
  },
  {
    x: 526.5,
    y: 336.5
  },
  {
    x: 526.5,
    y: 321.5
  },
  {
    x: 526.5,
    y: 306.5
  },
  {
    x: 526.5,
    y: 291.5
  },
  {
    x: 526.5,
    y: 276.5
  },
  {
    x: 526.5,
    y: 261.5
  },
  {
    x: 526.5,
    y: 246.5
  },
  {
    x: 541.5,
    y: 2046.5
  },
  {
    x: 541.5,
    y: 2031.5
  },
  {
    x: 541.5,
    y: 2016.5
  },
  {
    x: 541.5,
    y: 2001.5
  },
  {
    x: 541.5,
    y: 1986.5
  },
  {
    x: 541.5,
    y: 1971.5
  },
  {
    x: 541.5,
    y: 1956.5
  },
  {
    x: 541.5,
    y: 1941.5
  },
  {
    x: 541.5,
    y: 1926.5
  },
  {
    x: 541.5,
    y: 1911.5
  },
  {
    x: 541.5,
    y: 1896.5
  },
  {
    x: 541.5,
    y: 1881.5
  },
  {
    x: 541.5,
    y: 411.5
  },
  {
    x: 541.5,
    y: 396.5
  },
  {
    x: 541.5,
    y: 381.5
  },
  {
    x: 541.5,
    y: 366.5
  },
  {
    x: 541.5,
    y: 351.5
  },
  {
    x: 541.5,
    y: 336.5
  },
  {
    x: 541.5,
    y: 321.5
  },
  {
    x: 541.5,
    y: 306.5
  },
  {
    x: 541.5,
    y: 291.5
  },
  {
    x: 541.5,
    y: 276.5
  },
  {
    x: 541.5,
    y: 261.5
  },
  {
    x: 541.5,
    y: 246.5
  },
  {
    x: 541.5,
    y: 231.5
  },
  {
    x: 556.5,
    y: 2046.5
  },
  {
    x: 556.5,
    y: 2031.5
  },
  {
    x: 556.5,
    y: 2016.5
  },
  {
    x: 556.5,
    y: 2001.5
  },
  {
    x: 556.5,
    y: 1986.5
  },
  {
    x: 556.5,
    y: 1971.5
  },
  {
    x: 556.5,
    y: 1956.5
  },
  {
    x: 556.5,
    y: 1941.5
  },
  {
    x: 556.5,
    y: 1926.5
  },
  {
    x: 556.5,
    y: 1911.5
  },
  {
    x: 556.5,
    y: 1896.5
  },
  {
    x: 556.5,
    y: 1881.5
  },
  {
    x: 556.5,
    y: 411.5
  },
  {
    x: 556.5,
    y: 396.5
  },
  {
    x: 556.5,
    y: 381.5
  },
  {
    x: 556.5,
    y: 366.5
  },
  {
    x: 556.5,
    y: 351.5
  },
  {
    x: 556.5,
    y: 336.5
  },
  {
    x: 556.5,
    y: 321.5
  },
  {
    x: 556.5,
    y: 306.5
  },
  {
    x: 556.5,
    y: 291.5
  },
  {
    x: 556.5,
    y: 276.5
  },
  {
    x: 556.5,
    y: 261.5
  },
  {
    x: 556.5,
    y: 246.5
  },
  {
    x: 556.5,
    y: 231.5
  },
  {
    x: 571.5,
    y: 2046.5
  },
  {
    x: 571.5,
    y: 2031.5
  },
  {
    x: 571.5,
    y: 2016.5
  },
  {
    x: 571.5,
    y: 2001.5
  },
  {
    x: 571.5,
    y: 1986.5
  },
  {
    x: 571.5,
    y: 1971.5
  },
  {
    x: 571.5,
    y: 1956.5
  },
  {
    x: 571.5,
    y: 1941.5
  },
  {
    x: 571.5,
    y: 1926.5
  },
  {
    x: 571.5,
    y: 1911.5
  },
  {
    x: 571.5,
    y: 1896.5
  },
  {
    x: 571.5,
    y: 1881.5
  },
  {
    x: 571.5,
    y: 411.5
  },
  {
    x: 571.5,
    y: 396.5
  },
  {
    x: 571.5,
    y: 381.5
  },
  {
    x: 571.5,
    y: 366.5
  },
  {
    x: 571.5,
    y: 351.5
  },
  {
    x: 571.5,
    y: 336.5
  },
  {
    x: 571.5,
    y: 321.5
  },
  {
    x: 571.5,
    y: 306.5
  },
  {
    x: 571.5,
    y: 291.5
  },
  {
    x: 571.5,
    y: 276.5
  },
  {
    x: 571.5,
    y: 261.5
  },
  {
    x: 571.5,
    y: 246.5
  },
  {
    x: 571.5,
    y: 231.5
  },
  {
    x: 586.5,
    y: 2046.5
  },
  {
    x: 586.5,
    y: 2031.5
  },
  {
    x: 586.5,
    y: 2016.5
  },
  {
    x: 586.5,
    y: 2001.5
  },
  {
    x: 586.5,
    y: 1986.5
  },
  {
    x: 586.5,
    y: 1971.5
  },
  {
    x: 586.5,
    y: 1956.5
  },
  {
    x: 586.5,
    y: 1941.5
  },
  {
    x: 586.5,
    y: 1926.5
  },
  {
    x: 586.5,
    y: 1911.5
  },
  {
    x: 586.5,
    y: 1896.5
  },
  {
    x: 586.5,
    y: 1881.5
  },
  {
    x: 586.5,
    y: 426.5
  },
  {
    x: 586.5,
    y: 411.5
  },
  {
    x: 586.5,
    y: 396.5
  },
  {
    x: 586.5,
    y: 381.5
  },
  {
    x: 586.5,
    y: 366.5
  },
  {
    x: 586.5,
    y: 351.5
  },
  {
    x: 586.5,
    y: 336.5
  },
  {
    x: 586.5,
    y: 321.5
  },
  {
    x: 586.5,
    y: 306.5
  },
  {
    x: 586.5,
    y: 291.5
  },
  {
    x: 586.5,
    y: 276.5
  },
  {
    x: 586.5,
    y: 261.5
  },
  {
    x: 586.5,
    y: 246.5
  },
  {
    x: 586.5,
    y: 231.5
  },
  {
    x: 601.5,
    y: 2046.5
  },
  {
    x: 601.5,
    y: 2031.5
  },
  {
    x: 601.5,
    y: 2016.5
  },
  {
    x: 601.5,
    y: 2001.5
  },
  {
    x: 601.5,
    y: 1986.5
  },
  {
    x: 601.5,
    y: 1971.5
  },
  {
    x: 601.5,
    y: 1956.5
  },
  {
    x: 601.5,
    y: 1941.5
  },
  {
    x: 601.5,
    y: 1926.5
  },
  {
    x: 601.5,
    y: 1911.5
  },
  {
    x: 601.5,
    y: 1896.5
  },
  {
    x: 601.5,
    y: 1881.5
  },
  {
    x: 601.5,
    y: 456.5
  },
  {
    x: 601.5,
    y: 441.5
  },
  {
    x: 601.5,
    y: 426.5
  },
  {
    x: 601.5,
    y: 411.5
  },
  {
    x: 601.5,
    y: 396.5
  },
  {
    x: 601.5,
    y: 381.5
  },
  {
    x: 601.5,
    y: 366.5
  },
  {
    x: 601.5,
    y: 351.5
  },
  {
    x: 601.5,
    y: 336.5
  },
  {
    x: 601.5,
    y: 321.5
  },
  {
    x: 601.5,
    y: 306.5
  },
  {
    x: 601.5,
    y: 291.5
  },
  {
    x: 601.5,
    y: 276.5
  },
  {
    x: 601.5,
    y: 261.5
  },
  {
    x: 601.5,
    y: 246.5
  },
  {
    x: 601.5,
    y: 231.5
  },
  {
    x: 616.5,
    y: 2046.5
  },
  {
    x: 616.5,
    y: 2031.5
  },
  {
    x: 616.5,
    y: 2016.5
  },
  {
    x: 616.5,
    y: 2001.5
  },
  {
    x: 616.5,
    y: 1986.5
  },
  {
    x: 616.5,
    y: 1971.5
  },
  {
    x: 616.5,
    y: 1956.5
  },
  {
    x: 616.5,
    y: 1941.5
  },
  {
    x: 616.5,
    y: 1926.5
  },
  {
    x: 616.5,
    y: 1911.5
  },
  {
    x: 616.5,
    y: 1896.5
  },
  {
    x: 616.5,
    y: 1881.5
  },
  {
    x: 616.5,
    y: 1866.5
  },
  {
    x: 616.5,
    y: 456.5
  },
  {
    x: 616.5,
    y: 441.5
  },
  {
    x: 616.5,
    y: 426.5
  },
  {
    x: 616.5,
    y: 411.5
  },
  {
    x: 616.5,
    y: 396.5
  },
  {
    x: 616.5,
    y: 381.5
  },
  {
    x: 616.5,
    y: 366.5
  },
  {
    x: 616.5,
    y: 351.5
  },
  {
    x: 616.5,
    y: 336.5
  },
  {
    x: 616.5,
    y: 321.5
  },
  {
    x: 616.5,
    y: 306.5
  },
  {
    x: 616.5,
    y: 291.5
  },
  {
    x: 616.5,
    y: 276.5
  },
  {
    x: 616.5,
    y: 261.5
  },
  {
    x: 616.5,
    y: 246.5
  },
  {
    x: 631.5,
    y: 2046.5
  },
  {
    x: 631.5,
    y: 2031.5
  },
  {
    x: 631.5,
    y: 2016.5
  },
  {
    x: 631.5,
    y: 2001.5
  },
  {
    x: 631.5,
    y: 1986.5
  },
  {
    x: 631.5,
    y: 1971.5
  },
  {
    x: 631.5,
    y: 1956.5
  },
  {
    x: 631.5,
    y: 1941.5
  },
  {
    x: 631.5,
    y: 1926.5
  },
  {
    x: 631.5,
    y: 1911.5
  },
  {
    x: 631.5,
    y: 1896.5
  },
  {
    x: 631.5,
    y: 1881.5
  },
  {
    x: 631.5,
    y: 1866.5
  },
  {
    x: 631.5,
    y: 471.5
  },
  {
    x: 631.5,
    y: 456.5
  },
  {
    x: 631.5,
    y: 441.5
  },
  {
    x: 631.5,
    y: 426.5
  },
  {
    x: 631.5,
    y: 411.5
  },
  {
    x: 631.5,
    y: 396.5
  },
  {
    x: 631.5,
    y: 381.5
  },
  {
    x: 631.5,
    y: 366.5
  },
  {
    x: 631.5,
    y: 351.5
  },
  {
    x: 631.5,
    y: 336.5
  },
  {
    x: 631.5,
    y: 321.5
  },
  {
    x: 631.5,
    y: 306.5
  },
  {
    x: 631.5,
    y: 291.5
  },
  {
    x: 631.5,
    y: 276.5
  },
  {
    x: 631.5,
    y: 261.5
  },
  {
    x: 631.5,
    y: 246.5
  },
  {
    x: 631.5,
    y: 231.5
  },
  {
    x: 631.5,
    y: 201.5
  },
  {
    x: 646.5,
    y: 2046.5
  },
  {
    x: 646.5,
    y: 2031.5
  },
  {
    x: 646.5,
    y: 2016.5
  },
  {
    x: 646.5,
    y: 2001.5
  },
  {
    x: 646.5,
    y: 1986.5
  },
  {
    x: 646.5,
    y: 1971.5
  },
  {
    x: 646.5,
    y: 1956.5
  },
  {
    x: 646.5,
    y: 1941.5
  },
  {
    x: 646.5,
    y: 1926.5
  },
  {
    x: 646.5,
    y: 1911.5
  },
  {
    x: 646.5,
    y: 1896.5
  },
  {
    x: 646.5,
    y: 1881.5
  },
  {
    x: 646.5,
    y: 576.5
  },
  {
    x: 646.5,
    y: 561.5
  },
  {
    x: 646.5,
    y: 546.5
  },
  {
    x: 646.5,
    y: 531.5
  },
  {
    x: 646.5,
    y: 516.5
  },
  {
    x: 646.5,
    y: 501.5
  },
  {
    x: 646.5,
    y: 486.5
  },
  {
    x: 646.5,
    y: 471.5
  },
  {
    x: 646.5,
    y: 456.5
  },
  {
    x: 646.5,
    y: 441.5
  },
  {
    x: 646.5,
    y: 426.5
  },
  {
    x: 646.5,
    y: 411.5
  },
  {
    x: 646.5,
    y: 396.5
  },
  {
    x: 646.5,
    y: 381.5
  },
  {
    x: 646.5,
    y: 366.5
  },
  {
    x: 646.5,
    y: 351.5
  },
  {
    x: 646.5,
    y: 336.5
  },
  {
    x: 646.5,
    y: 321.5
  },
  {
    x: 646.5,
    y: 306.5
  },
  {
    x: 646.5,
    y: 291.5
  },
  {
    x: 646.5,
    y: 276.5
  },
  {
    x: 646.5,
    y: 261.5
  },
  {
    x: 646.5,
    y: 246.5
  },
  {
    x: 646.5,
    y: 231.5
  },
  {
    x: 646.5,
    y: 201.5
  },
  {
    x: 646.5,
    y: 186.5
  },
  {
    x: 661.5,
    y: 2046.5
  },
  {
    x: 661.5,
    y: 2031.5
  },
  {
    x: 661.5,
    y: 2016.5
  },
  {
    x: 661.5,
    y: 2001.5
  },
  {
    x: 661.5,
    y: 1986.5
  },
  {
    x: 661.5,
    y: 1971.5
  },
  {
    x: 661.5,
    y: 1956.5
  },
  {
    x: 661.5,
    y: 1941.5
  },
  {
    x: 661.5,
    y: 1926.5
  },
  {
    x: 661.5,
    y: 1911.5
  },
  {
    x: 661.5,
    y: 1896.5
  },
  {
    x: 661.5,
    y: 1881.5
  },
  {
    x: 661.5,
    y: 1866.5
  },
  {
    x: 661.5,
    y: 606.5
  },
  {
    x: 661.5,
    y: 591.5
  },
  {
    x: 661.5,
    y: 576.5
  },
  {
    x: 661.5,
    y: 561.5
  },
  {
    x: 661.5,
    y: 546.5
  },
  {
    x: 661.5,
    y: 531.5
  },
  {
    x: 661.5,
    y: 516.5
  },
  {
    x: 661.5,
    y: 501.5
  },
  {
    x: 661.5,
    y: 486.5
  },
  {
    x: 661.5,
    y: 471.5
  },
  {
    x: 661.5,
    y: 456.5
  },
  {
    x: 661.5,
    y: 441.5
  },
  {
    x: 661.5,
    y: 426.5
  },
  {
    x: 661.5,
    y: 411.5
  },
  {
    x: 661.5,
    y: 396.5
  },
  {
    x: 661.5,
    y: 381.5
  },
  {
    x: 661.5,
    y: 366.5
  },
  {
    x: 661.5,
    y: 351.5
  },
  {
    x: 661.5,
    y: 336.5
  },
  {
    x: 661.5,
    y: 321.5
  },
  {
    x: 661.5,
    y: 306.5
  },
  {
    x: 661.5,
    y: 291.5
  },
  {
    x: 661.5,
    y: 276.5
  },
  {
    x: 661.5,
    y: 261.5
  },
  {
    x: 661.5,
    y: 246.5
  },
  {
    x: 661.5,
    y: 231.5
  },
  {
    x: 661.5,
    y: 201.5
  },
  {
    x: 661.5,
    y: 186.5
  },
  {
    x: 661.5,
    y: 156.5
  },
  {
    x: 676.5,
    y: 2046.5
  },
  {
    x: 676.5,
    y: 2031.5
  },
  {
    x: 676.5,
    y: 2016.5
  },
  {
    x: 676.5,
    y: 2001.5
  },
  {
    x: 676.5,
    y: 1986.5
  },
  {
    x: 676.5,
    y: 1971.5
  },
  {
    x: 676.5,
    y: 1956.5
  },
  {
    x: 676.5,
    y: 1941.5
  },
  {
    x: 676.5,
    y: 1926.5
  },
  {
    x: 676.5,
    y: 1911.5
  },
  {
    x: 676.5,
    y: 1896.5
  },
  {
    x: 676.5,
    y: 1881.5
  },
  {
    x: 676.5,
    y: 1866.5
  },
  {
    x: 676.5,
    y: 636.5
  },
  {
    x: 676.5,
    y: 621.5
  },
  {
    x: 676.5,
    y: 606.5
  },
  {
    x: 676.5,
    y: 591.5
  },
  {
    x: 676.5,
    y: 576.5
  },
  {
    x: 676.5,
    y: 561.5
  },
  {
    x: 676.5,
    y: 546.5
  },
  {
    x: 676.5,
    y: 531.5
  },
  {
    x: 676.5,
    y: 516.5
  },
  {
    x: 676.5,
    y: 501.5
  },
  {
    x: 676.5,
    y: 486.5
  },
  {
    x: 676.5,
    y: 471.5
  },
  {
    x: 676.5,
    y: 456.5
  },
  {
    x: 676.5,
    y: 441.5
  },
  {
    x: 676.5,
    y: 426.5
  },
  {
    x: 676.5,
    y: 411.5
  },
  {
    x: 676.5,
    y: 396.5
  },
  {
    x: 676.5,
    y: 381.5
  },
  {
    x: 676.5,
    y: 366.5
  },
  {
    x: 676.5,
    y: 351.5
  },
  {
    x: 676.5,
    y: 336.5
  },
  {
    x: 676.5,
    y: 321.5
  },
  {
    x: 676.5,
    y: 306.5
  },
  {
    x: 676.5,
    y: 291.5
  },
  {
    x: 676.5,
    y: 276.5
  },
  {
    x: 676.5,
    y: 261.5
  },
  {
    x: 676.5,
    y: 246.5
  },
  {
    x: 676.5,
    y: 231.5
  },
  {
    x: 676.5,
    y: 201.5
  },
  {
    x: 676.5,
    y: 186.5
  },
  {
    x: 676.5,
    y: 156.5
  },
  {
    x: 691.5,
    y: 2046.5
  },
  {
    x: 691.5,
    y: 2031.5
  },
  {
    x: 691.5,
    y: 2016.5
  },
  {
    x: 691.5,
    y: 2001.5
  },
  {
    x: 691.5,
    y: 1986.5
  },
  {
    x: 691.5,
    y: 1971.5
  },
  {
    x: 691.5,
    y: 1956.5
  },
  {
    x: 691.5,
    y: 1941.5
  },
  {
    x: 691.5,
    y: 1926.5
  },
  {
    x: 691.5,
    y: 1911.5
  },
  {
    x: 691.5,
    y: 1896.5
  },
  {
    x: 691.5,
    y: 1881.5
  },
  {
    x: 691.5,
    y: 1866.5
  },
  {
    x: 691.5,
    y: 636.5
  },
  {
    x: 691.5,
    y: 621.5
  },
  {
    x: 691.5,
    y: 606.5
  },
  {
    x: 691.5,
    y: 591.5
  },
  {
    x: 691.5,
    y: 576.5
  },
  {
    x: 691.5,
    y: 561.5
  },
  {
    x: 691.5,
    y: 546.5
  },
  {
    x: 691.5,
    y: 531.5
  },
  {
    x: 691.5,
    y: 516.5
  },
  {
    x: 691.5,
    y: 501.5
  },
  {
    x: 691.5,
    y: 486.5
  },
  {
    x: 691.5,
    y: 471.5
  },
  {
    x: 691.5,
    y: 456.5
  },
  {
    x: 691.5,
    y: 441.5
  },
  {
    x: 691.5,
    y: 426.5
  },
  {
    x: 691.5,
    y: 411.5
  },
  {
    x: 691.5,
    y: 396.5
  },
  {
    x: 691.5,
    y: 381.5
  },
  {
    x: 691.5,
    y: 366.5
  },
  {
    x: 691.5,
    y: 351.5
  },
  {
    x: 691.5,
    y: 336.5
  },
  {
    x: 691.5,
    y: 321.5
  },
  {
    x: 691.5,
    y: 306.5
  },
  {
    x: 691.5,
    y: 291.5
  },
  {
    x: 691.5,
    y: 276.5
  },
  {
    x: 691.5,
    y: 261.5
  },
  {
    x: 691.5,
    y: 246.5
  },
  {
    x: 691.5,
    y: 201.5
  },
  {
    x: 691.5,
    y: 186.5
  },
  {
    x: 691.5,
    y: 156.5
  },
  {
    x: 706.5,
    y: 2046.5
  },
  {
    x: 706.5,
    y: 2031.5
  },
  {
    x: 706.5,
    y: 2016.5
  },
  {
    x: 706.5,
    y: 2001.5
  },
  {
    x: 706.5,
    y: 1986.5
  },
  {
    x: 706.5,
    y: 1971.5
  },
  {
    x: 706.5,
    y: 1956.5
  },
  {
    x: 706.5,
    y: 1941.5
  },
  {
    x: 706.5,
    y: 1926.5
  },
  {
    x: 706.5,
    y: 1911.5
  },
  {
    x: 706.5,
    y: 1896.5
  },
  {
    x: 706.5,
    y: 1881.5
  },
  {
    x: 706.5,
    y: 636.5
  },
  {
    x: 706.5,
    y: 621.5
  },
  {
    x: 706.5,
    y: 606.5
  },
  {
    x: 706.5,
    y: 591.5
  },
  {
    x: 706.5,
    y: 576.5
  },
  {
    x: 706.5,
    y: 561.5
  },
  {
    x: 706.5,
    y: 546.5
  },
  {
    x: 706.5,
    y: 531.5
  },
  {
    x: 706.5,
    y: 516.5
  },
  {
    x: 706.5,
    y: 501.5
  },
  {
    x: 706.5,
    y: 486.5
  },
  {
    x: 706.5,
    y: 471.5
  },
  {
    x: 706.5,
    y: 456.5
  },
  {
    x: 706.5,
    y: 441.5
  },
  {
    x: 706.5,
    y: 426.5
  },
  {
    x: 706.5,
    y: 411.5
  },
  {
    x: 706.5,
    y: 396.5
  },
  {
    x: 706.5,
    y: 381.5
  },
  {
    x: 706.5,
    y: 366.5
  },
  {
    x: 706.5,
    y: 351.5
  },
  {
    x: 706.5,
    y: 336.5
  },
  {
    x: 706.5,
    y: 321.5
  },
  {
    x: 706.5,
    y: 306.5
  },
  {
    x: 706.5,
    y: 291.5
  },
  {
    x: 706.5,
    y: 276.5
  },
  {
    x: 706.5,
    y: 261.5
  },
  {
    x: 706.5,
    y: 246.5
  },
  {
    x: 706.5,
    y: 231.5
  },
  {
    x: 706.5,
    y: 216.5
  },
  {
    x: 706.5,
    y: 201.5
  },
  {
    x: 706.5,
    y: 186.5
  },
  {
    x: 706.5,
    y: 156.5
  },
  {
    x: 721.5,
    y: 2046.5
  },
  {
    x: 721.5,
    y: 2031.5
  },
  {
    x: 721.5,
    y: 2016.5
  },
  {
    x: 721.5,
    y: 2001.5
  },
  {
    x: 721.5,
    y: 1986.5
  },
  {
    x: 721.5,
    y: 1971.5
  },
  {
    x: 721.5,
    y: 1956.5
  },
  {
    x: 721.5,
    y: 1941.5
  },
  {
    x: 721.5,
    y: 1926.5
  },
  {
    x: 721.5,
    y: 1911.5
  },
  {
    x: 721.5,
    y: 1896.5
  },
  {
    x: 721.5,
    y: 1881.5
  },
  {
    x: 721.5,
    y: 1866.5
  },
  {
    x: 721.5,
    y: 666.5
  },
  {
    x: 721.5,
    y: 651.5
  },
  {
    x: 721.5,
    y: 636.5
  },
  {
    x: 721.5,
    y: 621.5
  },
  {
    x: 721.5,
    y: 606.5
  },
  {
    x: 721.5,
    y: 591.5
  },
  {
    x: 721.5,
    y: 576.5
  },
  {
    x: 721.5,
    y: 561.5
  },
  {
    x: 721.5,
    y: 546.5
  },
  {
    x: 721.5,
    y: 531.5
  },
  {
    x: 721.5,
    y: 516.5
  },
  {
    x: 721.5,
    y: 501.5
  },
  {
    x: 721.5,
    y: 486.5
  },
  {
    x: 721.5,
    y: 471.5
  },
  {
    x: 721.5,
    y: 456.5
  },
  {
    x: 721.5,
    y: 441.5
  },
  {
    x: 721.5,
    y: 426.5
  },
  {
    x: 721.5,
    y: 411.5
  },
  {
    x: 721.5,
    y: 396.5
  },
  {
    x: 721.5,
    y: 381.5
  },
  {
    x: 721.5,
    y: 366.5
  },
  {
    x: 721.5,
    y: 351.5
  },
  {
    x: 721.5,
    y: 336.5
  },
  {
    x: 721.5,
    y: 321.5
  },
  {
    x: 721.5,
    y: 306.5
  },
  {
    x: 721.5,
    y: 291.5
  },
  {
    x: 721.5,
    y: 276.5
  },
  {
    x: 721.5,
    y: 261.5
  },
  {
    x: 721.5,
    y: 246.5
  },
  {
    x: 721.5,
    y: 231.5
  },
  {
    x: 721.5,
    y: 216.5
  },
  {
    x: 721.5,
    y: 201.5
  },
  {
    x: 721.5,
    y: 186.5
  },
  {
    x: 721.5,
    y: 156.5
  },
  {
    x: 721.5,
    y: 141.5
  },
  {
    x: 736.5,
    y: 2046.5
  },
  {
    x: 736.5,
    y: 2031.5
  },
  {
    x: 736.5,
    y: 2016.5
  },
  {
    x: 736.5,
    y: 2001.5
  },
  {
    x: 736.5,
    y: 1986.5
  },
  {
    x: 736.5,
    y: 1971.5
  },
  {
    x: 736.5,
    y: 1956.5
  },
  {
    x: 736.5,
    y: 1941.5
  },
  {
    x: 736.5,
    y: 1926.5
  },
  {
    x: 736.5,
    y: 1911.5
  },
  {
    x: 736.5,
    y: 1896.5
  },
  {
    x: 736.5,
    y: 1881.5
  },
  {
    x: 736.5,
    y: 681.5
  },
  {
    x: 736.5,
    y: 666.5
  },
  {
    x: 736.5,
    y: 651.5
  },
  {
    x: 736.5,
    y: 636.5
  },
  {
    x: 736.5,
    y: 621.5
  },
  {
    x: 736.5,
    y: 606.5
  },
  {
    x: 736.5,
    y: 591.5
  },
  {
    x: 736.5,
    y: 576.5
  },
  {
    x: 736.5,
    y: 561.5
  },
  {
    x: 736.5,
    y: 546.5
  },
  {
    x: 736.5,
    y: 531.5
  },
  {
    x: 736.5,
    y: 516.5
  },
  {
    x: 736.5,
    y: 501.5
  },
  {
    x: 736.5,
    y: 486.5
  },
  {
    x: 736.5,
    y: 471.5
  },
  {
    x: 736.5,
    y: 456.5
  },
  {
    x: 736.5,
    y: 441.5
  },
  {
    x: 736.5,
    y: 426.5
  },
  {
    x: 736.5,
    y: 411.5
  },
  {
    x: 736.5,
    y: 396.5
  },
  {
    x: 736.5,
    y: 381.5
  },
  {
    x: 736.5,
    y: 366.5
  },
  {
    x: 736.5,
    y: 351.5
  },
  {
    x: 736.5,
    y: 336.5
  },
  {
    x: 736.5,
    y: 321.5
  },
  {
    x: 736.5,
    y: 306.5
  },
  {
    x: 736.5,
    y: 291.5
  },
  {
    x: 736.5,
    y: 276.5
  },
  {
    x: 736.5,
    y: 261.5
  },
  {
    x: 736.5,
    y: 246.5
  },
  {
    x: 736.5,
    y: 231.5
  },
  {
    x: 736.5,
    y: 216.5
  },
  {
    x: 736.5,
    y: 201.5
  },
  {
    x: 736.5,
    y: 171.5
  },
  {
    x: 736.5,
    y: 156.5
  },
  {
    x: 751.5,
    y: 2046.5
  },
  {
    x: 751.5,
    y: 2031.5
  },
  {
    x: 751.5,
    y: 2016.5
  },
  {
    x: 751.5,
    y: 2001.5
  },
  {
    x: 751.5,
    y: 1986.5
  },
  {
    x: 751.5,
    y: 1971.5
  },
  {
    x: 751.5,
    y: 1956.5
  },
  {
    x: 751.5,
    y: 1941.5
  },
  {
    x: 751.5,
    y: 1926.5
  },
  {
    x: 751.5,
    y: 1911.5
  },
  {
    x: 751.5,
    y: 1896.5
  },
  {
    x: 751.5,
    y: 1881.5
  },
  {
    x: 751.5,
    y: 1866.5
  },
  {
    x: 751.5,
    y: 711.5
  },
  {
    x: 751.5,
    y: 696.5
  },
  {
    x: 751.5,
    y: 651.5
  },
  {
    x: 751.5,
    y: 636.5
  },
  {
    x: 751.5,
    y: 621.5
  },
  {
    x: 751.5,
    y: 606.5
  },
  {
    x: 751.5,
    y: 591.5
  },
  {
    x: 751.5,
    y: 576.5
  },
  {
    x: 751.5,
    y: 561.5
  },
  {
    x: 751.5,
    y: 546.5
  },
  {
    x: 751.5,
    y: 531.5
  },
  {
    x: 751.5,
    y: 516.5
  },
  {
    x: 751.5,
    y: 501.5
  },
  {
    x: 751.5,
    y: 486.5
  },
  {
    x: 751.5,
    y: 471.5
  },
  {
    x: 751.5,
    y: 456.5
  },
  {
    x: 751.5,
    y: 441.5
  },
  {
    x: 751.5,
    y: 426.5
  },
  {
    x: 751.5,
    y: 411.5
  },
  {
    x: 751.5,
    y: 396.5
  },
  {
    x: 751.5,
    y: 381.5
  },
  {
    x: 751.5,
    y: 366.5
  },
  {
    x: 751.5,
    y: 351.5
  },
  {
    x: 751.5,
    y: 336.5
  },
  {
    x: 751.5,
    y: 321.5
  },
  {
    x: 751.5,
    y: 306.5
  },
  {
    x: 751.5,
    y: 291.5
  },
  {
    x: 751.5,
    y: 276.5
  },
  {
    x: 751.5,
    y: 261.5
  },
  {
    x: 751.5,
    y: 246.5
  },
  {
    x: 751.5,
    y: 231.5
  },
  {
    x: 751.5,
    y: 216.5
  },
  {
    x: 751.5,
    y: 201.5
  },
  {
    x: 751.5,
    y: 171.5
  },
  {
    x: 751.5,
    y: 156.5
  },
  {
    x: 766.5,
    y: 2046.5
  },
  {
    x: 766.5,
    y: 2031.5
  },
  {
    x: 766.5,
    y: 2016.5
  },
  {
    x: 766.5,
    y: 2001.5
  },
  {
    x: 766.5,
    y: 1986.5
  },
  {
    x: 766.5,
    y: 1971.5
  },
  {
    x: 766.5,
    y: 1956.5
  },
  {
    x: 766.5,
    y: 1941.5
  },
  {
    x: 766.5,
    y: 1926.5
  },
  {
    x: 766.5,
    y: 1911.5
  },
  {
    x: 766.5,
    y: 1896.5
  },
  {
    x: 766.5,
    y: 1881.5
  },
  {
    x: 766.5,
    y: 711.5
  },
  {
    x: 766.5,
    y: 681.5
  },
  {
    x: 766.5,
    y: 666.5
  },
  {
    x: 766.5,
    y: 651.5
  },
  {
    x: 766.5,
    y: 636.5
  },
  {
    x: 766.5,
    y: 621.5
  },
  {
    x: 766.5,
    y: 606.5
  },
  {
    x: 766.5,
    y: 591.5
  },
  {
    x: 766.5,
    y: 576.5
  },
  {
    x: 766.5,
    y: 561.5
  },
  {
    x: 766.5,
    y: 546.5
  },
  {
    x: 766.5,
    y: 531.5
  },
  {
    x: 766.5,
    y: 516.5
  },
  {
    x: 766.5,
    y: 501.5
  },
  {
    x: 766.5,
    y: 486.5
  },
  {
    x: 766.5,
    y: 471.5
  },
  {
    x: 766.5,
    y: 456.5
  },
  {
    x: 766.5,
    y: 441.5
  },
  {
    x: 766.5,
    y: 426.5
  },
  {
    x: 766.5,
    y: 411.5
  },
  {
    x: 766.5,
    y: 396.5
  },
  {
    x: 766.5,
    y: 381.5
  },
  {
    x: 766.5,
    y: 366.5
  },
  {
    x: 766.5,
    y: 351.5
  },
  {
    x: 766.5,
    y: 336.5
  },
  {
    x: 766.5,
    y: 321.5
  },
  {
    x: 766.5,
    y: 306.5
  },
  {
    x: 766.5,
    y: 291.5
  },
  {
    x: 766.5,
    y: 276.5
  },
  {
    x: 766.5,
    y: 261.5
  },
  {
    x: 766.5,
    y: 231.5
  },
  {
    x: 766.5,
    y: 216.5
  },
  {
    x: 766.5,
    y: 201.5
  },
  {
    x: 766.5,
    y: 171.5
  },
  {
    x: 766.5,
    y: 156.5
  },
  {
    x: 766.5,
    y: 141.5
  },
  {
    x: 781.5,
    y: 2046.5
  },
  {
    x: 781.5,
    y: 2031.5
  },
  {
    x: 781.5,
    y: 2016.5
  },
  {
    x: 781.5,
    y: 2001.5
  },
  {
    x: 781.5,
    y: 1986.5
  },
  {
    x: 781.5,
    y: 1971.5
  },
  {
    x: 781.5,
    y: 1956.5
  },
  {
    x: 781.5,
    y: 1941.5
  },
  {
    x: 781.5,
    y: 1926.5
  },
  {
    x: 781.5,
    y: 1911.5
  },
  {
    x: 781.5,
    y: 1896.5
  },
  {
    x: 781.5,
    y: 1881.5
  },
  {
    x: 781.5,
    y: 741.5
  },
  {
    x: 781.5,
    y: 726.5
  },
  {
    x: 781.5,
    y: 696.5
  },
  {
    x: 781.5,
    y: 681.5
  },
  {
    x: 781.5,
    y: 666.5
  },
  {
    x: 781.5,
    y: 651.5
  },
  {
    x: 781.5,
    y: 636.5
  },
  {
    x: 781.5,
    y: 621.5
  },
  {
    x: 781.5,
    y: 606.5
  },
  {
    x: 781.5,
    y: 591.5
  },
  {
    x: 781.5,
    y: 576.5
  },
  {
    x: 781.5,
    y: 561.5
  },
  {
    x: 781.5,
    y: 546.5
  },
  {
    x: 781.5,
    y: 531.5
  },
  {
    x: 781.5,
    y: 516.5
  },
  {
    x: 781.5,
    y: 501.5
  },
  {
    x: 781.5,
    y: 486.5
  },
  {
    x: 781.5,
    y: 471.5
  },
  {
    x: 781.5,
    y: 456.5
  },
  {
    x: 781.5,
    y: 441.5
  },
  {
    x: 781.5,
    y: 426.5
  },
  {
    x: 781.5,
    y: 411.5
  },
  {
    x: 781.5,
    y: 396.5
  },
  {
    x: 781.5,
    y: 381.5
  },
  {
    x: 781.5,
    y: 366.5
  },
  {
    x: 781.5,
    y: 351.5
  },
  {
    x: 781.5,
    y: 336.5
  },
  {
    x: 781.5,
    y: 321.5
  },
  {
    x: 781.5,
    y: 306.5
  },
  {
    x: 781.5,
    y: 291.5
  },
  {
    x: 781.5,
    y: 276.5
  },
  {
    x: 781.5,
    y: 261.5
  },
  {
    x: 781.5,
    y: 231.5
  },
  {
    x: 781.5,
    y: 216.5
  },
  {
    x: 781.5,
    y: 201.5
  },
  {
    x: 781.5,
    y: 171.5
  },
  {
    x: 781.5,
    y: 156.5
  },
  {
    x: 781.5,
    y: 141.5
  },
  {
    x: 796.5,
    y: 2046.5
  },
  {
    x: 796.5,
    y: 2031.5
  },
  {
    x: 796.5,
    y: 2016.5
  },
  {
    x: 796.5,
    y: 2001.5
  },
  {
    x: 796.5,
    y: 1986.5
  },
  {
    x: 796.5,
    y: 1971.5
  },
  {
    x: 796.5,
    y: 1956.5
  },
  {
    x: 796.5,
    y: 1941.5
  },
  {
    x: 796.5,
    y: 1926.5
  },
  {
    x: 796.5,
    y: 1911.5
  },
  {
    x: 796.5,
    y: 1896.5
  },
  {
    x: 796.5,
    y: 1881.5
  },
  {
    x: 796.5,
    y: 756.5
  },
  {
    x: 796.5,
    y: 711.5
  },
  {
    x: 796.5,
    y: 696.5
  },
  {
    x: 796.5,
    y: 681.5
  },
  {
    x: 796.5,
    y: 666.5
  },
  {
    x: 796.5,
    y: 651.5
  },
  {
    x: 796.5,
    y: 636.5
  },
  {
    x: 796.5,
    y: 621.5
  },
  {
    x: 796.5,
    y: 606.5
  },
  {
    x: 796.5,
    y: 591.5
  },
  {
    x: 796.5,
    y: 576.5
  },
  {
    x: 796.5,
    y: 561.5
  },
  {
    x: 796.5,
    y: 546.5
  },
  {
    x: 796.5,
    y: 531.5
  },
  {
    x: 796.5,
    y: 516.5
  },
  {
    x: 796.5,
    y: 501.5
  },
  {
    x: 796.5,
    y: 486.5
  },
  {
    x: 796.5,
    y: 471.5
  },
  {
    x: 796.5,
    y: 456.5
  },
  {
    x: 796.5,
    y: 441.5
  },
  {
    x: 796.5,
    y: 426.5
  },
  {
    x: 796.5,
    y: 411.5
  },
  {
    x: 796.5,
    y: 396.5
  },
  {
    x: 796.5,
    y: 381.5
  },
  {
    x: 796.5,
    y: 366.5
  },
  {
    x: 796.5,
    y: 351.5
  },
  {
    x: 796.5,
    y: 336.5
  },
  {
    x: 796.5,
    y: 321.5
  },
  {
    x: 796.5,
    y: 306.5
  },
  {
    x: 796.5,
    y: 291.5
  },
  {
    x: 796.5,
    y: 276.5
  },
  {
    x: 796.5,
    y: 261.5
  },
  {
    x: 796.5,
    y: 231.5
  },
  {
    x: 796.5,
    y: 216.5
  },
  {
    x: 796.5,
    y: 201.5
  },
  {
    x: 796.5,
    y: 171.5
  },
  {
    x: 796.5,
    y: 156.5
  },
  {
    x: 796.5,
    y: 141.5
  },
  {
    x: 811.5,
    y: 2046.5
  },
  {
    x: 811.5,
    y: 2031.5
  },
  {
    x: 811.5,
    y: 2016.5
  },
  {
    x: 811.5,
    y: 2001.5
  },
  {
    x: 811.5,
    y: 1986.5
  },
  {
    x: 811.5,
    y: 1971.5
  },
  {
    x: 811.5,
    y: 1956.5
  },
  {
    x: 811.5,
    y: 1941.5
  },
  {
    x: 811.5,
    y: 1926.5
  },
  {
    x: 811.5,
    y: 1911.5
  },
  {
    x: 811.5,
    y: 1896.5
  },
  {
    x: 811.5,
    y: 1881.5
  },
  {
    x: 811.5,
    y: 726.5
  },
  {
    x: 811.5,
    y: 711.5
  },
  {
    x: 811.5,
    y: 696.5
  },
  {
    x: 811.5,
    y: 681.5
  },
  {
    x: 811.5,
    y: 666.5
  },
  {
    x: 811.5,
    y: 651.5
  },
  {
    x: 811.5,
    y: 636.5
  },
  {
    x: 811.5,
    y: 621.5
  },
  {
    x: 811.5,
    y: 606.5
  },
  {
    x: 811.5,
    y: 591.5
  },
  {
    x: 811.5,
    y: 576.5
  },
  {
    x: 811.5,
    y: 561.5
  },
  {
    x: 811.5,
    y: 546.5
  },
  {
    x: 811.5,
    y: 531.5
  },
  {
    x: 811.5,
    y: 516.5
  },
  {
    x: 811.5,
    y: 501.5
  },
  {
    x: 811.5,
    y: 486.5
  },
  {
    x: 811.5,
    y: 471.5
  },
  {
    x: 811.5,
    y: 456.5
  },
  {
    x: 811.5,
    y: 441.5
  },
  {
    x: 811.5,
    y: 426.5
  },
  {
    x: 811.5,
    y: 411.5
  },
  {
    x: 811.5,
    y: 396.5
  },
  {
    x: 811.5,
    y: 381.5
  },
  {
    x: 811.5,
    y: 366.5
  },
  {
    x: 811.5,
    y: 351.5
  },
  {
    x: 811.5,
    y: 336.5
  },
  {
    x: 811.5,
    y: 321.5
  },
  {
    x: 811.5,
    y: 306.5
  },
  {
    x: 811.5,
    y: 291.5
  },
  {
    x: 811.5,
    y: 276.5
  },
  {
    x: 811.5,
    y: 261.5
  },
  {
    x: 811.5,
    y: 246.5
  },
  {
    x: 811.5,
    y: 231.5
  },
  {
    x: 811.5,
    y: 216.5
  },
  {
    x: 811.5,
    y: 201.5
  },
  {
    x: 811.5,
    y: 171.5
  },
  {
    x: 811.5,
    y: 156.5
  },
  {
    x: 826.5,
    y: 2046.5
  },
  {
    x: 826.5,
    y: 2031.5
  },
  {
    x: 826.5,
    y: 2016.5
  },
  {
    x: 826.5,
    y: 2001.5
  },
  {
    x: 826.5,
    y: 1986.5
  },
  {
    x: 826.5,
    y: 1971.5
  },
  {
    x: 826.5,
    y: 1956.5
  },
  {
    x: 826.5,
    y: 1941.5
  },
  {
    x: 826.5,
    y: 1926.5
  },
  {
    x: 826.5,
    y: 1911.5
  },
  {
    x: 826.5,
    y: 1896.5
  },
  {
    x: 826.5,
    y: 1881.5
  },
  {
    x: 826.5,
    y: 741.5
  },
  {
    x: 826.5,
    y: 726.5
  },
  {
    x: 826.5,
    y: 711.5
  },
  {
    x: 826.5,
    y: 696.5
  },
  {
    x: 826.5,
    y: 681.5
  },
  {
    x: 826.5,
    y: 666.5
  },
  {
    x: 826.5,
    y: 651.5
  },
  {
    x: 826.5,
    y: 636.5
  },
  {
    x: 826.5,
    y: 621.5
  },
  {
    x: 826.5,
    y: 606.5
  },
  {
    x: 826.5,
    y: 591.5
  },
  {
    x: 826.5,
    y: 576.5
  },
  {
    x: 826.5,
    y: 561.5
  },
  {
    x: 826.5,
    y: 546.5
  },
  {
    x: 826.5,
    y: 531.5
  },
  {
    x: 826.5,
    y: 516.5
  },
  {
    x: 826.5,
    y: 501.5
  },
  {
    x: 826.5,
    y: 486.5
  },
  {
    x: 826.5,
    y: 471.5
  },
  {
    x: 826.5,
    y: 456.5
  },
  {
    x: 826.5,
    y: 441.5
  },
  {
    x: 826.5,
    y: 426.5
  },
  {
    x: 826.5,
    y: 411.5
  },
  {
    x: 826.5,
    y: 396.5
  },
  {
    x: 826.5,
    y: 381.5
  },
  {
    x: 826.5,
    y: 366.5
  },
  {
    x: 826.5,
    y: 351.5
  },
  {
    x: 826.5,
    y: 336.5
  },
  {
    x: 826.5,
    y: 321.5
  },
  {
    x: 826.5,
    y: 306.5
  },
  {
    x: 826.5,
    y: 291.5
  },
  {
    x: 826.5,
    y: 276.5
  },
  {
    x: 826.5,
    y: 261.5
  },
  {
    x: 826.5,
    y: 246.5
  },
  {
    x: 826.5,
    y: 231.5
  },
  {
    x: 826.5,
    y: 216.5
  },
  {
    x: 826.5,
    y: 201.5
  },
  {
    x: 826.5,
    y: 171.5
  },
  {
    x: 841.5,
    y: 2046.5
  },
  {
    x: 841.5,
    y: 2031.5
  },
  {
    x: 841.5,
    y: 2016.5
  },
  {
    x: 841.5,
    y: 2001.5
  },
  {
    x: 841.5,
    y: 1986.5
  },
  {
    x: 841.5,
    y: 1971.5
  },
  {
    x: 841.5,
    y: 1956.5
  },
  {
    x: 841.5,
    y: 1941.5
  },
  {
    x: 841.5,
    y: 1926.5
  },
  {
    x: 841.5,
    y: 1911.5
  },
  {
    x: 841.5,
    y: 1896.5
  },
  {
    x: 841.5,
    y: 1881.5
  },
  {
    x: 841.5,
    y: 756.5
  },
  {
    x: 841.5,
    y: 741.5
  },
  {
    x: 841.5,
    y: 726.5
  },
  {
    x: 841.5,
    y: 711.5
  },
  {
    x: 841.5,
    y: 696.5
  },
  {
    x: 841.5,
    y: 681.5
  },
  {
    x: 841.5,
    y: 666.5
  },
  {
    x: 841.5,
    y: 651.5
  },
  {
    x: 841.5,
    y: 636.5
  },
  {
    x: 841.5,
    y: 621.5
  },
  {
    x: 841.5,
    y: 606.5
  },
  {
    x: 841.5,
    y: 591.5
  },
  {
    x: 841.5,
    y: 576.5
  },
  {
    x: 841.5,
    y: 561.5
  },
  {
    x: 841.5,
    y: 546.5
  },
  {
    x: 841.5,
    y: 531.5
  },
  {
    x: 841.5,
    y: 516.5
  },
  {
    x: 841.5,
    y: 501.5
  },
  {
    x: 841.5,
    y: 486.5
  },
  {
    x: 841.5,
    y: 471.5
  },
  {
    x: 841.5,
    y: 456.5
  },
  {
    x: 841.5,
    y: 441.5
  },
  {
    x: 841.5,
    y: 426.5
  },
  {
    x: 841.5,
    y: 411.5
  },
  {
    x: 841.5,
    y: 396.5
  },
  {
    x: 841.5,
    y: 381.5
  },
  {
    x: 841.5,
    y: 366.5
  },
  {
    x: 841.5,
    y: 351.5
  },
  {
    x: 841.5,
    y: 336.5
  },
  {
    x: 841.5,
    y: 321.5
  },
  {
    x: 841.5,
    y: 306.5
  },
  {
    x: 841.5,
    y: 291.5
  },
  {
    x: 841.5,
    y: 276.5
  },
  {
    x: 841.5,
    y: 261.5
  },
  {
    x: 841.5,
    y: 246.5
  },
  {
    x: 841.5,
    y: 231.5
  },
  {
    x: 841.5,
    y: 216.5
  },
  {
    x: 841.5,
    y: 201.5
  },
  {
    x: 841.5,
    y: 186.5
  },
  {
    x: 841.5,
    y: 141.5
  },
  {
    x: 856.5,
    y: 2046.5
  },
  {
    x: 856.5,
    y: 2031.5
  },
  {
    x: 856.5,
    y: 2016.5
  },
  {
    x: 856.5,
    y: 2001.5
  },
  {
    x: 856.5,
    y: 1986.5
  },
  {
    x: 856.5,
    y: 1971.5
  },
  {
    x: 856.5,
    y: 1956.5
  },
  {
    x: 856.5,
    y: 1941.5
  },
  {
    x: 856.5,
    y: 1926.5
  },
  {
    x: 856.5,
    y: 1911.5
  },
  {
    x: 856.5,
    y: 1896.5
  },
  {
    x: 856.5,
    y: 1881.5
  },
  {
    x: 856.5,
    y: 801.5
  },
  {
    x: 856.5,
    y: 786.5
  },
  {
    x: 271.5,
    y: 786.5
  },
  {
    x: 226.5,
    y: 771.5
  },
  {
    x: 286.5,
    y: 801.5
  },
  {
    x: 271.5,
    y: 801.5
  },
  {
    x: 856.5,
    y: 771.5
  },
  {
    x: 856.5,
    y: 756.5
  },
  {
    x: 856.5,
    y: 741.5
  },
  {
    x: 856.5,
    y: 726.5
  },
  {
    x: 856.5,
    y: 711.5
  },
  {
    x: 856.5,
    y: 696.5
  },
  {
    x: 856.5,
    y: 681.5
  },
  {
    x: 856.5,
    y: 666.5
  },
  {
    x: 856.5,
    y: 651.5
  },
  {
    x: 856.5,
    y: 636.5
  },
  {
    x: 856.5,
    y: 621.5
  },
  {
    x: 856.5,
    y: 606.5
  },
  {
    x: 856.5,
    y: 591.5
  },
  {
    x: 856.5,
    y: 576.5
  },
  {
    x: 856.5,
    y: 561.5
  },
  {
    x: 856.5,
    y: 546.5
  },
  {
    x: 856.5,
    y: 531.5
  },
  {
    x: 856.5,
    y: 516.5
  },
  {
    x: 856.5,
    y: 501.5
  },
  {
    x: 856.5,
    y: 486.5
  },
  {
    x: 856.5,
    y: 471.5
  },
  {
    x: 856.5,
    y: 456.5
  },
  {
    x: 856.5,
    y: 441.5
  },
  {
    x: 856.5,
    y: 426.5
  },
  {
    x: 856.5,
    y: 411.5
  },
  {
    x: 856.5,
    y: 396.5
  },
  {
    x: 856.5,
    y: 381.5
  },
  {
    x: 856.5,
    y: 366.5
  },
  {
    x: 856.5,
    y: 351.5
  },
  {
    x: 856.5,
    y: 336.5
  },
  {
    x: 856.5,
    y: 321.5
  },
  {
    x: 856.5,
    y: 306.5
  },
  {
    x: 856.5,
    y: 291.5
  },
  {
    x: 856.5,
    y: 276.5
  },
  {
    x: 856.5,
    y: 261.5
  },
  {
    x: 856.5,
    y: 246.5
  },
  {
    x: 856.5,
    y: 231.5
  },
  {
    x: 856.5,
    y: 216.5
  },
  {
    x: 856.5,
    y: 201.5
  },
  {
    x: 856.5,
    y: 186.5
  },
  {
    x: 856.5,
    y: 141.5
  },
  {
    x: 856.5,
    y: 126.5
  },
  {
    x: 871.5,
    y: 2046.5
  },
  {
    x: 871.5,
    y: 2031.5
  },
  {
    x: 871.5,
    y: 2016.5
  },
  {
    x: 871.5,
    y: 2001.5
  },
  {
    x: 871.5,
    y: 1986.5
  },
  {
    x: 871.5,
    y: 1971.5
  },
  {
    x: 871.5,
    y: 1956.5
  },
  {
    x: 871.5,
    y: 1941.5
  },
  {
    x: 871.5,
    y: 1926.5
  },
  {
    x: 871.5,
    y: 1911.5
  },
  {
    x: 871.5,
    y: 1896.5
  },
  {
    x: 871.5,
    y: 1881.5
  },
  {
    x: 871.5,
    y: 1851.5
  },
  {
    x: 871.5,
    y: 801.5
  },
  {
    x: 871.5,
    y: 786.5
  },
  {
    x: 871.5,
    y: 771.5
  },
  {
    x: 871.5,
    y: 756.5
  },
  {
    x: 871.5,
    y: 741.5
  },
  {
    x: 871.5,
    y: 726.5
  },
  {
    x: 871.5,
    y: 711.5
  },
  {
    x: 871.5,
    y: 696.5
  },
  {
    x: 871.5,
    y: 681.5
  },
  {
    x: 871.5,
    y: 666.5
  },
  {
    x: 871.5,
    y: 651.5
  },
  {
    x: 871.5,
    y: 636.5
  },
  {
    x: 871.5,
    y: 621.5
  },
  {
    x: 871.5,
    y: 606.5
  },
  {
    x: 871.5,
    y: 591.5
  },
  {
    x: 871.5,
    y: 576.5
  },
  {
    x: 871.5,
    y: 561.5
  },
  {
    x: 871.5,
    y: 546.5
  },
  {
    x: 871.5,
    y: 531.5
  },
  {
    x: 871.5,
    y: 516.5
  },
  {
    x: 871.5,
    y: 501.5
  },
  {
    x: 871.5,
    y: 486.5
  },
  {
    x: 871.5,
    y: 471.5
  },
  {
    x: 871.5,
    y: 456.5
  },
  {
    x: 871.5,
    y: 441.5
  },
  {
    x: 871.5,
    y: 426.5
  },
  {
    x: 871.5,
    y: 411.5
  },
  {
    x: 871.5,
    y: 396.5
  },
  {
    x: 871.5,
    y: 381.5
  },
  {
    x: 871.5,
    y: 366.5
  },
  {
    x: 871.5,
    y: 351.5
  },
  {
    x: 871.5,
    y: 336.5
  },
  {
    x: 871.5,
    y: 321.5
  },
  {
    x: 871.5,
    y: 306.5
  },
  {
    x: 871.5,
    y: 291.5
  },
  {
    x: 871.5,
    y: 276.5
  },
  {
    x: 871.5,
    y: 261.5
  },
  {
    x: 871.5,
    y: 231.5
  },
  {
    x: 871.5,
    y: 156.5
  },
  {
    x: 871.5,
    y: 126.5
  },
  {
    x: 886.5,
    y: 2046.5
  },
  {
    x: 886.5,
    y: 2031.5
  },
  {
    x: 886.5,
    y: 2016.5
  },
  {
    x: 886.5,
    y: 2001.5
  },
  {
    x: 886.5,
    y: 1986.5
  },
  {
    x: 886.5,
    y: 1971.5
  },
  {
    x: 886.5,
    y: 1956.5
  },
  {
    x: 886.5,
    y: 1941.5
  },
  {
    x: 886.5,
    y: 1926.5
  },
  {
    x: 886.5,
    y: 1911.5
  },
  {
    x: 886.5,
    y: 1896.5
  },
  {
    x: 886.5,
    y: 1881.5
  },
  {
    x: 886.5,
    y: 1866.5
  },
  {
    x: 886.5,
    y: 816.5
  },
  {
    x: 886.5,
    y: 801.5
  },
  {
    x: 886.5,
    y: 786.5
  },
  {
    x: 886.5,
    y: 771.5
  },
  {
    x: 886.5,
    y: 756.5
  },
  {
    x: 886.5,
    y: 741.5
  },
  {
    x: 886.5,
    y: 726.5
  },
  {
    x: 886.5,
    y: 711.5
  },
  {
    x: 886.5,
    y: 696.5
  },
  {
    x: 886.5,
    y: 681.5
  },
  {
    x: 886.5,
    y: 666.5
  },
  {
    x: 886.5,
    y: 651.5
  },
  {
    x: 886.5,
    y: 636.5
  },
  {
    x: 886.5,
    y: 621.5
  },
  {
    x: 886.5,
    y: 606.5
  },
  {
    x: 886.5,
    y: 591.5
  },
  {
    x: 886.5,
    y: 576.5
  },
  {
    x: 886.5,
    y: 561.5
  },
  {
    x: 886.5,
    y: 546.5
  },
  {
    x: 886.5,
    y: 531.5
  },
  {
    x: 886.5,
    y: 516.5
  },
  {
    x: 886.5,
    y: 501.5
  },
  {
    x: 886.5,
    y: 486.5
  },
  {
    x: 886.5,
    y: 471.5
  },
  {
    x: 886.5,
    y: 456.5
  },
  {
    x: 886.5,
    y: 441.5
  },
  {
    x: 886.5,
    y: 426.5
  },
  {
    x: 886.5,
    y: 411.5
  },
  {
    x: 886.5,
    y: 396.5
  },
  {
    x: 886.5,
    y: 381.5
  },
  {
    x: 886.5,
    y: 366.5
  },
  {
    x: 886.5,
    y: 351.5
  },
  {
    x: 886.5,
    y: 336.5
  },
  {
    x: 886.5,
    y: 321.5
  },
  {
    x: 886.5,
    y: 306.5
  },
  {
    x: 886.5,
    y: 291.5
  },
  {
    x: 886.5,
    y: 276.5
  },
  {
    x: 886.5,
    y: 261.5
  },
  {
    x: 886.5,
    y: 231.5
  },
  {
    x: 886.5,
    y: 156.5
  },
  {
    x: 886.5,
    y: 126.5
  },
  {
    x: 901.5,
    y: 2046.5
  },
  {
    x: 901.5,
    y: 2031.5
  },
  {
    x: 901.5,
    y: 2016.5
  },
  {
    x: 901.5,
    y: 2001.5
  },
  {
    x: 901.5,
    y: 1986.5
  },
  {
    x: 901.5,
    y: 1971.5
  },
  {
    x: 901.5,
    y: 1956.5
  },
  {
    x: 901.5,
    y: 1941.5
  },
  {
    x: 901.5,
    y: 1926.5
  },
  {
    x: 901.5,
    y: 1911.5
  },
  {
    x: 901.5,
    y: 1896.5
  },
  {
    x: 901.5,
    y: 1881.5
  },
  {
    x: 901.5,
    y: 1866.5
  },
  {
    x: 901.5,
    y: 816.5
  },
  {
    x: 901.5,
    y: 801.5
  },
  {
    x: 901.5,
    y: 786.5
  },
  {
    x: 901.5,
    y: 771.5
  },
  {
    x: 901.5,
    y: 756.5
  },
  {
    x: 901.5,
    y: 741.5
  },
  {
    x: 901.5,
    y: 726.5
  },
  {
    x: 901.5,
    y: 711.5
  },
  {
    x: 901.5,
    y: 696.5
  },
  {
    x: 901.5,
    y: 681.5
  },
  {
    x: 901.5,
    y: 666.5
  },
  {
    x: 901.5,
    y: 651.5
  },
  {
    x: 901.5,
    y: 636.5
  },
  {
    x: 901.5,
    y: 621.5
  },
  {
    x: 901.5,
    y: 606.5
  },
  {
    x: 901.5,
    y: 591.5
  },
  {
    x: 901.5,
    y: 576.5
  },
  {
    x: 901.5,
    y: 561.5
  },
  {
    x: 901.5,
    y: 546.5
  },
  {
    x: 901.5,
    y: 531.5
  },
  {
    x: 901.5,
    y: 516.5
  },
  {
    x: 901.5,
    y: 501.5
  },
  {
    x: 901.5,
    y: 486.5
  },
  {
    x: 901.5,
    y: 471.5
  },
  {
    x: 901.5,
    y: 456.5
  },
  {
    x: 901.5,
    y: 441.5
  },
  {
    x: 901.5,
    y: 426.5
  },
  {
    x: 901.5,
    y: 411.5
  },
  {
    x: 901.5,
    y: 396.5
  },
  {
    x: 901.5,
    y: 381.5
  },
  {
    x: 901.5,
    y: 366.5
  },
  {
    x: 901.5,
    y: 351.5
  },
  {
    x: 901.5,
    y: 336.5
  },
  {
    x: 901.5,
    y: 321.5
  },
  {
    x: 901.5,
    y: 306.5
  },
  {
    x: 901.5,
    y: 291.5
  },
  {
    x: 901.5,
    y: 276.5
  },
  {
    x: 901.5,
    y: 261.5
  },
  {
    x: 901.5,
    y: 201.5
  },
  {
    x: 901.5,
    y: 186.5
  },
  {
    x: 901.5,
    y: 156.5
  },
  {
    x: 901.5,
    y: 126.5
  },
  {
    x: 916.5,
    y: 2046.5
  },
  {
    x: 916.5,
    y: 2031.5
  },
  {
    x: 916.5,
    y: 2016.5
  },
  {
    x: 916.5,
    y: 2001.5
  },
  {
    x: 916.5,
    y: 1986.5
  },
  {
    x: 916.5,
    y: 1971.5
  },
  {
    x: 916.5,
    y: 1956.5
  },
  {
    x: 916.5,
    y: 1941.5
  },
  {
    x: 916.5,
    y: 1926.5
  },
  {
    x: 916.5,
    y: 1911.5
  },
  {
    x: 916.5,
    y: 1896.5
  },
  {
    x: 916.5,
    y: 1881.5
  },
  {
    x: 916.5,
    y: 1866.5
  },
  {
    x: 916.5,
    y: 831.5
  },
  {
    x: 916.5,
    y: 816.5
  },
  {
    x: 916.5,
    y: 801.5
  },
  {
    x: 916.5,
    y: 786.5
  },
  {
    x: 916.5,
    y: 771.5
  },
  {
    x: 916.5,
    y: 756.5
  },
  {
    x: 916.5,
    y: 741.5
  },
  {
    x: 916.5,
    y: 726.5
  },
  {
    x: 916.5,
    y: 711.5
  },
  {
    x: 916.5,
    y: 696.5
  },
  {
    x: 916.5,
    y: 681.5
  },
  {
    x: 916.5,
    y: 666.5
  },
  {
    x: 916.5,
    y: 651.5
  },
  {
    x: 916.5,
    y: 636.5
  },
  {
    x: 916.5,
    y: 621.5
  },
  {
    x: 916.5,
    y: 606.5
  },
  {
    x: 916.5,
    y: 591.5
  },
  {
    x: 916.5,
    y: 576.5
  },
  {
    x: 916.5,
    y: 561.5
  },
  {
    x: 916.5,
    y: 546.5
  },
  {
    x: 916.5,
    y: 531.5
  },
  {
    x: 916.5,
    y: 516.5
  },
  {
    x: 916.5,
    y: 501.5
  },
  {
    x: 916.5,
    y: 486.5
  },
  {
    x: 916.5,
    y: 471.5
  },
  {
    x: 916.5,
    y: 456.5
  },
  {
    x: 916.5,
    y: 441.5
  },
  {
    x: 916.5,
    y: 426.5
  },
  {
    x: 916.5,
    y: 411.5
  },
  {
    x: 916.5,
    y: 396.5
  },
  {
    x: 916.5,
    y: 381.5
  },
  {
    x: 916.5,
    y: 366.5
  },
  {
    x: 916.5,
    y: 351.5
  },
  {
    x: 916.5,
    y: 336.5
  },
  {
    x: 916.5,
    y: 321.5
  },
  {
    x: 916.5,
    y: 306.5
  },
  {
    x: 916.5,
    y: 291.5
  },
  {
    x: 916.5,
    y: 276.5
  },
  {
    x: 916.5,
    y: 261.5
  },
  {
    x: 916.5,
    y: 201.5
  },
  {
    x: 916.5,
    y: 186.5
  },
  {
    x: 916.5,
    y: 156.5
  },
  {
    x: 916.5,
    y: 111.5
  },
  {
    x: 931.5,
    y: 2046.5
  },
  {
    x: 931.5,
    y: 2031.5
  },
  {
    x: 931.5,
    y: 2016.5
  },
  {
    x: 931.5,
    y: 2001.5
  },
  {
    x: 931.5,
    y: 1986.5
  },
  {
    x: 931.5,
    y: 1971.5
  },
  {
    x: 931.5,
    y: 1956.5
  },
  {
    x: 931.5,
    y: 1941.5
  },
  {
    x: 931.5,
    y: 1926.5
  },
  {
    x: 931.5,
    y: 1911.5
  },
  {
    x: 931.5,
    y: 1896.5
  },
  {
    x: 931.5,
    y: 1881.5
  },
  {
    x: 931.5,
    y: 1866.5
  },
  {
    x: 931.5,
    y: 1851.5
  },
  {
    x: 931.5,
    y: 831.5
  },
  {
    x: 931.5,
    y: 816.5
  },
  {
    x: 931.5,
    y: 801.5
  },
  {
    x: 931.5,
    y: 786.5
  },
  {
    x: 931.5,
    y: 771.5
  },
  {
    x: 931.5,
    y: 756.5
  },
  {
    x: 931.5,
    y: 741.5
  },
  {
    x: 931.5,
    y: 726.5
  },
  {
    x: 931.5,
    y: 711.5
  },
  {
    x: 931.5,
    y: 696.5
  },
  {
    x: 931.5,
    y: 681.5
  },
  {
    x: 931.5,
    y: 666.5
  },
  {
    x: 931.5,
    y: 651.5
  },
  {
    x: 931.5,
    y: 636.5
  },
  {
    x: 931.5,
    y: 621.5
  },
  {
    x: 931.5,
    y: 606.5
  },
  {
    x: 931.5,
    y: 591.5
  },
  {
    x: 931.5,
    y: 576.5
  },
  {
    x: 931.5,
    y: 561.5
  },
  {
    x: 931.5,
    y: 546.5
  },
  {
    x: 931.5,
    y: 531.5
  },
  {
    x: 931.5,
    y: 516.5
  },
  {
    x: 931.5,
    y: 501.5
  },
  {
    x: 931.5,
    y: 486.5
  },
  {
    x: 931.5,
    y: 471.5
  },
  {
    x: 931.5,
    y: 456.5
  },
  {
    x: 931.5,
    y: 441.5
  },
  {
    x: 931.5,
    y: 426.5
  },
  {
    x: 931.5,
    y: 411.5
  },
  {
    x: 931.5,
    y: 396.5
  },
  {
    x: 931.5,
    y: 381.5
  },
  {
    x: 931.5,
    y: 366.5
  },
  {
    x: 931.5,
    y: 351.5
  },
  {
    x: 931.5,
    y: 336.5
  },
  {
    x: 931.5,
    y: 321.5
  },
  {
    x: 931.5,
    y: 306.5
  },
  {
    x: 931.5,
    y: 291.5
  },
  {
    x: 931.5,
    y: 276.5
  },
  {
    x: 931.5,
    y: 261.5
  },
  {
    x: 931.5,
    y: 246.5
  },
  {
    x: 931.5,
    y: 231.5
  },
  {
    x: 931.5,
    y: 201.5
  },
  {
    x: 931.5,
    y: 186.5
  },
  {
    x: 931.5,
    y: 156.5
  },
  {
    x: 931.5,
    y: 126.5
  },
  {
    x: 946.5,
    y: 2046.5
  },
  {
    x: 946.5,
    y: 2031.5
  },
  {
    x: 946.5,
    y: 2016.5
  },
  {
    x: 946.5,
    y: 2001.5
  },
  {
    x: 946.5,
    y: 1986.5
  },
  {
    x: 946.5,
    y: 1971.5
  },
  {
    x: 946.5,
    y: 1956.5
  },
  {
    x: 946.5,
    y: 1941.5
  },
  {
    x: 946.5,
    y: 1926.5
  },
  {
    x: 946.5,
    y: 1911.5
  },
  {
    x: 946.5,
    y: 1896.5
  },
  {
    x: 946.5,
    y: 1881.5
  },
  {
    x: 946.5,
    y: 1866.5
  },
  {
    x: 946.5,
    y: 1851.5
  },
  {
    x: 946.5,
    y: 831.5
  },
  {
    x: 946.5,
    y: 816.5
  },
  {
    x: 946.5,
    y: 801.5
  },
  {
    x: 946.5,
    y: 696.5
  },
  {
    x: 946.5,
    y: 681.5
  },
  {
    x: 946.5,
    y: 666.5
  },
  {
    x: 946.5,
    y: 651.5
  },
  {
    x: 946.5,
    y: 636.5
  },
  {
    x: 946.5,
    y: 621.5
  },
  {
    x: 946.5,
    y: 606.5
  },
  {
    x: 946.5,
    y: 591.5
  },
  {
    x: 946.5,
    y: 576.5
  },
  {
    x: 946.5,
    y: 561.5
  },
  {
    x: 946.5,
    y: 546.5
  },
  {
    x: 946.5,
    y: 531.5
  },
  {
    x: 946.5,
    y: 516.5
  },
  {
    x: 946.5,
    y: 501.5
  },
  {
    x: 946.5,
    y: 486.5
  },
  {
    x: 946.5,
    y: 471.5
  },
  {
    x: 946.5,
    y: 456.5
  },
  {
    x: 946.5,
    y: 441.5
  },
  {
    x: 946.5,
    y: 426.5
  },
  {
    x: 946.5,
    y: 411.5
  },
  {
    x: 946.5,
    y: 396.5
  },
  {
    x: 946.5,
    y: 381.5
  },
  {
    x: 946.5,
    y: 366.5
  },
  {
    x: 946.5,
    y: 351.5
  },
  {
    x: 946.5,
    y: 336.5
  },
  {
    x: 946.5,
    y: 321.5
  },
  {
    x: 946.5,
    y: 306.5
  },
  {
    x: 946.5,
    y: 291.5
  },
  {
    x: 946.5,
    y: 276.5
  },
  {
    x: 946.5,
    y: 261.5
  },
  {
    x: 946.5,
    y: 246.5
  },
  {
    x: 946.5,
    y: 201.5
  },
  {
    x: 946.5,
    y: 126.5
  },
  {
    x: 946.5,
    y: 111.5
  },
  {
    x: 961.5,
    y: 2046.5
  },
  {
    x: 961.5,
    y: 2031.5
  },
  {
    x: 961.5,
    y: 2016.5
  },
  {
    x: 961.5,
    y: 2001.5
  },
  {
    x: 961.5,
    y: 1986.5
  },
  {
    x: 961.5,
    y: 1971.5
  },
  {
    x: 961.5,
    y: 1956.5
  },
  {
    x: 961.5,
    y: 1941.5
  },
  {
    x: 961.5,
    y: 1926.5
  },
  {
    x: 961.5,
    y: 1911.5
  },
  {
    x: 961.5,
    y: 1896.5
  },
  {
    x: 961.5,
    y: 1881.5
  },
  {
    x: 961.5,
    y: 1866.5
  },
  {
    x: 961.5,
    y: 831.5
  },
  {
    x: 961.5,
    y: 816.5
  },
  {
    x: 961.5,
    y: 696.5
  },
  {
    x: 961.5,
    y: 681.5
  },
  {
    x: 961.5,
    y: 666.5
  },
  {
    x: 961.5,
    y: 651.5
  },
  {
    x: 961.5,
    y: 636.5
  },
  {
    x: 961.5,
    y: 621.5
  },
  {
    x: 961.5,
    y: 606.5
  },
  {
    x: 961.5,
    y: 591.5
  },
  {
    x: 961.5,
    y: 576.5
  },
  {
    x: 961.5,
    y: 561.5
  },
  {
    x: 961.5,
    y: 546.5
  },
  {
    x: 961.5,
    y: 531.5
  },
  {
    x: 961.5,
    y: 516.5
  },
  {
    x: 961.5,
    y: 501.5
  },
  {
    x: 961.5,
    y: 486.5
  },
  {
    x: 961.5,
    y: 471.5
  },
  {
    x: 961.5,
    y: 456.5
  },
  {
    x: 961.5,
    y: 441.5
  },
  {
    x: 961.5,
    y: 426.5
  },
  {
    x: 961.5,
    y: 411.5
  },
  {
    x: 961.5,
    y: 396.5
  },
  {
    x: 961.5,
    y: 381.5
  },
  {
    x: 961.5,
    y: 366.5
  },
  {
    x: 961.5,
    y: 351.5
  },
  {
    x: 961.5,
    y: 336.5
  },
  {
    x: 961.5,
    y: 321.5
  },
  {
    x: 961.5,
    y: 306.5
  },
  {
    x: 961.5,
    y: 291.5
  },
  {
    x: 961.5,
    y: 276.5
  },
  {
    x: 961.5,
    y: 261.5
  },
  {
    x: 961.5,
    y: 231.5
  },
  {
    x: 961.5,
    y: 216.5
  },
  {
    x: 961.5,
    y: 186.5
  },
  {
    x: 961.5,
    y: 171.5
  },
  {
    x: 961.5,
    y: 156.5
  },
  {
    x: 961.5,
    y: 141.5
  },
  {
    x: 961.5,
    y: 111.5
  },
  {
    x: 976.5,
    y: 2046.5
  },
  {
    x: 976.5,
    y: 2031.5
  },
  {
    x: 976.5,
    y: 2016.5
  },
  {
    x: 976.5,
    y: 2001.5
  },
  {
    x: 976.5,
    y: 1986.5
  },
  {
    x: 976.5,
    y: 1971.5
  },
  {
    x: 976.5,
    y: 1956.5
  },
  {
    x: 976.5,
    y: 1941.5
  },
  {
    x: 976.5,
    y: 1926.5
  },
  {
    x: 976.5,
    y: 1911.5
  },
  {
    x: 976.5,
    y: 1896.5
  },
  {
    x: 976.5,
    y: 1881.5
  },
  {
    x: 976.5,
    y: 1866.5
  },
  {
    x: 976.5,
    y: 1851.5
  },
  {
    x: 976.5,
    y: 831.5
  },
  {
    x: 976.5,
    y: 816.5
  },
  {
    x: 976.5,
    y: 681.5
  },
  {
    x: 976.5,
    y: 666.5
  },
  {
    x: 976.5,
    y: 651.5
  },
  {
    x: 976.5,
    y: 636.5
  },
  {
    x: 976.5,
    y: 621.5
  },
  {
    x: 976.5,
    y: 606.5
  },
  {
    x: 976.5,
    y: 591.5
  },
  {
    x: 976.5,
    y: 576.5
  },
  {
    x: 976.5,
    y: 561.5
  },
  {
    x: 976.5,
    y: 546.5
  },
  {
    x: 976.5,
    y: 531.5
  },
  {
    x: 976.5,
    y: 516.5
  },
  {
    x: 976.5,
    y: 501.5
  },
  {
    x: 976.5,
    y: 486.5
  },
  {
    x: 976.5,
    y: 471.5
  },
  {
    x: 976.5,
    y: 456.5
  },
  {
    x: 976.5,
    y: 441.5
  },
  {
    x: 976.5,
    y: 426.5
  },
  {
    x: 976.5,
    y: 411.5
  },
  {
    x: 976.5,
    y: 396.5
  },
  {
    x: 976.5,
    y: 381.5
  },
  {
    x: 976.5,
    y: 366.5
  },
  {
    x: 976.5,
    y: 321.5
  },
  {
    x: 976.5,
    y: 306.5
  },
  {
    x: 976.5,
    y: 291.5
  },
  {
    x: 976.5,
    y: 276.5
  },
  {
    x: 976.5,
    y: 261.5
  },
  {
    x: 976.5,
    y: 246.5
  },
  {
    x: 976.5,
    y: 231.5
  },
  {
    x: 976.5,
    y: 216.5
  },
  {
    x: 976.5,
    y: 201.5
  },
  {
    x: 976.5,
    y: 186.5
  },
  {
    x: 976.5,
    y: 171.5
  },
  {
    x: 976.5,
    y: 156.5
  },
  {
    x: 976.5,
    y: 141.5
  },
  {
    x: 976.5,
    y: 111.5
  },
  {
    x: 991.5,
    y: 2046.5
  },
  {
    x: 991.5,
    y: 2031.5
  },
  {
    x: 991.5,
    y: 2016.5
  },
  {
    x: 991.5,
    y: 2001.5
  },
  {
    x: 991.5,
    y: 1986.5
  },
  {
    x: 991.5,
    y: 1971.5
  },
  {
    x: 991.5,
    y: 1956.5
  },
  {
    x: 991.5,
    y: 1941.5
  },
  {
    x: 991.5,
    y: 1926.5
  },
  {
    x: 991.5,
    y: 1911.5
  },
  {
    x: 991.5,
    y: 1896.5
  },
  {
    x: 991.5,
    y: 1881.5
  },
  {
    x: 991.5,
    y: 1866.5
  },
  {
    x: 991.5,
    y: 846.5
  },
  {
    x: 991.5,
    y: 831.5
  },
  {
    x: 991.5,
    y: 816.5
  },
  {
    x: 991.5,
    y: 681.5
  },
  {
    x: 991.5,
    y: 666.5
  },
  {
    x: 991.5,
    y: 651.5
  },
  {
    x: 991.5,
    y: 636.5
  },
  {
    x: 991.5,
    y: 621.5
  },
  {
    x: 991.5,
    y: 606.5
  },
  {
    x: 991.5,
    y: 591.5
  },
  {
    x: 991.5,
    y: 576.5
  },
  {
    x: 991.5,
    y: 561.5
  },
  {
    x: 991.5,
    y: 546.5
  },
  {
    x: 991.5,
    y: 531.5
  },
  {
    x: 991.5,
    y: 516.5
  },
  {
    x: 991.5,
    y: 501.5
  },
  {
    x: 991.5,
    y: 486.5
  },
  {
    x: 991.5,
    y: 471.5
  },
  {
    x: 991.5,
    y: 456.5
  },
  {
    x: 991.5,
    y: 441.5
  },
  {
    x: 991.5,
    y: 426.5
  },
  {
    x: 991.5,
    y: 411.5
  },
  {
    x: 991.5,
    y: 396.5
  },
  {
    x: 991.5,
    y: 381.5
  },
  {
    x: 991.5,
    y: 366.5
  },
  {
    x: 991.5,
    y: 306.5
  },
  {
    x: 991.5,
    y: 291.5
  },
  {
    x: 991.5,
    y: 276.5
  },
  {
    x: 991.5,
    y: 261.5
  },
  {
    x: 991.5,
    y: 246.5
  },
  {
    x: 991.5,
    y: 231.5
  },
  {
    x: 991.5,
    y: 216.5
  },
  {
    x: 991.5,
    y: 186.5
  },
  {
    x: 991.5,
    y: 171.5
  },
  {
    x: 991.5,
    y: 156.5
  },
  {
    x: 991.5,
    y: 126.5
  },
  {
    x: 991.5,
    y: 111.5
  },
  {
    x: 1006.5,
    y: 2046.5
  },
  {
    x: 1006.5,
    y: 2031.5
  },
  {
    x: 1006.5,
    y: 2016.5
  },
  {
    x: 1006.5,
    y: 2001.5
  },
  {
    x: 1006.5,
    y: 1986.5
  },
  {
    x: 1006.5,
    y: 1971.5
  },
  {
    x: 1006.5,
    y: 1956.5
  },
  {
    x: 1006.5,
    y: 1941.5
  },
  {
    x: 1006.5,
    y: 1926.5
  },
  {
    x: 1006.5,
    y: 1911.5
  },
  {
    x: 1006.5,
    y: 1896.5
  },
  {
    x: 1006.5,
    y: 1881.5
  },
  {
    x: 1006.5,
    y: 1866.5
  },
  {
    x: 1006.5,
    y: 1851.5
  },
  {
    x: 1006.5,
    y: 861.5
  },
  {
    x: 1006.5,
    y: 846.5
  },
  {
    x: 1006.5,
    y: 831.5
  },
  {
    x: 1006.5,
    y: 816.5
  },
  {
    x: 1006.5,
    y: 681.5
  },
  {
    x: 1006.5,
    y: 666.5
  },
  {
    x: 1006.5,
    y: 651.5
  },
  {
    x: 1006.5,
    y: 636.5
  },
  {
    x: 1006.5,
    y: 621.5
  },
  {
    x: 1006.5,
    y: 606.5
  },
  {
    x: 1006.5,
    y: 591.5
  },
  {
    x: 1006.5,
    y: 576.5
  },
  {
    x: 1006.5,
    y: 561.5
  },
  {
    x: 1006.5,
    y: 546.5
  },
  {
    x: 1006.5,
    y: 531.5
  },
  {
    x: 1006.5,
    y: 516.5
  },
  {
    x: 1006.5,
    y: 501.5
  },
  {
    x: 1006.5,
    y: 486.5
  },
  {
    x: 1006.5,
    y: 471.5
  },
  {
    x: 1006.5,
    y: 456.5
  },
  {
    x: 1006.5,
    y: 441.5
  },
  {
    x: 1006.5,
    y: 426.5
  },
  {
    x: 1006.5,
    y: 411.5
  },
  {
    x: 1006.5,
    y: 396.5
  },
  {
    x: 1006.5,
    y: 381.5
  },
  {
    x: 1006.5,
    y: 306.5
  },
  {
    x: 1006.5,
    y: 291.5
  },
  {
    x: 1006.5,
    y: 276.5
  },
  {
    x: 1006.5,
    y: 261.5
  },
  {
    x: 1006.5,
    y: 246.5
  },
  {
    x: 1006.5,
    y: 231.5
  },
  {
    x: 1006.5,
    y: 186.5
  },
  {
    x: 1006.5,
    y: 171.5
  },
  {
    x: 1006.5,
    y: 156.5
  },
  {
    x: 1006.5,
    y: 126.5
  },
  {
    x: 1006.5,
    y: 111.5
  },
  {
    x: 1021.5,
    y: 2046.5
  },
  {
    x: 1021.5,
    y: 2031.5
  },
  {
    x: 1021.5,
    y: 2016.5
  },
  {
    x: 1021.5,
    y: 2001.5
  },
  {
    x: 1021.5,
    y: 1986.5
  },
  {
    x: 1021.5,
    y: 1971.5
  },
  {
    x: 1021.5,
    y: 1956.5
  },
  {
    x: 1021.5,
    y: 1941.5
  },
  {
    x: 1021.5,
    y: 1926.5
  },
  {
    x: 1021.5,
    y: 1911.5
  },
  {
    x: 1021.5,
    y: 1896.5
  },
  {
    x: 1021.5,
    y: 1881.5
  },
  {
    x: 1021.5,
    y: 1866.5
  },
  {
    x: 1021.5,
    y: 861.5
  },
  {
    x: 1021.5,
    y: 846.5
  },
  {
    x: 1021.5,
    y: 831.5
  },
  {
    x: 1021.5,
    y: 816.5
  },
  {
    x: 1021.5,
    y: 801.5
  },
  {
    x: 1021.5,
    y: 786.5
  },
  {
    x: 1021.5,
    y: 681.5
  },
  {
    x: 1021.5,
    y: 666.5
  },
  {
    x: 1021.5,
    y: 651.5
  },
  {
    x: 1021.5,
    y: 636.5
  },
  {
    x: 1021.5,
    y: 621.5
  },
  {
    x: 1021.5,
    y: 606.5
  },
  {
    x: 1021.5,
    y: 591.5
  },
  {
    x: 1021.5,
    y: 576.5
  },
  {
    x: 1021.5,
    y: 561.5
  },
  {
    x: 1021.5,
    y: 546.5
  },
  {
    x: 1021.5,
    y: 531.5
  },
  {
    x: 1021.5,
    y: 516.5
  },
  {
    x: 1021.5,
    y: 501.5
  },
  {
    x: 1021.5,
    y: 486.5
  },
  {
    x: 1021.5,
    y: 471.5
  },
  {
    x: 1021.5,
    y: 456.5
  },
  {
    x: 1021.5,
    y: 441.5
  },
  {
    x: 1021.5,
    y: 426.5
  },
  {
    x: 1021.5,
    y: 411.5
  },
  {
    x: 1021.5,
    y: 396.5
  },
  {
    x: 1021.5,
    y: 381.5
  },
  {
    x: 1021.5,
    y: 291.5
  },
  {
    x: 1021.5,
    y: 276.5
  },
  {
    x: 1021.5,
    y: 261.5
  },
  {
    x: 1021.5,
    y: 246.5
  },
  {
    x: 1021.5,
    y: 171.5
  },
  {
    x: 1021.5,
    y: 156.5
  },
  {
    x: 1021.5,
    y: 141.5
  },
  {
    x: 1021.5,
    y: 126.5
  },
  {
    x: 1021.5,
    y: 111.5
  },
  {
    x: 1021.5,
    y: 96.5
  },
  {
    x: 1036.5,
    y: 2046.5
  },
  {
    x: 1036.5,
    y: 2031.5
  },
  {
    x: 1036.5,
    y: 2016.5
  },
  {
    x: 1036.5,
    y: 2001.5
  },
  {
    x: 1036.5,
    y: 1986.5
  },
  {
    x: 1036.5,
    y: 1971.5
  },
  {
    x: 1036.5,
    y: 1956.5
  },
  {
    x: 1036.5,
    y: 1941.5
  },
  {
    x: 1036.5,
    y: 1926.5
  },
  {
    x: 1036.5,
    y: 1911.5
  },
  {
    x: 1036.5,
    y: 1896.5
  },
  {
    x: 1036.5,
    y: 1881.5
  },
  {
    x: 1036.5,
    y: 1866.5
  },
  {
    x: 1036.5,
    y: 1851.5
  },
  {
    x: 1036.5,
    y: 861.5
  },
  {
    x: 1036.5,
    y: 846.5
  },
  {
    x: 1036.5,
    y: 831.5
  },
  {
    x: 1036.5,
    y: 816.5
  },
  {
    x: 1036.5,
    y: 801.5
  },
  {
    x: 1036.5,
    y: 786.5
  },
  {
    x: 1036.5,
    y: 666.5
  },
  {
    x: 1036.5,
    y: 651.5
  },
  {
    x: 1036.5,
    y: 636.5
  },
  {
    x: 1036.5,
    y: 621.5
  },
  {
    x: 1036.5,
    y: 606.5
  },
  {
    x: 1036.5,
    y: 591.5
  },
  {
    x: 1036.5,
    y: 576.5
  },
  {
    x: 1036.5,
    y: 561.5
  },
  {
    x: 1036.5,
    y: 546.5
  },
  {
    x: 1036.5,
    y: 531.5
  },
  {
    x: 1036.5,
    y: 516.5
  },
  {
    x: 1036.5,
    y: 501.5
  },
  {
    x: 1036.5,
    y: 486.5
  },
  {
    x: 1036.5,
    y: 471.5
  },
  {
    x: 1036.5,
    y: 456.5
  },
  {
    x: 1036.5,
    y: 441.5
  },
  {
    x: 1036.5,
    y: 426.5
  },
  {
    x: 1036.5,
    y: 411.5
  },
  {
    x: 1036.5,
    y: 396.5
  },
  {
    x: 1036.5,
    y: 381.5
  },
  {
    x: 1036.5,
    y: 291.5
  },
  {
    x: 1036.5,
    y: 276.5
  },
  {
    x: 1036.5,
    y: 261.5
  },
  {
    x: 1036.5,
    y: 246.5
  },
  {
    x: 1036.5,
    y: 216.5
  },
  {
    x: 1036.5,
    y: 201.5
  },
  {
    x: 1036.5,
    y: 171.5
  },
  {
    x: 1036.5,
    y: 126.5
  },
  {
    x: 1036.5,
    y: 111.5
  },
  {
    x: 1036.5,
    y: 96.5
  },
  {
    x: 1051.5,
    y: 2046.5
  },
  {
    x: 1051.5,
    y: 2031.5
  },
  {
    x: 1051.5,
    y: 2016.5
  },
  {
    x: 1051.5,
    y: 2001.5
  },
  {
    x: 1051.5,
    y: 1986.5
  },
  {
    x: 1051.5,
    y: 1971.5
  },
  {
    x: 1051.5,
    y: 1956.5
  },
  {
    x: 1051.5,
    y: 1941.5
  },
  {
    x: 1051.5,
    y: 1926.5
  },
  {
    x: 1051.5,
    y: 1911.5
  },
  {
    x: 1051.5,
    y: 1896.5
  },
  {
    x: 1051.5,
    y: 1881.5
  },
  {
    x: 1051.5,
    y: 1866.5
  },
  {
    x: 1051.5,
    y: 861.5
  },
  {
    x: 1051.5,
    y: 846.5
  },
  {
    x: 1051.5,
    y: 801.5
  },
  {
    x: 1051.5,
    y: 786.5
  },
  {
    x: 1051.5,
    y: 666.5
  },
  {
    x: 1051.5,
    y: 651.5
  },
  {
    x: 1051.5,
    y: 636.5
  },
  {
    x: 1051.5,
    y: 621.5
  },
  {
    x: 1051.5,
    y: 606.5
  },
  {
    x: 1051.5,
    y: 591.5
  },
  {
    x: 1051.5,
    y: 576.5
  },
  {
    x: 1051.5,
    y: 561.5
  },
  {
    x: 1051.5,
    y: 546.5
  },
  {
    x: 1051.5,
    y: 531.5
  },
  {
    x: 1051.5,
    y: 516.5
  },
  {
    x: 1051.5,
    y: 501.5
  },
  {
    x: 1051.5,
    y: 486.5
  },
  {
    x: 1051.5,
    y: 471.5
  },
  {
    x: 1051.5,
    y: 456.5
  },
  {
    x: 1051.5,
    y: 441.5
  },
  {
    x: 1051.5,
    y: 426.5
  },
  {
    x: 1051.5,
    y: 411.5
  },
  {
    x: 1051.5,
    y: 396.5
  },
  {
    x: 1051.5,
    y: 276.5
  },
  {
    x: 1051.5,
    y: 261.5
  },
  {
    x: 1051.5,
    y: 216.5
  },
  {
    x: 1051.5,
    y: 201.5
  },
  {
    x: 1051.5,
    y: 186.5
  },
  {
    x: 1051.5,
    y: 171.5
  },
  {
    x: 1051.5,
    y: 141.5
  },
  {
    x: 1051.5,
    y: 126.5
  },
  {
    x: 1051.5,
    y: 111.5
  },
  {
    x: 1051.5,
    y: 96.5
  },
  {
    x: 1066.5,
    y: 2046.5
  },
  {
    x: 1066.5,
    y: 2031.5
  },
  {
    x: 1066.5,
    y: 2016.5
  },
  {
    x: 1066.5,
    y: 2001.5
  },
  {
    x: 1066.5,
    y: 1986.5
  },
  {
    x: 1066.5,
    y: 1971.5
  },
  {
    x: 1066.5,
    y: 1956.5
  },
  {
    x: 1066.5,
    y: 1941.5
  },
  {
    x: 1066.5,
    y: 1926.5
  },
  {
    x: 1066.5,
    y: 1911.5
  },
  {
    x: 1066.5,
    y: 1896.5
  },
  {
    x: 1066.5,
    y: 1881.5
  },
  {
    x: 1066.5,
    y: 1866.5
  },
  {
    x: 1066.5,
    y: 906.5
  },
  {
    x: 1066.5,
    y: 891.5
  },
  {
    x: 1066.5,
    y: 876.5
  },
  {
    x: 1066.5,
    y: 861.5
  },
  {
    x: 1066.5,
    y: 846.5
  },
  {
    x: 1066.5,
    y: 666.5
  },
  {
    x: 1066.5,
    y: 651.5
  },
  {
    x: 1066.5,
    y: 636.5
  },
  {
    x: 1066.5,
    y: 621.5
  },
  {
    x: 1066.5,
    y: 606.5
  },
  {
    x: 1066.5,
    y: 591.5
  },
  {
    x: 1066.5,
    y: 576.5
  },
  {
    x: 1066.5,
    y: 561.5
  },
  {
    x: 1066.5,
    y: 546.5
  },
  {
    x: 1066.5,
    y: 531.5
  },
  {
    x: 1066.5,
    y: 516.5
  },
  {
    x: 1066.5,
    y: 501.5
  },
  {
    x: 1066.5,
    y: 486.5
  },
  {
    x: 1066.5,
    y: 471.5
  },
  {
    x: 1066.5,
    y: 456.5
  },
  {
    x: 1066.5,
    y: 441.5
  },
  {
    x: 1066.5,
    y: 426.5
  },
  {
    x: 1066.5,
    y: 411.5
  },
  {
    x: 1066.5,
    y: 396.5
  },
  {
    x: 1066.5,
    y: 291.5
  },
  {
    x: 1066.5,
    y: 261.5
  },
  {
    x: 1066.5,
    y: 246.5
  },
  {
    x: 1066.5,
    y: 216.5
  },
  {
    x: 1066.5,
    y: 201.5
  },
  {
    x: 1066.5,
    y: 186.5
  },
  {
    x: 1066.5,
    y: 171.5
  },
  {
    x: 1066.5,
    y: 141.5
  },
  {
    x: 1066.5,
    y: 126.5
  },
  {
    x: 1066.5,
    y: 111.5
  },
  {
    x: 1066.5,
    y: 96.5
  },
  {
    x: 1081.5,
    y: 2046.5
  },
  {
    x: 1081.5,
    y: 2031.5
  },
  {
    x: 1081.5,
    y: 2016.5
  },
  {
    x: 1081.5,
    y: 2001.5
  },
  {
    x: 1081.5,
    y: 1986.5
  },
  {
    x: 1081.5,
    y: 1971.5
  },
  {
    x: 1081.5,
    y: 1956.5
  },
  {
    x: 1081.5,
    y: 1941.5
  },
  {
    x: 1081.5,
    y: 1926.5
  },
  {
    x: 1081.5,
    y: 1911.5
  },
  {
    x: 1081.5,
    y: 1896.5
  },
  {
    x: 1081.5,
    y: 1881.5
  },
  {
    x: 1081.5,
    y: 1866.5
  },
  {
    x: 1081.5,
    y: 906.5
  },
  {
    x: 1081.5,
    y: 891.5
  },
  {
    x: 1081.5,
    y: 876.5
  },
  {
    x: 1081.5,
    y: 861.5
  },
  {
    x: 1081.5,
    y: 846.5
  },
  {
    x: 1081.5,
    y: 681.5
  },
  {
    x: 1081.5,
    y: 666.5
  },
  {
    x: 1081.5,
    y: 651.5
  },
  {
    x: 1081.5,
    y: 636.5
  },
  {
    x: 1081.5,
    y: 621.5
  },
  {
    x: 1081.5,
    y: 606.5
  },
  {
    x: 1081.5,
    y: 591.5
  },
  {
    x: 1081.5,
    y: 576.5
  },
  {
    x: 1081.5,
    y: 561.5
  },
  {
    x: 1081.5,
    y: 546.5
  },
  {
    x: 1081.5,
    y: 531.5
  },
  {
    x: 1081.5,
    y: 516.5
  },
  {
    x: 1081.5,
    y: 501.5
  },
  {
    x: 1081.5,
    y: 486.5
  },
  {
    x: 1081.5,
    y: 471.5
  },
  {
    x: 1081.5,
    y: 456.5
  },
  {
    x: 1081.5,
    y: 441.5
  },
  {
    x: 1081.5,
    y: 426.5
  },
  {
    x: 1081.5,
    y: 411.5
  },
  {
    x: 1081.5,
    y: 396.5
  },
  {
    x: 1081.5,
    y: 291.5
  },
  {
    x: 1081.5,
    y: 276.5
  },
  {
    x: 1081.5,
    y: 261.5
  },
  {
    x: 1081.5,
    y: 246.5
  },
  {
    x: 1081.5,
    y: 231.5
  },
  {
    x: 1081.5,
    y: 216.5
  },
  {
    x: 1081.5,
    y: 201.5
  },
  {
    x: 1081.5,
    y: 186.5
  },
  {
    x: 1081.5,
    y: 171.5
  },
  {
    x: 1081.5,
    y: 141.5
  },
  {
    x: 1081.5,
    y: 126.5
  },
  {
    x: 1081.5,
    y: 111.5
  },
  {
    x: 1081.5,
    y: 96.5
  },
  {
    x: 1096.5,
    y: 2046.5
  },
  {
    x: 1096.5,
    y: 2031.5
  },
  {
    x: 1096.5,
    y: 2016.5
  },
  {
    x: 1096.5,
    y: 2001.5
  },
  {
    x: 1096.5,
    y: 1986.5
  },
  {
    x: 1096.5,
    y: 1971.5
  },
  {
    x: 1096.5,
    y: 1956.5
  },
  {
    x: 1096.5,
    y: 1941.5
  },
  {
    x: 1096.5,
    y: 1926.5
  },
  {
    x: 1096.5,
    y: 1911.5
  },
  {
    x: 1096.5,
    y: 1896.5
  },
  {
    x: 1096.5,
    y: 1881.5
  },
  {
    x: 1096.5,
    y: 1866.5
  },
  {
    x: 1096.5,
    y: 921.5
  },
  {
    x: 1096.5,
    y: 906.5
  },
  {
    x: 1096.5,
    y: 891.5
  },
  {
    x: 1096.5,
    y: 876.5
  },
  {
    x: 1096.5,
    y: 861.5
  },
  {
    x: 1096.5,
    y: 846.5
  },
  {
    x: 1096.5,
    y: 771.5
  },
  {
    x: 1096.5,
    y: 681.5
  },
  {
    x: 1096.5,
    y: 666.5
  },
  {
    x: 1096.5,
    y: 651.5
  },
  {
    x: 1096.5,
    y: 636.5
  },
  {
    x: 1096.5,
    y: 621.5
  },
  {
    x: 1096.5,
    y: 606.5
  },
  {
    x: 1096.5,
    y: 591.5
  },
  {
    x: 1096.5,
    y: 576.5
  },
  {
    x: 1096.5,
    y: 561.5
  },
  {
    x: 1096.5,
    y: 546.5
  },
  {
    x: 1096.5,
    y: 531.5
  },
  {
    x: 1096.5,
    y: 516.5
  },
  {
    x: 1096.5,
    y: 501.5
  },
  {
    x: 1096.5,
    y: 486.5
  },
  {
    x: 1096.5,
    y: 471.5
  },
  {
    x: 1096.5,
    y: 456.5
  },
  {
    x: 1096.5,
    y: 441.5
  },
  {
    x: 1096.5,
    y: 426.5
  },
  {
    x: 1096.5,
    y: 411.5
  },
  {
    x: 1096.5,
    y: 396.5
  },
  {
    x: 1096.5,
    y: 291.5
  },
  {
    x: 1096.5,
    y: 276.5
  },
  {
    x: 1096.5,
    y: 261.5
  },
  {
    x: 1096.5,
    y: 246.5
  },
  {
    x: 1096.5,
    y: 231.5
  },
  {
    x: 1096.5,
    y: 216.5
  },
  {
    x: 1096.5,
    y: 201.5
  },
  {
    x: 1096.5,
    y: 186.5
  },
  {
    x: 1096.5,
    y: 171.5
  },
  {
    x: 1096.5,
    y: 141.5
  },
  {
    x: 1096.5,
    y: 126.5
  },
  {
    x: 1096.5,
    y: 111.5
  },
  {
    x: 1096.5,
    y: 96.5
  },
  {
    x: 1111.5,
    y: 2046.5
  },
  {
    x: 1111.5,
    y: 2031.5
  },
  {
    x: 1111.5,
    y: 2016.5
  },
  {
    x: 1111.5,
    y: 2001.5
  },
  {
    x: 1111.5,
    y: 1986.5
  },
  {
    x: 1111.5,
    y: 1971.5
  },
  {
    x: 1111.5,
    y: 1956.5
  },
  {
    x: 1111.5,
    y: 1941.5
  },
  {
    x: 1111.5,
    y: 1926.5
  },
  {
    x: 1111.5,
    y: 1911.5
  },
  {
    x: 1111.5,
    y: 1896.5
  },
  {
    x: 1111.5,
    y: 1881.5
  },
  {
    x: 1111.5,
    y: 1866.5
  },
  {
    x: 1111.5,
    y: 921.5
  },
  {
    x: 1111.5,
    y: 771.5
  },
  {
    x: 1111.5,
    y: 711.5
  },
  {
    x: 1111.5,
    y: 696.5
  },
  {
    x: 1111.5,
    y: 681.5
  },
  {
    x: 1111.5,
    y: 666.5
  },
  {
    x: 1111.5,
    y: 651.5
  },
  {
    x: 1111.5,
    y: 636.5
  },
  {
    x: 1111.5,
    y: 621.5
  },
  {
    x: 1111.5,
    y: 606.5
  },
  {
    x: 1111.5,
    y: 591.5
  },
  {
    x: 1111.5,
    y: 576.5
  },
  {
    x: 1111.5,
    y: 561.5
  },
  {
    x: 1111.5,
    y: 546.5
  },
  {
    x: 1111.5,
    y: 531.5
  },
  {
    x: 1111.5,
    y: 516.5
  },
  {
    x: 1111.5,
    y: 501.5
  },
  {
    x: 1111.5,
    y: 486.5
  },
  {
    x: 1111.5,
    y: 471.5
  },
  {
    x: 1111.5,
    y: 456.5
  },
  {
    x: 1111.5,
    y: 441.5
  },
  {
    x: 1111.5,
    y: 426.5
  },
  {
    x: 1111.5,
    y: 411.5
  },
  {
    x: 1111.5,
    y: 396.5
  },
  {
    x: 1111.5,
    y: 306.5
  },
  {
    x: 1111.5,
    y: 291.5
  },
  {
    x: 1111.5,
    y: 261.5
  },
  {
    x: 1111.5,
    y: 246.5
  },
  {
    x: 1111.5,
    y: 231.5
  },
  {
    x: 1111.5,
    y: 216.5
  },
  {
    x: 1111.5,
    y: 201.5
  },
  {
    x: 1111.5,
    y: 186.5
  },
  {
    x: 1111.5,
    y: 171.5
  },
  {
    x: 1111.5,
    y: 141.5
  },
  {
    x: 1111.5,
    y: 126.5
  },
  {
    x: 1111.5,
    y: 111.5
  },
  {
    x: 1111.5,
    y: 96.5
  },
  {
    x: 1126.5,
    y: 2046.5
  },
  {
    x: 1126.5,
    y: 2031.5
  },
  {
    x: 1126.5,
    y: 2016.5
  },
  {
    x: 1126.5,
    y: 2001.5
  },
  {
    x: 1126.5,
    y: 1986.5
  },
  {
    x: 1126.5,
    y: 1971.5
  },
  {
    x: 1126.5,
    y: 1956.5
  },
  {
    x: 1126.5,
    y: 1941.5
  },
  {
    x: 1126.5,
    y: 1926.5
  },
  {
    x: 1126.5,
    y: 1911.5
  },
  {
    x: 1126.5,
    y: 1896.5
  },
  {
    x: 1126.5,
    y: 1881.5
  },
  {
    x: 1126.5,
    y: 1866.5
  },
  {
    x: 1126.5,
    y: 1086.5
  },
  {
    x: 1126.5,
    y: 1071.5
  },
  {
    x: 1126.5,
    y: 936.5
  },
  {
    x: 1126.5,
    y: 921.5
  },
  {
    x: 1126.5,
    y: 771.5
  },
  {
    x: 1126.5,
    y: 726.5
  },
  {
    x: 1126.5,
    y: 711.5
  },
  {
    x: 1126.5,
    y: 696.5
  },
  {
    x: 1126.5,
    y: 651.5
  },
  {
    x: 1126.5,
    y: 636.5
  },
  {
    x: 1126.5,
    y: 621.5
  },
  {
    x: 1126.5,
    y: 606.5
  },
  {
    x: 1126.5,
    y: 591.5
  },
  {
    x: 1126.5,
    y: 576.5
  },
  {
    x: 1126.5,
    y: 561.5
  },
  {
    x: 1126.5,
    y: 546.5
  },
  {
    x: 1126.5,
    y: 531.5
  },
  {
    x: 1126.5,
    y: 516.5
  },
  {
    x: 1126.5,
    y: 501.5
  },
  {
    x: 1126.5,
    y: 486.5
  },
  {
    x: 1126.5,
    y: 471.5
  },
  {
    x: 1126.5,
    y: 456.5
  },
  {
    x: 1126.5,
    y: 441.5
  },
  {
    x: 1126.5,
    y: 231.5
  },
  {
    x: 1126.5,
    y: 216.5
  },
  {
    x: 1126.5,
    y: 201.5
  },
  {
    x: 1126.5,
    y: 186.5
  },
  {
    x: 1126.5,
    y: 171.5
  },
  {
    x: 1126.5,
    y: 141.5
  },
  {
    x: 1126.5,
    y: 126.5
  },
  {
    x: 1126.5,
    y: 111.5
  },
  {
    x: 1126.5,
    y: 96.5
  },
  {
    x: 1126.5,
    y: 81.5
  },
  {
    x: 1141.5,
    y: 2046.5
  },
  {
    x: 1141.5,
    y: 2031.5
  },
  {
    x: 1141.5,
    y: 2016.5
  },
  {
    x: 1141.5,
    y: 2001.5
  },
  {
    x: 1141.5,
    y: 1986.5
  },
  {
    x: 1141.5,
    y: 1971.5
  },
  {
    x: 1141.5,
    y: 1956.5
  },
  {
    x: 1141.5,
    y: 1941.5
  },
  {
    x: 1141.5,
    y: 1926.5
  },
  {
    x: 1141.5,
    y: 1911.5
  },
  {
    x: 1141.5,
    y: 1896.5
  },
  {
    x: 1141.5,
    y: 1881.5
  },
  {
    x: 1141.5,
    y: 1866.5
  },
  {
    x: 1141.5,
    y: 1101.5
  },
  {
    x: 1141.5,
    y: 1086.5
  },
  {
    x: 1141.5,
    y: 1071.5
  },
  {
    x: 1141.5,
    y: 1056.5
  },
  {
    x: 1141.5,
    y: 1041.5
  },
  {
    x: 1141.5,
    y: 1026.5
  },
  {
    x: 1021.5,
    y: 1026.5
  },
  {
    x: 1006.5,
    y: 1026.5
  },
  {
    x: 1141.5,
    y: 1011.5
  },
  {
    x: 1141.5,
    y: 921.5
  },
  {
    x: 1141.5,
    y: 771.5
  },
  {
    x: 1141.5,
    y: 636.5
  },
  {
    x: 1141.5,
    y: 621.5
  },
  {
    x: 1141.5,
    y: 606.5
  },
  {
    x: 1141.5,
    y: 591.5
  },
  {
    x: 1141.5,
    y: 576.5
  },
  {
    x: 1141.5,
    y: 561.5
  },
  {
    x: 1141.5,
    y: 546.5
  },
  {
    x: 1141.5,
    y: 531.5
  },
  {
    x: 1141.5,
    y: 516.5
  },
  {
    x: 1141.5,
    y: 501.5
  },
  {
    x: 1141.5,
    y: 486.5
  },
  {
    x: 1141.5,
    y: 471.5
  },
  {
    x: 1141.5,
    y: 456.5
  },
  {
    x: 1141.5,
    y: 441.5
  },
  {
    x: 1141.5,
    y: 321.5
  },
  {
    x: 1141.5,
    y: 231.5
  },
  {
    x: 1141.5,
    y: 216.5
  },
  {
    x: 1141.5,
    y: 201.5
  },
  {
    x: 1141.5,
    y: 186.5
  },
  {
    x: 1141.5,
    y: 171.5
  },
  {
    x: 1141.5,
    y: 156.5
  },
  {
    x: 1141.5,
    y: 141.5
  },
  {
    x: 1141.5,
    y: 126.5
  },
  {
    x: 1141.5,
    y: 111.5
  },
  {
    x: 1141.5,
    y: 96.5
  },
  {
    x: 1141.5,
    y: 81.5
  },
  {
    x: 1156.5,
    y: 2046.5
  },
  {
    x: 1156.5,
    y: 2031.5
  },
  {
    x: 1156.5,
    y: 2016.5
  },
  {
    x: 1156.5,
    y: 2001.5
  },
  {
    x: 1156.5,
    y: 1986.5
  },
  {
    x: 1156.5,
    y: 1971.5
  },
  {
    x: 1156.5,
    y: 1956.5
  },
  {
    x: 1156.5,
    y: 1941.5
  },
  {
    x: 1156.5,
    y: 1926.5
  },
  {
    x: 1156.5,
    y: 1911.5
  },
  {
    x: 1156.5,
    y: 1896.5
  },
  {
    x: 1156.5,
    y: 1881.5
  },
  {
    x: 1156.5,
    y: 1866.5
  },
  {
    x: 1156.5,
    y: 1131.5
  },
  {
    x: 1156.5,
    y: 1116.5
  },
  {
    x: 1156.5,
    y: 1101.5
  },
  {
    x: 1156.5,
    y: 1086.5
  },
  {
    x: 1156.5,
    y: 1071.5
  },
  {
    x: 1156.5,
    y: 1056.5
  },
  {
    x: 1156.5,
    y: 1041.5
  },
  {
    x: 1156.5,
    y: 1026.5
  },
  {
    x: 1156.5,
    y: 1011.5
  },
  {
    x: 1156.5,
    y: 996.5
  },
  {
    x: 1156.5,
    y: 921.5
  },
  {
    x: 1156.5,
    y: 816.5
  },
  {
    x: 1156.5,
    y: 786.5
  },
  {
    x: 1156.5,
    y: 771.5
  },
  {
    x: 1156.5,
    y: 741.5
  },
  {
    x: 1156.5,
    y: 636.5
  },
  {
    x: 1156.5,
    y: 621.5
  },
  {
    x: 1156.5,
    y: 606.5
  },
  {
    x: 1156.5,
    y: 591.5
  },
  {
    x: 1156.5,
    y: 576.5
  },
  {
    x: 1156.5,
    y: 561.5
  },
  {
    x: 1156.5,
    y: 546.5
  },
  {
    x: 1156.5,
    y: 531.5
  },
  {
    x: 1156.5,
    y: 516.5
  },
  {
    x: 1156.5,
    y: 501.5
  },
  {
    x: 1156.5,
    y: 486.5
  },
  {
    x: 1156.5,
    y: 471.5
  },
  {
    x: 1156.5,
    y: 456.5
  },
  {
    x: 1156.5,
    y: 441.5
  },
  {
    x: 1156.5,
    y: 426.5
  },
  {
    x: 1156.5,
    y: 411.5
  },
  {
    x: 1156.5,
    y: 396.5
  },
  {
    x: 1156.5,
    y: 351.5
  },
  {
    x: 1156.5,
    y: 231.5
  },
  {
    x: 1156.5,
    y: 216.5
  },
  {
    x: 1156.5,
    y: 201.5
  },
  {
    x: 1156.5,
    y: 186.5
  },
  {
    x: 1156.5,
    y: 141.5
  },
  {
    x: 1156.5,
    y: 126.5
  },
  {
    x: 1156.5,
    y: 111.5
  },
  {
    x: 1156.5,
    y: 96.5
  },
  {
    x: 1156.5,
    y: 81.5
  },
  {
    x: 1171.5,
    y: 2046.5
  },
  {
    x: 1171.5,
    y: 2031.5
  },
  {
    x: 1171.5,
    y: 2016.5
  },
  {
    x: 1171.5,
    y: 2001.5
  },
  {
    x: 1171.5,
    y: 1986.5
  },
  {
    x: 1171.5,
    y: 1971.5
  },
  {
    x: 1171.5,
    y: 1956.5
  },
  {
    x: 1171.5,
    y: 1941.5
  },
  {
    x: 1171.5,
    y: 1926.5
  },
  {
    x: 1171.5,
    y: 1911.5
  },
  {
    x: 1171.5,
    y: 1896.5
  },
  {
    x: 1171.5,
    y: 1881.5
  },
  {
    x: 1171.5,
    y: 1866.5
  },
  {
    x: 1171.5,
    y: 1161.5
  },
  {
    x: 1171.5,
    y: 1146.5
  },
  {
    x: 1171.5,
    y: 1131.5
  },
  {
    x: 1171.5,
    y: 1116.5
  },
  {
    x: 1171.5,
    y: 1101.5
  },
  {
    x: 1171.5,
    y: 1086.5
  },
  {
    x: 1171.5,
    y: 1071.5
  },
  {
    x: 1171.5,
    y: 1056.5
  },
  {
    x: 1171.5,
    y: 1041.5
  },
  {
    x: 1171.5,
    y: 1026.5
  },
  {
    x: 1171.5,
    y: 1011.5
  },
  {
    x: 1171.5,
    y: 996.5
  },
  {
    x: 1171.5,
    y: 981.5
  },
  {
    x: 1171.5,
    y: 966.5
  },
  {
    x: 1171.5,
    y: 951.5
  },
  {
    x: 1171.5,
    y: 936.5
  },
  {
    x: 1171.5,
    y: 816.5
  },
  {
    x: 1171.5,
    y: 786.5
  },
  {
    x: 1171.5,
    y: 726.5
  },
  {
    x: 1171.5,
    y: 621.5
  },
  {
    x: 1171.5,
    y: 606.5
  },
  {
    x: 1171.5,
    y: 591.5
  },
  {
    x: 1171.5,
    y: 576.5
  },
  {
    x: 1171.5,
    y: 561.5
  },
  {
    x: 1171.5,
    y: 546.5
  },
  {
    x: 1171.5,
    y: 531.5
  },
  {
    x: 1171.5,
    y: 516.5
  },
  {
    x: 1171.5,
    y: 501.5
  },
  {
    x: 1171.5,
    y: 486.5
  },
  {
    x: 1171.5,
    y: 471.5
  },
  {
    x: 1171.5,
    y: 456.5
  },
  {
    x: 1171.5,
    y: 441.5
  },
  {
    x: 1171.5,
    y: 426.5
  },
  {
    x: 1171.5,
    y: 411.5
  },
  {
    x: 1171.5,
    y: 396.5
  },
  {
    x: 1171.5,
    y: 351.5
  },
  {
    x: 1171.5,
    y: 336.5
  },
  {
    x: 1171.5,
    y: 321.5
  },
  {
    x: 1171.5,
    y: 291.5
  },
  {
    x: 1171.5,
    y: 231.5
  },
  {
    x: 1171.5,
    y: 216.5
  },
  {
    x: 1171.5,
    y: 201.5
  },
  {
    x: 1171.5,
    y: 126.5
  },
  {
    x: 1171.5,
    y: 111.5
  },
  {
    x: 1171.5,
    y: 96.5
  },
  {
    x: 1171.5,
    y: 81.5
  },
  {
    x: 1186.5,
    y: 2046.5
  },
  {
    x: 1186.5,
    y: 2031.5
  },
  {
    x: 1186.5,
    y: 2016.5
  },
  {
    x: 1186.5,
    y: 2001.5
  },
  {
    x: 1186.5,
    y: 1986.5
  },
  {
    x: 1186.5,
    y: 1971.5
  },
  {
    x: 1186.5,
    y: 1956.5
  },
  {
    x: 1186.5,
    y: 1941.5
  },
  {
    x: 1186.5,
    y: 1911.5
  },
  {
    x: 1186.5,
    y: 1896.5
  },
  {
    x: 1186.5,
    y: 1881.5
  },
  {
    x: 1186.5,
    y: 1866.5
  },
  {
    x: 1186.5,
    y: 1851.5
  },
  {
    x: 1186.5,
    y: 1821.5
  },
  {
    x: 1186.5,
    y: 1581.5
  },
  {
    x: 1186.5,
    y: 1191.5
  },
  {
    x: 1186.5,
    y: 1176.5
  },
  {
    x: 1186.5,
    y: 1161.5
  },
  {
    x: 1186.5,
    y: 1146.5
  },
  {
    x: 1186.5,
    y: 1131.5
  },
  {
    x: 1186.5,
    y: 1116.5
  },
  {
    x: 1186.5,
    y: 1101.5
  },
  {
    x: 1186.5,
    y: 1086.5
  },
  {
    x: 1186.5,
    y: 1071.5
  },
  {
    x: 1186.5,
    y: 1056.5
  },
  {
    x: 1186.5,
    y: 1041.5
  },
  {
    x: 1186.5,
    y: 1026.5
  },
  {
    x: 1186.5,
    y: 1011.5
  },
  {
    x: 1186.5,
    y: 996.5
  },
  {
    x: 1186.5,
    y: 981.5
  },
  {
    x: 1186.5,
    y: 966.5
  },
  {
    x: 1186.5,
    y: 951.5
  },
  {
    x: 1186.5,
    y: 936.5
  },
  {
    x: 1186.5,
    y: 921.5
  },
  {
    x: 1186.5,
    y: 816.5
  },
  {
    x: 1186.5,
    y: 786.5
  },
  {
    x: 1186.5,
    y: 741.5
  },
  {
    x: 1186.5,
    y: 591.5
  },
  {
    x: 1186.5,
    y: 576.5
  },
  {
    x: 1186.5,
    y: 561.5
  },
  {
    x: 1186.5,
    y: 546.5
  },
  {
    x: 1186.5,
    y: 531.5
  },
  {
    x: 1186.5,
    y: 516.5
  },
  {
    x: 1186.5,
    y: 501.5
  },
  {
    x: 1186.5,
    y: 486.5
  },
  {
    x: 1186.5,
    y: 471.5
  },
  {
    x: 1186.5,
    y: 456.5
  },
  {
    x: 1186.5,
    y: 441.5
  },
  {
    x: 1186.5,
    y: 426.5
  },
  {
    x: 1186.5,
    y: 411.5
  },
  {
    x: 1186.5,
    y: 396.5
  },
  {
    x: 1186.5,
    y: 381.5
  },
  {
    x: 1186.5,
    y: 366.5
  },
  {
    x: 1186.5,
    y: 351.5
  },
  {
    x: 1186.5,
    y: 336.5
  },
  {
    x: 1186.5,
    y: 321.5
  },
  {
    x: 1186.5,
    y: 291.5
  },
  {
    x: 1186.5,
    y: 246.5
  },
  {
    x: 1186.5,
    y: 231.5
  },
  {
    x: 1186.5,
    y: 216.5
  },
  {
    x: 1186.5,
    y: 201.5
  },
  {
    x: 1186.5,
    y: 126.5
  },
  {
    x: 1186.5,
    y: 111.5
  },
  {
    x: 1186.5,
    y: 96.5
  },
  {
    x: 1186.5,
    y: 81.5
  },
  {
    x: 1201.5,
    y: 2046.5
  },
  {
    x: 1201.5,
    y: 2031.5
  },
  {
    x: 1201.5,
    y: 2016.5
  },
  {
    x: 1201.5,
    y: 2001.5
  },
  {
    x: 1201.5,
    y: 1986.5
  },
  {
    x: 1201.5,
    y: 1971.5
  },
  {
    x: 1201.5,
    y: 1956.5
  },
  {
    x: 1201.5,
    y: 1911.5
  },
  {
    x: 1201.5,
    y: 1896.5
  },
  {
    x: 1201.5,
    y: 1881.5
  },
  {
    x: 1201.5,
    y: 1866.5
  },
  {
    x: 1201.5,
    y: 1851.5
  },
  {
    x: 1201.5,
    y: 1836.5
  },
  {
    x: 1201.5,
    y: 1821.5
  },
  {
    x: 1201.5,
    y: 1626.5
  },
  {
    x: 1201.5,
    y: 1611.5
  },
  {
    x: 1201.5,
    y: 1596.5
  },
  {
    x: 1201.5,
    y: 1581.5
  },
  {
    x: 1201.5,
    y: 1566.5
  },
  {
    x: 1201.5,
    y: 1551.5
  },
  {
    x: 1201.5,
    y: 1536.5
  },
  {
    x: 1201.5,
    y: 1521.5
  },
  {
    x: 1201.5,
    y: 1191.5
  },
  {
    x: 1201.5,
    y: 1176.5
  },
  {
    x: 1201.5,
    y: 1161.5
  },
  {
    x: 1201.5,
    y: 1146.5
  },
  {
    x: 1201.5,
    y: 1131.5
  },
  {
    x: 1201.5,
    y: 1116.5
  },
  {
    x: 1201.5,
    y: 1101.5
  },
  {
    x: 1201.5,
    y: 1086.5
  },
  {
    x: 1201.5,
    y: 1071.5
  },
  {
    x: 1201.5,
    y: 1056.5
  },
  {
    x: 1201.5,
    y: 1041.5
  },
  {
    x: 1201.5,
    y: 1026.5
  },
  {
    x: 1201.5,
    y: 1011.5
  },
  {
    x: 1201.5,
    y: 996.5
  },
  {
    x: 1201.5,
    y: 981.5
  },
  {
    x: 1201.5,
    y: 966.5
  },
  {
    x: 1201.5,
    y: 951.5
  },
  {
    x: 1201.5,
    y: 936.5
  },
  {
    x: 1201.5,
    y: 921.5
  },
  {
    x: 1201.5,
    y: 906.5
  },
  {
    x: 1201.5,
    y: 816.5
  },
  {
    x: 1201.5,
    y: 771.5
  },
  {
    x: 1201.5,
    y: 576.5
  },
  {
    x: 1201.5,
    y: 561.5
  },
  {
    x: 1201.5,
    y: 546.5
  },
  {
    x: 1201.5,
    y: 531.5
  },
  {
    x: 1201.5,
    y: 516.5
  },
  {
    x: 1201.5,
    y: 501.5
  },
  {
    x: 1201.5,
    y: 486.5
  },
  {
    x: 1201.5,
    y: 471.5
  },
  {
    x: 1201.5,
    y: 456.5
  },
  {
    x: 1201.5,
    y: 441.5
  },
  {
    x: 1201.5,
    y: 426.5
  },
  {
    x: 1201.5,
    y: 411.5
  },
  {
    x: 1201.5,
    y: 396.5
  },
  {
    x: 1201.5,
    y: 381.5
  },
  {
    x: 1201.5,
    y: 366.5
  },
  {
    x: 1201.5,
    y: 351.5
  },
  {
    x: 1201.5,
    y: 336.5
  },
  {
    x: 1201.5,
    y: 321.5
  },
  {
    x: 1201.5,
    y: 231.5
  },
  {
    x: 1201.5,
    y: 216.5
  },
  {
    x: 1201.5,
    y: 111.5
  },
  {
    x: 1201.5,
    y: 96.5
  },
  {
    x: 1201.5,
    y: 81.5
  },
  {
    x: 1216.5,
    y: 2046.5
  },
  {
    x: 1216.5,
    y: 2031.5
  },
  {
    x: 1216.5,
    y: 2016.5
  },
  {
    x: 1216.5,
    y: 2001.5
  },
  {
    x: 1216.5,
    y: 1986.5
  },
  {
    x: 1216.5,
    y: 1971.5
  },
  {
    x: 1216.5,
    y: 1956.5
  },
  {
    x: 1216.5,
    y: 1896.5
  },
  {
    x: 1216.5,
    y: 1881.5
  },
  {
    x: 1216.5,
    y: 1866.5
  },
  {
    x: 1216.5,
    y: 1851.5
  },
  {
    x: 1216.5,
    y: 1836.5
  },
  {
    x: 1216.5,
    y: 1626.5
  },
  {
    x: 1216.5,
    y: 1611.5
  },
  {
    x: 1216.5,
    y: 1596.5
  },
  {
    x: 1216.5,
    y: 1581.5
  },
  {
    x: 1216.5,
    y: 1566.5
  },
  {
    x: 1216.5,
    y: 1551.5
  },
  {
    x: 1216.5,
    y: 1536.5
  },
  {
    x: 1216.5,
    y: 1521.5
  },
  {
    x: 1216.5,
    y: 1506.5
  },
  {
    x: 1216.5,
    y: 1491.5
  },
  {
    x: 1216.5,
    y: 1476.5
  },
  {
    x: 1216.5,
    y: 1461.5
  },
  {
    x: 1216.5,
    y: 1446.5
  },
  {
    x: 1216.5,
    y: 1206.5
  },
  {
    x: 1216.5,
    y: 1191.5
  },
  {
    x: 1216.5,
    y: 1176.5
  },
  {
    x: 1216.5,
    y: 1161.5
  },
  {
    x: 1216.5,
    y: 1146.5
  },
  {
    x: 1216.5,
    y: 1131.5
  },
  {
    x: 1216.5,
    y: 1116.5
  },
  {
    x: 1216.5,
    y: 1101.5
  },
  {
    x: 1216.5,
    y: 1086.5
  },
  {
    x: 1216.5,
    y: 1071.5
  },
  {
    x: 1216.5,
    y: 1056.5
  },
  {
    x: 1216.5,
    y: 1041.5
  },
  {
    x: 1216.5,
    y: 1026.5
  },
  {
    x: 1216.5,
    y: 1011.5
  },
  {
    x: 1216.5,
    y: 996.5
  },
  {
    x: 1216.5,
    y: 981.5
  },
  {
    x: 1216.5,
    y: 966.5
  },
  {
    x: 1216.5,
    y: 951.5
  },
  {
    x: 1216.5,
    y: 936.5
  },
  {
    x: 1216.5,
    y: 921.5
  },
  {
    x: 1216.5,
    y: 906.5
  },
  {
    x: 1216.5,
    y: 816.5
  },
  {
    x: 1216.5,
    y: 801.5
  },
  {
    x: 1216.5,
    y: 786.5
  },
  {
    x: 1216.5,
    y: 561.5
  },
  {
    x: 1216.5,
    y: 546.5
  },
  {
    x: 1216.5,
    y: 531.5
  },
  {
    x: 1216.5,
    y: 516.5
  },
  {
    x: 1216.5,
    y: 501.5
  },
  {
    x: 1216.5,
    y: 486.5
  },
  {
    x: 1216.5,
    y: 471.5
  },
  {
    x: 1216.5,
    y: 456.5
  },
  {
    x: 1216.5,
    y: 441.5
  },
  {
    x: 1216.5,
    y: 426.5
  },
  {
    x: 1216.5,
    y: 411.5
  },
  {
    x: 1216.5,
    y: 396.5
  },
  {
    x: 1216.5,
    y: 381.5
  },
  {
    x: 1216.5,
    y: 366.5
  },
  {
    x: 1216.5,
    y: 351.5
  },
  {
    x: 1216.5,
    y: 336.5
  },
  {
    x: 1216.5,
    y: 321.5
  },
  {
    x: 1216.5,
    y: 291.5
  },
  {
    x: 1216.5,
    y: 276.5
  },
  {
    x: 1216.5,
    y: 261.5
  },
  {
    x: 1216.5,
    y: 246.5
  },
  {
    x: 1216.5,
    y: 231.5
  },
  {
    x: 1216.5,
    y: 216.5
  },
  {
    x: 1216.5,
    y: 111.5
  },
  {
    x: 1216.5,
    y: 96.5
  },
  {
    x: 1216.5,
    y: 81.5
  },
  {
    x: 1231.5,
    y: 2046.5
  },
  {
    x: 1231.5,
    y: 2031.5
  },
  {
    x: 1231.5,
    y: 2016.5
  },
  {
    x: 1231.5,
    y: 2001.5
  },
  {
    x: 1231.5,
    y: 1986.5
  },
  {
    x: 1231.5,
    y: 1971.5
  },
  {
    x: 1231.5,
    y: 1956.5
  },
  {
    x: 1231.5,
    y: 1941.5
  },
  {
    x: 1231.5,
    y: 1926.5
  },
  {
    x: 1231.5,
    y: 1896.5
  },
  {
    x: 1231.5,
    y: 1881.5
  },
  {
    x: 1231.5,
    y: 1866.5
  },
  {
    x: 1231.5,
    y: 1851.5
  },
  {
    x: 1231.5,
    y: 1836.5
  },
  {
    x: 1231.5,
    y: 1821.5
  },
  {
    x: 1231.5,
    y: 1806.5
  },
  {
    x: 1231.5,
    y: 1641.5
  },
  {
    x: 1231.5,
    y: 1626.5
  },
  {
    x: 1231.5,
    y: 1611.5
  },
  {
    x: 1231.5,
    y: 1596.5
  },
  {
    x: 1231.5,
    y: 1581.5
  },
  {
    x: 1231.5,
    y: 1566.5
  },
  {
    x: 1231.5,
    y: 1551.5
  },
  {
    x: 1231.5,
    y: 1536.5
  },
  {
    x: 1231.5,
    y: 1521.5
  },
  {
    x: 1231.5,
    y: 1506.5
  },
  {
    x: 1231.5,
    y: 1491.5
  },
  {
    x: 1231.5,
    y: 1476.5
  },
  {
    x: 1231.5,
    y: 1461.5
  },
  {
    x: 1231.5,
    y: 1446.5
  },
  {
    x: 1231.5,
    y: 1431.5
  },
  {
    x: 1231.5,
    y: 1416.5
  },
  {
    x: 1231.5,
    y: 1401.5
  },
  {
    x: 1231.5,
    y: 1371.5
  },
  {
    x: 1231.5,
    y: 1206.5
  },
  {
    x: 1231.5,
    y: 1191.5
  },
  {
    x: 1231.5,
    y: 1176.5
  },
  {
    x: 1231.5,
    y: 1161.5
  },
  {
    x: 1231.5,
    y: 1146.5
  },
  {
    x: 1231.5,
    y: 1131.5
  },
  {
    x: 1231.5,
    y: 1116.5
  },
  {
    x: 1231.5,
    y: 1101.5
  },
  {
    x: 1231.5,
    y: 1086.5
  },
  {
    x: 1231.5,
    y: 1071.5
  },
  {
    x: 1231.5,
    y: 1056.5
  },
  {
    x: 1231.5,
    y: 1041.5
  },
  {
    x: 1231.5,
    y: 1026.5
  },
  {
    x: 1231.5,
    y: 1011.5
  },
  {
    x: 1231.5,
    y: 996.5
  },
  {
    x: 1231.5,
    y: 981.5
  },
  {
    x: 1231.5,
    y: 966.5
  },
  {
    x: 1231.5,
    y: 951.5
  },
  {
    x: 1231.5,
    y: 936.5
  },
  {
    x: 1231.5,
    y: 921.5
  },
  {
    x: 1231.5,
    y: 906.5
  },
  {
    x: 1231.5,
    y: 891.5
  },
  {
    x: 1231.5,
    y: 816.5
  },
  {
    x: 1231.5,
    y: 801.5
  },
  {
    x: 1231.5,
    y: 546.5
  },
  {
    x: 1231.5,
    y: 531.5
  },
  {
    x: 1231.5,
    y: 516.5
  },
  {
    x: 1231.5,
    y: 501.5
  },
  {
    x: 1231.5,
    y: 486.5
  },
  {
    x: 1231.5,
    y: 471.5
  },
  {
    x: 1231.5,
    y: 456.5
  },
  {
    x: 1231.5,
    y: 441.5
  },
  {
    x: 1231.5,
    y: 426.5
  },
  {
    x: 1231.5,
    y: 411.5
  },
  {
    x: 1231.5,
    y: 396.5
  },
  {
    x: 1231.5,
    y: 381.5
  },
  {
    x: 1231.5,
    y: 366.5
  },
  {
    x: 1231.5,
    y: 351.5
  },
  {
    x: 1231.5,
    y: 336.5
  },
  {
    x: 1231.5,
    y: 321.5
  },
  {
    x: 1231.5,
    y: 291.5
  },
  {
    x: 1231.5,
    y: 276.5
  },
  {
    x: 1231.5,
    y: 261.5
  },
  {
    x: 1231.5,
    y: 246.5
  },
  {
    x: 1231.5,
    y: 231.5
  },
  {
    x: 1231.5,
    y: 216.5
  },
  {
    x: 1231.5,
    y: 141.5
  },
  {
    x: 1231.5,
    y: 111.5
  },
  {
    x: 1231.5,
    y: 96.5
  },
  {
    x: 1231.5,
    y: 81.5
  },
  {
    x: 1246.5,
    y: 2046.5
  },
  {
    x: 1246.5,
    y: 2031.5
  },
  {
    x: 1246.5,
    y: 2016.5
  },
  {
    x: 1246.5,
    y: 2001.5
  },
  {
    x: 1246.5,
    y: 1986.5
  },
  {
    x: 1246.5,
    y: 1971.5
  },
  {
    x: 1246.5,
    y: 1956.5
  },
  {
    x: 1246.5,
    y: 1941.5
  },
  {
    x: 1246.5,
    y: 1926.5
  },
  {
    x: 1246.5,
    y: 1896.5
  },
  {
    x: 1246.5,
    y: 1881.5
  },
  {
    x: 1246.5,
    y: 1866.5
  },
  {
    x: 1246.5,
    y: 1851.5
  },
  {
    x: 1246.5,
    y: 1836.5
  },
  {
    x: 1246.5,
    y: 1821.5
  },
  {
    x: 1246.5,
    y: 1806.5
  },
  {
    x: 1246.5,
    y: 1641.5
  },
  {
    x: 1246.5,
    y: 1626.5
  },
  {
    x: 1246.5,
    y: 1611.5
  },
  {
    x: 1246.5,
    y: 1596.5
  },
  {
    x: 1246.5,
    y: 1581.5
  },
  {
    x: 1246.5,
    y: 1566.5
  },
  {
    x: 1246.5,
    y: 1551.5
  },
  {
    x: 1246.5,
    y: 1536.5
  },
  {
    x: 1246.5,
    y: 1521.5
  },
  {
    x: 1246.5,
    y: 1506.5
  },
  {
    x: 1246.5,
    y: 1491.5
  },
  {
    x: 1246.5,
    y: 1476.5
  },
  {
    x: 1246.5,
    y: 1461.5
  },
  {
    x: 1246.5,
    y: 1446.5
  },
  {
    x: 1246.5,
    y: 1431.5
  },
  {
    x: 1246.5,
    y: 1416.5
  },
  {
    x: 1246.5,
    y: 1401.5
  },
  {
    x: 1246.5,
    y: 1386.5
  },
  {
    x: 1246.5,
    y: 1371.5
  },
  {
    x: 1246.5,
    y: 1356.5
  },
  {
    x: 1246.5,
    y: 1341.5
  },
  {
    x: 1246.5,
    y: 1326.5
  },
  {
    x: 1246.5,
    y: 1311.5
  },
  {
    x: 1246.5,
    y: 1296.5
  },
  {
    x: 1246.5,
    y: 1221.5
  },
  {
    x: 1246.5,
    y: 1206.5
  },
  {
    x: 1246.5,
    y: 1191.5
  },
  {
    x: 1246.5,
    y: 1176.5
  },
  {
    x: 1246.5,
    y: 1161.5
  },
  {
    x: 1246.5,
    y: 1146.5
  },
  {
    x: 1246.5,
    y: 1131.5
  },
  {
    x: 1246.5,
    y: 1116.5
  },
  {
    x: 1246.5,
    y: 1101.5
  },
  {
    x: 1246.5,
    y: 1086.5
  },
  {
    x: 1246.5,
    y: 1071.5
  },
  {
    x: 1246.5,
    y: 1056.5
  },
  {
    x: 1246.5,
    y: 1041.5
  },
  {
    x: 1246.5,
    y: 1026.5
  },
  {
    x: 1246.5,
    y: 1011.5
  },
  {
    x: 1246.5,
    y: 996.5
  },
  {
    x: 1246.5,
    y: 981.5
  },
  {
    x: 1246.5,
    y: 966.5
  },
  {
    x: 1246.5,
    y: 951.5
  },
  {
    x: 1246.5,
    y: 936.5
  },
  {
    x: 1246.5,
    y: 921.5
  },
  {
    x: 1246.5,
    y: 906.5
  },
  {
    x: 1246.5,
    y: 816.5
  },
  {
    x: 1246.5,
    y: 801.5
  },
  {
    x: 1246.5,
    y: 546.5
  },
  {
    x: 1246.5,
    y: 531.5
  },
  {
    x: 1246.5,
    y: 516.5
  },
  {
    x: 1246.5,
    y: 501.5
  },
  {
    x: 1246.5,
    y: 486.5
  },
  {
    x: 1246.5,
    y: 471.5
  },
  {
    x: 1246.5,
    y: 456.5
  },
  {
    x: 1246.5,
    y: 441.5
  },
  {
    x: 1246.5,
    y: 426.5
  },
  {
    x: 1246.5,
    y: 411.5
  },
  {
    x: 1246.5,
    y: 396.5
  },
  {
    x: 1246.5,
    y: 381.5
  },
  {
    x: 1246.5,
    y: 366.5
  },
  {
    x: 1246.5,
    y: 351.5
  },
  {
    x: 1246.5,
    y: 336.5
  },
  {
    x: 1246.5,
    y: 306.5
  },
  {
    x: 1246.5,
    y: 291.5
  },
  {
    x: 1246.5,
    y: 276.5
  },
  {
    x: 1246.5,
    y: 261.5
  },
  {
    x: 1246.5,
    y: 246.5
  },
  {
    x: 1246.5,
    y: 231.5
  },
  {
    x: 1246.5,
    y: 216.5
  },
  {
    x: 1246.5,
    y: 111.5
  },
  {
    x: 1246.5,
    y: 96.5
  },
  {
    x: 1246.5,
    y: 81.5
  },
  {
    x: 1261.5,
    y: 2046.5
  },
  {
    x: 1261.5,
    y: 2031.5
  },
  {
    x: 1261.5,
    y: 2016.5
  },
  {
    x: 1261.5,
    y: 2001.5
  },
  {
    x: 1261.5,
    y: 1986.5
  },
  {
    x: 1261.5,
    y: 1971.5
  },
  {
    x: 1261.5,
    y: 1956.5
  },
  {
    x: 1261.5,
    y: 1881.5
  },
  {
    x: 1261.5,
    y: 1866.5
  },
  {
    x: 1261.5,
    y: 1851.5
  },
  {
    x: 1261.5,
    y: 1836.5
  },
  {
    x: 1261.5,
    y: 1821.5
  },
  {
    x: 1261.5,
    y: 1791.5
  },
  {
    x: 1261.5,
    y: 1656.5
  },
  {
    x: 1261.5,
    y: 1641.5
  },
  {
    x: 1261.5,
    y: 1626.5
  },
  {
    x: 1261.5,
    y: 1611.5
  },
  {
    x: 1351.5,
    y: 1611.5
  },
  {
    x: 1366.5,
    y: 1611.5
  },
  {
    x: 1381.5,
    y: 1611.5
  },
  {
    x: 1261.5,
    y: 1596.5
  },
  {
    x: 1261.5,
    y: 1581.5
  },
  {
    x: 1261.5,
    y: 1566.5
  },
  {
    x: 1261.5,
    y: 1551.5
  },
  {
    x: 1261.5,
    y: 1536.5
  },
  {
    x: 1261.5,
    y: 1521.5
  },
  {
    x: 1261.5,
    y: 1506.5
  },
  {
    x: 1261.5,
    y: 1491.5
  },
  {
    x: 1261.5,
    y: 1476.5
  },
  {
    x: 1261.5,
    y: 1461.5
  },
  {
    x: 1261.5,
    y: 1446.5
  },
  {
    x: 1261.5,
    y: 1431.5
  },
  {
    x: 1261.5,
    y: 1416.5
  },
  {
    x: 1261.5,
    y: 1401.5
  },
  {
    x: 1261.5,
    y: 1386.5
  },
  {
    x: 1261.5,
    y: 1371.5
  },
  {
    x: 1261.5,
    y: 1356.5
  },
  {
    x: 1261.5,
    y: 1341.5
  },
  {
    x: 1261.5,
    y: 1326.5
  },
  {
    x: 1261.5,
    y: 1311.5
  },
  {
    x: 1261.5,
    y: 1296.5
  },
  {
    x: 1261.5,
    y: 1281.5
  },
  {
    x: 1261.5,
    y: 1266.5
  },
  {
    x: 1261.5,
    y: 1251.5
  },
  {
    x: 1261.5,
    y: 1236.5
  },
  {
    x: 1261.5,
    y: 1221.5
  },
  {
    x: 1261.5,
    y: 1206.5
  },
  {
    x: 1261.5,
    y: 1191.5
  },
  {
    x: 1261.5,
    y: 1176.5
  },
  {
    x: 1261.5,
    y: 1161.5
  },
  {
    x: 1261.5,
    y: 1146.5
  },
  {
    x: 1261.5,
    y: 1131.5
  },
  {
    x: 1261.5,
    y: 1116.5
  },
  {
    x: 1261.5,
    y: 1101.5
  },
  {
    x: 1261.5,
    y: 1086.5
  },
  {
    x: 1261.5,
    y: 1071.5
  },
  {
    x: 1261.5,
    y: 1056.5
  },
  {
    x: 1261.5,
    y: 1041.5
  },
  {
    x: 1261.5,
    y: 1026.5
  },
  {
    x: 1261.5,
    y: 1011.5
  },
  {
    x: 1261.5,
    y: 996.5
  },
  {
    x: 1261.5,
    y: 981.5
  },
  {
    x: 1261.5,
    y: 966.5
  },
  {
    x: 1261.5,
    y: 951.5
  },
  {
    x: 1261.5,
    y: 936.5
  },
  {
    x: 1261.5,
    y: 921.5
  },
  {
    x: 1261.5,
    y: 906.5
  },
  {
    x: 1261.5,
    y: 816.5
  },
  {
    x: 1261.5,
    y: 516.5
  },
  {
    x: 1261.5,
    y: 501.5
  },
  {
    x: 1261.5,
    y: 486.5
  },
  {
    x: 1261.5,
    y: 471.5
  },
  {
    x: 1261.5,
    y: 456.5
  },
  {
    x: 1261.5,
    y: 441.5
  },
  {
    x: 1261.5,
    y: 426.5
  },
  {
    x: 1261.5,
    y: 411.5
  },
  {
    x: 1261.5,
    y: 396.5
  },
  {
    x: 1261.5,
    y: 381.5
  },
  {
    x: 1261.5,
    y: 366.5
  },
  {
    x: 1261.5,
    y: 306.5
  },
  {
    x: 1261.5,
    y: 291.5
  },
  {
    x: 1261.5,
    y: 276.5
  },
  {
    x: 1261.5,
    y: 261.5
  },
  {
    x: 1261.5,
    y: 246.5
  },
  {
    x: 1261.5,
    y: 231.5
  },
  {
    x: 1261.5,
    y: 216.5
  },
  {
    x: 1261.5,
    y: 156.5
  },
  {
    x: 1261.5,
    y: 141.5
  },
  {
    x: 1261.5,
    y: 126.5
  },
  {
    x: 1261.5,
    y: 96.5
  },
  {
    x: 1261.5,
    y: 81.5
  },
  {
    x: 1276.5,
    y: 2046.5
  },
  {
    x: 1276.5,
    y: 2031.5
  },
  {
    x: 1276.5,
    y: 2016.5
  },
  {
    x: 1276.5,
    y: 2001.5
  },
  {
    x: 1276.5,
    y: 1986.5
  },
  {
    x: 1276.5,
    y: 1971.5
  },
  {
    x: 1276.5,
    y: 1956.5
  },
  {
    x: 1276.5,
    y: 1926.5
  },
  {
    x: 1276.5,
    y: 1881.5
  },
  {
    x: 1276.5,
    y: 1866.5
  },
  {
    x: 1276.5,
    y: 1851.5
  },
  {
    x: 1276.5,
    y: 1821.5
  },
  {
    x: 1276.5,
    y: 1791.5
  },
  {
    x: 1276.5,
    y: 1656.5
  },
  {
    x: 1276.5,
    y: 1641.5
  },
  {
    x: 1621.5,
    y: 1641.5
  },
  {
    x: 1636.5,
    y: 1641.5
  },
  {
    x: 1276.5,
    y: 1581.5
  },
  {
    x: 1276.5,
    y: 1566.5
  },
  {
    x: 1276.5,
    y: 1551.5
  },
  {
    x: 1276.5,
    y: 1536.5
  },
  {
    x: 1276.5,
    y: 1521.5
  },
  {
    x: 1276.5,
    y: 1506.5
  },
  {
    x: 1276.5,
    y: 1491.5
  },
  {
    x: 1276.5,
    y: 1476.5
  },
  {
    x: 1276.5,
    y: 1461.5
  },
  {
    x: 1276.5,
    y: 1446.5
  },
  {
    x: 1276.5,
    y: 1431.5
  },
  {
    x: 1276.5,
    y: 1416.5
  },
  {
    x: 1276.5,
    y: 1401.5
  },
  {
    x: 1276.5,
    y: 1386.5
  },
  {
    x: 1276.5,
    y: 1371.5
  },
  {
    x: 1276.5,
    y: 1356.5
  },
  {
    x: 1276.5,
    y: 1341.5
  },
  {
    x: 1276.5,
    y: 1326.5
  },
  {
    x: 1276.5,
    y: 1311.5
  },
  {
    x: 1276.5,
    y: 1296.5
  },
  {
    x: 1276.5,
    y: 1281.5
  },
  {
    x: 1276.5,
    y: 1266.5
  },
  {
    x: 1276.5,
    y: 1251.5
  },
  {
    x: 1276.5,
    y: 1236.5
  },
  {
    x: 1276.5,
    y: 1221.5
  },
  {
    x: 1276.5,
    y: 1206.5
  },
  {
    x: 1276.5,
    y: 1191.5
  },
  {
    x: 1276.5,
    y: 1176.5
  },
  {
    x: 1276.5,
    y: 1161.5
  },
  {
    x: 1276.5,
    y: 1146.5
  },
  {
    x: 1276.5,
    y: 1131.5
  },
  {
    x: 1276.5,
    y: 1116.5
  },
  {
    x: 1276.5,
    y: 1101.5
  },
  {
    x: 1276.5,
    y: 1086.5
  },
  {
    x: 1276.5,
    y: 1071.5
  },
  {
    x: 1276.5,
    y: 1056.5
  },
  {
    x: 1276.5,
    y: 1041.5
  },
  {
    x: 1276.5,
    y: 1026.5
  },
  {
    x: 1276.5,
    y: 1011.5
  },
  {
    x: 1276.5,
    y: 996.5
  },
  {
    x: 1276.5,
    y: 981.5
  },
  {
    x: 1276.5,
    y: 966.5
  },
  {
    x: 1276.5,
    y: 951.5
  },
  {
    x: 1276.5,
    y: 936.5
  },
  {
    x: 1276.5,
    y: 921.5
  },
  {
    x: 1276.5,
    y: 906.5
  },
  {
    x: 1276.5,
    y: 816.5
  },
  {
    x: 1276.5,
    y: 516.5
  },
  {
    x: 1276.5,
    y: 501.5
  },
  {
    x: 1276.5,
    y: 486.5
  },
  {
    x: 1276.5,
    y: 471.5
  },
  {
    x: 1276.5,
    y: 456.5
  },
  {
    x: 1276.5,
    y: 441.5
  },
  {
    x: 1276.5,
    y: 426.5
  },
  {
    x: 1276.5,
    y: 411.5
  },
  {
    x: 1276.5,
    y: 396.5
  },
  {
    x: 1276.5,
    y: 381.5
  },
  {
    x: 1276.5,
    y: 366.5
  },
  {
    x: 1276.5,
    y: 336.5
  },
  {
    x: 1276.5,
    y: 306.5
  },
  {
    x: 1276.5,
    y: 291.5
  },
  {
    x: 1276.5,
    y: 276.5
  },
  {
    x: 1276.5,
    y: 261.5
  },
  {
    x: 1276.5,
    y: 246.5
  },
  {
    x: 1276.5,
    y: 231.5
  },
  {
    x: 1276.5,
    y: 216.5
  },
  {
    x: 1276.5,
    y: 156.5
  },
  {
    x: 1276.5,
    y: 141.5
  },
  {
    x: 1276.5,
    y: 126.5
  },
  {
    x: 1276.5,
    y: 96.5
  },
  {
    x: 1276.5,
    y: 81.5
  },
  {
    x: 1291.5,
    y: 2046.5
  },
  {
    x: 1291.5,
    y: 2031.5
  },
  {
    x: 1291.5,
    y: 2016.5
  },
  {
    x: 1291.5,
    y: 2001.5
  },
  {
    x: 1291.5,
    y: 1986.5
  },
  {
    x: 1291.5,
    y: 1971.5
  },
  {
    x: 1291.5,
    y: 1956.5
  },
  {
    x: 1291.5,
    y: 1881.5
  },
  {
    x: 1291.5,
    y: 1866.5
  },
  {
    x: 1291.5,
    y: 1851.5
  },
  {
    x: 1291.5,
    y: 1836.5
  },
  {
    x: 1291.5,
    y: 1821.5
  },
  {
    x: 1291.5,
    y: 1806.5
  },
  {
    x: 1291.5,
    y: 1791.5
  },
  {
    x: 1291.5,
    y: 1776.5
  },
  {
    x: 1291.5,
    y: 1566.5
  },
  {
    x: 1291.5,
    y: 1536.5
  },
  {
    x: 1291.5,
    y: 1521.5
  },
  {
    x: 1291.5,
    y: 1506.5
  },
  {
    x: 1291.5,
    y: 1491.5
  },
  {
    x: 1291.5,
    y: 1476.5
  },
  {
    x: 1291.5,
    y: 1461.5
  },
  {
    x: 1291.5,
    y: 1446.5
  },
  {
    x: 1291.5,
    y: 1431.5
  },
  {
    x: 1291.5,
    y: 1416.5
  },
  {
    x: 1291.5,
    y: 1401.5
  },
  {
    x: 1291.5,
    y: 1386.5
  },
  {
    x: 1291.5,
    y: 1371.5
  },
  {
    x: 1291.5,
    y: 1356.5
  },
  {
    x: 1291.5,
    y: 1341.5
  },
  {
    x: 1291.5,
    y: 1326.5
  },
  {
    x: 1291.5,
    y: 1311.5
  },
  {
    x: 1291.5,
    y: 1296.5
  },
  {
    x: 1291.5,
    y: 1281.5
  },
  {
    x: 1291.5,
    y: 1266.5
  },
  {
    x: 1291.5,
    y: 1251.5
  },
  {
    x: 1291.5,
    y: 1236.5
  },
  {
    x: 1291.5,
    y: 1221.5
  },
  {
    x: 1291.5,
    y: 1206.5
  },
  {
    x: 1291.5,
    y: 1191.5
  },
  {
    x: 1291.5,
    y: 1176.5
  },
  {
    x: 1291.5,
    y: 1161.5
  },
  {
    x: 1291.5,
    y: 1146.5
  },
  {
    x: 1291.5,
    y: 1131.5
  },
  {
    x: 1291.5,
    y: 1116.5
  },
  {
    x: 1291.5,
    y: 1101.5
  },
  {
    x: 1291.5,
    y: 1086.5
  },
  {
    x: 1291.5,
    y: 1071.5
  },
  {
    x: 1291.5,
    y: 1056.5
  },
  {
    x: 1291.5,
    y: 1041.5
  },
  {
    x: 1291.5,
    y: 1026.5
  },
  {
    x: 1291.5,
    y: 1011.5
  },
  {
    x: 1291.5,
    y: 996.5
  },
  {
    x: 1291.5,
    y: 981.5
  },
  {
    x: 1291.5,
    y: 966.5
  },
  {
    x: 1291.5,
    y: 951.5
  },
  {
    x: 1291.5,
    y: 936.5
  },
  {
    x: 1291.5,
    y: 921.5
  },
  {
    x: 1291.5,
    y: 906.5
  },
  {
    x: 1291.5,
    y: 816.5
  },
  {
    x: 1291.5,
    y: 501.5
  },
  {
    x: 1291.5,
    y: 486.5
  },
  {
    x: 1291.5,
    y: 471.5
  },
  {
    x: 1291.5,
    y: 441.5
  },
  {
    x: 1291.5,
    y: 426.5
  },
  {
    x: 1291.5,
    y: 411.5
  },
  {
    x: 1291.5,
    y: 396.5
  },
  {
    x: 1291.5,
    y: 381.5
  },
  {
    x: 1291.5,
    y: 366.5
  },
  {
    x: 1291.5,
    y: 306.5
  },
  {
    x: 1291.5,
    y: 291.5
  },
  {
    x: 1291.5,
    y: 276.5
  },
  {
    x: 1291.5,
    y: 261.5
  },
  {
    x: 1291.5,
    y: 246.5
  },
  {
    x: 1291.5,
    y: 156.5
  },
  {
    x: 1291.5,
    y: 141.5
  },
  {
    x: 1291.5,
    y: 126.5
  },
  {
    x: 1291.5,
    y: 111.5
  },
  {
    x: 1291.5,
    y: 96.5
  },
  {
    x: 1291.5,
    y: 81.5
  },
  {
    x: 1306.5,
    y: 2046.5
  },
  {
    x: 1306.5,
    y: 2031.5
  },
  {
    x: 1306.5,
    y: 2016.5
  },
  {
    x: 1306.5,
    y: 2001.5
  },
  {
    x: 1306.5,
    y: 1986.5
  },
  {
    x: 1306.5,
    y: 1971.5
  },
  {
    x: 1306.5,
    y: 1956.5
  },
  {
    x: 1306.5,
    y: 1941.5
  },
  {
    x: 1306.5,
    y: 1881.5
  },
  {
    x: 1306.5,
    y: 1866.5
  },
  {
    x: 1306.5,
    y: 1851.5
  },
  {
    x: 1306.5,
    y: 1836.5
  },
  {
    x: 1306.5,
    y: 1821.5
  },
  {
    x: 1306.5,
    y: 1806.5
  },
  {
    x: 1306.5,
    y: 1791.5
  },
  {
    x: 1306.5,
    y: 1776.5
  },
  {
    x: 1306.5,
    y: 1521.5
  },
  {
    x: 1306.5,
    y: 1506.5
  },
  {
    x: 1306.5,
    y: 1491.5
  },
  {
    x: 1306.5,
    y: 1476.5
  },
  {
    x: 1306.5,
    y: 1461.5
  },
  {
    x: 1306.5,
    y: 1446.5
  },
  {
    x: 1306.5,
    y: 1431.5
  },
  {
    x: 1306.5,
    y: 1416.5
  },
  {
    x: 1306.5,
    y: 1401.5
  },
  {
    x: 1306.5,
    y: 1386.5
  },
  {
    x: 1306.5,
    y: 1371.5
  },
  {
    x: 1306.5,
    y: 1356.5
  },
  {
    x: 1306.5,
    y: 1341.5
  },
  {
    x: 1306.5,
    y: 1326.5
  },
  {
    x: 1306.5,
    y: 1311.5
  },
  {
    x: 1306.5,
    y: 1296.5
  },
  {
    x: 1306.5,
    y: 1281.5
  },
  {
    x: 1306.5,
    y: 1266.5
  },
  {
    x: 1306.5,
    y: 1251.5
  },
  {
    x: 1306.5,
    y: 1236.5
  },
  {
    x: 1306.5,
    y: 1221.5
  },
  {
    x: 1306.5,
    y: 1206.5
  },
  {
    x: 1306.5,
    y: 1191.5
  },
  {
    x: 1306.5,
    y: 1176.5
  },
  {
    x: 1306.5,
    y: 1161.5
  },
  {
    x: 1306.5,
    y: 1146.5
  },
  {
    x: 1306.5,
    y: 1131.5
  },
  {
    x: 1306.5,
    y: 1116.5
  },
  {
    x: 1306.5,
    y: 1101.5
  },
  {
    x: 1306.5,
    y: 1086.5
  },
  {
    x: 1306.5,
    y: 1071.5
  },
  {
    x: 1306.5,
    y: 1056.5
  },
  {
    x: 1306.5,
    y: 1041.5
  },
  {
    x: 1306.5,
    y: 1026.5
  },
  {
    x: 1306.5,
    y: 1011.5
  },
  {
    x: 1306.5,
    y: 996.5
  },
  {
    x: 1306.5,
    y: 981.5
  },
  {
    x: 1306.5,
    y: 966.5
  },
  {
    x: 1306.5,
    y: 951.5
  },
  {
    x: 1306.5,
    y: 936.5
  },
  {
    x: 1306.5,
    y: 921.5
  },
  {
    x: 1306.5,
    y: 816.5
  },
  {
    x: 1306.5,
    y: 516.5
  },
  {
    x: 1306.5,
    y: 501.5
  },
  {
    x: 1306.5,
    y: 486.5
  },
  {
    x: 1306.5,
    y: 471.5
  },
  {
    x: 1306.5,
    y: 441.5
  },
  {
    x: 1306.5,
    y: 426.5
  },
  {
    x: 1306.5,
    y: 411.5
  },
  {
    x: 1306.5,
    y: 396.5
  },
  {
    x: 1306.5,
    y: 381.5
  },
  {
    x: 1306.5,
    y: 366.5
  },
  {
    x: 1306.5,
    y: 351.5
  },
  {
    x: 1306.5,
    y: 321.5
  },
  {
    x: 1306.5,
    y: 306.5
  },
  {
    x: 1306.5,
    y: 291.5
  },
  {
    x: 1306.5,
    y: 276.5
  },
  {
    x: 1306.5,
    y: 261.5
  },
  {
    x: 1306.5,
    y: 156.5
  },
  {
    x: 1306.5,
    y: 141.5
  },
  {
    x: 1306.5,
    y: 126.5
  },
  {
    x: 1306.5,
    y: 111.5
  },
  {
    x: 1306.5,
    y: 96.5
  },
  {
    x: 1306.5,
    y: 81.5
  },
  {
    x: 1321.5,
    y: 2046.5
  },
  {
    x: 1321.5,
    y: 2031.5
  },
  {
    x: 1321.5,
    y: 2016.5
  },
  {
    x: 1321.5,
    y: 2001.5
  },
  {
    x: 1321.5,
    y: 1986.5
  },
  {
    x: 1321.5,
    y: 1971.5
  },
  {
    x: 1321.5,
    y: 1956.5
  },
  {
    x: 1321.5,
    y: 1941.5
  },
  {
    x: 1321.5,
    y: 1881.5
  },
  {
    x: 1321.5,
    y: 1866.5
  },
  {
    x: 1321.5,
    y: 1851.5
  },
  {
    x: 1321.5,
    y: 1836.5
  },
  {
    x: 1321.5,
    y: 1821.5
  },
  {
    x: 1321.5,
    y: 1806.5
  },
  {
    x: 1321.5,
    y: 1776.5
  },
  {
    x: 1321.5,
    y: 1761.5
  },
  {
    x: 1321.5,
    y: 1506.5
  },
  {
    x: 1321.5,
    y: 1491.5
  },
  {
    x: 1321.5,
    y: 1476.5
  },
  {
    x: 1321.5,
    y: 1461.5
  },
  {
    x: 1321.5,
    y: 1446.5
  },
  {
    x: 1321.5,
    y: 1431.5
  },
  {
    x: 1321.5,
    y: 1416.5
  },
  {
    x: 1321.5,
    y: 1401.5
  },
  {
    x: 1321.5,
    y: 1386.5
  },
  {
    x: 1321.5,
    y: 1371.5
  },
  {
    x: 1321.5,
    y: 1356.5
  },
  {
    x: 1321.5,
    y: 1341.5
  },
  {
    x: 1321.5,
    y: 1326.5
  },
  {
    x: 1321.5,
    y: 1311.5
  },
  {
    x: 1321.5,
    y: 1296.5
  },
  {
    x: 1321.5,
    y: 1281.5
  },
  {
    x: 1321.5,
    y: 1266.5
  },
  {
    x: 1321.5,
    y: 1251.5
  },
  {
    x: 1321.5,
    y: 1236.5
  },
  {
    x: 1321.5,
    y: 1221.5
  },
  {
    x: 1321.5,
    y: 1206.5
  },
  {
    x: 1321.5,
    y: 1191.5
  },
  {
    x: 1321.5,
    y: 1176.5
  },
  {
    x: 1321.5,
    y: 1161.5
  },
  {
    x: 1321.5,
    y: 1146.5
  },
  {
    x: 1321.5,
    y: 1131.5
  },
  {
    x: 1321.5,
    y: 1116.5
  },
  {
    x: 1321.5,
    y: 1101.5
  },
  {
    x: 1321.5,
    y: 1086.5
  },
  {
    x: 1321.5,
    y: 1071.5
  },
  {
    x: 1321.5,
    y: 1056.5
  },
  {
    x: 1321.5,
    y: 1041.5
  },
  {
    x: 1321.5,
    y: 1026.5
  },
  {
    x: 1321.5,
    y: 1011.5
  },
  {
    x: 1321.5,
    y: 996.5
  },
  {
    x: 1321.5,
    y: 981.5
  },
  {
    x: 1321.5,
    y: 966.5
  },
  {
    x: 1321.5,
    y: 951.5
  },
  {
    x: 1321.5,
    y: 936.5
  },
  {
    x: 1321.5,
    y: 921.5
  },
  {
    x: 1321.5,
    y: 906.5
  },
  {
    x: 1321.5,
    y: 516.5
  },
  {
    x: 1321.5,
    y: 501.5
  },
  {
    x: 1321.5,
    y: 456.5
  },
  {
    x: 1321.5,
    y: 441.5
  },
  {
    x: 1321.5,
    y: 426.5
  },
  {
    x: 1321.5,
    y: 411.5
  },
  {
    x: 1321.5,
    y: 396.5
  },
  {
    x: 1321.5,
    y: 381.5
  },
  {
    x: 1321.5,
    y: 366.5
  },
  {
    x: 1321.5,
    y: 351.5
  },
  {
    x: 1321.5,
    y: 276.5
  },
  {
    x: 1321.5,
    y: 261.5
  },
  {
    x: 1321.5,
    y: 156.5
  },
  {
    x: 1321.5,
    y: 141.5
  },
  {
    x: 1321.5,
    y: 126.5
  },
  {
    x: 1321.5,
    y: 111.5
  },
  {
    x: 1321.5,
    y: 81.5
  },
  {
    x: 1336.5,
    y: 2046.5
  },
  {
    x: 1336.5,
    y: 2031.5
  },
  {
    x: 1336.5,
    y: 2016.5
  },
  {
    x: 1336.5,
    y: 2001.5
  },
  {
    x: 1336.5,
    y: 1986.5
  },
  {
    x: 1336.5,
    y: 1971.5
  },
  {
    x: 1336.5,
    y: 1941.5
  },
  {
    x: 1336.5,
    y: 1866.5
  },
  {
    x: 1336.5,
    y: 1851.5
  },
  {
    x: 1336.5,
    y: 1836.5
  },
  {
    x: 1336.5,
    y: 1821.5
  },
  {
    x: 1336.5,
    y: 1776.5
  },
  {
    x: 1336.5,
    y: 1761.5
  },
  {
    x: 1336.5,
    y: 1491.5
  },
  {
    x: 1336.5,
    y: 1476.5
  },
  {
    x: 1336.5,
    y: 1461.5
  },
  {
    x: 1336.5,
    y: 1446.5
  },
  {
    x: 1336.5,
    y: 1431.5
  },
  {
    x: 1336.5,
    y: 1416.5
  },
  {
    x: 1336.5,
    y: 1401.5
  },
  {
    x: 1336.5,
    y: 1386.5
  },
  {
    x: 1336.5,
    y: 1371.5
  },
  {
    x: 1336.5,
    y: 1356.5
  },
  {
    x: 1336.5,
    y: 1341.5
  },
  {
    x: 1336.5,
    y: 1326.5
  },
  {
    x: 1336.5,
    y: 1311.5
  },
  {
    x: 1336.5,
    y: 1296.5
  },
  {
    x: 1336.5,
    y: 1281.5
  },
  {
    x: 1336.5,
    y: 1266.5
  },
  {
    x: 1336.5,
    y: 1251.5
  },
  {
    x: 1336.5,
    y: 1236.5
  },
  {
    x: 1336.5,
    y: 1221.5
  },
  {
    x: 1336.5,
    y: 1206.5
  },
  {
    x: 1336.5,
    y: 1191.5
  },
  {
    x: 1336.5,
    y: 1176.5
  },
  {
    x: 1336.5,
    y: 1161.5
  },
  {
    x: 1336.5,
    y: 1146.5
  },
  {
    x: 1336.5,
    y: 1131.5
  },
  {
    x: 1336.5,
    y: 1116.5
  },
  {
    x: 1336.5,
    y: 1101.5
  },
  {
    x: 1336.5,
    y: 1086.5
  },
  {
    x: 1336.5,
    y: 1071.5
  },
  {
    x: 1336.5,
    y: 1056.5
  },
  {
    x: 1336.5,
    y: 1041.5
  },
  {
    x: 1336.5,
    y: 1026.5
  },
  {
    x: 1336.5,
    y: 1011.5
  },
  {
    x: 1336.5,
    y: 996.5
  },
  {
    x: 1336.5,
    y: 981.5
  },
  {
    x: 1336.5,
    y: 966.5
  },
  {
    x: 1336.5,
    y: 951.5
  },
  {
    x: 1336.5,
    y: 936.5
  },
  {
    x: 1336.5,
    y: 921.5
  },
  {
    x: 1336.5,
    y: 831.5
  },
  {
    x: 1336.5,
    y: 441.5
  },
  {
    x: 1336.5,
    y: 426.5
  },
  {
    x: 1336.5,
    y: 411.5
  },
  {
    x: 1336.5,
    y: 396.5
  },
  {
    x: 1336.5,
    y: 381.5
  },
  {
    x: 1336.5,
    y: 366.5
  },
  {
    x: 1336.5,
    y: 276.5
  },
  {
    x: 1336.5,
    y: 261.5
  },
  {
    x: 1336.5,
    y: 156.5
  },
  {
    x: 1336.5,
    y: 141.5
  },
  {
    x: 1336.5,
    y: 126.5
  },
  {
    x: 1336.5,
    y: 111.5
  },
  {
    x: 1351.5,
    y: 2046.5
  },
  {
    x: 1351.5,
    y: 2031.5
  },
  {
    x: 1351.5,
    y: 2016.5
  },
  {
    x: 1351.5,
    y: 2001.5
  },
  {
    x: 1351.5,
    y: 1986.5
  },
  {
    x: 1351.5,
    y: 1941.5
  },
  {
    x: 1351.5,
    y: 1866.5
  },
  {
    x: 1351.5,
    y: 1851.5
  },
  {
    x: 1351.5,
    y: 1836.5
  },
  {
    x: 1351.5,
    y: 1776.5
  },
  {
    x: 1351.5,
    y: 1761.5
  },
  {
    x: 1351.5,
    y: 1461.5
  },
  {
    x: 1351.5,
    y: 1446.5
  },
  {
    x: 1351.5,
    y: 1431.5
  },
  {
    x: 1351.5,
    y: 1416.5
  },
  {
    x: 1351.5,
    y: 1401.5
  },
  {
    x: 1351.5,
    y: 1386.5
  },
  {
    x: 1351.5,
    y: 1371.5
  },
  {
    x: 1351.5,
    y: 1356.5
  },
  {
    x: 1351.5,
    y: 1341.5
  },
  {
    x: 1351.5,
    y: 1326.5
  },
  {
    x: 1351.5,
    y: 1311.5
  },
  {
    x: 1351.5,
    y: 1296.5
  },
  {
    x: 1351.5,
    y: 1281.5
  },
  {
    x: 1351.5,
    y: 1266.5
  },
  {
    x: 1351.5,
    y: 1251.5
  },
  {
    x: 1351.5,
    y: 1236.5
  },
  {
    x: 1351.5,
    y: 1221.5
  },
  {
    x: 1351.5,
    y: 1206.5
  },
  {
    x: 1351.5,
    y: 1191.5
  },
  {
    x: 1351.5,
    y: 1176.5
  },
  {
    x: 1351.5,
    y: 1161.5
  },
  {
    x: 1351.5,
    y: 1146.5
  },
  {
    x: 1351.5,
    y: 1131.5
  },
  {
    x: 1351.5,
    y: 1116.5
  },
  {
    x: 1351.5,
    y: 1101.5
  },
  {
    x: 1351.5,
    y: 1086.5
  },
  {
    x: 1351.5,
    y: 1071.5
  },
  {
    x: 1351.5,
    y: 1056.5
  },
  {
    x: 1351.5,
    y: 1041.5
  },
  {
    x: 1351.5,
    y: 1026.5
  },
  {
    x: 1351.5,
    y: 1011.5
  },
  {
    x: 1351.5,
    y: 996.5
  },
  {
    x: 1351.5,
    y: 981.5
  },
  {
    x: 1351.5,
    y: 966.5
  },
  {
    x: 1351.5,
    y: 951.5
  },
  {
    x: 1351.5,
    y: 936.5
  },
  {
    x: 1351.5,
    y: 921.5
  },
  {
    x: 1351.5,
    y: 906.5
  },
  {
    x: 1351.5,
    y: 876.5
  },
  {
    x: 1351.5,
    y: 846.5
  },
  {
    x: 1351.5,
    y: 501.5
  },
  {
    x: 1351.5,
    y: 441.5
  },
  {
    x: 1351.5,
    y: 426.5
  },
  {
    x: 1351.5,
    y: 411.5
  },
  {
    x: 1351.5,
    y: 396.5
  },
  {
    x: 1351.5,
    y: 381.5
  },
  {
    x: 1351.5,
    y: 156.5
  },
  {
    x: 1351.5,
    y: 141.5
  },
  {
    x: 1351.5,
    y: 126.5
  },
  {
    x: 1351.5,
    y: 111.5
  },
  {
    x: 1351.5,
    y: 96.5
  },
  {
    x: 1366.5,
    y: 2046.5
  },
  {
    x: 1366.5,
    y: 2031.5
  },
  {
    x: 1366.5,
    y: 2016.5
  },
  {
    x: 1366.5,
    y: 2001.5
  },
  {
    x: 1366.5,
    y: 1986.5
  },
  {
    x: 1366.5,
    y: 1941.5
  },
  {
    x: 1366.5,
    y: 1461.5
  },
  {
    x: 1366.5,
    y: 1446.5
  },
  {
    x: 1366.5,
    y: 1431.5
  },
  {
    x: 1366.5,
    y: 1416.5
  },
  {
    x: 1366.5,
    y: 1401.5
  },
  {
    x: 1366.5,
    y: 1386.5
  },
  {
    x: 1366.5,
    y: 1371.5
  },
  {
    x: 1366.5,
    y: 1356.5
  },
  {
    x: 1366.5,
    y: 1341.5
  },
  {
    x: 1366.5,
    y: 1326.5
  },
  {
    x: 1366.5,
    y: 1311.5
  },
  {
    x: 1366.5,
    y: 1296.5
  },
  {
    x: 1366.5,
    y: 1281.5
  },
  {
    x: 1366.5,
    y: 1266.5
  },
  {
    x: 1366.5,
    y: 1251.5
  },
  {
    x: 1366.5,
    y: 1236.5
  },
  {
    x: 1366.5,
    y: 1221.5
  },
  {
    x: 1366.5,
    y: 1206.5
  },
  {
    x: 1366.5,
    y: 1191.5
  },
  {
    x: 1366.5,
    y: 1176.5
  },
  {
    x: 1366.5,
    y: 1161.5
  },
  {
    x: 1366.5,
    y: 1146.5
  },
  {
    x: 1366.5,
    y: 1131.5
  },
  {
    x: 1366.5,
    y: 1116.5
  },
  {
    x: 1366.5,
    y: 1101.5
  },
  {
    x: 1366.5,
    y: 1086.5
  },
  {
    x: 1366.5,
    y: 1071.5
  },
  {
    x: 1366.5,
    y: 1056.5
  },
  {
    x: 1366.5,
    y: 1041.5
  },
  {
    x: 1366.5,
    y: 1026.5
  },
  {
    x: 1366.5,
    y: 1011.5
  },
  {
    x: 1366.5,
    y: 996.5
  },
  {
    x: 1366.5,
    y: 981.5
  },
  {
    x: 1366.5,
    y: 966.5
  },
  {
    x: 1366.5,
    y: 951.5
  },
  {
    x: 1366.5,
    y: 936.5
  },
  {
    x: 1366.5,
    y: 501.5
  },
  {
    x: 1366.5,
    y: 441.5
  },
  {
    x: 1366.5,
    y: 426.5
  },
  {
    x: 1366.5,
    y: 411.5
  },
  {
    x: 1366.5,
    y: 396.5
  },
  {
    x: 1366.5,
    y: 156.5
  },
  {
    x: 1366.5,
    y: 141.5
  },
  {
    x: 1366.5,
    y: 126.5
  },
  {
    x: 1366.5,
    y: 111.5
  },
  {
    x: 1366.5,
    y: 96.5
  },
  {
    x: 1381.5,
    y: 2046.5
  },
  {
    x: 1381.5,
    y: 2031.5
  },
  {
    x: 1381.5,
    y: 2016.5
  },
  {
    x: 1381.5,
    y: 2001.5
  },
  {
    x: 1381.5,
    y: 1986.5
  },
  {
    x: 1381.5,
    y: 1971.5
  },
  {
    x: 1381.5,
    y: 1746.5
  },
  {
    x: 1381.5,
    y: 1731.5
  },
  {
    x: 1381.5,
    y: 1461.5
  },
  {
    x: 1381.5,
    y: 1446.5
  },
  {
    x: 1381.5,
    y: 1431.5
  },
  {
    x: 1381.5,
    y: 1416.5
  },
  {
    x: 1381.5,
    y: 1401.5
  },
  {
    x: 1381.5,
    y: 1386.5
  },
  {
    x: 1381.5,
    y: 1371.5
  },
  {
    x: 1381.5,
    y: 1356.5
  },
  {
    x: 1381.5,
    y: 1341.5
  },
  {
    x: 1381.5,
    y: 1326.5
  },
  {
    x: 1381.5,
    y: 1311.5
  },
  {
    x: 1381.5,
    y: 1296.5
  },
  {
    x: 1381.5,
    y: 1281.5
  },
  {
    x: 1381.5,
    y: 1266.5
  },
  {
    x: 1381.5,
    y: 1251.5
  },
  {
    x: 1381.5,
    y: 1236.5
  },
  {
    x: 1381.5,
    y: 1221.5
  },
  {
    x: 1381.5,
    y: 1206.5
  },
  {
    x: 1381.5,
    y: 1191.5
  },
  {
    x: 1381.5,
    y: 1176.5
  },
  {
    x: 1381.5,
    y: 1161.5
  },
  {
    x: 1381.5,
    y: 1146.5
  },
  {
    x: 1381.5,
    y: 1131.5
  },
  {
    x: 1381.5,
    y: 1116.5
  },
  {
    x: 1381.5,
    y: 1101.5
  },
  {
    x: 1381.5,
    y: 1086.5
  },
  {
    x: 1381.5,
    y: 1071.5
  },
  {
    x: 1381.5,
    y: 1056.5
  },
  {
    x: 1381.5,
    y: 1041.5
  },
  {
    x: 1381.5,
    y: 1026.5
  },
  {
    x: 1381.5,
    y: 1011.5
  },
  {
    x: 1381.5,
    y: 996.5
  },
  {
    x: 1381.5,
    y: 981.5
  },
  {
    x: 1381.5,
    y: 966.5
  },
  {
    x: 1381.5,
    y: 951.5
  },
  {
    x: 1381.5,
    y: 936.5
  },
  {
    x: 1381.5,
    y: 471.5
  },
  {
    x: 1381.5,
    y: 426.5
  },
  {
    x: 1381.5,
    y: 411.5
  },
  {
    x: 1381.5,
    y: 156.5
  },
  {
    x: 1381.5,
    y: 141.5
  },
  {
    x: 1381.5,
    y: 126.5
  },
  {
    x: 1381.5,
    y: 111.5
  },
  {
    x: 1381.5,
    y: 96.5
  },
  {
    x: 1396.5,
    y: 2046.5
  },
  {
    x: 1396.5,
    y: 2031.5
  },
  {
    x: 1396.5,
    y: 2016.5
  },
  {
    x: 1396.5,
    y: 2001.5
  },
  {
    x: 1396.5,
    y: 1986.5
  },
  {
    x: 1396.5,
    y: 1971.5
  },
  {
    x: 1396.5,
    y: 1746.5
  },
  {
    x: 1396.5,
    y: 1446.5
  },
  {
    x: 1396.5,
    y: 1431.5
  },
  {
    x: 1396.5,
    y: 1416.5
  },
  {
    x: 1396.5,
    y: 1401.5
  },
  {
    x: 1396.5,
    y: 1386.5
  },
  {
    x: 1396.5,
    y: 1371.5
  },
  {
    x: 1396.5,
    y: 1356.5
  },
  {
    x: 1396.5,
    y: 1341.5
  },
  {
    x: 1396.5,
    y: 1326.5
  },
  {
    x: 1396.5,
    y: 1311.5
  },
  {
    x: 1396.5,
    y: 1296.5
  },
  {
    x: 1396.5,
    y: 1281.5
  },
  {
    x: 1396.5,
    y: 1266.5
  },
  {
    x: 1396.5,
    y: 1251.5
  },
  {
    x: 1396.5,
    y: 1236.5
  },
  {
    x: 1396.5,
    y: 1221.5
  },
  {
    x: 1396.5,
    y: 1206.5
  },
  {
    x: 1396.5,
    y: 1191.5
  },
  {
    x: 1396.5,
    y: 1176.5
  },
  {
    x: 1396.5,
    y: 1161.5
  },
  {
    x: 1396.5,
    y: 1146.5
  },
  {
    x: 1396.5,
    y: 1131.5
  },
  {
    x: 1396.5,
    y: 1116.5
  },
  {
    x: 1396.5,
    y: 1101.5
  },
  {
    x: 1396.5,
    y: 1086.5
  },
  {
    x: 1396.5,
    y: 1071.5
  },
  {
    x: 1396.5,
    y: 1056.5
  },
  {
    x: 1396.5,
    y: 1041.5
  },
  {
    x: 1396.5,
    y: 1026.5
  },
  {
    x: 1396.5,
    y: 1011.5
  },
  {
    x: 1396.5,
    y: 996.5
  },
  {
    x: 1396.5,
    y: 981.5
  },
  {
    x: 1396.5,
    y: 966.5
  },
  {
    x: 1396.5,
    y: 951.5
  },
  {
    x: 1396.5,
    y: 471.5
  },
  {
    x: 1396.5,
    y: 456.5
  },
  {
    x: 1396.5,
    y: 426.5
  },
  {
    x: 1396.5,
    y: 411.5
  },
  {
    x: 1396.5,
    y: 171.5
  },
  {
    x: 1396.5,
    y: 156.5
  },
  {
    x: 1396.5,
    y: 141.5
  },
  {
    x: 1396.5,
    y: 126.5
  },
  {
    x: 1396.5,
    y: 111.5
  },
  {
    x: 1396.5,
    y: 96.5
  },
  {
    x: 1411.5,
    y: 2046.5
  },
  {
    x: 1411.5,
    y: 2031.5
  },
  {
    x: 1411.5,
    y: 2016.5
  },
  {
    x: 1411.5,
    y: 2001.5
  },
  {
    x: 1411.5,
    y: 1986.5
  },
  {
    x: 1411.5,
    y: 1971.5
  },
  {
    x: 1411.5,
    y: 1746.5
  },
  {
    x: 1411.5,
    y: 1416.5
  },
  {
    x: 1411.5,
    y: 1401.5
  },
  {
    x: 1411.5,
    y: 1386.5
  },
  {
    x: 1411.5,
    y: 1371.5
  },
  {
    x: 1411.5,
    y: 1356.5
  },
  {
    x: 1411.5,
    y: 1341.5
  },
  {
    x: 1411.5,
    y: 1326.5
  },
  {
    x: 1411.5,
    y: 1311.5
  },
  {
    x: 1411.5,
    y: 1296.5
  },
  {
    x: 1411.5,
    y: 1281.5
  },
  {
    x: 1411.5,
    y: 1266.5
  },
  {
    x: 1411.5,
    y: 1251.5
  },
  {
    x: 1411.5,
    y: 1236.5
  },
  {
    x: 1411.5,
    y: 1221.5
  },
  {
    x: 1411.5,
    y: 1206.5
  },
  {
    x: 1411.5,
    y: 1191.5
  },
  {
    x: 1411.5,
    y: 1176.5
  },
  {
    x: 1411.5,
    y: 1161.5
  },
  {
    x: 1411.5,
    y: 1146.5
  },
  {
    x: 1411.5,
    y: 1131.5
  },
  {
    x: 1411.5,
    y: 1116.5
  },
  {
    x: 1411.5,
    y: 1101.5
  },
  {
    x: 1411.5,
    y: 1086.5
  },
  {
    x: 1411.5,
    y: 1071.5
  },
  {
    x: 1411.5,
    y: 1056.5
  },
  {
    x: 1411.5,
    y: 1041.5
  },
  {
    x: 1411.5,
    y: 1026.5
  },
  {
    x: 1411.5,
    y: 1011.5
  },
  {
    x: 1411.5,
    y: 996.5
  },
  {
    x: 1411.5,
    y: 981.5
  },
  {
    x: 1411.5,
    y: 966.5
  },
  {
    x: 1411.5,
    y: 471.5
  },
  {
    x: 1411.5,
    y: 456.5
  },
  {
    x: 1411.5,
    y: 441.5
  },
  {
    x: 1411.5,
    y: 426.5
  },
  {
    x: 1411.5,
    y: 186.5
  },
  {
    x: 1411.5,
    y: 171.5
  },
  {
    x: 1411.5,
    y: 156.5
  },
  {
    x: 1411.5,
    y: 141.5
  },
  {
    x: 1411.5,
    y: 126.5
  },
  {
    x: 1411.5,
    y: 111.5
  },
  {
    x: 1411.5,
    y: 96.5
  },
  {
    x: 1426.5,
    y: 2046.5
  },
  {
    x: 1426.5,
    y: 2031.5
  },
  {
    x: 1426.5,
    y: 2016.5
  },
  {
    x: 1426.5,
    y: 2001.5
  },
  {
    x: 1426.5,
    y: 1986.5
  },
  {
    x: 1426.5,
    y: 1971.5
  },
  {
    x: 1426.5,
    y: 1416.5
  },
  {
    x: 1426.5,
    y: 1401.5
  },
  {
    x: 1426.5,
    y: 1386.5
  },
  {
    x: 1426.5,
    y: 1371.5
  },
  {
    x: 1426.5,
    y: 1356.5
  },
  {
    x: 1426.5,
    y: 1341.5
  },
  {
    x: 1426.5,
    y: 1326.5
  },
  {
    x: 1426.5,
    y: 1311.5
  },
  {
    x: 1426.5,
    y: 1296.5
  },
  {
    x: 1426.5,
    y: 1281.5
  },
  {
    x: 1426.5,
    y: 1266.5
  },
  {
    x: 1426.5,
    y: 1251.5
  },
  {
    x: 1426.5,
    y: 1236.5
  },
  {
    x: 1426.5,
    y: 1221.5
  },
  {
    x: 1426.5,
    y: 1206.5
  },
  {
    x: 1426.5,
    y: 1191.5
  },
  {
    x: 1426.5,
    y: 1176.5
  },
  {
    x: 1426.5,
    y: 1161.5
  },
  {
    x: 1426.5,
    y: 1146.5
  },
  {
    x: 1426.5,
    y: 1131.5
  },
  {
    x: 1426.5,
    y: 1116.5
  },
  {
    x: 1426.5,
    y: 1101.5
  },
  {
    x: 1426.5,
    y: 1086.5
  },
  {
    x: 1426.5,
    y: 1071.5
  },
  {
    x: 1426.5,
    y: 1056.5
  },
  {
    x: 1426.5,
    y: 1041.5
  },
  {
    x: 1426.5,
    y: 1026.5
  },
  {
    x: 1426.5,
    y: 1011.5
  },
  {
    x: 1426.5,
    y: 996.5
  },
  {
    x: 1426.5,
    y: 981.5
  },
  {
    x: 1426.5,
    y: 966.5
  },
  {
    x: 1426.5,
    y: 486.5
  },
  {
    x: 1426.5,
    y: 471.5
  },
  {
    x: 1426.5,
    y: 231.5
  },
  {
    x: 1426.5,
    y: 201.5
  },
  {
    x: 1426.5,
    y: 186.5
  },
  {
    x: 1426.5,
    y: 171.5
  },
  {
    x: 1426.5,
    y: 156.5
  },
  {
    x: 1426.5,
    y: 141.5
  },
  {
    x: 1426.5,
    y: 126.5
  },
  {
    x: 1426.5,
    y: 111.5
  },
  {
    x: 1426.5,
    y: 96.5
  },
  {
    x: 1441.5,
    y: 2046.5
  },
  {
    x: 1441.5,
    y: 2031.5
  },
  {
    x: 1441.5,
    y: 2016.5
  },
  {
    x: 1441.5,
    y: 2001.5
  },
  {
    x: 1441.5,
    y: 1986.5
  },
  {
    x: 1441.5,
    y: 1971.5
  },
  {
    x: 1441.5,
    y: 1941.5
  },
  {
    x: 1441.5,
    y: 1401.5
  },
  {
    x: 1441.5,
    y: 1386.5
  },
  {
    x: 1441.5,
    y: 1371.5
  },
  {
    x: 1441.5,
    y: 1356.5
  },
  {
    x: 1441.5,
    y: 1341.5
  },
  {
    x: 1441.5,
    y: 1326.5
  },
  {
    x: 1441.5,
    y: 1311.5
  },
  {
    x: 1441.5,
    y: 1296.5
  },
  {
    x: 1441.5,
    y: 1281.5
  },
  {
    x: 1441.5,
    y: 1266.5
  },
  {
    x: 1441.5,
    y: 1251.5
  },
  {
    x: 1441.5,
    y: 1236.5
  },
  {
    x: 1441.5,
    y: 1221.5
  },
  {
    x: 1441.5,
    y: 1206.5
  },
  {
    x: 1441.5,
    y: 1191.5
  },
  {
    x: 1441.5,
    y: 1176.5
  },
  {
    x: 1441.5,
    y: 1161.5
  },
  {
    x: 1441.5,
    y: 1146.5
  },
  {
    x: 1441.5,
    y: 1131.5
  },
  {
    x: 1441.5,
    y: 1116.5
  },
  {
    x: 1441.5,
    y: 1101.5
  },
  {
    x: 1441.5,
    y: 1086.5
  },
  {
    x: 1441.5,
    y: 1071.5
  },
  {
    x: 1441.5,
    y: 1056.5
  },
  {
    x: 1441.5,
    y: 1041.5
  },
  {
    x: 1441.5,
    y: 1026.5
  },
  {
    x: 1441.5,
    y: 1011.5
  },
  {
    x: 1441.5,
    y: 996.5
  },
  {
    x: 1441.5,
    y: 981.5
  },
  {
    x: 1441.5,
    y: 966.5
  },
  {
    x: 1441.5,
    y: 486.5
  },
  {
    x: 1441.5,
    y: 471.5
  },
  {
    x: 1441.5,
    y: 261.5
  },
  {
    x: 1441.5,
    y: 246.5
  },
  {
    x: 1441.5,
    y: 231.5
  },
  {
    x: 1441.5,
    y: 201.5
  },
  {
    x: 1441.5,
    y: 186.5
  },
  {
    x: 1441.5,
    y: 171.5
  },
  {
    x: 1441.5,
    y: 156.5
  },
  {
    x: 1441.5,
    y: 141.5
  },
  {
    x: 1441.5,
    y: 126.5
  },
  {
    x: 1441.5,
    y: 111.5
  },
  {
    x: 1441.5,
    y: 96.5
  },
  {
    x: 1456.5,
    y: 2046.5
  },
  {
    x: 1456.5,
    y: 2031.5
  },
  {
    x: 1456.5,
    y: 2016.5
  },
  {
    x: 1456.5,
    y: 2001.5
  },
  {
    x: 1456.5,
    y: 1986.5
  },
  {
    x: 1456.5,
    y: 1971.5
  },
  {
    x: 1456.5,
    y: 1941.5
  },
  {
    x: 1456.5,
    y: 1386.5
  },
  {
    x: 1456.5,
    y: 1371.5
  },
  {
    x: 1456.5,
    y: 1356.5
  },
  {
    x: 1456.5,
    y: 1341.5
  },
  {
    x: 1456.5,
    y: 1326.5
  },
  {
    x: 1456.5,
    y: 1311.5
  },
  {
    x: 1456.5,
    y: 1296.5
  },
  {
    x: 1456.5,
    y: 1281.5
  },
  {
    x: 1456.5,
    y: 1266.5
  },
  {
    x: 1456.5,
    y: 1251.5
  },
  {
    x: 1456.5,
    y: 1236.5
  },
  {
    x: 1456.5,
    y: 1221.5
  },
  {
    x: 1456.5,
    y: 1206.5
  },
  {
    x: 1456.5,
    y: 1191.5
  },
  {
    x: 1456.5,
    y: 1176.5
  },
  {
    x: 1456.5,
    y: 1161.5
  },
  {
    x: 1456.5,
    y: 1146.5
  },
  {
    x: 1456.5,
    y: 1131.5
  },
  {
    x: 1456.5,
    y: 1116.5
  },
  {
    x: 1456.5,
    y: 1101.5
  },
  {
    x: 1456.5,
    y: 1086.5
  },
  {
    x: 1456.5,
    y: 1071.5
  },
  {
    x: 1456.5,
    y: 1056.5
  },
  {
    x: 1456.5,
    y: 1041.5
  },
  {
    x: 1456.5,
    y: 1026.5
  },
  {
    x: 1456.5,
    y: 1011.5
  },
  {
    x: 1456.5,
    y: 996.5
  },
  {
    x: 1456.5,
    y: 981.5
  },
  {
    x: 1456.5,
    y: 291.5
  },
  {
    x: 1456.5,
    y: 276.5
  },
  {
    x: 1456.5,
    y: 261.5
  },
  {
    x: 1456.5,
    y: 246.5
  },
  {
    x: 1456.5,
    y: 231.5
  },
  {
    x: 1456.5,
    y: 201.5
  },
  {
    x: 1456.5,
    y: 186.5
  },
  {
    x: 1456.5,
    y: 171.5
  },
  {
    x: 1456.5,
    y: 156.5
  },
  {
    x: 1456.5,
    y: 141.5
  },
  {
    x: 1456.5,
    y: 126.5
  },
  {
    x: 1456.5,
    y: 111.5
  },
  {
    x: 1456.5,
    y: 96.5
  },
  {
    x: 1471.5,
    y: 2046.5
  },
  {
    x: 1471.5,
    y: 2031.5
  },
  {
    x: 1471.5,
    y: 2016.5
  },
  {
    x: 1471.5,
    y: 2001.5
  },
  {
    x: 1471.5,
    y: 1986.5
  },
  {
    x: 1471.5,
    y: 1971.5
  },
  {
    x: 1471.5,
    y: 1956.5
  },
  {
    x: 1471.5,
    y: 1941.5
  },
  {
    x: 1471.5,
    y: 1371.5
  },
  {
    x: 1471.5,
    y: 1356.5
  },
  {
    x: 1471.5,
    y: 1341.5
  },
  {
    x: 1471.5,
    y: 1326.5
  },
  {
    x: 1471.5,
    y: 1311.5
  },
  {
    x: 1471.5,
    y: 1296.5
  },
  {
    x: 1471.5,
    y: 1281.5
  },
  {
    x: 1471.5,
    y: 1266.5
  },
  {
    x: 1471.5,
    y: 1251.5
  },
  {
    x: 1471.5,
    y: 1236.5
  },
  {
    x: 1471.5,
    y: 1221.5
  },
  {
    x: 1471.5,
    y: 1206.5
  },
  {
    x: 1471.5,
    y: 1191.5
  },
  {
    x: 1471.5,
    y: 1176.5
  },
  {
    x: 1471.5,
    y: 1161.5
  },
  {
    x: 1471.5,
    y: 1146.5
  },
  {
    x: 1471.5,
    y: 1131.5
  },
  {
    x: 1471.5,
    y: 1116.5
  },
  {
    x: 1471.5,
    y: 1101.5
  },
  {
    x: 1471.5,
    y: 1086.5
  },
  {
    x: 1471.5,
    y: 1071.5
  },
  {
    x: 1471.5,
    y: 1056.5
  },
  {
    x: 1471.5,
    y: 1041.5
  },
  {
    x: 1471.5,
    y: 1026.5
  },
  {
    x: 1471.5,
    y: 1011.5
  },
  {
    x: 1471.5,
    y: 996.5
  },
  {
    x: 1471.5,
    y: 306.5
  },
  {
    x: 1471.5,
    y: 291.5
  },
  {
    x: 1471.5,
    y: 276.5
  },
  {
    x: 1471.5,
    y: 261.5
  },
  {
    x: 1471.5,
    y: 246.5
  },
  {
    x: 1471.5,
    y: 231.5
  },
  {
    x: 1471.5,
    y: 216.5
  },
  {
    x: 1471.5,
    y: 201.5
  },
  {
    x: 1471.5,
    y: 186.5
  },
  {
    x: 1471.5,
    y: 171.5
  },
  {
    x: 1471.5,
    y: 156.5
  },
  {
    x: 1471.5,
    y: 141.5
  },
  {
    x: 1471.5,
    y: 126.5
  },
  {
    x: 1471.5,
    y: 111.5
  },
  {
    x: 1471.5,
    y: 96.5
  },
  {
    x: 1486.5,
    y: 2046.5
  },
  {
    x: 1486.5,
    y: 2031.5
  },
  {
    x: 1486.5,
    y: 2016.5
  },
  {
    x: 1486.5,
    y: 2001.5
  },
  {
    x: 1486.5,
    y: 1986.5
  },
  {
    x: 1486.5,
    y: 1971.5
  },
  {
    x: 1486.5,
    y: 1956.5
  },
  {
    x: 1486.5,
    y: 1941.5
  },
  {
    x: 1486.5,
    y: 1926.5
  },
  {
    x: 1486.5,
    y: 1341.5
  },
  {
    x: 1486.5,
    y: 1326.5
  },
  {
    x: 1486.5,
    y: 1311.5
  },
  {
    x: 1486.5,
    y: 1296.5
  },
  {
    x: 1486.5,
    y: 1281.5
  },
  {
    x: 1486.5,
    y: 1266.5
  },
  {
    x: 1486.5,
    y: 1251.5
  },
  {
    x: 1486.5,
    y: 1236.5
  },
  {
    x: 1486.5,
    y: 1221.5
  },
  {
    x: 1486.5,
    y: 1206.5
  },
  {
    x: 1486.5,
    y: 1191.5
  },
  {
    x: 1486.5,
    y: 1176.5
  },
  {
    x: 1486.5,
    y: 1161.5
  },
  {
    x: 1486.5,
    y: 1146.5
  },
  {
    x: 1486.5,
    y: 1131.5
  },
  {
    x: 1486.5,
    y: 1116.5
  },
  {
    x: 1486.5,
    y: 1101.5
  },
  {
    x: 1486.5,
    y: 1086.5
  },
  {
    x: 1486.5,
    y: 1071.5
  },
  {
    x: 1486.5,
    y: 1056.5
  },
  {
    x: 1486.5,
    y: 1041.5
  },
  {
    x: 1486.5,
    y: 1026.5
  },
  {
    x: 1486.5,
    y: 321.5
  },
  {
    x: 1486.5,
    y: 306.5
  },
  {
    x: 1486.5,
    y: 291.5
  },
  {
    x: 1486.5,
    y: 276.5
  },
  {
    x: 1486.5,
    y: 261.5
  },
  {
    x: 1486.5,
    y: 246.5
  },
  {
    x: 1486.5,
    y: 231.5
  },
  {
    x: 1486.5,
    y: 216.5
  },
  {
    x: 1486.5,
    y: 201.5
  },
  {
    x: 1486.5,
    y: 186.5
  },
  {
    x: 1486.5,
    y: 171.5
  },
  {
    x: 1486.5,
    y: 156.5
  },
  {
    x: 1486.5,
    y: 141.5
  },
  {
    x: 1486.5,
    y: 126.5
  },
  {
    x: 1486.5,
    y: 111.5
  },
  {
    x: 1486.5,
    y: 96.5
  },
  {
    x: 1501.5,
    y: 2046.5
  },
  {
    x: 1501.5,
    y: 2031.5
  },
  {
    x: 1501.5,
    y: 2016.5
  },
  {
    x: 1501.5,
    y: 2001.5
  },
  {
    x: 1501.5,
    y: 1986.5
  },
  {
    x: 1501.5,
    y: 1971.5
  },
  {
    x: 1501.5,
    y: 1956.5
  },
  {
    x: 1501.5,
    y: 1926.5
  },
  {
    x: 1501.5,
    y: 1911.5
  },
  {
    x: 1501.5,
    y: 1311.5
  },
  {
    x: 1501.5,
    y: 1296.5
  },
  {
    x: 1501.5,
    y: 1281.5
  },
  {
    x: 1501.5,
    y: 1266.5
  },
  {
    x: 1501.5,
    y: 1251.5
  },
  {
    x: 1501.5,
    y: 1236.5
  },
  {
    x: 1501.5,
    y: 1221.5
  },
  {
    x: 1501.5,
    y: 1206.5
  },
  {
    x: 1501.5,
    y: 1191.5
  },
  {
    x: 1501.5,
    y: 1176.5
  },
  {
    x: 1501.5,
    y: 1161.5
  },
  {
    x: 1501.5,
    y: 1146.5
  },
  {
    x: 1501.5,
    y: 1131.5
  },
  {
    x: 1501.5,
    y: 1116.5
  },
  {
    x: 1501.5,
    y: 1101.5
  },
  {
    x: 1501.5,
    y: 1086.5
  },
  {
    x: 1501.5,
    y: 1071.5
  },
  {
    x: 1501.5,
    y: 1056.5
  },
  {
    x: 1501.5,
    y: 1041.5
  },
  {
    x: 1501.5,
    y: 321.5
  },
  {
    x: 1501.5,
    y: 306.5
  },
  {
    x: 1501.5,
    y: 291.5
  },
  {
    x: 1501.5,
    y: 276.5
  },
  {
    x: 1501.5,
    y: 261.5
  },
  {
    x: 1501.5,
    y: 246.5
  },
  {
    x: 1501.5,
    y: 231.5
  },
  {
    x: 1501.5,
    y: 216.5
  },
  {
    x: 1501.5,
    y: 201.5
  },
  {
    x: 1501.5,
    y: 186.5
  },
  {
    x: 1501.5,
    y: 171.5
  },
  {
    x: 1501.5,
    y: 156.5
  },
  {
    x: 1501.5,
    y: 141.5
  },
  {
    x: 1501.5,
    y: 126.5
  },
  {
    x: 1501.5,
    y: 111.5
  },
  {
    x: 1501.5,
    y: 96.5
  },
  {
    x: 1516.5,
    y: 2046.5
  },
  {
    x: 1516.5,
    y: 2031.5
  },
  {
    x: 1516.5,
    y: 2016.5
  },
  {
    x: 1516.5,
    y: 2001.5
  },
  {
    x: 1516.5,
    y: 1986.5
  },
  {
    x: 1516.5,
    y: 1971.5
  },
  {
    x: 1516.5,
    y: 1956.5
  },
  {
    x: 1516.5,
    y: 1926.5
  },
  {
    x: 1516.5,
    y: 1911.5
  },
  {
    x: 1516.5,
    y: 1296.5
  },
  {
    x: 1516.5,
    y: 1281.5
  },
  {
    x: 1516.5,
    y: 1266.5
  },
  {
    x: 1516.5,
    y: 1251.5
  },
  {
    x: 1516.5,
    y: 1236.5
  },
  {
    x: 1516.5,
    y: 1221.5
  },
  {
    x: 1516.5,
    y: 1206.5
  },
  {
    x: 1516.5,
    y: 1191.5
  },
  {
    x: 1516.5,
    y: 1176.5
  },
  {
    x: 1516.5,
    y: 1161.5
  },
  {
    x: 1516.5,
    y: 1146.5
  },
  {
    x: 1516.5,
    y: 1131.5
  },
  {
    x: 1516.5,
    y: 1116.5
  },
  {
    x: 1516.5,
    y: 1101.5
  },
  {
    x: 1516.5,
    y: 1086.5
  },
  {
    x: 1516.5,
    y: 1071.5
  },
  {
    x: 1516.5,
    y: 1056.5
  },
  {
    x: 1516.5,
    y: 1041.5
  },
  {
    x: 1516.5,
    y: 321.5
  },
  {
    x: 1516.5,
    y: 306.5
  },
  {
    x: 1516.5,
    y: 291.5
  },
  {
    x: 1516.5,
    y: 276.5
  },
  {
    x: 1516.5,
    y: 261.5
  },
  {
    x: 1516.5,
    y: 246.5
  },
  {
    x: 1516.5,
    y: 231.5
  },
  {
    x: 1516.5,
    y: 216.5
  },
  {
    x: 1516.5,
    y: 201.5
  },
  {
    x: 1516.5,
    y: 186.5
  },
  {
    x: 1516.5,
    y: 171.5
  },
  {
    x: 1516.5,
    y: 156.5
  },
  {
    x: 1516.5,
    y: 141.5
  },
  {
    x: 1516.5,
    y: 126.5
  },
  {
    x: 1516.5,
    y: 111.5
  },
  {
    x: 1516.5,
    y: 96.5
  },
  {
    x: 1531.5,
    y: 2046.5
  },
  {
    x: 1531.5,
    y: 2031.5
  },
  {
    x: 1531.5,
    y: 2016.5
  },
  {
    x: 1531.5,
    y: 2001.5
  },
  {
    x: 1531.5,
    y: 1986.5
  },
  {
    x: 1531.5,
    y: 1971.5
  },
  {
    x: 1531.5,
    y: 1926.5
  },
  {
    x: 1531.5,
    y: 1911.5
  },
  {
    x: 1531.5,
    y: 1296.5
  },
  {
    x: 1531.5,
    y: 1281.5
  },
  {
    x: 1531.5,
    y: 1266.5
  },
  {
    x: 1531.5,
    y: 1251.5
  },
  {
    x: 1531.5,
    y: 1236.5
  },
  {
    x: 1531.5,
    y: 1221.5
  },
  {
    x: 1531.5,
    y: 1206.5
  },
  {
    x: 1531.5,
    y: 1191.5
  },
  {
    x: 1531.5,
    y: 1176.5
  },
  {
    x: 1531.5,
    y: 1161.5
  },
  {
    x: 1531.5,
    y: 1146.5
  },
  {
    x: 1531.5,
    y: 1131.5
  },
  {
    x: 1531.5,
    y: 1116.5
  },
  {
    x: 1531.5,
    y: 1101.5
  },
  {
    x: 1531.5,
    y: 1086.5
  },
  {
    x: 1531.5,
    y: 1071.5
  },
  {
    x: 1531.5,
    y: 1056.5
  },
  {
    x: 1531.5,
    y: 1041.5
  },
  {
    x: 1531.5,
    y: 336.5
  },
  {
    x: 1531.5,
    y: 321.5
  },
  {
    x: 1531.5,
    y: 306.5
  },
  {
    x: 1531.5,
    y: 291.5
  },
  {
    x: 1531.5,
    y: 276.5
  },
  {
    x: 1531.5,
    y: 261.5
  },
  {
    x: 1531.5,
    y: 246.5
  },
  {
    x: 1531.5,
    y: 231.5
  },
  {
    x: 1531.5,
    y: 216.5
  },
  {
    x: 1531.5,
    y: 201.5
  },
  {
    x: 1531.5,
    y: 186.5
  },
  {
    x: 1531.5,
    y: 171.5
  },
  {
    x: 1531.5,
    y: 156.5
  },
  {
    x: 1531.5,
    y: 141.5
  },
  {
    x: 1531.5,
    y: 126.5
  },
  {
    x: 1531.5,
    y: 111.5
  },
  {
    x: 1531.5,
    y: 96.5
  },
  {
    x: 1531.5,
    y: 81.5
  },
  {
    x: 1546.5,
    y: 2046.5
  },
  {
    x: 1546.5,
    y: 2031.5
  },
  {
    x: 1546.5,
    y: 2016.5
  },
  {
    x: 1546.5,
    y: 2001.5
  },
  {
    x: 1546.5,
    y: 1986.5
  },
  {
    x: 1546.5,
    y: 1971.5
  },
  {
    x: 1546.5,
    y: 1926.5
  },
  {
    x: 1546.5,
    y: 1281.5
  },
  {
    x: 1546.5,
    y: 1266.5
  },
  {
    x: 1546.5,
    y: 1251.5
  },
  {
    x: 1546.5,
    y: 1236.5
  },
  {
    x: 1546.5,
    y: 1221.5
  },
  {
    x: 1546.5,
    y: 1206.5
  },
  {
    x: 1546.5,
    y: 1191.5
  },
  {
    x: 1546.5,
    y: 1176.5
  },
  {
    x: 1546.5,
    y: 1161.5
  },
  {
    x: 1546.5,
    y: 1146.5
  },
  {
    x: 1546.5,
    y: 1131.5
  },
  {
    x: 1546.5,
    y: 1116.5
  },
  {
    x: 1546.5,
    y: 1101.5
  },
  {
    x: 1546.5,
    y: 1086.5
  },
  {
    x: 1546.5,
    y: 1071.5
  },
  {
    x: 1546.5,
    y: 1056.5
  },
  {
    x: 1546.5,
    y: 336.5
  },
  {
    x: 1546.5,
    y: 321.5
  },
  {
    x: 1546.5,
    y: 306.5
  },
  {
    x: 1546.5,
    y: 291.5
  },
  {
    x: 1546.5,
    y: 276.5
  },
  {
    x: 1546.5,
    y: 261.5
  },
  {
    x: 1546.5,
    y: 246.5
  },
  {
    x: 1546.5,
    y: 231.5
  },
  {
    x: 1546.5,
    y: 216.5
  },
  {
    x: 1546.5,
    y: 201.5
  },
  {
    x: 1546.5,
    y: 186.5
  },
  {
    x: 1546.5,
    y: 171.5
  },
  {
    x: 1546.5,
    y: 156.5
  },
  {
    x: 1546.5,
    y: 141.5
  },
  {
    x: 1546.5,
    y: 126.5
  },
  {
    x: 1546.5,
    y: 111.5
  },
  {
    x: 1546.5,
    y: 96.5
  },
  {
    x: 1546.5,
    y: 81.5
  },
  {
    x: 1561.5,
    y: 2046.5
  },
  {
    x: 1561.5,
    y: 2031.5
  },
  {
    x: 1561.5,
    y: 2016.5
  },
  {
    x: 1561.5,
    y: 2001.5
  },
  {
    x: 1561.5,
    y: 1986.5
  },
  {
    x: 1561.5,
    y: 1971.5
  },
  {
    x: 1561.5,
    y: 1956.5
  },
  {
    x: 1561.5,
    y: 1281.5
  },
  {
    x: 1561.5,
    y: 1266.5
  },
  {
    x: 1561.5,
    y: 1251.5
  },
  {
    x: 1561.5,
    y: 1236.5
  },
  {
    x: 1561.5,
    y: 1221.5
  },
  {
    x: 1561.5,
    y: 1206.5
  },
  {
    x: 1561.5,
    y: 1191.5
  },
  {
    x: 1561.5,
    y: 1176.5
  },
  {
    x: 1561.5,
    y: 1161.5
  },
  {
    x: 1561.5,
    y: 1146.5
  },
  {
    x: 1561.5,
    y: 1131.5
  },
  {
    x: 1561.5,
    y: 1116.5
  },
  {
    x: 1561.5,
    y: 1101.5
  },
  {
    x: 1561.5,
    y: 1086.5
  },
  {
    x: 1561.5,
    y: 1071.5
  },
  {
    x: 1561.5,
    y: 1056.5
  },
  {
    x: 1561.5,
    y: 321.5
  },
  {
    x: 1561.5,
    y: 306.5
  },
  {
    x: 1561.5,
    y: 291.5
  },
  {
    x: 1561.5,
    y: 276.5
  },
  {
    x: 1561.5,
    y: 261.5
  },
  {
    x: 1561.5,
    y: 246.5
  },
  {
    x: 1561.5,
    y: 231.5
  },
  {
    x: 1561.5,
    y: 216.5
  },
  {
    x: 1561.5,
    y: 201.5
  },
  {
    x: 1561.5,
    y: 186.5
  },
  {
    x: 1561.5,
    y: 171.5
  },
  {
    x: 1561.5,
    y: 156.5
  },
  {
    x: 1561.5,
    y: 141.5
  },
  {
    x: 1561.5,
    y: 126.5
  },
  {
    x: 1561.5,
    y: 111.5
  },
  {
    x: 1561.5,
    y: 96.5
  },
  {
    x: 1561.5,
    y: 81.5
  },
  {
    x: 1576.5,
    y: 2046.5
  },
  {
    x: 1576.5,
    y: 2031.5
  },
  {
    x: 1576.5,
    y: 2016.5
  },
  {
    x: 1576.5,
    y: 2001.5
  },
  {
    x: 1576.5,
    y: 1986.5
  },
  {
    x: 1576.5,
    y: 1971.5
  },
  {
    x: 1576.5,
    y: 1956.5
  },
  {
    x: 1576.5,
    y: 1266.5
  },
  {
    x: 1576.5,
    y: 1251.5
  },
  {
    x: 1576.5,
    y: 1236.5
  },
  {
    x: 1576.5,
    y: 1221.5
  },
  {
    x: 1576.5,
    y: 1206.5
  },
  {
    x: 1576.5,
    y: 1191.5
  },
  {
    x: 1576.5,
    y: 1176.5
  },
  {
    x: 1576.5,
    y: 1161.5
  },
  {
    x: 1576.5,
    y: 1146.5
  },
  {
    x: 1576.5,
    y: 1131.5
  },
  {
    x: 1576.5,
    y: 1116.5
  },
  {
    x: 1576.5,
    y: 1101.5
  },
  {
    x: 1576.5,
    y: 1086.5
  },
  {
    x: 1576.5,
    y: 1071.5
  },
  {
    x: 1576.5,
    y: 1056.5
  },
  {
    x: 1576.5,
    y: 306.5
  },
  {
    x: 1576.5,
    y: 291.5
  },
  {
    x: 1576.5,
    y: 276.5
  },
  {
    x: 1576.5,
    y: 261.5
  },
  {
    x: 1576.5,
    y: 246.5
  },
  {
    x: 1576.5,
    y: 231.5
  },
  {
    x: 1576.5,
    y: 216.5
  },
  {
    x: 1576.5,
    y: 201.5
  },
  {
    x: 1576.5,
    y: 186.5
  },
  {
    x: 1576.5,
    y: 171.5
  },
  {
    x: 1576.5,
    y: 156.5
  },
  {
    x: 1576.5,
    y: 141.5
  },
  {
    x: 1576.5,
    y: 126.5
  },
  {
    x: 1576.5,
    y: 111.5
  },
  {
    x: 1576.5,
    y: 96.5
  },
  {
    x: 1576.5,
    y: 81.5
  },
  {
    x: 1591.5,
    y: 2046.5
  },
  {
    x: 1591.5,
    y: 2031.5
  },
  {
    x: 1591.5,
    y: 2016.5
  },
  {
    x: 1591.5,
    y: 2001.5
  },
  {
    x: 1591.5,
    y: 1986.5
  },
  {
    x: 1591.5,
    y: 1971.5
  },
  {
    x: 1591.5,
    y: 1956.5
  },
  {
    x: 1591.5,
    y: 1251.5
  },
  {
    x: 1591.5,
    y: 1236.5
  },
  {
    x: 1591.5,
    y: 1221.5
  },
  {
    x: 1591.5,
    y: 1206.5
  },
  {
    x: 1591.5,
    y: 1191.5
  },
  {
    x: 1591.5,
    y: 1176.5
  },
  {
    x: 1591.5,
    y: 1161.5
  },
  {
    x: 1591.5,
    y: 1146.5
  },
  {
    x: 1591.5,
    y: 1131.5
  },
  {
    x: 1591.5,
    y: 1116.5
  },
  {
    x: 1591.5,
    y: 1101.5
  },
  {
    x: 1591.5,
    y: 1086.5
  },
  {
    x: 1591.5,
    y: 1071.5
  },
  {
    x: 1591.5,
    y: 1056.5
  },
  {
    x: 1591.5,
    y: 276.5
  },
  {
    x: 1591.5,
    y: 261.5
  },
  {
    x: 1591.5,
    y: 246.5
  },
  {
    x: 1591.5,
    y: 231.5
  },
  {
    x: 1591.5,
    y: 216.5
  },
  {
    x: 1591.5,
    y: 201.5
  },
  {
    x: 1591.5,
    y: 186.5
  },
  {
    x: 1591.5,
    y: 171.5
  },
  {
    x: 1591.5,
    y: 156.5
  },
  {
    x: 1591.5,
    y: 141.5
  },
  {
    x: 1591.5,
    y: 126.5
  },
  {
    x: 1591.5,
    y: 111.5
  },
  {
    x: 1591.5,
    y: 96.5
  },
  {
    x: 1591.5,
    y: 81.5
  },
  {
    x: 1606.5,
    y: 2046.5
  },
  {
    x: 1606.5,
    y: 2031.5
  },
  {
    x: 1606.5,
    y: 2016.5
  },
  {
    x: 1606.5,
    y: 2001.5
  },
  {
    x: 1606.5,
    y: 1986.5
  },
  {
    x: 1606.5,
    y: 1971.5
  },
  {
    x: 1606.5,
    y: 1956.5
  },
  {
    x: 1606.5,
    y: 1161.5
  },
  {
    x: 1606.5,
    y: 1146.5
  },
  {
    x: 1606.5,
    y: 1131.5
  },
  {
    x: 1606.5,
    y: 1116.5
  },
  {
    x: 1606.5,
    y: 1101.5
  },
  {
    x: 1606.5,
    y: 1086.5
  },
  {
    x: 1606.5,
    y: 1071.5
  },
  {
    x: 1606.5,
    y: 276.5
  },
  {
    x: 1606.5,
    y: 261.5
  },
  {
    x: 1606.5,
    y: 246.5
  },
  {
    x: 1606.5,
    y: 231.5
  },
  {
    x: 1606.5,
    y: 216.5
  },
  {
    x: 1606.5,
    y: 201.5
  },
  {
    x: 1606.5,
    y: 186.5
  },
  {
    x: 1606.5,
    y: 171.5
  },
  {
    x: 1606.5,
    y: 156.5
  },
  {
    x: 1606.5,
    y: 141.5
  },
  {
    x: 1606.5,
    y: 126.5
  },
  {
    x: 1606.5,
    y: 111.5
  },
  {
    x: 1606.5,
    y: 96.5
  },
  {
    x: 1606.5,
    y: 81.5
  },
  {
    x: 1621.5,
    y: 2046.5
  },
  {
    x: 1621.5,
    y: 2031.5
  },
  {
    x: 1621.5,
    y: 2016.5
  },
  {
    x: 1621.5,
    y: 2001.5
  },
  {
    x: 1621.5,
    y: 1986.5
  },
  {
    x: 1621.5,
    y: 1971.5
  },
  {
    x: 1621.5,
    y: 1956.5
  },
  {
    x: 1621.5,
    y: 1146.5
  },
  {
    x: 1621.5,
    y: 1131.5
  },
  {
    x: 1621.5,
    y: 1116.5
  },
  {
    x: 1621.5,
    y: 1101.5
  },
  {
    x: 1621.5,
    y: 1086.5
  },
  {
    x: 1621.5,
    y: 276.5
  },
  {
    x: 1621.5,
    y: 261.5
  },
  {
    x: 1621.5,
    y: 246.5
  },
  {
    x: 1621.5,
    y: 231.5
  },
  {
    x: 1621.5,
    y: 216.5
  },
  {
    x: 1621.5,
    y: 201.5
  },
  {
    x: 1621.5,
    y: 186.5
  },
  {
    x: 1621.5,
    y: 171.5
  },
  {
    x: 1621.5,
    y: 156.5
  },
  {
    x: 1621.5,
    y: 141.5
  },
  {
    x: 1621.5,
    y: 126.5
  },
  {
    x: 1621.5,
    y: 111.5
  },
  {
    x: 1621.5,
    y: 96.5
  },
  {
    x: 1621.5,
    y: 81.5
  },
  {
    x: 1636.5,
    y: 2046.5
  },
  {
    x: 1636.5,
    y: 2031.5
  },
  {
    x: 1636.5,
    y: 2016.5
  },
  {
    x: 1636.5,
    y: 2001.5
  },
  {
    x: 1636.5,
    y: 1986.5
  },
  {
    x: 1636.5,
    y: 1971.5
  },
  {
    x: 1636.5,
    y: 1956.5
  },
  {
    x: 1636.5,
    y: 1926.5
  },
  {
    x: 1636.5,
    y: 1131.5
  },
  {
    x: 1636.5,
    y: 1116.5
  },
  {
    x: 1636.5,
    y: 1101.5
  },
  {
    x: 1636.5,
    y: 1086.5
  },
  {
    x: 1636.5,
    y: 261.5
  },
  {
    x: 1636.5,
    y: 246.5
  },
  {
    x: 1636.5,
    y: 231.5
  },
  {
    x: 1636.5,
    y: 216.5
  },
  {
    x: 1636.5,
    y: 201.5
  },
  {
    x: 1636.5,
    y: 186.5
  },
  {
    x: 1636.5,
    y: 171.5
  },
  {
    x: 1636.5,
    y: 156.5
  },
  {
    x: 1636.5,
    y: 141.5
  },
  {
    x: 1636.5,
    y: 126.5
  },
  {
    x: 1636.5,
    y: 111.5
  },
  {
    x: 1636.5,
    y: 96.5
  },
  {
    x: 1636.5,
    y: 81.5
  },
  {
    x: 1651.5,
    y: 2046.5
  },
  {
    x: 1651.5,
    y: 2031.5
  },
  {
    x: 1651.5,
    y: 2016.5
  },
  {
    x: 1651.5,
    y: 2001.5
  },
  {
    x: 1651.5,
    y: 1986.5
  },
  {
    x: 1651.5,
    y: 1971.5
  },
  {
    x: 1651.5,
    y: 1956.5
  },
  {
    x: 1651.5,
    y: 1941.5
  },
  {
    x: 1651.5,
    y: 1926.5
  },
  {
    x: 1651.5,
    y: 1911.5
  },
  {
    x: 1651.5,
    y: 1116.5
  },
  {
    x: 1651.5,
    y: 1101.5
  },
  {
    x: 1651.5,
    y: 261.5
  },
  {
    x: 1651.5,
    y: 246.5
  },
  {
    x: 1651.5,
    y: 231.5
  },
  {
    x: 1651.5,
    y: 216.5
  },
  {
    x: 1651.5,
    y: 201.5
  },
  {
    x: 1651.5,
    y: 186.5
  },
  {
    x: 1651.5,
    y: 171.5
  },
  {
    x: 1651.5,
    y: 156.5
  },
  {
    x: 1651.5,
    y: 141.5
  },
  {
    x: 1651.5,
    y: 126.5
  },
  {
    x: 1651.5,
    y: 111.5
  },
  {
    x: 1651.5,
    y: 96.5
  },
  {
    x: 1651.5,
    y: 81.5
  },
  {
    x: 1666.5,
    y: 2046.5
  },
  {
    x: 1666.5,
    y: 2031.5
  },
  {
    x: 1666.5,
    y: 2016.5
  },
  {
    x: 1666.5,
    y: 2001.5
  },
  {
    x: 1666.5,
    y: 1986.5
  },
  {
    x: 1666.5,
    y: 1971.5
  },
  {
    x: 1666.5,
    y: 1956.5
  },
  {
    x: 1666.5,
    y: 1941.5
  },
  {
    x: 1666.5,
    y: 1911.5
  },
  {
    x: 1666.5,
    y: 246.5
  },
  {
    x: 1666.5,
    y: 231.5
  },
  {
    x: 1666.5,
    y: 216.5
  },
  {
    x: 1666.5,
    y: 201.5
  },
  {
    x: 1666.5,
    y: 186.5
  },
  {
    x: 1666.5,
    y: 171.5
  },
  {
    x: 1666.5,
    y: 156.5
  },
  {
    x: 1666.5,
    y: 141.5
  },
  {
    x: 1666.5,
    y: 126.5
  },
  {
    x: 1666.5,
    y: 111.5
  },
  {
    x: 1666.5,
    y: 96.5
  },
  {
    x: 1666.5,
    y: 81.5
  },
  {
    x: 1681.5,
    y: 2046.5
  },
  {
    x: 1681.5,
    y: 2031.5
  },
  {
    x: 1681.5,
    y: 2016.5
  },
  {
    x: 1681.5,
    y: 2001.5
  },
  {
    x: 1681.5,
    y: 1986.5
  },
  {
    x: 1681.5,
    y: 1971.5
  },
  {
    x: 1681.5,
    y: 1956.5
  },
  {
    x: 1681.5,
    y: 1941.5
  },
  {
    x: 1681.5,
    y: 1911.5
  },
  {
    x: 1681.5,
    y: 246.5
  },
  {
    x: 1681.5,
    y: 231.5
  },
  {
    x: 1681.5,
    y: 216.5
  },
  {
    x: 1681.5,
    y: 201.5
  },
  {
    x: 1681.5,
    y: 186.5
  },
  {
    x: 1681.5,
    y: 171.5
  },
  {
    x: 1681.5,
    y: 156.5
  },
  {
    x: 1681.5,
    y: 141.5
  },
  {
    x: 1681.5,
    y: 126.5
  },
  {
    x: 1681.5,
    y: 111.5
  },
  {
    x: 1681.5,
    y: 96.5
  },
  {
    x: 1681.5,
    y: 81.5
  },
  {
    x: 1696.5,
    y: 2046.5
  },
  {
    x: 1696.5,
    y: 2031.5
  },
  {
    x: 1696.5,
    y: 2016.5
  },
  {
    x: 1696.5,
    y: 2001.5
  },
  {
    x: 1696.5,
    y: 1986.5
  },
  {
    x: 1696.5,
    y: 1971.5
  },
  {
    x: 1696.5,
    y: 1956.5
  },
  {
    x: 1696.5,
    y: 1941.5
  },
  {
    x: 1696.5,
    y: 1911.5
  },
  {
    x: 1696.5,
    y: 246.5
  },
  {
    x: 1696.5,
    y: 231.5
  },
  {
    x: 1696.5,
    y: 216.5
  },
  {
    x: 1696.5,
    y: 201.5
  },
  {
    x: 1696.5,
    y: 186.5
  },
  {
    x: 1696.5,
    y: 171.5
  },
  {
    x: 1696.5,
    y: 156.5
  },
  {
    x: 1696.5,
    y: 141.5
  },
  {
    x: 1696.5,
    y: 126.5
  },
  {
    x: 1696.5,
    y: 111.5
  },
  {
    x: 1696.5,
    y: 96.5
  },
  {
    x: 1696.5,
    y: 81.5
  },
  {
    x: 1711.5,
    y: 2046.5
  },
  {
    x: 1711.5,
    y: 2031.5
  },
  {
    x: 1711.5,
    y: 2016.5
  },
  {
    x: 1711.5,
    y: 2001.5
  },
  {
    x: 1711.5,
    y: 1986.5
  },
  {
    x: 1711.5,
    y: 1971.5
  },
  {
    x: 1711.5,
    y: 1956.5
  },
  {
    x: 1711.5,
    y: 1941.5
  },
  {
    x: 1711.5,
    y: 1926.5
  },
  {
    x: 1711.5,
    y: 1911.5
  },
  {
    x: 1711.5,
    y: 1896.5
  },
  {
    x: 1711.5,
    y: 246.5
  },
  {
    x: 1711.5,
    y: 231.5
  },
  {
    x: 1711.5,
    y: 216.5
  },
  {
    x: 1711.5,
    y: 201.5
  },
  {
    x: 1711.5,
    y: 186.5
  },
  {
    x: 1711.5,
    y: 171.5
  },
  {
    x: 1711.5,
    y: 156.5
  },
  {
    x: 1711.5,
    y: 141.5
  },
  {
    x: 1711.5,
    y: 126.5
  },
  {
    x: 1711.5,
    y: 111.5
  },
  {
    x: 1711.5,
    y: 96.5
  },
  {
    x: 1711.5,
    y: 81.5
  },
  {
    x: 1726.5,
    y: 2046.5
  },
  {
    x: 1726.5,
    y: 2031.5
  },
  {
    x: 1726.5,
    y: 2016.5
  },
  {
    x: 1726.5,
    y: 2001.5
  },
  {
    x: 1726.5,
    y: 1986.5
  },
  {
    x: 1726.5,
    y: 1971.5
  },
  {
    x: 1726.5,
    y: 1956.5
  },
  {
    x: 1726.5,
    y: 1941.5
  },
  {
    x: 1726.5,
    y: 1926.5
  },
  {
    x: 1726.5,
    y: 1911.5
  },
  {
    x: 1726.5,
    y: 1896.5
  },
  {
    x: 1726.5,
    y: 231.5
  },
  {
    x: 1726.5,
    y: 216.5
  },
  {
    x: 1726.5,
    y: 201.5
  },
  {
    x: 1726.5,
    y: 186.5
  },
  {
    x: 1726.5,
    y: 171.5
  },
  {
    x: 1726.5,
    y: 156.5
  },
  {
    x: 1726.5,
    y: 141.5
  },
  {
    x: 1726.5,
    y: 126.5
  },
  {
    x: 1726.5,
    y: 111.5
  },
  {
    x: 1726.5,
    y: 96.5
  },
  {
    x: 1726.5,
    y: 81.5
  },
  {
    x: 1741.5,
    y: 2046.5
  },
  {
    x: 1741.5,
    y: 2031.5
  },
  {
    x: 1741.5,
    y: 2016.5
  },
  {
    x: 1741.5,
    y: 2001.5
  },
  {
    x: 1741.5,
    y: 1986.5
  },
  {
    x: 1741.5,
    y: 1971.5
  },
  {
    x: 1741.5,
    y: 1956.5
  },
  {
    x: 1741.5,
    y: 1941.5
  },
  {
    x: 1741.5,
    y: 1926.5
  },
  {
    x: 1741.5,
    y: 1911.5
  },
  {
    x: 1741.5,
    y: 1896.5
  },
  {
    x: 1741.5,
    y: 231.5
  },
  {
    x: 1741.5,
    y: 216.5
  },
  {
    x: 1741.5,
    y: 201.5
  },
  {
    x: 1741.5,
    y: 186.5
  },
  {
    x: 1741.5,
    y: 171.5
  },
  {
    x: 1741.5,
    y: 156.5
  },
  {
    x: 1741.5,
    y: 141.5
  },
  {
    x: 1741.5,
    y: 126.5
  },
  {
    x: 1741.5,
    y: 111.5
  },
  {
    x: 1741.5,
    y: 96.5
  },
  {
    x: 1741.5,
    y: 81.5
  },
  {
    x: 1756.5,
    y: 2046.5
  },
  {
    x: 1756.5,
    y: 2031.5
  },
  {
    x: 1756.5,
    y: 2016.5
  },
  {
    x: 1756.5,
    y: 2001.5
  },
  {
    x: 1756.5,
    y: 1986.5
  },
  {
    x: 1756.5,
    y: 1971.5
  },
  {
    x: 1756.5,
    y: 1956.5
  },
  {
    x: 1756.5,
    y: 1941.5
  },
  {
    x: 1756.5,
    y: 1926.5
  },
  {
    x: 1756.5,
    y: 1911.5
  },
  {
    x: 1756.5,
    y: 1896.5
  },
  {
    x: 1756.5,
    y: 231.5
  },
  {
    x: 1756.5,
    y: 216.5
  },
  {
    x: 1756.5,
    y: 201.5
  },
  {
    x: 1756.5,
    y: 186.5
  },
  {
    x: 1756.5,
    y: 171.5
  },
  {
    x: 1756.5,
    y: 156.5
  },
  {
    x: 1756.5,
    y: 141.5
  },
  {
    x: 1756.5,
    y: 126.5
  },
  {
    x: 1756.5,
    y: 111.5
  },
  {
    x: 1756.5,
    y: 96.5
  },
  {
    x: 1756.5,
    y: 81.5
  },
  {
    x: 1771.5,
    y: 2046.5
  },
  {
    x: 1771.5,
    y: 2031.5
  },
  {
    x: 1771.5,
    y: 2016.5
  },
  {
    x: 1771.5,
    y: 2001.5
  },
  {
    x: 1771.5,
    y: 1986.5
  },
  {
    x: 1771.5,
    y: 1971.5
  },
  {
    x: 1771.5,
    y: 1956.5
  },
  {
    x: 1771.5,
    y: 1941.5
  },
  {
    x: 1771.5,
    y: 1926.5
  },
  {
    x: 1771.5,
    y: 1911.5
  },
  {
    x: 1771.5,
    y: 1896.5
  },
  {
    x: 1771.5,
    y: 231.5
  },
  {
    x: 1771.5,
    y: 216.5
  },
  {
    x: 1771.5,
    y: 201.5
  },
  {
    x: 1771.5,
    y: 186.5
  },
  {
    x: 1771.5,
    y: 171.5
  },
  {
    x: 1771.5,
    y: 156.5
  },
  {
    x: 1771.5,
    y: 141.5
  },
  {
    x: 1771.5,
    y: 126.5
  },
  {
    x: 1771.5,
    y: 111.5
  },
  {
    x: 1771.5,
    y: 96.5
  },
  {
    x: 1771.5,
    y: 81.5
  },
  {
    x: 1786.5,
    y: 2046.5
  },
  {
    x: 1786.5,
    y: 2031.5
  },
  {
    x: 1786.5,
    y: 2016.5
  },
  {
    x: 1786.5,
    y: 2001.5
  },
  {
    x: 1786.5,
    y: 1986.5
  },
  {
    x: 1786.5,
    y: 1971.5
  },
  {
    x: 1786.5,
    y: 1956.5
  },
  {
    x: 1786.5,
    y: 1941.5
  },
  {
    x: 1786.5,
    y: 1926.5
  },
  {
    x: 1786.5,
    y: 1911.5
  },
  {
    x: 1786.5,
    y: 1896.5
  },
  {
    x: 1786.5,
    y: 276.5
  },
  {
    x: 1786.5,
    y: 216.5
  },
  {
    x: 1786.5,
    y: 201.5
  },
  {
    x: 1786.5,
    y: 186.5
  },
  {
    x: 1786.5,
    y: 171.5
  },
  {
    x: 1786.5,
    y: 156.5
  },
  {
    x: 1786.5,
    y: 141.5
  },
  {
    x: 1786.5,
    y: 126.5
  },
  {
    x: 1786.5,
    y: 111.5
  },
  {
    x: 1786.5,
    y: 96.5
  },
  {
    x: 1786.5,
    y: 81.5
  },
  {
    x: 1801.5,
    y: 2046.5
  },
  {
    x: 1801.5,
    y: 2031.5
  },
  {
    x: 1801.5,
    y: 2016.5
  },
  {
    x: 1801.5,
    y: 2001.5
  },
  {
    x: 1801.5,
    y: 1986.5
  },
  {
    x: 1801.5,
    y: 1971.5
  },
  {
    x: 1801.5,
    y: 1956.5
  },
  {
    x: 1801.5,
    y: 1941.5
  },
  {
    x: 1801.5,
    y: 1926.5
  },
  {
    x: 1801.5,
    y: 1911.5
  },
  {
    x: 1801.5,
    y: 1896.5
  },
  {
    x: 1801.5,
    y: 1866.5
  },
  {
    x: 1801.5,
    y: 291.5
  },
  {
    x: 1801.5,
    y: 276.5
  },
  {
    x: 1801.5,
    y: 216.5
  },
  {
    x: 1801.5,
    y: 186.5
  },
  {
    x: 1801.5,
    y: 171.5
  },
  {
    x: 1801.5,
    y: 156.5
  },
  {
    x: 1801.5,
    y: 141.5
  },
  {
    x: 1801.5,
    y: 126.5
  },
  {
    x: 1801.5,
    y: 111.5
  },
  {
    x: 1801.5,
    y: 96.5
  },
  {
    x: 1801.5,
    y: 81.5
  },
  {
    x: 1816.5,
    y: 2046.5
  },
  {
    x: 1816.5,
    y: 2031.5
  },
  {
    x: 1816.5,
    y: 2016.5
  },
  {
    x: 1816.5,
    y: 2001.5
  },
  {
    x: 1816.5,
    y: 1986.5
  },
  {
    x: 1816.5,
    y: 1971.5
  },
  {
    x: 1816.5,
    y: 1956.5
  },
  {
    x: 1816.5,
    y: 1941.5
  },
  {
    x: 1816.5,
    y: 1926.5
  },
  {
    x: 1816.5,
    y: 1911.5
  },
  {
    x: 1816.5,
    y: 1896.5
  },
  {
    x: 1816.5,
    y: 291.5
  },
  {
    x: 1816.5,
    y: 276.5
  },
  {
    x: 1816.5,
    y: 186.5
  },
  {
    x: 1816.5,
    y: 171.5
  },
  {
    x: 1816.5,
    y: 156.5
  },
  {
    x: 1816.5,
    y: 141.5
  },
  {
    x: 1816.5,
    y: 126.5
  },
  {
    x: 1816.5,
    y: 111.5
  },
  {
    x: 1816.5,
    y: 96.5
  },
  {
    x: 1831.5,
    y: 2046.5
  },
  {
    x: 1831.5,
    y: 2031.5
  },
  {
    x: 1831.5,
    y: 2016.5
  },
  {
    x: 1831.5,
    y: 2001.5
  },
  {
    x: 1831.5,
    y: 1986.5
  },
  {
    x: 1831.5,
    y: 1971.5
  },
  {
    x: 1831.5,
    y: 1956.5
  },
  {
    x: 1831.5,
    y: 1941.5
  },
  {
    x: 1831.5,
    y: 1926.5
  },
  {
    x: 1831.5,
    y: 1911.5
  },
  {
    x: 1831.5,
    y: 1896.5
  },
  {
    x: 1831.5,
    y: 291.5
  },
  {
    x: 1831.5,
    y: 276.5
  },
  {
    x: 1831.5,
    y: 156.5
  },
  {
    x: 1831.5,
    y: 141.5
  },
  {
    x: 1831.5,
    y: 111.5
  },
  {
    x: 1831.5,
    y: 96.5
  },
  {
    x: 1846.5,
    y: 2046.5
  },
  {
    x: 1846.5,
    y: 2031.5
  },
  {
    x: 1846.5,
    y: 2016.5
  },
  {
    x: 1846.5,
    y: 2001.5
  },
  {
    x: 1846.5,
    y: 1986.5
  },
  {
    x: 1846.5,
    y: 1971.5
  },
  {
    x: 1846.5,
    y: 1956.5
  },
  {
    x: 1846.5,
    y: 1941.5
  },
  {
    x: 1846.5,
    y: 1926.5
  },
  {
    x: 1846.5,
    y: 1911.5
  },
  {
    x: 1846.5,
    y: 1896.5
  },
  {
    x: 1846.5,
    y: 1881.5
  },
  {
    x: 1846.5,
    y: 291.5
  },
  {
    x: 1846.5,
    y: 276.5
  },
  {
    x: 1846.5,
    y: 171.5
  },
  {
    x: 1846.5,
    y: 141.5
  },
  {
    x: 1846.5,
    y: 111.5
  },
  {
    x: 1846.5,
    y: 96.5
  },
  {
    x: 1861.5,
    y: 2046.5
  },
  {
    x: 1861.5,
    y: 2031.5
  },
  {
    x: 1861.5,
    y: 2016.5
  },
  {
    x: 1861.5,
    y: 2001.5
  },
  {
    x: 1861.5,
    y: 1986.5
  },
  {
    x: 1861.5,
    y: 1971.5
  },
  {
    x: 1861.5,
    y: 1956.5
  },
  {
    x: 1861.5,
    y: 1941.5
  },
  {
    x: 1861.5,
    y: 1926.5
  },
  {
    x: 1861.5,
    y: 1911.5
  },
  {
    x: 1861.5,
    y: 1896.5
  },
  {
    x: 1861.5,
    y: 1881.5
  },
  {
    x: 1861.5,
    y: 1851.5
  },
  {
    x: 1861.5,
    y: 876.5
  },
  {
    x: 1861.5,
    y: 861.5
  },
  {
    x: 1861.5,
    y: 846.5
  },
  {
    x: 1861.5,
    y: 831.5
  },
  {
    x: 1861.5,
    y: 816.5
  },
  {
    x: 1861.5,
    y: 801.5
  },
  {
    x: 1861.5,
    y: 786.5
  },
  {
    x: 1861.5,
    y: 771.5
  },
  {
    x: 1861.5,
    y: 291.5
  },
  {
    x: 1861.5,
    y: 276.5
  },
  {
    x: 1861.5,
    y: 111.5
  },
  {
    x: 1861.5,
    y: 96.5
  },
  {
    x: 1876.5,
    y: 2046.5
  },
  {
    x: 1876.5,
    y: 2031.5
  },
  {
    x: 1876.5,
    y: 2016.5
  },
  {
    x: 1876.5,
    y: 2001.5
  },
  {
    x: 1876.5,
    y: 1986.5
  },
  {
    x: 1876.5,
    y: 1971.5
  },
  {
    x: 1876.5,
    y: 1956.5
  },
  {
    x: 1876.5,
    y: 1941.5
  },
  {
    x: 1876.5,
    y: 1926.5
  },
  {
    x: 1876.5,
    y: 1911.5
  },
  {
    x: 1876.5,
    y: 1896.5
  },
  {
    x: 1876.5,
    y: 1881.5
  },
  {
    x: 1876.5,
    y: 891.5
  },
  {
    x: 1876.5,
    y: 876.5
  },
  {
    x: 1876.5,
    y: 861.5
  },
  {
    x: 1876.5,
    y: 846.5
  },
  {
    x: 1876.5,
    y: 831.5
  },
  {
    x: 1876.5,
    y: 816.5
  },
  {
    x: 1876.5,
    y: 801.5
  },
  {
    x: 1876.5,
    y: 786.5
  },
  {
    x: 1876.5,
    y: 771.5
  },
  {
    x: 1876.5,
    y: 756.5
  },
  {
    x: 1876.5,
    y: 291.5
  },
  {
    x: 1876.5,
    y: 276.5
  },
  {
    x: 1876.5,
    y: 96.5
  },
  {
    x: 1891.5,
    y: 2046.5
  },
  {
    x: 1891.5,
    y: 2031.5
  },
  {
    x: 1891.5,
    y: 2016.5
  },
  {
    x: 1891.5,
    y: 2001.5
  },
  {
    x: 1891.5,
    y: 1986.5
  },
  {
    x: 1891.5,
    y: 1971.5
  },
  {
    x: 1891.5,
    y: 1956.5
  },
  {
    x: 1891.5,
    y: 1941.5
  },
  {
    x: 1891.5,
    y: 1926.5
  },
  {
    x: 1891.5,
    y: 1911.5
  },
  {
    x: 1891.5,
    y: 1896.5
  },
  {
    x: 1891.5,
    y: 1881.5
  },
  {
    x: 1891.5,
    y: 1866.5
  },
  {
    x: 1891.5,
    y: 1851.5
  },
  {
    x: 1891.5,
    y: 906.5
  },
  {
    x: 1891.5,
    y: 891.5
  },
  {
    x: 1891.5,
    y: 876.5
  },
  {
    x: 1891.5,
    y: 861.5
  },
  {
    x: 1891.5,
    y: 846.5
  },
  {
    x: 1891.5,
    y: 831.5
  },
  {
    x: 1891.5,
    y: 816.5
  },
  {
    x: 1891.5,
    y: 801.5
  },
  {
    x: 1891.5,
    y: 786.5
  },
  {
    x: 1891.5,
    y: 771.5
  },
  {
    x: 1891.5,
    y: 756.5
  },
  {
    x: 1891.5,
    y: 741.5
  },
  {
    x: 1891.5,
    y: 726.5
  },
  {
    x: 1891.5,
    y: 696.5
  },
  {
    x: 1891.5,
    y: 276.5
  },
  {
    x: 1891.5,
    y: 96.5
  },
  {
    x: 1906.5,
    y: 2046.5
  },
  {
    x: 1906.5,
    y: 2031.5
  },
  {
    x: 1906.5,
    y: 2016.5
  },
  {
    x: 1906.5,
    y: 2001.5
  },
  {
    x: 1906.5,
    y: 1986.5
  },
  {
    x: 1906.5,
    y: 1971.5
  },
  {
    x: 1906.5,
    y: 1956.5
  },
  {
    x: 1906.5,
    y: 1941.5
  },
  {
    x: 1906.5,
    y: 1926.5
  },
  {
    x: 1906.5,
    y: 1911.5
  },
  {
    x: 1906.5,
    y: 1896.5
  },
  {
    x: 1906.5,
    y: 1881.5
  },
  {
    x: 1906.5,
    y: 1866.5
  },
  {
    x: 1906.5,
    y: 1851.5
  },
  {
    x: 1906.5,
    y: 1836.5
  },
  {
    x: 1906.5,
    y: 936.5
  },
  {
    x: 1906.5,
    y: 921.5
  },
  {
    x: 1906.5,
    y: 906.5
  },
  {
    x: 1906.5,
    y: 891.5
  },
  {
    x: 1906.5,
    y: 876.5
  },
  {
    x: 1906.5,
    y: 861.5
  },
  {
    x: 1906.5,
    y: 846.5
  },
  {
    x: 1906.5,
    y: 831.5
  },
  {
    x: 1906.5,
    y: 816.5
  },
  {
    x: 1906.5,
    y: 801.5
  },
  {
    x: 1906.5,
    y: 786.5
  },
  {
    x: 1906.5,
    y: 771.5
  },
  {
    x: 1906.5,
    y: 756.5
  },
  {
    x: 1906.5,
    y: 741.5
  },
  {
    x: 1906.5,
    y: 726.5
  },
  {
    x: 1906.5,
    y: 711.5
  },
  {
    x: 1906.5,
    y: 96.5
  },
  {
    x: 1921.5,
    y: 2046.5
  },
  {
    x: 1921.5,
    y: 2031.5
  },
  {
    x: 1921.5,
    y: 2016.5
  },
  {
    x: 1921.5,
    y: 2001.5
  },
  {
    x: 1921.5,
    y: 1986.5
  },
  {
    x: 1921.5,
    y: 1971.5
  },
  {
    x: 1921.5,
    y: 1956.5
  },
  {
    x: 1921.5,
    y: 1941.5
  },
  {
    x: 1921.5,
    y: 1926.5
  },
  {
    x: 1921.5,
    y: 1911.5
  },
  {
    x: 1921.5,
    y: 1896.5
  },
  {
    x: 1921.5,
    y: 1881.5
  },
  {
    x: 1921.5,
    y: 1866.5
  },
  {
    x: 1921.5,
    y: 1851.5
  },
  {
    x: 1921.5,
    y: 936.5
  },
  {
    x: 1921.5,
    y: 921.5
  },
  {
    x: 1921.5,
    y: 906.5
  },
  {
    x: 1921.5,
    y: 891.5
  },
  {
    x: 1921.5,
    y: 876.5
  },
  {
    x: 1921.5,
    y: 861.5
  },
  {
    x: 1921.5,
    y: 846.5
  },
  {
    x: 1921.5,
    y: 831.5
  },
  {
    x: 1921.5,
    y: 816.5
  },
  {
    x: 1921.5,
    y: 801.5
  },
  {
    x: 1921.5,
    y: 786.5
  },
  {
    x: 1921.5,
    y: 771.5
  },
  {
    x: 1921.5,
    y: 756.5
  },
  {
    x: 1921.5,
    y: 741.5
  },
  {
    x: 1921.5,
    y: 726.5
  },
  {
    x: 1921.5,
    y: 711.5
  },
  {
    x: 1921.5,
    y: 696.5
  },
  {
    x: 1936.5,
    y: 2046.5
  },
  {
    x: 1936.5,
    y: 2031.5
  },
  {
    x: 1936.5,
    y: 2016.5
  },
  {
    x: 1936.5,
    y: 2001.5
  },
  {
    x: 1936.5,
    y: 1986.5
  },
  {
    x: 1936.5,
    y: 1971.5
  },
  {
    x: 1936.5,
    y: 1956.5
  },
  {
    x: 1936.5,
    y: 1941.5
  },
  {
    x: 1936.5,
    y: 1926.5
  },
  {
    x: 1936.5,
    y: 1911.5
  },
  {
    x: 1936.5,
    y: 1896.5
  },
  {
    x: 1936.5,
    y: 1881.5
  },
  {
    x: 1936.5,
    y: 1866.5
  },
  {
    x: 1936.5,
    y: 1851.5
  },
  {
    x: 1936.5,
    y: 1836.5
  },
  {
    x: 1936.5,
    y: 951.5
  },
  {
    x: 1936.5,
    y: 936.5
  },
  {
    x: 1936.5,
    y: 921.5
  },
  {
    x: 1936.5,
    y: 906.5
  },
  {
    x: 1936.5,
    y: 891.5
  },
  {
    x: 1936.5,
    y: 876.5
  },
  {
    x: 1936.5,
    y: 861.5
  },
  {
    x: 1936.5,
    y: 846.5
  },
  {
    x: 1936.5,
    y: 831.5
  },
  {
    x: 1936.5,
    y: 816.5
  },
  {
    x: 1936.5,
    y: 801.5
  },
  {
    x: 1936.5,
    y: 786.5
  },
  {
    x: 1936.5,
    y: 771.5
  },
  {
    x: 1936.5,
    y: 756.5
  },
  {
    x: 1936.5,
    y: 741.5
  },
  {
    x: 1936.5,
    y: 726.5
  },
  {
    x: 1936.5,
    y: 711.5
  },
  {
    x: 1936.5,
    y: 696.5
  },
  {
    x: 1936.5,
    y: 681.5
  },
  {
    x: 1936.5,
    y: 666.5
  },
  {
    x: 1936.5,
    y: 426.5
  },
  {
    x: 1936.5,
    y: 411.5
  },
  {
    x: 1951.5,
    y: 2046.5
  },
  {
    x: 1951.5,
    y: 2031.5
  },
  {
    x: 1951.5,
    y: 2016.5
  },
  {
    x: 1951.5,
    y: 2001.5
  },
  {
    x: 1951.5,
    y: 1986.5
  },
  {
    x: 1951.5,
    y: 1971.5
  },
  {
    x: 1951.5,
    y: 1956.5
  },
  {
    x: 1951.5,
    y: 1941.5
  },
  {
    x: 1951.5,
    y: 1926.5
  },
  {
    x: 1951.5,
    y: 1911.5
  },
  {
    x: 1951.5,
    y: 1896.5
  },
  {
    x: 1951.5,
    y: 1881.5
  },
  {
    x: 1951.5,
    y: 1866.5
  },
  {
    x: 1951.5,
    y: 1851.5
  },
  {
    x: 1951.5,
    y: 966.5
  },
  {
    x: 1951.5,
    y: 951.5
  },
  {
    x: 1951.5,
    y: 936.5
  },
  {
    x: 1951.5,
    y: 921.5
  },
  {
    x: 1951.5,
    y: 906.5
  },
  {
    x: 1951.5,
    y: 891.5
  },
  {
    x: 1951.5,
    y: 876.5
  },
  {
    x: 1951.5,
    y: 861.5
  },
  {
    x: 1951.5,
    y: 846.5
  },
  {
    x: 1951.5,
    y: 831.5
  },
  {
    x: 1951.5,
    y: 816.5
  },
  {
    x: 1951.5,
    y: 801.5
  },
  {
    x: 1951.5,
    y: 786.5
  },
  {
    x: 1951.5,
    y: 771.5
  },
  {
    x: 1951.5,
    y: 756.5
  },
  {
    x: 1951.5,
    y: 741.5
  },
  {
    x: 1951.5,
    y: 726.5
  },
  {
    x: 1951.5,
    y: 711.5
  },
  {
    x: 1951.5,
    y: 696.5
  },
  {
    x: 1951.5,
    y: 681.5
  },
  {
    x: 1951.5,
    y: 666.5
  },
  {
    x: 1951.5,
    y: 651.5
  },
  {
    x: 1951.5,
    y: 591.5
  },
  {
    x: 1951.5,
    y: 576.5
  },
  {
    x: 1951.5,
    y: 561.5
  },
  {
    x: 1951.5,
    y: 546.5
  },
  {
    x: 1951.5,
    y: 531.5
  },
  {
    x: 1951.5,
    y: 426.5
  },
  {
    x: 1951.5,
    y: 411.5
  },
  {
    x: 1966.5,
    y: 2046.5
  },
  {
    x: 1966.5,
    y: 2031.5
  },
  {
    x: 1966.5,
    y: 2016.5
  },
  {
    x: 1966.5,
    y: 2001.5
  },
  {
    x: 1966.5,
    y: 1986.5
  },
  {
    x: 1966.5,
    y: 1971.5
  },
  {
    x: 1966.5,
    y: 1956.5
  },
  {
    x: 1966.5,
    y: 1941.5
  },
  {
    x: 1966.5,
    y: 1926.5
  },
  {
    x: 1966.5,
    y: 1911.5
  },
  {
    x: 1966.5,
    y: 1896.5
  },
  {
    x: 1966.5,
    y: 1881.5
  },
  {
    x: 1966.5,
    y: 1866.5
  },
  {
    x: 1966.5,
    y: 1851.5
  },
  {
    x: 1966.5,
    y: 1836.5
  },
  {
    x: 1966.5,
    y: 966.5
  },
  {
    x: 1966.5,
    y: 951.5
  },
  {
    x: 1966.5,
    y: 936.5
  },
  {
    x: 1966.5,
    y: 921.5
  },
  {
    x: 1966.5,
    y: 906.5
  },
  {
    x: 1966.5,
    y: 891.5
  },
  {
    x: 1966.5,
    y: 876.5
  },
  {
    x: 1966.5,
    y: 861.5
  },
  {
    x: 1966.5,
    y: 846.5
  },
  {
    x: 1966.5,
    y: 831.5
  },
  {
    x: 1966.5,
    y: 816.5
  },
  {
    x: 1966.5,
    y: 801.5
  },
  {
    x: 1966.5,
    y: 786.5
  },
  {
    x: 1966.5,
    y: 771.5
  },
  {
    x: 1966.5,
    y: 756.5
  },
  {
    x: 1966.5,
    y: 741.5
  },
  {
    x: 1966.5,
    y: 726.5
  },
  {
    x: 1966.5,
    y: 711.5
  },
  {
    x: 1966.5,
    y: 696.5
  },
  {
    x: 1966.5,
    y: 681.5
  },
  {
    x: 1966.5,
    y: 666.5
  },
  {
    x: 1966.5,
    y: 651.5
  },
  {
    x: 1966.5,
    y: 591.5
  },
  {
    x: 1966.5,
    y: 576.5
  },
  {
    x: 1966.5,
    y: 561.5
  },
  {
    x: 1966.5,
    y: 546.5
  },
  {
    x: 1966.5,
    y: 531.5
  },
  {
    x: 1966.5,
    y: 426.5
  },
  {
    x: 1966.5,
    y: 411.5
  },
  {
    x: 1966.5,
    y: 396.5
  },
  {
    x: 1966.5,
    y: 366.5
  },
  {
    x: 1981.5,
    y: 2046.5
  },
  {
    x: 1981.5,
    y: 2031.5
  },
  {
    x: 1981.5,
    y: 2016.5
  },
  {
    x: 1981.5,
    y: 2001.5
  },
  {
    x: 1981.5,
    y: 1986.5
  },
  {
    x: 1981.5,
    y: 1971.5
  },
  {
    x: 1981.5,
    y: 1956.5
  },
  {
    x: 1981.5,
    y: 1941.5
  },
  {
    x: 1981.5,
    y: 1926.5
  },
  {
    x: 1981.5,
    y: 1911.5
  },
  {
    x: 1981.5,
    y: 1896.5
  },
  {
    x: 1981.5,
    y: 1881.5
  },
  {
    x: 1981.5,
    y: 1866.5
  },
  {
    x: 1981.5,
    y: 1851.5
  },
  {
    x: 1981.5,
    y: 1836.5
  },
  {
    x: 1981.5,
    y: 966.5
  },
  {
    x: 1981.5,
    y: 951.5
  },
  {
    x: 1981.5,
    y: 936.5
  },
  {
    x: 1981.5,
    y: 921.5
  },
  {
    x: 1981.5,
    y: 906.5
  },
  {
    x: 1981.5,
    y: 891.5
  },
  {
    x: 1981.5,
    y: 876.5
  },
  {
    x: 1981.5,
    y: 861.5
  },
  {
    x: 1981.5,
    y: 846.5
  },
  {
    x: 1981.5,
    y: 831.5
  },
  {
    x: 1981.5,
    y: 816.5
  },
  {
    x: 1981.5,
    y: 801.5
  },
  {
    x: 1981.5,
    y: 786.5
  },
  {
    x: 1981.5,
    y: 771.5
  },
  {
    x: 1981.5,
    y: 756.5
  },
  {
    x: 1981.5,
    y: 741.5
  },
  {
    x: 1981.5,
    y: 726.5
  },
  {
    x: 1981.5,
    y: 711.5
  },
  {
    x: 1981.5,
    y: 696.5
  },
  {
    x: 1981.5,
    y: 681.5
  },
  {
    x: 1981.5,
    y: 666.5
  },
  {
    x: 1981.5,
    y: 651.5
  },
  {
    x: 1981.5,
    y: 636.5
  },
  {
    x: 1981.5,
    y: 621.5
  },
  {
    x: 1981.5,
    y: 606.5
  },
  {
    x: 1981.5,
    y: 591.5
  },
  {
    x: 1981.5,
    y: 576.5
  },
  {
    x: 1981.5,
    y: 561.5
  },
  {
    x: 1981.5,
    y: 546.5
  },
  {
    x: 1981.5,
    y: 531.5
  },
  {
    x: 1981.5,
    y: 471.5
  },
  {
    x: 1981.5,
    y: 456.5
  },
  {
    x: 1981.5,
    y: 396.5
  },
  {
    x: 1981.5,
    y: 381.5
  },
  {
    x: 1981.5,
    y: 366.5
  },
  {
    x: 1996.5,
    y: 2046.5
  },
  {
    x: 1996.5,
    y: 2031.5
  },
  {
    x: 1996.5,
    y: 2016.5
  },
  {
    x: 1996.5,
    y: 2001.5
  },
  {
    x: 1996.5,
    y: 1986.5
  },
  {
    x: 1996.5,
    y: 1971.5
  },
  {
    x: 1996.5,
    y: 1956.5
  },
  {
    x: 1996.5,
    y: 1941.5
  },
  {
    x: 1996.5,
    y: 1926.5
  },
  {
    x: 1996.5,
    y: 1911.5
  },
  {
    x: 1996.5,
    y: 1896.5
  },
  {
    x: 1996.5,
    y: 1881.5
  },
  {
    x: 1996.5,
    y: 1866.5
  },
  {
    x: 1996.5,
    y: 1851.5
  },
  {
    x: 1996.5,
    y: 1836.5
  },
  {
    x: 1996.5,
    y: 951.5
  },
  {
    x: 1996.5,
    y: 936.5
  },
  {
    x: 1996.5,
    y: 921.5
  },
  {
    x: 1996.5,
    y: 906.5
  },
  {
    x: 1996.5,
    y: 891.5
  },
  {
    x: 1996.5,
    y: 876.5
  },
  {
    x: 1996.5,
    y: 861.5
  },
  {
    x: 1996.5,
    y: 846.5
  },
  {
    x: 1996.5,
    y: 831.5
  },
  {
    x: 1996.5,
    y: 816.5
  },
  {
    x: 1996.5,
    y: 801.5
  },
  {
    x: 1996.5,
    y: 786.5
  },
  {
    x: 1996.5,
    y: 771.5
  },
  {
    x: 1996.5,
    y: 756.5
  },
  {
    x: 1996.5,
    y: 741.5
  },
  {
    x: 1996.5,
    y: 726.5
  },
  {
    x: 1996.5,
    y: 711.5
  },
  {
    x: 1996.5,
    y: 696.5
  },
  {
    x: 1996.5,
    y: 681.5
  },
  {
    x: 1996.5,
    y: 666.5
  },
  {
    x: 1996.5,
    y: 651.5
  },
  {
    x: 1996.5,
    y: 636.5
  },
  {
    x: 1996.5,
    y: 621.5
  },
  {
    x: 1996.5,
    y: 606.5
  },
  {
    x: 1996.5,
    y: 591.5
  },
  {
    x: 1996.5,
    y: 576.5
  },
  {
    x: 1996.5,
    y: 561.5
  },
  {
    x: 1996.5,
    y: 546.5
  },
  {
    x: 1996.5,
    y: 531.5
  },
  {
    x: 1996.5,
    y: 471.5
  },
  {
    x: 1996.5,
    y: 441.5
  },
  {
    x: 1996.5,
    y: 426.5
  },
  {
    x: 1996.5,
    y: 411.5
  },
  {
    x: 1996.5,
    y: 396.5
  },
  {
    x: 1996.5,
    y: 381.5
  },
  {
    x: 1996.5,
    y: 366.5
  },
  {
    x: 2011.5,
    y: 2046.5
  },
  {
    x: 2011.5,
    y: 2031.5
  },
  {
    x: 2011.5,
    y: 2016.5
  },
  {
    x: 2011.5,
    y: 2001.5
  },
  {
    x: 2011.5,
    y: 1986.5
  },
  {
    x: 2011.5,
    y: 1971.5
  },
  {
    x: 2011.5,
    y: 1956.5
  },
  {
    x: 2011.5,
    y: 1941.5
  },
  {
    x: 2011.5,
    y: 1926.5
  },
  {
    x: 2011.5,
    y: 1911.5
  },
  {
    x: 2011.5,
    y: 1896.5
  },
  {
    x: 2011.5,
    y: 1881.5
  },
  {
    x: 2011.5,
    y: 1866.5
  },
  {
    x: 2011.5,
    y: 1851.5
  },
  {
    x: 2011.5,
    y: 1836.5
  },
  {
    x: 2011.5,
    y: 966.5
  },
  {
    x: 2011.5,
    y: 951.5
  },
  {
    x: 2011.5,
    y: 936.5
  },
  {
    x: 2011.5,
    y: 921.5
  },
  {
    x: 2011.5,
    y: 906.5
  },
  {
    x: 2011.5,
    y: 891.5
  },
  {
    x: 2011.5,
    y: 876.5
  },
  {
    x: 2011.5,
    y: 861.5
  },
  {
    x: 2011.5,
    y: 846.5
  },
  {
    x: 2011.5,
    y: 831.5
  },
  {
    x: 2011.5,
    y: 816.5
  },
  {
    x: 2011.5,
    y: 801.5
  },
  {
    x: 2011.5,
    y: 786.5
  },
  {
    x: 2011.5,
    y: 771.5
  },
  {
    x: 2011.5,
    y: 756.5
  },
  {
    x: 2011.5,
    y: 741.5
  },
  {
    x: 2011.5,
    y: 726.5
  },
  {
    x: 2011.5,
    y: 711.5
  },
  {
    x: 2011.5,
    y: 696.5
  },
  {
    x: 2011.5,
    y: 681.5
  },
  {
    x: 2011.5,
    y: 666.5
  },
  {
    x: 2011.5,
    y: 651.5
  },
  {
    x: 2011.5,
    y: 636.5
  },
  {
    x: 2011.5,
    y: 621.5
  },
  {
    x: 2011.5,
    y: 606.5
  },
  {
    x: 2011.5,
    y: 591.5
  },
  {
    x: 2011.5,
    y: 576.5
  },
  {
    x: 2011.5,
    y: 561.5
  },
  {
    x: 2011.5,
    y: 546.5
  },
  {
    x: 2011.5,
    y: 531.5
  },
  {
    x: 2011.5,
    y: 471.5
  },
  {
    x: 2011.5,
    y: 441.5
  },
  {
    x: 2011.5,
    y: 426.5
  },
  {
    x: 2011.5,
    y: 411.5
  },
  {
    x: 2011.5,
    y: 396.5
  },
  {
    x: 2011.5,
    y: 381.5
  },
  {
    x: 2011.5,
    y: 366.5
  },
  {
    x: 2011.5,
    y: 351.5
  },
  {
    x: 2026.5,
    y: 2046.5
  },
  {
    x: 2026.5,
    y: 2031.5
  },
  {
    x: 2026.5,
    y: 2016.5
  },
  {
    x: 2026.5,
    y: 2001.5
  },
  {
    x: 2026.5,
    y: 1986.5
  },
  {
    x: 2026.5,
    y: 1971.5
  },
  {
    x: 2026.5,
    y: 1956.5
  },
  {
    x: 2026.5,
    y: 1941.5
  },
  {
    x: 2026.5,
    y: 1926.5
  },
  {
    x: 2026.5,
    y: 1911.5
  },
  {
    x: 2026.5,
    y: 1896.5
  },
  {
    x: 2026.5,
    y: 1881.5
  },
  {
    x: 2026.5,
    y: 1866.5
  },
  {
    x: 2026.5,
    y: 1851.5
  },
  {
    x: 2026.5,
    y: 1836.5
  },
  {
    x: 2026.5,
    y: 966.5
  },
  {
    x: 2026.5,
    y: 951.5
  },
  {
    x: 2026.5,
    y: 936.5
  },
  {
    x: 2026.5,
    y: 921.5
  },
  {
    x: 2026.5,
    y: 906.5
  },
  {
    x: 2026.5,
    y: 891.5
  },
  {
    x: 2026.5,
    y: 876.5
  },
  {
    x: 2026.5,
    y: 861.5
  },
  {
    x: 2026.5,
    y: 846.5
  },
  {
    x: 2026.5,
    y: 831.5
  },
  {
    x: 2026.5,
    y: 816.5
  },
  {
    x: 2026.5,
    y: 801.5
  },
  {
    x: 2026.5,
    y: 786.5
  },
  {
    x: 2026.5,
    y: 771.5
  },
  {
    x: 2026.5,
    y: 756.5
  },
  {
    x: 2026.5,
    y: 741.5
  },
  {
    x: 2026.5,
    y: 726.5
  },
  {
    x: 2026.5,
    y: 711.5
  },
  {
    x: 2026.5,
    y: 696.5
  },
  {
    x: 2026.5,
    y: 681.5
  },
  {
    x: 2026.5,
    y: 666.5
  },
  {
    x: 2026.5,
    y: 651.5
  },
  {
    x: 2026.5,
    y: 636.5
  },
  {
    x: 2026.5,
    y: 591.5
  },
  {
    x: 2026.5,
    y: 576.5
  },
  {
    x: 2026.5,
    y: 561.5
  },
  {
    x: 2026.5,
    y: 546.5
  },
  {
    x: 2026.5,
    y: 531.5
  },
  {
    x: 2026.5,
    y: 486.5
  },
  {
    x: 2026.5,
    y: 471.5
  },
  {
    x: 2026.5,
    y: 456.5
  },
  {
    x: 2026.5,
    y: 441.5
  },
  {
    x: 2026.5,
    y: 426.5
  },
  {
    x: 2026.5,
    y: 411.5
  },
  {
    x: 2026.5,
    y: 396.5
  },
  {
    x: 2026.5,
    y: 366.5
  },
  {
    x: 2041.5,
    y: 2046.5
  },
  {
    x: 2041.5,
    y: 2031.5
  },
  {
    x: 2041.5,
    y: 2016.5
  },
  {
    x: 2041.5,
    y: 2001.5
  },
  {
    x: 2041.5,
    y: 1986.5
  },
  {
    x: 2041.5,
    y: 1971.5
  },
  {
    x: 2041.5,
    y: 1956.5
  },
  {
    x: 2041.5,
    y: 1941.5
  },
  {
    x: 2041.5,
    y: 1926.5
  },
  {
    x: 2041.5,
    y: 1911.5
  },
  {
    x: 2041.5,
    y: 1896.5
  },
  {
    x: 2041.5,
    y: 1881.5
  },
  {
    x: 2041.5,
    y: 1866.5
  },
  {
    x: 2041.5,
    y: 1851.5
  },
  {
    x: 2041.5,
    y: 951.5
  },
  {
    x: 2041.5,
    y: 936.5
  },
  {
    x: 2041.5,
    y: 921.5
  },
  {
    x: 2041.5,
    y: 906.5
  },
  {
    x: 2041.5,
    y: 891.5
  },
  {
    x: 2041.5,
    y: 876.5
  },
  {
    x: 2041.5,
    y: 861.5
  },
  {
    x: 2041.5,
    y: 846.5
  },
  {
    x: 2041.5,
    y: 831.5
  },
  {
    x: 2041.5,
    y: 816.5
  },
  {
    x: 2041.5,
    y: 801.5
  },
  {
    x: 2041.5,
    y: 786.5
  },
  {
    x: 2041.5,
    y: 771.5
  },
  {
    x: 2041.5,
    y: 756.5
  },
  {
    x: 2041.5,
    y: 741.5
  },
  {
    x: 2041.5,
    y: 726.5
  },
  {
    x: 2041.5,
    y: 711.5
  },
  {
    x: 2041.5,
    y: 696.5
  },
  {
    x: 2041.5,
    y: 681.5
  },
  {
    x: 2041.5,
    y: 666.5
  },
  {
    x: 2041.5,
    y: 651.5
  },
  {
    x: 2041.5,
    y: 636.5
  },
  {
    x: 2041.5,
    y: 621.5
  },
  {
    x: 2041.5,
    y: 591.5
  },
  {
    x: 2041.5,
    y: 576.5
  },
  {
    x: 2041.5,
    y: 561.5
  },
  {
    x: 2041.5,
    y: 546.5
  },
  {
    x: 2041.5,
    y: 531.5
  },
  {
    x: 2041.5,
    y: 516.5
  },
  {
    x: 2041.5,
    y: 501.5
  },
  {
    x: 2041.5,
    y: 486.5
  },
  {
    x: 2041.5,
    y: 471.5
  },
  {
    x: 2041.5,
    y: 441.5
  },
  {
    x: 2041.5,
    y: 426.5
  },
  {
    x: 2041.5,
    y: 411.5
  },
  {
    x: 2056.5,
    y: 2046.5
  },
  {
    x: 2056.5,
    y: 2031.5
  },
  {
    x: 2056.5,
    y: 2016.5
  },
  {
    x: 2056.5,
    y: 2001.5
  },
  {
    x: 2056.5,
    y: 1986.5
  },
  {
    x: 2056.5,
    y: 1971.5
  },
  {
    x: 2056.5,
    y: 1956.5
  },
  {
    x: 2056.5,
    y: 1941.5
  },
  {
    x: 2056.5,
    y: 1926.5
  },
  {
    x: 2056.5,
    y: 1911.5
  },
  {
    x: 2056.5,
    y: 1896.5
  },
  {
    x: 2056.5,
    y: 1881.5
  },
  {
    x: 2056.5,
    y: 1866.5
  },
  {
    x: 2056.5,
    y: 1851.5
  },
  {
    x: 2056.5,
    y: 1836.5
  },
  {
    x: 2056.5,
    y: 951.5
  },
  {
    x: 2056.5,
    y: 936.5
  },
  {
    x: 2056.5,
    y: 921.5
  },
  {
    x: 2056.5,
    y: 906.5
  },
  {
    x: 2056.5,
    y: 891.5
  },
  {
    x: 2056.5,
    y: 876.5
  },
  {
    x: 2056.5,
    y: 861.5
  },
  {
    x: 2056.5,
    y: 846.5
  },
  {
    x: 2056.5,
    y: 831.5
  },
  {
    x: 2056.5,
    y: 816.5
  },
  {
    x: 2056.5,
    y: 801.5
  },
  {
    x: 2056.5,
    y: 786.5
  },
  {
    x: 2056.5,
    y: 771.5
  },
  {
    x: 2056.5,
    y: 756.5
  },
  {
    x: 2056.5,
    y: 741.5
  },
  {
    x: 2056.5,
    y: 726.5
  },
  {
    x: 2056.5,
    y: 711.5
  },
  {
    x: 2056.5,
    y: 696.5
  },
  {
    x: 2056.5,
    y: 681.5
  },
  {
    x: 2056.5,
    y: 666.5
  },
  {
    x: 2056.5,
    y: 651.5
  },
  {
    x: 2056.5,
    y: 636.5
  },
  {
    x: 2056.5,
    y: 621.5
  },
  {
    x: 2056.5,
    y: 546.5
  },
  {
    x: 2056.5,
    y: 531.5
  },
  {
    x: 2056.5,
    y: 516.5
  },
  {
    x: 2056.5,
    y: 501.5
  },
  {
    x: 2056.5,
    y: 486.5
  },
  {
    x: 2056.5,
    y: 471.5
  },
  {
    x: 2056.5,
    y: 456.5
  },
  {
    x: 2056.5,
    y: 441.5
  },
  {
    x: 2056.5,
    y: 426.5
  },
  {
    x: 2071.5,
    y: 2046.5
  },
  {
    x: 2071.5,
    y: 2031.5
  },
  {
    x: 2071.5,
    y: 2016.5
  },
  {
    x: 2071.5,
    y: 2001.5
  },
  {
    x: 2071.5,
    y: 1986.5
  },
  {
    x: 2071.5,
    y: 1971.5
  },
  {
    x: 2071.5,
    y: 1956.5
  },
  {
    x: 2071.5,
    y: 1941.5
  },
  {
    x: 2071.5,
    y: 1926.5
  },
  {
    x: 2071.5,
    y: 1911.5
  },
  {
    x: 2071.5,
    y: 1896.5
  },
  {
    x: 2071.5,
    y: 1881.5
  },
  {
    x: 2071.5,
    y: 1866.5
  },
  {
    x: 2071.5,
    y: 1851.5
  },
  {
    x: 2071.5,
    y: 1836.5
  },
  {
    x: 2071.5,
    y: 951.5
  },
  {
    x: 2071.5,
    y: 936.5
  },
  {
    x: 2071.5,
    y: 921.5
  },
  {
    x: 2071.5,
    y: 906.5
  },
  {
    x: 2071.5,
    y: 891.5
  },
  {
    x: 2071.5,
    y: 876.5
  },
  {
    x: 2071.5,
    y: 861.5
  },
  {
    x: 2071.5,
    y: 846.5
  },
  {
    x: 2071.5,
    y: 831.5
  },
  {
    x: 2071.5,
    y: 816.5
  },
  {
    x: 2071.5,
    y: 801.5
  },
  {
    x: 2071.5,
    y: 786.5
  },
  {
    x: 2071.5,
    y: 771.5
  },
  {
    x: 2071.5,
    y: 756.5
  },
  {
    x: 2071.5,
    y: 741.5
  },
  {
    x: 2071.5,
    y: 726.5
  },
  {
    x: 2071.5,
    y: 711.5
  },
  {
    x: 2071.5,
    y: 696.5
  },
  {
    x: 2071.5,
    y: 681.5
  },
  {
    x: 2071.5,
    y: 666.5
  },
  {
    x: 2071.5,
    y: 651.5
  },
  {
    x: 2071.5,
    y: 636.5
  },
  {
    x: 2071.5,
    y: 621.5
  },
  {
    x: 2071.5,
    y: 546.5
  },
  {
    x: 2071.5,
    y: 531.5
  },
  {
    x: 2071.5,
    y: 516.5
  },
  {
    x: 2071.5,
    y: 501.5
  },
  {
    x: 2071.5,
    y: 486.5
  },
  {
    x: 2071.5,
    y: 471.5
  },
  {
    x: 2071.5,
    y: 456.5
  },
  {
    x: 2086.5,
    y: 2046.5
  },
  {
    x: 2086.5,
    y: 2031.5
  },
  {
    x: 2086.5,
    y: 2016.5
  },
  {
    x: 2086.5,
    y: 2001.5
  },
  {
    x: 2086.5,
    y: 1986.5
  },
  {
    x: 2086.5,
    y: 1971.5
  },
  {
    x: 2086.5,
    y: 1956.5
  },
  {
    x: 2086.5,
    y: 1941.5
  },
  {
    x: 2086.5,
    y: 1926.5
  },
  {
    x: 2086.5,
    y: 1911.5
  },
  {
    x: 2086.5,
    y: 1896.5
  },
  {
    x: 2086.5,
    y: 1881.5
  },
  {
    x: 2086.5,
    y: 1866.5
  },
  {
    x: 2086.5,
    y: 1851.5
  },
  {
    x: 2086.5,
    y: 1836.5
  },
  {
    x: 2086.5,
    y: 951.5
  },
  {
    x: 2086.5,
    y: 936.5
  },
  {
    x: 2086.5,
    y: 921.5
  },
  {
    x: 2086.5,
    y: 906.5
  },
  {
    x: 2086.5,
    y: 891.5
  },
  {
    x: 2086.5,
    y: 876.5
  },
  {
    x: 2086.5,
    y: 861.5
  },
  {
    x: 2086.5,
    y: 846.5
  },
  {
    x: 2086.5,
    y: 831.5
  },
  {
    x: 2086.5,
    y: 816.5
  },
  {
    x: 2086.5,
    y: 801.5
  },
  {
    x: 2086.5,
    y: 786.5
  },
  {
    x: 2086.5,
    y: 771.5
  },
  {
    x: 2086.5,
    y: 756.5
  },
  {
    x: 2086.5,
    y: 741.5
  },
  {
    x: 2086.5,
    y: 726.5
  },
  {
    x: 2086.5,
    y: 711.5
  },
  {
    x: 2086.5,
    y: 696.5
  },
  {
    x: 2086.5,
    y: 681.5
  },
  {
    x: 2086.5,
    y: 666.5
  },
  {
    x: 2086.5,
    y: 651.5
  },
  {
    x: 2086.5,
    y: 636.5
  },
  {
    x: 2086.5,
    y: 621.5
  },
  {
    x: 2086.5,
    y: 606.5
  },
  {
    x: 2086.5,
    y: 576.5
  },
  {
    x: 2086.5,
    y: 531.5
  },
  {
    x: 2086.5,
    y: 516.5
  },
  {
    x: 2086.5,
    y: 501.5
  },
  {
    x: 2086.5,
    y: 486.5
  },
  {
    x: 2086.5,
    y: 471.5
  },
  {
    x: 2086.5,
    y: 456.5
  },
  {
    x: 2086.5,
    y: 441.5
  },
  {
    x: 2101.5,
    y: 2046.5
  },
  {
    x: 2101.5,
    y: 2031.5
  },
  {
    x: 2101.5,
    y: 2016.5
  },
  {
    x: 2101.5,
    y: 2001.5
  },
  {
    x: 2101.5,
    y: 1986.5
  },
  {
    x: 2101.5,
    y: 1971.5
  },
  {
    x: 2101.5,
    y: 1956.5
  },
  {
    x: 2101.5,
    y: 1941.5
  },
  {
    x: 2101.5,
    y: 1926.5
  },
  {
    x: 2101.5,
    y: 1911.5
  },
  {
    x: 2101.5,
    y: 1896.5
  },
  {
    x: 2101.5,
    y: 1881.5
  },
  {
    x: 2101.5,
    y: 1866.5
  },
  {
    x: 2101.5,
    y: 1851.5
  },
  {
    x: 2101.5,
    y: 1836.5
  },
  {
    x: 2101.5,
    y: 951.5
  },
  {
    x: 2101.5,
    y: 936.5
  },
  {
    x: 2101.5,
    y: 921.5
  },
  {
    x: 2101.5,
    y: 906.5
  },
  {
    x: 2101.5,
    y: 891.5
  },
  {
    x: 2101.5,
    y: 876.5
  },
  {
    x: 2101.5,
    y: 861.5
  },
  {
    x: 2101.5,
    y: 846.5
  },
  {
    x: 2101.5,
    y: 831.5
  },
  {
    x: 2101.5,
    y: 816.5
  },
  {
    x: 2101.5,
    y: 801.5
  },
  {
    x: 2101.5,
    y: 786.5
  },
  {
    x: 2101.5,
    y: 771.5
  },
  {
    x: 2101.5,
    y: 756.5
  },
  {
    x: 2101.5,
    y: 741.5
  },
  {
    x: 2101.5,
    y: 726.5
  },
  {
    x: 2101.5,
    y: 711.5
  },
  {
    x: 2101.5,
    y: 696.5
  },
  {
    x: 2101.5,
    y: 681.5
  },
  {
    x: 2101.5,
    y: 666.5
  },
  {
    x: 2101.5,
    y: 651.5
  },
  {
    x: 2101.5,
    y: 636.5
  },
  {
    x: 2101.5,
    y: 621.5
  },
  {
    x: 2101.5,
    y: 606.5
  },
  {
    x: 2101.5,
    y: 531.5
  },
  {
    x: 2101.5,
    y: 516.5
  },
  {
    x: 2101.5,
    y: 501.5
  },
  {
    x: 2101.5,
    y: 486.5
  },
  {
    x: 2101.5,
    y: 471.5
  },
  {
    x: 2101.5,
    y: 456.5
  },
  {
    x: 2101.5,
    y: 441.5
  },
  {
    x: 2101.5,
    y: 426.5
  },
  {
    x: 2116.5,
    y: 2046.5
  },
  {
    x: 2116.5,
    y: 2031.5
  },
  {
    x: 2116.5,
    y: 2016.5
  },
  {
    x: 2116.5,
    y: 2001.5
  },
  {
    x: 2116.5,
    y: 1986.5
  },
  {
    x: 2116.5,
    y: 1971.5
  },
  {
    x: 2116.5,
    y: 1956.5
  },
  {
    x: 2116.5,
    y: 1941.5
  },
  {
    x: 2116.5,
    y: 1926.5
  },
  {
    x: 2116.5,
    y: 1911.5
  },
  {
    x: 2116.5,
    y: 1896.5
  },
  {
    x: 2116.5,
    y: 1881.5
  },
  {
    x: 2116.5,
    y: 1866.5
  },
  {
    x: 2116.5,
    y: 1851.5
  },
  {
    x: 2116.5,
    y: 1836.5
  },
  {
    x: 2116.5,
    y: 966.5
  },
  {
    x: 2116.5,
    y: 951.5
  },
  {
    x: 2116.5,
    y: 936.5
  },
  {
    x: 2116.5,
    y: 921.5
  },
  {
    x: 2116.5,
    y: 906.5
  },
  {
    x: 2116.5,
    y: 891.5
  },
  {
    x: 2116.5,
    y: 876.5
  },
  {
    x: 2116.5,
    y: 861.5
  },
  {
    x: 2116.5,
    y: 846.5
  },
  {
    x: 2116.5,
    y: 831.5
  },
  {
    x: 2116.5,
    y: 816.5
  },
  {
    x: 2116.5,
    y: 801.5
  },
  {
    x: 2116.5,
    y: 786.5
  },
  {
    x: 2116.5,
    y: 771.5
  },
  {
    x: 2116.5,
    y: 756.5
  },
  {
    x: 2116.5,
    y: 741.5
  },
  {
    x: 2116.5,
    y: 726.5
  },
  {
    x: 2116.5,
    y: 711.5
  },
  {
    x: 2116.5,
    y: 696.5
  },
  {
    x: 2116.5,
    y: 681.5
  },
  {
    x: 2116.5,
    y: 666.5
  },
  {
    x: 2116.5,
    y: 651.5
  },
  {
    x: 2116.5,
    y: 636.5
  },
  {
    x: 2116.5,
    y: 621.5
  },
  {
    x: 2116.5,
    y: 606.5
  },
  {
    x: 2116.5,
    y: 531.5
  },
  {
    x: 2116.5,
    y: 516.5
  },
  {
    x: 2116.5,
    y: 501.5
  },
  {
    x: 2116.5,
    y: 486.5
  },
  {
    x: 2116.5,
    y: 471.5
  },
  {
    x: 2116.5,
    y: 456.5
  },
  {
    x: 2116.5,
    y: 441.5
  },
  {
    x: 2116.5,
    y: 426.5
  },
  {
    x: 2116.5,
    y: 351.5
  },
  {
    x: 2116.5,
    y: 336.5
  },
  {
    x: 2116.5,
    y: 321.5
  },
  {
    x: 2131.5,
    y: 2046.5
  },
  {
    x: 2131.5,
    y: 2031.5
  },
  {
    x: 2131.5,
    y: 2016.5
  },
  {
    x: 2131.5,
    y: 2001.5
  },
  {
    x: 2131.5,
    y: 1986.5
  },
  {
    x: 2131.5,
    y: 1971.5
  },
  {
    x: 2131.5,
    y: 1956.5
  },
  {
    x: 2131.5,
    y: 1941.5
  },
  {
    x: 2131.5,
    y: 1926.5
  },
  {
    x: 2131.5,
    y: 1911.5
  },
  {
    x: 2131.5,
    y: 1896.5
  },
  {
    x: 2131.5,
    y: 1881.5
  },
  {
    x: 2131.5,
    y: 1866.5
  },
  {
    x: 2131.5,
    y: 1851.5
  },
  {
    x: 2131.5,
    y: 1836.5
  },
  {
    x: 2131.5,
    y: 966.5
  },
  {
    x: 2131.5,
    y: 951.5
  },
  {
    x: 2131.5,
    y: 936.5
  },
  {
    x: 2131.5,
    y: 921.5
  },
  {
    x: 2131.5,
    y: 906.5
  },
  {
    x: 2131.5,
    y: 891.5
  },
  {
    x: 2131.5,
    y: 876.5
  },
  {
    x: 2131.5,
    y: 861.5
  },
  {
    x: 2131.5,
    y: 846.5
  },
  {
    x: 2131.5,
    y: 831.5
  },
  {
    x: 2131.5,
    y: 816.5
  },
  {
    x: 2131.5,
    y: 801.5
  },
  {
    x: 2131.5,
    y: 786.5
  },
  {
    x: 2131.5,
    y: 771.5
  },
  {
    x: 2131.5,
    y: 756.5
  },
  {
    x: 2131.5,
    y: 741.5
  },
  {
    x: 2131.5,
    y: 726.5
  },
  {
    x: 2131.5,
    y: 711.5
  },
  {
    x: 2131.5,
    y: 696.5
  },
  {
    x: 2131.5,
    y: 681.5
  },
  {
    x: 2131.5,
    y: 666.5
  },
  {
    x: 2131.5,
    y: 651.5
  },
  {
    x: 2131.5,
    y: 636.5
  },
  {
    x: 2131.5,
    y: 621.5
  },
  {
    x: 2131.5,
    y: 606.5
  },
  {
    x: 2131.5,
    y: 531.5
  },
  {
    x: 2131.5,
    y: 516.5
  },
  {
    x: 2131.5,
    y: 501.5
  },
  {
    x: 2131.5,
    y: 486.5
  },
  {
    x: 2131.5,
    y: 471.5
  },
  {
    x: 2131.5,
    y: 456.5
  },
  {
    x: 2131.5,
    y: 441.5
  },
  {
    x: 2131.5,
    y: 426.5
  },
  {
    x: 2131.5,
    y: 351.5
  },
  {
    x: 2131.5,
    y: 336.5
  },
  {
    x: 2131.5,
    y: 321.5
  },
  {
    x: 2131.5,
    y: 306.5
  },
  {
    x: 2146.5,
    y: 2046.5
  },
  {
    x: 2146.5,
    y: 2031.5
  },
  {
    x: 2146.5,
    y: 2016.5
  },
  {
    x: 2146.5,
    y: 2001.5
  },
  {
    x: 2146.5,
    y: 1986.5
  },
  {
    x: 2146.5,
    y: 1971.5
  },
  {
    x: 2146.5,
    y: 1956.5
  },
  {
    x: 2146.5,
    y: 1941.5
  },
  {
    x: 2146.5,
    y: 1926.5
  },
  {
    x: 2146.5,
    y: 1911.5
  },
  {
    x: 2146.5,
    y: 1896.5
  },
  {
    x: 2146.5,
    y: 1881.5
  },
  {
    x: 2146.5,
    y: 1866.5
  },
  {
    x: 2146.5,
    y: 1851.5
  },
  {
    x: 2146.5,
    y: 1836.5
  },
  {
    x: 2146.5,
    y: 981.5
  },
  {
    x: 2146.5,
    y: 966.5
  },
  {
    x: 2146.5,
    y: 951.5
  },
  {
    x: 2146.5,
    y: 936.5
  },
  {
    x: 2146.5,
    y: 921.5
  },
  {
    x: 2146.5,
    y: 906.5
  },
  {
    x: 2146.5,
    y: 891.5
  },
  {
    x: 2146.5,
    y: 876.5
  },
  {
    x: 2146.5,
    y: 861.5
  },
  {
    x: 2146.5,
    y: 846.5
  },
  {
    x: 2146.5,
    y: 831.5
  },
  {
    x: 2146.5,
    y: 816.5
  },
  {
    x: 2146.5,
    y: 801.5
  },
  {
    x: 2146.5,
    y: 786.5
  },
  {
    x: 2146.5,
    y: 771.5
  },
  {
    x: 2146.5,
    y: 756.5
  },
  {
    x: 2146.5,
    y: 741.5
  },
  {
    x: 2146.5,
    y: 726.5
  },
  {
    x: 2146.5,
    y: 711.5
  },
  {
    x: 2146.5,
    y: 696.5
  },
  {
    x: 2146.5,
    y: 681.5
  },
  {
    x: 2146.5,
    y: 666.5
  },
  {
    x: 2146.5,
    y: 651.5
  },
  {
    x: 2146.5,
    y: 636.5
  },
  {
    x: 2146.5,
    y: 621.5
  },
  {
    x: 2146.5,
    y: 606.5
  },
  {
    x: 2146.5,
    y: 576.5
  },
  {
    x: 2146.5,
    y: 561.5
  },
  {
    x: 2146.5,
    y: 546.5
  },
  {
    x: 2146.5,
    y: 531.5
  },
  {
    x: 2146.5,
    y: 516.5
  },
  {
    x: 2146.5,
    y: 501.5
  },
  {
    x: 2146.5,
    y: 486.5
  },
  {
    x: 2146.5,
    y: 471.5
  },
  {
    x: 2146.5,
    y: 456.5
  },
  {
    x: 2146.5,
    y: 441.5
  },
  {
    x: 2146.5,
    y: 426.5
  },
  {
    x: 2146.5,
    y: 411.5
  },
  {
    x: 2146.5,
    y: 396.5
  },
  {
    x: 2146.5,
    y: 381.5
  },
  {
    x: 2146.5,
    y: 351.5
  },
  {
    x: 2146.5,
    y: 336.5
  },
  {
    x: 2146.5,
    y: 321.5
  },
  {
    x: 2146.5,
    y: 306.5
  },
  {
    x: 2161.5,
    y: 2046.5
  },
  {
    x: 2161.5,
    y: 2031.5
  },
  {
    x: 2161.5,
    y: 2016.5
  },
  {
    x: 2161.5,
    y: 2001.5
  },
  {
    x: 2161.5,
    y: 1986.5
  },
  {
    x: 2161.5,
    y: 1971.5
  },
  {
    x: 2161.5,
    y: 1956.5
  },
  {
    x: 2161.5,
    y: 1941.5
  },
  {
    x: 2161.5,
    y: 1926.5
  },
  {
    x: 2161.5,
    y: 1911.5
  },
  {
    x: 2161.5,
    y: 1896.5
  },
  {
    x: 2161.5,
    y: 1881.5
  },
  {
    x: 2161.5,
    y: 1866.5
  },
  {
    x: 2161.5,
    y: 1851.5
  },
  {
    x: 2161.5,
    y: 1836.5
  },
  {
    x: 2161.5,
    y: 1056.5
  },
  {
    x: 2161.5,
    y: 1041.5
  },
  {
    x: 2161.5,
    y: 1026.5
  },
  {
    x: 2161.5,
    y: 1011.5
  },
  {
    x: 2161.5,
    y: 996.5
  },
  {
    x: 2161.5,
    y: 981.5
  },
  {
    x: 2161.5,
    y: 966.5
  },
  {
    x: 2161.5,
    y: 951.5
  },
  {
    x: 2161.5,
    y: 936.5
  },
  {
    x: 2161.5,
    y: 921.5
  },
  {
    x: 2161.5,
    y: 906.5
  },
  {
    x: 2161.5,
    y: 891.5
  },
  {
    x: 2161.5,
    y: 876.5
  },
  {
    x: 2161.5,
    y: 861.5
  },
  {
    x: 2161.5,
    y: 846.5
  },
  {
    x: 2161.5,
    y: 831.5
  },
  {
    x: 2161.5,
    y: 816.5
  },
  {
    x: 2161.5,
    y: 801.5
  },
  {
    x: 2161.5,
    y: 786.5
  },
  {
    x: 2161.5,
    y: 771.5
  },
  {
    x: 2161.5,
    y: 756.5
  },
  {
    x: 2161.5,
    y: 741.5
  },
  {
    x: 2161.5,
    y: 726.5
  },
  {
    x: 2161.5,
    y: 711.5
  },
  {
    x: 2161.5,
    y: 696.5
  },
  {
    x: 2161.5,
    y: 681.5
  },
  {
    x: 2161.5,
    y: 666.5
  },
  {
    x: 2161.5,
    y: 651.5
  },
  {
    x: 2161.5,
    y: 636.5
  },
  {
    x: 2161.5,
    y: 621.5
  },
  {
    x: 2161.5,
    y: 606.5
  },
  {
    x: 2161.5,
    y: 516.5
  },
  {
    x: 2161.5,
    y: 501.5
  },
  {
    x: 2161.5,
    y: 486.5
  },
  {
    x: 2161.5,
    y: 471.5
  },
  {
    x: 2161.5,
    y: 456.5
  },
  {
    x: 2161.5,
    y: 441.5
  },
  {
    x: 2161.5,
    y: 426.5
  },
  {
    x: 2161.5,
    y: 411.5
  },
  {
    x: 2161.5,
    y: 396.5
  },
  {
    x: 2161.5,
    y: 381.5
  },
  {
    x: 2161.5,
    y: 351.5
  },
  {
    x: 2161.5,
    y: 336.5
  },
  {
    x: 2161.5,
    y: 321.5
  },
  {
    x: 2161.5,
    y: 306.5
  },
  {
    x: 2176.5,
    y: 2046.5
  },
  {
    x: 2176.5,
    y: 2031.5
  },
  {
    x: 2176.5,
    y: 2016.5
  },
  {
    x: 2176.5,
    y: 2001.5
  },
  {
    x: 2176.5,
    y: 1986.5
  },
  {
    x: 2176.5,
    y: 1971.5
  },
  {
    x: 2176.5,
    y: 1956.5
  },
  {
    x: 2176.5,
    y: 1941.5
  },
  {
    x: 2176.5,
    y: 1926.5
  },
  {
    x: 2176.5,
    y: 1911.5
  },
  {
    x: 2176.5,
    y: 1896.5
  },
  {
    x: 2176.5,
    y: 1881.5
  },
  {
    x: 2176.5,
    y: 1866.5
  },
  {
    x: 2176.5,
    y: 1851.5
  },
  {
    x: 2176.5,
    y: 1836.5
  },
  {
    x: 2176.5,
    y: 1071.5
  },
  {
    x: 2176.5,
    y: 1056.5
  },
  {
    x: 2176.5,
    y: 1041.5
  },
  {
    x: 2176.5,
    y: 1026.5
  },
  {
    x: 2176.5,
    y: 1011.5
  },
  {
    x: 2176.5,
    y: 996.5
  },
  {
    x: 2176.5,
    y: 981.5
  },
  {
    x: 2176.5,
    y: 966.5
  },
  {
    x: 2176.5,
    y: 951.5
  },
  {
    x: 2176.5,
    y: 936.5
  },
  {
    x: 2176.5,
    y: 921.5
  },
  {
    x: 2176.5,
    y: 906.5
  },
  {
    x: 2176.5,
    y: 891.5
  },
  {
    x: 2176.5,
    y: 876.5
  },
  {
    x: 2176.5,
    y: 861.5
  },
  {
    x: 2176.5,
    y: 846.5
  },
  {
    x: 2176.5,
    y: 831.5
  },
  {
    x: 2176.5,
    y: 816.5
  },
  {
    x: 2176.5,
    y: 801.5
  },
  {
    x: 2176.5,
    y: 786.5
  },
  {
    x: 2176.5,
    y: 771.5
  },
  {
    x: 2176.5,
    y: 756.5
  },
  {
    x: 2176.5,
    y: 741.5
  },
  {
    x: 2176.5,
    y: 726.5
  },
  {
    x: 2176.5,
    y: 711.5
  },
  {
    x: 2176.5,
    y: 696.5
  },
  {
    x: 2176.5,
    y: 681.5
  },
  {
    x: 2176.5,
    y: 666.5
  },
  {
    x: 2176.5,
    y: 651.5
  },
  {
    x: 2176.5,
    y: 531.5
  },
  {
    x: 2176.5,
    y: 516.5
  },
  {
    x: 2176.5,
    y: 501.5
  },
  {
    x: 2176.5,
    y: 486.5
  },
  {
    x: 2176.5,
    y: 471.5
  },
  {
    x: 2176.5,
    y: 456.5
  },
  {
    x: 2176.5,
    y: 441.5
  },
  {
    x: 2176.5,
    y: 426.5
  },
  {
    x: 2176.5,
    y: 411.5
  },
  {
    x: 2176.5,
    y: 396.5
  },
  {
    x: 2176.5,
    y: 351.5
  },
  {
    x: 2176.5,
    y: 336.5
  },
  {
    x: 2176.5,
    y: 321.5
  },
  {
    x: 2176.5,
    y: 306.5
  },
  {
    x: 2176.5,
    y: 291.5
  },
  {
    x: 2176.5,
    y: 126.5
  },
  {
    x: 2191.5,
    y: 2046.5
  },
  {
    x: 2191.5,
    y: 2031.5
  },
  {
    x: 2191.5,
    y: 2016.5
  },
  {
    x: 2191.5,
    y: 2001.5
  },
  {
    x: 2191.5,
    y: 1986.5
  },
  {
    x: 2191.5,
    y: 1971.5
  },
  {
    x: 2191.5,
    y: 1956.5
  },
  {
    x: 2191.5,
    y: 1941.5
  },
  {
    x: 2191.5,
    y: 1926.5
  },
  {
    x: 2191.5,
    y: 1911.5
  },
  {
    x: 2191.5,
    y: 1896.5
  },
  {
    x: 2191.5,
    y: 1881.5
  },
  {
    x: 2191.5,
    y: 1866.5
  },
  {
    x: 2191.5,
    y: 1851.5
  },
  {
    x: 2191.5,
    y: 1836.5
  },
  {
    x: 2191.5,
    y: 1821.5
  },
  {
    x: 2191.5,
    y: 1236.5
  },
  {
    x: 2191.5,
    y: 1221.5
  },
  {
    x: 2191.5,
    y: 1206.5
  },
  {
    x: 2191.5,
    y: 1191.5
  },
  {
    x: 2191.5,
    y: 1176.5
  },
  {
    x: 2191.5,
    y: 1101.5
  },
  {
    x: 2191.5,
    y: 1086.5
  },
  {
    x: 2191.5,
    y: 1071.5
  },
  {
    x: 2191.5,
    y: 1056.5
  },
  {
    x: 2191.5,
    y: 1041.5
  },
  {
    x: 2191.5,
    y: 1026.5
  },
  {
    x: 2191.5,
    y: 1011.5
  },
  {
    x: 2191.5,
    y: 996.5
  },
  {
    x: 2191.5,
    y: 981.5
  },
  {
    x: 2191.5,
    y: 966.5
  },
  {
    x: 2191.5,
    y: 951.5
  },
  {
    x: 2191.5,
    y: 936.5
  },
  {
    x: 2191.5,
    y: 921.5
  },
  {
    x: 2191.5,
    y: 906.5
  },
  {
    x: 2191.5,
    y: 891.5
  },
  {
    x: 2191.5,
    y: 876.5
  },
  {
    x: 2191.5,
    y: 861.5
  },
  {
    x: 2191.5,
    y: 846.5
  },
  {
    x: 2191.5,
    y: 831.5
  },
  {
    x: 2191.5,
    y: 816.5
  },
  {
    x: 2191.5,
    y: 801.5
  },
  {
    x: 2191.5,
    y: 786.5
  },
  {
    x: 2191.5,
    y: 771.5
  },
  {
    x: 2191.5,
    y: 756.5
  },
  {
    x: 2191.5,
    y: 741.5
  },
  {
    x: 2191.5,
    y: 726.5
  },
  {
    x: 2191.5,
    y: 711.5
  },
  {
    x: 2191.5,
    y: 696.5
  },
  {
    x: 2191.5,
    y: 681.5
  },
  {
    x: 2191.5,
    y: 666.5
  },
  {
    x: 2191.5,
    y: 651.5
  },
  {
    x: 2191.5,
    y: 591.5
  },
  {
    x: 2191.5,
    y: 546.5
  },
  {
    x: 2191.5,
    y: 531.5
  },
  {
    x: 2191.5,
    y: 501.5
  },
  {
    x: 2191.5,
    y: 486.5
  },
  {
    x: 2191.5,
    y: 471.5
  },
  {
    x: 2191.5,
    y: 456.5
  },
  {
    x: 2191.5,
    y: 441.5
  },
  {
    x: 2191.5,
    y: 426.5
  },
  {
    x: 2191.5,
    y: 411.5
  },
  {
    x: 2191.5,
    y: 396.5
  },
  {
    x: 2191.5,
    y: 381.5
  },
  {
    x: 2191.5,
    y: 366.5
  },
  {
    x: 2191.5,
    y: 351.5
  },
  {
    x: 2191.5,
    y: 336.5
  },
  {
    x: 2191.5,
    y: 321.5
  },
  {
    x: 2191.5,
    y: 306.5
  },
  {
    x: 2191.5,
    y: 291.5
  },
  {
    x: 2191.5,
    y: 276.5
  },
  {
    x: 2191.5,
    y: 126.5
  },
  {
    x: 2206.5,
    y: 2046.5
  },
  {
    x: 2206.5,
    y: 2031.5
  },
  {
    x: 2206.5,
    y: 2016.5
  },
  {
    x: 2206.5,
    y: 2001.5
  },
  {
    x: 2206.5,
    y: 1986.5
  },
  {
    x: 2206.5,
    y: 1971.5
  },
  {
    x: 2206.5,
    y: 1956.5
  },
  {
    x: 2206.5,
    y: 1941.5
  },
  {
    x: 2206.5,
    y: 1926.5
  },
  {
    x: 2206.5,
    y: 1911.5
  },
  {
    x: 2206.5,
    y: 1896.5
  },
  {
    x: 2206.5,
    y: 1881.5
  },
  {
    x: 2206.5,
    y: 1866.5
  },
  {
    x: 2206.5,
    y: 1851.5
  },
  {
    x: 2206.5,
    y: 1836.5
  },
  {
    x: 2206.5,
    y: 1266.5
  },
  {
    x: 2206.5,
    y: 1251.5
  },
  {
    x: 2206.5,
    y: 1236.5
  },
  {
    x: 2206.5,
    y: 1221.5
  },
  {
    x: 2206.5,
    y: 1206.5
  },
  {
    x: 2206.5,
    y: 1191.5
  },
  {
    x: 2206.5,
    y: 1176.5
  },
  {
    x: 2206.5,
    y: 1161.5
  },
  {
    x: 2206.5,
    y: 1146.5
  },
  {
    x: 2206.5,
    y: 1131.5
  },
  {
    x: 2206.5,
    y: 1116.5
  },
  {
    x: 2206.5,
    y: 1101.5
  },
  {
    x: 2206.5,
    y: 1086.5
  },
  {
    x: 2206.5,
    y: 1071.5
  },
  {
    x: 2206.5,
    y: 1056.5
  },
  {
    x: 2206.5,
    y: 1041.5
  },
  {
    x: 2206.5,
    y: 1026.5
  },
  {
    x: 2206.5,
    y: 1011.5
  },
  {
    x: 2206.5,
    y: 996.5
  },
  {
    x: 2206.5,
    y: 981.5
  },
  {
    x: 2206.5,
    y: 966.5
  },
  {
    x: 2206.5,
    y: 951.5
  },
  {
    x: 2206.5,
    y: 936.5
  },
  {
    x: 2206.5,
    y: 921.5
  },
  {
    x: 2206.5,
    y: 906.5
  },
  {
    x: 2206.5,
    y: 891.5
  },
  {
    x: 2206.5,
    y: 876.5
  },
  {
    x: 2206.5,
    y: 861.5
  },
  {
    x: 2206.5,
    y: 846.5
  },
  {
    x: 2206.5,
    y: 831.5
  },
  {
    x: 2206.5,
    y: 816.5
  },
  {
    x: 2206.5,
    y: 801.5
  },
  {
    x: 2206.5,
    y: 786.5
  },
  {
    x: 2206.5,
    y: 771.5
  },
  {
    x: 2206.5,
    y: 756.5
  },
  {
    x: 2206.5,
    y: 741.5
  },
  {
    x: 2206.5,
    y: 726.5
  },
  {
    x: 2206.5,
    y: 711.5
  },
  {
    x: 2206.5,
    y: 696.5
  },
  {
    x: 2206.5,
    y: 681.5
  },
  {
    x: 2206.5,
    y: 666.5
  },
  {
    x: 2206.5,
    y: 651.5
  },
  {
    x: 2206.5,
    y: 591.5
  },
  {
    x: 2206.5,
    y: 546.5
  },
  {
    x: 2206.5,
    y: 501.5
  },
  {
    x: 2206.5,
    y: 486.5
  },
  {
    x: 2206.5,
    y: 471.5
  },
  {
    x: 2206.5,
    y: 456.5
  },
  {
    x: 2206.5,
    y: 441.5
  },
  {
    x: 2206.5,
    y: 426.5
  },
  {
    x: 2206.5,
    y: 411.5
  },
  {
    x: 2206.5,
    y: 381.5
  },
  {
    x: 2206.5,
    y: 366.5
  },
  {
    x: 2206.5,
    y: 351.5
  },
  {
    x: 2206.5,
    y: 336.5
  },
  {
    x: 2206.5,
    y: 321.5
  },
  {
    x: 2206.5,
    y: 306.5
  },
  {
    x: 2206.5,
    y: 291.5
  },
  {
    x: 2206.5,
    y: 276.5
  },
  {
    x: 2206.5,
    y: 261.5
  },
  {
    x: 2206.5,
    y: 246.5
  },
  {
    x: 2206.5,
    y: 141.5
  },
  {
    x: 2206.5,
    y: 126.5
  },
  {
    x: 2221.5,
    y: 2046.5
  },
  {
    x: 2221.5,
    y: 2031.5
  },
  {
    x: 2221.5,
    y: 2016.5
  },
  {
    x: 2221.5,
    y: 2001.5
  },
  {
    x: 2221.5,
    y: 1986.5
  },
  {
    x: 2221.5,
    y: 1971.5
  },
  {
    x: 2221.5,
    y: 1956.5
  },
  {
    x: 2221.5,
    y: 1941.5
  },
  {
    x: 2221.5,
    y: 1926.5
  },
  {
    x: 2221.5,
    y: 1911.5
  },
  {
    x: 2221.5,
    y: 1896.5
  },
  {
    x: 2221.5,
    y: 1881.5
  },
  {
    x: 2221.5,
    y: 1866.5
  },
  {
    x: 2221.5,
    y: 1851.5
  },
  {
    x: 2221.5,
    y: 1836.5
  },
  {
    x: 2221.5,
    y: 1326.5
  },
  {
    x: 2221.5,
    y: 1311.5
  },
  {
    x: 2221.5,
    y: 1296.5
  },
  {
    x: 2221.5,
    y: 1281.5
  },
  {
    x: 2221.5,
    y: 1266.5
  },
  {
    x: 2221.5,
    y: 1251.5
  },
  {
    x: 2221.5,
    y: 1236.5
  },
  {
    x: 2221.5,
    y: 1221.5
  },
  {
    x: 2221.5,
    y: 1206.5
  },
  {
    x: 2221.5,
    y: 1191.5
  },
  {
    x: 2221.5,
    y: 1176.5
  },
  {
    x: 2221.5,
    y: 1161.5
  },
  {
    x: 2221.5,
    y: 1146.5
  },
  {
    x: 2221.5,
    y: 1131.5
  },
  {
    x: 2221.5,
    y: 1116.5
  },
  {
    x: 2221.5,
    y: 1101.5
  },
  {
    x: 2221.5,
    y: 1086.5
  },
  {
    x: 2221.5,
    y: 1071.5
  },
  {
    x: 2221.5,
    y: 1056.5
  },
  {
    x: 2221.5,
    y: 1041.5
  },
  {
    x: 2221.5,
    y: 1026.5
  },
  {
    x: 2221.5,
    y: 1011.5
  },
  {
    x: 2221.5,
    y: 996.5
  },
  {
    x: 2221.5,
    y: 981.5
  },
  {
    x: 2221.5,
    y: 966.5
  },
  {
    x: 2221.5,
    y: 951.5
  },
  {
    x: 2221.5,
    y: 936.5
  },
  {
    x: 2221.5,
    y: 921.5
  },
  {
    x: 2221.5,
    y: 906.5
  },
  {
    x: 2221.5,
    y: 891.5
  },
  {
    x: 2221.5,
    y: 876.5
  },
  {
    x: 2221.5,
    y: 861.5
  },
  {
    x: 2221.5,
    y: 846.5
  },
  {
    x: 2221.5,
    y: 831.5
  },
  {
    x: 2221.5,
    y: 816.5
  },
  {
    x: 2221.5,
    y: 801.5
  },
  {
    x: 2221.5,
    y: 786.5
  },
  {
    x: 2221.5,
    y: 771.5
  },
  {
    x: 2221.5,
    y: 756.5
  },
  {
    x: 2221.5,
    y: 741.5
  },
  {
    x: 2221.5,
    y: 726.5
  },
  {
    x: 2221.5,
    y: 711.5
  },
  {
    x: 2221.5,
    y: 696.5
  },
  {
    x: 2221.5,
    y: 681.5
  },
  {
    x: 2221.5,
    y: 666.5
  },
  {
    x: 2221.5,
    y: 591.5
  },
  {
    x: 2221.5,
    y: 561.5
  },
  {
    x: 2221.5,
    y: 546.5
  },
  {
    x: 2221.5,
    y: 516.5
  },
  {
    x: 2221.5,
    y: 501.5
  },
  {
    x: 2221.5,
    y: 486.5
  },
  {
    x: 2221.5,
    y: 471.5
  },
  {
    x: 2221.5,
    y: 456.5
  },
  {
    x: 2221.5,
    y: 441.5
  },
  {
    x: 2221.5,
    y: 426.5
  },
  {
    x: 2221.5,
    y: 411.5
  },
  {
    x: 2221.5,
    y: 396.5
  },
  {
    x: 2221.5,
    y: 381.5
  },
  {
    x: 2221.5,
    y: 366.5
  },
  {
    x: 2221.5,
    y: 351.5
  },
  {
    x: 2221.5,
    y: 336.5
  },
  {
    x: 2221.5,
    y: 321.5
  },
  {
    x: 2221.5,
    y: 306.5
  },
  {
    x: 2221.5,
    y: 291.5
  },
  {
    x: 2221.5,
    y: 276.5
  },
  {
    x: 2221.5,
    y: 261.5
  },
  {
    x: 2221.5,
    y: 246.5
  },
  {
    x: 2221.5,
    y: 141.5
  },
  {
    x: 2221.5,
    y: 126.5
  },
  {
    x: 2236.5,
    y: 2046.5
  },
  {
    x: 2236.5,
    y: 2031.5
  },
  {
    x: 2236.5,
    y: 2016.5
  },
  {
    x: 2236.5,
    y: 2001.5
  },
  {
    x: 2236.5,
    y: 1986.5
  },
  {
    x: 2236.5,
    y: 1971.5
  },
  {
    x: 2236.5,
    y: 1956.5
  },
  {
    x: 2236.5,
    y: 1941.5
  },
  {
    x: 2236.5,
    y: 1926.5
  },
  {
    x: 2236.5,
    y: 1911.5
  },
  {
    x: 2236.5,
    y: 1896.5
  },
  {
    x: 2236.5,
    y: 1881.5
  },
  {
    x: 2236.5,
    y: 1866.5
  },
  {
    x: 2236.5,
    y: 1851.5
  },
  {
    x: 2236.5,
    y: 1836.5
  },
  {
    x: 2236.5,
    y: 1341.5
  },
  {
    x: 2236.5,
    y: 1326.5
  },
  {
    x: 2236.5,
    y: 1311.5
  },
  {
    x: 2236.5,
    y: 1296.5
  },
  {
    x: 2236.5,
    y: 1281.5
  },
  {
    x: 2236.5,
    y: 1266.5
  },
  {
    x: 2236.5,
    y: 1251.5
  },
  {
    x: 2236.5,
    y: 1236.5
  },
  {
    x: 2236.5,
    y: 1221.5
  },
  {
    x: 2236.5,
    y: 1206.5
  },
  {
    x: 2236.5,
    y: 1191.5
  },
  {
    x: 2236.5,
    y: 1176.5
  },
  {
    x: 2236.5,
    y: 1161.5
  },
  {
    x: 2236.5,
    y: 1146.5
  },
  {
    x: 2236.5,
    y: 1131.5
  },
  {
    x: 2236.5,
    y: 1116.5
  },
  {
    x: 2236.5,
    y: 1101.5
  },
  {
    x: 2236.5,
    y: 1086.5
  },
  {
    x: 2236.5,
    y: 1071.5
  },
  {
    x: 2236.5,
    y: 1056.5
  },
  {
    x: 2236.5,
    y: 1041.5
  },
  {
    x: 2236.5,
    y: 1026.5
  },
  {
    x: 2236.5,
    y: 1011.5
  },
  {
    x: 2236.5,
    y: 996.5
  },
  {
    x: 2236.5,
    y: 981.5
  },
  {
    x: 2236.5,
    y: 966.5
  },
  {
    x: 2236.5,
    y: 951.5
  },
  {
    x: 2236.5,
    y: 936.5
  },
  {
    x: 2236.5,
    y: 921.5
  },
  {
    x: 2236.5,
    y: 906.5
  },
  {
    x: 2236.5,
    y: 891.5
  },
  {
    x: 2236.5,
    y: 876.5
  },
  {
    x: 2236.5,
    y: 861.5
  },
  {
    x: 2236.5,
    y: 846.5
  },
  {
    x: 2236.5,
    y: 831.5
  },
  {
    x: 2236.5,
    y: 816.5
  },
  {
    x: 2236.5,
    y: 801.5
  },
  {
    x: 2236.5,
    y: 786.5
  },
  {
    x: 2236.5,
    y: 771.5
  },
  {
    x: 2236.5,
    y: 756.5
  },
  {
    x: 2236.5,
    y: 741.5
  },
  {
    x: 2236.5,
    y: 726.5
  },
  {
    x: 2236.5,
    y: 711.5
  },
  {
    x: 2236.5,
    y: 696.5
  },
  {
    x: 2236.5,
    y: 681.5
  },
  {
    x: 2236.5,
    y: 576.5
  },
  {
    x: 2236.5,
    y: 561.5
  },
  {
    x: 2236.5,
    y: 516.5
  },
  {
    x: 2236.5,
    y: 501.5
  },
  {
    x: 2236.5,
    y: 486.5
  },
  {
    x: 2236.5,
    y: 471.5
  },
  {
    x: 2236.5,
    y: 456.5
  },
  {
    x: 2236.5,
    y: 441.5
  },
  {
    x: 2236.5,
    y: 426.5
  },
  {
    x: 2236.5,
    y: 411.5
  },
  {
    x: 2236.5,
    y: 381.5
  },
  {
    x: 2236.5,
    y: 366.5
  },
  {
    x: 2236.5,
    y: 351.5
  },
  {
    x: 2236.5,
    y: 336.5
  },
  {
    x: 2236.5,
    y: 321.5
  },
  {
    x: 2236.5,
    y: 306.5
  },
  {
    x: 2236.5,
    y: 291.5
  },
  {
    x: 2236.5,
    y: 276.5
  },
  {
    x: 2236.5,
    y: 261.5
  },
  {
    x: 2236.5,
    y: 246.5
  },
  {
    x: 2236.5,
    y: 141.5
  },
  {
    x: 2236.5,
    y: 126.5
  },
  {
    x: 2251.5,
    y: 2046.5
  },
  {
    x: 2251.5,
    y: 2031.5
  },
  {
    x: 2251.5,
    y: 2016.5
  },
  {
    x: 2251.5,
    y: 2001.5
  },
  {
    x: 2251.5,
    y: 1986.5
  },
  {
    x: 2251.5,
    y: 1971.5
  },
  {
    x: 2251.5,
    y: 1956.5
  },
  {
    x: 2251.5,
    y: 1941.5
  },
  {
    x: 2251.5,
    y: 1926.5
  },
  {
    x: 2251.5,
    y: 1911.5
  },
  {
    x: 2251.5,
    y: 1896.5
  },
  {
    x: 2251.5,
    y: 1881.5
  },
  {
    x: 2251.5,
    y: 1866.5
  },
  {
    x: 2251.5,
    y: 1851.5
  },
  {
    x: 2251.5,
    y: 1836.5
  },
  {
    x: 2251.5,
    y: 1371.5
  },
  {
    x: 2251.5,
    y: 1356.5
  },
  {
    x: 2251.5,
    y: 1341.5
  },
  {
    x: 2251.5,
    y: 1326.5
  },
  {
    x: 2251.5,
    y: 1311.5
  },
  {
    x: 2251.5,
    y: 1296.5
  },
  {
    x: 2251.5,
    y: 1281.5
  },
  {
    x: 2251.5,
    y: 1266.5
  },
  {
    x: 2251.5,
    y: 1251.5
  },
  {
    x: 2251.5,
    y: 1236.5
  },
  {
    x: 2251.5,
    y: 1221.5
  },
  {
    x: 2251.5,
    y: 1206.5
  },
  {
    x: 2251.5,
    y: 1191.5
  },
  {
    x: 2251.5,
    y: 1176.5
  },
  {
    x: 2251.5,
    y: 1161.5
  },
  {
    x: 2251.5,
    y: 1146.5
  },
  {
    x: 2251.5,
    y: 1131.5
  },
  {
    x: 2251.5,
    y: 1116.5
  },
  {
    x: 2251.5,
    y: 1101.5
  },
  {
    x: 2251.5,
    y: 1086.5
  },
  {
    x: 2251.5,
    y: 1071.5
  },
  {
    x: 2251.5,
    y: 1056.5
  },
  {
    x: 2251.5,
    y: 1041.5
  },
  {
    x: 2251.5,
    y: 1026.5
  },
  {
    x: 2251.5,
    y: 1011.5
  },
  {
    x: 2251.5,
    y: 996.5
  },
  {
    x: 2251.5,
    y: 981.5
  },
  {
    x: 2251.5,
    y: 966.5
  },
  {
    x: 2251.5,
    y: 951.5
  },
  {
    x: 2251.5,
    y: 936.5
  },
  {
    x: 2251.5,
    y: 921.5
  },
  {
    x: 2251.5,
    y: 906.5
  },
  {
    x: 2251.5,
    y: 891.5
  },
  {
    x: 2251.5,
    y: 876.5
  },
  {
    x: 2251.5,
    y: 861.5
  },
  {
    x: 2251.5,
    y: 846.5
  },
  {
    x: 2251.5,
    y: 831.5
  },
  {
    x: 2251.5,
    y: 816.5
  },
  {
    x: 2251.5,
    y: 801.5
  },
  {
    x: 2251.5,
    y: 786.5
  },
  {
    x: 2251.5,
    y: 771.5
  },
  {
    x: 2251.5,
    y: 756.5
  },
  {
    x: 2251.5,
    y: 741.5
  },
  {
    x: 2251.5,
    y: 726.5
  },
  {
    x: 2251.5,
    y: 711.5
  },
  {
    x: 2251.5,
    y: 696.5
  },
  {
    x: 2251.5,
    y: 681.5
  },
  {
    x: 2251.5,
    y: 561.5
  },
  {
    x: 2251.5,
    y: 531.5
  },
  {
    x: 2251.5,
    y: 516.5
  },
  {
    x: 2251.5,
    y: 501.5
  },
  {
    x: 2251.5,
    y: 486.5
  },
  {
    x: 2251.5,
    y: 471.5
  },
  {
    x: 2251.5,
    y: 456.5
  },
  {
    x: 2251.5,
    y: 441.5
  },
  {
    x: 2251.5,
    y: 426.5
  },
  {
    x: 2251.5,
    y: 411.5
  },
  {
    x: 2251.5,
    y: 351.5
  },
  {
    x: 2251.5,
    y: 336.5
  },
  {
    x: 2251.5,
    y: 306.5
  },
  {
    x: 2251.5,
    y: 291.5
  },
  {
    x: 2251.5,
    y: 276.5
  },
  {
    x: 2251.5,
    y: 261.5
  },
  {
    x: 2251.5,
    y: 246.5
  },
  {
    x: 2251.5,
    y: 231.5
  },
  {
    x: 2251.5,
    y: 141.5
  },
  {
    x: 2251.5,
    y: 126.5
  },
  {
    x: 2251.5,
    y: 111.5
  },
  {
    x: 2266.5,
    y: 2046.5
  },
  {
    x: 2266.5,
    y: 2031.5
  },
  {
    x: 2266.5,
    y: 2016.5
  },
  {
    x: 2266.5,
    y: 2001.5
  },
  {
    x: 2266.5,
    y: 1986.5
  },
  {
    x: 2266.5,
    y: 1971.5
  },
  {
    x: 2266.5,
    y: 1956.5
  },
  {
    x: 2266.5,
    y: 1941.5
  },
  {
    x: 2266.5,
    y: 1926.5
  },
  {
    x: 2266.5,
    y: 1911.5
  },
  {
    x: 2266.5,
    y: 1896.5
  },
  {
    x: 2266.5,
    y: 1881.5
  },
  {
    x: 2266.5,
    y: 1866.5
  },
  {
    x: 2266.5,
    y: 1851.5
  },
  {
    x: 2266.5,
    y: 1836.5
  },
  {
    x: 2266.5,
    y: 1416.5
  },
  {
    x: 2266.5,
    y: 1401.5
  },
  {
    x: 2266.5,
    y: 1386.5
  },
  {
    x: 2266.5,
    y: 1371.5
  },
  {
    x: 2266.5,
    y: 1356.5
  },
  {
    x: 2266.5,
    y: 1341.5
  },
  {
    x: 2266.5,
    y: 1326.5
  },
  {
    x: 2266.5,
    y: 1311.5
  },
  {
    x: 2266.5,
    y: 1296.5
  },
  {
    x: 2266.5,
    y: 1281.5
  },
  {
    x: 2266.5,
    y: 1266.5
  },
  {
    x: 2266.5,
    y: 1251.5
  },
  {
    x: 2266.5,
    y: 1236.5
  },
  {
    x: 2266.5,
    y: 1221.5
  },
  {
    x: 2266.5,
    y: 1206.5
  },
  {
    x: 2266.5,
    y: 1191.5
  },
  {
    x: 2266.5,
    y: 1176.5
  },
  {
    x: 2266.5,
    y: 1161.5
  },
  {
    x: 2266.5,
    y: 1146.5
  },
  {
    x: 2266.5,
    y: 1131.5
  },
  {
    x: 2266.5,
    y: 1116.5
  },
  {
    x: 2266.5,
    y: 1101.5
  },
  {
    x: 2266.5,
    y: 1086.5
  },
  {
    x: 2266.5,
    y: 1071.5
  },
  {
    x: 2266.5,
    y: 1056.5
  },
  {
    x: 2266.5,
    y: 1041.5
  },
  {
    x: 2266.5,
    y: 1026.5
  },
  {
    x: 2266.5,
    y: 1011.5
  },
  {
    x: 2266.5,
    y: 996.5
  },
  {
    x: 2266.5,
    y: 981.5
  },
  {
    x: 2266.5,
    y: 966.5
  },
  {
    x: 2266.5,
    y: 951.5
  },
  {
    x: 2266.5,
    y: 936.5
  },
  {
    x: 2266.5,
    y: 921.5
  },
  {
    x: 2266.5,
    y: 906.5
  },
  {
    x: 2266.5,
    y: 891.5
  },
  {
    x: 2266.5,
    y: 876.5
  },
  {
    x: 2266.5,
    y: 861.5
  },
  {
    x: 2266.5,
    y: 846.5
  },
  {
    x: 2266.5,
    y: 831.5
  },
  {
    x: 2266.5,
    y: 816.5
  },
  {
    x: 2266.5,
    y: 801.5
  },
  {
    x: 2266.5,
    y: 786.5
  },
  {
    x: 2266.5,
    y: 771.5
  },
  {
    x: 2266.5,
    y: 756.5
  },
  {
    x: 2266.5,
    y: 741.5
  },
  {
    x: 2266.5,
    y: 726.5
  },
  {
    x: 2266.5,
    y: 711.5
  },
  {
    x: 2266.5,
    y: 696.5
  },
  {
    x: 2266.5,
    y: 681.5
  },
  {
    x: 2266.5,
    y: 546.5
  },
  {
    x: 2266.5,
    y: 531.5
  },
  {
    x: 2266.5,
    y: 516.5
  },
  {
    x: 2266.5,
    y: 501.5
  },
  {
    x: 2266.5,
    y: 486.5
  },
  {
    x: 2266.5,
    y: 471.5
  },
  {
    x: 2266.5,
    y: 456.5
  },
  {
    x: 2266.5,
    y: 441.5
  },
  {
    x: 2266.5,
    y: 426.5
  },
  {
    x: 2266.5,
    y: 411.5
  },
  {
    x: 2266.5,
    y: 366.5
  },
  {
    x: 2266.5,
    y: 291.5
  },
  {
    x: 2266.5,
    y: 276.5
  },
  {
    x: 2266.5,
    y: 261.5
  },
  {
    x: 2266.5,
    y: 246.5
  },
  {
    x: 2266.5,
    y: 231.5
  },
  {
    x: 2266.5,
    y: 126.5
  },
  {
    x: 2266.5,
    y: 111.5
  },
  {
    x: 2281.5,
    y: 2046.5
  },
  {
    x: 2281.5,
    y: 2031.5
  },
  {
    x: 2281.5,
    y: 2016.5
  },
  {
    x: 2281.5,
    y: 2001.5
  },
  {
    x: 2281.5,
    y: 1986.5
  },
  {
    x: 2281.5,
    y: 1971.5
  },
  {
    x: 2281.5,
    y: 1956.5
  },
  {
    x: 2281.5,
    y: 1941.5
  },
  {
    x: 2281.5,
    y: 1926.5
  },
  {
    x: 2281.5,
    y: 1911.5
  },
  {
    x: 2281.5,
    y: 1896.5
  },
  {
    x: 2281.5,
    y: 1881.5
  },
  {
    x: 2281.5,
    y: 1866.5
  },
  {
    x: 2281.5,
    y: 1851.5
  },
  {
    x: 2281.5,
    y: 1836.5
  },
  {
    x: 2281.5,
    y: 1416.5
  },
  {
    x: 2281.5,
    y: 1401.5
  },
  {
    x: 2281.5,
    y: 1386.5
  },
  {
    x: 2281.5,
    y: 1371.5
  },
  {
    x: 2281.5,
    y: 1356.5
  },
  {
    x: 2281.5,
    y: 1341.5
  },
  {
    x: 2281.5,
    y: 1326.5
  },
  {
    x: 2281.5,
    y: 1311.5
  },
  {
    x: 2281.5,
    y: 1296.5
  },
  {
    x: 2281.5,
    y: 1281.5
  },
  {
    x: 2281.5,
    y: 1266.5
  },
  {
    x: 2281.5,
    y: 1251.5
  },
  {
    x: 2281.5,
    y: 1236.5
  },
  {
    x: 2281.5,
    y: 1221.5
  },
  {
    x: 2281.5,
    y: 1206.5
  },
  {
    x: 2281.5,
    y: 1191.5
  },
  {
    x: 2281.5,
    y: 1176.5
  },
  {
    x: 2281.5,
    y: 1161.5
  },
  {
    x: 2281.5,
    y: 1146.5
  },
  {
    x: 2281.5,
    y: 1131.5
  },
  {
    x: 2281.5,
    y: 1116.5
  },
  {
    x: 2281.5,
    y: 1101.5
  },
  {
    x: 2281.5,
    y: 1086.5
  },
  {
    x: 2281.5,
    y: 1071.5
  },
  {
    x: 2281.5,
    y: 1056.5
  },
  {
    x: 2281.5,
    y: 1041.5
  },
  {
    x: 2281.5,
    y: 1026.5
  },
  {
    x: 2281.5,
    y: 1011.5
  },
  {
    x: 2281.5,
    y: 996.5
  },
  {
    x: 2281.5,
    y: 981.5
  },
  {
    x: 2281.5,
    y: 966.5
  },
  {
    x: 2281.5,
    y: 951.5
  },
  {
    x: 2281.5,
    y: 936.5
  },
  {
    x: 2281.5,
    y: 921.5
  },
  {
    x: 2281.5,
    y: 906.5
  },
  {
    x: 2281.5,
    y: 891.5
  },
  {
    x: 2281.5,
    y: 876.5
  },
  {
    x: 2281.5,
    y: 861.5
  },
  {
    x: 2281.5,
    y: 846.5
  },
  {
    x: 2281.5,
    y: 831.5
  },
  {
    x: 2281.5,
    y: 816.5
  },
  {
    x: 2281.5,
    y: 801.5
  },
  {
    x: 2281.5,
    y: 786.5
  },
  {
    x: 2281.5,
    y: 771.5
  },
  {
    x: 2281.5,
    y: 756.5
  },
  {
    x: 2281.5,
    y: 741.5
  },
  {
    x: 2281.5,
    y: 726.5
  },
  {
    x: 2281.5,
    y: 711.5
  },
  {
    x: 2281.5,
    y: 696.5
  },
  {
    x: 2281.5,
    y: 681.5
  },
  {
    x: 2281.5,
    y: 666.5
  },
  {
    x: 2281.5,
    y: 591.5
  },
  {
    x: 2281.5,
    y: 576.5
  },
  {
    x: 2281.5,
    y: 561.5
  },
  {
    x: 2281.5,
    y: 546.5
  },
  {
    x: 2281.5,
    y: 531.5
  },
  {
    x: 2281.5,
    y: 516.5
  },
  {
    x: 2281.5,
    y: 501.5
  },
  {
    x: 2281.5,
    y: 486.5
  },
  {
    x: 2281.5,
    y: 471.5
  },
  {
    x: 2281.5,
    y: 456.5
  },
  {
    x: 2281.5,
    y: 441.5
  },
  {
    x: 2281.5,
    y: 426.5
  },
  {
    x: 2281.5,
    y: 411.5
  },
  {
    x: 2281.5,
    y: 396.5
  },
  {
    x: 2281.5,
    y: 291.5
  },
  {
    x: 2281.5,
    y: 276.5
  },
  {
    x: 2281.5,
    y: 261.5
  },
  {
    x: 2281.5,
    y: 246.5
  },
  {
    x: 2281.5,
    y: 231.5
  },
  {
    x: 2281.5,
    y: 126.5
  },
  {
    x: 2281.5,
    y: 111.5
  },
  {
    x: 2296.5,
    y: 2046.5
  },
  {
    x: 2296.5,
    y: 2031.5
  },
  {
    x: 2296.5,
    y: 2016.5
  },
  {
    x: 2296.5,
    y: 2001.5
  },
  {
    x: 2296.5,
    y: 1986.5
  },
  {
    x: 2296.5,
    y: 1971.5
  },
  {
    x: 2296.5,
    y: 1956.5
  },
  {
    x: 2296.5,
    y: 1941.5
  },
  {
    x: 2296.5,
    y: 1926.5
  },
  {
    x: 2296.5,
    y: 1911.5
  },
  {
    x: 2296.5,
    y: 1896.5
  },
  {
    x: 2296.5,
    y: 1881.5
  },
  {
    x: 2296.5,
    y: 1866.5
  },
  {
    x: 2296.5,
    y: 1851.5
  },
  {
    x: 2296.5,
    y: 1836.5
  },
  {
    x: 2296.5,
    y: 1401.5
  },
  {
    x: 2296.5,
    y: 1386.5
  },
  {
    x: 2296.5,
    y: 1371.5
  },
  {
    x: 2296.5,
    y: 1356.5
  },
  {
    x: 2296.5,
    y: 1341.5
  },
  {
    x: 2296.5,
    y: 1326.5
  },
  {
    x: 2296.5,
    y: 1311.5
  },
  {
    x: 2296.5,
    y: 1296.5
  },
  {
    x: 2296.5,
    y: 1281.5
  },
  {
    x: 2296.5,
    y: 1266.5
  },
  {
    x: 2296.5,
    y: 1251.5
  },
  {
    x: 2296.5,
    y: 1236.5
  },
  {
    x: 2296.5,
    y: 1221.5
  },
  {
    x: 2296.5,
    y: 1206.5
  },
  {
    x: 2296.5,
    y: 1191.5
  },
  {
    x: 2296.5,
    y: 1176.5
  },
  {
    x: 2296.5,
    y: 1161.5
  },
  {
    x: 2296.5,
    y: 1146.5
  },
  {
    x: 2296.5,
    y: 1131.5
  },
  {
    x: 2296.5,
    y: 1116.5
  },
  {
    x: 2296.5,
    y: 1101.5
  },
  {
    x: 2296.5,
    y: 1086.5
  },
  {
    x: 2296.5,
    y: 1071.5
  },
  {
    x: 2296.5,
    y: 1056.5
  },
  {
    x: 2296.5,
    y: 1041.5
  },
  {
    x: 2296.5,
    y: 1026.5
  },
  {
    x: 2296.5,
    y: 1011.5
  },
  {
    x: 2296.5,
    y: 996.5
  },
  {
    x: 2296.5,
    y: 981.5
  },
  {
    x: 2296.5,
    y: 966.5
  },
  {
    x: 2296.5,
    y: 951.5
  },
  {
    x: 2296.5,
    y: 936.5
  },
  {
    x: 2296.5,
    y: 921.5
  },
  {
    x: 2296.5,
    y: 906.5
  },
  {
    x: 2296.5,
    y: 891.5
  },
  {
    x: 2296.5,
    y: 876.5
  },
  {
    x: 2296.5,
    y: 861.5
  },
  {
    x: 2296.5,
    y: 846.5
  },
  {
    x: 2296.5,
    y: 831.5
  },
  {
    x: 2296.5,
    y: 816.5
  },
  {
    x: 2296.5,
    y: 801.5
  },
  {
    x: 2296.5,
    y: 786.5
  },
  {
    x: 2296.5,
    y: 771.5
  },
  {
    x: 2296.5,
    y: 756.5
  },
  {
    x: 2296.5,
    y: 741.5
  },
  {
    x: 2296.5,
    y: 726.5
  },
  {
    x: 2296.5,
    y: 711.5
  },
  {
    x: 2296.5,
    y: 696.5
  },
  {
    x: 2296.5,
    y: 681.5
  },
  {
    x: 2296.5,
    y: 666.5
  },
  {
    x: 2296.5,
    y: 651.5
  },
  {
    x: 2296.5,
    y: 591.5
  },
  {
    x: 2296.5,
    y: 576.5
  },
  {
    x: 2296.5,
    y: 561.5
  },
  {
    x: 2296.5,
    y: 546.5
  },
  {
    x: 2296.5,
    y: 531.5
  },
  {
    x: 2296.5,
    y: 516.5
  },
  {
    x: 2296.5,
    y: 501.5
  },
  {
    x: 2296.5,
    y: 486.5
  },
  {
    x: 2296.5,
    y: 471.5
  },
  {
    x: 2296.5,
    y: 456.5
  },
  {
    x: 2296.5,
    y: 441.5
  },
  {
    x: 2296.5,
    y: 426.5
  },
  {
    x: 2296.5,
    y: 411.5
  },
  {
    x: 2296.5,
    y: 396.5
  },
  {
    x: 2296.5,
    y: 381.5
  },
  {
    x: 2296.5,
    y: 336.5
  },
  {
    x: 2296.5,
    y: 321.5
  },
  {
    x: 2296.5,
    y: 306.5
  },
  {
    x: 2296.5,
    y: 276.5
  },
  {
    x: 2296.5,
    y: 261.5
  },
  {
    x: 2296.5,
    y: 246.5
  },
  {
    x: 2296.5,
    y: 231.5
  },
  {
    x: 2296.5,
    y: 141.5
  },
  {
    x: 2296.5,
    y: 111.5
  },
  {
    x: 2311.5,
    y: 2046.5
  },
  {
    x: 2311.5,
    y: 2031.5
  },
  {
    x: 2311.5,
    y: 2016.5
  },
  {
    x: 2311.5,
    y: 2001.5
  },
  {
    x: 2311.5,
    y: 1986.5
  },
  {
    x: 2311.5,
    y: 1971.5
  },
  {
    x: 2311.5,
    y: 1956.5
  },
  {
    x: 2311.5,
    y: 1941.5
  },
  {
    x: 2311.5,
    y: 1926.5
  },
  {
    x: 2311.5,
    y: 1911.5
  },
  {
    x: 2311.5,
    y: 1896.5
  },
  {
    x: 2311.5,
    y: 1881.5
  },
  {
    x: 2311.5,
    y: 1866.5
  },
  {
    x: 2311.5,
    y: 1851.5
  },
  {
    x: 2311.5,
    y: 1836.5
  },
  {
    x: 2311.5,
    y: 1401.5
  },
  {
    x: 2311.5,
    y: 1386.5
  },
  {
    x: 2311.5,
    y: 1371.5
  },
  {
    x: 2311.5,
    y: 1356.5
  },
  {
    x: 2311.5,
    y: 1341.5
  },
  {
    x: 2311.5,
    y: 1326.5
  },
  {
    x: 2311.5,
    y: 1311.5
  },
  {
    x: 2311.5,
    y: 1296.5
  },
  {
    x: 2311.5,
    y: 1281.5
  },
  {
    x: 2311.5,
    y: 1266.5
  },
  {
    x: 2311.5,
    y: 1251.5
  },
  {
    x: 2311.5,
    y: 1236.5
  },
  {
    x: 2311.5,
    y: 1221.5
  },
  {
    x: 2311.5,
    y: 1206.5
  },
  {
    x: 2311.5,
    y: 1191.5
  },
  {
    x: 2311.5,
    y: 1176.5
  },
  {
    x: 2311.5,
    y: 1161.5
  },
  {
    x: 2311.5,
    y: 1146.5
  },
  {
    x: 2311.5,
    y: 1131.5
  },
  {
    x: 2311.5,
    y: 1116.5
  },
  {
    x: 2311.5,
    y: 1101.5
  },
  {
    x: 2311.5,
    y: 1086.5
  },
  {
    x: 2311.5,
    y: 1071.5
  },
  {
    x: 2311.5,
    y: 1056.5
  },
  {
    x: 2311.5,
    y: 1041.5
  },
  {
    x: 2311.5,
    y: 1026.5
  },
  {
    x: 2311.5,
    y: 1011.5
  },
  {
    x: 2311.5,
    y: 996.5
  },
  {
    x: 2311.5,
    y: 981.5
  },
  {
    x: 2311.5,
    y: 966.5
  },
  {
    x: 2311.5,
    y: 951.5
  },
  {
    x: 2311.5,
    y: 936.5
  },
  {
    x: 2311.5,
    y: 921.5
  },
  {
    x: 2311.5,
    y: 906.5
  },
  {
    x: 2311.5,
    y: 891.5
  },
  {
    x: 2311.5,
    y: 876.5
  },
  {
    x: 2311.5,
    y: 861.5
  },
  {
    x: 2311.5,
    y: 846.5
  },
  {
    x: 2311.5,
    y: 831.5
  },
  {
    x: 2311.5,
    y: 816.5
  },
  {
    x: 2311.5,
    y: 801.5
  },
  {
    x: 2311.5,
    y: 786.5
  },
  {
    x: 2311.5,
    y: 771.5
  },
  {
    x: 2311.5,
    y: 756.5
  },
  {
    x: 2311.5,
    y: 741.5
  },
  {
    x: 2311.5,
    y: 726.5
  },
  {
    x: 2311.5,
    y: 711.5
  },
  {
    x: 2311.5,
    y: 696.5
  },
  {
    x: 2311.5,
    y: 681.5
  },
  {
    x: 2311.5,
    y: 666.5
  },
  {
    x: 2311.5,
    y: 606.5
  },
  {
    x: 2311.5,
    y: 591.5
  },
  {
    x: 2311.5,
    y: 576.5
  },
  {
    x: 2311.5,
    y: 561.5
  },
  {
    x: 2311.5,
    y: 546.5
  },
  {
    x: 2311.5,
    y: 531.5
  },
  {
    x: 2311.5,
    y: 516.5
  },
  {
    x: 2311.5,
    y: 501.5
  },
  {
    x: 2311.5,
    y: 486.5
  },
  {
    x: 2311.5,
    y: 471.5
  },
  {
    x: 2311.5,
    y: 456.5
  },
  {
    x: 2311.5,
    y: 441.5
  },
  {
    x: 2311.5,
    y: 426.5
  },
  {
    x: 2311.5,
    y: 411.5
  },
  {
    x: 2311.5,
    y: 396.5
  },
  {
    x: 2311.5,
    y: 381.5
  },
  {
    x: 2311.5,
    y: 336.5
  },
  {
    x: 2311.5,
    y: 321.5
  },
  {
    x: 2311.5,
    y: 306.5
  },
  {
    x: 2311.5,
    y: 261.5
  },
  {
    x: 2311.5,
    y: 246.5
  },
  {
    x: 2311.5,
    y: 231.5
  },
  {
    x: 2311.5,
    y: 141.5
  },
  {
    x: 2311.5,
    y: 111.5
  },
  {
    x: 2326.5,
    y: 2046.5
  },
  {
    x: 2326.5,
    y: 2031.5
  },
  {
    x: 2326.5,
    y: 2016.5
  },
  {
    x: 2326.5,
    y: 2001.5
  },
  {
    x: 2326.5,
    y: 1986.5
  },
  {
    x: 2326.5,
    y: 1971.5
  },
  {
    x: 2326.5,
    y: 1956.5
  },
  {
    x: 2326.5,
    y: 1941.5
  },
  {
    x: 2326.5,
    y: 1926.5
  },
  {
    x: 2326.5,
    y: 1911.5
  },
  {
    x: 2326.5,
    y: 1896.5
  },
  {
    x: 2326.5,
    y: 1881.5
  },
  {
    x: 2326.5,
    y: 1866.5
  },
  {
    x: 2326.5,
    y: 1851.5
  },
  {
    x: 2326.5,
    y: 1836.5
  },
  {
    x: 2326.5,
    y: 1401.5
  },
  {
    x: 2326.5,
    y: 1386.5
  },
  {
    x: 2326.5,
    y: 1371.5
  },
  {
    x: 2326.5,
    y: 1356.5
  },
  {
    x: 2326.5,
    y: 1341.5
  },
  {
    x: 2326.5,
    y: 1326.5
  },
  {
    x: 2326.5,
    y: 1311.5
  },
  {
    x: 2326.5,
    y: 1296.5
  },
  {
    x: 2326.5,
    y: 1281.5
  },
  {
    x: 2326.5,
    y: 1266.5
  },
  {
    x: 2326.5,
    y: 1251.5
  },
  {
    x: 2326.5,
    y: 1236.5
  },
  {
    x: 2326.5,
    y: 1221.5
  },
  {
    x: 2326.5,
    y: 1206.5
  },
  {
    x: 2326.5,
    y: 1191.5
  },
  {
    x: 2326.5,
    y: 1176.5
  },
  {
    x: 2326.5,
    y: 1161.5
  },
  {
    x: 2326.5,
    y: 1146.5
  },
  {
    x: 2326.5,
    y: 1131.5
  },
  {
    x: 2326.5,
    y: 1116.5
  },
  {
    x: 2326.5,
    y: 1101.5
  },
  {
    x: 2326.5,
    y: 1086.5
  },
  {
    x: 2326.5,
    y: 1071.5
  },
  {
    x: 2326.5,
    y: 1056.5
  },
  {
    x: 2326.5,
    y: 1041.5
  },
  {
    x: 2326.5,
    y: 1026.5
  },
  {
    x: 2326.5,
    y: 1011.5
  },
  {
    x: 2326.5,
    y: 996.5
  },
  {
    x: 2326.5,
    y: 981.5
  },
  {
    x: 2326.5,
    y: 966.5
  },
  {
    x: 2326.5,
    y: 951.5
  },
  {
    x: 2326.5,
    y: 936.5
  },
  {
    x: 2326.5,
    y: 921.5
  },
  {
    x: 2326.5,
    y: 906.5
  },
  {
    x: 2326.5,
    y: 891.5
  },
  {
    x: 2326.5,
    y: 876.5
  },
  {
    x: 2326.5,
    y: 861.5
  },
  {
    x: 2326.5,
    y: 846.5
  },
  {
    x: 2326.5,
    y: 831.5
  },
  {
    x: 2326.5,
    y: 816.5
  },
  {
    x: 2326.5,
    y: 801.5
  },
  {
    x: 2326.5,
    y: 786.5
  },
  {
    x: 2326.5,
    y: 771.5
  },
  {
    x: 2326.5,
    y: 756.5
  },
  {
    x: 2326.5,
    y: 741.5
  },
  {
    x: 2326.5,
    y: 726.5
  },
  {
    x: 2326.5,
    y: 711.5
  },
  {
    x: 2326.5,
    y: 696.5
  },
  {
    x: 2326.5,
    y: 681.5
  },
  {
    x: 2326.5,
    y: 666.5
  },
  {
    x: 2326.5,
    y: 621.5
  },
  {
    x: 2326.5,
    y: 591.5
  },
  {
    x: 2326.5,
    y: 561.5
  },
  {
    x: 2326.5,
    y: 546.5
  },
  {
    x: 2326.5,
    y: 531.5
  },
  {
    x: 2326.5,
    y: 516.5
  },
  {
    x: 2326.5,
    y: 501.5
  },
  {
    x: 2326.5,
    y: 486.5
  },
  {
    x: 2326.5,
    y: 471.5
  },
  {
    x: 2326.5,
    y: 456.5
  },
  {
    x: 2326.5,
    y: 441.5
  },
  {
    x: 2326.5,
    y: 426.5
  },
  {
    x: 2326.5,
    y: 411.5
  },
  {
    x: 2326.5,
    y: 396.5
  },
  {
    x: 2326.5,
    y: 381.5
  },
  {
    x: 2326.5,
    y: 366.5
  },
  {
    x: 2326.5,
    y: 351.5
  },
  {
    x: 2326.5,
    y: 336.5
  },
  {
    x: 2326.5,
    y: 321.5
  },
  {
    x: 2326.5,
    y: 306.5
  },
  {
    x: 2326.5,
    y: 291.5
  },
  {
    x: 2326.5,
    y: 276.5
  },
  {
    x: 2326.5,
    y: 261.5
  },
  {
    x: 2326.5,
    y: 246.5
  },
  {
    x: 2326.5,
    y: 231.5
  },
  {
    x: 2326.5,
    y: 216.5
  },
  {
    x: 2326.5,
    y: 111.5
  },
  {
    x: 2341.5,
    y: 2046.5
  },
  {
    x: 2341.5,
    y: 2031.5
  },
  {
    x: 2341.5,
    y: 2016.5
  },
  {
    x: 2341.5,
    y: 2001.5
  },
  {
    x: 2341.5,
    y: 1986.5
  },
  {
    x: 2341.5,
    y: 1971.5
  },
  {
    x: 2341.5,
    y: 1956.5
  },
  {
    x: 2341.5,
    y: 1941.5
  },
  {
    x: 2341.5,
    y: 1926.5
  },
  {
    x: 2341.5,
    y: 1911.5
  },
  {
    x: 2341.5,
    y: 1896.5
  },
  {
    x: 2341.5,
    y: 1881.5
  },
  {
    x: 2341.5,
    y: 1866.5
  },
  {
    x: 2341.5,
    y: 1851.5
  },
  {
    x: 2341.5,
    y: 1836.5
  },
  {
    x: 2341.5,
    y: 1401.5
  },
  {
    x: 2341.5,
    y: 1386.5
  },
  {
    x: 2341.5,
    y: 1371.5
  },
  {
    x: 2341.5,
    y: 1356.5
  },
  {
    x: 2341.5,
    y: 1341.5
  },
  {
    x: 2341.5,
    y: 1326.5
  },
  {
    x: 2341.5,
    y: 1311.5
  },
  {
    x: 2341.5,
    y: 1296.5
  },
  {
    x: 2341.5,
    y: 1281.5
  },
  {
    x: 2341.5,
    y: 1266.5
  },
  {
    x: 2341.5,
    y: 1251.5
  },
  {
    x: 2341.5,
    y: 1236.5
  },
  {
    x: 2341.5,
    y: 1221.5
  },
  {
    x: 2341.5,
    y: 1206.5
  },
  {
    x: 2341.5,
    y: 1191.5
  },
  {
    x: 2341.5,
    y: 1176.5
  },
  {
    x: 2341.5,
    y: 1161.5
  },
  {
    x: 2341.5,
    y: 1146.5
  },
  {
    x: 2341.5,
    y: 1131.5
  },
  {
    x: 2341.5,
    y: 1116.5
  },
  {
    x: 2341.5,
    y: 1101.5
  },
  {
    x: 2341.5,
    y: 1086.5
  },
  {
    x: 2341.5,
    y: 1071.5
  },
  {
    x: 2341.5,
    y: 1056.5
  },
  {
    x: 2341.5,
    y: 1041.5
  },
  {
    x: 2341.5,
    y: 1026.5
  },
  {
    x: 2341.5,
    y: 1011.5
  },
  {
    x: 2341.5,
    y: 996.5
  },
  {
    x: 2341.5,
    y: 981.5
  },
  {
    x: 2341.5,
    y: 966.5
  },
  {
    x: 2341.5,
    y: 951.5
  },
  {
    x: 2341.5,
    y: 936.5
  },
  {
    x: 2341.5,
    y: 921.5
  },
  {
    x: 2341.5,
    y: 906.5
  },
  {
    x: 2341.5,
    y: 891.5
  },
  {
    x: 2341.5,
    y: 876.5
  },
  {
    x: 2341.5,
    y: 861.5
  },
  {
    x: 2341.5,
    y: 846.5
  },
  {
    x: 2341.5,
    y: 831.5
  },
  {
    x: 2341.5,
    y: 816.5
  },
  {
    x: 2341.5,
    y: 801.5
  },
  {
    x: 2341.5,
    y: 786.5
  },
  {
    x: 2341.5,
    y: 771.5
  },
  {
    x: 2341.5,
    y: 756.5
  },
  {
    x: 2341.5,
    y: 741.5
  },
  {
    x: 2341.5,
    y: 726.5
  },
  {
    x: 2341.5,
    y: 711.5
  },
  {
    x: 2341.5,
    y: 696.5
  },
  {
    x: 2341.5,
    y: 681.5
  },
  {
    x: 2341.5,
    y: 666.5
  },
  {
    x: 2341.5,
    y: 621.5
  },
  {
    x: 2341.5,
    y: 576.5
  },
  {
    x: 2341.5,
    y: 561.5
  },
  {
    x: 2341.5,
    y: 546.5
  },
  {
    x: 2341.5,
    y: 531.5
  },
  {
    x: 2341.5,
    y: 516.5
  },
  {
    x: 2341.5,
    y: 501.5
  },
  {
    x: 2341.5,
    y: 486.5
  },
  {
    x: 2341.5,
    y: 471.5
  },
  {
    x: 2341.5,
    y: 456.5
  },
  {
    x: 2341.5,
    y: 441.5
  },
  {
    x: 2341.5,
    y: 426.5
  },
  {
    x: 2341.5,
    y: 411.5
  },
  {
    x: 2341.5,
    y: 396.5
  },
  {
    x: 2341.5,
    y: 381.5
  },
  {
    x: 2341.5,
    y: 366.5
  },
  {
    x: 2341.5,
    y: 351.5
  },
  {
    x: 2341.5,
    y: 336.5
  },
  {
    x: 2341.5,
    y: 321.5
  },
  {
    x: 2341.5,
    y: 306.5
  },
  {
    x: 2341.5,
    y: 291.5
  },
  {
    x: 2341.5,
    y: 276.5
  },
  {
    x: 2341.5,
    y: 261.5
  },
  {
    x: 2341.5,
    y: 246.5
  },
  {
    x: 2341.5,
    y: 231.5
  },
  {
    x: 2341.5,
    y: 216.5
  },
  {
    x: 2341.5,
    y: 111.5
  },
  {
    x: 2356.5,
    y: 2046.5
  },
  {
    x: 2356.5,
    y: 2031.5
  },
  {
    x: 2356.5,
    y: 2016.5
  },
  {
    x: 2356.5,
    y: 2001.5
  },
  {
    x: 2356.5,
    y: 1986.5
  },
  {
    x: 2356.5,
    y: 1971.5
  },
  {
    x: 2356.5,
    y: 1956.5
  },
  {
    x: 2356.5,
    y: 1941.5
  },
  {
    x: 2356.5,
    y: 1926.5
  },
  {
    x: 2356.5,
    y: 1911.5
  },
  {
    x: 2356.5,
    y: 1896.5
  },
  {
    x: 2356.5,
    y: 1881.5
  },
  {
    x: 2356.5,
    y: 1866.5
  },
  {
    x: 2356.5,
    y: 1851.5
  },
  {
    x: 2356.5,
    y: 1836.5
  },
  {
    x: 2356.5,
    y: 1401.5
  },
  {
    x: 2356.5,
    y: 1386.5
  },
  {
    x: 2356.5,
    y: 1371.5
  },
  {
    x: 2356.5,
    y: 1356.5
  },
  {
    x: 2356.5,
    y: 1341.5
  },
  {
    x: 2356.5,
    y: 1326.5
  },
  {
    x: 2356.5,
    y: 1311.5
  },
  {
    x: 2356.5,
    y: 1296.5
  },
  {
    x: 2356.5,
    y: 1281.5
  },
  {
    x: 2356.5,
    y: 1266.5
  },
  {
    x: 2356.5,
    y: 1251.5
  },
  {
    x: 2356.5,
    y: 1236.5
  },
  {
    x: 2356.5,
    y: 1221.5
  },
  {
    x: 2356.5,
    y: 1206.5
  },
  {
    x: 2356.5,
    y: 1191.5
  },
  {
    x: 2356.5,
    y: 1176.5
  },
  {
    x: 2356.5,
    y: 1161.5
  },
  {
    x: 2356.5,
    y: 1146.5
  },
  {
    x: 2356.5,
    y: 1131.5
  },
  {
    x: 2356.5,
    y: 1116.5
  },
  {
    x: 2356.5,
    y: 1101.5
  },
  {
    x: 2356.5,
    y: 1086.5
  },
  {
    x: 2356.5,
    y: 1071.5
  },
  {
    x: 2356.5,
    y: 1056.5
  },
  {
    x: 2356.5,
    y: 1041.5
  },
  {
    x: 2356.5,
    y: 1026.5
  },
  {
    x: 2356.5,
    y: 1011.5
  },
  {
    x: 2356.5,
    y: 996.5
  },
  {
    x: 2356.5,
    y: 981.5
  },
  {
    x: 2356.5,
    y: 966.5
  },
  {
    x: 2356.5,
    y: 951.5
  },
  {
    x: 2356.5,
    y: 936.5
  },
  {
    x: 2356.5,
    y: 921.5
  },
  {
    x: 2356.5,
    y: 906.5
  },
  {
    x: 2356.5,
    y: 891.5
  },
  {
    x: 2356.5,
    y: 876.5
  },
  {
    x: 2356.5,
    y: 861.5
  },
  {
    x: 2356.5,
    y: 846.5
  },
  {
    x: 2356.5,
    y: 831.5
  },
  {
    x: 2356.5,
    y: 816.5
  },
  {
    x: 2356.5,
    y: 801.5
  },
  {
    x: 2356.5,
    y: 786.5
  },
  {
    x: 2356.5,
    y: 771.5
  },
  {
    x: 2356.5,
    y: 756.5
  },
  {
    x: 2356.5,
    y: 741.5
  },
  {
    x: 2356.5,
    y: 726.5
  },
  {
    x: 2356.5,
    y: 711.5
  },
  {
    x: 2356.5,
    y: 696.5
  },
  {
    x: 2356.5,
    y: 681.5
  },
  {
    x: 2356.5,
    y: 666.5
  },
  {
    x: 2356.5,
    y: 591.5
  },
  {
    x: 2356.5,
    y: 576.5
  },
  {
    x: 2356.5,
    y: 561.5
  },
  {
    x: 2356.5,
    y: 546.5
  },
  {
    x: 2356.5,
    y: 531.5
  },
  {
    x: 2356.5,
    y: 516.5
  },
  {
    x: 2356.5,
    y: 501.5
  },
  {
    x: 2356.5,
    y: 486.5
  },
  {
    x: 2356.5,
    y: 471.5
  },
  {
    x: 2356.5,
    y: 456.5
  },
  {
    x: 2356.5,
    y: 441.5
  },
  {
    x: 2356.5,
    y: 426.5
  },
  {
    x: 2356.5,
    y: 411.5
  },
  {
    x: 2356.5,
    y: 396.5
  },
  {
    x: 2356.5,
    y: 381.5
  },
  {
    x: 2356.5,
    y: 366.5
  },
  {
    x: 2356.5,
    y: 351.5
  },
  {
    x: 2356.5,
    y: 336.5
  },
  {
    x: 2356.5,
    y: 321.5
  },
  {
    x: 2356.5,
    y: 306.5
  },
  {
    x: 2356.5,
    y: 291.5
  },
  {
    x: 2356.5,
    y: 276.5
  },
  {
    x: 2356.5,
    y: 261.5
  },
  {
    x: 2356.5,
    y: 246.5
  },
  {
    x: 2356.5,
    y: 231.5
  },
  {
    x: 2356.5,
    y: 216.5
  },
  {
    x: 2356.5,
    y: 126.5
  },
  {
    x: 2356.5,
    y: 111.5
  },
  {
    x: 2371.5,
    y: 2046.5
  },
  {
    x: 2371.5,
    y: 2031.5
  },
  {
    x: 2371.5,
    y: 2016.5
  },
  {
    x: 2371.5,
    y: 2001.5
  },
  {
    x: 2371.5,
    y: 1986.5
  },
  {
    x: 2371.5,
    y: 1971.5
  },
  {
    x: 2371.5,
    y: 1956.5
  },
  {
    x: 2371.5,
    y: 1941.5
  },
  {
    x: 2371.5,
    y: 1926.5
  },
  {
    x: 2371.5,
    y: 1911.5
  },
  {
    x: 2371.5,
    y: 1896.5
  },
  {
    x: 2371.5,
    y: 1881.5
  },
  {
    x: 2371.5,
    y: 1866.5
  },
  {
    x: 2371.5,
    y: 1851.5
  },
  {
    x: 2371.5,
    y: 1836.5
  },
  {
    x: 2371.5,
    y: 1386.5
  },
  {
    x: 2371.5,
    y: 1371.5
  },
  {
    x: 2371.5,
    y: 1356.5
  },
  {
    x: 2371.5,
    y: 1341.5
  },
  {
    x: 2371.5,
    y: 1326.5
  },
  {
    x: 2371.5,
    y: 1311.5
  },
  {
    x: 2371.5,
    y: 1296.5
  },
  {
    x: 2371.5,
    y: 1281.5
  },
  {
    x: 2371.5,
    y: 1266.5
  },
  {
    x: 2371.5,
    y: 1251.5
  },
  {
    x: 2371.5,
    y: 1236.5
  },
  {
    x: 2371.5,
    y: 1221.5
  },
  {
    x: 2371.5,
    y: 1206.5
  },
  {
    x: 2371.5,
    y: 1191.5
  },
  {
    x: 2371.5,
    y: 1176.5
  },
  {
    x: 2371.5,
    y: 1161.5
  },
  {
    x: 2371.5,
    y: 1146.5
  },
  {
    x: 2371.5,
    y: 1131.5
  },
  {
    x: 2371.5,
    y: 1116.5
  },
  {
    x: 2371.5,
    y: 1101.5
  },
  {
    x: 2371.5,
    y: 1086.5
  },
  {
    x: 2371.5,
    y: 1071.5
  },
  {
    x: 2371.5,
    y: 1056.5
  },
  {
    x: 2371.5,
    y: 1041.5
  },
  {
    x: 2371.5,
    y: 1026.5
  },
  {
    x: 2371.5,
    y: 1011.5
  },
  {
    x: 2371.5,
    y: 996.5
  },
  {
    x: 2371.5,
    y: 981.5
  },
  {
    x: 2371.5,
    y: 966.5
  },
  {
    x: 2371.5,
    y: 951.5
  },
  {
    x: 2371.5,
    y: 936.5
  },
  {
    x: 2371.5,
    y: 921.5
  },
  {
    x: 2371.5,
    y: 906.5
  },
  {
    x: 2371.5,
    y: 891.5
  },
  {
    x: 2371.5,
    y: 876.5
  },
  {
    x: 2371.5,
    y: 861.5
  },
  {
    x: 2371.5,
    y: 846.5
  },
  {
    x: 2371.5,
    y: 831.5
  },
  {
    x: 2371.5,
    y: 816.5
  },
  {
    x: 2371.5,
    y: 801.5
  },
  {
    x: 2371.5,
    y: 786.5
  },
  {
    x: 2371.5,
    y: 771.5
  },
  {
    x: 2371.5,
    y: 756.5
  },
  {
    x: 2371.5,
    y: 741.5
  },
  {
    x: 2371.5,
    y: 726.5
  },
  {
    x: 2371.5,
    y: 711.5
  },
  {
    x: 2371.5,
    y: 696.5
  },
  {
    x: 2371.5,
    y: 681.5
  },
  {
    x: 2371.5,
    y: 666.5
  },
  {
    x: 2371.5,
    y: 606.5
  },
  {
    x: 2371.5,
    y: 591.5
  },
  {
    x: 2371.5,
    y: 576.5
  },
  {
    x: 2371.5,
    y: 561.5
  },
  {
    x: 2371.5,
    y: 516.5
  },
  {
    x: 2371.5,
    y: 501.5
  },
  {
    x: 2371.5,
    y: 486.5
  },
  {
    x: 2371.5,
    y: 471.5
  },
  {
    x: 2371.5,
    y: 456.5
  },
  {
    x: 2371.5,
    y: 441.5
  },
  {
    x: 2371.5,
    y: 426.5
  },
  {
    x: 2371.5,
    y: 411.5
  },
  {
    x: 2371.5,
    y: 396.5
  },
  {
    x: 2371.5,
    y: 381.5
  },
  {
    x: 2371.5,
    y: 366.5
  },
  {
    x: 2371.5,
    y: 351.5
  },
  {
    x: 2371.5,
    y: 336.5
  },
  {
    x: 2371.5,
    y: 321.5
  },
  {
    x: 2371.5,
    y: 306.5
  },
  {
    x: 2371.5,
    y: 291.5
  },
  {
    x: 2371.5,
    y: 276.5
  },
  {
    x: 2371.5,
    y: 261.5
  },
  {
    x: 2371.5,
    y: 246.5
  },
  {
    x: 2371.5,
    y: 231.5
  },
  {
    x: 2371.5,
    y: 216.5
  },
  {
    x: 2371.5,
    y: 126.5
  },
  {
    x: 2386.5,
    y: 2046.5
  },
  {
    x: 2386.5,
    y: 2031.5
  },
  {
    x: 2386.5,
    y: 2016.5
  },
  {
    x: 2386.5,
    y: 2001.5
  },
  {
    x: 2386.5,
    y: 1986.5
  },
  {
    x: 2386.5,
    y: 1971.5
  },
  {
    x: 2386.5,
    y: 1956.5
  },
  {
    x: 2386.5,
    y: 1941.5
  },
  {
    x: 2386.5,
    y: 1926.5
  },
  {
    x: 2386.5,
    y: 1911.5
  },
  {
    x: 2386.5,
    y: 1896.5
  },
  {
    x: 2386.5,
    y: 1881.5
  },
  {
    x: 2386.5,
    y: 1866.5
  },
  {
    x: 2386.5,
    y: 1851.5
  },
  {
    x: 2386.5,
    y: 1836.5
  },
  {
    x: 2386.5,
    y: 1371.5
  },
  {
    x: 2386.5,
    y: 1356.5
  },
  {
    x: 2386.5,
    y: 1341.5
  },
  {
    x: 2386.5,
    y: 1326.5
  },
  {
    x: 2386.5,
    y: 1311.5
  },
  {
    x: 2386.5,
    y: 1296.5
  },
  {
    x: 2386.5,
    y: 1281.5
  },
  {
    x: 2386.5,
    y: 1266.5
  },
  {
    x: 2386.5,
    y: 1251.5
  },
  {
    x: 2386.5,
    y: 1236.5
  },
  {
    x: 2386.5,
    y: 1221.5
  },
  {
    x: 2386.5,
    y: 1206.5
  },
  {
    x: 2386.5,
    y: 1191.5
  },
  {
    x: 2386.5,
    y: 1176.5
  },
  {
    x: 2386.5,
    y: 1161.5
  },
  {
    x: 2386.5,
    y: 1146.5
  },
  {
    x: 2386.5,
    y: 1131.5
  },
  {
    x: 2386.5,
    y: 1116.5
  },
  {
    x: 2386.5,
    y: 1101.5
  },
  {
    x: 2386.5,
    y: 1086.5
  },
  {
    x: 2386.5,
    y: 1071.5
  },
  {
    x: 2386.5,
    y: 1056.5
  },
  {
    x: 2386.5,
    y: 1041.5
  },
  {
    x: 2386.5,
    y: 1026.5
  },
  {
    x: 2386.5,
    y: 1011.5
  },
  {
    x: 2386.5,
    y: 996.5
  },
  {
    x: 2386.5,
    y: 981.5
  },
  {
    x: 2386.5,
    y: 966.5
  },
  {
    x: 2386.5,
    y: 951.5
  },
  {
    x: 2386.5,
    y: 936.5
  },
  {
    x: 2386.5,
    y: 921.5
  },
  {
    x: 2386.5,
    y: 906.5
  },
  {
    x: 2386.5,
    y: 891.5
  },
  {
    x: 2386.5,
    y: 876.5
  },
  {
    x: 2386.5,
    y: 861.5
  },
  {
    x: 2386.5,
    y: 846.5
  },
  {
    x: 2386.5,
    y: 831.5
  },
  {
    x: 2386.5,
    y: 816.5
  },
  {
    x: 2386.5,
    y: 801.5
  },
  {
    x: 2386.5,
    y: 786.5
  },
  {
    x: 2386.5,
    y: 771.5
  },
  {
    x: 2386.5,
    y: 756.5
  },
  {
    x: 2386.5,
    y: 741.5
  },
  {
    x: 2386.5,
    y: 726.5
  },
  {
    x: 2386.5,
    y: 711.5
  },
  {
    x: 2386.5,
    y: 696.5
  },
  {
    x: 2386.5,
    y: 681.5
  },
  {
    x: 2386.5,
    y: 666.5
  },
  {
    x: 2386.5,
    y: 606.5
  },
  {
    x: 2386.5,
    y: 591.5
  },
  {
    x: 2386.5,
    y: 576.5
  },
  {
    x: 2386.5,
    y: 561.5
  },
  {
    x: 2386.5,
    y: 501.5
  },
  {
    x: 2386.5,
    y: 486.5
  },
  {
    x: 2386.5,
    y: 471.5
  },
  {
    x: 2386.5,
    y: 456.5
  },
  {
    x: 2386.5,
    y: 441.5
  },
  {
    x: 2386.5,
    y: 426.5
  },
  {
    x: 2386.5,
    y: 411.5
  },
  {
    x: 2386.5,
    y: 396.5
  },
  {
    x: 2386.5,
    y: 381.5
  },
  {
    x: 2386.5,
    y: 366.5
  },
  {
    x: 2386.5,
    y: 351.5
  },
  {
    x: 2386.5,
    y: 336.5
  },
  {
    x: 2386.5,
    y: 321.5
  },
  {
    x: 2386.5,
    y: 306.5
  },
  {
    x: 2386.5,
    y: 291.5
  },
  {
    x: 2386.5,
    y: 276.5
  },
  {
    x: 2386.5,
    y: 261.5
  },
  {
    x: 2386.5,
    y: 246.5
  },
  {
    x: 2386.5,
    y: 231.5
  },
  {
    x: 2386.5,
    y: 216.5
  },
  {
    x: 2401.5,
    y: 2046.5
  },
  {
    x: 2401.5,
    y: 2031.5
  },
  {
    x: 2401.5,
    y: 2016.5
  },
  {
    x: 2401.5,
    y: 2001.5
  },
  {
    x: 2401.5,
    y: 1986.5
  },
  {
    x: 2401.5,
    y: 1971.5
  },
  {
    x: 2401.5,
    y: 1956.5
  },
  {
    x: 2401.5,
    y: 1941.5
  },
  {
    x: 2401.5,
    y: 1926.5
  },
  {
    x: 2401.5,
    y: 1911.5
  },
  {
    x: 2401.5,
    y: 1896.5
  },
  {
    x: 2401.5,
    y: 1881.5
  },
  {
    x: 2401.5,
    y: 1866.5
  },
  {
    x: 2401.5,
    y: 1851.5
  },
  {
    x: 2401.5,
    y: 1836.5
  },
  {
    x: 2401.5,
    y: 1356.5
  },
  {
    x: 2401.5,
    y: 1341.5
  },
  {
    x: 2401.5,
    y: 1326.5
  },
  {
    x: 2401.5,
    y: 1311.5
  },
  {
    x: 2401.5,
    y: 1296.5
  },
  {
    x: 2401.5,
    y: 1281.5
  },
  {
    x: 2401.5,
    y: 1266.5
  },
  {
    x: 2401.5,
    y: 1251.5
  },
  {
    x: 2401.5,
    y: 1236.5
  },
  {
    x: 2401.5,
    y: 1221.5
  },
  {
    x: 2401.5,
    y: 1206.5
  },
  {
    x: 2401.5,
    y: 1191.5
  },
  {
    x: 2401.5,
    y: 1176.5
  },
  {
    x: 2401.5,
    y: 1161.5
  },
  {
    x: 2401.5,
    y: 1146.5
  },
  {
    x: 2401.5,
    y: 1131.5
  },
  {
    x: 2401.5,
    y: 1116.5
  },
  {
    x: 2401.5,
    y: 1101.5
  },
  {
    x: 2401.5,
    y: 1086.5
  },
  {
    x: 2401.5,
    y: 1071.5
  },
  {
    x: 2401.5,
    y: 1056.5
  },
  {
    x: 2401.5,
    y: 1041.5
  },
  {
    x: 2401.5,
    y: 1026.5
  },
  {
    x: 2401.5,
    y: 1011.5
  },
  {
    x: 2401.5,
    y: 996.5
  },
  {
    x: 2401.5,
    y: 981.5
  },
  {
    x: 2401.5,
    y: 966.5
  },
  {
    x: 2401.5,
    y: 951.5
  },
  {
    x: 2401.5,
    y: 936.5
  },
  {
    x: 2401.5,
    y: 921.5
  },
  {
    x: 2401.5,
    y: 906.5
  },
  {
    x: 2401.5,
    y: 891.5
  },
  {
    x: 2401.5,
    y: 876.5
  },
  {
    x: 2401.5,
    y: 861.5
  },
  {
    x: 2401.5,
    y: 846.5
  },
  {
    x: 2401.5,
    y: 831.5
  },
  {
    x: 2401.5,
    y: 816.5
  },
  {
    x: 2401.5,
    y: 801.5
  },
  {
    x: 2401.5,
    y: 786.5
  },
  {
    x: 2401.5,
    y: 771.5
  },
  {
    x: 2401.5,
    y: 756.5
  },
  {
    x: 2401.5,
    y: 741.5
  },
  {
    x: 2401.5,
    y: 726.5
  },
  {
    x: 2401.5,
    y: 711.5
  },
  {
    x: 2401.5,
    y: 696.5
  },
  {
    x: 2401.5,
    y: 681.5
  },
  {
    x: 2401.5,
    y: 666.5
  },
  {
    x: 2401.5,
    y: 606.5
  },
  {
    x: 2401.5,
    y: 591.5
  },
  {
    x: 2401.5,
    y: 576.5
  },
  {
    x: 2401.5,
    y: 561.5
  },
  {
    x: 2401.5,
    y: 486.5
  },
  {
    x: 2401.5,
    y: 471.5
  },
  {
    x: 2401.5,
    y: 456.5
  },
  {
    x: 2401.5,
    y: 441.5
  },
  {
    x: 2401.5,
    y: 426.5
  },
  {
    x: 2401.5,
    y: 411.5
  },
  {
    x: 2401.5,
    y: 396.5
  },
  {
    x: 2401.5,
    y: 381.5
  },
  {
    x: 2401.5,
    y: 366.5
  },
  {
    x: 2401.5,
    y: 351.5
  },
  {
    x: 2401.5,
    y: 336.5
  },
  {
    x: 2401.5,
    y: 321.5
  },
  {
    x: 2401.5,
    y: 306.5
  },
  {
    x: 2401.5,
    y: 291.5
  },
  {
    x: 2401.5,
    y: 276.5
  },
  {
    x: 2401.5,
    y: 261.5
  },
  {
    x: 2401.5,
    y: 246.5
  },
  {
    x: 2401.5,
    y: 231.5
  },
  {
    x: 2416.5,
    y: 2046.5
  },
  {
    x: 2416.5,
    y: 2031.5
  },
  {
    x: 2416.5,
    y: 2016.5
  },
  {
    x: 2416.5,
    y: 2001.5
  },
  {
    x: 2416.5,
    y: 1986.5
  },
  {
    x: 2416.5,
    y: 1971.5
  },
  {
    x: 2416.5,
    y: 1956.5
  },
  {
    x: 2416.5,
    y: 1941.5
  },
  {
    x: 2416.5,
    y: 1926.5
  },
  {
    x: 2416.5,
    y: 1911.5
  },
  {
    x: 2416.5,
    y: 1896.5
  },
  {
    x: 2416.5,
    y: 1881.5
  },
  {
    x: 2416.5,
    y: 1866.5
  },
  {
    x: 2416.5,
    y: 1851.5
  },
  {
    x: 2416.5,
    y: 1836.5
  },
  {
    x: 2416.5,
    y: 1341.5
  },
  {
    x: 2416.5,
    y: 1326.5
  },
  {
    x: 2416.5,
    y: 1311.5
  },
  {
    x: 2416.5,
    y: 1296.5
  },
  {
    x: 2416.5,
    y: 1281.5
  },
  {
    x: 2416.5,
    y: 1266.5
  },
  {
    x: 2416.5,
    y: 1251.5
  },
  {
    x: 2416.5,
    y: 1236.5
  },
  {
    x: 2416.5,
    y: 1221.5
  },
  {
    x: 2416.5,
    y: 1206.5
  },
  {
    x: 2416.5,
    y: 1191.5
  },
  {
    x: 2416.5,
    y: 1176.5
  },
  {
    x: 2416.5,
    y: 1161.5
  },
  {
    x: 2416.5,
    y: 1146.5
  },
  {
    x: 2416.5,
    y: 1131.5
  },
  {
    x: 2416.5,
    y: 1116.5
  },
  {
    x: 2416.5,
    y: 1101.5
  },
  {
    x: 2416.5,
    y: 1086.5
  },
  {
    x: 2416.5,
    y: 1071.5
  },
  {
    x: 2416.5,
    y: 1056.5
  },
  {
    x: 2416.5,
    y: 1041.5
  },
  {
    x: 2416.5,
    y: 1026.5
  },
  {
    x: 2416.5,
    y: 1011.5
  },
  {
    x: 2416.5,
    y: 996.5
  },
  {
    x: 2416.5,
    y: 981.5
  },
  {
    x: 2416.5,
    y: 966.5
  },
  {
    x: 2416.5,
    y: 951.5
  },
  {
    x: 2416.5,
    y: 936.5
  },
  {
    x: 2416.5,
    y: 921.5
  },
  {
    x: 2416.5,
    y: 906.5
  },
  {
    x: 2416.5,
    y: 891.5
  },
  {
    x: 2416.5,
    y: 876.5
  },
  {
    x: 2416.5,
    y: 861.5
  },
  {
    x: 2416.5,
    y: 846.5
  },
  {
    x: 2416.5,
    y: 831.5
  },
  {
    x: 2416.5,
    y: 816.5
  },
  {
    x: 2416.5,
    y: 801.5
  },
  {
    x: 2416.5,
    y: 786.5
  },
  {
    x: 2416.5,
    y: 771.5
  },
  {
    x: 2416.5,
    y: 756.5
  },
  {
    x: 2416.5,
    y: 741.5
  },
  {
    x: 2416.5,
    y: 726.5
  },
  {
    x: 2416.5,
    y: 711.5
  },
  {
    x: 2416.5,
    y: 696.5
  },
  {
    x: 2416.5,
    y: 681.5
  },
  {
    x: 2416.5,
    y: 666.5
  },
  {
    x: 2416.5,
    y: 606.5
  },
  {
    x: 2416.5,
    y: 591.5
  },
  {
    x: 2416.5,
    y: 576.5
  },
  {
    x: 2416.5,
    y: 561.5
  },
  {
    x: 2416.5,
    y: 486.5
  },
  {
    x: 2416.5,
    y: 471.5
  },
  {
    x: 2416.5,
    y: 456.5
  },
  {
    x: 2416.5,
    y: 441.5
  },
  {
    x: 2416.5,
    y: 426.5
  },
  {
    x: 2416.5,
    y: 411.5
  },
  {
    x: 2416.5,
    y: 396.5
  },
  {
    x: 2416.5,
    y: 381.5
  },
  {
    x: 2416.5,
    y: 366.5
  },
  {
    x: 2416.5,
    y: 351.5
  },
  {
    x: 2416.5,
    y: 336.5
  },
  {
    x: 2416.5,
    y: 321.5
  },
  {
    x: 2416.5,
    y: 306.5
  },
  {
    x: 2416.5,
    y: 291.5
  },
  {
    x: 2416.5,
    y: 276.5
  },
  {
    x: 2416.5,
    y: 261.5
  },
  {
    x: 2416.5,
    y: 246.5
  },
  {
    x: 2416.5,
    y: 231.5
  },
  {
    x: 2431.5,
    y: 2046.5
  },
  {
    x: 2431.5,
    y: 2031.5
  },
  {
    x: 2431.5,
    y: 2016.5
  },
  {
    x: 2431.5,
    y: 2001.5
  },
  {
    x: 2431.5,
    y: 1986.5
  },
  {
    x: 2431.5,
    y: 1971.5
  },
  {
    x: 2431.5,
    y: 1956.5
  },
  {
    x: 2431.5,
    y: 1941.5
  },
  {
    x: 2431.5,
    y: 1926.5
  },
  {
    x: 2431.5,
    y: 1911.5
  },
  {
    x: 2431.5,
    y: 1896.5
  },
  {
    x: 2431.5,
    y: 1881.5
  },
  {
    x: 2431.5,
    y: 1866.5
  },
  {
    x: 2431.5,
    y: 1851.5
  },
  {
    x: 2431.5,
    y: 1836.5
  },
  {
    x: 2431.5,
    y: 1821.5
  },
  {
    x: 2431.5,
    y: 1806.5
  },
  {
    x: 2431.5,
    y: 1296.5
  },
  {
    x: 2431.5,
    y: 1281.5
  },
  {
    x: 2431.5,
    y: 1266.5
  },
  {
    x: 2431.5,
    y: 1251.5
  },
  {
    x: 2431.5,
    y: 1236.5
  },
  {
    x: 2431.5,
    y: 1221.5
  },
  {
    x: 2431.5,
    y: 1206.5
  },
  {
    x: 2431.5,
    y: 1191.5
  },
  {
    x: 2431.5,
    y: 1176.5
  },
  {
    x: 2431.5,
    y: 1161.5
  },
  {
    x: 2431.5,
    y: 1146.5
  },
  {
    x: 2431.5,
    y: 1131.5
  },
  {
    x: 2431.5,
    y: 1116.5
  },
  {
    x: 2431.5,
    y: 1101.5
  },
  {
    x: 2431.5,
    y: 1086.5
  },
  {
    x: 2431.5,
    y: 1071.5
  },
  {
    x: 2431.5,
    y: 1056.5
  },
  {
    x: 2431.5,
    y: 1041.5
  },
  {
    x: 2431.5,
    y: 1026.5
  },
  {
    x: 2431.5,
    y: 1011.5
  },
  {
    x: 2431.5,
    y: 996.5
  },
  {
    x: 2431.5,
    y: 981.5
  },
  {
    x: 2431.5,
    y: 966.5
  },
  {
    x: 2431.5,
    y: 951.5
  },
  {
    x: 2431.5,
    y: 936.5
  },
  {
    x: 2431.5,
    y: 921.5
  },
  {
    x: 2431.5,
    y: 906.5
  },
  {
    x: 2431.5,
    y: 891.5
  },
  {
    x: 2431.5,
    y: 876.5
  },
  {
    x: 2431.5,
    y: 861.5
  },
  {
    x: 2431.5,
    y: 846.5
  },
  {
    x: 2431.5,
    y: 831.5
  },
  {
    x: 2431.5,
    y: 816.5
  },
  {
    x: 2431.5,
    y: 801.5
  },
  {
    x: 2431.5,
    y: 786.5
  },
  {
    x: 2431.5,
    y: 771.5
  },
  {
    x: 2431.5,
    y: 756.5
  },
  {
    x: 2431.5,
    y: 741.5
  },
  {
    x: 2431.5,
    y: 726.5
  },
  {
    x: 2431.5,
    y: 711.5
  },
  {
    x: 2431.5,
    y: 696.5
  },
  {
    x: 2431.5,
    y: 681.5
  },
  {
    x: 2431.5,
    y: 666.5
  },
  {
    x: 2431.5,
    y: 621.5
  },
  {
    x: 2431.5,
    y: 606.5
  },
  {
    x: 2431.5,
    y: 591.5
  },
  {
    x: 2431.5,
    y: 576.5
  },
  {
    x: 2431.5,
    y: 561.5
  },
  {
    x: 2431.5,
    y: 546.5
  },
  {
    x: 2431.5,
    y: 516.5
  },
  {
    x: 2431.5,
    y: 501.5
  },
  {
    x: 2431.5,
    y: 486.5
  },
  {
    x: 2431.5,
    y: 471.5
  },
  {
    x: 2431.5,
    y: 456.5
  },
  {
    x: 2431.5,
    y: 441.5
  },
  {
    x: 2431.5,
    y: 426.5
  },
  {
    x: 2431.5,
    y: 411.5
  },
  {
    x: 2431.5,
    y: 396.5
  },
  {
    x: 2431.5,
    y: 381.5
  },
  {
    x: 2431.5,
    y: 366.5
  },
  {
    x: 2431.5,
    y: 351.5
  },
  {
    x: 2431.5,
    y: 336.5
  },
  {
    x: 2431.5,
    y: 321.5
  },
  {
    x: 2431.5,
    y: 306.5
  },
  {
    x: 2431.5,
    y: 291.5
  },
  {
    x: 2431.5,
    y: 276.5
  },
  {
    x: 2431.5,
    y: 261.5
  },
  {
    x: 2431.5,
    y: 246.5
  },
  {
    x: 2446.5,
    y: 2046.5
  },
  {
    x: 2446.5,
    y: 2031.5
  },
  {
    x: 2446.5,
    y: 2016.5
  },
  {
    x: 2446.5,
    y: 2001.5
  },
  {
    x: 2446.5,
    y: 1986.5
  },
  {
    x: 2446.5,
    y: 1971.5
  },
  {
    x: 2446.5,
    y: 1956.5
  },
  {
    x: 2446.5,
    y: 1941.5
  },
  {
    x: 2446.5,
    y: 1926.5
  },
  {
    x: 2446.5,
    y: 1911.5
  },
  {
    x: 2446.5,
    y: 1896.5
  },
  {
    x: 2446.5,
    y: 1881.5
  },
  {
    x: 2446.5,
    y: 1866.5
  },
  {
    x: 2446.5,
    y: 1851.5
  },
  {
    x: 2446.5,
    y: 1836.5
  },
  {
    x: 2446.5,
    y: 1821.5
  },
  {
    x: 2446.5,
    y: 1296.5
  },
  {
    x: 2446.5,
    y: 1281.5
  },
  {
    x: 2446.5,
    y: 1266.5
  },
  {
    x: 2446.5,
    y: 1251.5
  },
  {
    x: 2446.5,
    y: 1236.5
  },
  {
    x: 2446.5,
    y: 1221.5
  },
  {
    x: 2446.5,
    y: 1206.5
  },
  {
    x: 2446.5,
    y: 1191.5
  },
  {
    x: 2446.5,
    y: 1176.5
  },
  {
    x: 2446.5,
    y: 1161.5
  },
  {
    x: 2446.5,
    y: 1146.5
  },
  {
    x: 2446.5,
    y: 1131.5
  },
  {
    x: 2446.5,
    y: 1116.5
  },
  {
    x: 2446.5,
    y: 1101.5
  },
  {
    x: 2446.5,
    y: 1086.5
  },
  {
    x: 2446.5,
    y: 1071.5
  },
  {
    x: 2446.5,
    y: 1056.5
  },
  {
    x: 2446.5,
    y: 1041.5
  },
  {
    x: 2446.5,
    y: 1026.5
  },
  {
    x: 2446.5,
    y: 1011.5
  },
  {
    x: 2446.5,
    y: 996.5
  },
  {
    x: 2446.5,
    y: 981.5
  },
  {
    x: 2446.5,
    y: 966.5
  },
  {
    x: 2446.5,
    y: 951.5
  },
  {
    x: 2446.5,
    y: 936.5
  },
  {
    x: 2446.5,
    y: 921.5
  },
  {
    x: 2446.5,
    y: 906.5
  },
  {
    x: 2446.5,
    y: 891.5
  },
  {
    x: 2446.5,
    y: 876.5
  },
  {
    x: 2446.5,
    y: 861.5
  },
  {
    x: 2446.5,
    y: 846.5
  },
  {
    x: 2446.5,
    y: 831.5
  },
  {
    x: 2446.5,
    y: 816.5
  },
  {
    x: 2446.5,
    y: 801.5
  },
  {
    x: 2446.5,
    y: 786.5
  },
  {
    x: 2446.5,
    y: 771.5
  },
  {
    x: 2446.5,
    y: 756.5
  },
  {
    x: 2446.5,
    y: 741.5
  },
  {
    x: 2446.5,
    y: 696.5
  },
  {
    x: 2446.5,
    y: 681.5
  },
  {
    x: 2446.5,
    y: 666.5
  },
  {
    x: 2446.5,
    y: 651.5
  },
  {
    x: 2446.5,
    y: 606.5
  },
  {
    x: 2446.5,
    y: 591.5
  },
  {
    x: 2446.5,
    y: 576.5
  },
  {
    x: 2446.5,
    y: 561.5
  },
  {
    x: 2446.5,
    y: 546.5
  },
  {
    x: 2446.5,
    y: 486.5
  },
  {
    x: 2446.5,
    y: 471.5
  },
  {
    x: 2446.5,
    y: 456.5
  },
  {
    x: 2446.5,
    y: 441.5
  },
  {
    x: 2446.5,
    y: 426.5
  },
  {
    x: 2446.5,
    y: 411.5
  },
  {
    x: 2446.5,
    y: 396.5
  },
  {
    x: 2446.5,
    y: 381.5
  },
  {
    x: 2446.5,
    y: 366.5
  },
  {
    x: 2446.5,
    y: 351.5
  },
  {
    x: 2446.5,
    y: 336.5
  },
  {
    x: 2446.5,
    y: 321.5
  },
  {
    x: 2446.5,
    y: 306.5
  },
  {
    x: 2446.5,
    y: 291.5
  },
  {
    x: 2446.5,
    y: 261.5
  },
  {
    x: 2446.5,
    y: 246.5
  },
  {
    x: 2461.5,
    y: 2046.5
  },
  {
    x: 2461.5,
    y: 2031.5
  },
  {
    x: 2461.5,
    y: 2016.5
  },
  {
    x: 2461.5,
    y: 2001.5
  },
  {
    x: 2461.5,
    y: 1986.5
  },
  {
    x: 2461.5,
    y: 1971.5
  },
  {
    x: 2461.5,
    y: 1956.5
  },
  {
    x: 2461.5,
    y: 1941.5
  },
  {
    x: 2461.5,
    y: 1926.5
  },
  {
    x: 2461.5,
    y: 1911.5
  },
  {
    x: 2461.5,
    y: 1896.5
  },
  {
    x: 2461.5,
    y: 1881.5
  },
  {
    x: 2461.5,
    y: 1866.5
  },
  {
    x: 2461.5,
    y: 1851.5
  },
  {
    x: 2461.5,
    y: 1836.5
  },
  {
    x: 2461.5,
    y: 1821.5
  },
  {
    x: 2461.5,
    y: 1236.5
  },
  {
    x: 2461.5,
    y: 1221.5
  },
  {
    x: 2461.5,
    y: 1206.5
  },
  {
    x: 2461.5,
    y: 1191.5
  },
  {
    x: 2461.5,
    y: 1176.5
  },
  {
    x: 2461.5,
    y: 1161.5
  },
  {
    x: 2461.5,
    y: 1146.5
  },
  {
    x: 2461.5,
    y: 1131.5
  },
  {
    x: 2461.5,
    y: 1116.5
  },
  {
    x: 2461.5,
    y: 1101.5
  },
  {
    x: 2461.5,
    y: 1086.5
  },
  {
    x: 2461.5,
    y: 1071.5
  },
  {
    x: 2461.5,
    y: 1056.5
  },
  {
    x: 2461.5,
    y: 1041.5
  },
  {
    x: 2461.5,
    y: 1026.5
  },
  {
    x: 2461.5,
    y: 1011.5
  },
  {
    x: 2461.5,
    y: 996.5
  },
  {
    x: 2461.5,
    y: 981.5
  },
  {
    x: 2461.5,
    y: 966.5
  },
  {
    x: 2461.5,
    y: 951.5
  },
  {
    x: 2461.5,
    y: 936.5
  },
  {
    x: 2461.5,
    y: 921.5
  },
  {
    x: 2461.5,
    y: 906.5
  },
  {
    x: 2461.5,
    y: 891.5
  },
  {
    x: 2461.5,
    y: 876.5
  },
  {
    x: 2461.5,
    y: 861.5
  },
  {
    x: 2461.5,
    y: 846.5
  },
  {
    x: 2461.5,
    y: 831.5
  },
  {
    x: 2461.5,
    y: 816.5
  },
  {
    x: 2461.5,
    y: 801.5
  },
  {
    x: 2461.5,
    y: 786.5
  },
  {
    x: 2461.5,
    y: 771.5
  },
  {
    x: 2461.5,
    y: 711.5
  },
  {
    x: 2461.5,
    y: 696.5
  },
  {
    x: 2461.5,
    y: 681.5
  },
  {
    x: 2461.5,
    y: 666.5
  },
  {
    x: 2461.5,
    y: 651.5
  },
  {
    x: 2461.5,
    y: 636.5
  },
  {
    x: 2461.5,
    y: 621.5
  },
  {
    x: 2461.5,
    y: 606.5
  },
  {
    x: 2461.5,
    y: 591.5
  },
  {
    x: 2461.5,
    y: 576.5
  },
  {
    x: 2461.5,
    y: 561.5
  },
  {
    x: 2461.5,
    y: 486.5
  },
  {
    x: 2461.5,
    y: 471.5
  },
  {
    x: 2461.5,
    y: 456.5
  },
  {
    x: 2461.5,
    y: 441.5
  },
  {
    x: 2461.5,
    y: 426.5
  },
  {
    x: 2461.5,
    y: 411.5
  },
  {
    x: 2461.5,
    y: 396.5
  },
  {
    x: 2461.5,
    y: 381.5
  },
  {
    x: 2461.5,
    y: 366.5
  },
  {
    x: 2461.5,
    y: 351.5
  },
  {
    x: 2461.5,
    y: 336.5
  },
  {
    x: 2461.5,
    y: 321.5
  },
  {
    x: 2461.5,
    y: 306.5
  },
  {
    x: 2461.5,
    y: 261.5
  },
  {
    x: 2461.5,
    y: 246.5
  },
  {
    x: 2476.5,
    y: 2046.5
  },
  {
    x: 2476.5,
    y: 2031.5
  },
  {
    x: 2476.5,
    y: 2016.5
  },
  {
    x: 2476.5,
    y: 2001.5
  },
  {
    x: 2476.5,
    y: 1986.5
  },
  {
    x: 2476.5,
    y: 1971.5
  },
  {
    x: 2476.5,
    y: 1956.5
  },
  {
    x: 2476.5,
    y: 1941.5
  },
  {
    x: 2476.5,
    y: 1926.5
  },
  {
    x: 2476.5,
    y: 1911.5
  },
  {
    x: 2476.5,
    y: 1896.5
  },
  {
    x: 2476.5,
    y: 1881.5
  },
  {
    x: 2476.5,
    y: 1866.5
  },
  {
    x: 2476.5,
    y: 1851.5
  },
  {
    x: 2476.5,
    y: 1836.5
  },
  {
    x: 2476.5,
    y: 1821.5
  },
  {
    x: 2476.5,
    y: 1221.5
  },
  {
    x: 2476.5,
    y: 1206.5
  },
  {
    x: 2476.5,
    y: 1191.5
  },
  {
    x: 2476.5,
    y: 1176.5
  },
  {
    x: 2476.5,
    y: 1161.5
  },
  {
    x: 2476.5,
    y: 1146.5
  },
  {
    x: 2476.5,
    y: 1131.5
  },
  {
    x: 2476.5,
    y: 1116.5
  },
  {
    x: 2476.5,
    y: 1101.5
  },
  {
    x: 2476.5,
    y: 1086.5
  },
  {
    x: 2476.5,
    y: 1071.5
  },
  {
    x: 2476.5,
    y: 1056.5
  },
  {
    x: 2476.5,
    y: 1041.5
  },
  {
    x: 2476.5,
    y: 1026.5
  },
  {
    x: 2476.5,
    y: 1011.5
  },
  {
    x: 2476.5,
    y: 996.5
  },
  {
    x: 2476.5,
    y: 981.5
  },
  {
    x: 2476.5,
    y: 966.5
  },
  {
    x: 2476.5,
    y: 951.5
  },
  {
    x: 2476.5,
    y: 936.5
  },
  {
    x: 2476.5,
    y: 921.5
  },
  {
    x: 2476.5,
    y: 906.5
  },
  {
    x: 2476.5,
    y: 891.5
  },
  {
    x: 2476.5,
    y: 876.5
  },
  {
    x: 2476.5,
    y: 861.5
  },
  {
    x: 2476.5,
    y: 846.5
  },
  {
    x: 2476.5,
    y: 831.5
  },
  {
    x: 2476.5,
    y: 816.5
  },
  {
    x: 2476.5,
    y: 741.5
  },
  {
    x: 2476.5,
    y: 726.5
  },
  {
    x: 2476.5,
    y: 711.5
  },
  {
    x: 2476.5,
    y: 696.5
  },
  {
    x: 2476.5,
    y: 681.5
  },
  {
    x: 2476.5,
    y: 666.5
  },
  {
    x: 2476.5,
    y: 651.5
  },
  {
    x: 2476.5,
    y: 636.5
  },
  {
    x: 2476.5,
    y: 621.5
  },
  {
    x: 2476.5,
    y: 606.5
  },
  {
    x: 2476.5,
    y: 591.5
  },
  {
    x: 2476.5,
    y: 576.5
  },
  {
    x: 2476.5,
    y: 561.5
  },
  {
    x: 2476.5,
    y: 486.5
  },
  {
    x: 2476.5,
    y: 471.5
  },
  {
    x: 2476.5,
    y: 456.5
  },
  {
    x: 2476.5,
    y: 441.5
  },
  {
    x: 2476.5,
    y: 426.5
  },
  {
    x: 2476.5,
    y: 411.5
  },
  {
    x: 2476.5,
    y: 396.5
  },
  {
    x: 2476.5,
    y: 381.5
  },
  {
    x: 2476.5,
    y: 366.5
  },
  {
    x: 2476.5,
    y: 351.5
  },
  {
    x: 2476.5,
    y: 336.5
  },
  {
    x: 2476.5,
    y: 321.5
  },
  {
    x: 2476.5,
    y: 306.5
  },
  {
    x: 2476.5,
    y: 291.5
  },
  {
    x: 2476.5,
    y: 261.5
  },
  {
    x: 2476.5,
    y: 246.5
  },
  {
    x: 2491.5,
    y: 2046.5
  },
  {
    x: 2491.5,
    y: 2031.5
  },
  {
    x: 2491.5,
    y: 2016.5
  },
  {
    x: 2491.5,
    y: 2001.5
  },
  {
    x: 2491.5,
    y: 1986.5
  },
  {
    x: 2491.5,
    y: 1971.5
  },
  {
    x: 2491.5,
    y: 1956.5
  },
  {
    x: 2491.5,
    y: 1941.5
  },
  {
    x: 2491.5,
    y: 1926.5
  },
  {
    x: 2491.5,
    y: 1911.5
  },
  {
    x: 2491.5,
    y: 1896.5
  },
  {
    x: 2491.5,
    y: 1881.5
  },
  {
    x: 2491.5,
    y: 1866.5
  },
  {
    x: 2491.5,
    y: 1851.5
  },
  {
    x: 2491.5,
    y: 1836.5
  },
  {
    x: 2491.5,
    y: 1821.5
  },
  {
    x: 2491.5,
    y: 1206.5
  },
  {
    x: 2491.5,
    y: 1191.5
  },
  {
    x: 2491.5,
    y: 1176.5
  },
  {
    x: 2491.5,
    y: 1161.5
  },
  {
    x: 2491.5,
    y: 1146.5
  },
  {
    x: 2491.5,
    y: 1131.5
  },
  {
    x: 2491.5,
    y: 1116.5
  },
  {
    x: 2491.5,
    y: 1101.5
  },
  {
    x: 2491.5,
    y: 1086.5
  },
  {
    x: 2491.5,
    y: 1071.5
  },
  {
    x: 2491.5,
    y: 1056.5
  },
  {
    x: 2491.5,
    y: 1041.5
  },
  {
    x: 2491.5,
    y: 1026.5
  },
  {
    x: 2491.5,
    y: 1011.5
  },
  {
    x: 2491.5,
    y: 996.5
  },
  {
    x: 2491.5,
    y: 981.5
  },
  {
    x: 2491.5,
    y: 966.5
  },
  {
    x: 2491.5,
    y: 951.5
  },
  {
    x: 2491.5,
    y: 936.5
  },
  {
    x: 2491.5,
    y: 921.5
  },
  {
    x: 2491.5,
    y: 906.5
  },
  {
    x: 2491.5,
    y: 891.5
  },
  {
    x: 2491.5,
    y: 876.5
  },
  {
    x: 2491.5,
    y: 861.5
  },
  {
    x: 2491.5,
    y: 846.5
  },
  {
    x: 2491.5,
    y: 831.5
  },
  {
    x: 2491.5,
    y: 771.5
  },
  {
    x: 2491.5,
    y: 756.5
  },
  {
    x: 2491.5,
    y: 741.5
  },
  {
    x: 2491.5,
    y: 726.5
  },
  {
    x: 2491.5,
    y: 711.5
  },
  {
    x: 2491.5,
    y: 696.5
  },
  {
    x: 2491.5,
    y: 681.5
  },
  {
    x: 2491.5,
    y: 666.5
  },
  {
    x: 2491.5,
    y: 651.5
  },
  {
    x: 2491.5,
    y: 636.5
  },
  {
    x: 2491.5,
    y: 621.5
  },
  {
    x: 2491.5,
    y: 606.5
  },
  {
    x: 2491.5,
    y: 591.5
  },
  {
    x: 2491.5,
    y: 576.5
  },
  {
    x: 2491.5,
    y: 561.5
  },
  {
    x: 2491.5,
    y: 516.5
  },
  {
    x: 2491.5,
    y: 501.5
  },
  {
    x: 2491.5,
    y: 486.5
  },
  {
    x: 2491.5,
    y: 471.5
  },
  {
    x: 2491.5,
    y: 456.5
  },
  {
    x: 2491.5,
    y: 441.5
  },
  {
    x: 2491.5,
    y: 426.5
  },
  {
    x: 2491.5,
    y: 411.5
  },
  {
    x: 2491.5,
    y: 396.5
  },
  {
    x: 2491.5,
    y: 381.5
  },
  {
    x: 2491.5,
    y: 366.5
  },
  {
    x: 2491.5,
    y: 351.5
  },
  {
    x: 2491.5,
    y: 336.5
  },
  {
    x: 2491.5,
    y: 321.5
  },
  {
    x: 2491.5,
    y: 306.5
  },
  {
    x: 2491.5,
    y: 291.5
  },
  {
    x: 2491.5,
    y: 261.5
  },
  {
    x: 2491.5,
    y: 246.5
  },
  {
    x: 2506.5,
    y: 2046.5
  },
  {
    x: 2506.5,
    y: 2031.5
  },
  {
    x: 2506.5,
    y: 2016.5
  },
  {
    x: 2506.5,
    y: 2001.5
  },
  {
    x: 2506.5,
    y: 1986.5
  },
  {
    x: 2506.5,
    y: 1971.5
  },
  {
    x: 2506.5,
    y: 1956.5
  },
  {
    x: 2506.5,
    y: 1941.5
  },
  {
    x: 2506.5,
    y: 1926.5
  },
  {
    x: 2506.5,
    y: 1911.5
  },
  {
    x: 2506.5,
    y: 1896.5
  },
  {
    x: 2506.5,
    y: 1881.5
  },
  {
    x: 2506.5,
    y: 1866.5
  },
  {
    x: 2506.5,
    y: 1851.5
  },
  {
    x: 2506.5,
    y: 1836.5
  },
  {
    x: 2506.5,
    y: 1821.5
  },
  {
    x: 2506.5,
    y: 1806.5
  },
  {
    x: 2506.5,
    y: 1191.5
  },
  {
    x: 2506.5,
    y: 1176.5
  },
  {
    x: 2506.5,
    y: 1161.5
  },
  {
    x: 2506.5,
    y: 1146.5
  },
  {
    x: 2506.5,
    y: 1056.5
  },
  {
    x: 2506.5,
    y: 1041.5
  },
  {
    x: 2506.5,
    y: 1026.5
  },
  {
    x: 2506.5,
    y: 1011.5
  },
  {
    x: 2506.5,
    y: 996.5
  },
  {
    x: 2506.5,
    y: 981.5
  },
  {
    x: 2506.5,
    y: 966.5
  },
  {
    x: 2506.5,
    y: 951.5
  },
  {
    x: 2506.5,
    y: 936.5
  },
  {
    x: 2506.5,
    y: 921.5
  },
  {
    x: 2506.5,
    y: 906.5
  },
  {
    x: 2506.5,
    y: 891.5
  },
  {
    x: 2506.5,
    y: 876.5
  },
  {
    x: 2506.5,
    y: 861.5
  },
  {
    x: 2506.5,
    y: 786.5
  },
  {
    x: 2506.5,
    y: 771.5
  },
  {
    x: 2506.5,
    y: 756.5
  },
  {
    x: 2506.5,
    y: 741.5
  },
  {
    x: 2506.5,
    y: 726.5
  },
  {
    x: 2506.5,
    y: 711.5
  },
  {
    x: 2506.5,
    y: 696.5
  },
  {
    x: 2506.5,
    y: 681.5
  },
  {
    x: 2506.5,
    y: 666.5
  },
  {
    x: 2506.5,
    y: 651.5
  },
  {
    x: 2506.5,
    y: 636.5
  },
  {
    x: 2506.5,
    y: 621.5
  },
  {
    x: 2506.5,
    y: 606.5
  },
  {
    x: 2506.5,
    y: 591.5
  },
  {
    x: 2506.5,
    y: 576.5
  },
  {
    x: 2506.5,
    y: 561.5
  },
  {
    x: 2506.5,
    y: 531.5
  },
  {
    x: 2506.5,
    y: 516.5
  },
  {
    x: 2506.5,
    y: 501.5
  },
  {
    x: 2506.5,
    y: 486.5
  },
  {
    x: 2506.5,
    y: 471.5
  },
  {
    x: 2506.5,
    y: 456.5
  },
  {
    x: 2506.5,
    y: 441.5
  },
  {
    x: 2506.5,
    y: 426.5
  },
  {
    x: 2506.5,
    y: 411.5
  },
  {
    x: 2506.5,
    y: 396.5
  },
  {
    x: 2506.5,
    y: 381.5
  },
  {
    x: 2506.5,
    y: 366.5
  },
  {
    x: 2506.5,
    y: 351.5
  },
  {
    x: 2506.5,
    y: 336.5
  },
  {
    x: 2506.5,
    y: 321.5
  },
  {
    x: 2506.5,
    y: 306.5
  },
  {
    x: 2506.5,
    y: 291.5
  },
  {
    x: 2506.5,
    y: 276.5
  },
  {
    x: 2506.5,
    y: 261.5
  },
  {
    x: 2521.5,
    y: 2046.5
  },
  {
    x: 2521.5,
    y: 2031.5
  },
  {
    x: 2521.5,
    y: 2016.5
  },
  {
    x: 2521.5,
    y: 2001.5
  },
  {
    x: 2521.5,
    y: 1986.5
  },
  {
    x: 2521.5,
    y: 1971.5
  },
  {
    x: 2521.5,
    y: 1956.5
  },
  {
    x: 2521.5,
    y: 1941.5
  },
  {
    x: 2521.5,
    y: 1926.5
  },
  {
    x: 2521.5,
    y: 1911.5
  },
  {
    x: 2521.5,
    y: 1896.5
  },
  {
    x: 2521.5,
    y: 1881.5
  },
  {
    x: 2521.5,
    y: 1866.5
  },
  {
    x: 2521.5,
    y: 1851.5
  },
  {
    x: 2521.5,
    y: 1836.5
  },
  {
    x: 2521.5,
    y: 1821.5
  },
  {
    x: 2521.5,
    y: 1806.5
  },
  {
    x: 2521.5,
    y: 1041.5
  },
  {
    x: 2521.5,
    y: 1026.5
  },
  {
    x: 2521.5,
    y: 1011.5
  },
  {
    x: 2521.5,
    y: 996.5
  },
  {
    x: 2521.5,
    y: 981.5
  },
  {
    x: 2521.5,
    y: 966.5
  },
  {
    x: 2521.5,
    y: 951.5
  },
  {
    x: 2521.5,
    y: 936.5
  },
  {
    x: 2521.5,
    y: 921.5
  },
  {
    x: 2521.5,
    y: 906.5
  },
  {
    x: 2521.5,
    y: 891.5
  },
  {
    x: 2521.5,
    y: 876.5
  },
  {
    x: 2521.5,
    y: 816.5
  },
  {
    x: 2521.5,
    y: 801.5
  },
  {
    x: 2521.5,
    y: 786.5
  },
  {
    x: 2521.5,
    y: 771.5
  },
  {
    x: 2521.5,
    y: 756.5
  },
  {
    x: 2521.5,
    y: 741.5
  },
  {
    x: 2521.5,
    y: 726.5
  },
  {
    x: 2521.5,
    y: 711.5
  },
  {
    x: 2521.5,
    y: 696.5
  },
  {
    x: 2521.5,
    y: 681.5
  },
  {
    x: 2521.5,
    y: 666.5
  },
  {
    x: 2521.5,
    y: 651.5
  },
  {
    x: 2521.5,
    y: 636.5
  },
  {
    x: 2521.5,
    y: 621.5
  },
  {
    x: 2521.5,
    y: 606.5
  },
  {
    x: 2521.5,
    y: 591.5
  },
  {
    x: 2521.5,
    y: 576.5
  },
  {
    x: 2521.5,
    y: 561.5
  },
  {
    x: 2521.5,
    y: 531.5
  },
  {
    x: 2521.5,
    y: 516.5
  },
  {
    x: 2521.5,
    y: 501.5
  },
  {
    x: 2521.5,
    y: 486.5
  },
  {
    x: 2521.5,
    y: 471.5
  },
  {
    x: 2521.5,
    y: 456.5
  },
  {
    x: 2521.5,
    y: 441.5
  },
  {
    x: 2521.5,
    y: 426.5
  },
  {
    x: 2521.5,
    y: 411.5
  },
  {
    x: 2521.5,
    y: 396.5
  },
  {
    x: 2521.5,
    y: 381.5
  },
  {
    x: 2521.5,
    y: 366.5
  },
  {
    x: 2521.5,
    y: 351.5
  },
  {
    x: 2521.5,
    y: 336.5
  },
  {
    x: 2521.5,
    y: 321.5
  },
  {
    x: 2521.5,
    y: 306.5
  },
  {
    x: 2521.5,
    y: 291.5
  },
  {
    x: 2521.5,
    y: 276.5
  },
  {
    x: 2536.5,
    y: 2046.5
  },
  {
    x: 2536.5,
    y: 2031.5
  },
  {
    x: 2536.5,
    y: 2016.5
  },
  {
    x: 2536.5,
    y: 2001.5
  },
  {
    x: 2536.5,
    y: 1986.5
  },
  {
    x: 2536.5,
    y: 1971.5
  },
  {
    x: 2536.5,
    y: 1956.5
  },
  {
    x: 2536.5,
    y: 1941.5
  },
  {
    x: 2536.5,
    y: 1926.5
  },
  {
    x: 2536.5,
    y: 1911.5
  },
  {
    x: 2536.5,
    y: 1896.5
  },
  {
    x: 2536.5,
    y: 1881.5
  },
  {
    x: 2536.5,
    y: 1866.5
  },
  {
    x: 2536.5,
    y: 1851.5
  },
  {
    x: 2536.5,
    y: 1836.5
  },
  {
    x: 2536.5,
    y: 1821.5
  },
  {
    x: 2536.5,
    y: 1806.5
  },
  {
    x: 2536.5,
    y: 1011.5
  },
  {
    x: 2536.5,
    y: 996.5
  },
  {
    x: 2536.5,
    y: 981.5
  },
  {
    x: 2536.5,
    y: 966.5
  },
  {
    x: 2536.5,
    y: 951.5
  },
  {
    x: 2536.5,
    y: 936.5
  },
  {
    x: 2536.5,
    y: 921.5
  },
  {
    x: 2536.5,
    y: 906.5
  },
  {
    x: 2536.5,
    y: 891.5
  },
  {
    x: 2536.5,
    y: 846.5
  },
  {
    x: 2536.5,
    y: 831.5
  },
  {
    x: 2536.5,
    y: 816.5
  },
  {
    x: 2536.5,
    y: 801.5
  },
  {
    x: 2536.5,
    y: 786.5
  },
  {
    x: 2536.5,
    y: 771.5
  },
  {
    x: 2536.5,
    y: 756.5
  },
  {
    x: 2536.5,
    y: 741.5
  },
  {
    x: 2536.5,
    y: 726.5
  },
  {
    x: 2536.5,
    y: 711.5
  },
  {
    x: 2536.5,
    y: 696.5
  },
  {
    x: 2536.5,
    y: 681.5
  },
  {
    x: 2536.5,
    y: 666.5
  },
  {
    x: 2536.5,
    y: 651.5
  },
  {
    x: 2536.5,
    y: 636.5
  },
  {
    x: 2536.5,
    y: 621.5
  },
  {
    x: 2536.5,
    y: 606.5
  },
  {
    x: 2536.5,
    y: 591.5
  },
  {
    x: 2536.5,
    y: 576.5
  },
  {
    x: 2536.5,
    y: 561.5
  },
  {
    x: 2536.5,
    y: 546.5
  },
  {
    x: 2536.5,
    y: 531.5
  },
  {
    x: 2536.5,
    y: 516.5
  },
  {
    x: 2536.5,
    y: 501.5
  },
  {
    x: 2536.5,
    y: 486.5
  },
  {
    x: 2536.5,
    y: 471.5
  },
  {
    x: 2536.5,
    y: 456.5
  },
  {
    x: 2536.5,
    y: 441.5
  },
  {
    x: 2536.5,
    y: 426.5
  },
  {
    x: 2536.5,
    y: 411.5
  },
  {
    x: 2536.5,
    y: 396.5
  },
  {
    x: 2536.5,
    y: 381.5
  },
  {
    x: 2536.5,
    y: 366.5
  },
  {
    x: 2536.5,
    y: 351.5
  },
  {
    x: 2536.5,
    y: 336.5
  },
  {
    x: 2536.5,
    y: 321.5
  },
  {
    x: 2536.5,
    y: 306.5
  },
  {
    x: 2536.5,
    y: 291.5
  },
  {
    x: 2536.5,
    y: 276.5
  },
  {
    x: 2551.5,
    y: 2046.5
  },
  {
    x: 2551.5,
    y: 2031.5
  },
  {
    x: 2551.5,
    y: 2016.5
  },
  {
    x: 2551.5,
    y: 2001.5
  },
  {
    x: 2551.5,
    y: 1986.5
  },
  {
    x: 2551.5,
    y: 1971.5
  },
  {
    x: 2551.5,
    y: 1956.5
  },
  {
    x: 2551.5,
    y: 1941.5
  },
  {
    x: 2551.5,
    y: 1926.5
  },
  {
    x: 2551.5,
    y: 1911.5
  },
  {
    x: 2551.5,
    y: 1896.5
  },
  {
    x: 2551.5,
    y: 1881.5
  },
  {
    x: 2551.5,
    y: 1866.5
  },
  {
    x: 2551.5,
    y: 1851.5
  },
  {
    x: 2551.5,
    y: 1836.5
  },
  {
    x: 2551.5,
    y: 1821.5
  },
  {
    x: 2551.5,
    y: 1806.5
  },
  {
    x: 2551.5,
    y: 1311.5
  },
  {
    x: 2551.5,
    y: 1296.5
  },
  {
    x: 2551.5,
    y: 1281.5
  },
  {
    x: 2551.5,
    y: 1266.5
  },
  {
    x: 2551.5,
    y: 1251.5
  },
  {
    x: 2551.5,
    y: 1236.5
  },
  {
    x: 2551.5,
    y: 1221.5
  },
  {
    x: 2551.5,
    y: 1161.5
  },
  {
    x: 2551.5,
    y: 996.5
  },
  {
    x: 2551.5,
    y: 981.5
  },
  {
    x: 2551.5,
    y: 966.5
  },
  {
    x: 2551.5,
    y: 951.5
  },
  {
    x: 2551.5,
    y: 936.5
  },
  {
    x: 2551.5,
    y: 921.5
  },
  {
    x: 2551.5,
    y: 906.5
  },
  {
    x: 2551.5,
    y: 876.5
  },
  {
    x: 2551.5,
    y: 861.5
  },
  {
    x: 2551.5,
    y: 846.5
  },
  {
    x: 2551.5,
    y: 831.5
  },
  {
    x: 2551.5,
    y: 816.5
  },
  {
    x: 2551.5,
    y: 801.5
  },
  {
    x: 2551.5,
    y: 786.5
  },
  {
    x: 2551.5,
    y: 771.5
  },
  {
    x: 2551.5,
    y: 756.5
  },
  {
    x: 2551.5,
    y: 741.5
  },
  {
    x: 2551.5,
    y: 726.5
  },
  {
    x: 2551.5,
    y: 711.5
  },
  {
    x: 2551.5,
    y: 696.5
  },
  {
    x: 2551.5,
    y: 681.5
  },
  {
    x: 2551.5,
    y: 666.5
  },
  {
    x: 2551.5,
    y: 651.5
  },
  {
    x: 2551.5,
    y: 636.5
  },
  {
    x: 2551.5,
    y: 621.5
  },
  {
    x: 2551.5,
    y: 606.5
  },
  {
    x: 2551.5,
    y: 591.5
  },
  {
    x: 2551.5,
    y: 576.5
  },
  {
    x: 2551.5,
    y: 561.5
  },
  {
    x: 2551.5,
    y: 546.5
  },
  {
    x: 2551.5,
    y: 531.5
  },
  {
    x: 2551.5,
    y: 516.5
  },
  {
    x: 2551.5,
    y: 501.5
  },
  {
    x: 2551.5,
    y: 486.5
  },
  {
    x: 2551.5,
    y: 471.5
  },
  {
    x: 2551.5,
    y: 456.5
  },
  {
    x: 2551.5,
    y: 441.5
  },
  {
    x: 2551.5,
    y: 426.5
  },
  {
    x: 2551.5,
    y: 411.5
  },
  {
    x: 2551.5,
    y: 396.5
  },
  {
    x: 2551.5,
    y: 381.5
  },
  {
    x: 2551.5,
    y: 366.5
  },
  {
    x: 2551.5,
    y: 351.5
  },
  {
    x: 2551.5,
    y: 336.5
  },
  {
    x: 2551.5,
    y: 321.5
  },
  {
    x: 2551.5,
    y: 306.5
  },
  {
    x: 2551.5,
    y: 291.5
  },
  {
    x: 2551.5,
    y: 276.5
  },
  {
    x: 2551.5,
    y: 261.5
  },
  {
    x: 2551.5,
    y: 246.5
  },
  {
    x: 2566.5,
    y: 2046.5
  },
  {
    x: 2566.5,
    y: 2031.5
  },
  {
    x: 2566.5,
    y: 2016.5
  },
  {
    x: 2566.5,
    y: 2001.5
  },
  {
    x: 2566.5,
    y: 1986.5
  },
  {
    x: 2566.5,
    y: 1971.5
  },
  {
    x: 2566.5,
    y: 1956.5
  },
  {
    x: 2566.5,
    y: 1941.5
  },
  {
    x: 2566.5,
    y: 1926.5
  },
  {
    x: 2566.5,
    y: 1911.5
  },
  {
    x: 2566.5,
    y: 1896.5
  },
  {
    x: 2566.5,
    y: 1881.5
  },
  {
    x: 2566.5,
    y: 1866.5
  },
  {
    x: 2566.5,
    y: 1851.5
  },
  {
    x: 2566.5,
    y: 1836.5
  },
  {
    x: 2566.5,
    y: 1821.5
  },
  {
    x: 2566.5,
    y: 1806.5
  },
  {
    x: 2566.5,
    y: 1311.5
  },
  {
    x: 2566.5,
    y: 1296.5
  },
  {
    x: 2566.5,
    y: 1281.5
  },
  {
    x: 2566.5,
    y: 1266.5
  },
  {
    x: 2566.5,
    y: 1251.5
  },
  {
    x: 2566.5,
    y: 1236.5
  },
  {
    x: 2566.5,
    y: 1221.5
  },
  {
    x: 2566.5,
    y: 1206.5
  },
  {
    x: 2566.5,
    y: 996.5
  },
  {
    x: 2566.5,
    y: 981.5
  },
  {
    x: 2566.5,
    y: 966.5
  },
  {
    x: 2566.5,
    y: 951.5
  },
  {
    x: 2566.5,
    y: 936.5
  },
  {
    x: 2566.5,
    y: 921.5
  },
  {
    x: 2566.5,
    y: 906.5
  },
  {
    x: 2566.5,
    y: 861.5
  },
  {
    x: 2566.5,
    y: 846.5
  },
  {
    x: 2566.5,
    y: 831.5
  },
  {
    x: 2566.5,
    y: 816.5
  },
  {
    x: 2566.5,
    y: 801.5
  },
  {
    x: 2566.5,
    y: 786.5
  },
  {
    x: 2566.5,
    y: 771.5
  },
  {
    x: 2566.5,
    y: 756.5
  },
  {
    x: 2566.5,
    y: 741.5
  },
  {
    x: 2566.5,
    y: 726.5
  },
  {
    x: 2566.5,
    y: 711.5
  },
  {
    x: 2566.5,
    y: 696.5
  },
  {
    x: 2566.5,
    y: 681.5
  },
  {
    x: 2566.5,
    y: 666.5
  },
  {
    x: 2566.5,
    y: 651.5
  },
  {
    x: 2566.5,
    y: 636.5
  },
  {
    x: 2566.5,
    y: 621.5
  },
  {
    x: 2566.5,
    y: 606.5
  },
  {
    x: 2566.5,
    y: 591.5
  },
  {
    x: 2566.5,
    y: 576.5
  },
  {
    x: 2566.5,
    y: 561.5
  },
  {
    x: 2566.5,
    y: 546.5
  },
  {
    x: 2566.5,
    y: 531.5
  },
  {
    x: 2566.5,
    y: 516.5
  },
  {
    x: 2566.5,
    y: 501.5
  },
  {
    x: 2566.5,
    y: 486.5
  },
  {
    x: 2566.5,
    y: 471.5
  },
  {
    x: 2566.5,
    y: 456.5
  },
  {
    x: 2566.5,
    y: 441.5
  },
  {
    x: 2566.5,
    y: 426.5
  },
  {
    x: 2566.5,
    y: 411.5
  },
  {
    x: 2566.5,
    y: 396.5
  },
  {
    x: 2566.5,
    y: 381.5
  },
  {
    x: 2566.5,
    y: 366.5
  },
  {
    x: 2566.5,
    y: 351.5
  },
  {
    x: 2566.5,
    y: 336.5
  },
  {
    x: 2566.5,
    y: 321.5
  },
  {
    x: 2566.5,
    y: 306.5
  },
  {
    x: 2566.5,
    y: 291.5
  },
  {
    x: 2566.5,
    y: 276.5
  },
  {
    x: 2566.5,
    y: 261.5
  },
  {
    x: 2566.5,
    y: 246.5
  },
  {
    x: 2581.5,
    y: 2046.5
  },
  {
    x: 2581.5,
    y: 2031.5
  },
  {
    x: 2581.5,
    y: 2016.5
  },
  {
    x: 2581.5,
    y: 2001.5
  },
  {
    x: 2581.5,
    y: 1986.5
  },
  {
    x: 2581.5,
    y: 1971.5
  },
  {
    x: 2581.5,
    y: 1956.5
  },
  {
    x: 2581.5,
    y: 1941.5
  },
  {
    x: 2581.5,
    y: 1926.5
  },
  {
    x: 2581.5,
    y: 1911.5
  },
  {
    x: 2581.5,
    y: 1896.5
  },
  {
    x: 2581.5,
    y: 1881.5
  },
  {
    x: 2581.5,
    y: 1866.5
  },
  {
    x: 2581.5,
    y: 1851.5
  },
  {
    x: 2581.5,
    y: 1836.5
  },
  {
    x: 2581.5,
    y: 1821.5
  },
  {
    x: 2581.5,
    y: 1806.5
  },
  {
    x: 2581.5,
    y: 1791.5
  },
  {
    x: 2581.5,
    y: 1296.5
  },
  {
    x: 2581.5,
    y: 1281.5
  },
  {
    x: 2581.5,
    y: 1266.5
  },
  {
    x: 2581.5,
    y: 1251.5
  },
  {
    x: 2581.5,
    y: 1236.5
  },
  {
    x: 2581.5,
    y: 1221.5
  },
  {
    x: 2581.5,
    y: 1206.5
  },
  {
    x: 2581.5,
    y: 981.5
  },
  {
    x: 2581.5,
    y: 966.5
  },
  {
    x: 2581.5,
    y: 951.5
  },
  {
    x: 2581.5,
    y: 936.5
  },
  {
    x: 2581.5,
    y: 921.5
  },
  {
    x: 2581.5,
    y: 906.5
  },
  {
    x: 2581.5,
    y: 861.5
  },
  {
    x: 2581.5,
    y: 846.5
  },
  {
    x: 2581.5,
    y: 831.5
  },
  {
    x: 2581.5,
    y: 816.5
  },
  {
    x: 2581.5,
    y: 801.5
  },
  {
    x: 2581.5,
    y: 786.5
  },
  {
    x: 2581.5,
    y: 771.5
  },
  {
    x: 2581.5,
    y: 756.5
  },
  {
    x: 2581.5,
    y: 741.5
  },
  {
    x: 2581.5,
    y: 726.5
  },
  {
    x: 2581.5,
    y: 711.5
  },
  {
    x: 2581.5,
    y: 696.5
  },
  {
    x: 2581.5,
    y: 681.5
  },
  {
    x: 2581.5,
    y: 666.5
  },
  {
    x: 2581.5,
    y: 651.5
  },
  {
    x: 2581.5,
    y: 636.5
  },
  {
    x: 2581.5,
    y: 621.5
  },
  {
    x: 2581.5,
    y: 606.5
  },
  {
    x: 2581.5,
    y: 591.5
  },
  {
    x: 2581.5,
    y: 576.5
  },
  {
    x: 2581.5,
    y: 561.5
  },
  {
    x: 2581.5,
    y: 546.5
  },
  {
    x: 2581.5,
    y: 531.5
  },
  {
    x: 2581.5,
    y: 516.5
  },
  {
    x: 2581.5,
    y: 501.5
  },
  {
    x: 2581.5,
    y: 486.5
  },
  {
    x: 2581.5,
    y: 471.5
  },
  {
    x: 2581.5,
    y: 456.5
  },
  {
    x: 2581.5,
    y: 441.5
  },
  {
    x: 2581.5,
    y: 426.5
  },
  {
    x: 2581.5,
    y: 411.5
  },
  {
    x: 2581.5,
    y: 396.5
  },
  {
    x: 2581.5,
    y: 381.5
  },
  {
    x: 2581.5,
    y: 366.5
  },
  {
    x: 2581.5,
    y: 351.5
  },
  {
    x: 2581.5,
    y: 336.5
  },
  {
    x: 2581.5,
    y: 321.5
  },
  {
    x: 2581.5,
    y: 306.5
  },
  {
    x: 2581.5,
    y: 291.5
  },
  {
    x: 2581.5,
    y: 276.5
  },
  {
    x: 2581.5,
    y: 111.5
  },
  {
    x: 2596.5,
    y: 2046.5
  },
  {
    x: 2596.5,
    y: 2031.5
  },
  {
    x: 2596.5,
    y: 2016.5
  },
  {
    x: 2596.5,
    y: 2001.5
  },
  {
    x: 2596.5,
    y: 1986.5
  },
  {
    x: 2596.5,
    y: 1971.5
  },
  {
    x: 2596.5,
    y: 1956.5
  },
  {
    x: 2596.5,
    y: 1941.5
  },
  {
    x: 2596.5,
    y: 1926.5
  },
  {
    x: 2596.5,
    y: 1911.5
  },
  {
    x: 2596.5,
    y: 1896.5
  },
  {
    x: 2596.5,
    y: 1881.5
  },
  {
    x: 2596.5,
    y: 1866.5
  },
  {
    x: 2596.5,
    y: 1851.5
  },
  {
    x: 2596.5,
    y: 1836.5
  },
  {
    x: 2596.5,
    y: 1821.5
  },
  {
    x: 2596.5,
    y: 1806.5
  },
  {
    x: 2596.5,
    y: 1266.5
  },
  {
    x: 2596.5,
    y: 1251.5
  },
  {
    x: 2596.5,
    y: 1236.5
  },
  {
    x: 2596.5,
    y: 1221.5
  },
  {
    x: 2596.5,
    y: 1206.5
  },
  {
    x: 2596.5,
    y: 1191.5
  },
  {
    x: 2596.5,
    y: 1176.5
  },
  {
    x: 2596.5,
    y: 966.5
  },
  {
    x: 2596.5,
    y: 951.5
  },
  {
    x: 2596.5,
    y: 936.5
  },
  {
    x: 2596.5,
    y: 921.5
  },
  {
    x: 2596.5,
    y: 906.5
  },
  {
    x: 2596.5,
    y: 861.5
  },
  {
    x: 2596.5,
    y: 846.5
  },
  {
    x: 2596.5,
    y: 831.5
  },
  {
    x: 2596.5,
    y: 816.5
  },
  {
    x: 2596.5,
    y: 801.5
  },
  {
    x: 2596.5,
    y: 786.5
  },
  {
    x: 2596.5,
    y: 771.5
  },
  {
    x: 2596.5,
    y: 756.5
  },
  {
    x: 2596.5,
    y: 741.5
  },
  {
    x: 2596.5,
    y: 726.5
  },
  {
    x: 2596.5,
    y: 711.5
  },
  {
    x: 2596.5,
    y: 696.5
  },
  {
    x: 2596.5,
    y: 681.5
  },
  {
    x: 2596.5,
    y: 666.5
  },
  {
    x: 2596.5,
    y: 651.5
  },
  {
    x: 2596.5,
    y: 636.5
  },
  {
    x: 2596.5,
    y: 621.5
  },
  {
    x: 2596.5,
    y: 606.5
  },
  {
    x: 2596.5,
    y: 591.5
  },
  {
    x: 2596.5,
    y: 576.5
  },
  {
    x: 2596.5,
    y: 561.5
  },
  {
    x: 2596.5,
    y: 546.5
  },
  {
    x: 2596.5,
    y: 501.5
  },
  {
    x: 2596.5,
    y: 486.5
  },
  {
    x: 2596.5,
    y: 471.5
  },
  {
    x: 2596.5,
    y: 456.5
  },
  {
    x: 2596.5,
    y: 441.5
  },
  {
    x: 2596.5,
    y: 426.5
  },
  {
    x: 2596.5,
    y: 411.5
  },
  {
    x: 2596.5,
    y: 396.5
  },
  {
    x: 2596.5,
    y: 381.5
  },
  {
    x: 2596.5,
    y: 366.5
  },
  {
    x: 2596.5,
    y: 351.5
  },
  {
    x: 2596.5,
    y: 336.5
  },
  {
    x: 2596.5,
    y: 321.5
  },
  {
    x: 2596.5,
    y: 306.5
  },
  {
    x: 2596.5,
    y: 291.5
  },
  {
    x: 2596.5,
    y: 276.5
  },
  {
    x: 2596.5,
    y: 261.5
  },
  {
    x: 2596.5,
    y: 111.5
  },
  {
    x: 2611.5,
    y: 2046.5
  },
  {
    x: 2611.5,
    y: 2031.5
  },
  {
    x: 2611.5,
    y: 2016.5
  },
  {
    x: 2611.5,
    y: 2001.5
  },
  {
    x: 2611.5,
    y: 1986.5
  },
  {
    x: 2611.5,
    y: 1971.5
  },
  {
    x: 2611.5,
    y: 1956.5
  },
  {
    x: 2611.5,
    y: 1941.5
  },
  {
    x: 2611.5,
    y: 1926.5
  },
  {
    x: 2611.5,
    y: 1911.5
  },
  {
    x: 2611.5,
    y: 1896.5
  },
  {
    x: 2611.5,
    y: 1881.5
  },
  {
    x: 2611.5,
    y: 1866.5
  },
  {
    x: 2611.5,
    y: 1851.5
  },
  {
    x: 2611.5,
    y: 1836.5
  },
  {
    x: 2611.5,
    y: 1821.5
  },
  {
    x: 2611.5,
    y: 1806.5
  },
  {
    x: 2611.5,
    y: 1791.5
  },
  {
    x: 2611.5,
    y: 1221.5
  },
  {
    x: 2611.5,
    y: 1206.5
  },
  {
    x: 2611.5,
    y: 1191.5
  },
  {
    x: 2611.5,
    y: 1176.5
  },
  {
    x: 2611.5,
    y: 936.5
  },
  {
    x: 2611.5,
    y: 921.5
  },
  {
    x: 2611.5,
    y: 906.5
  },
  {
    x: 2611.5,
    y: 846.5
  },
  {
    x: 2611.5,
    y: 831.5
  },
  {
    x: 2611.5,
    y: 816.5
  },
  {
    x: 2611.5,
    y: 801.5
  },
  {
    x: 2611.5,
    y: 786.5
  },
  {
    x: 2611.5,
    y: 771.5
  },
  {
    x: 2611.5,
    y: 756.5
  },
  {
    x: 2611.5,
    y: 741.5
  },
  {
    x: 2611.5,
    y: 726.5
  },
  {
    x: 2611.5,
    y: 681.5
  },
  {
    x: 2611.5,
    y: 666.5
  },
  {
    x: 2611.5,
    y: 651.5
  },
  {
    x: 2611.5,
    y: 636.5
  },
  {
    x: 2611.5,
    y: 621.5
  },
  {
    x: 2611.5,
    y: 606.5
  },
  {
    x: 2611.5,
    y: 561.5
  },
  {
    x: 2611.5,
    y: 486.5
  },
  {
    x: 2611.5,
    y: 471.5
  },
  {
    x: 2611.5,
    y: 456.5
  },
  {
    x: 2611.5,
    y: 441.5
  },
  {
    x: 2611.5,
    y: 426.5
  },
  {
    x: 2611.5,
    y: 411.5
  },
  {
    x: 2611.5,
    y: 396.5
  },
  {
    x: 2611.5,
    y: 381.5
  },
  {
    x: 2611.5,
    y: 366.5
  },
  {
    x: 2611.5,
    y: 351.5
  },
  {
    x: 2611.5,
    y: 336.5
  },
  {
    x: 2611.5,
    y: 321.5
  },
  {
    x: 2611.5,
    y: 306.5
  },
  {
    x: 2611.5,
    y: 291.5
  },
  {
    x: 2611.5,
    y: 276.5
  },
  {
    x: 2611.5,
    y: 261.5
  },
  {
    x: 2611.5,
    y: 111.5
  },
  {
    x: 2626.5,
    y: 2046.5
  },
  {
    x: 2626.5,
    y: 2031.5
  },
  {
    x: 2626.5,
    y: 2016.5
  },
  {
    x: 2626.5,
    y: 2001.5
  },
  {
    x: 2626.5,
    y: 1986.5
  },
  {
    x: 2626.5,
    y: 1971.5
  },
  {
    x: 2626.5,
    y: 1956.5
  },
  {
    x: 2626.5,
    y: 1941.5
  },
  {
    x: 2626.5,
    y: 1926.5
  },
  {
    x: 2626.5,
    y: 1911.5
  },
  {
    x: 2626.5,
    y: 1896.5
  },
  {
    x: 2626.5,
    y: 1881.5
  },
  {
    x: 2626.5,
    y: 1866.5
  },
  {
    x: 2626.5,
    y: 1851.5
  },
  {
    x: 2626.5,
    y: 1836.5
  },
  {
    x: 2626.5,
    y: 1821.5
  },
  {
    x: 2626.5,
    y: 1806.5
  },
  {
    x: 2626.5,
    y: 1791.5
  },
  {
    x: 2626.5,
    y: 906.5
  },
  {
    x: 2626.5,
    y: 891.5
  },
  {
    x: 2626.5,
    y: 846.5
  },
  {
    x: 2626.5,
    y: 831.5
  },
  {
    x: 2626.5,
    y: 816.5
  },
  {
    x: 2626.5,
    y: 801.5
  },
  {
    x: 2626.5,
    y: 786.5
  },
  {
    x: 2626.5,
    y: 771.5
  },
  {
    x: 2626.5,
    y: 756.5
  },
  {
    x: 2626.5,
    y: 741.5
  },
  {
    x: 2626.5,
    y: 681.5
  },
  {
    x: 2626.5,
    y: 666.5
  },
  {
    x: 2626.5,
    y: 651.5
  },
  {
    x: 2626.5,
    y: 636.5
  },
  {
    x: 2626.5,
    y: 621.5
  },
  {
    x: 2626.5,
    y: 606.5
  },
  {
    x: 2626.5,
    y: 516.5
  },
  {
    x: 2626.5,
    y: 486.5
  },
  {
    x: 2626.5,
    y: 471.5
  },
  {
    x: 2626.5,
    y: 456.5
  },
  {
    x: 2626.5,
    y: 441.5
  },
  {
    x: 2626.5,
    y: 426.5
  },
  {
    x: 2626.5,
    y: 411.5
  },
  {
    x: 2626.5,
    y: 396.5
  },
  {
    x: 2626.5,
    y: 381.5
  },
  {
    x: 2626.5,
    y: 366.5
  },
  {
    x: 2626.5,
    y: 351.5
  },
  {
    x: 2626.5,
    y: 336.5
  },
  {
    x: 2626.5,
    y: 321.5
  },
  {
    x: 2626.5,
    y: 306.5
  },
  {
    x: 2626.5,
    y: 291.5
  },
  {
    x: 2626.5,
    y: 276.5
  },
  {
    x: 2626.5,
    y: 261.5
  },
  {
    x: 2626.5,
    y: 246.5
  },
  {
    x: 2626.5,
    y: 111.5
  },
  {
    x: 2641.5,
    y: 2046.5
  },
  {
    x: 2641.5,
    y: 2031.5
  },
  {
    x: 2641.5,
    y: 2016.5
  },
  {
    x: 2641.5,
    y: 2001.5
  },
  {
    x: 2641.5,
    y: 1986.5
  },
  {
    x: 2641.5,
    y: 1971.5
  },
  {
    x: 2641.5,
    y: 1956.5
  },
  {
    x: 2641.5,
    y: 1941.5
  },
  {
    x: 2641.5,
    y: 1926.5
  },
  {
    x: 2641.5,
    y: 1911.5
  },
  {
    x: 2641.5,
    y: 1896.5
  },
  {
    x: 2641.5,
    y: 1881.5
  },
  {
    x: 2641.5,
    y: 1866.5
  },
  {
    x: 2641.5,
    y: 1851.5
  },
  {
    x: 2641.5,
    y: 1836.5
  },
  {
    x: 2641.5,
    y: 1821.5
  },
  {
    x: 2641.5,
    y: 1806.5
  },
  {
    x: 2641.5,
    y: 1791.5
  },
  {
    x: 2641.5,
    y: 1776.5
  },
  {
    x: 2641.5,
    y: 846.5
  },
  {
    x: 2641.5,
    y: 831.5
  },
  {
    x: 2641.5,
    y: 816.5
  },
  {
    x: 2641.5,
    y: 801.5
  },
  {
    x: 2641.5,
    y: 786.5
  },
  {
    x: 2641.5,
    y: 771.5
  },
  {
    x: 2641.5,
    y: 756.5
  },
  {
    x: 2641.5,
    y: 696.5
  },
  {
    x: 2641.5,
    y: 681.5
  },
  {
    x: 2641.5,
    y: 666.5
  },
  {
    x: 2641.5,
    y: 651.5
  },
  {
    x: 2641.5,
    y: 636.5
  },
  {
    x: 2641.5,
    y: 621.5
  },
  {
    x: 2641.5,
    y: 606.5
  },
  {
    x: 2641.5,
    y: 531.5
  },
  {
    x: 2641.5,
    y: 516.5
  },
  {
    x: 2641.5,
    y: 486.5
  },
  {
    x: 2641.5,
    y: 471.5
  },
  {
    x: 2641.5,
    y: 456.5
  },
  {
    x: 2641.5,
    y: 441.5
  },
  {
    x: 2641.5,
    y: 426.5
  },
  {
    x: 2641.5,
    y: 411.5
  },
  {
    x: 2641.5,
    y: 396.5
  },
  {
    x: 2641.5,
    y: 381.5
  },
  {
    x: 2641.5,
    y: 366.5
  },
  {
    x: 2641.5,
    y: 351.5
  },
  {
    x: 2641.5,
    y: 336.5
  },
  {
    x: 2641.5,
    y: 321.5
  },
  {
    x: 2641.5,
    y: 306.5
  },
  {
    x: 2641.5,
    y: 291.5
  },
  {
    x: 2641.5,
    y: 276.5
  },
  {
    x: 2641.5,
    y: 261.5
  },
  {
    x: 2641.5,
    y: 246.5
  },
  {
    x: 2641.5,
    y: 201.5
  },
  {
    x: 2641.5,
    y: 111.5
  },
  {
    x: 2656.5,
    y: 2046.5
  },
  {
    x: 2656.5,
    y: 2031.5
  },
  {
    x: 2656.5,
    y: 2016.5
  },
  {
    x: 2656.5,
    y: 2001.5
  },
  {
    x: 2656.5,
    y: 1986.5
  },
  {
    x: 2656.5,
    y: 1971.5
  },
  {
    x: 2656.5,
    y: 1956.5
  },
  {
    x: 2656.5,
    y: 1941.5
  },
  {
    x: 2656.5,
    y: 1926.5
  },
  {
    x: 2656.5,
    y: 1911.5
  },
  {
    x: 2656.5,
    y: 1896.5
  },
  {
    x: 2656.5,
    y: 1881.5
  },
  {
    x: 2656.5,
    y: 1866.5
  },
  {
    x: 2656.5,
    y: 1851.5
  },
  {
    x: 2656.5,
    y: 1836.5
  },
  {
    x: 2656.5,
    y: 1821.5
  },
  {
    x: 2656.5,
    y: 1806.5
  },
  {
    x: 2656.5,
    y: 1791.5
  },
  {
    x: 2656.5,
    y: 1776.5
  },
  {
    x: 2656.5,
    y: 831.5
  },
  {
    x: 2656.5,
    y: 876.5
  },
  {
    x: 2656.5,
    y: 816.5
  },
  {
    x: 2656.5,
    y: 801.5
  },
  {
    x: 2656.5,
    y: 786.5
  },
  {
    x: 2656.5,
    y: 771.5
  },
  {
    x: 2656.5,
    y: 756.5
  },
  {
    x: 2656.5,
    y: 711.5
  },
  {
    x: 2656.5,
    y: 696.5
  },
  {
    x: 2656.5,
    y: 681.5
  },
  {
    x: 2656.5,
    y: 666.5
  },
  {
    x: 2656.5,
    y: 651.5
  },
  {
    x: 2656.5,
    y: 636.5
  },
  {
    x: 2656.5,
    y: 621.5
  },
  {
    x: 2656.5,
    y: 606.5
  },
  {
    x: 2656.5,
    y: 576.5
  },
  {
    x: 2656.5,
    y: 561.5
  },
  {
    x: 2656.5,
    y: 531.5
  },
  {
    x: 2656.5,
    y: 516.5
  },
  {
    x: 2656.5,
    y: 501.5
  },
  {
    x: 2656.5,
    y: 486.5
  },
  {
    x: 2656.5,
    y: 471.5
  },
  {
    x: 2656.5,
    y: 456.5
  },
  {
    x: 2656.5,
    y: 441.5
  },
  {
    x: 2656.5,
    y: 426.5
  },
  {
    x: 2656.5,
    y: 411.5
  },
  {
    x: 2656.5,
    y: 396.5
  },
  {
    x: 2656.5,
    y: 381.5
  },
  {
    x: 2656.5,
    y: 366.5
  },
  {
    x: 2656.5,
    y: 351.5
  },
  {
    x: 2656.5,
    y: 336.5
  },
  {
    x: 2656.5,
    y: 321.5
  },
  {
    x: 2656.5,
    y: 306.5
  },
  {
    x: 2656.5,
    y: 291.5
  },
  {
    x: 2656.5,
    y: 276.5
  },
  {
    x: 2656.5,
    y: 261.5
  },
  {
    x: 2656.5,
    y: 246.5
  },
  {
    x: 2656.5,
    y: 216.5
  },
  {
    x: 2656.5,
    y: 201.5
  },
  {
    x: 2656.5,
    y: 111.5
  },
  {
    x: 2671.5,
    y: 2046.5
  },
  {
    x: 2671.5,
    y: 2031.5
  },
  {
    x: 2671.5,
    y: 2016.5
  },
  {
    x: 2671.5,
    y: 2001.5
  },
  {
    x: 2671.5,
    y: 1986.5
  },
  {
    x: 2671.5,
    y: 1971.5
  },
  {
    x: 2671.5,
    y: 1956.5
  },
  {
    x: 2671.5,
    y: 1941.5
  },
  {
    x: 2671.5,
    y: 1926.5
  },
  {
    x: 2671.5,
    y: 1911.5
  },
  {
    x: 2671.5,
    y: 1896.5
  },
  {
    x: 2671.5,
    y: 1881.5
  },
  {
    x: 2671.5,
    y: 1866.5
  },
  {
    x: 2671.5,
    y: 1851.5
  },
  {
    x: 2671.5,
    y: 1836.5
  },
  {
    x: 2671.5,
    y: 1821.5
  },
  {
    x: 2671.5,
    y: 1806.5
  },
  {
    x: 2671.5,
    y: 1791.5
  },
  {
    x: 2671.5,
    y: 1776.5
  },
  {
    x: 2671.5,
    y: 831.5
  },
  {
    x: 2671.5,
    y: 816.5
  },
  {
    x: 2671.5,
    y: 801.5
  },
  {
    x: 2671.5,
    y: 786.5
  },
  {
    x: 2671.5,
    y: 771.5
  },
  {
    x: 2671.5,
    y: 756.5
  },
  {
    x: 2671.5,
    y: 741.5
  },
  {
    x: 2671.5,
    y: 711.5
  },
  {
    x: 2671.5,
    y: 696.5
  },
  {
    x: 2671.5,
    y: 681.5
  },
  {
    x: 2671.5,
    y: 666.5
  },
  {
    x: 2671.5,
    y: 651.5
  },
  {
    x: 2671.5,
    y: 636.5
  },
  {
    x: 2671.5,
    y: 621.5
  },
  {
    x: 2671.5,
    y: 606.5
  },
  {
    x: 2671.5,
    y: 591.5
  },
  {
    x: 2671.5,
    y: 576.5
  },
  {
    x: 2671.5,
    y: 561.5
  },
  {
    x: 2671.5,
    y: 546.5
  },
  {
    x: 2671.5,
    y: 531.5
  },
  {
    x: 2671.5,
    y: 516.5
  },
  {
    x: 2671.5,
    y: 501.5
  },
  {
    x: 2671.5,
    y: 486.5
  },
  {
    x: 2671.5,
    y: 471.5
  },
  {
    x: 2671.5,
    y: 456.5
  },
  {
    x: 2671.5,
    y: 441.5
  },
  {
    x: 2671.5,
    y: 426.5
  },
  {
    x: 2671.5,
    y: 411.5
  },
  {
    x: 2671.5,
    y: 396.5
  },
  {
    x: 2671.5,
    y: 381.5
  },
  {
    x: 2671.5,
    y: 366.5
  },
  {
    x: 2671.5,
    y: 351.5
  },
  {
    x: 2671.5,
    y: 336.5
  },
  {
    x: 2671.5,
    y: 321.5
  },
  {
    x: 2671.5,
    y: 306.5
  },
  {
    x: 2671.5,
    y: 291.5
  },
  {
    x: 2671.5,
    y: 276.5
  },
  {
    x: 2671.5,
    y: 261.5
  },
  {
    x: 2671.5,
    y: 246.5
  },
  {
    x: 2671.5,
    y: 216.5
  },
  {
    x: 2671.5,
    y: 201.5
  },
  {
    x: 2671.5,
    y: 186.5
  },
  {
    x: 2671.5,
    y: 111.5
  },
  {
    x: 2686.5,
    y: 2046.5
  },
  {
    x: 2686.5,
    y: 2031.5
  },
  {
    x: 2686.5,
    y: 2016.5
  },
  {
    x: 2686.5,
    y: 2001.5
  },
  {
    x: 2686.5,
    y: 1986.5
  },
  {
    x: 2686.5,
    y: 1971.5
  },
  {
    x: 2686.5,
    y: 1956.5
  },
  {
    x: 2686.5,
    y: 1941.5
  },
  {
    x: 2686.5,
    y: 1926.5
  },
  {
    x: 2686.5,
    y: 1911.5
  },
  {
    x: 2686.5,
    y: 1896.5
  },
  {
    x: 2686.5,
    y: 1881.5
  },
  {
    x: 2686.5,
    y: 1866.5
  },
  {
    x: 2686.5,
    y: 1851.5
  },
  {
    x: 2686.5,
    y: 1836.5
  },
  {
    x: 2686.5,
    y: 1821.5
  },
  {
    x: 2686.5,
    y: 1806.5
  },
  {
    x: 2686.5,
    y: 1791.5
  },
  {
    x: 2686.5,
    y: 816.5
  },
  {
    x: 2686.5,
    y: 801.5
  },
  {
    x: 2686.5,
    y: 786.5
  },
  {
    x: 2686.5,
    y: 771.5
  },
  {
    x: 2686.5,
    y: 756.5
  },
  {
    x: 2686.5,
    y: 741.5
  },
  {
    x: 2686.5,
    y: 726.5
  },
  {
    x: 2686.5,
    y: 711.5
  },
  {
    x: 2686.5,
    y: 696.5
  },
  {
    x: 2686.5,
    y: 681.5
  },
  {
    x: 2686.5,
    y: 666.5
  },
  {
    x: 2686.5,
    y: 651.5
  },
  {
    x: 2686.5,
    y: 636.5
  },
  {
    x: 2686.5,
    y: 621.5
  },
  {
    x: 2686.5,
    y: 606.5
  },
  {
    x: 2686.5,
    y: 591.5
  },
  {
    x: 2686.5,
    y: 576.5
  },
  {
    x: 2686.5,
    y: 561.5
  },
  {
    x: 2686.5,
    y: 546.5
  },
  {
    x: 2686.5,
    y: 531.5
  },
  {
    x: 2686.5,
    y: 516.5
  },
  {
    x: 2686.5,
    y: 501.5
  },
  {
    x: 2686.5,
    y: 486.5
  },
  {
    x: 2686.5,
    y: 471.5
  },
  {
    x: 2686.5,
    y: 456.5
  },
  {
    x: 2686.5,
    y: 441.5
  },
  {
    x: 2686.5,
    y: 426.5
  },
  {
    x: 2686.5,
    y: 411.5
  },
  {
    x: 2686.5,
    y: 396.5
  },
  {
    x: 2686.5,
    y: 381.5
  },
  {
    x: 2686.5,
    y: 366.5
  },
  {
    x: 2686.5,
    y: 351.5
  },
  {
    x: 2686.5,
    y: 336.5
  },
  {
    x: 2686.5,
    y: 321.5
  },
  {
    x: 2686.5,
    y: 306.5
  },
  {
    x: 2686.5,
    y: 291.5
  },
  {
    x: 2686.5,
    y: 276.5
  },
  {
    x: 2686.5,
    y: 261.5
  },
  {
    x: 2686.5,
    y: 246.5
  },
  {
    x: 2686.5,
    y: 216.5
  },
  {
    x: 2686.5,
    y: 186.5
  },
  {
    x: 2686.5,
    y: 171.5
  },
  {
    x: 2686.5,
    y: 111.5
  },
  {
    x: 2686.5,
    y: 96.5
  },
  {
    x: 2701.5,
    y: 2046.5
  },
  {
    x: 2701.5,
    y: 2031.5
  },
  {
    x: 2701.5,
    y: 2016.5
  },
  {
    x: 2701.5,
    y: 2001.5
  },
  {
    x: 2701.5,
    y: 1986.5
  },
  {
    x: 2701.5,
    y: 1971.5
  },
  {
    x: 2701.5,
    y: 1956.5
  },
  {
    x: 2701.5,
    y: 1941.5
  },
  {
    x: 2701.5,
    y: 1926.5
  },
  {
    x: 2701.5,
    y: 1911.5
  },
  {
    x: 2701.5,
    y: 1896.5
  },
  {
    x: 2701.5,
    y: 1881.5
  },
  {
    x: 2701.5,
    y: 1866.5
  },
  {
    x: 2701.5,
    y: 1851.5
  },
  {
    x: 2701.5,
    y: 1836.5
  },
  {
    x: 2701.5,
    y: 1821.5
  },
  {
    x: 2701.5,
    y: 1806.5
  },
  {
    x: 2701.5,
    y: 1791.5
  },
  {
    x: 2701.5,
    y: 801.5
  },
  {
    x: 2701.5,
    y: 786.5
  },
  {
    x: 2701.5,
    y: 771.5
  },
  {
    x: 2701.5,
    y: 756.5
  },
  {
    x: 2701.5,
    y: 726.5
  },
  {
    x: 2701.5,
    y: 711.5
  },
  {
    x: 2701.5,
    y: 696.5
  },
  {
    x: 2701.5,
    y: 681.5
  },
  {
    x: 2701.5,
    y: 666.5
  },
  {
    x: 2701.5,
    y: 651.5
  },
  {
    x: 2701.5,
    y: 636.5
  },
  {
    x: 2701.5,
    y: 621.5
  },
  {
    x: 2701.5,
    y: 606.5
  },
  {
    x: 2701.5,
    y: 591.5
  },
  {
    x: 2701.5,
    y: 576.5
  },
  {
    x: 2701.5,
    y: 561.5
  },
  {
    x: 2701.5,
    y: 546.5
  },
  {
    x: 2701.5,
    y: 531.5
  },
  {
    x: 2701.5,
    y: 516.5
  },
  {
    x: 2701.5,
    y: 501.5
  },
  {
    x: 2701.5,
    y: 486.5
  },
  {
    x: 2701.5,
    y: 471.5
  },
  {
    x: 2701.5,
    y: 456.5
  },
  {
    x: 2701.5,
    y: 441.5
  },
  {
    x: 2701.5,
    y: 426.5
  },
  {
    x: 2701.5,
    y: 411.5
  },
  {
    x: 2701.5,
    y: 396.5
  },
  {
    x: 2701.5,
    y: 381.5
  },
  {
    x: 2701.5,
    y: 366.5
  },
  {
    x: 2701.5,
    y: 351.5
  },
  {
    x: 2701.5,
    y: 336.5
  },
  {
    x: 2701.5,
    y: 321.5
  },
  {
    x: 2701.5,
    y: 306.5
  },
  {
    x: 2701.5,
    y: 291.5
  },
  {
    x: 2701.5,
    y: 276.5
  },
  {
    x: 2701.5,
    y: 261.5
  },
  {
    x: 2701.5,
    y: 246.5
  },
  {
    x: 2701.5,
    y: 186.5
  },
  {
    x: 2701.5,
    y: 171.5
  },
  {
    x: 2701.5,
    y: 111.5
  },
  {
    x: 2701.5,
    y: 96.5
  },
  {
    x: 2716.5,
    y: 2046.5
  },
  {
    x: 2716.5,
    y: 2031.5
  },
  {
    x: 2716.5,
    y: 2016.5
  },
  {
    x: 2716.5,
    y: 2001.5
  },
  {
    x: 2716.5,
    y: 1986.5
  },
  {
    x: 2716.5,
    y: 1971.5
  },
  {
    x: 2716.5,
    y: 1956.5
  },
  {
    x: 2716.5,
    y: 1941.5
  },
  {
    x: 2716.5,
    y: 1926.5
  },
  {
    x: 2716.5,
    y: 1911.5
  },
  {
    x: 2716.5,
    y: 1896.5
  },
  {
    x: 2716.5,
    y: 1881.5
  },
  {
    x: 2716.5,
    y: 1866.5
  },
  {
    x: 2716.5,
    y: 1851.5
  },
  {
    x: 2716.5,
    y: 1836.5
  },
  {
    x: 2716.5,
    y: 1821.5
  },
  {
    x: 2716.5,
    y: 1806.5
  },
  {
    x: 2716.5,
    y: 1791.5
  },
  {
    x: 2716.5,
    y: 786.5
  },
  {
    x: 2716.5,
    y: 771.5
  },
  {
    x: 2716.5,
    y: 756.5
  },
  {
    x: 2716.5,
    y: 726.5
  },
  {
    x: 2716.5,
    y: 711.5
  },
  {
    x: 2716.5,
    y: 696.5
  },
  {
    x: 2716.5,
    y: 681.5
  },
  {
    x: 2716.5,
    y: 666.5
  },
  {
    x: 2716.5,
    y: 651.5
  },
  {
    x: 2716.5,
    y: 636.5
  },
  {
    x: 2716.5,
    y: 621.5
  },
  {
    x: 2716.5,
    y: 606.5
  },
  {
    x: 2716.5,
    y: 591.5
  },
  {
    x: 2716.5,
    y: 576.5
  },
  {
    x: 2716.5,
    y: 561.5
  },
  {
    x: 2716.5,
    y: 546.5
  },
  {
    x: 2716.5,
    y: 531.5
  },
  {
    x: 2716.5,
    y: 516.5
  },
  {
    x: 2716.5,
    y: 501.5
  },
  {
    x: 2716.5,
    y: 486.5
  },
  {
    x: 2716.5,
    y: 471.5
  },
  {
    x: 2716.5,
    y: 456.5
  },
  {
    x: 2716.5,
    y: 441.5
  },
  {
    x: 2716.5,
    y: 426.5
  },
  {
    x: 2716.5,
    y: 411.5
  },
  {
    x: 2716.5,
    y: 396.5
  },
  {
    x: 2716.5,
    y: 381.5
  },
  {
    x: 2716.5,
    y: 366.5
  },
  {
    x: 2716.5,
    y: 351.5
  },
  {
    x: 2716.5,
    y: 336.5
  },
  {
    x: 2716.5,
    y: 321.5
  },
  {
    x: 2716.5,
    y: 306.5
  },
  {
    x: 2716.5,
    y: 291.5
  },
  {
    x: 2716.5,
    y: 276.5
  },
  {
    x: 2716.5,
    y: 261.5
  },
  {
    x: 2716.5,
    y: 246.5
  },
  {
    x: 2716.5,
    y: 171.5
  },
  {
    x: 2716.5,
    y: 96.5
  },
  {
    x: 2731.5,
    y: 2046.5
  },
  {
    x: 2731.5,
    y: 2031.5
  },
  {
    x: 2731.5,
    y: 2016.5
  },
  {
    x: 2731.5,
    y: 2001.5
  },
  {
    x: 2731.5,
    y: 1986.5
  },
  {
    x: 2731.5,
    y: 1971.5
  },
  {
    x: 2731.5,
    y: 1956.5
  },
  {
    x: 2731.5,
    y: 1941.5
  },
  {
    x: 2731.5,
    y: 1926.5
  },
  {
    x: 2731.5,
    y: 1911.5
  },
  {
    x: 2731.5,
    y: 1896.5
  },
  {
    x: 2731.5,
    y: 1881.5
  },
  {
    x: 2731.5,
    y: 1866.5
  },
  {
    x: 2731.5,
    y: 1851.5
  },
  {
    x: 2731.5,
    y: 1836.5
  },
  {
    x: 2731.5,
    y: 1821.5
  },
  {
    x: 2731.5,
    y: 1806.5
  },
  {
    x: 2731.5,
    y: 1791.5
  },
  {
    x: 2731.5,
    y: 726.5
  },
  {
    x: 2731.5,
    y: 711.5
  },
  {
    x: 2731.5,
    y: 696.5
  },
  {
    x: 2731.5,
    y: 681.5
  },
  {
    x: 2731.5,
    y: 666.5
  },
  {
    x: 2731.5,
    y: 651.5
  },
  {
    x: 2731.5,
    y: 636.5
  },
  {
    x: 2731.5,
    y: 621.5
  },
  {
    x: 2731.5,
    y: 606.5
  },
  {
    x: 2731.5,
    y: 591.5
  },
  {
    x: 2731.5,
    y: 576.5
  },
  {
    x: 2731.5,
    y: 561.5
  },
  {
    x: 2731.5,
    y: 546.5
  },
  {
    x: 2731.5,
    y: 531.5
  },
  {
    x: 2731.5,
    y: 516.5
  },
  {
    x: 2731.5,
    y: 501.5
  },
  {
    x: 2731.5,
    y: 486.5
  },
  {
    x: 2731.5,
    y: 471.5
  },
  {
    x: 2731.5,
    y: 456.5
  },
  {
    x: 2731.5,
    y: 441.5
  },
  {
    x: 2731.5,
    y: 426.5
  },
  {
    x: 2731.5,
    y: 411.5
  },
  {
    x: 2731.5,
    y: 396.5
  },
  {
    x: 2731.5,
    y: 381.5
  },
  {
    x: 2731.5,
    y: 366.5
  },
  {
    x: 2731.5,
    y: 351.5
  },
  {
    x: 2731.5,
    y: 336.5
  },
  {
    x: 2731.5,
    y: 321.5
  },
  {
    x: 2731.5,
    y: 306.5
  },
  {
    x: 2731.5,
    y: 291.5
  },
  {
    x: 2731.5,
    y: 276.5
  },
  {
    x: 2731.5,
    y: 261.5
  },
  {
    x: 2731.5,
    y: 246.5
  },
  {
    x: 2731.5,
    y: 231.5
  },
  {
    x: 2731.5,
    y: 171.5
  },
  {
    x: 2746.5,
    y: 2046.5
  },
  {
    x: 2746.5,
    y: 2031.5
  },
  {
    x: 2746.5,
    y: 2016.5
  },
  {
    x: 2746.5,
    y: 2001.5
  },
  {
    x: 2746.5,
    y: 1986.5
  },
  {
    x: 2746.5,
    y: 1971.5
  },
  {
    x: 2746.5,
    y: 1956.5
  },
  {
    x: 2746.5,
    y: 1941.5
  },
  {
    x: 2746.5,
    y: 1926.5
  },
  {
    x: 2746.5,
    y: 1911.5
  },
  {
    x: 2746.5,
    y: 1896.5
  },
  {
    x: 2746.5,
    y: 1881.5
  },
  {
    x: 2746.5,
    y: 1866.5
  },
  {
    x: 2746.5,
    y: 1851.5
  },
  {
    x: 2746.5,
    y: 1836.5
  },
  {
    x: 2746.5,
    y: 1821.5
  },
  {
    x: 2746.5,
    y: 1806.5
  },
  {
    x: 2746.5,
    y: 1791.5
  },
  {
    x: 2746.5,
    y: 726.5
  },
  {
    x: 2746.5,
    y: 711.5
  },
  {
    x: 2746.5,
    y: 696.5
  },
  {
    x: 2746.5,
    y: 681.5
  },
  {
    x: 2746.5,
    y: 666.5
  },
  {
    x: 2746.5,
    y: 651.5
  },
  {
    x: 2746.5,
    y: 636.5
  },
  {
    x: 2746.5,
    y: 621.5
  },
  {
    x: 2746.5,
    y: 606.5
  },
  {
    x: 2746.5,
    y: 591.5
  },
  {
    x: 2746.5,
    y: 576.5
  },
  {
    x: 2746.5,
    y: 561.5
  },
  {
    x: 2746.5,
    y: 546.5
  },
  {
    x: 2746.5,
    y: 531.5
  },
  {
    x: 2746.5,
    y: 516.5
  },
  {
    x: 2746.5,
    y: 501.5
  },
  {
    x: 2746.5,
    y: 486.5
  },
  {
    x: 2746.5,
    y: 471.5
  },
  {
    x: 2746.5,
    y: 456.5
  },
  {
    x: 2746.5,
    y: 441.5
  },
  {
    x: 2746.5,
    y: 426.5
  },
  {
    x: 2746.5,
    y: 411.5
  },
  {
    x: 2746.5,
    y: 396.5
  },
  {
    x: 2746.5,
    y: 381.5
  },
  {
    x: 2746.5,
    y: 366.5
  },
  {
    x: 2746.5,
    y: 351.5
  },
  {
    x: 2746.5,
    y: 336.5
  },
  {
    x: 2746.5,
    y: 321.5
  },
  {
    x: 2746.5,
    y: 306.5
  },
  {
    x: 2746.5,
    y: 291.5
  },
  {
    x: 2746.5,
    y: 276.5
  },
  {
    x: 2746.5,
    y: 261.5
  },
  {
    x: 2746.5,
    y: 246.5
  },
  {
    x: 2746.5,
    y: 231.5
  },
  {
    x: 2746.5,
    y: 156.5
  },
  {
    x: 2761.5,
    y: 2046.5
  },
  {
    x: 2761.5,
    y: 2031.5
  },
  {
    x: 2761.5,
    y: 2016.5
  },
  {
    x: 2761.5,
    y: 2001.5
  },
  {
    x: 2761.5,
    y: 1986.5
  },
  {
    x: 2761.5,
    y: 1971.5
  },
  {
    x: 2761.5,
    y: 1956.5
  },
  {
    x: 2761.5,
    y: 1941.5
  },
  {
    x: 2761.5,
    y: 1926.5
  },
  {
    x: 2761.5,
    y: 1911.5
  },
  {
    x: 2761.5,
    y: 1896.5
  },
  {
    x: 2761.5,
    y: 1881.5
  },
  {
    x: 2761.5,
    y: 1866.5
  },
  {
    x: 2761.5,
    y: 1851.5
  },
  {
    x: 2761.5,
    y: 1836.5
  },
  {
    x: 2761.5,
    y: 1821.5
  },
  {
    x: 2761.5,
    y: 1806.5
  },
  {
    x: 2761.5,
    y: 1791.5
  },
  {
    x: 2761.5,
    y: 726.5
  },
  {
    x: 2761.5,
    y: 711.5
  },
  {
    x: 2761.5,
    y: 696.5
  },
  {
    x: 2761.5,
    y: 681.5
  },
  {
    x: 2761.5,
    y: 666.5
  },
  {
    x: 2761.5,
    y: 651.5
  },
  {
    x: 2761.5,
    y: 636.5
  },
  {
    x: 2761.5,
    y: 621.5
  },
  {
    x: 2761.5,
    y: 606.5
  },
  {
    x: 2761.5,
    y: 591.5
  },
  {
    x: 2761.5,
    y: 576.5
  },
  {
    x: 2761.5,
    y: 561.5
  },
  {
    x: 2761.5,
    y: 546.5
  },
  {
    x: 2761.5,
    y: 531.5
  },
  {
    x: 2761.5,
    y: 516.5
  },
  {
    x: 2761.5,
    y: 501.5
  },
  {
    x: 2761.5,
    y: 486.5
  },
  {
    x: 2761.5,
    y: 471.5
  },
  {
    x: 2761.5,
    y: 456.5
  },
  {
    x: 2761.5,
    y: 441.5
  },
  {
    x: 2761.5,
    y: 426.5
  },
  {
    x: 2761.5,
    y: 411.5
  },
  {
    x: 2761.5,
    y: 396.5
  },
  {
    x: 2761.5,
    y: 381.5
  },
  {
    x: 2761.5,
    y: 366.5
  },
  {
    x: 2761.5,
    y: 351.5
  },
  {
    x: 2761.5,
    y: 336.5
  },
  {
    x: 2761.5,
    y: 321.5
  },
  {
    x: 2761.5,
    y: 306.5
  },
  {
    x: 2761.5,
    y: 291.5
  },
  {
    x: 2761.5,
    y: 276.5
  },
  {
    x: 2761.5,
    y: 261.5
  },
  {
    x: 2761.5,
    y: 246.5
  },
  {
    x: 2761.5,
    y: 231.5
  },
  {
    x: 2761.5,
    y: 156.5
  },
  {
    x: 2776.5,
    y: 2046.5
  },
  {
    x: 2776.5,
    y: 2031.5
  },
  {
    x: 2776.5,
    y: 2016.5
  },
  {
    x: 2776.5,
    y: 2001.5
  },
  {
    x: 2776.5,
    y: 1986.5
  },
  {
    x: 2776.5,
    y: 1971.5
  },
  {
    x: 2776.5,
    y: 1956.5
  },
  {
    x: 2776.5,
    y: 1941.5
  },
  {
    x: 2776.5,
    y: 1926.5
  },
  {
    x: 2776.5,
    y: 1911.5
  },
  {
    x: 2776.5,
    y: 1896.5
  },
  {
    x: 2776.5,
    y: 1881.5
  },
  {
    x: 2776.5,
    y: 1866.5
  },
  {
    x: 2776.5,
    y: 1851.5
  },
  {
    x: 2776.5,
    y: 1836.5
  },
  {
    x: 2776.5,
    y: 1821.5
  },
  {
    x: 2776.5,
    y: 1806.5
  },
  {
    x: 2776.5,
    y: 1791.5
  },
  {
    x: 2776.5,
    y: 726.5
  },
  {
    x: 2776.5,
    y: 711.5
  },
  {
    x: 2776.5,
    y: 696.5
  },
  {
    x: 2776.5,
    y: 681.5
  },
  {
    x: 2776.5,
    y: 666.5
  },
  {
    x: 2776.5,
    y: 651.5
  },
  {
    x: 2776.5,
    y: 636.5
  },
  {
    x: 2776.5,
    y: 621.5
  },
  {
    x: 2776.5,
    y: 606.5
  },
  {
    x: 2776.5,
    y: 591.5
  },
  {
    x: 2776.5,
    y: 576.5
  },
  {
    x: 2776.5,
    y: 561.5
  },
  {
    x: 2776.5,
    y: 546.5
  },
  {
    x: 2776.5,
    y: 531.5
  },
  {
    x: 2776.5,
    y: 516.5
  },
  {
    x: 2776.5,
    y: 501.5
  },
  {
    x: 2776.5,
    y: 486.5
  },
  {
    x: 2776.5,
    y: 471.5
  },
  {
    x: 2776.5,
    y: 456.5
  },
  {
    x: 2776.5,
    y: 441.5
  },
  {
    x: 2776.5,
    y: 426.5
  },
  {
    x: 2776.5,
    y: 411.5
  },
  {
    x: 2776.5,
    y: 396.5
  },
  {
    x: 2776.5,
    y: 381.5
  },
  {
    x: 2776.5,
    y: 366.5
  },
  {
    x: 2776.5,
    y: 351.5
  },
  {
    x: 2776.5,
    y: 336.5
  },
  {
    x: 2776.5,
    y: 321.5
  },
  {
    x: 2776.5,
    y: 306.5
  },
  {
    x: 2776.5,
    y: 291.5
  },
  {
    x: 2776.5,
    y: 276.5
  },
  {
    x: 2776.5,
    y: 261.5
  },
  {
    x: 2776.5,
    y: 246.5
  },
  {
    x: 2776.5,
    y: 231.5
  },
  {
    x: 2776.5,
    y: 156.5
  },
  {
    x: 2791.5,
    y: 2046.5
  },
  {
    x: 2791.5,
    y: 2031.5
  },
  {
    x: 2791.5,
    y: 2016.5
  },
  {
    x: 2791.5,
    y: 2001.5
  },
  {
    x: 2791.5,
    y: 1986.5
  },
  {
    x: 2791.5,
    y: 1971.5
  },
  {
    x: 2791.5,
    y: 1956.5
  },
  {
    x: 2791.5,
    y: 1941.5
  },
  {
    x: 2791.5,
    y: 1926.5
  },
  {
    x: 2791.5,
    y: 1911.5
  },
  {
    x: 2791.5,
    y: 1896.5
  },
  {
    x: 2791.5,
    y: 1881.5
  },
  {
    x: 2791.5,
    y: 1866.5
  },
  {
    x: 2791.5,
    y: 1851.5
  },
  {
    x: 2791.5,
    y: 1836.5
  },
  {
    x: 2791.5,
    y: 1821.5
  },
  {
    x: 2791.5,
    y: 1806.5
  },
  {
    x: 2791.5,
    y: 726.5
  },
  {
    x: 2791.5,
    y: 711.5
  },
  {
    x: 2791.5,
    y: 696.5
  },
  {
    x: 2791.5,
    y: 681.5
  },
  {
    x: 2791.5,
    y: 666.5
  },
  {
    x: 2791.5,
    y: 651.5
  },
  {
    x: 2791.5,
    y: 636.5
  },
  {
    x: 2791.5,
    y: 621.5
  },
  {
    x: 2791.5,
    y: 606.5
  },
  {
    x: 2791.5,
    y: 591.5
  },
  {
    x: 2791.5,
    y: 576.5
  },
  {
    x: 2791.5,
    y: 561.5
  },
  {
    x: 2791.5,
    y: 546.5
  },
  {
    x: 2791.5,
    y: 531.5
  },
  {
    x: 2791.5,
    y: 516.5
  },
  {
    x: 2791.5,
    y: 501.5
  },
  {
    x: 2791.5,
    y: 486.5
  },
  {
    x: 2791.5,
    y: 471.5
  },
  {
    x: 2791.5,
    y: 456.5
  },
  {
    x: 2791.5,
    y: 441.5
  },
  {
    x: 2791.5,
    y: 426.5
  },
  {
    x: 2791.5,
    y: 411.5
  },
  {
    x: 2791.5,
    y: 396.5
  },
  {
    x: 2791.5,
    y: 381.5
  },
  {
    x: 2791.5,
    y: 366.5
  },
  {
    x: 2791.5,
    y: 351.5
  },
  {
    x: 2791.5,
    y: 336.5
  },
  {
    x: 2791.5,
    y: 321.5
  },
  {
    x: 2791.5,
    y: 306.5
  },
  {
    x: 2791.5,
    y: 291.5
  },
  {
    x: 2791.5,
    y: 276.5
  },
  {
    x: 2791.5,
    y: 261.5
  },
  {
    x: 2791.5,
    y: 246.5
  },
  {
    x: 2791.5,
    y: 156.5
  },
  {
    x: 2806.5,
    y: 2046.5
  },
  {
    x: 2806.5,
    y: 2031.5
  },
  {
    x: 2806.5,
    y: 2016.5
  },
  {
    x: 2806.5,
    y: 2001.5
  },
  {
    x: 2806.5,
    y: 1986.5
  },
  {
    x: 2806.5,
    y: 1971.5
  },
  {
    x: 2806.5,
    y: 1956.5
  },
  {
    x: 2806.5,
    y: 1941.5
  },
  {
    x: 2806.5,
    y: 1926.5
  },
  {
    x: 2806.5,
    y: 1911.5
  },
  {
    x: 2806.5,
    y: 1896.5
  },
  {
    x: 2806.5,
    y: 1881.5
  },
  {
    x: 2806.5,
    y: 1866.5
  },
  {
    x: 2806.5,
    y: 1851.5
  },
  {
    x: 2806.5,
    y: 1836.5
  },
  {
    x: 2806.5,
    y: 1821.5
  },
  {
    x: 2806.5,
    y: 1806.5
  },
  {
    x: 2806.5,
    y: 726.5
  },
  {
    x: 2806.5,
    y: 711.5
  },
  {
    x: 2806.5,
    y: 696.5
  },
  {
    x: 2806.5,
    y: 681.5
  },
  {
    x: 2806.5,
    y: 666.5
  },
  {
    x: 2806.5,
    y: 651.5
  },
  {
    x: 2806.5,
    y: 636.5
  },
  {
    x: 2806.5,
    y: 621.5
  },
  {
    x: 2806.5,
    y: 606.5
  },
  {
    x: 2806.5,
    y: 591.5
  },
  {
    x: 2806.5,
    y: 576.5
  },
  {
    x: 2806.5,
    y: 561.5
  },
  {
    x: 2806.5,
    y: 546.5
  },
  {
    x: 2806.5,
    y: 531.5
  },
  {
    x: 2806.5,
    y: 516.5
  },
  {
    x: 2806.5,
    y: 501.5
  },
  {
    x: 2806.5,
    y: 486.5
  },
  {
    x: 2806.5,
    y: 471.5
  },
  {
    x: 2806.5,
    y: 456.5
  },
  {
    x: 2806.5,
    y: 441.5
  },
  {
    x: 2806.5,
    y: 426.5
  },
  {
    x: 2806.5,
    y: 411.5
  },
  {
    x: 2806.5,
    y: 396.5
  },
  {
    x: 2806.5,
    y: 381.5
  },
  {
    x: 2806.5,
    y: 366.5
  },
  {
    x: 2806.5,
    y: 351.5
  },
  {
    x: 2806.5,
    y: 336.5
  },
  {
    x: 2806.5,
    y: 321.5
  },
  {
    x: 2806.5,
    y: 306.5
  },
  {
    x: 2806.5,
    y: 291.5
  },
  {
    x: 2806.5,
    y: 276.5
  },
  {
    x: 2806.5,
    y: 261.5
  },
  {
    x: 2806.5,
    y: 246.5
  },
  {
    x: 2806.5,
    y: 231.5
  },
  {
    x: 2806.5,
    y: 216.5
  },
  {
    x: 2806.5,
    y: 156.5
  },
  {
    x: 2821.5,
    y: 2046.5
  },
  {
    x: 2821.5,
    y: 2031.5
  },
  {
    x: 2821.5,
    y: 2016.5
  },
  {
    x: 2821.5,
    y: 2001.5
  },
  {
    x: 2821.5,
    y: 1986.5
  },
  {
    x: 2821.5,
    y: 1971.5
  },
  {
    x: 2821.5,
    y: 1956.5
  },
  {
    x: 2821.5,
    y: 1941.5
  },
  {
    x: 2821.5,
    y: 1926.5
  },
  {
    x: 2821.5,
    y: 1911.5
  },
  {
    x: 2821.5,
    y: 1896.5
  },
  {
    x: 2821.5,
    y: 1881.5
  },
  {
    x: 2821.5,
    y: 1866.5
  },
  {
    x: 2821.5,
    y: 1851.5
  },
  {
    x: 2821.5,
    y: 1836.5
  },
  {
    x: 2821.5,
    y: 1806.5
  },
  {
    x: 2821.5,
    y: 756.5
  },
  {
    x: 2821.5,
    y: 741.5
  },
  {
    x: 2821.5,
    y: 726.5
  },
  {
    x: 2821.5,
    y: 711.5
  },
  {
    x: 2821.5,
    y: 696.5
  },
  {
    x: 2821.5,
    y: 681.5
  },
  {
    x: 2821.5,
    y: 666.5
  },
  {
    x: 2821.5,
    y: 651.5
  },
  {
    x: 2821.5,
    y: 636.5
  },
  {
    x: 2821.5,
    y: 621.5
  },
  {
    x: 2821.5,
    y: 606.5
  },
  {
    x: 2821.5,
    y: 591.5
  },
  {
    x: 2821.5,
    y: 576.5
  },
  {
    x: 2821.5,
    y: 561.5
  },
  {
    x: 2821.5,
    y: 546.5
  },
  {
    x: 2821.5,
    y: 531.5
  },
  {
    x: 2821.5,
    y: 516.5
  },
  {
    x: 2821.5,
    y: 501.5
  },
  {
    x: 2821.5,
    y: 486.5
  },
  {
    x: 2821.5,
    y: 471.5
  },
  {
    x: 2821.5,
    y: 456.5
  },
  {
    x: 2821.5,
    y: 441.5
  },
  {
    x: 2821.5,
    y: 426.5
  },
  {
    x: 2821.5,
    y: 411.5
  },
  {
    x: 2821.5,
    y: 396.5
  },
  {
    x: 2821.5,
    y: 381.5
  },
  {
    x: 2821.5,
    y: 366.5
  },
  {
    x: 2821.5,
    y: 351.5
  },
  {
    x: 2821.5,
    y: 336.5
  },
  {
    x: 2821.5,
    y: 321.5
  },
  {
    x: 2821.5,
    y: 306.5
  },
  {
    x: 2821.5,
    y: 291.5
  },
  {
    x: 2821.5,
    y: 276.5
  },
  {
    x: 2821.5,
    y: 261.5
  },
  {
    x: 2821.5,
    y: 246.5
  },
  {
    x: 2821.5,
    y: 231.5
  },
  {
    x: 2821.5,
    y: 216.5
  },
  {
    x: 2821.5,
    y: 156.5
  },
  {
    x: 2836.5,
    y: 2046.5
  },
  {
    x: 2836.5,
    y: 2031.5
  },
  {
    x: 2836.5,
    y: 2016.5
  },
  {
    x: 2836.5,
    y: 2001.5
  },
  {
    x: 2836.5,
    y: 1986.5
  },
  {
    x: 2836.5,
    y: 1971.5
  },
  {
    x: 2836.5,
    y: 1956.5
  },
  {
    x: 2836.5,
    y: 1941.5
  },
  {
    x: 2836.5,
    y: 1926.5
  },
  {
    x: 2836.5,
    y: 1911.5
  },
  {
    x: 2836.5,
    y: 1896.5
  },
  {
    x: 2836.5,
    y: 1881.5
  },
  {
    x: 2836.5,
    y: 1866.5
  },
  {
    x: 2836.5,
    y: 1851.5
  },
  {
    x: 2836.5,
    y: 1806.5
  },
  {
    x: 2836.5,
    y: 1581.5
  },
  {
    x: 2836.5,
    y: 771.5
  },
  {
    x: 2836.5,
    y: 756.5
  },
  {
    x: 2836.5,
    y: 741.5
  },
  {
    x: 2836.5,
    y: 726.5
  },
  {
    x: 2836.5,
    y: 711.5
  },
  {
    x: 2836.5,
    y: 696.5
  },
  {
    x: 2836.5,
    y: 681.5
  },
  {
    x: 2836.5,
    y: 666.5
  },
  {
    x: 2836.5,
    y: 651.5
  },
  {
    x: 2836.5,
    y: 636.5
  },
  {
    x: 2836.5,
    y: 621.5
  },
  {
    x: 2836.5,
    y: 606.5
  },
  {
    x: 2836.5,
    y: 591.5
  },
  {
    x: 2836.5,
    y: 576.5
  },
  {
    x: 2836.5,
    y: 561.5
  },
  {
    x: 2836.5,
    y: 546.5
  },
  {
    x: 2836.5,
    y: 531.5
  },
  {
    x: 2836.5,
    y: 516.5
  },
  {
    x: 2836.5,
    y: 501.5
  },
  {
    x: 2836.5,
    y: 486.5
  },
  {
    x: 2836.5,
    y: 471.5
  },
  {
    x: 2836.5,
    y: 456.5
  },
  {
    x: 2836.5,
    y: 441.5
  },
  {
    x: 2836.5,
    y: 426.5
  },
  {
    x: 2836.5,
    y: 411.5
  },
  {
    x: 2836.5,
    y: 396.5
  },
  {
    x: 2836.5,
    y: 381.5
  },
  {
    x: 2836.5,
    y: 366.5
  },
  {
    x: 2836.5,
    y: 351.5
  },
  {
    x: 2836.5,
    y: 336.5
  },
  {
    x: 2836.5,
    y: 321.5
  },
  {
    x: 2836.5,
    y: 306.5
  },
  {
    x: 2836.5,
    y: 291.5
  },
  {
    x: 2836.5,
    y: 276.5
  },
  {
    x: 2836.5,
    y: 261.5
  },
  {
    x: 2836.5,
    y: 246.5
  },
  {
    x: 2836.5,
    y: 231.5
  },
  {
    x: 2836.5,
    y: 216.5
  },
  {
    x: 2836.5,
    y: 201.5
  },
  {
    x: 2851.5,
    y: 2046.5
  },
  {
    x: 2851.5,
    y: 2031.5
  },
  {
    x: 2851.5,
    y: 2016.5
  },
  {
    x: 2851.5,
    y: 2001.5
  },
  {
    x: 2851.5,
    y: 1986.5
  },
  {
    x: 2851.5,
    y: 1971.5
  },
  {
    x: 2851.5,
    y: 1956.5
  },
  {
    x: 2851.5,
    y: 1941.5
  },
  {
    x: 2851.5,
    y: 1926.5
  },
  {
    x: 2851.5,
    y: 1911.5
  },
  {
    x: 2851.5,
    y: 1896.5
  },
  {
    x: 2851.5,
    y: 1881.5
  },
  {
    x: 2851.5,
    y: 1866.5
  },
  {
    x: 2851.5,
    y: 1851.5
  },
  {
    x: 2851.5,
    y: 786.5
  },
  {
    x: 2851.5,
    y: 771.5
  },
  {
    x: 2851.5,
    y: 756.5
  },
  {
    x: 2851.5,
    y: 741.5
  },
  {
    x: 2851.5,
    y: 726.5
  },
  {
    x: 2851.5,
    y: 711.5
  },
  {
    x: 2851.5,
    y: 696.5
  },
  {
    x: 2851.5,
    y: 681.5
  },
  {
    x: 2851.5,
    y: 666.5
  },
  {
    x: 2851.5,
    y: 651.5
  },
  {
    x: 2851.5,
    y: 636.5
  },
  {
    x: 2851.5,
    y: 621.5
  },
  {
    x: 2851.5,
    y: 606.5
  },
  {
    x: 2851.5,
    y: 591.5
  },
  {
    x: 2851.5,
    y: 576.5
  },
  {
    x: 2851.5,
    y: 561.5
  },
  {
    x: 2851.5,
    y: 546.5
  },
  {
    x: 2851.5,
    y: 531.5
  },
  {
    x: 2851.5,
    y: 516.5
  },
  {
    x: 2851.5,
    y: 501.5
  },
  {
    x: 2851.5,
    y: 486.5
  },
  {
    x: 2851.5,
    y: 471.5
  },
  {
    x: 2851.5,
    y: 456.5
  },
  {
    x: 2851.5,
    y: 441.5
  },
  {
    x: 2851.5,
    y: 426.5
  },
  {
    x: 2851.5,
    y: 411.5
  },
  {
    x: 2851.5,
    y: 396.5
  },
  {
    x: 2851.5,
    y: 381.5
  },
  {
    x: 2851.5,
    y: 366.5
  },
  {
    x: 2851.5,
    y: 351.5
  },
  {
    x: 2851.5,
    y: 336.5
  },
  {
    x: 2851.5,
    y: 321.5
  },
  {
    x: 2851.5,
    y: 306.5
  },
  {
    x: 2851.5,
    y: 291.5
  },
  {
    x: 2851.5,
    y: 276.5
  },
  {
    x: 2851.5,
    y: 261.5
  },
  {
    x: 2851.5,
    y: 246.5
  },
  {
    x: 2851.5,
    y: 231.5
  },
  {
    x: 2851.5,
    y: 216.5
  },
  {
    x: 2851.5,
    y: 201.5
  },
  {
    x: 2851.5,
    y: 186.5
  },
  {
    x: 2866.5,
    y: 2046.5
  },
  {
    x: 2866.5,
    y: 2031.5
  },
  {
    x: 2866.5,
    y: 2016.5
  },
  {
    x: 2866.5,
    y: 2001.5
  },
  {
    x: 2866.5,
    y: 1986.5
  },
  {
    x: 2866.5,
    y: 1971.5
  },
  {
    x: 2866.5,
    y: 1956.5
  },
  {
    x: 2866.5,
    y: 1941.5
  },
  {
    x: 2866.5,
    y: 1926.5
  },
  {
    x: 2866.5,
    y: 1911.5
  },
  {
    x: 2866.5,
    y: 1896.5
  },
  {
    x: 2866.5,
    y: 1881.5
  },
  {
    x: 2866.5,
    y: 1866.5
  },
  {
    x: 2866.5,
    y: 1851.5
  },
  {
    x: 2866.5,
    y: 1836.5
  },
  {
    x: 2866.5,
    y: 771.5
  },
  {
    x: 2866.5,
    y: 756.5
  },
  {
    x: 2866.5,
    y: 741.5
  },
  {
    x: 2866.5,
    y: 726.5
  },
  {
    x: 2866.5,
    y: 711.5
  },
  {
    x: 2866.5,
    y: 696.5
  },
  {
    x: 2866.5,
    y: 681.5
  },
  {
    x: 2866.5,
    y: 666.5
  },
  {
    x: 2866.5,
    y: 651.5
  },
  {
    x: 2866.5,
    y: 636.5
  },
  {
    x: 2866.5,
    y: 621.5
  },
  {
    x: 2866.5,
    y: 606.5
  },
  {
    x: 2866.5,
    y: 591.5
  },
  {
    x: 2866.5,
    y: 576.5
  },
  {
    x: 2866.5,
    y: 561.5
  },
  {
    x: 2866.5,
    y: 546.5
  },
  {
    x: 2866.5,
    y: 531.5
  },
  {
    x: 2866.5,
    y: 516.5
  },
  {
    x: 2866.5,
    y: 501.5
  },
  {
    x: 2866.5,
    y: 486.5
  },
  {
    x: 2866.5,
    y: 471.5
  },
  {
    x: 2866.5,
    y: 456.5
  },
  {
    x: 2866.5,
    y: 441.5
  },
  {
    x: 2866.5,
    y: 426.5
  },
  {
    x: 2866.5,
    y: 411.5
  },
  {
    x: 2866.5,
    y: 396.5
  },
  {
    x: 2866.5,
    y: 381.5
  },
  {
    x: 2866.5,
    y: 366.5
  },
  {
    x: 2866.5,
    y: 351.5
  },
  {
    x: 2866.5,
    y: 336.5
  },
  {
    x: 2866.5,
    y: 321.5
  },
  {
    x: 2866.5,
    y: 306.5
  },
  {
    x: 2866.5,
    y: 291.5
  },
  {
    x: 2866.5,
    y: 276.5
  },
  {
    x: 2866.5,
    y: 246.5
  },
  {
    x: 2866.5,
    y: 231.5
  },
  {
    x: 2866.5,
    y: 216.5
  },
  {
    x: 2866.5,
    y: 201.5
  },
  {
    x: 2881.5,
    y: 2046.5
  },
  {
    x: 2881.5,
    y: 2031.5
  },
  {
    x: 2881.5,
    y: 2016.5
  },
  {
    x: 2881.5,
    y: 2001.5
  },
  {
    x: 2881.5,
    y: 1986.5
  },
  {
    x: 2881.5,
    y: 1971.5
  },
  {
    x: 2881.5,
    y: 1956.5
  },
  {
    x: 2881.5,
    y: 1941.5
  },
  {
    x: 2881.5,
    y: 1926.5
  },
  {
    x: 2881.5,
    y: 1911.5
  },
  {
    x: 2881.5,
    y: 1896.5
  },
  {
    x: 2881.5,
    y: 1881.5
  },
  {
    x: 2881.5,
    y: 1866.5
  },
  {
    x: 2881.5,
    y: 1851.5
  },
  {
    x: 2881.5,
    y: 1836.5
  },
  {
    x: 2881.5,
    y: 1821.5
  },
  {
    x: 2881.5,
    y: 831.5
  },
  {
    x: 2881.5,
    y: 816.5
  },
  {
    x: 2881.5,
    y: 801.5
  },
  {
    x: 2881.5,
    y: 786.5
  },
  {
    x: 2881.5,
    y: 771.5
  },
  {
    x: 2881.5,
    y: 756.5
  },
  {
    x: 2881.5,
    y: 741.5
  },
  {
    x: 2881.5,
    y: 726.5
  },
  {
    x: 2881.5,
    y: 711.5
  },
  {
    x: 2881.5,
    y: 696.5
  },
  {
    x: 2881.5,
    y: 681.5
  },
  {
    x: 2881.5,
    y: 666.5
  },
  {
    x: 2881.5,
    y: 651.5
  },
  {
    x: 2881.5,
    y: 636.5
  },
  {
    x: 2881.5,
    y: 621.5
  },
  {
    x: 2881.5,
    y: 606.5
  },
  {
    x: 2881.5,
    y: 591.5
  },
  {
    x: 2881.5,
    y: 576.5
  },
  {
    x: 2881.5,
    y: 561.5
  },
  {
    x: 2881.5,
    y: 546.5
  },
  {
    x: 2881.5,
    y: 531.5
  },
  {
    x: 2881.5,
    y: 516.5
  },
  {
    x: 2881.5,
    y: 501.5
  },
  {
    x: 2881.5,
    y: 486.5
  },
  {
    x: 2881.5,
    y: 471.5
  },
  {
    x: 2881.5,
    y: 456.5
  },
  {
    x: 2881.5,
    y: 441.5
  },
  {
    x: 2881.5,
    y: 426.5
  },
  {
    x: 2881.5,
    y: 411.5
  },
  {
    x: 2881.5,
    y: 396.5
  },
  {
    x: 2881.5,
    y: 381.5
  },
  {
    x: 2881.5,
    y: 366.5
  },
  {
    x: 2881.5,
    y: 351.5
  },
  {
    x: 2881.5,
    y: 336.5
  },
  {
    x: 2881.5,
    y: 321.5
  },
  {
    x: 2881.5,
    y: 306.5
  },
  {
    x: 2881.5,
    y: 291.5
  },
  {
    x: 2881.5,
    y: 276.5
  },
  {
    x: 2881.5,
    y: 246.5
  },
  {
    x: 2896.5,
    y: 2046.5
  },
  {
    x: 2896.5,
    y: 2031.5
  },
  {
    x: 2896.5,
    y: 2016.5
  },
  {
    x: 2896.5,
    y: 2001.5
  },
  {
    x: 2896.5,
    y: 1986.5
  },
  {
    x: 2896.5,
    y: 1971.5
  },
  {
    x: 2896.5,
    y: 1956.5
  },
  {
    x: 2896.5,
    y: 1941.5
  },
  {
    x: 2896.5,
    y: 1926.5
  },
  {
    x: 2896.5,
    y: 1911.5
  },
  {
    x: 2896.5,
    y: 1896.5
  },
  {
    x: 2896.5,
    y: 1881.5
  },
  {
    x: 2896.5,
    y: 1866.5
  },
  {
    x: 2896.5,
    y: 1851.5
  },
  {
    x: 2896.5,
    y: 1836.5
  },
  {
    x: 2896.5,
    y: 1821.5
  },
  {
    x: 2896.5,
    y: 861.5
  },
  {
    x: 2896.5,
    y: 846.5
  },
  {
    x: 2896.5,
    y: 831.5
  },
  {
    x: 2896.5,
    y: 816.5
  },
  {
    x: 2896.5,
    y: 801.5
  },
  {
    x: 2896.5,
    y: 786.5
  },
  {
    x: 2896.5,
    y: 771.5
  },
  {
    x: 2896.5,
    y: 756.5
  },
  {
    x: 2896.5,
    y: 741.5
  },
  {
    x: 2896.5,
    y: 726.5
  },
  {
    x: 2896.5,
    y: 711.5
  },
  {
    x: 2896.5,
    y: 696.5
  },
  {
    x: 2896.5,
    y: 681.5
  },
  {
    x: 2896.5,
    y: 666.5
  },
  {
    x: 2896.5,
    y: 651.5
  },
  {
    x: 2896.5,
    y: 636.5
  },
  {
    x: 2896.5,
    y: 621.5
  },
  {
    x: 2896.5,
    y: 606.5
  },
  {
    x: 2896.5,
    y: 591.5
  },
  {
    x: 2896.5,
    y: 576.5
  },
  {
    x: 2896.5,
    y: 561.5
  },
  {
    x: 2896.5,
    y: 546.5
  },
  {
    x: 2896.5,
    y: 531.5
  },
  {
    x: 2896.5,
    y: 516.5
  },
  {
    x: 2896.5,
    y: 501.5
  },
  {
    x: 2896.5,
    y: 486.5
  },
  {
    x: 2896.5,
    y: 471.5
  },
  {
    x: 2896.5,
    y: 456.5
  },
  {
    x: 2896.5,
    y: 441.5
  },
  {
    x: 2896.5,
    y: 426.5
  },
  {
    x: 2896.5,
    y: 411.5
  },
  {
    x: 2896.5,
    y: 396.5
  },
  {
    x: 2896.5,
    y: 381.5
  },
  {
    x: 2896.5,
    y: 366.5
  },
  {
    x: 2896.5,
    y: 351.5
  },
  {
    x: 2896.5,
    y: 336.5
  },
  {
    x: 2896.5,
    y: 321.5
  },
  {
    x: 2896.5,
    y: 306.5
  },
  {
    x: 2896.5,
    y: 291.5
  },
  {
    x: 2896.5,
    y: 276.5
  },
  {
    x: 2896.5,
    y: 261.5
  },
  {
    x: 2896.5,
    y: 246.5
  },
  {
    x: 2896.5,
    y: 231.5
  },
  {
    x: 2896.5,
    y: 216.5
  },
  {
    x: 2896.5,
    y: 201.5
  },
  {
    x: 2911.5,
    y: 2046.5
  },
  {
    x: 2911.5,
    y: 2031.5
  },
  {
    x: 2911.5,
    y: 2016.5
  },
  {
    x: 2911.5,
    y: 2001.5
  },
  {
    x: 2911.5,
    y: 1986.5
  },
  {
    x: 2911.5,
    y: 1971.5
  },
  {
    x: 2911.5,
    y: 1956.5
  },
  {
    x: 2911.5,
    y: 1941.5
  },
  {
    x: 2911.5,
    y: 1926.5
  },
  {
    x: 2911.5,
    y: 1911.5
  },
  {
    x: 2911.5,
    y: 1896.5
  },
  {
    x: 2911.5,
    y: 1881.5
  },
  {
    x: 2911.5,
    y: 1866.5
  },
  {
    x: 2911.5,
    y: 1851.5
  },
  {
    x: 2911.5,
    y: 1836.5
  },
  {
    x: 2911.5,
    y: 1821.5
  },
  {
    x: 2911.5,
    y: 891.5
  },
  {
    x: 2911.5,
    y: 876.5
  },
  {
    x: 2911.5,
    y: 861.5
  },
  {
    x: 2911.5,
    y: 846.5
  },
  {
    x: 2911.5,
    y: 831.5
  },
  {
    x: 2911.5,
    y: 816.5
  },
  {
    x: 2911.5,
    y: 801.5
  },
  {
    x: 2911.5,
    y: 786.5
  },
  {
    x: 2911.5,
    y: 771.5
  },
  {
    x: 2911.5,
    y: 756.5
  },
  {
    x: 2911.5,
    y: 741.5
  },
  {
    x: 2911.5,
    y: 726.5
  },
  {
    x: 2911.5,
    y: 711.5
  },
  {
    x: 2911.5,
    y: 696.5
  },
  {
    x: 2911.5,
    y: 681.5
  },
  {
    x: 2911.5,
    y: 666.5
  },
  {
    x: 2911.5,
    y: 651.5
  },
  {
    x: 2911.5,
    y: 636.5
  },
  {
    x: 2911.5,
    y: 621.5
  },
  {
    x: 2911.5,
    y: 606.5
  },
  {
    x: 2911.5,
    y: 591.5
  },
  {
    x: 2911.5,
    y: 576.5
  },
  {
    x: 2911.5,
    y: 561.5
  },
  {
    x: 2911.5,
    y: 546.5
  },
  {
    x: 2911.5,
    y: 531.5
  },
  {
    x: 2911.5,
    y: 516.5
  },
  {
    x: 2911.5,
    y: 501.5
  },
  {
    x: 2911.5,
    y: 486.5
  },
  {
    x: 2911.5,
    y: 471.5
  },
  {
    x: 2911.5,
    y: 456.5
  },
  {
    x: 2911.5,
    y: 441.5
  },
  {
    x: 2911.5,
    y: 426.5
  },
  {
    x: 2911.5,
    y: 411.5
  },
  {
    x: 2911.5,
    y: 396.5
  },
  {
    x: 2911.5,
    y: 381.5
  },
  {
    x: 2911.5,
    y: 366.5
  },
  {
    x: 2911.5,
    y: 351.5
  },
  {
    x: 2911.5,
    y: 336.5
  },
  {
    x: 2911.5,
    y: 321.5
  },
  {
    x: 2911.5,
    y: 306.5
  },
  {
    x: 2911.5,
    y: 291.5
  },
  {
    x: 2911.5,
    y: 276.5
  },
  {
    x: 2911.5,
    y: 261.5
  },
  {
    x: 2911.5,
    y: 246.5
  },
  {
    x: 2911.5,
    y: 231.5
  },
  {
    x: 2911.5,
    y: 216.5
  },
  {
    x: 2911.5,
    y: 201.5
  },
  {
    x: 2911.5,
    y: 186.5
  },
  {
    x: 2926.5,
    y: 2046.5
  },
  {
    x: 2926.5,
    y: 2031.5
  },
  {
    x: 2926.5,
    y: 2016.5
  },
  {
    x: 2926.5,
    y: 2001.5
  },
  {
    x: 2926.5,
    y: 1986.5
  },
  {
    x: 2926.5,
    y: 1971.5
  },
  {
    x: 2926.5,
    y: 1956.5
  },
  {
    x: 2926.5,
    y: 1941.5
  },
  {
    x: 2926.5,
    y: 1926.5
  },
  {
    x: 2926.5,
    y: 1911.5
  },
  {
    x: 2926.5,
    y: 1896.5
  },
  {
    x: 2926.5,
    y: 1881.5
  },
  {
    x: 2926.5,
    y: 1866.5
  },
  {
    x: 2926.5,
    y: 1851.5
  },
  {
    x: 2926.5,
    y: 1836.5
  },
  {
    x: 2926.5,
    y: 1821.5
  },
  {
    x: 2926.5,
    y: 921.5
  },
  {
    x: 2926.5,
    y: 906.5
  },
  {
    x: 2926.5,
    y: 891.5
  },
  {
    x: 2926.5,
    y: 876.5
  },
  {
    x: 2926.5,
    y: 861.5
  },
  {
    x: 2926.5,
    y: 846.5
  },
  {
    x: 2926.5,
    y: 831.5
  },
  {
    x: 2926.5,
    y: 816.5
  },
  {
    x: 2926.5,
    y: 801.5
  },
  {
    x: 2926.5,
    y: 786.5
  },
  {
    x: 2926.5,
    y: 771.5
  },
  {
    x: 2926.5,
    y: 756.5
  },
  {
    x: 2926.5,
    y: 741.5
  },
  {
    x: 2926.5,
    y: 726.5
  },
  {
    x: 2926.5,
    y: 711.5
  },
  {
    x: 2926.5,
    y: 696.5
  },
  {
    x: 2926.5,
    y: 681.5
  },
  {
    x: 2926.5,
    y: 666.5
  },
  {
    x: 2926.5,
    y: 651.5
  },
  {
    x: 2926.5,
    y: 636.5
  },
  {
    x: 2926.5,
    y: 621.5
  },
  {
    x: 2926.5,
    y: 606.5
  },
  {
    x: 2926.5,
    y: 591.5
  },
  {
    x: 2926.5,
    y: 576.5
  },
  {
    x: 2926.5,
    y: 561.5
  },
  {
    x: 2926.5,
    y: 546.5
  },
  {
    x: 2926.5,
    y: 531.5
  },
  {
    x: 2926.5,
    y: 516.5
  },
  {
    x: 2926.5,
    y: 501.5
  },
  {
    x: 2926.5,
    y: 486.5
  },
  {
    x: 2926.5,
    y: 471.5
  },
  {
    x: 2926.5,
    y: 456.5
  },
  {
    x: 2926.5,
    y: 441.5
  },
  {
    x: 2926.5,
    y: 426.5
  },
  {
    x: 2926.5,
    y: 411.5
  },
  {
    x: 2926.5,
    y: 396.5
  },
  {
    x: 2926.5,
    y: 381.5
  },
  {
    x: 2926.5,
    y: 366.5
  },
  {
    x: 2926.5,
    y: 351.5
  },
  {
    x: 2926.5,
    y: 336.5
  },
  {
    x: 2926.5,
    y: 321.5
  },
  {
    x: 2926.5,
    y: 306.5
  },
  {
    x: 2926.5,
    y: 291.5
  },
  {
    x: 2926.5,
    y: 276.5
  },
  {
    x: 2926.5,
    y: 261.5
  },
  {
    x: 2926.5,
    y: 246.5
  },
  {
    x: 2926.5,
    y: 231.5
  },
  {
    x: 2926.5,
    y: 216.5
  },
  {
    x: 2926.5,
    y: 201.5
  },
  {
    x: 2941.5,
    y: 2046.5
  },
  {
    x: 2941.5,
    y: 2031.5
  },
  {
    x: 2941.5,
    y: 2016.5
  },
  {
    x: 2941.5,
    y: 2001.5
  },
  {
    x: 2941.5,
    y: 1986.5
  },
  {
    x: 2941.5,
    y: 1971.5
  },
  {
    x: 2941.5,
    y: 1956.5
  },
  {
    x: 2941.5,
    y: 1941.5
  },
  {
    x: 2941.5,
    y: 1926.5
  },
  {
    x: 2941.5,
    y: 1911.5
  },
  {
    x: 2941.5,
    y: 1896.5
  },
  {
    x: 2941.5,
    y: 1881.5
  },
  {
    x: 2941.5,
    y: 1866.5
  },
  {
    x: 2941.5,
    y: 1851.5
  },
  {
    x: 2941.5,
    y: 1836.5
  },
  {
    x: 2941.5,
    y: 1821.5
  },
  {
    x: 2941.5,
    y: 1806.5
  },
  {
    x: 2941.5,
    y: 906.5
  },
  {
    x: 2941.5,
    y: 891.5
  },
  {
    x: 2941.5,
    y: 876.5
  },
  {
    x: 2941.5,
    y: 861.5
  },
  {
    x: 2941.5,
    y: 846.5
  },
  {
    x: 2941.5,
    y: 831.5
  },
  {
    x: 2941.5,
    y: 816.5
  },
  {
    x: 2941.5,
    y: 801.5
  },
  {
    x: 2941.5,
    y: 786.5
  },
  {
    x: 2941.5,
    y: 771.5
  },
  {
    x: 2941.5,
    y: 756.5
  },
  {
    x: 2941.5,
    y: 741.5
  },
  {
    x: 2941.5,
    y: 726.5
  },
  {
    x: 2941.5,
    y: 711.5
  },
  {
    x: 2941.5,
    y: 696.5
  },
  {
    x: 2941.5,
    y: 681.5
  },
  {
    x: 2941.5,
    y: 666.5
  },
  {
    x: 2941.5,
    y: 651.5
  },
  {
    x: 2941.5,
    y: 636.5
  },
  {
    x: 2941.5,
    y: 621.5
  },
  {
    x: 2941.5,
    y: 606.5
  },
  {
    x: 2941.5,
    y: 591.5
  },
  {
    x: 2941.5,
    y: 576.5
  },
  {
    x: 2941.5,
    y: 561.5
  },
  {
    x: 2941.5,
    y: 546.5
  },
  {
    x: 2941.5,
    y: 531.5
  },
  {
    x: 2941.5,
    y: 516.5
  },
  {
    x: 2941.5,
    y: 501.5
  },
  {
    x: 2941.5,
    y: 486.5
  },
  {
    x: 2941.5,
    y: 471.5
  },
  {
    x: 2941.5,
    y: 456.5
  },
  {
    x: 2941.5,
    y: 441.5
  },
  {
    x: 2941.5,
    y: 426.5
  },
  {
    x: 2941.5,
    y: 411.5
  },
  {
    x: 2941.5,
    y: 396.5
  },
  {
    x: 2941.5,
    y: 381.5
  },
  {
    x: 2941.5,
    y: 366.5
  },
  {
    x: 2941.5,
    y: 351.5
  },
  {
    x: 2941.5,
    y: 336.5
  },
  {
    x: 2941.5,
    y: 321.5
  },
  {
    x: 2941.5,
    y: 306.5
  },
  {
    x: 2941.5,
    y: 291.5
  },
  {
    x: 2941.5,
    y: 276.5
  },
  {
    x: 2941.5,
    y: 261.5
  },
  {
    x: 2941.5,
    y: 246.5
  },
  {
    x: 2941.5,
    y: 231.5
  },
  {
    x: 2941.5,
    y: 216.5
  },
  {
    x: 2941.5,
    y: 201.5
  },
  {
    x: 2956.5,
    y: 2046.5
  },
  {
    x: 2956.5,
    y: 2031.5
  },
  {
    x: 2956.5,
    y: 2016.5
  },
  {
    x: 2956.5,
    y: 2001.5
  },
  {
    x: 2956.5,
    y: 1986.5
  },
  {
    x: 2956.5,
    y: 1971.5
  },
  {
    x: 2956.5,
    y: 1956.5
  },
  {
    x: 2956.5,
    y: 1941.5
  },
  {
    x: 2956.5,
    y: 1926.5
  },
  {
    x: 2956.5,
    y: 1911.5
  },
  {
    x: 2956.5,
    y: 1896.5
  },
  {
    x: 2956.5,
    y: 1881.5
  },
  {
    x: 2956.5,
    y: 1866.5
  },
  {
    x: 2956.5,
    y: 1851.5
  },
  {
    x: 2956.5,
    y: 1836.5
  },
  {
    x: 2956.5,
    y: 1821.5
  },
  {
    x: 2956.5,
    y: 1806.5
  },
  {
    x: 2956.5,
    y: 936.5
  },
  {
    x: 2956.5,
    y: 891.5
  },
  {
    x: 2956.5,
    y: 876.5
  },
  {
    x: 2956.5,
    y: 861.5
  },
  {
    x: 2956.5,
    y: 846.5
  },
  {
    x: 2956.5,
    y: 831.5
  },
  {
    x: 2956.5,
    y: 816.5
  },
  {
    x: 2956.5,
    y: 801.5
  },
  {
    x: 2956.5,
    y: 786.5
  },
  {
    x: 2956.5,
    y: 771.5
  },
  {
    x: 2956.5,
    y: 756.5
  },
  {
    x: 2956.5,
    y: 741.5
  },
  {
    x: 2956.5,
    y: 726.5
  },
  {
    x: 2956.5,
    y: 711.5
  },
  {
    x: 2956.5,
    y: 696.5
  },
  {
    x: 2956.5,
    y: 681.5
  },
  {
    x: 2956.5,
    y: 666.5
  },
  {
    x: 2956.5,
    y: 651.5
  },
  {
    x: 2956.5,
    y: 636.5
  },
  {
    x: 2956.5,
    y: 621.5
  },
  {
    x: 2956.5,
    y: 606.5
  },
  {
    x: 2956.5,
    y: 591.5
  },
  {
    x: 2956.5,
    y: 576.5
  },
  {
    x: 2956.5,
    y: 561.5
  },
  {
    x: 2956.5,
    y: 546.5
  },
  {
    x: 2956.5,
    y: 531.5
  },
  {
    x: 2956.5,
    y: 516.5
  },
  {
    x: 2956.5,
    y: 501.5
  },
  {
    x: 2956.5,
    y: 486.5
  },
  {
    x: 2956.5,
    y: 471.5
  },
  {
    x: 2956.5,
    y: 456.5
  },
  {
    x: 2956.5,
    y: 441.5
  },
  {
    x: 2956.5,
    y: 426.5
  },
  {
    x: 2956.5,
    y: 411.5
  },
  {
    x: 2956.5,
    y: 396.5
  },
  {
    x: 2956.5,
    y: 381.5
  },
  {
    x: 2956.5,
    y: 366.5
  },
  {
    x: 2956.5,
    y: 351.5
  },
  {
    x: 2956.5,
    y: 336.5
  },
  {
    x: 2956.5,
    y: 321.5
  },
  {
    x: 2956.5,
    y: 306.5
  },
  {
    x: 2956.5,
    y: 291.5
  },
  {
    x: 2956.5,
    y: 276.5
  },
  {
    x: 2956.5,
    y: 261.5
  },
  {
    x: 2956.5,
    y: 246.5
  },
  {
    x: 2956.5,
    y: 231.5
  },
  {
    x: 2956.5,
    y: 216.5
  },
  {
    x: 2956.5,
    y: 201.5
  },
  {
    x: 2971.5,
    y: 2046.5
  },
  {
    x: 2971.5,
    y: 2031.5
  },
  {
    x: 2971.5,
    y: 2016.5
  },
  {
    x: 2971.5,
    y: 2001.5
  },
  {
    x: 2971.5,
    y: 1986.5
  },
  {
    x: 2971.5,
    y: 1971.5
  },
  {
    x: 2971.5,
    y: 1956.5
  },
  {
    x: 2971.5,
    y: 1941.5
  },
  {
    x: 2971.5,
    y: 1926.5
  },
  {
    x: 2971.5,
    y: 1911.5
  },
  {
    x: 2971.5,
    y: 1896.5
  },
  {
    x: 2971.5,
    y: 1881.5
  },
  {
    x: 2971.5,
    y: 1866.5
  },
  {
    x: 2971.5,
    y: 1851.5
  },
  {
    x: 2971.5,
    y: 1836.5
  },
  {
    x: 2971.5,
    y: 1821.5
  },
  {
    x: 2971.5,
    y: 1806.5
  },
  {
    x: 2971.5,
    y: 951.5
  },
  {
    x: 2971.5,
    y: 936.5
  },
  {
    x: 2971.5,
    y: 831.5
  },
  {
    x: 2971.5,
    y: 816.5
  },
  {
    x: 2971.5,
    y: 801.5
  },
  {
    x: 2971.5,
    y: 786.5
  },
  {
    x: 2971.5,
    y: 771.5
  },
  {
    x: 2971.5,
    y: 756.5
  },
  {
    x: 2971.5,
    y: 741.5
  },
  {
    x: 2971.5,
    y: 726.5
  },
  {
    x: 2971.5,
    y: 711.5
  },
  {
    x: 2971.5,
    y: 696.5
  },
  {
    x: 2971.5,
    y: 681.5
  },
  {
    x: 2971.5,
    y: 666.5
  },
  {
    x: 2971.5,
    y: 651.5
  },
  {
    x: 2971.5,
    y: 636.5
  },
  {
    x: 2971.5,
    y: 621.5
  },
  {
    x: 2971.5,
    y: 606.5
  },
  {
    x: 2971.5,
    y: 591.5
  },
  {
    x: 2971.5,
    y: 576.5
  },
  {
    x: 2971.5,
    y: 561.5
  },
  {
    x: 2971.5,
    y: 546.5
  },
  {
    x: 2971.5,
    y: 531.5
  },
  {
    x: 2971.5,
    y: 516.5
  },
  {
    x: 2971.5,
    y: 501.5
  },
  {
    x: 2971.5,
    y: 486.5
  },
  {
    x: 2971.5,
    y: 471.5
  },
  {
    x: 2971.5,
    y: 456.5
  },
  {
    x: 2971.5,
    y: 441.5
  },
  {
    x: 2971.5,
    y: 426.5
  },
  {
    x: 2971.5,
    y: 411.5
  },
  {
    x: 2971.5,
    y: 396.5
  },
  {
    x: 2971.5,
    y: 381.5
  },
  {
    x: 2971.5,
    y: 366.5
  },
  {
    x: 2971.5,
    y: 351.5
  },
  {
    x: 2971.5,
    y: 336.5
  },
  {
    x: 2971.5,
    y: 321.5
  },
  {
    x: 2971.5,
    y: 306.5
  },
  {
    x: 2971.5,
    y: 291.5
  },
  {
    x: 2971.5,
    y: 276.5
  },
  {
    x: 2971.5,
    y: 261.5
  },
  {
    x: 2971.5,
    y: 246.5
  },
  {
    x: 2971.5,
    y: 231.5
  },
  {
    x: 2971.5,
    y: 216.5
  },
  {
    x: 2971.5,
    y: 201.5
  },
  {
    x: 2971.5,
    y: 186.5
  },
  {
    x: 2986.5,
    y: 2046.5
  },
  {
    x: 2986.5,
    y: 2031.5
  },
  {
    x: 2986.5,
    y: 2016.5
  },
  {
    x: 2986.5,
    y: 2001.5
  },
  {
    x: 2986.5,
    y: 1986.5
  },
  {
    x: 2986.5,
    y: 1971.5
  },
  {
    x: 2986.5,
    y: 1956.5
  },
  {
    x: 2986.5,
    y: 1941.5
  },
  {
    x: 2986.5,
    y: 1926.5
  },
  {
    x: 2986.5,
    y: 1911.5
  },
  {
    x: 2986.5,
    y: 1896.5
  },
  {
    x: 2986.5,
    y: 1881.5
  },
  {
    x: 2986.5,
    y: 1866.5
  },
  {
    x: 2986.5,
    y: 1851.5
  },
  {
    x: 2986.5,
    y: 1836.5
  },
  {
    x: 2986.5,
    y: 1821.5
  },
  {
    x: 2986.5,
    y: 1806.5
  },
  {
    x: 2986.5,
    y: 831.5
  },
  {
    x: 2986.5,
    y: 816.5
  },
  {
    x: 2986.5,
    y: 801.5
  },
  {
    x: 2986.5,
    y: 786.5
  },
  {
    x: 2986.5,
    y: 771.5
  },
  {
    x: 2986.5,
    y: 756.5
  },
  {
    x: 2986.5,
    y: 741.5
  },
  {
    x: 2986.5,
    y: 726.5
  },
  {
    x: 2986.5,
    y: 711.5
  },
  {
    x: 2986.5,
    y: 696.5
  },
  {
    x: 2986.5,
    y: 681.5
  },
  {
    x: 2986.5,
    y: 666.5
  },
  {
    x: 2986.5,
    y: 651.5
  },
  {
    x: 2986.5,
    y: 636.5
  },
  {
    x: 2986.5,
    y: 621.5
  },
  {
    x: 2986.5,
    y: 606.5
  },
  {
    x: 2986.5,
    y: 591.5
  },
  {
    x: 2986.5,
    y: 576.5
  },
  {
    x: 2986.5,
    y: 561.5
  },
  {
    x: 2986.5,
    y: 546.5
  },
  {
    x: 2986.5,
    y: 531.5
  },
  {
    x: 2986.5,
    y: 516.5
  },
  {
    x: 2986.5,
    y: 501.5
  },
  {
    x: 2986.5,
    y: 486.5
  },
  {
    x: 2986.5,
    y: 471.5
  },
  {
    x: 2986.5,
    y: 456.5
  },
  {
    x: 2986.5,
    y: 441.5
  },
  {
    x: 2986.5,
    y: 426.5
  },
  {
    x: 2986.5,
    y: 411.5
  },
  {
    x: 2986.5,
    y: 396.5
  },
  {
    x: 2986.5,
    y: 381.5
  },
  {
    x: 2986.5,
    y: 366.5
  },
  {
    x: 2986.5,
    y: 351.5
  },
  {
    x: 2986.5,
    y: 336.5
  },
  {
    x: 2986.5,
    y: 321.5
  },
  {
    x: 2986.5,
    y: 306.5
  },
  {
    x: 2986.5,
    y: 291.5
  },
  {
    x: 2986.5,
    y: 276.5
  },
  {
    x: 2986.5,
    y: 261.5
  },
  {
    x: 2986.5,
    y: 246.5
  },
  {
    x: 2986.5,
    y: 231.5
  },
  {
    x: 2986.5,
    y: 216.5
  },
  {
    x: 2986.5,
    y: 201.5
  },
  {
    x: 2986.5,
    y: 186.5
  },
  {
    x: 3001.5,
    y: 2046.5
  },
  {
    x: 3001.5,
    y: 2031.5
  },
  {
    x: 3001.5,
    y: 2016.5
  },
  {
    x: 3001.5,
    y: 2001.5
  },
  {
    x: 3001.5,
    y: 1986.5
  },
  {
    x: 3001.5,
    y: 1971.5
  },
  {
    x: 3001.5,
    y: 1956.5
  },
  {
    x: 3001.5,
    y: 1941.5
  },
  {
    x: 3001.5,
    y: 1926.5
  },
  {
    x: 3001.5,
    y: 1911.5
  },
  {
    x: 3001.5,
    y: 1896.5
  },
  {
    x: 3001.5,
    y: 1881.5
  },
  {
    x: 3001.5,
    y: 1866.5
  },
  {
    x: 3001.5,
    y: 1851.5
  },
  {
    x: 3001.5,
    y: 1836.5
  },
  {
    x: 3001.5,
    y: 1821.5
  },
  {
    x: 3001.5,
    y: 1806.5
  },
  {
    x: 3001.5,
    y: 1791.5
  },
  {
    x: 3001.5,
    y: 816.5
  },
  {
    x: 3001.5,
    y: 801.5
  },
  {
    x: 3001.5,
    y: 786.5
  },
  {
    x: 3001.5,
    y: 771.5
  },
  {
    x: 3001.5,
    y: 756.5
  },
  {
    x: 3001.5,
    y: 741.5
  },
  {
    x: 3001.5,
    y: 726.5
  },
  {
    x: 3001.5,
    y: 711.5
  },
  {
    x: 3001.5,
    y: 696.5
  },
  {
    x: 3001.5,
    y: 681.5
  },
  {
    x: 3001.5,
    y: 666.5
  },
  {
    x: 3001.5,
    y: 651.5
  },
  {
    x: 3001.5,
    y: 636.5
  },
  {
    x: 3001.5,
    y: 621.5
  },
  {
    x: 3001.5,
    y: 606.5
  },
  {
    x: 3001.5,
    y: 591.5
  },
  {
    x: 3001.5,
    y: 576.5
  },
  {
    x: 3001.5,
    y: 561.5
  },
  {
    x: 3001.5,
    y: 546.5
  },
  {
    x: 3001.5,
    y: 531.5
  },
  {
    x: 3001.5,
    y: 516.5
  },
  {
    x: 3001.5,
    y: 501.5
  },
  {
    x: 3001.5,
    y: 486.5
  },
  {
    x: 3001.5,
    y: 471.5
  },
  {
    x: 3001.5,
    y: 456.5
  },
  {
    x: 3001.5,
    y: 441.5
  },
  {
    x: 3001.5,
    y: 426.5
  },
  {
    x: 3001.5,
    y: 411.5
  },
  {
    x: 3001.5,
    y: 396.5
  },
  {
    x: 3001.5,
    y: 381.5
  },
  {
    x: 3001.5,
    y: 366.5
  },
  {
    x: 3001.5,
    y: 351.5
  },
  {
    x: 3001.5,
    y: 336.5
  },
  {
    x: 3001.5,
    y: 321.5
  },
  {
    x: 3001.5,
    y: 306.5
  },
  {
    x: 3001.5,
    y: 291.5
  },
  {
    x: 3001.5,
    y: 276.5
  },
  {
    x: 3001.5,
    y: 261.5
  },
  {
    x: 3001.5,
    y: 246.5
  },
  {
    x: 3001.5,
    y: 231.5
  },
  {
    x: 3001.5,
    y: 216.5
  },
  {
    x: 3001.5,
    y: 201.5
  },
  {
    x: 3001.5,
    y: 186.5
  },
  {
    x: 3016.5,
    y: 2046.5
  },
  {
    x: 3016.5,
    y: 2031.5
  },
  {
    x: 3016.5,
    y: 2016.5
  },
  {
    x: 3016.5,
    y: 2001.5
  },
  {
    x: 3016.5,
    y: 1986.5
  },
  {
    x: 3016.5,
    y: 1971.5
  },
  {
    x: 3016.5,
    y: 1956.5
  },
  {
    x: 3016.5,
    y: 1941.5
  },
  {
    x: 3016.5,
    y: 1926.5
  },
  {
    x: 3016.5,
    y: 1911.5
  },
  {
    x: 3016.5,
    y: 1896.5
  },
  {
    x: 3016.5,
    y: 1881.5
  },
  {
    x: 3016.5,
    y: 1866.5
  },
  {
    x: 3016.5,
    y: 1851.5
  },
  {
    x: 3016.5,
    y: 1836.5
  },
  {
    x: 3016.5,
    y: 1821.5
  },
  {
    x: 3016.5,
    y: 1806.5
  },
  {
    x: 3016.5,
    y: 1791.5
  },
  {
    x: 3016.5,
    y: 801.5
  },
  {
    x: 3016.5,
    y: 786.5
  },
  {
    x: 3016.5,
    y: 771.5
  },
  {
    x: 3016.5,
    y: 756.5
  },
  {
    x: 3016.5,
    y: 741.5
  },
  {
    x: 3016.5,
    y: 726.5
  },
  {
    x: 3016.5,
    y: 711.5
  },
  {
    x: 3016.5,
    y: 696.5
  },
  {
    x: 3016.5,
    y: 681.5
  },
  {
    x: 3016.5,
    y: 666.5
  },
  {
    x: 3016.5,
    y: 651.5
  },
  {
    x: 3016.5,
    y: 636.5
  },
  {
    x: 3016.5,
    y: 621.5
  },
  {
    x: 3016.5,
    y: 606.5
  },
  {
    x: 3016.5,
    y: 591.5
  },
  {
    x: 3016.5,
    y: 576.5
  },
  {
    x: 3016.5,
    y: 561.5
  },
  {
    x: 3016.5,
    y: 546.5
  },
  {
    x: 3016.5,
    y: 531.5
  },
  {
    x: 3016.5,
    y: 516.5
  },
  {
    x: 3016.5,
    y: 501.5
  },
  {
    x: 3016.5,
    y: 486.5
  },
  {
    x: 3016.5,
    y: 471.5
  },
  {
    x: 3016.5,
    y: 456.5
  },
  {
    x: 3016.5,
    y: 441.5
  },
  {
    x: 3016.5,
    y: 426.5
  },
  {
    x: 3016.5,
    y: 411.5
  },
  {
    x: 3016.5,
    y: 396.5
  },
  {
    x: 3016.5,
    y: 381.5
  },
  {
    x: 3016.5,
    y: 366.5
  },
  {
    x: 3016.5,
    y: 351.5
  },
  {
    x: 3016.5,
    y: 336.5
  },
  {
    x: 3016.5,
    y: 321.5
  },
  {
    x: 3016.5,
    y: 306.5
  },
  {
    x: 3016.5,
    y: 291.5
  },
  {
    x: 3016.5,
    y: 276.5
  },
  {
    x: 3016.5,
    y: 261.5
  },
  {
    x: 3016.5,
    y: 246.5
  },
  {
    x: 3016.5,
    y: 231.5
  },
  {
    x: 3016.5,
    y: 216.5
  },
  {
    x: 3016.5,
    y: 201.5
  },
  {
    x: 3016.5,
    y: 186.5
  },
  {
    x: 3031.5,
    y: 2046.5
  },
  {
    x: 3031.5,
    y: 2031.5
  },
  {
    x: 3031.5,
    y: 2016.5
  },
  {
    x: 3031.5,
    y: 2001.5
  },
  {
    x: 3031.5,
    y: 1986.5
  },
  {
    x: 3031.5,
    y: 1971.5
  },
  {
    x: 3031.5,
    y: 1956.5
  },
  {
    x: 3031.5,
    y: 1941.5
  },
  {
    x: 3031.5,
    y: 1926.5
  },
  {
    x: 3031.5,
    y: 1911.5
  },
  {
    x: 3031.5,
    y: 1896.5
  },
  {
    x: 3031.5,
    y: 1881.5
  },
  {
    x: 3031.5,
    y: 1866.5
  },
  {
    x: 3031.5,
    y: 1851.5
  },
  {
    x: 3031.5,
    y: 1836.5
  },
  {
    x: 3031.5,
    y: 1821.5
  },
  {
    x: 3031.5,
    y: 1806.5
  },
  {
    x: 3031.5,
    y: 1791.5
  },
  {
    x: 3031.5,
    y: 786.5
  },
  {
    x: 3031.5,
    y: 771.5
  },
  {
    x: 3031.5,
    y: 756.5
  },
  {
    x: 3031.5,
    y: 741.5
  },
  {
    x: 3031.5,
    y: 726.5
  },
  {
    x: 3031.5,
    y: 711.5
  },
  {
    x: 3031.5,
    y: 696.5
  },
  {
    x: 3031.5,
    y: 681.5
  },
  {
    x: 3031.5,
    y: 666.5
  },
  {
    x: 3031.5,
    y: 651.5
  },
  {
    x: 3031.5,
    y: 636.5
  },
  {
    x: 3031.5,
    y: 621.5
  },
  {
    x: 3031.5,
    y: 606.5
  },
  {
    x: 3031.5,
    y: 591.5
  },
  {
    x: 3031.5,
    y: 576.5
  },
  {
    x: 3031.5,
    y: 561.5
  },
  {
    x: 3031.5,
    y: 546.5
  },
  {
    x: 3031.5,
    y: 531.5
  },
  {
    x: 3031.5,
    y: 516.5
  },
  {
    x: 3031.5,
    y: 501.5
  },
  {
    x: 3031.5,
    y: 486.5
  },
  {
    x: 3031.5,
    y: 471.5
  },
  {
    x: 3031.5,
    y: 456.5
  },
  {
    x: 3031.5,
    y: 441.5
  },
  {
    x: 3031.5,
    y: 426.5
  },
  {
    x: 3031.5,
    y: 411.5
  },
  {
    x: 3031.5,
    y: 396.5
  },
  {
    x: 3031.5,
    y: 381.5
  },
  {
    x: 3031.5,
    y: 366.5
  },
  {
    x: 3031.5,
    y: 351.5
  },
  {
    x: 3031.5,
    y: 336.5
  },
  {
    x: 3031.5,
    y: 321.5
  },
  {
    x: 3031.5,
    y: 306.5
  },
  {
    x: 3031.5,
    y: 291.5
  },
  {
    x: 3031.5,
    y: 276.5
  },
  {
    x: 3031.5,
    y: 261.5
  },
  {
    x: 3031.5,
    y: 246.5
  },
  {
    x: 3031.5,
    y: 231.5
  },
  {
    x: 3031.5,
    y: 216.5
  },
  {
    x: 3031.5,
    y: 201.5
  },
  {
    x: 3031.5,
    y: 186.5
  },
  {
    x: 3031.5,
    y: 171.5
  },
  {
    x: 3046.5,
    y: 2046.5
  },
  {
    x: 3046.5,
    y: 2031.5
  },
  {
    x: 3046.5,
    y: 2016.5
  },
  {
    x: 3046.5,
    y: 2001.5
  },
  {
    x: 3046.5,
    y: 1986.5
  },
  {
    x: 3046.5,
    y: 1971.5
  },
  {
    x: 3046.5,
    y: 1956.5
  },
  {
    x: 3046.5,
    y: 1941.5
  },
  {
    x: 3046.5,
    y: 1926.5
  },
  {
    x: 3046.5,
    y: 1911.5
  },
  {
    x: 3046.5,
    y: 1896.5
  },
  {
    x: 3046.5,
    y: 1881.5
  },
  {
    x: 3046.5,
    y: 1866.5
  },
  {
    x: 3046.5,
    y: 1851.5
  },
  {
    x: 3046.5,
    y: 1836.5
  },
  {
    x: 3046.5,
    y: 1821.5
  },
  {
    x: 3046.5,
    y: 1806.5
  },
  {
    x: 3046.5,
    y: 1791.5
  },
  {
    x: 3046.5,
    y: 771.5
  },
  {
    x: 3046.5,
    y: 756.5
  },
  {
    x: 3046.5,
    y: 741.5
  },
  {
    x: 3046.5,
    y: 726.5
  },
  {
    x: 3046.5,
    y: 711.5
  },
  {
    x: 3046.5,
    y: 696.5
  },
  {
    x: 3046.5,
    y: 681.5
  },
  {
    x: 3046.5,
    y: 666.5
  },
  {
    x: 3046.5,
    y: 651.5
  },
  {
    x: 3046.5,
    y: 636.5
  },
  {
    x: 3046.5,
    y: 621.5
  },
  {
    x: 3046.5,
    y: 606.5
  },
  {
    x: 3046.5,
    y: 591.5
  },
  {
    x: 3046.5,
    y: 576.5
  },
  {
    x: 3046.5,
    y: 561.5
  },
  {
    x: 3046.5,
    y: 546.5
  },
  {
    x: 3046.5,
    y: 531.5
  },
  {
    x: 3046.5,
    y: 516.5
  },
  {
    x: 3046.5,
    y: 501.5
  },
  {
    x: 3046.5,
    y: 486.5
  },
  {
    x: 3046.5,
    y: 471.5
  },
  {
    x: 3046.5,
    y: 456.5
  },
  {
    x: 3046.5,
    y: 441.5
  },
  {
    x: 3046.5,
    y: 426.5
  },
  {
    x: 3046.5,
    y: 411.5
  },
  {
    x: 3046.5,
    y: 396.5
  },
  {
    x: 3046.5,
    y: 381.5
  },
  {
    x: 3046.5,
    y: 366.5
  },
  {
    x: 3046.5,
    y: 351.5
  },
  {
    x: 3046.5,
    y: 336.5
  },
  {
    x: 3046.5,
    y: 321.5
  },
  {
    x: 3046.5,
    y: 306.5
  },
  {
    x: 3046.5,
    y: 291.5
  },
  {
    x: 3046.5,
    y: 276.5
  },
  {
    x: 3046.5,
    y: 261.5
  },
  {
    x: 3046.5,
    y: 246.5
  },
  {
    x: 3046.5,
    y: 231.5
  },
  {
    x: 3046.5,
    y: 216.5
  },
  {
    x: 3046.5,
    y: 201.5
  },
  {
    x: 3046.5,
    y: 186.5
  },
  {
    x: 3046.5,
    y: 171.5
  },
  {
    x: 3061.5,
    y: 2046.5
  },
  {
    x: 3061.5,
    y: 2031.5
  },
  {
    x: 3061.5,
    y: 2016.5
  },
  {
    x: 3061.5,
    y: 2001.5
  },
  {
    x: 3061.5,
    y: 1986.5
  },
  {
    x: 3061.5,
    y: 1971.5
  },
  {
    x: 3061.5,
    y: 1956.5
  },
  {
    x: 3061.5,
    y: 1941.5
  },
  {
    x: 3061.5,
    y: 1926.5
  },
  {
    x: 3061.5,
    y: 1911.5
  },
  {
    x: 3061.5,
    y: 1896.5
  },
  {
    x: 3061.5,
    y: 1881.5
  },
  {
    x: 3061.5,
    y: 1866.5
  },
  {
    x: 3061.5,
    y: 1851.5
  },
  {
    x: 3061.5,
    y: 1836.5
  },
  {
    x: 3061.5,
    y: 1821.5
  },
  {
    x: 3061.5,
    y: 1806.5
  },
  {
    x: 3061.5,
    y: 1791.5
  },
  {
    x: 3061.5,
    y: 771.5
  },
  {
    x: 3061.5,
    y: 756.5
  },
  {
    x: 3061.5,
    y: 741.5
  },
  {
    x: 3061.5,
    y: 726.5
  },
  {
    x: 3061.5,
    y: 711.5
  },
  {
    x: 3061.5,
    y: 696.5
  },
  {
    x: 3061.5,
    y: 681.5
  },
  {
    x: 3061.5,
    y: 666.5
  },
  {
    x: 3061.5,
    y: 651.5
  },
  {
    x: 3061.5,
    y: 636.5
  },
  {
    x: 3061.5,
    y: 621.5
  },
  {
    x: 3061.5,
    y: 606.5
  },
  {
    x: 3061.5,
    y: 591.5
  },
  {
    x: 3061.5,
    y: 576.5
  },
  {
    x: 3061.5,
    y: 561.5
  },
  {
    x: 3061.5,
    y: 546.5
  },
  {
    x: 3061.5,
    y: 531.5
  },
  {
    x: 3061.5,
    y: 516.5
  },
  {
    x: 3061.5,
    y: 501.5
  },
  {
    x: 3061.5,
    y: 486.5
  },
  {
    x: 3061.5,
    y: 471.5
  },
  {
    x: 3061.5,
    y: 456.5
  },
  {
    x: 3061.5,
    y: 441.5
  },
  {
    x: 3061.5,
    y: 426.5
  },
  {
    x: 3061.5,
    y: 411.5
  },
  {
    x: 3061.5,
    y: 396.5
  },
  {
    x: 3061.5,
    y: 381.5
  },
  {
    x: 3061.5,
    y: 366.5
  },
  {
    x: 3061.5,
    y: 351.5
  },
  {
    x: 3061.5,
    y: 336.5
  },
  {
    x: 3061.5,
    y: 321.5
  },
  {
    x: 3061.5,
    y: 306.5
  },
  {
    x: 3061.5,
    y: 291.5
  },
  {
    x: 3061.5,
    y: 276.5
  },
  {
    x: 3061.5,
    y: 261.5
  },
  {
    x: 3061.5,
    y: 246.5
  },
  {
    x: 3061.5,
    y: 231.5
  },
  {
    x: 3061.5,
    y: 216.5
  },
  {
    x: 3061.5,
    y: 201.5
  },
  {
    x: 3061.5,
    y: 186.5
  },
  {
    x: 3061.5,
    y: 171.5
  },
  {
    x: 3076.5,
    y: 2046.5
  },
  {
    x: 3076.5,
    y: 2031.5
  },
  {
    x: 3076.5,
    y: 2016.5
  },
  {
    x: 3076.5,
    y: 2001.5
  },
  {
    x: 3076.5,
    y: 1986.5
  },
  {
    x: 3076.5,
    y: 1971.5
  },
  {
    x: 3076.5,
    y: 1956.5
  },
  {
    x: 3076.5,
    y: 1941.5
  },
  {
    x: 3076.5,
    y: 1926.5
  },
  {
    x: 3076.5,
    y: 1911.5
  },
  {
    x: 3076.5,
    y: 1896.5
  },
  {
    x: 3076.5,
    y: 1881.5
  },
  {
    x: 3076.5,
    y: 1866.5
  },
  {
    x: 3076.5,
    y: 1851.5
  },
  {
    x: 3076.5,
    y: 1836.5
  },
  {
    x: 3076.5,
    y: 1821.5
  },
  {
    x: 3076.5,
    y: 1806.5
  },
  {
    x: 3076.5,
    y: 1791.5
  },
  {
    x: 3076.5,
    y: 771.5
  },
  {
    x: 3076.5,
    y: 756.5
  },
  {
    x: 3076.5,
    y: 741.5
  },
  {
    x: 3076.5,
    y: 726.5
  },
  {
    x: 3076.5,
    y: 711.5
  },
  {
    x: 3076.5,
    y: 696.5
  },
  {
    x: 3076.5,
    y: 681.5
  },
  {
    x: 3076.5,
    y: 666.5
  },
  {
    x: 3076.5,
    y: 651.5
  },
  {
    x: 3076.5,
    y: 636.5
  },
  {
    x: 3076.5,
    y: 621.5
  },
  {
    x: 3076.5,
    y: 606.5
  },
  {
    x: 3076.5,
    y: 591.5
  },
  {
    x: 3076.5,
    y: 576.5
  },
  {
    x: 3076.5,
    y: 561.5
  },
  {
    x: 3076.5,
    y: 546.5
  },
  {
    x: 3076.5,
    y: 531.5
  },
  {
    x: 3076.5,
    y: 516.5
  },
  {
    x: 3076.5,
    y: 501.5
  },
  {
    x: 3076.5,
    y: 486.5
  },
  {
    x: 3076.5,
    y: 471.5
  },
  {
    x: 3076.5,
    y: 456.5
  },
  {
    x: 3076.5,
    y: 441.5
  },
  {
    x: 3076.5,
    y: 426.5
  },
  {
    x: 3076.5,
    y: 411.5
  },
  {
    x: 3076.5,
    y: 396.5
  },
  {
    x: 3076.5,
    y: 381.5
  },
  {
    x: 3076.5,
    y: 366.5
  },
  {
    x: 3076.5,
    y: 351.5
  },
  {
    x: 3076.5,
    y: 336.5
  },
  {
    x: 3076.5,
    y: 321.5
  },
  {
    x: 3076.5,
    y: 306.5
  },
  {
    x: 3076.5,
    y: 291.5
  },
  {
    x: 3076.5,
    y: 276.5
  },
  {
    x: 3076.5,
    y: 261.5
  },
  {
    x: 3076.5,
    y: 246.5
  },
  {
    x: 3076.5,
    y: 231.5
  },
  {
    x: 3076.5,
    y: 216.5
  },
  {
    x: 3076.5,
    y: 201.5
  },
  {
    x: 3076.5,
    y: 186.5
  },
  {
    x: 3076.5,
    y: 171.5
  },
  {
    x: 3091.5,
    y: 2046.5
  },
  {
    x: 3091.5,
    y: 2031.5
  },
  {
    x: 3091.5,
    y: 2016.5
  },
  {
    x: 3091.5,
    y: 2001.5
  },
  {
    x: 3091.5,
    y: 1986.5
  },
  {
    x: 3091.5,
    y: 1971.5
  },
  {
    x: 3091.5,
    y: 1956.5
  },
  {
    x: 3091.5,
    y: 1941.5
  },
  {
    x: 3091.5,
    y: 1926.5
  },
  {
    x: 3091.5,
    y: 1911.5
  },
  {
    x: 3091.5,
    y: 1896.5
  },
  {
    x: 3091.5,
    y: 1881.5
  },
  {
    x: 3091.5,
    y: 1866.5
  },
  {
    x: 3091.5,
    y: 1851.5
  },
  {
    x: 3091.5,
    y: 1836.5
  },
  {
    x: 3091.5,
    y: 1821.5
  },
  {
    x: 3091.5,
    y: 1806.5
  },
  {
    x: 3091.5,
    y: 1791.5
  },
  {
    x: 3091.5,
    y: 771.5
  },
  {
    x: 3091.5,
    y: 756.5
  },
  {
    x: 3091.5,
    y: 741.5
  },
  {
    x: 3091.5,
    y: 726.5
  },
  {
    x: 3091.5,
    y: 711.5
  },
  {
    x: 3091.5,
    y: 696.5
  },
  {
    x: 3091.5,
    y: 681.5
  },
  {
    x: 3091.5,
    y: 666.5
  },
  {
    x: 3091.5,
    y: 651.5
  },
  {
    x: 3091.5,
    y: 636.5
  },
  {
    x: 3091.5,
    y: 621.5
  },
  {
    x: 3091.5,
    y: 606.5
  },
  {
    x: 3091.5,
    y: 591.5
  },
  {
    x: 3091.5,
    y: 576.5
  },
  {
    x: 3091.5,
    y: 561.5
  },
  {
    x: 3091.5,
    y: 546.5
  },
  {
    x: 3091.5,
    y: 531.5
  },
  {
    x: 3091.5,
    y: 516.5
  },
  {
    x: 3091.5,
    y: 501.5
  },
  {
    x: 3091.5,
    y: 486.5
  },
  {
    x: 3091.5,
    y: 471.5
  },
  {
    x: 3091.5,
    y: 456.5
  },
  {
    x: 3091.5,
    y: 441.5
  },
  {
    x: 3091.5,
    y: 426.5
  },
  {
    x: 3091.5,
    y: 411.5
  },
  {
    x: 3091.5,
    y: 396.5
  },
  {
    x: 3091.5,
    y: 381.5
  },
  {
    x: 3091.5,
    y: 366.5
  },
  {
    x: 3091.5,
    y: 351.5
  },
  {
    x: 3091.5,
    y: 336.5
  },
  {
    x: 3091.5,
    y: 321.5
  },
  {
    x: 3091.5,
    y: 306.5
  },
  {
    x: 3091.5,
    y: 291.5
  },
  {
    x: 3091.5,
    y: 276.5
  },
  {
    x: 3091.5,
    y: 261.5
  },
  {
    x: 3091.5,
    y: 246.5
  },
  {
    x: 3091.5,
    y: 231.5
  },
  {
    x: 3091.5,
    y: 216.5
  },
  {
    x: 3091.5,
    y: 201.5
  },
  {
    x: 3091.5,
    y: 186.5
  },
  {
    x: 3091.5,
    y: 171.5
  },
  {
    x: 3091.5,
    y: 111.5
  },
  {
    x: 3106.5,
    y: 2046.5
  },
  {
    x: 3106.5,
    y: 2031.5
  },
  {
    x: 3106.5,
    y: 2016.5
  },
  {
    x: 3106.5,
    y: 2001.5
  },
  {
    x: 3106.5,
    y: 1986.5
  },
  {
    x: 3106.5,
    y: 1971.5
  },
  {
    x: 3106.5,
    y: 1956.5
  },
  {
    x: 3106.5,
    y: 1941.5
  },
  {
    x: 3106.5,
    y: 1926.5
  },
  {
    x: 3106.5,
    y: 1911.5
  },
  {
    x: 3106.5,
    y: 1896.5
  },
  {
    x: 3106.5,
    y: 1881.5
  },
  {
    x: 3106.5,
    y: 1866.5
  },
  {
    x: 3106.5,
    y: 1851.5
  },
  {
    x: 3106.5,
    y: 1836.5
  },
  {
    x: 3106.5,
    y: 1821.5
  },
  {
    x: 3106.5,
    y: 1806.5
  },
  {
    x: 3106.5,
    y: 1791.5
  },
  {
    x: 3106.5,
    y: 876.5
  },
  {
    x: 3106.5,
    y: 786.5
  },
  {
    x: 3106.5,
    y: 771.5
  },
  {
    x: 3106.5,
    y: 756.5
  },
  {
    x: 3106.5,
    y: 741.5
  },
  {
    x: 3106.5,
    y: 726.5
  },
  {
    x: 3106.5,
    y: 711.5
  },
  {
    x: 3106.5,
    y: 696.5
  },
  {
    x: 3106.5,
    y: 681.5
  },
  {
    x: 3106.5,
    y: 666.5
  },
  {
    x: 3106.5,
    y: 651.5
  },
  {
    x: 3106.5,
    y: 636.5
  },
  {
    x: 3106.5,
    y: 621.5
  },
  {
    x: 3106.5,
    y: 606.5
  },
  {
    x: 3106.5,
    y: 591.5
  },
  {
    x: 3106.5,
    y: 576.5
  },
  {
    x: 3106.5,
    y: 561.5
  },
  {
    x: 3106.5,
    y: 546.5
  },
  {
    x: 3106.5,
    y: 531.5
  },
  {
    x: 3106.5,
    y: 516.5
  },
  {
    x: 3106.5,
    y: 501.5
  },
  {
    x: 3106.5,
    y: 486.5
  },
  {
    x: 3106.5,
    y: 471.5
  },
  {
    x: 3106.5,
    y: 456.5
  },
  {
    x: 3106.5,
    y: 441.5
  },
  {
    x: 3106.5,
    y: 426.5
  },
  {
    x: 3106.5,
    y: 411.5
  },
  {
    x: 3106.5,
    y: 396.5
  },
  {
    x: 3106.5,
    y: 381.5
  },
  {
    x: 3106.5,
    y: 366.5
  },
  {
    x: 3106.5,
    y: 351.5
  },
  {
    x: 3106.5,
    y: 336.5
  },
  {
    x: 3106.5,
    y: 321.5
  },
  {
    x: 3106.5,
    y: 306.5
  },
  {
    x: 3106.5,
    y: 291.5
  },
  {
    x: 3106.5,
    y: 276.5
  },
  {
    x: 3106.5,
    y: 261.5
  },
  {
    x: 3106.5,
    y: 246.5
  },
  {
    x: 3106.5,
    y: 231.5
  },
  {
    x: 3106.5,
    y: 216.5
  },
  {
    x: 3106.5,
    y: 201.5
  },
  {
    x: 3106.5,
    y: 186.5
  },
  {
    x: 3106.5,
    y: 171.5
  },
  {
    x: 3106.5,
    y: 111.5
  },
  {
    x: 3121.5,
    y: 2046.5
  },
  {
    x: 3121.5,
    y: 2031.5
  },
  {
    x: 3121.5,
    y: 2016.5
  },
  {
    x: 3121.5,
    y: 2001.5
  },
  {
    x: 3121.5,
    y: 1986.5
  },
  {
    x: 3121.5,
    y: 1971.5
  },
  {
    x: 3121.5,
    y: 1956.5
  },
  {
    x: 3121.5,
    y: 1941.5
  },
  {
    x: 3121.5,
    y: 1926.5
  },
  {
    x: 3121.5,
    y: 1911.5
  },
  {
    x: 3121.5,
    y: 1896.5
  },
  {
    x: 3121.5,
    y: 1881.5
  },
  {
    x: 3121.5,
    y: 1866.5
  },
  {
    x: 3121.5,
    y: 1851.5
  },
  {
    x: 3121.5,
    y: 1836.5
  },
  {
    x: 3121.5,
    y: 1821.5
  },
  {
    x: 3121.5,
    y: 1806.5
  },
  {
    x: 3121.5,
    y: 1791.5
  },
  {
    x: 3121.5,
    y: 831.5
  },
  {
    x: 3121.5,
    y: 816.5
  },
  {
    x: 3121.5,
    y: 801.5
  },
  {
    x: 3121.5,
    y: 786.5
  },
  {
    x: 3121.5,
    y: 771.5
  },
  {
    x: 3121.5,
    y: 756.5
  },
  {
    x: 3121.5,
    y: 741.5
  },
  {
    x: 3121.5,
    y: 726.5
  },
  {
    x: 3121.5,
    y: 711.5
  },
  {
    x: 3121.5,
    y: 696.5
  },
  {
    x: 3121.5,
    y: 681.5
  },
  {
    x: 3121.5,
    y: 666.5
  },
  {
    x: 3121.5,
    y: 651.5
  },
  {
    x: 3121.5,
    y: 636.5
  },
  {
    x: 3121.5,
    y: 621.5
  },
  {
    x: 3121.5,
    y: 606.5
  },
  {
    x: 3121.5,
    y: 591.5
  },
  {
    x: 3121.5,
    y: 576.5
  },
  {
    x: 3121.5,
    y: 561.5
  },
  {
    x: 3121.5,
    y: 546.5
  },
  {
    x: 3121.5,
    y: 531.5
  },
  {
    x: 3121.5,
    y: 516.5
  },
  {
    x: 3121.5,
    y: 501.5
  },
  {
    x: 3121.5,
    y: 486.5
  },
  {
    x: 3121.5,
    y: 471.5
  },
  {
    x: 3121.5,
    y: 456.5
  },
  {
    x: 3121.5,
    y: 441.5
  },
  {
    x: 3121.5,
    y: 426.5
  },
  {
    x: 3121.5,
    y: 411.5
  },
  {
    x: 3121.5,
    y: 396.5
  },
  {
    x: 3121.5,
    y: 381.5
  },
  {
    x: 3121.5,
    y: 366.5
  },
  {
    x: 3121.5,
    y: 351.5
  },
  {
    x: 3121.5,
    y: 336.5
  },
  {
    x: 3121.5,
    y: 321.5
  },
  {
    x: 3121.5,
    y: 306.5
  },
  {
    x: 3121.5,
    y: 291.5
  },
  {
    x: 3121.5,
    y: 276.5
  },
  {
    x: 3121.5,
    y: 261.5
  },
  {
    x: 3121.5,
    y: 246.5
  },
  {
    x: 3121.5,
    y: 231.5
  },
  {
    x: 3121.5,
    y: 216.5
  },
  {
    x: 3121.5,
    y: 201.5
  },
  {
    x: 3121.5,
    y: 186.5
  },
  {
    x: 3121.5,
    y: 171.5
  },
  {
    x: 3121.5,
    y: 156.5
  },
  {
    x: 3121.5,
    y: 111.5
  },
  {
    x: 3136.5,
    y: 2046.5
  },
  {
    x: 3136.5,
    y: 2031.5
  },
  {
    x: 3136.5,
    y: 2016.5
  },
  {
    x: 3136.5,
    y: 2001.5
  },
  {
    x: 3136.5,
    y: 1986.5
  },
  {
    x: 3136.5,
    y: 1971.5
  },
  {
    x: 3136.5,
    y: 1956.5
  },
  {
    x: 3136.5,
    y: 1941.5
  },
  {
    x: 3136.5,
    y: 1926.5
  },
  {
    x: 3136.5,
    y: 1911.5
  },
  {
    x: 3136.5,
    y: 1896.5
  },
  {
    x: 3136.5,
    y: 1881.5
  },
  {
    x: 3136.5,
    y: 1866.5
  },
  {
    x: 3136.5,
    y: 1851.5
  },
  {
    x: 3136.5,
    y: 1836.5
  },
  {
    x: 3136.5,
    y: 1821.5
  },
  {
    x: 3136.5,
    y: 1806.5
  },
  {
    x: 3136.5,
    y: 1791.5
  },
  {
    x: 3136.5,
    y: 966.5
  },
  {
    x: 3136.5,
    y: 831.5
  },
  {
    x: 3136.5,
    y: 816.5
  },
  {
    x: 3136.5,
    y: 801.5
  },
  {
    x: 3136.5,
    y: 786.5
  },
  {
    x: 3136.5,
    y: 771.5
  },
  {
    x: 3136.5,
    y: 756.5
  },
  {
    x: 3136.5,
    y: 741.5
  },
  {
    x: 3136.5,
    y: 726.5
  },
  {
    x: 3136.5,
    y: 711.5
  },
  {
    x: 3136.5,
    y: 696.5
  },
  {
    x: 3136.5,
    y: 681.5
  },
  {
    x: 3136.5,
    y: 666.5
  },
  {
    x: 3136.5,
    y: 651.5
  },
  {
    x: 3136.5,
    y: 636.5
  },
  {
    x: 3136.5,
    y: 621.5
  },
  {
    x: 3136.5,
    y: 606.5
  },
  {
    x: 3136.5,
    y: 591.5
  },
  {
    x: 3136.5,
    y: 576.5
  },
  {
    x: 3136.5,
    y: 561.5
  },
  {
    x: 3136.5,
    y: 546.5
  },
  {
    x: 3136.5,
    y: 531.5
  },
  {
    x: 3136.5,
    y: 516.5
  },
  {
    x: 3136.5,
    y: 501.5
  },
  {
    x: 3136.5,
    y: 486.5
  },
  {
    x: 3136.5,
    y: 471.5
  },
  {
    x: 3136.5,
    y: 456.5
  },
  {
    x: 3136.5,
    y: 441.5
  },
  {
    x: 3136.5,
    y: 426.5
  },
  {
    x: 3136.5,
    y: 411.5
  },
  {
    x: 3136.5,
    y: 396.5
  },
  {
    x: 3136.5,
    y: 381.5
  },
  {
    x: 3136.5,
    y: 366.5
  },
  {
    x: 3136.5,
    y: 351.5
  },
  {
    x: 3136.5,
    y: 336.5
  },
  {
    x: 3136.5,
    y: 321.5
  },
  {
    x: 3136.5,
    y: 306.5
  },
  {
    x: 3136.5,
    y: 291.5
  },
  {
    x: 3136.5,
    y: 276.5
  },
  {
    x: 3136.5,
    y: 261.5
  },
  {
    x: 3136.5,
    y: 246.5
  },
  {
    x: 3136.5,
    y: 231.5
  },
  {
    x: 3136.5,
    y: 216.5
  },
  {
    x: 3136.5,
    y: 201.5
  },
  {
    x: 3136.5,
    y: 186.5
  },
  {
    x: 3136.5,
    y: 171.5
  },
  {
    x: 3136.5,
    y: 156.5
  },
  {
    x: 3136.5,
    y: 111.5
  },
  {
    x: 3151.5,
    y: 2046.5
  },
  {
    x: 3151.5,
    y: 2031.5
  },
  {
    x: 3151.5,
    y: 2016.5
  },
  {
    x: 3151.5,
    y: 2001.5
  },
  {
    x: 3151.5,
    y: 1986.5
  },
  {
    x: 3151.5,
    y: 1971.5
  },
  {
    x: 3151.5,
    y: 1956.5
  },
  {
    x: 3151.5,
    y: 1941.5
  },
  {
    x: 3151.5,
    y: 1926.5
  },
  {
    x: 3151.5,
    y: 1911.5
  },
  {
    x: 3151.5,
    y: 1896.5
  },
  {
    x: 3151.5,
    y: 1881.5
  },
  {
    x: 3151.5,
    y: 1866.5
  },
  {
    x: 3151.5,
    y: 1851.5
  },
  {
    x: 3151.5,
    y: 1836.5
  },
  {
    x: 3151.5,
    y: 1821.5
  },
  {
    x: 3151.5,
    y: 1806.5
  },
  {
    x: 3151.5,
    y: 1791.5
  },
  {
    x: 3151.5,
    y: 1776.5
  },
  {
    x: 3151.5,
    y: 981.5
  },
  {
    x: 3151.5,
    y: 966.5
  },
  {
    x: 3151.5,
    y: 831.5
  },
  {
    x: 3151.5,
    y: 816.5
  },
  {
    x: 3151.5,
    y: 801.5
  },
  {
    x: 3151.5,
    y: 786.5
  },
  {
    x: 3151.5,
    y: 771.5
  },
  {
    x: 3151.5,
    y: 756.5
  },
  {
    x: 3151.5,
    y: 741.5
  },
  {
    x: 3151.5,
    y: 726.5
  },
  {
    x: 3151.5,
    y: 711.5
  },
  {
    x: 3151.5,
    y: 696.5
  },
  {
    x: 3151.5,
    y: 681.5
  },
  {
    x: 3151.5,
    y: 666.5
  },
  {
    x: 3151.5,
    y: 651.5
  },
  {
    x: 3151.5,
    y: 636.5
  },
  {
    x: 3151.5,
    y: 621.5
  },
  {
    x: 3151.5,
    y: 606.5
  },
  {
    x: 3151.5,
    y: 591.5
  },
  {
    x: 3151.5,
    y: 576.5
  },
  {
    x: 3151.5,
    y: 561.5
  },
  {
    x: 3151.5,
    y: 546.5
  },
  {
    x: 3151.5,
    y: 531.5
  },
  {
    x: 3151.5,
    y: 516.5
  },
  {
    x: 3151.5,
    y: 501.5
  },
  {
    x: 3151.5,
    y: 486.5
  },
  {
    x: 3151.5,
    y: 471.5
  },
  {
    x: 3151.5,
    y: 456.5
  },
  {
    x: 3151.5,
    y: 441.5
  },
  {
    x: 3151.5,
    y: 426.5
  },
  {
    x: 3151.5,
    y: 411.5
  },
  {
    x: 3151.5,
    y: 396.5
  },
  {
    x: 3151.5,
    y: 381.5
  },
  {
    x: 3151.5,
    y: 366.5
  },
  {
    x: 3151.5,
    y: 351.5
  },
  {
    x: 3151.5,
    y: 336.5
  },
  {
    x: 3151.5,
    y: 321.5
  },
  {
    x: 3151.5,
    y: 306.5
  },
  {
    x: 3151.5,
    y: 291.5
  },
  {
    x: 3151.5,
    y: 276.5
  },
  {
    x: 3151.5,
    y: 261.5
  },
  {
    x: 3151.5,
    y: 246.5
  },
  {
    x: 3151.5,
    y: 231.5
  },
  {
    x: 3151.5,
    y: 216.5
  },
  {
    x: 3151.5,
    y: 201.5
  },
  {
    x: 3151.5,
    y: 186.5
  },
  {
    x: 3151.5,
    y: 171.5
  },
  {
    x: 3151.5,
    y: 156.5
  },
  {
    x: 3151.5,
    y: 126.5
  },
  {
    x: 3151.5,
    y: 111.5
  },
  {
    x: 3166.5,
    y: 2046.5
  },
  {
    x: 3166.5,
    y: 2031.5
  },
  {
    x: 3166.5,
    y: 2016.5
  },
  {
    x: 3166.5,
    y: 2001.5
  },
  {
    x: 3166.5,
    y: 1986.5
  },
  {
    x: 3166.5,
    y: 1971.5
  },
  {
    x: 3166.5,
    y: 1956.5
  },
  {
    x: 3166.5,
    y: 1941.5
  },
  {
    x: 3166.5,
    y: 1926.5
  },
  {
    x: 3166.5,
    y: 1911.5
  },
  {
    x: 3166.5,
    y: 1896.5
  },
  {
    x: 3166.5,
    y: 1881.5
  },
  {
    x: 3166.5,
    y: 1866.5
  },
  {
    x: 3166.5,
    y: 1851.5
  },
  {
    x: 3166.5,
    y: 1836.5
  },
  {
    x: 3166.5,
    y: 1821.5
  },
  {
    x: 3166.5,
    y: 1806.5
  },
  {
    x: 3166.5,
    y: 1791.5
  },
  {
    x: 3166.5,
    y: 996.5
  },
  {
    x: 3166.5,
    y: 981.5
  },
  {
    x: 3166.5,
    y: 936.5
  },
  {
    x: 3166.5,
    y: 921.5
  },
  {
    x: 3166.5,
    y: 906.5
  },
  {
    x: 3166.5,
    y: 891.5
  },
  {
    x: 3166.5,
    y: 876.5
  },
  {
    x: 3166.5,
    y: 861.5
  },
  {
    x: 3166.5,
    y: 846.5
  },
  {
    x: 3166.5,
    y: 831.5
  },
  {
    x: 3166.5,
    y: 816.5
  },
  {
    x: 3166.5,
    y: 801.5
  },
  {
    x: 3166.5,
    y: 786.5
  },
  {
    x: 3166.5,
    y: 771.5
  },
  {
    x: 3166.5,
    y: 756.5
  },
  {
    x: 3166.5,
    y: 741.5
  },
  {
    x: 3166.5,
    y: 726.5
  },
  {
    x: 3166.5,
    y: 711.5
  },
  {
    x: 3166.5,
    y: 696.5
  },
  {
    x: 3166.5,
    y: 681.5
  },
  {
    x: 3166.5,
    y: 666.5
  },
  {
    x: 3166.5,
    y: 651.5
  },
  {
    x: 3166.5,
    y: 636.5
  },
  {
    x: 3166.5,
    y: 621.5
  },
  {
    x: 3166.5,
    y: 606.5
  },
  {
    x: 3166.5,
    y: 591.5
  },
  {
    x: 3166.5,
    y: 576.5
  },
  {
    x: 3166.5,
    y: 561.5
  },
  {
    x: 3166.5,
    y: 546.5
  },
  {
    x: 3166.5,
    y: 531.5
  },
  {
    x: 3166.5,
    y: 516.5
  },
  {
    x: 3166.5,
    y: 501.5
  },
  {
    x: 3166.5,
    y: 486.5
  },
  {
    x: 3166.5,
    y: 471.5
  },
  {
    x: 3166.5,
    y: 456.5
  },
  {
    x: 3166.5,
    y: 441.5
  },
  {
    x: 3166.5,
    y: 426.5
  },
  {
    x: 3166.5,
    y: 411.5
  },
  {
    x: 3166.5,
    y: 396.5
  },
  {
    x: 3166.5,
    y: 381.5
  },
  {
    x: 3166.5,
    y: 366.5
  },
  {
    x: 3166.5,
    y: 351.5
  },
  {
    x: 3166.5,
    y: 336.5
  },
  {
    x: 3166.5,
    y: 321.5
  },
  {
    x: 3166.5,
    y: 306.5
  },
  {
    x: 3166.5,
    y: 291.5
  },
  {
    x: 3166.5,
    y: 276.5
  },
  {
    x: 3166.5,
    y: 261.5
  },
  {
    x: 3166.5,
    y: 246.5
  },
  {
    x: 3166.5,
    y: 231.5
  },
  {
    x: 3166.5,
    y: 216.5
  },
  {
    x: 3166.5,
    y: 201.5
  },
  {
    x: 3166.5,
    y: 186.5
  },
  {
    x: 3166.5,
    y: 171.5
  },
  {
    x: 3166.5,
    y: 156.5
  },
  {
    x: 3166.5,
    y: 126.5
  },
  {
    x: 3166.5,
    y: 111.5
  },
  {
    x: 3181.5,
    y: 2046.5
  },
  {
    x: 3181.5,
    y: 2031.5
  },
  {
    x: 3181.5,
    y: 2016.5
  },
  {
    x: 3181.5,
    y: 2001.5
  },
  {
    x: 3181.5,
    y: 1986.5
  },
  {
    x: 3181.5,
    y: 1971.5
  },
  {
    x: 3181.5,
    y: 1956.5
  },
  {
    x: 3181.5,
    y: 1941.5
  },
  {
    x: 3181.5,
    y: 1926.5
  },
  {
    x: 3181.5,
    y: 1911.5
  },
  {
    x: 3181.5,
    y: 1896.5
  },
  {
    x: 3181.5,
    y: 1881.5
  },
  {
    x: 3181.5,
    y: 1866.5
  },
  {
    x: 3181.5,
    y: 1851.5
  },
  {
    x: 3181.5,
    y: 1836.5
  },
  {
    x: 3181.5,
    y: 1821.5
  },
  {
    x: 3181.5,
    y: 1806.5
  },
  {
    x: 3181.5,
    y: 1791.5
  },
  {
    x: 3181.5,
    y: 1011.5
  },
  {
    x: 3181.5,
    y: 996.5
  },
  {
    x: 3181.5,
    y: 936.5
  },
  {
    x: 3181.5,
    y: 921.5
  },
  {
    x: 3181.5,
    y: 891.5
  },
  {
    x: 3181.5,
    y: 876.5
  },
  {
    x: 3181.5,
    y: 861.5
  },
  {
    x: 3181.5,
    y: 846.5
  },
  {
    x: 3181.5,
    y: 831.5
  },
  {
    x: 3181.5,
    y: 816.5
  },
  {
    x: 3181.5,
    y: 801.5
  },
  {
    x: 3181.5,
    y: 786.5
  },
  {
    x: 3181.5,
    y: 771.5
  },
  {
    x: 3181.5,
    y: 756.5
  },
  {
    x: 3181.5,
    y: 741.5
  },
  {
    x: 3181.5,
    y: 726.5
  },
  {
    x: 3181.5,
    y: 711.5
  },
  {
    x: 3181.5,
    y: 696.5
  },
  {
    x: 3181.5,
    y: 681.5
  },
  {
    x: 3181.5,
    y: 666.5
  },
  {
    x: 3181.5,
    y: 651.5
  },
  {
    x: 3181.5,
    y: 636.5
  },
  {
    x: 3181.5,
    y: 621.5
  },
  {
    x: 3181.5,
    y: 606.5
  },
  {
    x: 3181.5,
    y: 591.5
  },
  {
    x: 3181.5,
    y: 576.5
  },
  {
    x: 3181.5,
    y: 561.5
  },
  {
    x: 3181.5,
    y: 546.5
  },
  {
    x: 3181.5,
    y: 531.5
  },
  {
    x: 3181.5,
    y: 516.5
  },
  {
    x: 3181.5,
    y: 501.5
  },
  {
    x: 3181.5,
    y: 486.5
  },
  {
    x: 3181.5,
    y: 471.5
  },
  {
    x: 3181.5,
    y: 456.5
  },
  {
    x: 3181.5,
    y: 441.5
  },
  {
    x: 3181.5,
    y: 426.5
  },
  {
    x: 3181.5,
    y: 411.5
  },
  {
    x: 3181.5,
    y: 396.5
  },
  {
    x: 3181.5,
    y: 381.5
  },
  {
    x: 3181.5,
    y: 366.5
  },
  {
    x: 3181.5,
    y: 351.5
  },
  {
    x: 3181.5,
    y: 336.5
  },
  {
    x: 3181.5,
    y: 321.5
  },
  {
    x: 3181.5,
    y: 306.5
  },
  {
    x: 3181.5,
    y: 291.5
  },
  {
    x: 3181.5,
    y: 276.5
  },
  {
    x: 3181.5,
    y: 261.5
  },
  {
    x: 3181.5,
    y: 246.5
  },
  {
    x: 3181.5,
    y: 231.5
  },
  {
    x: 3181.5,
    y: 216.5
  },
  {
    x: 3181.5,
    y: 201.5
  },
  {
    x: 3181.5,
    y: 186.5
  },
  {
    x: 3181.5,
    y: 171.5
  },
  {
    x: 3181.5,
    y: 156.5
  },
  {
    x: 3181.5,
    y: 126.5
  },
  {
    x: 3196.5,
    y: 2046.5
  },
  {
    x: 3196.5,
    y: 2031.5
  },
  {
    x: 3196.5,
    y: 2016.5
  },
  {
    x: 3196.5,
    y: 2001.5
  },
  {
    x: 3196.5,
    y: 1986.5
  },
  {
    x: 3196.5,
    y: 1971.5
  },
  {
    x: 3196.5,
    y: 1956.5
  },
  {
    x: 3196.5,
    y: 1941.5
  },
  {
    x: 3196.5,
    y: 1926.5
  },
  {
    x: 3196.5,
    y: 1911.5
  },
  {
    x: 3196.5,
    y: 1896.5
  },
  {
    x: 3196.5,
    y: 1881.5
  },
  {
    x: 3196.5,
    y: 1866.5
  },
  {
    x: 3196.5,
    y: 1851.5
  },
  {
    x: 3196.5,
    y: 1836.5
  },
  {
    x: 3196.5,
    y: 1821.5
  },
  {
    x: 3196.5,
    y: 1806.5
  },
  {
    x: 3196.5,
    y: 1791.5
  },
  {
    x: 3196.5,
    y: 1041.5
  },
  {
    x: 3196.5,
    y: 1026.5
  },
  {
    x: 3196.5,
    y: 1011.5
  },
  {
    x: 3196.5,
    y: 996.5
  },
  {
    x: 3196.5,
    y: 981.5
  },
  {
    x: 3196.5,
    y: 966.5
  },
  {
    x: 3196.5,
    y: 951.5
  },
  {
    x: 3196.5,
    y: 876.5
  },
  {
    x: 3196.5,
    y: 861.5
  },
  {
    x: 3196.5,
    y: 846.5
  },
  {
    x: 3196.5,
    y: 831.5
  },
  {
    x: 3196.5,
    y: 816.5
  },
  {
    x: 3196.5,
    y: 801.5
  },
  {
    x: 3196.5,
    y: 786.5
  },
  {
    x: 3196.5,
    y: 771.5
  },
  {
    x: 3196.5,
    y: 756.5
  },
  {
    x: 3196.5,
    y: 741.5
  },
  {
    x: 3196.5,
    y: 726.5
  },
  {
    x: 3196.5,
    y: 711.5
  },
  {
    x: 3196.5,
    y: 696.5
  },
  {
    x: 3196.5,
    y: 681.5
  },
  {
    x: 3196.5,
    y: 666.5
  },
  {
    x: 3196.5,
    y: 651.5
  },
  {
    x: 3196.5,
    y: 636.5
  },
  {
    x: 3196.5,
    y: 621.5
  },
  {
    x: 3196.5,
    y: 606.5
  },
  {
    x: 3196.5,
    y: 591.5
  },
  {
    x: 3196.5,
    y: 576.5
  },
  {
    x: 3196.5,
    y: 561.5
  },
  {
    x: 3196.5,
    y: 546.5
  },
  {
    x: 3196.5,
    y: 531.5
  },
  {
    x: 3196.5,
    y: 516.5
  },
  {
    x: 3196.5,
    y: 501.5
  },
  {
    x: 3196.5,
    y: 486.5
  },
  {
    x: 3196.5,
    y: 471.5
  },
  {
    x: 3196.5,
    y: 456.5
  },
  {
    x: 3196.5,
    y: 441.5
  },
  {
    x: 3196.5,
    y: 426.5
  },
  {
    x: 3196.5,
    y: 411.5
  },
  {
    x: 3196.5,
    y: 396.5
  },
  {
    x: 3196.5,
    y: 381.5
  },
  {
    x: 3196.5,
    y: 366.5
  },
  {
    x: 3196.5,
    y: 351.5
  },
  {
    x: 3196.5,
    y: 336.5
  },
  {
    x: 3196.5,
    y: 321.5
  },
  {
    x: 3196.5,
    y: 306.5
  },
  {
    x: 3196.5,
    y: 291.5
  },
  {
    x: 3196.5,
    y: 276.5
  },
  {
    x: 3196.5,
    y: 261.5
  },
  {
    x: 3196.5,
    y: 246.5
  },
  {
    x: 3196.5,
    y: 231.5
  },
  {
    x: 3196.5,
    y: 216.5
  },
  {
    x: 3196.5,
    y: 201.5
  },
  {
    x: 3196.5,
    y: 186.5
  },
  {
    x: 3196.5,
    y: 171.5
  },
  {
    x: 3196.5,
    y: 156.5
  },
  {
    x: 3196.5,
    y: 126.5
  },
  {
    x: 3211.5,
    y: 2046.5
  },
  {
    x: 3211.5,
    y: 2031.5
  },
  {
    x: 3211.5,
    y: 2016.5
  },
  {
    x: 3211.5,
    y: 2001.5
  },
  {
    x: 3211.5,
    y: 1986.5
  },
  {
    x: 3211.5,
    y: 1971.5
  },
  {
    x: 3211.5,
    y: 1956.5
  },
  {
    x: 3211.5,
    y: 1941.5
  },
  {
    x: 3211.5,
    y: 1926.5
  },
  {
    x: 3211.5,
    y: 1911.5
  },
  {
    x: 3211.5,
    y: 1896.5
  },
  {
    x: 3211.5,
    y: 1881.5
  },
  {
    x: 3211.5,
    y: 1866.5
  },
  {
    x: 3211.5,
    y: 1851.5
  },
  {
    x: 3211.5,
    y: 1836.5
  },
  {
    x: 3211.5,
    y: 1821.5
  },
  {
    x: 3211.5,
    y: 1806.5
  },
  {
    x: 3211.5,
    y: 1791.5
  },
  {
    x: 3211.5,
    y: 1776.5
  },
  {
    x: 3211.5,
    y: 1056.5
  },
  {
    x: 3211.5,
    y: 1041.5
  },
  {
    x: 3211.5,
    y: 1026.5
  },
  {
    x: 3211.5,
    y: 1011.5
  },
  {
    x: 3211.5,
    y: 996.5
  },
  {
    x: 3211.5,
    y: 981.5
  },
  {
    x: 3211.5,
    y: 966.5
  },
  {
    x: 3211.5,
    y: 876.5
  },
  {
    x: 3211.5,
    y: 861.5
  },
  {
    x: 3211.5,
    y: 846.5
  },
  {
    x: 3211.5,
    y: 831.5
  },
  {
    x: 3211.5,
    y: 816.5
  },
  {
    x: 3211.5,
    y: 801.5
  },
  {
    x: 3211.5,
    y: 786.5
  },
  {
    x: 3211.5,
    y: 771.5
  },
  {
    x: 3211.5,
    y: 756.5
  },
  {
    x: 3211.5,
    y: 741.5
  },
  {
    x: 3211.5,
    y: 726.5
  },
  {
    x: 3211.5,
    y: 711.5
  },
  {
    x: 3211.5,
    y: 696.5
  },
  {
    x: 3211.5,
    y: 681.5
  },
  {
    x: 3211.5,
    y: 666.5
  },
  {
    x: 3211.5,
    y: 651.5
  },
  {
    x: 3211.5,
    y: 636.5
  },
  {
    x: 3211.5,
    y: 621.5
  },
  {
    x: 3211.5,
    y: 606.5
  },
  {
    x: 3211.5,
    y: 591.5
  },
  {
    x: 3211.5,
    y: 576.5
  },
  {
    x: 3211.5,
    y: 561.5
  },
  {
    x: 3211.5,
    y: 546.5
  },
  {
    x: 3211.5,
    y: 531.5
  },
  {
    x: 3211.5,
    y: 516.5
  },
  {
    x: 3211.5,
    y: 501.5
  },
  {
    x: 3211.5,
    y: 486.5
  },
  {
    x: 3211.5,
    y: 471.5
  },
  {
    x: 3211.5,
    y: 456.5
  },
  {
    x: 3211.5,
    y: 441.5
  },
  {
    x: 3211.5,
    y: 426.5
  },
  {
    x: 3211.5,
    y: 411.5
  },
  {
    x: 3211.5,
    y: 396.5
  },
  {
    x: 3211.5,
    y: 381.5
  },
  {
    x: 3211.5,
    y: 366.5
  },
  {
    x: 3211.5,
    y: 351.5
  },
  {
    x: 3211.5,
    y: 336.5
  },
  {
    x: 3211.5,
    y: 321.5
  },
  {
    x: 3211.5,
    y: 306.5
  },
  {
    x: 3211.5,
    y: 291.5
  },
  {
    x: 3211.5,
    y: 276.5
  },
  {
    x: 3211.5,
    y: 261.5
  },
  {
    x: 3211.5,
    y: 246.5
  },
  {
    x: 3211.5,
    y: 231.5
  },
  {
    x: 3211.5,
    y: 216.5
  },
  {
    x: 3211.5,
    y: 201.5
  },
  {
    x: 3211.5,
    y: 186.5
  },
  {
    x: 3211.5,
    y: 171.5
  },
  {
    x: 3211.5,
    y: 156.5
  },
  {
    x: 3211.5,
    y: 141.5
  },
  {
    x: 3211.5,
    y: 126.5
  },
  {
    x: 3226.5,
    y: 2046.5
  },
  {
    x: 3226.5,
    y: 2031.5
  },
  {
    x: 3226.5,
    y: 2016.5
  },
  {
    x: 3226.5,
    y: 2001.5
  },
  {
    x: 3226.5,
    y: 1986.5
  },
  {
    x: 3226.5,
    y: 1971.5
  },
  {
    x: 3226.5,
    y: 1956.5
  },
  {
    x: 3226.5,
    y: 1941.5
  },
  {
    x: 3226.5,
    y: 1926.5
  },
  {
    x: 3226.5,
    y: 1911.5
  },
  {
    x: 3226.5,
    y: 1896.5
  },
  {
    x: 3226.5,
    y: 1881.5
  },
  {
    x: 3226.5,
    y: 1866.5
  },
  {
    x: 3226.5,
    y: 1851.5
  },
  {
    x: 3226.5,
    y: 1836.5
  },
  {
    x: 3226.5,
    y: 1821.5
  },
  {
    x: 3226.5,
    y: 1806.5
  },
  {
    x: 3226.5,
    y: 1791.5
  },
  {
    x: 3226.5,
    y: 1776.5
  },
  {
    x: 3226.5,
    y: 1071.5
  },
  {
    x: 3226.5,
    y: 1056.5
  },
  {
    x: 3226.5,
    y: 1041.5
  },
  {
    x: 3226.5,
    y: 1026.5
  },
  {
    x: 3226.5,
    y: 996.5
  },
  {
    x: 3226.5,
    y: 891.5
  },
  {
    x: 3226.5,
    y: 876.5
  },
  {
    x: 3226.5,
    y: 861.5
  },
  {
    x: 3226.5,
    y: 846.5
  },
  {
    x: 3226.5,
    y: 831.5
  },
  {
    x: 3226.5,
    y: 816.5
  },
  {
    x: 3226.5,
    y: 801.5
  },
  {
    x: 3226.5,
    y: 786.5
  },
  {
    x: 3226.5,
    y: 771.5
  },
  {
    x: 3226.5,
    y: 756.5
  },
  {
    x: 3226.5,
    y: 741.5
  },
  {
    x: 3226.5,
    y: 726.5
  },
  {
    x: 3226.5,
    y: 711.5
  },
  {
    x: 3226.5,
    y: 696.5
  },
  {
    x: 3226.5,
    y: 681.5
  },
  {
    x: 3226.5,
    y: 666.5
  },
  {
    x: 3226.5,
    y: 651.5
  },
  {
    x: 3226.5,
    y: 636.5
  },
  {
    x: 3226.5,
    y: 621.5
  },
  {
    x: 3226.5,
    y: 606.5
  },
  {
    x: 3226.5,
    y: 591.5
  },
  {
    x: 3226.5,
    y: 576.5
  },
  {
    x: 3226.5,
    y: 561.5
  },
  {
    x: 3226.5,
    y: 546.5
  },
  {
    x: 3226.5,
    y: 531.5
  },
  {
    x: 3226.5,
    y: 516.5
  },
  {
    x: 3226.5,
    y: 501.5
  },
  {
    x: 3226.5,
    y: 486.5
  },
  {
    x: 3226.5,
    y: 471.5
  },
  {
    x: 3226.5,
    y: 456.5
  },
  {
    x: 3226.5,
    y: 441.5
  },
  {
    x: 3226.5,
    y: 426.5
  },
  {
    x: 3226.5,
    y: 411.5
  },
  {
    x: 3226.5,
    y: 396.5
  },
  {
    x: 3226.5,
    y: 381.5
  },
  {
    x: 3226.5,
    y: 366.5
  },
  {
    x: 3226.5,
    y: 351.5
  },
  {
    x: 3226.5,
    y: 336.5
  },
  {
    x: 3226.5,
    y: 321.5
  },
  {
    x: 3226.5,
    y: 306.5
  },
  {
    x: 3226.5,
    y: 291.5
  },
  {
    x: 3226.5,
    y: 276.5
  },
  {
    x: 3226.5,
    y: 261.5
  },
  {
    x: 3226.5,
    y: 246.5
  },
  {
    x: 3226.5,
    y: 231.5
  },
  {
    x: 3226.5,
    y: 216.5
  },
  {
    x: 3226.5,
    y: 201.5
  },
  {
    x: 3226.5,
    y: 186.5
  },
  {
    x: 3226.5,
    y: 171.5
  },
  {
    x: 3226.5,
    y: 156.5
  },
  {
    x: 3226.5,
    y: 141.5
  },
  {
    x: 3226.5,
    y: 126.5
  },
  {
    x: 3241.5,
    y: 2046.5
  },
  {
    x: 3241.5,
    y: 2031.5
  },
  {
    x: 3241.5,
    y: 2016.5
  },
  {
    x: 3241.5,
    y: 2001.5
  },
  {
    x: 3241.5,
    y: 1986.5
  },
  {
    x: 3241.5,
    y: 1971.5
  },
  {
    x: 3241.5,
    y: 1956.5
  },
  {
    x: 3241.5,
    y: 1941.5
  },
  {
    x: 3241.5,
    y: 1926.5
  },
  {
    x: 3241.5,
    y: 1911.5
  },
  {
    x: 3241.5,
    y: 1896.5
  },
  {
    x: 3241.5,
    y: 1881.5
  },
  {
    x: 3241.5,
    y: 1866.5
  },
  {
    x: 3241.5,
    y: 1851.5
  },
  {
    x: 3241.5,
    y: 1836.5
  },
  {
    x: 3241.5,
    y: 1821.5
  },
  {
    x: 3241.5,
    y: 1806.5
  },
  {
    x: 3241.5,
    y: 1791.5
  },
  {
    x: 3241.5,
    y: 1776.5
  },
  {
    x: 3241.5,
    y: 1086.5
  },
  {
    x: 3241.5,
    y: 1071.5
  },
  {
    x: 3241.5,
    y: 1056.5
  },
  {
    x: 3241.5,
    y: 921.5
  },
  {
    x: 3241.5,
    y: 906.5
  },
  {
    x: 3241.5,
    y: 891.5
  },
  {
    x: 3241.5,
    y: 876.5
  },
  {
    x: 3241.5,
    y: 861.5
  },
  {
    x: 3241.5,
    y: 846.5
  },
  {
    x: 3241.5,
    y: 831.5
  },
  {
    x: 3241.5,
    y: 816.5
  },
  {
    x: 3241.5,
    y: 801.5
  },
  {
    x: 3241.5,
    y: 786.5
  },
  {
    x: 3241.5,
    y: 771.5
  },
  {
    x: 3241.5,
    y: 756.5
  },
  {
    x: 3241.5,
    y: 741.5
  },
  {
    x: 3241.5,
    y: 726.5
  },
  {
    x: 3241.5,
    y: 711.5
  },
  {
    x: 3241.5,
    y: 696.5
  },
  {
    x: 3241.5,
    y: 681.5
  },
  {
    x: 3241.5,
    y: 666.5
  },
  {
    x: 3241.5,
    y: 651.5
  },
  {
    x: 3241.5,
    y: 636.5
  },
  {
    x: 3241.5,
    y: 621.5
  },
  {
    x: 3241.5,
    y: 606.5
  },
  {
    x: 3241.5,
    y: 591.5
  },
  {
    x: 3241.5,
    y: 576.5
  },
  {
    x: 3241.5,
    y: 561.5
  },
  {
    x: 3241.5,
    y: 546.5
  },
  {
    x: 3241.5,
    y: 531.5
  },
  {
    x: 3241.5,
    y: 516.5
  },
  {
    x: 3241.5,
    y: 501.5
  },
  {
    x: 3241.5,
    y: 486.5
  },
  {
    x: 3241.5,
    y: 471.5
  },
  {
    x: 3241.5,
    y: 456.5
  },
  {
    x: 3241.5,
    y: 441.5
  },
  {
    x: 3241.5,
    y: 426.5
  },
  {
    x: 3241.5,
    y: 411.5
  },
  {
    x: 3241.5,
    y: 396.5
  },
  {
    x: 3241.5,
    y: 381.5
  },
  {
    x: 3241.5,
    y: 366.5
  },
  {
    x: 3241.5,
    y: 351.5
  },
  {
    x: 3241.5,
    y: 336.5
  },
  {
    x: 3241.5,
    y: 321.5
  },
  {
    x: 3241.5,
    y: 306.5
  },
  {
    x: 3241.5,
    y: 291.5
  },
  {
    x: 3241.5,
    y: 276.5
  },
  {
    x: 3241.5,
    y: 261.5
  },
  {
    x: 3241.5,
    y: 246.5
  },
  {
    x: 3241.5,
    y: 231.5
  },
  {
    x: 3241.5,
    y: 216.5
  },
  {
    x: 3241.5,
    y: 201.5
  },
  {
    x: 3241.5,
    y: 186.5
  },
  {
    x: 3241.5,
    y: 171.5
  },
  {
    x: 3241.5,
    y: 156.5
  },
  {
    x: 3241.5,
    y: 141.5
  },
  {
    x: 3241.5,
    y: 126.5
  },
  {
    x: 3256.5,
    y: 2046.5
  },
  {
    x: 3256.5,
    y: 2031.5
  },
  {
    x: 3256.5,
    y: 2016.5
  },
  {
    x: 3256.5,
    y: 2001.5
  },
  {
    x: 3256.5,
    y: 1986.5
  },
  {
    x: 3256.5,
    y: 1971.5
  },
  {
    x: 3256.5,
    y: 1956.5
  },
  {
    x: 3256.5,
    y: 1941.5
  },
  {
    x: 3256.5,
    y: 1926.5
  },
  {
    x: 3256.5,
    y: 1911.5
  },
  {
    x: 3256.5,
    y: 1896.5
  },
  {
    x: 3256.5,
    y: 1881.5
  },
  {
    x: 3256.5,
    y: 1866.5
  },
  {
    x: 3256.5,
    y: 1851.5
  },
  {
    x: 3256.5,
    y: 1836.5
  },
  {
    x: 3256.5,
    y: 1821.5
  },
  {
    x: 3256.5,
    y: 1806.5
  },
  {
    x: 3256.5,
    y: 1791.5
  },
  {
    x: 3256.5,
    y: 1101.5
  },
  {
    x: 3256.5,
    y: 1056.5
  },
  {
    x: 3256.5,
    y: 906.5
  },
  {
    x: 3256.5,
    y: 891.5
  },
  {
    x: 3256.5,
    y: 876.5
  },
  {
    x: 3256.5,
    y: 861.5
  },
  {
    x: 3256.5,
    y: 846.5
  },
  {
    x: 3256.5,
    y: 831.5
  },
  {
    x: 3256.5,
    y: 816.5
  },
  {
    x: 3256.5,
    y: 786.5
  },
  {
    x: 3256.5,
    y: 771.5
  },
  {
    x: 3256.5,
    y: 756.5
  },
  {
    x: 3256.5,
    y: 741.5
  },
  {
    x: 3256.5,
    y: 726.5
  },
  {
    x: 3256.5,
    y: 711.5
  },
  {
    x: 3256.5,
    y: 696.5
  },
  {
    x: 3256.5,
    y: 681.5
  },
  {
    x: 3256.5,
    y: 666.5
  },
  {
    x: 3256.5,
    y: 651.5
  },
  {
    x: 3256.5,
    y: 636.5
  },
  {
    x: 3256.5,
    y: 621.5
  },
  {
    x: 3256.5,
    y: 606.5
  },
  {
    x: 3256.5,
    y: 591.5
  },
  {
    x: 3256.5,
    y: 576.5
  },
  {
    x: 3256.5,
    y: 561.5
  },
  {
    x: 3256.5,
    y: 546.5
  },
  {
    x: 3256.5,
    y: 531.5
  },
  {
    x: 3256.5,
    y: 516.5
  },
  {
    x: 3256.5,
    y: 501.5
  },
  {
    x: 3256.5,
    y: 486.5
  },
  {
    x: 3256.5,
    y: 471.5
  },
  {
    x: 3256.5,
    y: 456.5
  },
  {
    x: 3256.5,
    y: 441.5
  },
  {
    x: 3256.5,
    y: 426.5
  },
  {
    x: 3256.5,
    y: 411.5
  },
  {
    x: 3256.5,
    y: 396.5
  },
  {
    x: 3256.5,
    y: 381.5
  },
  {
    x: 3256.5,
    y: 366.5
  },
  {
    x: 3256.5,
    y: 351.5
  },
  {
    x: 3256.5,
    y: 336.5
  },
  {
    x: 3256.5,
    y: 321.5
  },
  {
    x: 3256.5,
    y: 306.5
  },
  {
    x: 3256.5,
    y: 291.5
  },
  {
    x: 3256.5,
    y: 276.5
  },
  {
    x: 3256.5,
    y: 261.5
  },
  {
    x: 3256.5,
    y: 246.5
  },
  {
    x: 3256.5,
    y: 231.5
  },
  {
    x: 3256.5,
    y: 216.5
  },
  {
    x: 3256.5,
    y: 201.5
  },
  {
    x: 3256.5,
    y: 186.5
  },
  {
    x: 3256.5,
    y: 171.5
  },
  {
    x: 3256.5,
    y: 156.5
  },
  {
    x: 3256.5,
    y: 141.5
  },
  {
    x: 3271.5,
    y: 2046.5
  },
  {
    x: 3271.5,
    y: 2031.5
  },
  {
    x: 3271.5,
    y: 2016.5
  },
  {
    x: 3271.5,
    y: 2001.5
  },
  {
    x: 3271.5,
    y: 1986.5
  },
  {
    x: 3271.5,
    y: 1971.5
  },
  {
    x: 3271.5,
    y: 1956.5
  },
  {
    x: 3271.5,
    y: 1941.5
  },
  {
    x: 3271.5,
    y: 1926.5
  },
  {
    x: 3271.5,
    y: 1911.5
  },
  {
    x: 3271.5,
    y: 1896.5
  },
  {
    x: 3271.5,
    y: 1881.5
  },
  {
    x: 3271.5,
    y: 1866.5
  },
  {
    x: 3271.5,
    y: 1851.5
  },
  {
    x: 3271.5,
    y: 1836.5
  },
  {
    x: 3271.5,
    y: 1821.5
  },
  {
    x: 3271.5,
    y: 1806.5
  },
  {
    x: 3271.5,
    y: 1791.5
  },
  {
    x: 3271.5,
    y: 1101.5
  },
  {
    x: 3271.5,
    y: 1056.5
  },
  {
    x: 3271.5,
    y: 891.5
  },
  {
    x: 3271.5,
    y: 876.5
  },
  {
    x: 3271.5,
    y: 861.5
  },
  {
    x: 3271.5,
    y: 846.5
  },
  {
    x: 3271.5,
    y: 771.5
  },
  {
    x: 3271.5,
    y: 756.5
  },
  {
    x: 3271.5,
    y: 741.5
  },
  {
    x: 3271.5,
    y: 726.5
  },
  {
    x: 3271.5,
    y: 711.5
  },
  {
    x: 3271.5,
    y: 696.5
  },
  {
    x: 3271.5,
    y: 681.5
  },
  {
    x: 3271.5,
    y: 666.5
  },
  {
    x: 3271.5,
    y: 651.5
  },
  {
    x: 3271.5,
    y: 636.5
  },
  {
    x: 3271.5,
    y: 621.5
  },
  {
    x: 3271.5,
    y: 606.5
  },
  {
    x: 3271.5,
    y: 591.5
  },
  {
    x: 3271.5,
    y: 576.5
  },
  {
    x: 3271.5,
    y: 561.5
  },
  {
    x: 3271.5,
    y: 546.5
  },
  {
    x: 3271.5,
    y: 531.5
  },
  {
    x: 3271.5,
    y: 516.5
  },
  {
    x: 3271.5,
    y: 501.5
  },
  {
    x: 3271.5,
    y: 486.5
  },
  {
    x: 3271.5,
    y: 471.5
  },
  {
    x: 3271.5,
    y: 456.5
  },
  {
    x: 3271.5,
    y: 441.5
  },
  {
    x: 3271.5,
    y: 426.5
  },
  {
    x: 3271.5,
    y: 411.5
  },
  {
    x: 3271.5,
    y: 396.5
  },
  {
    x: 3271.5,
    y: 381.5
  },
  {
    x: 3271.5,
    y: 366.5
  },
  {
    x: 3271.5,
    y: 351.5
  },
  {
    x: 3271.5,
    y: 336.5
  },
  {
    x: 3271.5,
    y: 321.5
  },
  {
    x: 3271.5,
    y: 306.5
  },
  {
    x: 3271.5,
    y: 291.5
  },
  {
    x: 3271.5,
    y: 276.5
  },
  {
    x: 3271.5,
    y: 261.5
  },
  {
    x: 3271.5,
    y: 246.5
  },
  {
    x: 3271.5,
    y: 231.5
  },
  {
    x: 3271.5,
    y: 216.5
  },
  {
    x: 3271.5,
    y: 201.5
  },
  {
    x: 3271.5,
    y: 186.5
  },
  {
    x: 3271.5,
    y: 171.5
  },
  {
    x: 3271.5,
    y: 156.5
  },
  {
    x: 3286.5,
    y: 2046.5
  },
  {
    x: 3286.5,
    y: 2031.5
  },
  {
    x: 3286.5,
    y: 2016.5
  },
  {
    x: 3286.5,
    y: 2001.5
  },
  {
    x: 3286.5,
    y: 1986.5
  },
  {
    x: 3286.5,
    y: 1971.5
  },
  {
    x: 3286.5,
    y: 1956.5
  },
  {
    x: 3286.5,
    y: 1941.5
  },
  {
    x: 3286.5,
    y: 1926.5
  },
  {
    x: 3286.5,
    y: 1911.5
  },
  {
    x: 3286.5,
    y: 1896.5
  },
  {
    x: 3286.5,
    y: 1881.5
  },
  {
    x: 3286.5,
    y: 1866.5
  },
  {
    x: 3286.5,
    y: 1851.5
  },
  {
    x: 3286.5,
    y: 1836.5
  },
  {
    x: 3286.5,
    y: 1821.5
  },
  {
    x: 3286.5,
    y: 1806.5
  },
  {
    x: 3286.5,
    y: 1791.5
  },
  {
    x: 3286.5,
    y: 1101.5
  },
  {
    x: 3286.5,
    y: 891.5
  },
  {
    x: 3286.5,
    y: 876.5
  },
  {
    x: 3286.5,
    y: 861.5
  },
  {
    x: 3286.5,
    y: 801.5
  },
  {
    x: 3286.5,
    y: 771.5
  },
  {
    x: 3286.5,
    y: 756.5
  },
  {
    x: 3286.5,
    y: 741.5
  },
  {
    x: 3286.5,
    y: 726.5
  },
  {
    x: 3286.5,
    y: 711.5
  },
  {
    x: 3286.5,
    y: 696.5
  },
  {
    x: 3286.5,
    y: 681.5
  },
  {
    x: 3286.5,
    y: 666.5
  },
  {
    x: 3286.5,
    y: 651.5
  },
  {
    x: 3286.5,
    y: 636.5
  },
  {
    x: 3286.5,
    y: 621.5
  },
  {
    x: 3286.5,
    y: 606.5
  },
  {
    x: 3286.5,
    y: 591.5
  },
  {
    x: 3286.5,
    y: 576.5
  },
  {
    x: 3286.5,
    y: 561.5
  },
  {
    x: 3286.5,
    y: 546.5
  },
  {
    x: 3286.5,
    y: 531.5
  },
  {
    x: 3286.5,
    y: 516.5
  },
  {
    x: 3286.5,
    y: 501.5
  },
  {
    x: 3286.5,
    y: 486.5
  },
  {
    x: 3286.5,
    y: 471.5
  },
  {
    x: 3286.5,
    y: 456.5
  },
  {
    x: 3286.5,
    y: 441.5
  },
  {
    x: 3286.5,
    y: 426.5
  },
  {
    x: 3286.5,
    y: 411.5
  },
  {
    x: 3286.5,
    y: 396.5
  },
  {
    x: 3286.5,
    y: 381.5
  },
  {
    x: 3286.5,
    y: 366.5
  },
  {
    x: 3286.5,
    y: 351.5
  },
  {
    x: 3286.5,
    y: 336.5
  },
  {
    x: 3286.5,
    y: 321.5
  },
  {
    x: 3286.5,
    y: 306.5
  },
  {
    x: 3286.5,
    y: 291.5
  },
  {
    x: 3286.5,
    y: 276.5
  },
  {
    x: 3286.5,
    y: 261.5
  },
  {
    x: 3286.5,
    y: 246.5
  },
  {
    x: 3286.5,
    y: 231.5
  },
  {
    x: 3286.5,
    y: 216.5
  },
  {
    x: 3286.5,
    y: 201.5
  },
  {
    x: 3286.5,
    y: 171.5
  },
  {
    x: 3286.5,
    y: 156.5
  },
  {
    x: 3301.5,
    y: 2046.5
  },
  {
    x: 3301.5,
    y: 2031.5
  },
  {
    x: 3301.5,
    y: 2016.5
  },
  {
    x: 3301.5,
    y: 2001.5
  },
  {
    x: 3301.5,
    y: 1986.5
  },
  {
    x: 3301.5,
    y: 1971.5
  },
  {
    x: 3301.5,
    y: 1956.5
  },
  {
    x: 3301.5,
    y: 1941.5
  },
  {
    x: 3301.5,
    y: 1926.5
  },
  {
    x: 3301.5,
    y: 1911.5
  },
  {
    x: 3301.5,
    y: 1896.5
  },
  {
    x: 3301.5,
    y: 1881.5
  },
  {
    x: 3301.5,
    y: 1866.5
  },
  {
    x: 3301.5,
    y: 1851.5
  },
  {
    x: 3301.5,
    y: 1836.5
  },
  {
    x: 3301.5,
    y: 1821.5
  },
  {
    x: 3301.5,
    y: 1806.5
  },
  {
    x: 3301.5,
    y: 1791.5
  },
  {
    x: 3301.5,
    y: 1101.5
  },
  {
    x: 3301.5,
    y: 1041.5
  },
  {
    x: 3301.5,
    y: 1026.5
  },
  {
    x: 3301.5,
    y: 1011.5
  },
  {
    x: 3301.5,
    y: 801.5
  },
  {
    x: 3301.5,
    y: 786.5
  },
  {
    x: 3301.5,
    y: 771.5
  },
  {
    x: 3301.5,
    y: 756.5
  },
  {
    x: 3301.5,
    y: 741.5
  },
  {
    x: 3301.5,
    y: 726.5
  },
  {
    x: 3301.5,
    y: 711.5
  },
  {
    x: 3301.5,
    y: 696.5
  },
  {
    x: 3301.5,
    y: 681.5
  },
  {
    x: 3301.5,
    y: 666.5
  },
  {
    x: 3301.5,
    y: 651.5
  },
  {
    x: 3301.5,
    y: 636.5
  },
  {
    x: 3301.5,
    y: 621.5
  },
  {
    x: 3301.5,
    y: 606.5
  },
  {
    x: 3301.5,
    y: 591.5
  },
  {
    x: 3301.5,
    y: 576.5
  },
  {
    x: 3301.5,
    y: 561.5
  },
  {
    x: 3301.5,
    y: 546.5
  },
  {
    x: 3301.5,
    y: 531.5
  },
  {
    x: 3301.5,
    y: 516.5
  },
  {
    x: 3301.5,
    y: 501.5
  },
  {
    x: 3301.5,
    y: 486.5
  },
  {
    x: 3301.5,
    y: 471.5
  },
  {
    x: 3301.5,
    y: 456.5
  },
  {
    x: 3301.5,
    y: 441.5
  },
  {
    x: 3301.5,
    y: 426.5
  },
  {
    x: 3301.5,
    y: 411.5
  },
  {
    x: 3301.5,
    y: 396.5
  },
  {
    x: 3301.5,
    y: 381.5
  },
  {
    x: 3301.5,
    y: 366.5
  },
  {
    x: 3301.5,
    y: 351.5
  },
  {
    x: 3301.5,
    y: 336.5
  },
  {
    x: 3301.5,
    y: 321.5
  },
  {
    x: 3301.5,
    y: 306.5
  },
  {
    x: 3301.5,
    y: 291.5
  },
  {
    x: 3301.5,
    y: 276.5
  },
  {
    x: 3301.5,
    y: 261.5
  },
  {
    x: 3301.5,
    y: 246.5
  },
  {
    x: 3301.5,
    y: 231.5
  },
  {
    x: 3301.5,
    y: 216.5
  },
  {
    x: 3301.5,
    y: 201.5
  },
  {
    x: 3301.5,
    y: 186.5
  },
  {
    x: 3301.5,
    y: 171.5
  },
  {
    x: 3301.5,
    y: 156.5
  },
  {
    x: 3316.5,
    y: 2046.5
  },
  {
    x: 3316.5,
    y: 2031.5
  },
  {
    x: 3316.5,
    y: 2016.5
  },
  {
    x: 3316.5,
    y: 2001.5
  },
  {
    x: 3316.5,
    y: 1986.5
  },
  {
    x: 3316.5,
    y: 1971.5
  },
  {
    x: 3316.5,
    y: 1956.5
  },
  {
    x: 3316.5,
    y: 1941.5
  },
  {
    x: 3316.5,
    y: 1926.5
  },
  {
    x: 3316.5,
    y: 1911.5
  },
  {
    x: 3316.5,
    y: 1896.5
  },
  {
    x: 3316.5,
    y: 1881.5
  },
  {
    x: 3316.5,
    y: 1866.5
  },
  {
    x: 3316.5,
    y: 1851.5
  },
  {
    x: 3316.5,
    y: 1836.5
  },
  {
    x: 3316.5,
    y: 1821.5
  },
  {
    x: 3316.5,
    y: 1806.5
  },
  {
    x: 3316.5,
    y: 1791.5
  },
  {
    x: 3316.5,
    y: 1776.5
  },
  {
    x: 3316.5,
    y: 1116.5
  },
  {
    x: 3316.5,
    y: 1101.5
  },
  {
    x: 3316.5,
    y: 1056.5
  },
  {
    x: 3316.5,
    y: 1041.5
  },
  {
    x: 3316.5,
    y: 1026.5
  },
  {
    x: 3316.5,
    y: 1011.5
  },
  {
    x: 3316.5,
    y: 996.5
  },
  {
    x: 3316.5,
    y: 771.5
  },
  {
    x: 3316.5,
    y: 756.5
  },
  {
    x: 3316.5,
    y: 741.5
  },
  {
    x: 3316.5,
    y: 726.5
  },
  {
    x: 3316.5,
    y: 711.5
  },
  {
    x: 3316.5,
    y: 696.5
  },
  {
    x: 3316.5,
    y: 681.5
  },
  {
    x: 3316.5,
    y: 666.5
  },
  {
    x: 3316.5,
    y: 651.5
  },
  {
    x: 3316.5,
    y: 636.5
  },
  {
    x: 3316.5,
    y: 621.5
  },
  {
    x: 3316.5,
    y: 606.5
  },
  {
    x: 3316.5,
    y: 591.5
  },
  {
    x: 3316.5,
    y: 576.5
  },
  {
    x: 3316.5,
    y: 561.5
  },
  {
    x: 3316.5,
    y: 546.5
  },
  {
    x: 3316.5,
    y: 531.5
  },
  {
    x: 3316.5,
    y: 516.5
  },
  {
    x: 3316.5,
    y: 501.5
  },
  {
    x: 3316.5,
    y: 486.5
  },
  {
    x: 3316.5,
    y: 471.5
  },
  {
    x: 3316.5,
    y: 456.5
  },
  {
    x: 3316.5,
    y: 441.5
  },
  {
    x: 3316.5,
    y: 426.5
  },
  {
    x: 3316.5,
    y: 411.5
  },
  {
    x: 3316.5,
    y: 396.5
  },
  {
    x: 3316.5,
    y: 381.5
  },
  {
    x: 3316.5,
    y: 366.5
  },
  {
    x: 3316.5,
    y: 351.5
  },
  {
    x: 3316.5,
    y: 336.5
  },
  {
    x: 3316.5,
    y: 321.5
  },
  {
    x: 3316.5,
    y: 306.5
  },
  {
    x: 3316.5,
    y: 291.5
  },
  {
    x: 3316.5,
    y: 276.5
  },
  {
    x: 3316.5,
    y: 261.5
  },
  {
    x: 3316.5,
    y: 246.5
  },
  {
    x: 3316.5,
    y: 231.5
  },
  {
    x: 3316.5,
    y: 216.5
  },
  {
    x: 3316.5,
    y: 201.5
  },
  {
    x: 3316.5,
    y: 186.5
  },
  {
    x: 3316.5,
    y: 171.5
  },
  {
    x: 3316.5,
    y: 156.5
  },
  {
    x: 3331.5,
    y: 2046.5
  },
  {
    x: 3331.5,
    y: 2031.5
  },
  {
    x: 3331.5,
    y: 2016.5
  },
  {
    x: 3331.5,
    y: 2001.5
  },
  {
    x: 3331.5,
    y: 1986.5
  },
  {
    x: 3331.5,
    y: 1971.5
  },
  {
    x: 3331.5,
    y: 1956.5
  },
  {
    x: 3331.5,
    y: 1941.5
  },
  {
    x: 3331.5,
    y: 1926.5
  },
  {
    x: 3331.5,
    y: 1911.5
  },
  {
    x: 3331.5,
    y: 1896.5
  },
  {
    x: 3331.5,
    y: 1881.5
  },
  {
    x: 3331.5,
    y: 1866.5
  },
  {
    x: 3331.5,
    y: 1851.5
  },
  {
    x: 3331.5,
    y: 1836.5
  },
  {
    x: 3331.5,
    y: 1821.5
  },
  {
    x: 3331.5,
    y: 1806.5
  },
  {
    x: 3331.5,
    y: 1791.5
  },
  {
    x: 3331.5,
    y: 1776.5
  },
  {
    x: 3331.5,
    y: 1116.5
  },
  {
    x: 3331.5,
    y: 1101.5
  },
  {
    x: 3331.5,
    y: 1056.5
  },
  {
    x: 3331.5,
    y: 1041.5
  },
  {
    x: 3331.5,
    y: 1026.5
  },
  {
    x: 3331.5,
    y: 1011.5
  },
  {
    x: 3331.5,
    y: 996.5
  },
  {
    x: 3331.5,
    y: 771.5
  },
  {
    x: 3331.5,
    y: 756.5
  },
  {
    x: 3331.5,
    y: 741.5
  },
  {
    x: 3331.5,
    y: 726.5
  },
  {
    x: 3331.5,
    y: 711.5
  },
  {
    x: 3331.5,
    y: 696.5
  },
  {
    x: 3331.5,
    y: 681.5
  },
  {
    x: 3331.5,
    y: 666.5
  },
  {
    x: 3331.5,
    y: 651.5
  },
  {
    x: 3331.5,
    y: 636.5
  },
  {
    x: 3331.5,
    y: 621.5
  },
  {
    x: 3331.5,
    y: 606.5
  },
  {
    x: 3331.5,
    y: 591.5
  },
  {
    x: 3331.5,
    y: 576.5
  },
  {
    x: 3331.5,
    y: 561.5
  },
  {
    x: 3331.5,
    y: 546.5
  },
  {
    x: 3331.5,
    y: 531.5
  },
  {
    x: 3331.5,
    y: 516.5
  },
  {
    x: 3331.5,
    y: 501.5
  },
  {
    x: 3331.5,
    y: 486.5
  },
  {
    x: 3331.5,
    y: 471.5
  },
  {
    x: 3331.5,
    y: 456.5
  },
  {
    x: 3331.5,
    y: 441.5
  },
  {
    x: 3331.5,
    y: 426.5
  },
  {
    x: 3331.5,
    y: 411.5
  },
  {
    x: 3331.5,
    y: 396.5
  },
  {
    x: 3331.5,
    y: 381.5
  },
  {
    x: 3331.5,
    y: 366.5
  },
  {
    x: 3331.5,
    y: 351.5
  },
  {
    x: 3331.5,
    y: 336.5
  },
  {
    x: 3331.5,
    y: 321.5
  },
  {
    x: 3331.5,
    y: 306.5
  },
  {
    x: 3331.5,
    y: 291.5
  },
  {
    x: 3331.5,
    y: 276.5
  },
  {
    x: 3331.5,
    y: 261.5
  },
  {
    x: 3331.5,
    y: 246.5
  },
  {
    x: 3331.5,
    y: 231.5
  },
  {
    x: 3331.5,
    y: 216.5
  },
  {
    x: 3331.5,
    y: 201.5
  },
  {
    x: 3331.5,
    y: 186.5
  },
  {
    x: 3331.5,
    y: 171.5
  },
  {
    x: 3331.5,
    y: 156.5
  },
  {
    x: 3346.5,
    y: 2046.5
  },
  {
    x: 3346.5,
    y: 2031.5
  },
  {
    x: 3346.5,
    y: 2016.5
  },
  {
    x: 3346.5,
    y: 2001.5
  },
  {
    x: 3346.5,
    y: 1986.5
  },
  {
    x: 3346.5,
    y: 1971.5
  },
  {
    x: 3346.5,
    y: 1956.5
  },
  {
    x: 3346.5,
    y: 1941.5
  },
  {
    x: 3346.5,
    y: 1926.5
  },
  {
    x: 3346.5,
    y: 1911.5
  },
  {
    x: 3346.5,
    y: 1896.5
  },
  {
    x: 3346.5,
    y: 1881.5
  },
  {
    x: 3346.5,
    y: 1866.5
  },
  {
    x: 3346.5,
    y: 1851.5
  },
  {
    x: 3346.5,
    y: 1836.5
  },
  {
    x: 3346.5,
    y: 1821.5
  },
  {
    x: 3346.5,
    y: 1806.5
  },
  {
    x: 3346.5,
    y: 1791.5
  },
  {
    x: 3346.5,
    y: 1776.5
  },
  {
    x: 3346.5,
    y: 1341.5
  },
  {
    x: 3346.5,
    y: 1326.5
  },
  {
    x: 3346.5,
    y: 1311.5
  },
  {
    x: 3346.5,
    y: 1296.5
  },
  {
    x: 3346.5,
    y: 1281.5
  },
  {
    x: 3346.5,
    y: 1116.5
  },
  {
    x: 3346.5,
    y: 1101.5
  },
  {
    x: 3346.5,
    y: 1056.5
  },
  {
    x: 3346.5,
    y: 1041.5
  },
  {
    x: 3346.5,
    y: 1026.5
  },
  {
    x: 3346.5,
    y: 1011.5
  },
  {
    x: 3346.5,
    y: 996.5
  },
  {
    x: 3346.5,
    y: 981.5
  },
  {
    x: 3346.5,
    y: 756.5
  },
  {
    x: 3346.5,
    y: 741.5
  },
  {
    x: 3346.5,
    y: 726.5
  },
  {
    x: 3346.5,
    y: 711.5
  },
  {
    x: 3346.5,
    y: 696.5
  },
  {
    x: 3346.5,
    y: 681.5
  },
  {
    x: 3346.5,
    y: 666.5
  },
  {
    x: 3346.5,
    y: 651.5
  },
  {
    x: 3346.5,
    y: 636.5
  },
  {
    x: 3346.5,
    y: 621.5
  },
  {
    x: 3346.5,
    y: 606.5
  },
  {
    x: 3346.5,
    y: 591.5
  },
  {
    x: 3346.5,
    y: 576.5
  },
  {
    x: 3346.5,
    y: 561.5
  },
  {
    x: 3346.5,
    y: 546.5
  },
  {
    x: 3346.5,
    y: 531.5
  },
  {
    x: 3346.5,
    y: 516.5
  },
  {
    x: 3346.5,
    y: 501.5
  },
  {
    x: 3346.5,
    y: 486.5
  },
  {
    x: 3346.5,
    y: 471.5
  },
  {
    x: 3346.5,
    y: 456.5
  },
  {
    x: 3346.5,
    y: 441.5
  },
  {
    x: 3346.5,
    y: 426.5
  },
  {
    x: 3346.5,
    y: 411.5
  },
  {
    x: 3346.5,
    y: 396.5
  },
  {
    x: 3346.5,
    y: 381.5
  },
  {
    x: 3346.5,
    y: 366.5
  },
  {
    x: 3346.5,
    y: 351.5
  },
  {
    x: 3346.5,
    y: 336.5
  },
  {
    x: 3346.5,
    y: 321.5
  },
  {
    x: 3346.5,
    y: 306.5
  },
  {
    x: 3346.5,
    y: 291.5
  },
  {
    x: 3346.5,
    y: 276.5
  },
  {
    x: 3346.5,
    y: 261.5
  },
  {
    x: 3346.5,
    y: 246.5
  },
  {
    x: 3346.5,
    y: 231.5
  },
  {
    x: 3346.5,
    y: 216.5
  },
  {
    x: 3346.5,
    y: 201.5
  },
  {
    x: 3346.5,
    y: 186.5
  },
  {
    x: 3361.5,
    y: 2046.5
  },
  {
    x: 3361.5,
    y: 2031.5
  },
  {
    x: 3361.5,
    y: 2016.5
  },
  {
    x: 3361.5,
    y: 2001.5
  },
  {
    x: 3361.5,
    y: 1986.5
  },
  {
    x: 3361.5,
    y: 1971.5
  },
  {
    x: 3361.5,
    y: 1956.5
  },
  {
    x: 3361.5,
    y: 1941.5
  },
  {
    x: 3361.5,
    y: 1926.5
  },
  {
    x: 3361.5,
    y: 1911.5
  },
  {
    x: 3361.5,
    y: 1896.5
  },
  {
    x: 3361.5,
    y: 1881.5
  },
  {
    x: 3361.5,
    y: 1866.5
  },
  {
    x: 3361.5,
    y: 1851.5
  },
  {
    x: 3361.5,
    y: 1836.5
  },
  {
    x: 3361.5,
    y: 1821.5
  },
  {
    x: 3361.5,
    y: 1806.5
  },
  {
    x: 3361.5,
    y: 1791.5
  },
  {
    x: 3361.5,
    y: 1371.5
  },
  {
    x: 3361.5,
    y: 1356.5
  },
  {
    x: 3361.5,
    y: 1341.5
  },
  {
    x: 3361.5,
    y: 1326.5
  },
  {
    x: 3361.5,
    y: 1311.5
  },
  {
    x: 3361.5,
    y: 1296.5
  },
  {
    x: 3361.5,
    y: 1281.5
  },
  {
    x: 3361.5,
    y: 1116.5
  },
  {
    x: 3361.5,
    y: 1056.5
  },
  {
    x: 3361.5,
    y: 1041.5
  },
  {
    x: 3361.5,
    y: 1026.5
  },
  {
    x: 3361.5,
    y: 1011.5
  },
  {
    x: 3361.5,
    y: 996.5
  },
  {
    x: 3361.5,
    y: 981.5
  },
  {
    x: 3361.5,
    y: 966.5
  },
  {
    x: 3361.5,
    y: 756.5
  },
  {
    x: 3361.5,
    y: 741.5
  },
  {
    x: 3361.5,
    y: 726.5
  },
  {
    x: 3361.5,
    y: 711.5
  },
  {
    x: 3361.5,
    y: 696.5
  },
  {
    x: 3361.5,
    y: 681.5
  },
  {
    x: 3361.5,
    y: 666.5
  },
  {
    x: 3361.5,
    y: 651.5
  },
  {
    x: 3361.5,
    y: 636.5
  },
  {
    x: 3361.5,
    y: 621.5
  },
  {
    x: 3361.5,
    y: 606.5
  },
  {
    x: 3361.5,
    y: 591.5
  },
  {
    x: 3361.5,
    y: 576.5
  },
  {
    x: 3361.5,
    y: 561.5
  },
  {
    x: 3361.5,
    y: 546.5
  },
  {
    x: 3361.5,
    y: 531.5
  },
  {
    x: 3361.5,
    y: 516.5
  },
  {
    x: 3361.5,
    y: 501.5
  },
  {
    x: 3361.5,
    y: 486.5
  },
  {
    x: 3361.5,
    y: 471.5
  },
  {
    x: 3361.5,
    y: 456.5
  },
  {
    x: 3361.5,
    y: 441.5
  },
  {
    x: 3361.5,
    y: 426.5
  },
  {
    x: 3361.5,
    y: 411.5
  },
  {
    x: 3361.5,
    y: 396.5
  },
  {
    x: 3361.5,
    y: 381.5
  },
  {
    x: 3361.5,
    y: 366.5
  },
  {
    x: 3361.5,
    y: 351.5
  },
  {
    x: 3361.5,
    y: 336.5
  },
  {
    x: 3361.5,
    y: 321.5
  },
  {
    x: 3361.5,
    y: 306.5
  },
  {
    x: 3361.5,
    y: 291.5
  },
  {
    x: 3361.5,
    y: 276.5
  },
  {
    x: 3361.5,
    y: 261.5
  },
  {
    x: 3361.5,
    y: 246.5
  },
  {
    x: 3361.5,
    y: 231.5
  },
  {
    x: 3361.5,
    y: 216.5
  },
  {
    x: 3361.5,
    y: 201.5
  },
  {
    x: 3361.5,
    y: 186.5
  },
  {
    x: 3376.5,
    y: 2046.5
  },
  {
    x: 3376.5,
    y: 2031.5
  },
  {
    x: 3376.5,
    y: 2016.5
  },
  {
    x: 3376.5,
    y: 2001.5
  },
  {
    x: 3376.5,
    y: 1986.5
  },
  {
    x: 3376.5,
    y: 1971.5
  },
  {
    x: 3376.5,
    y: 1956.5
  },
  {
    x: 3376.5,
    y: 1941.5
  },
  {
    x: 3376.5,
    y: 1926.5
  },
  {
    x: 3376.5,
    y: 1911.5
  },
  {
    x: 3376.5,
    y: 1896.5
  },
  {
    x: 3376.5,
    y: 1881.5
  },
  {
    x: 3376.5,
    y: 1866.5
  },
  {
    x: 3376.5,
    y: 1851.5
  },
  {
    x: 3376.5,
    y: 1836.5
  },
  {
    x: 3376.5,
    y: 1821.5
  },
  {
    x: 3376.5,
    y: 1806.5
  },
  {
    x: 3376.5,
    y: 1791.5
  },
  {
    x: 3376.5,
    y: 1416.5
  },
  {
    x: 3376.5,
    y: 1401.5
  },
  {
    x: 3376.5,
    y: 1386.5
  },
  {
    x: 3376.5,
    y: 1371.5
  },
  {
    x: 3376.5,
    y: 1356.5
  },
  {
    x: 3376.5,
    y: 1341.5
  },
  {
    x: 3376.5,
    y: 1326.5
  },
  {
    x: 3376.5,
    y: 1311.5
  },
  {
    x: 3376.5,
    y: 1296.5
  },
  {
    x: 3376.5,
    y: 1281.5
  },
  {
    x: 3376.5,
    y: 1266.5
  },
  {
    x: 3376.5,
    y: 1116.5
  },
  {
    x: 3376.5,
    y: 1041.5
  },
  {
    x: 3376.5,
    y: 1026.5
  },
  {
    x: 3376.5,
    y: 1011.5
  },
  {
    x: 3376.5,
    y: 996.5
  },
  {
    x: 3376.5,
    y: 981.5
  },
  {
    x: 3376.5,
    y: 966.5
  },
  {
    x: 3376.5,
    y: 951.5
  },
  {
    x: 3376.5,
    y: 756.5
  },
  {
    x: 3376.5,
    y: 741.5
  },
  {
    x: 3376.5,
    y: 726.5
  },
  {
    x: 3376.5,
    y: 711.5
  },
  {
    x: 3376.5,
    y: 696.5
  },
  {
    x: 3376.5,
    y: 681.5
  },
  {
    x: 3376.5,
    y: 666.5
  },
  {
    x: 3376.5,
    y: 651.5
  },
  {
    x: 3376.5,
    y: 636.5
  },
  {
    x: 3376.5,
    y: 621.5
  },
  {
    x: 3376.5,
    y: 606.5
  },
  {
    x: 3376.5,
    y: 591.5
  },
  {
    x: 3376.5,
    y: 576.5
  },
  {
    x: 3376.5,
    y: 561.5
  },
  {
    x: 3376.5,
    y: 546.5
  },
  {
    x: 3376.5,
    y: 531.5
  },
  {
    x: 3376.5,
    y: 516.5
  },
  {
    x: 3376.5,
    y: 501.5
  },
  {
    x: 3376.5,
    y: 486.5
  },
  {
    x: 3376.5,
    y: 471.5
  },
  {
    x: 3376.5,
    y: 456.5
  },
  {
    x: 3376.5,
    y: 441.5
  },
  {
    x: 3376.5,
    y: 426.5
  },
  {
    x: 3376.5,
    y: 411.5
  },
  {
    x: 3376.5,
    y: 396.5
  },
  {
    x: 3376.5,
    y: 381.5
  },
  {
    x: 3376.5,
    y: 366.5
  },
  {
    x: 3376.5,
    y: 351.5
  },
  {
    x: 3376.5,
    y: 336.5
  },
  {
    x: 3376.5,
    y: 321.5
  },
  {
    x: 3376.5,
    y: 306.5
  },
  {
    x: 3376.5,
    y: 291.5
  },
  {
    x: 3376.5,
    y: 276.5
  },
  {
    x: 3376.5,
    y: 261.5
  },
  {
    x: 3376.5,
    y: 246.5
  },
  {
    x: 3376.5,
    y: 231.5
  },
  {
    x: 3376.5,
    y: 216.5
  },
  {
    x: 3376.5,
    y: 201.5
  },
  {
    x: 3376.5,
    y: 186.5
  },
  {
    x: 3391.5,
    y: 2046.5
  },
  {
    x: 3391.5,
    y: 2031.5
  },
  {
    x: 3391.5,
    y: 2016.5
  },
  {
    x: 3391.5,
    y: 2001.5
  },
  {
    x: 3391.5,
    y: 1986.5
  },
  {
    x: 3391.5,
    y: 1971.5
  },
  {
    x: 3391.5,
    y: 1956.5
  },
  {
    x: 3391.5,
    y: 1941.5
  },
  {
    x: 3391.5,
    y: 1926.5
  },
  {
    x: 3391.5,
    y: 1911.5
  },
  {
    x: 3391.5,
    y: 1896.5
  },
  {
    x: 3391.5,
    y: 1881.5
  },
  {
    x: 3391.5,
    y: 1866.5
  },
  {
    x: 3391.5,
    y: 1851.5
  },
  {
    x: 3391.5,
    y: 1836.5
  },
  {
    x: 3391.5,
    y: 1821.5
  },
  {
    x: 3391.5,
    y: 1806.5
  },
  {
    x: 3391.5,
    y: 1791.5
  },
  {
    x: 3391.5,
    y: 1416.5
  },
  {
    x: 3391.5,
    y: 1401.5
  },
  {
    x: 3391.5,
    y: 1386.5
  },
  {
    x: 3391.5,
    y: 1371.5
  },
  {
    x: 3391.5,
    y: 1356.5
  },
  {
    x: 3391.5,
    y: 1341.5
  },
  {
    x: 3391.5,
    y: 1326.5
  },
  {
    x: 3391.5,
    y: 1311.5
  },
  {
    x: 3391.5,
    y: 1296.5
  },
  {
    x: 3391.5,
    y: 1281.5
  },
  {
    x: 3391.5,
    y: 1266.5
  },
  {
    x: 3391.5,
    y: 1116.5
  },
  {
    x: 3391.5,
    y: 1011.5
  },
  {
    x: 3391.5,
    y: 996.5
  },
  {
    x: 3391.5,
    y: 966.5
  },
  {
    x: 3391.5,
    y: 921.5
  },
  {
    x: 3391.5,
    y: 741.5
  },
  {
    x: 3391.5,
    y: 726.5
  },
  {
    x: 3391.5,
    y: 711.5
  },
  {
    x: 3391.5,
    y: 696.5
  },
  {
    x: 3391.5,
    y: 681.5
  },
  {
    x: 3391.5,
    y: 666.5
  },
  {
    x: 3391.5,
    y: 651.5
  },
  {
    x: 3391.5,
    y: 636.5
  },
  {
    x: 3391.5,
    y: 621.5
  },
  {
    x: 3391.5,
    y: 606.5
  },
  {
    x: 3391.5,
    y: 591.5
  },
  {
    x: 3391.5,
    y: 576.5
  },
  {
    x: 3391.5,
    y: 561.5
  },
  {
    x: 3391.5,
    y: 546.5
  },
  {
    x: 3391.5,
    y: 531.5
  },
  {
    x: 3391.5,
    y: 516.5
  },
  {
    x: 3391.5,
    y: 501.5
  },
  {
    x: 3391.5,
    y: 486.5
  },
  {
    x: 3391.5,
    y: 471.5
  },
  {
    x: 3391.5,
    y: 456.5
  },
  {
    x: 3391.5,
    y: 441.5
  },
  {
    x: 3391.5,
    y: 426.5
  },
  {
    x: 3391.5,
    y: 411.5
  },
  {
    x: 3391.5,
    y: 396.5
  },
  {
    x: 3391.5,
    y: 381.5
  },
  {
    x: 3391.5,
    y: 366.5
  },
  {
    x: 3391.5,
    y: 351.5
  },
  {
    x: 3391.5,
    y: 336.5
  },
  {
    x: 3391.5,
    y: 321.5
  },
  {
    x: 3391.5,
    y: 306.5
  },
  {
    x: 3391.5,
    y: 291.5
  },
  {
    x: 3391.5,
    y: 276.5
  },
  {
    x: 3391.5,
    y: 261.5
  },
  {
    x: 3391.5,
    y: 246.5
  },
  {
    x: 3391.5,
    y: 231.5
  },
  {
    x: 3391.5,
    y: 216.5
  },
  {
    x: 3391.5,
    y: 201.5
  },
  {
    x: 3391.5,
    y: 186.5
  },
  {
    x: 3406.5,
    y: 2046.5
  },
  {
    x: 3406.5,
    y: 2031.5
  },
  {
    x: 3406.5,
    y: 2016.5
  },
  {
    x: 3406.5,
    y: 2001.5
  },
  {
    x: 3406.5,
    y: 1986.5
  },
  {
    x: 3406.5,
    y: 1971.5
  },
  {
    x: 3406.5,
    y: 1956.5
  },
  {
    x: 3406.5,
    y: 1941.5
  },
  {
    x: 3406.5,
    y: 1926.5
  },
  {
    x: 3406.5,
    y: 1911.5
  },
  {
    x: 3406.5,
    y: 1896.5
  },
  {
    x: 3406.5,
    y: 1881.5
  },
  {
    x: 3406.5,
    y: 1866.5
  },
  {
    x: 3406.5,
    y: 1851.5
  },
  {
    x: 3406.5,
    y: 1836.5
  },
  {
    x: 3406.5,
    y: 1821.5
  },
  {
    x: 3406.5,
    y: 1806.5
  },
  {
    x: 3406.5,
    y: 1791.5
  },
  {
    x: 3406.5,
    y: 1401.5
  },
  {
    x: 3406.5,
    y: 1386.5
  },
  {
    x: 3406.5,
    y: 1371.5
  },
  {
    x: 3406.5,
    y: 1356.5
  },
  {
    x: 3406.5,
    y: 1341.5
  },
  {
    x: 3406.5,
    y: 1326.5
  },
  {
    x: 3406.5,
    y: 1311.5
  },
  {
    x: 3406.5,
    y: 1296.5
  },
  {
    x: 3406.5,
    y: 1281.5
  },
  {
    x: 3406.5,
    y: 1266.5
  },
  {
    x: 3406.5,
    y: 1251.5
  },
  {
    x: 3406.5,
    y: 1131.5
  },
  {
    x: 3406.5,
    y: 1086.5
  },
  {
    x: 3406.5,
    y: 1071.5
  },
  {
    x: 3406.5,
    y: 1056.5
  },
  {
    x: 3406.5,
    y: 1041.5
  },
  {
    x: 3406.5,
    y: 906.5
  },
  {
    x: 3406.5,
    y: 726.5
  },
  {
    x: 3406.5,
    y: 711.5
  },
  {
    x: 3406.5,
    y: 696.5
  },
  {
    x: 3406.5,
    y: 681.5
  },
  {
    x: 3406.5,
    y: 666.5
  },
  {
    x: 3406.5,
    y: 651.5
  },
  {
    x: 3406.5,
    y: 636.5
  },
  {
    x: 3406.5,
    y: 621.5
  },
  {
    x: 3406.5,
    y: 606.5
  },
  {
    x: 3406.5,
    y: 561.5
  },
  {
    x: 3406.5,
    y: 546.5
  },
  {
    x: 3406.5,
    y: 531.5
  },
  {
    x: 3406.5,
    y: 516.5
  },
  {
    x: 3406.5,
    y: 501.5
  },
  {
    x: 3406.5,
    y: 486.5
  },
  {
    x: 3406.5,
    y: 471.5
  },
  {
    x: 3406.5,
    y: 456.5
  },
  {
    x: 3406.5,
    y: 441.5
  },
  {
    x: 3406.5,
    y: 426.5
  },
  {
    x: 3406.5,
    y: 411.5
  },
  {
    x: 3406.5,
    y: 396.5
  },
  {
    x: 3406.5,
    y: 381.5
  },
  {
    x: 3406.5,
    y: 366.5
  },
  {
    x: 3406.5,
    y: 351.5
  },
  {
    x: 3406.5,
    y: 336.5
  },
  {
    x: 3406.5,
    y: 321.5
  },
  {
    x: 3406.5,
    y: 306.5
  },
  {
    x: 3406.5,
    y: 291.5
  },
  {
    x: 3406.5,
    y: 276.5
  },
  {
    x: 3406.5,
    y: 261.5
  },
  {
    x: 3406.5,
    y: 246.5
  },
  {
    x: 3406.5,
    y: 231.5
  },
  {
    x: 3406.5,
    y: 216.5
  },
  {
    x: 3406.5,
    y: 201.5
  },
  {
    x: 3421.5,
    y: 2046.5
  },
  {
    x: 3421.5,
    y: 2031.5
  },
  {
    x: 3421.5,
    y: 2016.5
  },
  {
    x: 3421.5,
    y: 2001.5
  },
  {
    x: 3421.5,
    y: 1986.5
  },
  {
    x: 3421.5,
    y: 1971.5
  },
  {
    x: 3421.5,
    y: 1956.5
  },
  {
    x: 3421.5,
    y: 1941.5
  },
  {
    x: 3421.5,
    y: 1926.5
  },
  {
    x: 3421.5,
    y: 1911.5
  },
  {
    x: 3421.5,
    y: 1896.5
  },
  {
    x: 3421.5,
    y: 1881.5
  },
  {
    x: 3421.5,
    y: 1866.5
  },
  {
    x: 3421.5,
    y: 1851.5
  },
  {
    x: 3421.5,
    y: 1836.5
  },
  {
    x: 3421.5,
    y: 1821.5
  },
  {
    x: 3421.5,
    y: 1806.5
  },
  {
    x: 3421.5,
    y: 1791.5
  },
  {
    x: 3421.5,
    y: 1401.5
  },
  {
    x: 3421.5,
    y: 1386.5
  },
  {
    x: 3421.5,
    y: 1371.5
  },
  {
    x: 3421.5,
    y: 1356.5
  },
  {
    x: 3421.5,
    y: 1341.5
  },
  {
    x: 3421.5,
    y: 1326.5
  },
  {
    x: 3421.5,
    y: 1311.5
  },
  {
    x: 3421.5,
    y: 1296.5
  },
  {
    x: 3421.5,
    y: 1281.5
  },
  {
    x: 3421.5,
    y: 1266.5
  },
  {
    x: 3421.5,
    y: 1251.5
  },
  {
    x: 3421.5,
    y: 1071.5
  },
  {
    x: 3421.5,
    y: 1056.5
  },
  {
    x: 3421.5,
    y: 1041.5
  },
  {
    x: 3421.5,
    y: 1011.5
  },
  {
    x: 3421.5,
    y: 876.5
  },
  {
    x: 3421.5,
    y: 861.5
  },
  {
    x: 3421.5,
    y: 846.5
  },
  {
    x: 3421.5,
    y: 831.5
  },
  {
    x: 3421.5,
    y: 816.5
  },
  {
    x: 3421.5,
    y: 771.5
  },
  {
    x: 3421.5,
    y: 756.5
  },
  {
    x: 3421.5,
    y: 711.5
  },
  {
    x: 3421.5,
    y: 696.5
  },
  {
    x: 3421.5,
    y: 681.5
  },
  {
    x: 3421.5,
    y: 666.5
  },
  {
    x: 3421.5,
    y: 651.5
  },
  {
    x: 3421.5,
    y: 606.5
  },
  {
    x: 3421.5,
    y: 561.5
  },
  {
    x: 3421.5,
    y: 546.5
  },
  {
    x: 3421.5,
    y: 531.5
  },
  {
    x: 3421.5,
    y: 516.5
  },
  {
    x: 3421.5,
    y: 501.5
  },
  {
    x: 3421.5,
    y: 486.5
  },
  {
    x: 3421.5,
    y: 471.5
  },
  {
    x: 3421.5,
    y: 456.5
  },
  {
    x: 3421.5,
    y: 441.5
  },
  {
    x: 3421.5,
    y: 426.5
  },
  {
    x: 3421.5,
    y: 411.5
  },
  {
    x: 3421.5,
    y: 396.5
  },
  {
    x: 3421.5,
    y: 381.5
  },
  {
    x: 3421.5,
    y: 366.5
  },
  {
    x: 3421.5,
    y: 351.5
  },
  {
    x: 3421.5,
    y: 336.5
  },
  {
    x: 3421.5,
    y: 321.5
  },
  {
    x: 3421.5,
    y: 306.5
  },
  {
    x: 3421.5,
    y: 291.5
  },
  {
    x: 3421.5,
    y: 276.5
  },
  {
    x: 3421.5,
    y: 261.5
  },
  {
    x: 3421.5,
    y: 246.5
  },
  {
    x: 3421.5,
    y: 231.5
  },
  {
    x: 3421.5,
    y: 216.5
  },
  {
    x: 3421.5,
    y: 201.5
  },
  {
    x: 3436.5,
    y: 2046.5
  },
  {
    x: 3436.5,
    y: 2031.5
  },
  {
    x: 3436.5,
    y: 2016.5
  },
  {
    x: 3436.5,
    y: 2001.5
  },
  {
    x: 3436.5,
    y: 1986.5
  },
  {
    x: 3436.5,
    y: 1971.5
  },
  {
    x: 3436.5,
    y: 1956.5
  },
  {
    x: 3436.5,
    y: 1941.5
  },
  {
    x: 3436.5,
    y: 1926.5
  },
  {
    x: 3436.5,
    y: 1911.5
  },
  {
    x: 3436.5,
    y: 1896.5
  },
  {
    x: 3436.5,
    y: 1881.5
  },
  {
    x: 3436.5,
    y: 1866.5
  },
  {
    x: 3436.5,
    y: 1851.5
  },
  {
    x: 3436.5,
    y: 1836.5
  },
  {
    x: 3436.5,
    y: 1821.5
  },
  {
    x: 3436.5,
    y: 1806.5
  },
  {
    x: 3436.5,
    y: 1791.5
  },
  {
    x: 3436.5,
    y: 1401.5
  },
  {
    x: 3436.5,
    y: 1386.5
  },
  {
    x: 3436.5,
    y: 1371.5
  },
  {
    x: 3436.5,
    y: 1356.5
  },
  {
    x: 3436.5,
    y: 1341.5
  },
  {
    x: 3436.5,
    y: 1326.5
  },
  {
    x: 3436.5,
    y: 1311.5
  },
  {
    x: 3436.5,
    y: 1296.5
  },
  {
    x: 3436.5,
    y: 1281.5
  },
  {
    x: 3436.5,
    y: 1266.5
  },
  {
    x: 3436.5,
    y: 1251.5
  },
  {
    x: 3436.5,
    y: 1236.5
  },
  {
    x: 3436.5,
    y: 1086.5
  },
  {
    x: 3436.5,
    y: 1071.5
  },
  {
    x: 3436.5,
    y: 1056.5
  },
  {
    x: 3436.5,
    y: 1041.5
  },
  {
    x: 3436.5,
    y: 1011.5
  },
  {
    x: 3436.5,
    y: 951.5
  },
  {
    x: 3436.5,
    y: 936.5
  },
  {
    x: 3436.5,
    y: 906.5
  },
  {
    x: 3436.5,
    y: 891.5
  },
  {
    x: 3436.5,
    y: 831.5
  },
  {
    x: 3436.5,
    y: 816.5
  },
  {
    x: 3436.5,
    y: 576.5
  },
  {
    x: 3436.5,
    y: 561.5
  },
  {
    x: 3436.5,
    y: 546.5
  },
  {
    x: 3436.5,
    y: 531.5
  },
  {
    x: 3436.5,
    y: 516.5
  },
  {
    x: 3436.5,
    y: 501.5
  },
  {
    x: 3436.5,
    y: 486.5
  },
  {
    x: 3436.5,
    y: 471.5
  },
  {
    x: 3436.5,
    y: 456.5
  },
  {
    x: 3436.5,
    y: 441.5
  },
  {
    x: 3436.5,
    y: 426.5
  },
  {
    x: 3436.5,
    y: 411.5
  },
  {
    x: 3436.5,
    y: 396.5
  },
  {
    x: 3436.5,
    y: 381.5
  },
  {
    x: 3436.5,
    y: 366.5
  },
  {
    x: 3436.5,
    y: 351.5
  },
  {
    x: 3436.5,
    y: 336.5
  },
  {
    x: 3436.5,
    y: 321.5
  },
  {
    x: 3436.5,
    y: 306.5
  },
  {
    x: 3436.5,
    y: 291.5
  },
  {
    x: 3436.5,
    y: 276.5
  },
  {
    x: 3436.5,
    y: 261.5
  },
  {
    x: 3436.5,
    y: 246.5
  },
  {
    x: 3436.5,
    y: 231.5
  },
  {
    x: 3436.5,
    y: 216.5
  },
  {
    x: 3436.5,
    y: 201.5
  },
  {
    x: 3451.5,
    y: 2046.5
  },
  {
    x: 3451.5,
    y: 2031.5
  },
  {
    x: 3451.5,
    y: 2016.5
  },
  {
    x: 3451.5,
    y: 2001.5
  },
  {
    x: 3451.5,
    y: 1986.5
  },
  {
    x: 3451.5,
    y: 1971.5
  },
  {
    x: 3451.5,
    y: 1956.5
  },
  {
    x: 3451.5,
    y: 1941.5
  },
  {
    x: 3451.5,
    y: 1926.5
  },
  {
    x: 3451.5,
    y: 1911.5
  },
  {
    x: 3451.5,
    y: 1896.5
  },
  {
    x: 3451.5,
    y: 1881.5
  },
  {
    x: 3451.5,
    y: 1866.5
  },
  {
    x: 3451.5,
    y: 1851.5
  },
  {
    x: 3451.5,
    y: 1836.5
  },
  {
    x: 3451.5,
    y: 1821.5
  },
  {
    x: 3451.5,
    y: 1806.5
  },
  {
    x: 3451.5,
    y: 1791.5
  },
  {
    x: 3451.5,
    y: 1401.5
  },
  {
    x: 3451.5,
    y: 1386.5
  },
  {
    x: 3451.5,
    y: 1371.5
  },
  {
    x: 3451.5,
    y: 1356.5
  },
  {
    x: 3451.5,
    y: 1341.5
  },
  {
    x: 3451.5,
    y: 1326.5
  },
  {
    x: 3451.5,
    y: 1311.5
  },
  {
    x: 3451.5,
    y: 1296.5
  },
  {
    x: 3451.5,
    y: 1281.5
  },
  {
    x: 3451.5,
    y: 1266.5
  },
  {
    x: 3451.5,
    y: 1251.5
  },
  {
    x: 3451.5,
    y: 1236.5
  },
  {
    x: 3451.5,
    y: 1221.5
  },
  {
    x: 3451.5,
    y: 1146.5
  },
  {
    x: 3451.5,
    y: 1116.5
  },
  {
    x: 3451.5,
    y: 1086.5
  },
  {
    x: 3451.5,
    y: 1071.5
  },
  {
    x: 3451.5,
    y: 1041.5
  },
  {
    x: 3451.5,
    y: 1011.5
  },
  {
    x: 3451.5,
    y: 951.5
  },
  {
    x: 3451.5,
    y: 936.5
  },
  {
    x: 3451.5,
    y: 921.5
  },
  {
    x: 3451.5,
    y: 906.5
  },
  {
    x: 3451.5,
    y: 876.5
  },
  {
    x: 3451.5,
    y: 561.5
  },
  {
    x: 3451.5,
    y: 546.5
  },
  {
    x: 3451.5,
    y: 531.5
  },
  {
    x: 3451.5,
    y: 516.5
  },
  {
    x: 3451.5,
    y: 501.5
  },
  {
    x: 3451.5,
    y: 486.5
  },
  {
    x: 3451.5,
    y: 471.5
  },
  {
    x: 3451.5,
    y: 456.5
  },
  {
    x: 3451.5,
    y: 441.5
  },
  {
    x: 3451.5,
    y: 426.5
  },
  {
    x: 3451.5,
    y: 411.5
  },
  {
    x: 3451.5,
    y: 396.5
  },
  {
    x: 3451.5,
    y: 381.5
  },
  {
    x: 3451.5,
    y: 366.5
  },
  {
    x: 3451.5,
    y: 351.5
  },
  {
    x: 3451.5,
    y: 336.5
  },
  {
    x: 3451.5,
    y: 321.5
  },
  {
    x: 3451.5,
    y: 306.5
  },
  {
    x: 3451.5,
    y: 291.5
  },
  {
    x: 3451.5,
    y: 276.5
  },
  {
    x: 3451.5,
    y: 261.5
  },
  {
    x: 3451.5,
    y: 246.5
  },
  {
    x: 3451.5,
    y: 231.5
  },
  {
    x: 3451.5,
    y: 216.5
  },
  {
    x: 3451.5,
    y: 201.5
  },
  {
    x: 3451.5,
    y: 186.5
  },
  {
    x: 3466.5,
    y: 2046.5
  },
  {
    x: 3466.5,
    y: 2031.5
  },
  {
    x: 3466.5,
    y: 2016.5
  },
  {
    x: 3466.5,
    y: 2001.5
  },
  {
    x: 3466.5,
    y: 1986.5
  },
  {
    x: 3466.5,
    y: 1971.5
  },
  {
    x: 3466.5,
    y: 1956.5
  },
  {
    x: 3466.5,
    y: 1941.5
  },
  {
    x: 3466.5,
    y: 1926.5
  },
  {
    x: 3466.5,
    y: 1911.5
  },
  {
    x: 3466.5,
    y: 1896.5
  },
  {
    x: 3466.5,
    y: 1881.5
  },
  {
    x: 3466.5,
    y: 1866.5
  },
  {
    x: 3466.5,
    y: 1851.5
  },
  {
    x: 3466.5,
    y: 1836.5
  },
  {
    x: 3466.5,
    y: 1821.5
  },
  {
    x: 3466.5,
    y: 1806.5
  },
  {
    x: 3466.5,
    y: 1791.5
  },
  {
    x: 3466.5,
    y: 1386.5
  },
  {
    x: 3466.5,
    y: 1371.5
  },
  {
    x: 3466.5,
    y: 1356.5
  },
  {
    x: 3466.5,
    y: 1341.5
  },
  {
    x: 3466.5,
    y: 1326.5
  },
  {
    x: 3466.5,
    y: 1311.5
  },
  {
    x: 3466.5,
    y: 1296.5
  },
  {
    x: 3466.5,
    y: 1281.5
  },
  {
    x: 3466.5,
    y: 1266.5
  },
  {
    x: 3466.5,
    y: 1251.5
  },
  {
    x: 3466.5,
    y: 1236.5
  },
  {
    x: 3466.5,
    y: 1221.5
  },
  {
    x: 3466.5,
    y: 1206.5
  },
  {
    x: 3466.5,
    y: 1131.5
  },
  {
    x: 3466.5,
    y: 1116.5
  },
  {
    x: 3466.5,
    y: 1041.5
  },
  {
    x: 3466.5,
    y: 1011.5
  },
  {
    x: 3466.5,
    y: 951.5
  },
  {
    x: 3466.5,
    y: 936.5
  },
  {
    x: 3466.5,
    y: 921.5
  },
  {
    x: 3466.5,
    y: 906.5
  },
  {
    x: 3466.5,
    y: 891.5
  },
  {
    x: 3466.5,
    y: 561.5
  },
  {
    x: 3466.5,
    y: 546.5
  },
  {
    x: 3466.5,
    y: 531.5
  },
  {
    x: 3466.5,
    y: 516.5
  },
  {
    x: 3466.5,
    y: 501.5
  },
  {
    x: 3466.5,
    y: 486.5
  },
  {
    x: 3466.5,
    y: 471.5
  },
  {
    x: 3466.5,
    y: 456.5
  },
  {
    x: 3466.5,
    y: 441.5
  },
  {
    x: 3466.5,
    y: 426.5
  },
  {
    x: 3466.5,
    y: 411.5
  },
  {
    x: 3466.5,
    y: 396.5
  },
  {
    x: 3466.5,
    y: 381.5
  },
  {
    x: 3466.5,
    y: 366.5
  },
  {
    x: 3466.5,
    y: 351.5
  },
  {
    x: 3466.5,
    y: 336.5
  },
  {
    x: 3466.5,
    y: 321.5
  },
  {
    x: 3466.5,
    y: 306.5
  },
  {
    x: 3466.5,
    y: 291.5
  },
  {
    x: 3466.5,
    y: 276.5
  },
  {
    x: 3466.5,
    y: 261.5
  },
  {
    x: 3466.5,
    y: 246.5
  },
  {
    x: 3466.5,
    y: 231.5
  },
  {
    x: 3466.5,
    y: 216.5
  },
  {
    x: 3466.5,
    y: 201.5
  },
  {
    x: 3466.5,
    y: 186.5
  },
  {
    x: 3481.5,
    y: 2046.5
  },
  {
    x: 3481.5,
    y: 2031.5
  },
  {
    x: 3481.5,
    y: 2016.5
  },
  {
    x: 3481.5,
    y: 2001.5
  },
  {
    x: 3481.5,
    y: 1986.5
  },
  {
    x: 3481.5,
    y: 1971.5
  },
  {
    x: 3481.5,
    y: 1956.5
  },
  {
    x: 3481.5,
    y: 1941.5
  },
  {
    x: 3481.5,
    y: 1926.5
  },
  {
    x: 3481.5,
    y: 1911.5
  },
  {
    x: 3481.5,
    y: 1896.5
  },
  {
    x: 3481.5,
    y: 1881.5
  },
  {
    x: 3481.5,
    y: 1866.5
  },
  {
    x: 3481.5,
    y: 1851.5
  },
  {
    x: 3481.5,
    y: 1836.5
  },
  {
    x: 3481.5,
    y: 1821.5
  },
  {
    x: 3481.5,
    y: 1806.5
  },
  {
    x: 3481.5,
    y: 1791.5
  },
  {
    x: 3481.5,
    y: 1386.5
  },
  {
    x: 3481.5,
    y: 1371.5
  },
  {
    x: 3481.5,
    y: 1356.5
  },
  {
    x: 3481.5,
    y: 1341.5
  },
  {
    x: 3481.5,
    y: 1326.5
  },
  {
    x: 3481.5,
    y: 1311.5
  },
  {
    x: 3481.5,
    y: 1296.5
  },
  {
    x: 3481.5,
    y: 1281.5
  },
  {
    x: 3481.5,
    y: 1266.5
  },
  {
    x: 3481.5,
    y: 1251.5
  },
  {
    x: 3481.5,
    y: 1236.5
  },
  {
    x: 3481.5,
    y: 1221.5
  },
  {
    x: 3481.5,
    y: 1206.5
  },
  {
    x: 3481.5,
    y: 1191.5
  },
  {
    x: 3481.5,
    y: 1116.5
  },
  {
    x: 3481.5,
    y: 1041.5
  },
  {
    x: 3481.5,
    y: 936.5
  },
  {
    x: 3481.5,
    y: 921.5
  },
  {
    x: 3481.5,
    y: 591.5
  },
  {
    x: 3481.5,
    y: 576.5
  },
  {
    x: 3481.5,
    y: 561.5
  },
  {
    x: 3481.5,
    y: 546.5
  },
  {
    x: 3481.5,
    y: 531.5
  },
  {
    x: 3481.5,
    y: 516.5
  },
  {
    x: 3481.5,
    y: 501.5
  },
  {
    x: 3481.5,
    y: 486.5
  },
  {
    x: 3481.5,
    y: 471.5
  },
  {
    x: 3481.5,
    y: 456.5
  },
  {
    x: 3481.5,
    y: 441.5
  },
  {
    x: 3481.5,
    y: 426.5
  },
  {
    x: 3481.5,
    y: 411.5
  },
  {
    x: 3481.5,
    y: 396.5
  },
  {
    x: 3481.5,
    y: 381.5
  },
  {
    x: 3481.5,
    y: 366.5
  },
  {
    x: 3481.5,
    y: 351.5
  },
  {
    x: 3481.5,
    y: 336.5
  },
  {
    x: 3481.5,
    y: 321.5
  },
  {
    x: 3481.5,
    y: 306.5
  },
  {
    x: 3481.5,
    y: 291.5
  },
  {
    x: 3481.5,
    y: 276.5
  },
  {
    x: 3481.5,
    y: 261.5
  },
  {
    x: 3481.5,
    y: 246.5
  },
  {
    x: 3481.5,
    y: 231.5
  },
  {
    x: 3481.5,
    y: 216.5
  },
  {
    x: 3481.5,
    y: 201.5
  },
  {
    x: 3481.5,
    y: 186.5
  },
  {
    x: 3496.5,
    y: 2046.5
  },
  {
    x: 3496.5,
    y: 2031.5
  },
  {
    x: 3496.5,
    y: 2016.5
  },
  {
    x: 3496.5,
    y: 2001.5
  },
  {
    x: 3496.5,
    y: 1986.5
  },
  {
    x: 3496.5,
    y: 1971.5
  },
  {
    x: 3496.5,
    y: 1956.5
  },
  {
    x: 3496.5,
    y: 1941.5
  },
  {
    x: 3496.5,
    y: 1926.5
  },
  {
    x: 3496.5,
    y: 1911.5
  },
  {
    x: 3496.5,
    y: 1896.5
  },
  {
    x: 3496.5,
    y: 1881.5
  },
  {
    x: 3496.5,
    y: 1866.5
  },
  {
    x: 3496.5,
    y: 1851.5
  },
  {
    x: 3496.5,
    y: 1836.5
  },
  {
    x: 3496.5,
    y: 1821.5
  },
  {
    x: 3496.5,
    y: 1806.5
  },
  {
    x: 3496.5,
    y: 1791.5
  },
  {
    x: 3496.5,
    y: 1386.5
  },
  {
    x: 3496.5,
    y: 1371.5
  },
  {
    x: 3496.5,
    y: 1356.5
  },
  {
    x: 3496.5,
    y: 1341.5
  },
  {
    x: 3496.5,
    y: 1326.5
  },
  {
    x: 3496.5,
    y: 1311.5
  },
  {
    x: 3496.5,
    y: 1296.5
  },
  {
    x: 3496.5,
    y: 1281.5
  },
  {
    x: 3496.5,
    y: 1266.5
  },
  {
    x: 3496.5,
    y: 1251.5
  },
  {
    x: 3496.5,
    y: 1236.5
  },
  {
    x: 3496.5,
    y: 1221.5
  },
  {
    x: 3496.5,
    y: 1206.5
  },
  {
    x: 3496.5,
    y: 1191.5
  },
  {
    x: 3496.5,
    y: 1041.5
  },
  {
    x: 3496.5,
    y: 1026.5
  },
  {
    x: 3496.5,
    y: 1011.5
  },
  {
    x: 3496.5,
    y: 621.5
  },
  {
    x: 3496.5,
    y: 606.5
  },
  {
    x: 3496.5,
    y: 591.5
  },
  {
    x: 3496.5,
    y: 576.5
  },
  {
    x: 3496.5,
    y: 561.5
  },
  {
    x: 3496.5,
    y: 546.5
  },
  {
    x: 3496.5,
    y: 531.5
  },
  {
    x: 3496.5,
    y: 516.5
  },
  {
    x: 3496.5,
    y: 501.5
  },
  {
    x: 3496.5,
    y: 486.5
  },
  {
    x: 3496.5,
    y: 471.5
  },
  {
    x: 3496.5,
    y: 456.5
  },
  {
    x: 3496.5,
    y: 441.5
  },
  {
    x: 3496.5,
    y: 426.5
  },
  {
    x: 3496.5,
    y: 411.5
  },
  {
    x: 3496.5,
    y: 396.5
  },
  {
    x: 3496.5,
    y: 381.5
  },
  {
    x: 3496.5,
    y: 366.5
  },
  {
    x: 3496.5,
    y: 351.5
  },
  {
    x: 3496.5,
    y: 336.5
  },
  {
    x: 3496.5,
    y: 321.5
  },
  {
    x: 3496.5,
    y: 306.5
  },
  {
    x: 3496.5,
    y: 291.5
  },
  {
    x: 3496.5,
    y: 276.5
  },
  {
    x: 3496.5,
    y: 261.5
  },
  {
    x: 3496.5,
    y: 246.5
  },
  {
    x: 3496.5,
    y: 231.5
  },
  {
    x: 3496.5,
    y: 216.5
  },
  {
    x: 3496.5,
    y: 201.5
  },
  {
    x: 3496.5,
    y: 186.5
  },
  {
    x: 3511.5,
    y: 2046.5
  },
  {
    x: 3511.5,
    y: 2031.5
  },
  {
    x: 3511.5,
    y: 2016.5
  },
  {
    x: 3511.5,
    y: 2001.5
  },
  {
    x: 3511.5,
    y: 1986.5
  },
  {
    x: 3511.5,
    y: 1971.5
  },
  {
    x: 3511.5,
    y: 1956.5
  },
  {
    x: 3511.5,
    y: 1941.5
  },
  {
    x: 3511.5,
    y: 1926.5
  },
  {
    x: 3511.5,
    y: 1911.5
  },
  {
    x: 3511.5,
    y: 1896.5
  },
  {
    x: 3511.5,
    y: 1881.5
  },
  {
    x: 3511.5,
    y: 1866.5
  },
  {
    x: 3511.5,
    y: 1851.5
  },
  {
    x: 3511.5,
    y: 1836.5
  },
  {
    x: 3511.5,
    y: 1821.5
  },
  {
    x: 3511.5,
    y: 1806.5
  },
  {
    x: 3511.5,
    y: 1791.5
  },
  {
    x: 3511.5,
    y: 1386.5
  },
  {
    x: 3511.5,
    y: 1371.5
  },
  {
    x: 3511.5,
    y: 1356.5
  },
  {
    x: 3511.5,
    y: 1341.5
  },
  {
    x: 3511.5,
    y: 1326.5
  },
  {
    x: 3511.5,
    y: 1311.5
  },
  {
    x: 3511.5,
    y: 1296.5
  },
  {
    x: 3511.5,
    y: 1281.5
  },
  {
    x: 3511.5,
    y: 1266.5
  },
  {
    x: 3511.5,
    y: 1251.5
  },
  {
    x: 3511.5,
    y: 1236.5
  },
  {
    x: 3511.5,
    y: 1221.5
  },
  {
    x: 3511.5,
    y: 1206.5
  },
  {
    x: 3511.5,
    y: 1191.5
  },
  {
    x: 3511.5,
    y: 1056.5
  },
  {
    x: 3511.5,
    y: 1011.5
  },
  {
    x: 3511.5,
    y: 996.5
  },
  {
    x: 3511.5,
    y: 621.5
  },
  {
    x: 3511.5,
    y: 606.5
  },
  {
    x: 3511.5,
    y: 591.5
  },
  {
    x: 3511.5,
    y: 561.5
  },
  {
    x: 3511.5,
    y: 546.5
  },
  {
    x: 3511.5,
    y: 531.5
  },
  {
    x: 3511.5,
    y: 516.5
  },
  {
    x: 3511.5,
    y: 501.5
  },
  {
    x: 3511.5,
    y: 486.5
  },
  {
    x: 3511.5,
    y: 471.5
  },
  {
    x: 3511.5,
    y: 456.5
  },
  {
    x: 3511.5,
    y: 441.5
  },
  {
    x: 3511.5,
    y: 426.5
  },
  {
    x: 3511.5,
    y: 411.5
  },
  {
    x: 3511.5,
    y: 396.5
  },
  {
    x: 3511.5,
    y: 381.5
  },
  {
    x: 3511.5,
    y: 366.5
  },
  {
    x: 3511.5,
    y: 351.5
  },
  {
    x: 3511.5,
    y: 336.5
  },
  {
    x: 3511.5,
    y: 321.5
  },
  {
    x: 3511.5,
    y: 306.5
  },
  {
    x: 3511.5,
    y: 291.5
  },
  {
    x: 3511.5,
    y: 276.5
  },
  {
    x: 3511.5,
    y: 261.5
  },
  {
    x: 3511.5,
    y: 246.5
  },
  {
    x: 3511.5,
    y: 231.5
  },
  {
    x: 3511.5,
    y: 216.5
  },
  {
    x: 3511.5,
    y: 201.5
  },
  {
    x: 3526.5,
    y: 2046.5
  },
  {
    x: 3526.5,
    y: 2031.5
  },
  {
    x: 3526.5,
    y: 2016.5
  },
  {
    x: 3526.5,
    y: 2001.5
  },
  {
    x: 3526.5,
    y: 1986.5
  },
  {
    x: 3526.5,
    y: 1971.5
  },
  {
    x: 3526.5,
    y: 1956.5
  },
  {
    x: 3526.5,
    y: 1941.5
  },
  {
    x: 3526.5,
    y: 1926.5
  },
  {
    x: 3526.5,
    y: 1911.5
  },
  {
    x: 3526.5,
    y: 1896.5
  },
  {
    x: 3526.5,
    y: 1881.5
  },
  {
    x: 3526.5,
    y: 1866.5
  },
  {
    x: 3526.5,
    y: 1851.5
  },
  {
    x: 3526.5,
    y: 1836.5
  },
  {
    x: 3526.5,
    y: 1821.5
  },
  {
    x: 3526.5,
    y: 1806.5
  },
  {
    x: 3526.5,
    y: 1791.5
  },
  {
    x: 3526.5,
    y: 1371.5
  },
  {
    x: 3526.5,
    y: 1356.5
  },
  {
    x: 3526.5,
    y: 1341.5
  },
  {
    x: 3526.5,
    y: 1326.5
  },
  {
    x: 3526.5,
    y: 1311.5
  },
  {
    x: 3526.5,
    y: 1296.5
  },
  {
    x: 3526.5,
    y: 1281.5
  },
  {
    x: 3526.5,
    y: 1266.5
  },
  {
    x: 3526.5,
    y: 1251.5
  },
  {
    x: 3526.5,
    y: 1236.5
  },
  {
    x: 3526.5,
    y: 1221.5
  },
  {
    x: 3526.5,
    y: 1206.5
  },
  {
    x: 3526.5,
    y: 1191.5
  },
  {
    x: 3526.5,
    y: 1176.5
  },
  {
    x: 3526.5,
    y: 1056.5
  },
  {
    x: 3526.5,
    y: 1041.5
  },
  {
    x: 3526.5,
    y: 1026.5
  },
  {
    x: 3526.5,
    y: 651.5
  },
  {
    x: 3526.5,
    y: 531.5
  },
  {
    x: 3526.5,
    y: 516.5
  },
  {
    x: 3526.5,
    y: 501.5
  },
  {
    x: 3526.5,
    y: 486.5
  },
  {
    x: 3526.5,
    y: 471.5
  },
  {
    x: 3526.5,
    y: 456.5
  },
  {
    x: 3526.5,
    y: 441.5
  },
  {
    x: 3526.5,
    y: 426.5
  },
  {
    x: 3526.5,
    y: 411.5
  },
  {
    x: 3526.5,
    y: 396.5
  },
  {
    x: 3526.5,
    y: 381.5
  },
  {
    x: 3526.5,
    y: 366.5
  },
  {
    x: 3526.5,
    y: 351.5
  },
  {
    x: 3526.5,
    y: 336.5
  },
  {
    x: 3526.5,
    y: 321.5
  },
  {
    x: 3526.5,
    y: 306.5
  },
  {
    x: 3526.5,
    y: 291.5
  },
  {
    x: 3526.5,
    y: 276.5
  },
  {
    x: 3526.5,
    y: 261.5
  },
  {
    x: 3526.5,
    y: 246.5
  },
  {
    x: 3526.5,
    y: 231.5
  },
  {
    x: 3526.5,
    y: 216.5
  },
  {
    x: 3541.5,
    y: 2046.5
  },
  {
    x: 3541.5,
    y: 2031.5
  },
  {
    x: 3541.5,
    y: 2016.5
  },
  {
    x: 3541.5,
    y: 2001.5
  },
  {
    x: 3541.5,
    y: 1986.5
  },
  {
    x: 3541.5,
    y: 1971.5
  },
  {
    x: 3541.5,
    y: 1956.5
  },
  {
    x: 3541.5,
    y: 1941.5
  },
  {
    x: 3541.5,
    y: 1926.5
  },
  {
    x: 3541.5,
    y: 1911.5
  },
  {
    x: 3541.5,
    y: 1896.5
  },
  {
    x: 3541.5,
    y: 1881.5
  },
  {
    x: 3541.5,
    y: 1866.5
  },
  {
    x: 3541.5,
    y: 1851.5
  },
  {
    x: 3541.5,
    y: 1836.5
  },
  {
    x: 3541.5,
    y: 1821.5
  },
  {
    x: 3541.5,
    y: 1806.5
  },
  {
    x: 3541.5,
    y: 1791.5
  },
  {
    x: 3541.5,
    y: 1776.5
  },
  {
    x: 3541.5,
    y: 1371.5
  },
  {
    x: 3541.5,
    y: 1356.5
  },
  {
    x: 3541.5,
    y: 1341.5
  },
  {
    x: 3541.5,
    y: 1326.5
  },
  {
    x: 3541.5,
    y: 1311.5
  },
  {
    x: 3541.5,
    y: 1296.5
  },
  {
    x: 3541.5,
    y: 1281.5
  },
  {
    x: 3541.5,
    y: 1266.5
  },
  {
    x: 3541.5,
    y: 1251.5
  },
  {
    x: 3541.5,
    y: 1236.5
  },
  {
    x: 3541.5,
    y: 1221.5
  },
  {
    x: 3541.5,
    y: 1206.5
  },
  {
    x: 3541.5,
    y: 1191.5
  },
  {
    x: 3541.5,
    y: 1176.5
  },
  {
    x: 3541.5,
    y: 1041.5
  },
  {
    x: 3541.5,
    y: 1026.5
  },
  {
    x: 3541.5,
    y: 666.5
  },
  {
    x: 3541.5,
    y: 651.5
  },
  {
    x: 3541.5,
    y: 636.5
  },
  {
    x: 3541.5,
    y: 531.5
  },
  {
    x: 3541.5,
    y: 516.5
  },
  {
    x: 3541.5,
    y: 501.5
  },
  {
    x: 3541.5,
    y: 486.5
  },
  {
    x: 3541.5,
    y: 471.5
  },
  {
    x: 3541.5,
    y: 456.5
  },
  {
    x: 3541.5,
    y: 441.5
  },
  {
    x: 3541.5,
    y: 426.5
  },
  {
    x: 3541.5,
    y: 411.5
  },
  {
    x: 3541.5,
    y: 396.5
  },
  {
    x: 3541.5,
    y: 381.5
  },
  {
    x: 3541.5,
    y: 366.5
  },
  {
    x: 3541.5,
    y: 351.5
  },
  {
    x: 3541.5,
    y: 336.5
  },
  {
    x: 3541.5,
    y: 321.5
  },
  {
    x: 3541.5,
    y: 306.5
  },
  {
    x: 3541.5,
    y: 291.5
  },
  {
    x: 3541.5,
    y: 276.5
  },
  {
    x: 3541.5,
    y: 261.5
  },
  {
    x: 3541.5,
    y: 246.5
  },
  {
    x: 3541.5,
    y: 231.5
  },
  {
    x: 3541.5,
    y: 216.5
  },
  {
    x: 3556.5,
    y: 2046.5
  },
  {
    x: 3556.5,
    y: 2031.5
  },
  {
    x: 3556.5,
    y: 2016.5
  },
  {
    x: 3556.5,
    y: 2001.5
  },
  {
    x: 3556.5,
    y: 1986.5
  },
  {
    x: 3556.5,
    y: 1971.5
  },
  {
    x: 3556.5,
    y: 1956.5
  },
  {
    x: 3556.5,
    y: 1941.5
  },
  {
    x: 3556.5,
    y: 1926.5
  },
  {
    x: 3556.5,
    y: 1911.5
  },
  {
    x: 3556.5,
    y: 1896.5
  },
  {
    x: 3556.5,
    y: 1881.5
  },
  {
    x: 3556.5,
    y: 1866.5
  },
  {
    x: 3556.5,
    y: 1851.5
  },
  {
    x: 3556.5,
    y: 1836.5
  },
  {
    x: 3556.5,
    y: 1821.5
  },
  {
    x: 3556.5,
    y: 1806.5
  },
  {
    x: 3556.5,
    y: 1791.5
  },
  {
    x: 3556.5,
    y: 1776.5
  },
  {
    x: 3556.5,
    y: 1386.5
  },
  {
    x: 3556.5,
    y: 1371.5
  },
  {
    x: 3556.5,
    y: 1356.5
  },
  {
    x: 3556.5,
    y: 1341.5
  },
  {
    x: 3556.5,
    y: 1326.5
  },
  {
    x: 3556.5,
    y: 1311.5
  },
  {
    x: 3556.5,
    y: 1296.5
  },
  {
    x: 3556.5,
    y: 1281.5
  },
  {
    x: 3556.5,
    y: 1266.5
  },
  {
    x: 3556.5,
    y: 1251.5
  },
  {
    x: 3556.5,
    y: 1236.5
  },
  {
    x: 3556.5,
    y: 1221.5
  },
  {
    x: 3556.5,
    y: 1206.5
  },
  {
    x: 3556.5,
    y: 1191.5
  },
  {
    x: 3556.5,
    y: 1176.5
  },
  {
    x: 3556.5,
    y: 1161.5
  },
  {
    x: 3556.5,
    y: 1056.5
  },
  {
    x: 3556.5,
    y: 1041.5
  },
  {
    x: 3556.5,
    y: 651.5
  },
  {
    x: 3556.5,
    y: 636.5
  },
  {
    x: 3556.5,
    y: 621.5
  },
  {
    x: 3556.5,
    y: 531.5
  },
  {
    x: 3556.5,
    y: 516.5
  },
  {
    x: 3556.5,
    y: 501.5
  },
  {
    x: 3556.5,
    y: 486.5
  },
  {
    x: 3556.5,
    y: 471.5
  },
  {
    x: 3556.5,
    y: 456.5
  },
  {
    x: 3556.5,
    y: 441.5
  },
  {
    x: 3556.5,
    y: 426.5
  },
  {
    x: 3556.5,
    y: 411.5
  },
  {
    x: 3556.5,
    y: 396.5
  },
  {
    x: 3556.5,
    y: 381.5
  },
  {
    x: 3556.5,
    y: 366.5
  },
  {
    x: 3556.5,
    y: 351.5
  },
  {
    x: 3556.5,
    y: 336.5
  },
  {
    x: 3556.5,
    y: 321.5
  },
  {
    x: 3556.5,
    y: 306.5
  },
  {
    x: 3556.5,
    y: 291.5
  },
  {
    x: 3556.5,
    y: 276.5
  },
  {
    x: 3556.5,
    y: 261.5
  },
  {
    x: 3556.5,
    y: 246.5
  },
  {
    x: 3556.5,
    y: 231.5
  },
  {
    x: 3556.5,
    y: 216.5
  },
  {
    x: 3571.5,
    y: 2046.5
  },
  {
    x: 3571.5,
    y: 2031.5
  },
  {
    x: 3571.5,
    y: 2016.5
  },
  {
    x: 3571.5,
    y: 2001.5
  },
  {
    x: 3571.5,
    y: 1986.5
  },
  {
    x: 3571.5,
    y: 1971.5
  },
  {
    x: 3571.5,
    y: 1956.5
  },
  {
    x: 3571.5,
    y: 1941.5
  },
  {
    x: 3571.5,
    y: 1926.5
  },
  {
    x: 3571.5,
    y: 1911.5
  },
  {
    x: 3571.5,
    y: 1896.5
  },
  {
    x: 3571.5,
    y: 1881.5
  },
  {
    x: 3571.5,
    y: 1866.5
  },
  {
    x: 3571.5,
    y: 1851.5
  },
  {
    x: 3571.5,
    y: 1836.5
  },
  {
    x: 3571.5,
    y: 1821.5
  },
  {
    x: 3571.5,
    y: 1806.5
  },
  {
    x: 3571.5,
    y: 1791.5
  },
  {
    x: 3571.5,
    y: 1776.5
  },
  {
    x: 3571.5,
    y: 1386.5
  },
  {
    x: 3571.5,
    y: 1371.5
  },
  {
    x: 3571.5,
    y: 1356.5
  },
  {
    x: 3571.5,
    y: 1341.5
  },
  {
    x: 3571.5,
    y: 1326.5
  },
  {
    x: 3571.5,
    y: 1311.5
  },
  {
    x: 3571.5,
    y: 1296.5
  },
  {
    x: 3571.5,
    y: 1281.5
  },
  {
    x: 3571.5,
    y: 1266.5
  },
  {
    x: 3571.5,
    y: 1251.5
  },
  {
    x: 3571.5,
    y: 1236.5
  },
  {
    x: 3571.5,
    y: 1221.5
  },
  {
    x: 3571.5,
    y: 1206.5
  },
  {
    x: 3571.5,
    y: 1191.5
  },
  {
    x: 3571.5,
    y: 1176.5
  },
  {
    x: 3571.5,
    y: 1161.5
  },
  {
    x: 3571.5,
    y: 1056.5
  },
  {
    x: 3571.5,
    y: 1041.5
  },
  {
    x: 3571.5,
    y: 636.5
  },
  {
    x: 3571.5,
    y: 621.5
  },
  {
    x: 3571.5,
    y: 531.5
  },
  {
    x: 3571.5,
    y: 516.5
  },
  {
    x: 3571.5,
    y: 501.5
  },
  {
    x: 3571.5,
    y: 486.5
  },
  {
    x: 3571.5,
    y: 471.5
  },
  {
    x: 3571.5,
    y: 456.5
  },
  {
    x: 3571.5,
    y: 441.5
  },
  {
    x: 3571.5,
    y: 426.5
  },
  {
    x: 3571.5,
    y: 411.5
  },
  {
    x: 3571.5,
    y: 396.5
  },
  {
    x: 3571.5,
    y: 381.5
  },
  {
    x: 3571.5,
    y: 366.5
  },
  {
    x: 3571.5,
    y: 351.5
  },
  {
    x: 3571.5,
    y: 336.5
  },
  {
    x: 3571.5,
    y: 321.5
  },
  {
    x: 3571.5,
    y: 306.5
  },
  {
    x: 3571.5,
    y: 291.5
  },
  {
    x: 3571.5,
    y: 276.5
  },
  {
    x: 3571.5,
    y: 261.5
  },
  {
    x: 3571.5,
    y: 246.5
  },
  {
    x: 3571.5,
    y: 231.5
  },
  {
    x: 3571.5,
    y: 216.5
  },
  {
    x: 3586.5,
    y: 2046.5
  },
  {
    x: 3586.5,
    y: 2031.5
  },
  {
    x: 3586.5,
    y: 2016.5
  },
  {
    x: 3586.5,
    y: 2001.5
  },
  {
    x: 3586.5,
    y: 1986.5
  },
  {
    x: 3586.5,
    y: 1971.5
  },
  {
    x: 3586.5,
    y: 1956.5
  },
  {
    x: 3586.5,
    y: 1941.5
  },
  {
    x: 3586.5,
    y: 1926.5
  },
  {
    x: 3586.5,
    y: 1911.5
  },
  {
    x: 3586.5,
    y: 1896.5
  },
  {
    x: 3586.5,
    y: 1881.5
  },
  {
    x: 3586.5,
    y: 1866.5
  },
  {
    x: 3586.5,
    y: 1851.5
  },
  {
    x: 3586.5,
    y: 1836.5
  },
  {
    x: 3586.5,
    y: 1821.5
  },
  {
    x: 3586.5,
    y: 1806.5
  },
  {
    x: 3586.5,
    y: 1791.5
  },
  {
    x: 3586.5,
    y: 1776.5
  },
  {
    x: 3586.5,
    y: 1401.5
  },
  {
    x: 3586.5,
    y: 1386.5
  },
  {
    x: 3586.5,
    y: 1371.5
  },
  {
    x: 3586.5,
    y: 1356.5
  },
  {
    x: 3586.5,
    y: 1341.5
  },
  {
    x: 3586.5,
    y: 1326.5
  },
  {
    x: 3586.5,
    y: 1311.5
  },
  {
    x: 3586.5,
    y: 1296.5
  },
  {
    x: 3586.5,
    y: 1281.5
  },
  {
    x: 3586.5,
    y: 1266.5
  },
  {
    x: 3586.5,
    y: 1251.5
  },
  {
    x: 3586.5,
    y: 1236.5
  },
  {
    x: 3586.5,
    y: 1221.5
  },
  {
    x: 3586.5,
    y: 1206.5
  },
  {
    x: 3586.5,
    y: 1191.5
  },
  {
    x: 3586.5,
    y: 1176.5
  },
  {
    x: 3586.5,
    y: 1161.5
  },
  {
    x: 3586.5,
    y: 1071.5
  },
  {
    x: 3586.5,
    y: 636.5
  },
  {
    x: 3586.5,
    y: 621.5
  },
  {
    x: 3586.5,
    y: 516.5
  },
  {
    x: 3586.5,
    y: 501.5
  },
  {
    x: 3586.5,
    y: 486.5
  },
  {
    x: 3586.5,
    y: 471.5
  },
  {
    x: 3586.5,
    y: 456.5
  },
  {
    x: 3586.5,
    y: 441.5
  },
  {
    x: 3586.5,
    y: 426.5
  },
  {
    x: 3586.5,
    y: 411.5
  },
  {
    x: 3586.5,
    y: 396.5
  },
  {
    x: 3586.5,
    y: 381.5
  },
  {
    x: 3586.5,
    y: 366.5
  },
  {
    x: 3586.5,
    y: 351.5
  },
  {
    x: 3586.5,
    y: 336.5
  },
  {
    x: 3586.5,
    y: 321.5
  },
  {
    x: 3586.5,
    y: 306.5
  },
  {
    x: 3586.5,
    y: 291.5
  },
  {
    x: 3586.5,
    y: 276.5
  },
  {
    x: 3586.5,
    y: 261.5
  },
  {
    x: 3586.5,
    y: 246.5
  },
  {
    x: 3586.5,
    y: 231.5
  },
  {
    x: 3586.5,
    y: 216.5
  },
  {
    x: 3601.5,
    y: 2046.5
  },
  {
    x: 3601.5,
    y: 2031.5
  },
  {
    x: 3601.5,
    y: 2016.5
  },
  {
    x: 3601.5,
    y: 2001.5
  },
  {
    x: 3601.5,
    y: 1986.5
  },
  {
    x: 3601.5,
    y: 1971.5
  },
  {
    x: 3601.5,
    y: 1956.5
  },
  {
    x: 3601.5,
    y: 1941.5
  },
  {
    x: 3601.5,
    y: 1926.5
  },
  {
    x: 3601.5,
    y: 1911.5
  },
  {
    x: 3601.5,
    y: 1896.5
  },
  {
    x: 3601.5,
    y: 1881.5
  },
  {
    x: 3601.5,
    y: 1866.5
  },
  {
    x: 3601.5,
    y: 1851.5
  },
  {
    x: 3601.5,
    y: 1836.5
  },
  {
    x: 3601.5,
    y: 1821.5
  },
  {
    x: 3601.5,
    y: 1806.5
  },
  {
    x: 3601.5,
    y: 1791.5
  },
  {
    x: 3601.5,
    y: 1431.5
  },
  {
    x: 3601.5,
    y: 1401.5
  },
  {
    x: 3601.5,
    y: 1386.5
  },
  {
    x: 3601.5,
    y: 1371.5
  },
  {
    x: 3601.5,
    y: 1356.5
  },
  {
    x: 3601.5,
    y: 1341.5
  },
  {
    x: 3601.5,
    y: 1326.5
  },
  {
    x: 3601.5,
    y: 1311.5
  },
  {
    x: 3601.5,
    y: 1296.5
  },
  {
    x: 3601.5,
    y: 1281.5
  },
  {
    x: 3601.5,
    y: 1266.5
  },
  {
    x: 3601.5,
    y: 1251.5
  },
  {
    x: 3601.5,
    y: 1236.5
  },
  {
    x: 3601.5,
    y: 1221.5
  },
  {
    x: 3601.5,
    y: 1206.5
  },
  {
    x: 3601.5,
    y: 1161.5
  },
  {
    x: 3601.5,
    y: 1071.5
  },
  {
    x: 3601.5,
    y: 1056.5
  },
  {
    x: 3601.5,
    y: 1041.5
  },
  {
    x: 3601.5,
    y: 621.5
  },
  {
    x: 3601.5,
    y: 606.5
  },
  {
    x: 3601.5,
    y: 501.5
  },
  {
    x: 3601.5,
    y: 486.5
  },
  {
    x: 3601.5,
    y: 471.5
  },
  {
    x: 3601.5,
    y: 456.5
  },
  {
    x: 3601.5,
    y: 441.5
  },
  {
    x: 3601.5,
    y: 426.5
  },
  {
    x: 3601.5,
    y: 411.5
  },
  {
    x: 3601.5,
    y: 396.5
  },
  {
    x: 3601.5,
    y: 381.5
  },
  {
    x: 3601.5,
    y: 366.5
  },
  {
    x: 3601.5,
    y: 351.5
  },
  {
    x: 3601.5,
    y: 336.5
  },
  {
    x: 3601.5,
    y: 321.5
  },
  {
    x: 3601.5,
    y: 306.5
  },
  {
    x: 3601.5,
    y: 291.5
  },
  {
    x: 3601.5,
    y: 276.5
  },
  {
    x: 3601.5,
    y: 261.5
  },
  {
    x: 3601.5,
    y: 246.5
  },
  {
    x: 3601.5,
    y: 231.5
  },
  {
    x: 3601.5,
    y: 216.5
  },
  {
    x: 3616.5,
    y: 2046.5
  },
  {
    x: 3616.5,
    y: 2031.5
  },
  {
    x: 3616.5,
    y: 2016.5
  },
  {
    x: 3616.5,
    y: 2001.5
  },
  {
    x: 3616.5,
    y: 1986.5
  },
  {
    x: 3616.5,
    y: 1971.5
  },
  {
    x: 3616.5,
    y: 1956.5
  },
  {
    x: 3616.5,
    y: 1941.5
  },
  {
    x: 3616.5,
    y: 1926.5
  },
  {
    x: 3616.5,
    y: 1911.5
  },
  {
    x: 3616.5,
    y: 1896.5
  },
  {
    x: 3616.5,
    y: 1881.5
  },
  {
    x: 3616.5,
    y: 1866.5
  },
  {
    x: 3616.5,
    y: 1851.5
  },
  {
    x: 3616.5,
    y: 1836.5
  },
  {
    x: 3616.5,
    y: 1821.5
  },
  {
    x: 3616.5,
    y: 1806.5
  },
  {
    x: 3616.5,
    y: 1791.5
  },
  {
    x: 3616.5,
    y: 1431.5
  },
  {
    x: 3616.5,
    y: 1416.5
  },
  {
    x: 3616.5,
    y: 1401.5
  },
  {
    x: 3616.5,
    y: 1386.5
  },
  {
    x: 3616.5,
    y: 1371.5
  },
  {
    x: 3616.5,
    y: 1356.5
  },
  {
    x: 3616.5,
    y: 1341.5
  },
  {
    x: 3616.5,
    y: 1326.5
  },
  {
    x: 3616.5,
    y: 1311.5
  },
  {
    x: 3616.5,
    y: 1296.5
  },
  {
    x: 3616.5,
    y: 1281.5
  },
  {
    x: 3616.5,
    y: 1266.5
  },
  {
    x: 3616.5,
    y: 1251.5
  },
  {
    x: 3616.5,
    y: 1236.5
  },
  {
    x: 3616.5,
    y: 1221.5
  },
  {
    x: 3616.5,
    y: 1116.5
  },
  {
    x: 3616.5,
    y: 1071.5
  },
  {
    x: 3616.5,
    y: 1056.5
  },
  {
    x: 3616.5,
    y: 1041.5
  },
  {
    x: 3616.5,
    y: 621.5
  },
  {
    x: 3616.5,
    y: 606.5
  },
  {
    x: 3616.5,
    y: 501.5
  },
  {
    x: 3616.5,
    y: 486.5
  },
  {
    x: 3616.5,
    y: 471.5
  },
  {
    x: 3616.5,
    y: 456.5
  },
  {
    x: 3616.5,
    y: 441.5
  },
  {
    x: 3616.5,
    y: 426.5
  },
  {
    x: 3616.5,
    y: 411.5
  },
  {
    x: 3616.5,
    y: 396.5
  },
  {
    x: 3616.5,
    y: 381.5
  },
  {
    x: 3616.5,
    y: 366.5
  },
  {
    x: 3616.5,
    y: 351.5
  },
  {
    x: 3616.5,
    y: 336.5
  },
  {
    x: 3616.5,
    y: 321.5
  },
  {
    x: 3616.5,
    y: 306.5
  },
  {
    x: 3616.5,
    y: 291.5
  },
  {
    x: 3616.5,
    y: 276.5
  },
  {
    x: 3616.5,
    y: 261.5
  },
  {
    x: 3616.5,
    y: 246.5
  },
  {
    x: 3616.5,
    y: 231.5
  },
  {
    x: 3616.5,
    y: 216.5
  },
  {
    x: 3616.5,
    y: 171.5
  },
  {
    x: 3631.5,
    y: 2046.5
  },
  {
    x: 3631.5,
    y: 2031.5
  },
  {
    x: 3631.5,
    y: 2016.5
  },
  {
    x: 3631.5,
    y: 2001.5
  },
  {
    x: 3631.5,
    y: 1986.5
  },
  {
    x: 3631.5,
    y: 1971.5
  },
  {
    x: 3631.5,
    y: 1956.5
  },
  {
    x: 3631.5,
    y: 1941.5
  },
  {
    x: 3631.5,
    y: 1926.5
  },
  {
    x: 3631.5,
    y: 1911.5
  },
  {
    x: 3631.5,
    y: 1896.5
  },
  {
    x: 3631.5,
    y: 1881.5
  },
  {
    x: 3631.5,
    y: 1866.5
  },
  {
    x: 3631.5,
    y: 1851.5
  },
  {
    x: 3631.5,
    y: 1836.5
  },
  {
    x: 3631.5,
    y: 1821.5
  },
  {
    x: 3631.5,
    y: 1806.5
  },
  {
    x: 3631.5,
    y: 1791.5
  },
  {
    x: 3631.5,
    y: 1431.5
  },
  {
    x: 3631.5,
    y: 1416.5
  },
  {
    x: 3631.5,
    y: 1401.5
  },
  {
    x: 3631.5,
    y: 1386.5
  },
  {
    x: 3631.5,
    y: 1371.5
  },
  {
    x: 3631.5,
    y: 1356.5
  },
  {
    x: 3631.5,
    y: 1341.5
  },
  {
    x: 3631.5,
    y: 1326.5
  },
  {
    x: 3631.5,
    y: 1311.5
  },
  {
    x: 3631.5,
    y: 1296.5
  },
  {
    x: 3631.5,
    y: 1281.5
  },
  {
    x: 3631.5,
    y: 1266.5
  },
  {
    x: 3631.5,
    y: 1251.5
  },
  {
    x: 3631.5,
    y: 1236.5
  },
  {
    x: 3631.5,
    y: 1221.5
  },
  {
    x: 3631.5,
    y: 1116.5
  },
  {
    x: 3631.5,
    y: 1101.5
  },
  {
    x: 3631.5,
    y: 1086.5
  },
  {
    x: 3631.5,
    y: 1071.5
  },
  {
    x: 3631.5,
    y: 1056.5
  },
  {
    x: 3631.5,
    y: 621.5
  },
  {
    x: 3631.5,
    y: 606.5
  },
  {
    x: 3631.5,
    y: 591.5
  },
  {
    x: 3631.5,
    y: 471.5
  },
  {
    x: 3631.5,
    y: 456.5
  },
  {
    x: 3631.5,
    y: 441.5
  },
  {
    x: 3631.5,
    y: 426.5
  },
  {
    x: 3631.5,
    y: 411.5
  },
  {
    x: 3631.5,
    y: 366.5
  },
  {
    x: 3631.5,
    y: 351.5
  },
  {
    x: 3631.5,
    y: 336.5
  },
  {
    x: 3631.5,
    y: 321.5
  },
  {
    x: 3631.5,
    y: 306.5
  },
  {
    x: 3631.5,
    y: 291.5
  },
  {
    x: 3631.5,
    y: 276.5
  },
  {
    x: 3631.5,
    y: 261.5
  },
  {
    x: 3631.5,
    y: 246.5
  },
  {
    x: 3631.5,
    y: 231.5
  },
  {
    x: 3631.5,
    y: 216.5
  },
  {
    x: 3631.5,
    y: 201.5
  },
  {
    x: 3631.5,
    y: 171.5
  },
  {
    x: 3631.5,
    y: 156.5
  },
  {
    x: 3646.5,
    y: 2046.5
  },
  {
    x: 3646.5,
    y: 2031.5
  },
  {
    x: 3646.5,
    y: 2016.5
  },
  {
    x: 3646.5,
    y: 2001.5
  },
  {
    x: 3646.5,
    y: 1986.5
  },
  {
    x: 3646.5,
    y: 1971.5
  },
  {
    x: 3646.5,
    y: 1956.5
  },
  {
    x: 3646.5,
    y: 1941.5
  },
  {
    x: 3646.5,
    y: 1926.5
  },
  {
    x: 3646.5,
    y: 1911.5
  },
  {
    x: 3646.5,
    y: 1896.5
  },
  {
    x: 3646.5,
    y: 1881.5
  },
  {
    x: 3646.5,
    y: 1866.5
  },
  {
    x: 3646.5,
    y: 1851.5
  },
  {
    x: 3646.5,
    y: 1836.5
  },
  {
    x: 3646.5,
    y: 1821.5
  },
  {
    x: 3646.5,
    y: 1806.5
  },
  {
    x: 3646.5,
    y: 1791.5
  },
  {
    x: 3646.5,
    y: 1446.5
  },
  {
    x: 3646.5,
    y: 1431.5
  },
  {
    x: 3646.5,
    y: 1416.5
  },
  {
    x: 3646.5,
    y: 1401.5
  },
  {
    x: 3646.5,
    y: 1386.5
  },
  {
    x: 3646.5,
    y: 1371.5
  },
  {
    x: 3646.5,
    y: 1356.5
  },
  {
    x: 3646.5,
    y: 1341.5
  },
  {
    x: 3646.5,
    y: 1326.5
  },
  {
    x: 3646.5,
    y: 1311.5
  },
  {
    x: 3646.5,
    y: 1296.5
  },
  {
    x: 3646.5,
    y: 1281.5
  },
  {
    x: 3646.5,
    y: 1266.5
  },
  {
    x: 3646.5,
    y: 1251.5
  },
  {
    x: 3646.5,
    y: 1236.5
  },
  {
    x: 3646.5,
    y: 1116.5
  },
  {
    x: 3646.5,
    y: 1101.5
  },
  {
    x: 3646.5,
    y: 1086.5
  },
  {
    x: 3646.5,
    y: 1071.5
  },
  {
    x: 3646.5,
    y: 1056.5
  },
  {
    x: 3646.5,
    y: 621.5
  },
  {
    x: 3646.5,
    y: 606.5
  },
  {
    x: 3646.5,
    y: 591.5
  },
  {
    x: 3646.5,
    y: 576.5
  },
  {
    x: 3646.5,
    y: 561.5
  },
  {
    x: 3646.5,
    y: 546.5
  },
  {
    x: 3646.5,
    y: 456.5
  },
  {
    x: 3646.5,
    y: 441.5
  },
  {
    x: 3646.5,
    y: 426.5
  },
  {
    x: 3646.5,
    y: 411.5
  },
  {
    x: 3646.5,
    y: 351.5
  },
  {
    x: 3646.5,
    y: 336.5
  },
  {
    x: 3646.5,
    y: 321.5
  },
  {
    x: 3646.5,
    y: 306.5
  },
  {
    x: 3646.5,
    y: 291.5
  },
  {
    x: 3646.5,
    y: 276.5
  },
  {
    x: 3646.5,
    y: 261.5
  },
  {
    x: 3646.5,
    y: 246.5
  },
  {
    x: 3646.5,
    y: 231.5
  },
  {
    x: 3646.5,
    y: 216.5
  },
  {
    x: 3646.5,
    y: 201.5
  },
  {
    x: 3646.5,
    y: 186.5
  },
  {
    x: 3646.5,
    y: 171.5
  },
  {
    x: 3661.5,
    y: 2046.5
  },
  {
    x: 3661.5,
    y: 2031.5
  },
  {
    x: 3661.5,
    y: 2016.5
  },
  {
    x: 3661.5,
    y: 2001.5
  },
  {
    x: 3661.5,
    y: 1986.5
  },
  {
    x: 3661.5,
    y: 1971.5
  },
  {
    x: 3661.5,
    y: 1956.5
  },
  {
    x: 3661.5,
    y: 1941.5
  },
  {
    x: 3661.5,
    y: 1926.5
  },
  {
    x: 3661.5,
    y: 1911.5
  },
  {
    x: 3661.5,
    y: 1896.5
  },
  {
    x: 3661.5,
    y: 1881.5
  },
  {
    x: 3661.5,
    y: 1866.5
  },
  {
    x: 3661.5,
    y: 1851.5
  },
  {
    x: 3661.5,
    y: 1836.5
  },
  {
    x: 3661.5,
    y: 1821.5
  },
  {
    x: 3661.5,
    y: 1806.5
  },
  {
    x: 3661.5,
    y: 1791.5
  },
  {
    x: 3661.5,
    y: 1461.5
  },
  {
    x: 3661.5,
    y: 1446.5
  },
  {
    x: 3661.5,
    y: 1431.5
  },
  {
    x: 3661.5,
    y: 1416.5
  },
  {
    x: 3661.5,
    y: 1401.5
  },
  {
    x: 3661.5,
    y: 1386.5
  },
  {
    x: 3661.5,
    y: 1371.5
  },
  {
    x: 3661.5,
    y: 1356.5
  },
  {
    x: 3661.5,
    y: 1341.5
  },
  {
    x: 3661.5,
    y: 1326.5
  },
  {
    x: 3661.5,
    y: 1311.5
  },
  {
    x: 3661.5,
    y: 1296.5
  },
  {
    x: 3661.5,
    y: 1281.5
  },
  {
    x: 3661.5,
    y: 1266.5
  },
  {
    x: 3661.5,
    y: 1251.5
  },
  {
    x: 3661.5,
    y: 1236.5
  },
  {
    x: 3661.5,
    y: 1221.5
  },
  {
    x: 3661.5,
    y: 1206.5
  },
  {
    x: 3661.5,
    y: 1191.5
  },
  {
    x: 3661.5,
    y: 1176.5
  },
  {
    x: 3661.5,
    y: 1161.5
  },
  {
    x: 3661.5,
    y: 1116.5
  },
  {
    x: 3661.5,
    y: 1101.5
  },
  {
    x: 3661.5,
    y: 1086.5
  },
  {
    x: 3661.5,
    y: 1071.5
  },
  {
    x: 3661.5,
    y: 1056.5
  },
  {
    x: 3661.5,
    y: 576.5
  },
  {
    x: 3661.5,
    y: 531.5
  },
  {
    x: 3661.5,
    y: 516.5
  },
  {
    x: 3661.5,
    y: 501.5
  },
  {
    x: 3661.5,
    y: 486.5
  },
  {
    x: 3661.5,
    y: 471.5
  },
  {
    x: 3661.5,
    y: 456.5
  },
  {
    x: 3661.5,
    y: 441.5
  },
  {
    x: 3661.5,
    y: 426.5
  },
  {
    x: 3661.5,
    y: 351.5
  },
  {
    x: 3661.5,
    y: 336.5
  },
  {
    x: 3661.5,
    y: 321.5
  },
  {
    x: 3661.5,
    y: 306.5
  },
  {
    x: 3661.5,
    y: 291.5
  },
  {
    x: 3661.5,
    y: 276.5
  },
  {
    x: 3661.5,
    y: 261.5
  },
  {
    x: 3661.5,
    y: 246.5
  },
  {
    x: 3661.5,
    y: 231.5
  },
  {
    x: 3661.5,
    y: 216.5
  },
  {
    x: 3661.5,
    y: 201.5
  },
  {
    x: 3661.5,
    y: 186.5
  },
  {
    x: 3661.5,
    y: 171.5
  },
  {
    x: 3661.5,
    y: 156.5
  },
  {
    x: 3676.5,
    y: 2046.5
  },
  {
    x: 3676.5,
    y: 2031.5
  },
  {
    x: 3676.5,
    y: 2016.5
  },
  {
    x: 3676.5,
    y: 2001.5
  },
  {
    x: 3676.5,
    y: 1986.5
  },
  {
    x: 3676.5,
    y: 1971.5
  },
  {
    x: 3676.5,
    y: 1956.5
  },
  {
    x: 3676.5,
    y: 1941.5
  },
  {
    x: 3676.5,
    y: 1926.5
  },
  {
    x: 3676.5,
    y: 1911.5
  },
  {
    x: 3676.5,
    y: 1896.5
  },
  {
    x: 3676.5,
    y: 1881.5
  },
  {
    x: 3676.5,
    y: 1866.5
  },
  {
    x: 3676.5,
    y: 1851.5
  },
  {
    x: 3676.5,
    y: 1836.5
  },
  {
    x: 3676.5,
    y: 1821.5
  },
  {
    x: 3676.5,
    y: 1806.5
  },
  {
    x: 3676.5,
    y: 1791.5
  },
  {
    x: 3676.5,
    y: 1461.5
  },
  {
    x: 3676.5,
    y: 1446.5
  },
  {
    x: 3676.5,
    y: 1431.5
  },
  {
    x: 3676.5,
    y: 1416.5
  },
  {
    x: 3676.5,
    y: 1401.5
  },
  {
    x: 3676.5,
    y: 1386.5
  },
  {
    x: 3676.5,
    y: 1371.5
  },
  {
    x: 3676.5,
    y: 1356.5
  },
  {
    x: 3676.5,
    y: 1341.5
  },
  {
    x: 3676.5,
    y: 1326.5
  },
  {
    x: 3676.5,
    y: 1311.5
  },
  {
    x: 3676.5,
    y: 1296.5
  },
  {
    x: 3676.5,
    y: 1281.5
  },
  {
    x: 3676.5,
    y: 1266.5
  },
  {
    x: 3676.5,
    y: 1251.5
  },
  {
    x: 3676.5,
    y: 1236.5
  },
  {
    x: 3676.5,
    y: 1221.5
  },
  {
    x: 3676.5,
    y: 1206.5
  },
  {
    x: 3676.5,
    y: 1191.5
  },
  {
    x: 3676.5,
    y: 1176.5
  },
  {
    x: 3676.5,
    y: 1161.5
  },
  {
    x: 3676.5,
    y: 1116.5
  },
  {
    x: 3676.5,
    y: 1101.5
  },
  {
    x: 3676.5,
    y: 1086.5
  },
  {
    x: 3676.5,
    y: 1071.5
  },
  {
    x: 3676.5,
    y: 546.5
  },
  {
    x: 3676.5,
    y: 531.5
  },
  {
    x: 3676.5,
    y: 516.5
  },
  {
    x: 3676.5,
    y: 486.5
  },
  {
    x: 3676.5,
    y: 456.5
  },
  {
    x: 3676.5,
    y: 441.5
  },
  {
    x: 3676.5,
    y: 426.5
  },
  {
    x: 3676.5,
    y: 336.5
  },
  {
    x: 3676.5,
    y: 321.5
  },
  {
    x: 3676.5,
    y: 306.5
  },
  {
    x: 3676.5,
    y: 291.5
  },
  {
    x: 3676.5,
    y: 276.5
  },
  {
    x: 3676.5,
    y: 261.5
  },
  {
    x: 3676.5,
    y: 246.5
  },
  {
    x: 3676.5,
    y: 231.5
  },
  {
    x: 3676.5,
    y: 216.5
  },
  {
    x: 3676.5,
    y: 201.5
  },
  {
    x: 3676.5,
    y: 186.5
  },
  {
    x: 3676.5,
    y: 171.5
  },
  {
    x: 3691.5,
    y: 2046.5
  },
  {
    x: 3691.5,
    y: 2031.5
  },
  {
    x: 3691.5,
    y: 2016.5
  },
  {
    x: 3691.5,
    y: 2001.5
  },
  {
    x: 3691.5,
    y: 1986.5
  },
  {
    x: 3691.5,
    y: 1971.5
  },
  {
    x: 3691.5,
    y: 1956.5
  },
  {
    x: 3691.5,
    y: 1941.5
  },
  {
    x: 3691.5,
    y: 1926.5
  },
  {
    x: 3691.5,
    y: 1911.5
  },
  {
    x: 3691.5,
    y: 1896.5
  },
  {
    x: 3691.5,
    y: 1881.5
  },
  {
    x: 3691.5,
    y: 1866.5
  },
  {
    x: 3691.5,
    y: 1851.5
  },
  {
    x: 3691.5,
    y: 1836.5
  },
  {
    x: 3691.5,
    y: 1821.5
  },
  {
    x: 3691.5,
    y: 1806.5
  },
  {
    x: 3691.5,
    y: 1461.5
  },
  {
    x: 3691.5,
    y: 1446.5
  },
  {
    x: 3691.5,
    y: 1431.5
  },
  {
    x: 3691.5,
    y: 1416.5
  },
  {
    x: 3691.5,
    y: 1401.5
  },
  {
    x: 3691.5,
    y: 1386.5
  },
  {
    x: 3691.5,
    y: 1371.5
  },
  {
    x: 3691.5,
    y: 1356.5
  },
  {
    x: 3691.5,
    y: 1341.5
  },
  {
    x: 3691.5,
    y: 1326.5
  },
  {
    x: 3691.5,
    y: 1311.5
  },
  {
    x: 3691.5,
    y: 1296.5
  },
  {
    x: 3691.5,
    y: 1281.5
  },
  {
    x: 3691.5,
    y: 1266.5
  },
  {
    x: 3691.5,
    y: 1251.5
  },
  {
    x: 3691.5,
    y: 1236.5
  },
  {
    x: 3691.5,
    y: 1221.5
  },
  {
    x: 3691.5,
    y: 1206.5
  },
  {
    x: 3691.5,
    y: 1191.5
  },
  {
    x: 3691.5,
    y: 1101.5
  },
  {
    x: 3691.5,
    y: 1086.5
  },
  {
    x: 3691.5,
    y: 1071.5
  },
  {
    x: 3691.5,
    y: 531.5
  },
  {
    x: 3691.5,
    y: 336.5
  },
  {
    x: 3691.5,
    y: 321.5
  },
  {
    x: 3691.5,
    y: 306.5
  },
  {
    x: 3691.5,
    y: 291.5
  },
  {
    x: 3691.5,
    y: 276.5
  },
  {
    x: 3691.5,
    y: 261.5
  },
  {
    x: 3691.5,
    y: 246.5
  },
  {
    x: 3691.5,
    y: 231.5
  },
  {
    x: 3691.5,
    y: 216.5
  },
  {
    x: 3691.5,
    y: 201.5
  },
  {
    x: 3706.5,
    y: 2046.5
  },
  {
    x: 3706.5,
    y: 2031.5
  },
  {
    x: 3706.5,
    y: 2016.5
  },
  {
    x: 3706.5,
    y: 2001.5
  },
  {
    x: 3706.5,
    y: 1986.5
  },
  {
    x: 3706.5,
    y: 1971.5
  },
  {
    x: 3706.5,
    y: 1956.5
  },
  {
    x: 3706.5,
    y: 1941.5
  },
  {
    x: 3706.5,
    y: 1926.5
  },
  {
    x: 3706.5,
    y: 1911.5
  },
  {
    x: 3706.5,
    y: 1896.5
  },
  {
    x: 3706.5,
    y: 1881.5
  },
  {
    x: 3706.5,
    y: 1866.5
  },
  {
    x: 3706.5,
    y: 1851.5
  },
  {
    x: 3706.5,
    y: 1836.5
  },
  {
    x: 3706.5,
    y: 1821.5
  },
  {
    x: 3706.5,
    y: 1806.5
  },
  {
    x: 3706.5,
    y: 1506.5
  },
  {
    x: 3706.5,
    y: 1491.5
  },
  {
    x: 3706.5,
    y: 1461.5
  },
  {
    x: 3706.5,
    y: 1446.5
  },
  {
    x: 3706.5,
    y: 1431.5
  },
  {
    x: 3706.5,
    y: 1416.5
  },
  {
    x: 3706.5,
    y: 1401.5
  },
  {
    x: 3706.5,
    y: 1386.5
  },
  {
    x: 3706.5,
    y: 1371.5
  },
  {
    x: 3706.5,
    y: 1356.5
  },
  {
    x: 3706.5,
    y: 1341.5
  },
  {
    x: 3706.5,
    y: 1326.5
  },
  {
    x: 3706.5,
    y: 1311.5
  },
  {
    x: 3706.5,
    y: 1296.5
  },
  {
    x: 3706.5,
    y: 1281.5
  },
  {
    x: 3706.5,
    y: 1266.5
  },
  {
    x: 3706.5,
    y: 1251.5
  },
  {
    x: 3706.5,
    y: 1236.5
  },
  {
    x: 3706.5,
    y: 1221.5
  },
  {
    x: 3706.5,
    y: 1116.5
  },
  {
    x: 3706.5,
    y: 1101.5
  },
  {
    x: 3706.5,
    y: 1086.5
  },
  {
    x: 3706.5,
    y: 531.5
  },
  {
    x: 3706.5,
    y: 336.5
  },
  {
    x: 3706.5,
    y: 321.5
  },
  {
    x: 3706.5,
    y: 306.5
  },
  {
    x: 3706.5,
    y: 291.5
  },
  {
    x: 3706.5,
    y: 276.5
  },
  {
    x: 3706.5,
    y: 261.5
  },
  {
    x: 3706.5,
    y: 246.5
  },
  {
    x: 3706.5,
    y: 231.5
  },
  {
    x: 3706.5,
    y: 216.5
  },
  {
    x: 3706.5,
    y: 201.5
  },
  {
    x: 3721.5,
    y: 2046.5
  },
  {
    x: 3721.5,
    y: 2031.5
  },
  {
    x: 3721.5,
    y: 2016.5
  },
  {
    x: 3721.5,
    y: 2001.5
  },
  {
    x: 3721.5,
    y: 1986.5
  },
  {
    x: 3721.5,
    y: 1971.5
  },
  {
    x: 3721.5,
    y: 1956.5
  },
  {
    x: 3721.5,
    y: 1941.5
  },
  {
    x: 3721.5,
    y: 1926.5
  },
  {
    x: 3721.5,
    y: 1911.5
  },
  {
    x: 3721.5,
    y: 1896.5
  },
  {
    x: 3721.5,
    y: 1881.5
  },
  {
    x: 3721.5,
    y: 1866.5
  },
  {
    x: 3721.5,
    y: 1851.5
  },
  {
    x: 3721.5,
    y: 1836.5
  },
  {
    x: 3721.5,
    y: 1821.5
  },
  {
    x: 3721.5,
    y: 1806.5
  },
  {
    x: 3721.5,
    y: 1506.5
  },
  {
    x: 3721.5,
    y: 1491.5
  },
  {
    x: 3721.5,
    y: 1461.5
  },
  {
    x: 3721.5,
    y: 1446.5
  },
  {
    x: 3721.5,
    y: 1431.5
  },
  {
    x: 3721.5,
    y: 1416.5
  },
  {
    x: 3721.5,
    y: 1401.5
  },
  {
    x: 3721.5,
    y: 1386.5
  },
  {
    x: 3721.5,
    y: 1371.5
  },
  {
    x: 3721.5,
    y: 1356.5
  },
  {
    x: 3721.5,
    y: 1341.5
  },
  {
    x: 3721.5,
    y: 1326.5
  },
  {
    x: 3721.5,
    y: 1311.5
  },
  {
    x: 3721.5,
    y: 1296.5
  },
  {
    x: 3721.5,
    y: 1281.5
  },
  {
    x: 3721.5,
    y: 1266.5
  },
  {
    x: 3721.5,
    y: 1251.5
  },
  {
    x: 3721.5,
    y: 1131.5
  },
  {
    x: 3721.5,
    y: 1116.5
  },
  {
    x: 3721.5,
    y: 1101.5
  },
  {
    x: 3721.5,
    y: 1086.5
  },
  {
    x: 3721.5,
    y: 516.5
  },
  {
    x: 3721.5,
    y: 336.5
  },
  {
    x: 3721.5,
    y: 321.5
  },
  {
    x: 3721.5,
    y: 306.5
  },
  {
    x: 3721.5,
    y: 291.5
  },
  {
    x: 3721.5,
    y: 276.5
  },
  {
    x: 3721.5,
    y: 261.5
  },
  {
    x: 3721.5,
    y: 246.5
  },
  {
    x: 3721.5,
    y: 231.5
  },
  {
    x: 3721.5,
    y: 216.5
  },
  {
    x: 3721.5,
    y: 201.5
  },
  {
    x: 3721.5,
    y: 171.5
  },
  {
    x: 3736.5,
    y: 2046.5
  },
  {
    x: 3736.5,
    y: 2031.5
  },
  {
    x: 3736.5,
    y: 2016.5
  },
  {
    x: 3736.5,
    y: 2001.5
  },
  {
    x: 3736.5,
    y: 1986.5
  },
  {
    x: 3736.5,
    y: 1971.5
  },
  {
    x: 3736.5,
    y: 1956.5
  },
  {
    x: 3736.5,
    y: 1941.5
  },
  {
    x: 3736.5,
    y: 1926.5
  },
  {
    x: 3736.5,
    y: 1911.5
  },
  {
    x: 3736.5,
    y: 1896.5
  },
  {
    x: 3736.5,
    y: 1881.5
  },
  {
    x: 3736.5,
    y: 1866.5
  },
  {
    x: 3736.5,
    y: 1851.5
  },
  {
    x: 3736.5,
    y: 1836.5
  },
  {
    x: 3736.5,
    y: 1821.5
  },
  {
    x: 3736.5,
    y: 1806.5
  },
  {
    x: 3736.5,
    y: 1491.5
  },
  {
    x: 3736.5,
    y: 1446.5
  },
  {
    x: 3736.5,
    y: 1431.5
  },
  {
    x: 3736.5,
    y: 1416.5
  },
  {
    x: 3736.5,
    y: 1401.5
  },
  {
    x: 3736.5,
    y: 1386.5
  },
  {
    x: 3736.5,
    y: 1371.5
  },
  {
    x: 3736.5,
    y: 1356.5
  },
  {
    x: 3736.5,
    y: 1341.5
  },
  {
    x: 3736.5,
    y: 1326.5
  },
  {
    x: 3736.5,
    y: 1311.5
  },
  {
    x: 3736.5,
    y: 1296.5
  },
  {
    x: 3736.5,
    y: 1281.5
  },
  {
    x: 3736.5,
    y: 1266.5
  },
  {
    x: 3736.5,
    y: 1251.5
  },
  {
    x: 3736.5,
    y: 1131.5
  },
  {
    x: 3736.5,
    y: 1086.5
  },
  {
    x: 3736.5,
    y: 336.5
  },
  {
    x: 3736.5,
    y: 321.5
  },
  {
    x: 3736.5,
    y: 306.5
  },
  {
    x: 3736.5,
    y: 291.5
  },
  {
    x: 3736.5,
    y: 276.5
  },
  {
    x: 3736.5,
    y: 261.5
  },
  {
    x: 3736.5,
    y: 246.5
  },
  {
    x: 3736.5,
    y: 231.5
  },
  {
    x: 3736.5,
    y: 216.5
  },
  {
    x: 3736.5,
    y: 201.5
  },
  {
    x: 3736.5,
    y: 171.5
  },
  {
    x: 3751.5,
    y: 2046.5
  },
  {
    x: 3751.5,
    y: 2031.5
  },
  {
    x: 3751.5,
    y: 2016.5
  },
  {
    x: 3751.5,
    y: 2001.5
  },
  {
    x: 3751.5,
    y: 1986.5
  },
  {
    x: 3751.5,
    y: 1971.5
  },
  {
    x: 3751.5,
    y: 1956.5
  },
  {
    x: 3751.5,
    y: 1941.5
  },
  {
    x: 3751.5,
    y: 1926.5
  },
  {
    x: 3751.5,
    y: 1911.5
  },
  {
    x: 3751.5,
    y: 1896.5
  },
  {
    x: 3751.5,
    y: 1881.5
  },
  {
    x: 3751.5,
    y: 1866.5
  },
  {
    x: 3751.5,
    y: 1851.5
  },
  {
    x: 3751.5,
    y: 1836.5
  },
  {
    x: 3751.5,
    y: 1821.5
  },
  {
    x: 3751.5,
    y: 1806.5
  },
  {
    x: 3751.5,
    y: 1446.5
  },
  {
    x: 3751.5,
    y: 1431.5
  },
  {
    x: 3751.5,
    y: 1416.5
  },
  {
    x: 3751.5,
    y: 1401.5
  },
  {
    x: 3751.5,
    y: 1386.5
  },
  {
    x: 3751.5,
    y: 1371.5
  },
  {
    x: 3751.5,
    y: 1356.5
  },
  {
    x: 3751.5,
    y: 1341.5
  },
  {
    x: 3751.5,
    y: 1326.5
  },
  {
    x: 3751.5,
    y: 1311.5
  },
  {
    x: 3751.5,
    y: 1296.5
  },
  {
    x: 3751.5,
    y: 1281.5
  },
  {
    x: 3751.5,
    y: 1131.5
  },
  {
    x: 3751.5,
    y: 1086.5
  },
  {
    x: 3751.5,
    y: 501.5
  },
  {
    x: 3751.5,
    y: 336.5
  },
  {
    x: 3751.5,
    y: 321.5
  },
  {
    x: 3751.5,
    y: 306.5
  },
  {
    x: 3751.5,
    y: 291.5
  },
  {
    x: 3751.5,
    y: 276.5
  },
  {
    x: 3751.5,
    y: 261.5
  },
  {
    x: 3751.5,
    y: 246.5
  },
  {
    x: 3751.5,
    y: 231.5
  },
  {
    x: 3751.5,
    y: 216.5
  },
  {
    x: 3751.5,
    y: 171.5
  },
  {
    x: 3766.5,
    y: 2046.5
  },
  {
    x: 3766.5,
    y: 2031.5
  },
  {
    x: 3766.5,
    y: 2016.5
  },
  {
    x: 3766.5,
    y: 2001.5
  },
  {
    x: 3766.5,
    y: 1986.5
  },
  {
    x: 3766.5,
    y: 1971.5
  },
  {
    x: 3766.5,
    y: 1956.5
  },
  {
    x: 3766.5,
    y: 1941.5
  },
  {
    x: 3766.5,
    y: 1926.5
  },
  {
    x: 3766.5,
    y: 1911.5
  },
  {
    x: 3766.5,
    y: 1896.5
  },
  {
    x: 3766.5,
    y: 1881.5
  },
  {
    x: 3766.5,
    y: 1866.5
  },
  {
    x: 3766.5,
    y: 1851.5
  },
  {
    x: 3766.5,
    y: 1836.5
  },
  {
    x: 3766.5,
    y: 1821.5
  },
  {
    x: 3766.5,
    y: 1806.5
  },
  {
    x: 3766.5,
    y: 1401.5
  },
  {
    x: 3766.5,
    y: 1386.5
  },
  {
    x: 3766.5,
    y: 1371.5
  },
  {
    x: 3766.5,
    y: 1356.5
  },
  {
    x: 3766.5,
    y: 1341.5
  },
  {
    x: 3766.5,
    y: 1326.5
  },
  {
    x: 3766.5,
    y: 1311.5
  },
  {
    x: 3766.5,
    y: 1296.5
  },
  {
    x: 3766.5,
    y: 1131.5
  },
  {
    x: 3766.5,
    y: 1086.5
  },
  {
    x: 3766.5,
    y: 1056.5
  },
  {
    x: 3766.5,
    y: 351.5
  },
  {
    x: 3766.5,
    y: 336.5
  },
  {
    x: 3766.5,
    y: 321.5
  },
  {
    x: 3766.5,
    y: 306.5
  },
  {
    x: 3766.5,
    y: 291.5
  },
  {
    x: 3766.5,
    y: 276.5
  },
  {
    x: 3766.5,
    y: 261.5
  },
  {
    x: 3766.5,
    y: 246.5
  },
  {
    x: 3766.5,
    y: 231.5
  },
  {
    x: 3766.5,
    y: 216.5
  },
  {
    x: 3781.5,
    y: 2046.5
  },
  {
    x: 3781.5,
    y: 2031.5
  },
  {
    x: 3781.5,
    y: 2016.5
  },
  {
    x: 3781.5,
    y: 2001.5
  },
  {
    x: 3781.5,
    y: 1986.5
  },
  {
    x: 3781.5,
    y: 1971.5
  },
  {
    x: 3781.5,
    y: 1956.5
  },
  {
    x: 3781.5,
    y: 1941.5
  },
  {
    x: 3781.5,
    y: 1926.5
  },
  {
    x: 3781.5,
    y: 1911.5
  },
  {
    x: 3781.5,
    y: 1896.5
  },
  {
    x: 3781.5,
    y: 1881.5
  },
  {
    x: 3781.5,
    y: 1866.5
  },
  {
    x: 3781.5,
    y: 1851.5
  },
  {
    x: 3781.5,
    y: 1836.5
  },
  {
    x: 3781.5,
    y: 1821.5
  },
  {
    x: 3781.5,
    y: 1806.5
  },
  {
    x: 3781.5,
    y: 1386.5
  },
  {
    x: 3781.5,
    y: 1371.5
  },
  {
    x: 3781.5,
    y: 1356.5
  },
  {
    x: 3781.5,
    y: 1341.5
  },
  {
    x: 3781.5,
    y: 1326.5
  },
  {
    x: 3781.5,
    y: 1311.5
  },
  {
    x: 3781.5,
    y: 1086.5
  },
  {
    x: 3781.5,
    y: 1071.5
  },
  {
    x: 3781.5,
    y: 486.5
  },
  {
    x: 3781.5,
    y: 351.5
  },
  {
    x: 3781.5,
    y: 336.5
  },
  {
    x: 3781.5,
    y: 321.5
  },
  {
    x: 3781.5,
    y: 306.5
  },
  {
    x: 3781.5,
    y: 291.5
  },
  {
    x: 3781.5,
    y: 276.5
  },
  {
    x: 3781.5,
    y: 261.5
  },
  {
    x: 3781.5,
    y: 246.5
  },
  {
    x: 3781.5,
    y: 231.5
  },
  {
    x: 3781.5,
    y: 216.5
  },
  {
    x: 3796.5,
    y: 2046.5
  },
  {
    x: 3796.5,
    y: 2031.5
  },
  {
    x: 3796.5,
    y: 2016.5
  },
  {
    x: 3796.5,
    y: 2001.5
  },
  {
    x: 3796.5,
    y: 1986.5
  },
  {
    x: 3796.5,
    y: 1971.5
  },
  {
    x: 3796.5,
    y: 1956.5
  },
  {
    x: 3796.5,
    y: 1941.5
  },
  {
    x: 3796.5,
    y: 1926.5
  },
  {
    x: 3796.5,
    y: 1911.5
  },
  {
    x: 3796.5,
    y: 1896.5
  },
  {
    x: 3796.5,
    y: 1881.5
  },
  {
    x: 3796.5,
    y: 1866.5
  },
  {
    x: 3796.5,
    y: 1851.5
  },
  {
    x: 3796.5,
    y: 1836.5
  },
  {
    x: 3796.5,
    y: 1821.5
  },
  {
    x: 3796.5,
    y: 1806.5
  },
  {
    x: 3796.5,
    y: 1341.5
  },
  {
    x: 3796.5,
    y: 1086.5
  },
  {
    x: 3796.5,
    y: 351.5
  },
  {
    x: 3796.5,
    y: 336.5
  },
  {
    x: 3796.5,
    y: 321.5
  },
  {
    x: 3796.5,
    y: 306.5
  },
  {
    x: 3796.5,
    y: 291.5
  },
  {
    x: 3796.5,
    y: 276.5
  },
  {
    x: 3796.5,
    y: 261.5
  },
  {
    x: 3796.5,
    y: 246.5
  },
  {
    x: 3796.5,
    y: 231.5
  },
  {
    x: 3796.5,
    y: 216.5
  },
  {
    x: 3811.5,
    y: 2046.5
  },
  {
    x: 3811.5,
    y: 2031.5
  },
  {
    x: 3811.5,
    y: 2016.5
  },
  {
    x: 3811.5,
    y: 2001.5
  },
  {
    x: 3811.5,
    y: 1986.5
  },
  {
    x: 3811.5,
    y: 1971.5
  },
  {
    x: 3811.5,
    y: 1956.5
  },
  {
    x: 3811.5,
    y: 1941.5
  },
  {
    x: 3811.5,
    y: 1926.5
  },
  {
    x: 3811.5,
    y: 1911.5
  },
  {
    x: 3811.5,
    y: 1896.5
  },
  {
    x: 3811.5,
    y: 1881.5
  },
  {
    x: 3811.5,
    y: 1866.5
  },
  {
    x: 3811.5,
    y: 1851.5
  },
  {
    x: 3811.5,
    y: 1836.5
  },
  {
    x: 3811.5,
    y: 1821.5
  },
  {
    x: 3811.5,
    y: 1806.5
  },
  {
    x: 3811.5,
    y: 1086.5
  },
  {
    x: 3811.5,
    y: 456.5
  },
  {
    x: 3811.5,
    y: 351.5
  },
  {
    x: 3811.5,
    y: 336.5
  },
  {
    x: 3811.5,
    y: 321.5
  },
  {
    x: 3811.5,
    y: 306.5
  },
  {
    x: 3811.5,
    y: 291.5
  },
  {
    x: 3811.5,
    y: 276.5
  },
  {
    x: 3811.5,
    y: 261.5
  },
  {
    x: 3811.5,
    y: 246.5
  },
  {
    x: 3811.5,
    y: 231.5
  },
  {
    x: 3811.5,
    y: 216.5
  },
  {
    x: 3826.5,
    y: 2046.5
  },
  {
    x: 3826.5,
    y: 2031.5
  },
  {
    x: 3826.5,
    y: 2016.5
  },
  {
    x: 3826.5,
    y: 2001.5
  },
  {
    x: 3826.5,
    y: 1986.5
  },
  {
    x: 3826.5,
    y: 1971.5
  },
  {
    x: 3826.5,
    y: 1956.5
  },
  {
    x: 3826.5,
    y: 1941.5
  },
  {
    x: 3826.5,
    y: 1926.5
  },
  {
    x: 3826.5,
    y: 1911.5
  },
  {
    x: 3826.5,
    y: 1896.5
  },
  {
    x: 3826.5,
    y: 1881.5
  },
  {
    x: 3826.5,
    y: 1866.5
  },
  {
    x: 3826.5,
    y: 1851.5
  },
  {
    x: 3826.5,
    y: 1836.5
  },
  {
    x: 3826.5,
    y: 1821.5
  },
  {
    x: 3826.5,
    y: 1101.5
  },
  {
    x: 3826.5,
    y: 426.5
  },
  {
    x: 3826.5,
    y: 411.5
  },
  {
    x: 3826.5,
    y: 396.5
  },
  {
    x: 3826.5,
    y: 381.5
  },
  {
    x: 3826.5,
    y: 321.5
  },
  {
    x: 3826.5,
    y: 306.5
  },
  {
    x: 3826.5,
    y: 291.5
  },
  {
    x: 3826.5,
    y: 276.5
  },
  {
    x: 3826.5,
    y: 261.5
  },
  {
    x: 3826.5,
    y: 246.5
  },
  {
    x: 3826.5,
    y: 231.5
  },
  {
    x: 3826.5,
    y: 216.5
  },
  {
    x: 3841.5,
    y: 2046.5
  },
  {
    x: 3841.5,
    y: 2031.5
  },
  {
    x: 3841.5,
    y: 2016.5
  },
  {
    x: 3841.5,
    y: 2001.5
  },
  {
    x: 3841.5,
    y: 1986.5
  },
  {
    x: 3841.5,
    y: 1971.5
  },
  {
    x: 3841.5,
    y: 1956.5
  },
  {
    x: 3841.5,
    y: 1941.5
  },
  {
    x: 3841.5,
    y: 1926.5
  },
  {
    x: 3841.5,
    y: 1911.5
  },
  {
    x: 3841.5,
    y: 1896.5
  },
  {
    x: 3841.5,
    y: 1881.5
  },
  {
    x: 3841.5,
    y: 1866.5
  },
  {
    x: 3841.5,
    y: 1851.5
  },
  {
    x: 3841.5,
    y: 1836.5
  },
  {
    x: 3841.5,
    y: 1821.5
  },
  {
    x: 3841.5,
    y: 1116.5
  },
  {
    x: 3841.5,
    y: 426.5
  },
  {
    x: 3841.5,
    y: 411.5
  },
  {
    x: 3841.5,
    y: 396.5
  },
  {
    x: 3841.5,
    y: 381.5
  },
  {
    x: 3841.5,
    y: 366.5
  },
  {
    x: 3841.5,
    y: 321.5
  },
  {
    x: 3841.5,
    y: 306.5
  },
  {
    x: 3841.5,
    y: 291.5
  },
  {
    x: 3841.5,
    y: 276.5
  },
  {
    x: 3841.5,
    y: 261.5
  },
  {
    x: 3841.5,
    y: 246.5
  },
  {
    x: 3841.5,
    y: 231.5
  },
  {
    x: 3841.5,
    y: 216.5
  },
  {
    x: 3856.5,
    y: 2046.5
  },
  {
    x: 3856.5,
    y: 2031.5
  },
  {
    x: 3856.5,
    y: 2016.5
  },
  {
    x: 3856.5,
    y: 2001.5
  },
  {
    x: 3856.5,
    y: 1986.5
  },
  {
    x: 3856.5,
    y: 1971.5
  },
  {
    x: 3856.5,
    y: 1956.5
  },
  {
    x: 3856.5,
    y: 1941.5
  },
  {
    x: 3856.5,
    y: 1926.5
  },
  {
    x: 3856.5,
    y: 1911.5
  },
  {
    x: 3856.5,
    y: 1896.5
  },
  {
    x: 3856.5,
    y: 1881.5
  },
  {
    x: 3856.5,
    y: 1866.5
  },
  {
    x: 3856.5,
    y: 1851.5
  },
  {
    x: 3856.5,
    y: 1836.5
  },
  {
    x: 3856.5,
    y: 1821.5
  },
  {
    x: 3856.5,
    y: 1131.5
  },
  {
    x: 3856.5,
    y: 1116.5
  },
  {
    x: 3856.5,
    y: 411.5
  },
  {
    x: 3856.5,
    y: 396.5
  },
  {
    x: 3856.5,
    y: 381.5
  },
  {
    x: 3856.5,
    y: 366.5
  },
  {
    x: 3856.5,
    y: 306.5
  },
  {
    x: 3856.5,
    y: 291.5
  },
  {
    x: 3856.5,
    y: 276.5
  },
  {
    x: 3856.5,
    y: 261.5
  },
  {
    x: 3856.5,
    y: 246.5
  },
  {
    x: 3856.5,
    y: 231.5
  },
  {
    x: 3856.5,
    y: 216.5
  },
  {
    x: 3871.5,
    y: 2046.5
  },
  {
    x: 3871.5,
    y: 2031.5
  },
  {
    x: 3871.5,
    y: 2016.5
  },
  {
    x: 3871.5,
    y: 2001.5
  },
  {
    x: 3871.5,
    y: 1986.5
  },
  {
    x: 3871.5,
    y: 1971.5
  },
  {
    x: 3871.5,
    y: 1956.5
  },
  {
    x: 3871.5,
    y: 1941.5
  },
  {
    x: 3871.5,
    y: 1926.5
  },
  {
    x: 3871.5,
    y: 1911.5
  },
  {
    x: 3871.5,
    y: 1896.5
  },
  {
    x: 3871.5,
    y: 1881.5
  },
  {
    x: 3871.5,
    y: 1866.5
  },
  {
    x: 3871.5,
    y: 1851.5
  },
  {
    x: 3871.5,
    y: 1836.5
  },
  {
    x: 3871.5,
    y: 1821.5
  },
  {
    x: 3871.5,
    y: 1131.5
  },
  {
    x: 3871.5,
    y: 1116.5
  },
  {
    x: 3871.5,
    y: 396.5
  },
  {
    x: 3871.5,
    y: 381.5
  },
  {
    x: 3871.5,
    y: 366.5
  },
  {
    x: 3871.5,
    y: 351.5
  },
  {
    x: 3871.5,
    y: 321.5
  },
  {
    x: 3871.5,
    y: 306.5
  },
  {
    x: 3871.5,
    y: 291.5
  },
  {
    x: 3871.5,
    y: 276.5
  },
  {
    x: 3871.5,
    y: 261.5
  },
  {
    x: 3871.5,
    y: 246.5
  },
  {
    x: 3871.5,
    y: 231.5
  },
  {
    x: 3871.5,
    y: 216.5
  },
  {
    x: 3886.5,
    y: 2046.5
  },
  {
    x: 3886.5,
    y: 2031.5
  },
  {
    x: 3886.5,
    y: 2016.5
  },
  {
    x: 3886.5,
    y: 2001.5
  },
  {
    x: 3886.5,
    y: 1986.5
  },
  {
    x: 3886.5,
    y: 1971.5
  },
  {
    x: 3886.5,
    y: 1956.5
  },
  {
    x: 3886.5,
    y: 1911.5
  },
  {
    x: 3886.5,
    y: 1896.5
  },
  {
    x: 3886.5,
    y: 1881.5
  },
  {
    x: 3886.5,
    y: 1866.5
  },
  {
    x: 3886.5,
    y: 1851.5
  },
  {
    x: 3886.5,
    y: 1836.5
  },
  {
    x: 3886.5,
    y: 1146.5
  },
  {
    x: 3886.5,
    y: 1131.5
  },
  {
    x: 3886.5,
    y: 396.5
  },
  {
    x: 3886.5,
    y: 381.5
  },
  {
    x: 3886.5,
    y: 366.5
  },
  {
    x: 3886.5,
    y: 351.5
  },
  {
    x: 3886.5,
    y: 321.5
  },
  {
    x: 3886.5,
    y: 306.5
  },
  {
    x: 3886.5,
    y: 291.5
  },
  {
    x: 3886.5,
    y: 276.5
  },
  {
    x: 3886.5,
    y: 261.5
  },
  {
    x: 3886.5,
    y: 246.5
  },
  {
    x: 3886.5,
    y: 231.5
  },
  {
    x: 3901.5,
    y: 2046.5
  },
  {
    x: 3901.5,
    y: 2031.5
  },
  {
    x: 3901.5,
    y: 2016.5
  },
  {
    x: 3901.5,
    y: 2001.5
  },
  {
    x: 3901.5,
    y: 1986.5
  },
  {
    x: 3901.5,
    y: 1971.5
  },
  {
    x: 3901.5,
    y: 1956.5
  },
  {
    x: 3901.5,
    y: 1911.5
  },
  {
    x: 3901.5,
    y: 1866.5
  },
  {
    x: 3901.5,
    y: 1851.5
  },
  {
    x: 3901.5,
    y: 1836.5
  },
  {
    x: 3901.5,
    y: 381.5
  },
  {
    x: 3901.5,
    y: 366.5
  },
  {
    x: 3901.5,
    y: 351.5
  },
  {
    x: 3901.5,
    y: 336.5
  },
  {
    x: 3901.5,
    y: 321.5
  },
  {
    x: 3901.5,
    y: 306.5
  },
  {
    x: 3901.5,
    y: 291.5
  },
  {
    x: 3901.5,
    y: 276.5
  },
  {
    x: 3901.5,
    y: 261.5
  },
  {
    x: 3901.5,
    y: 246.5
  },
  {
    x: 3901.5,
    y: 231.5
  },
  {
    x: 3916.5,
    y: 2046.5
  },
  {
    x: 3916.5,
    y: 2031.5
  },
  {
    x: 3916.5,
    y: 2016.5
  },
  {
    x: 3916.5,
    y: 2001.5
  },
  {
    x: 3916.5,
    y: 1986.5
  },
  {
    x: 3916.5,
    y: 1971.5
  },
  {
    x: 3916.5,
    y: 1911.5
  },
  {
    x: 3916.5,
    y: 1866.5
  },
  {
    x: 3916.5,
    y: 1851.5
  },
  {
    x: 3916.5,
    y: 1836.5
  },
  {
    x: 3916.5,
    y: 1251.5
  },
  {
    x: 3916.5,
    y: 351.5
  },
  {
    x: 3916.5,
    y: 336.5
  },
  {
    x: 3916.5,
    y: 321.5
  },
  {
    x: 3916.5,
    y: 306.5
  },
  {
    x: 3916.5,
    y: 291.5
  },
  {
    x: 3916.5,
    y: 276.5
  },
  {
    x: 3916.5,
    y: 261.5
  },
  {
    x: 3916.5,
    y: 246.5
  },
  {
    x: 3916.5,
    y: 231.5
  },
  {
    x: 3931.5,
    y: 2046.5
  },
  {
    x: 3931.5,
    y: 2031.5
  },
  {
    x: 3931.5,
    y: 2016.5
  },
  {
    x: 3931.5,
    y: 2001.5
  },
  {
    x: 3931.5,
    y: 1986.5
  },
  {
    x: 3931.5,
    y: 1971.5
  },
  {
    x: 3931.5,
    y: 1911.5
  },
  {
    x: 3931.5,
    y: 1851.5
  },
  {
    x: 3931.5,
    y: 1836.5
  },
  {
    x: 3931.5,
    y: 1266.5
  },
  {
    x: 3931.5,
    y: 336.5
  },
  {
    x: 3931.5,
    y: 321.5
  },
  {
    x: 3931.5,
    y: 306.5
  },
  {
    x: 3931.5,
    y: 291.5
  },
  {
    x: 3931.5,
    y: 276.5
  },
  {
    x: 3931.5,
    y: 261.5
  },
  {
    x: 3931.5,
    y: 246.5
  },
  {
    x: 3931.5,
    y: 231.5
  },
  {
    x: 3946.5,
    y: 2046.5
  },
  {
    x: 3946.5,
    y: 2031.5
  },
  {
    x: 3946.5,
    y: 2016.5
  },
  {
    x: 3946.5,
    y: 2001.5
  },
  {
    x: 3946.5,
    y: 1986.5
  },
  {
    x: 3946.5,
    y: 1971.5
  },
  {
    x: 3946.5,
    y: 1911.5
  },
  {
    x: 3946.5,
    y: 1851.5
  },
  {
    x: 3946.5,
    y: 1836.5
  },
  {
    x: 3946.5,
    y: 1266.5
  },
  {
    x: 3946.5,
    y: 1206.5
  },
  {
    x: 3946.5,
    y: 1191.5
  },
  {
    x: 3946.5,
    y: 336.5
  },
  {
    x: 3946.5,
    y: 321.5
  },
  {
    x: 3946.5,
    y: 306.5
  },
  {
    x: 3946.5,
    y: 291.5
  },
  {
    x: 3946.5,
    y: 276.5
  },
  {
    x: 3946.5,
    y: 261.5
  },
  {
    x: 3946.5,
    y: 246.5
  },
  {
    x: 3946.5,
    y: 231.5
  },
  {
    x: 3961.5,
    y: 2046.5
  },
  {
    x: 3961.5,
    y: 2031.5
  },
  {
    x: 3961.5,
    y: 2016.5
  },
  {
    x: 3961.5,
    y: 2001.5
  },
  {
    x: 3961.5,
    y: 1986.5
  },
  {
    x: 3961.5,
    y: 1971.5
  },
  {
    x: 3961.5,
    y: 1851.5
  },
  {
    x: 3961.5,
    y: 1836.5
  },
  {
    x: 3961.5,
    y: 1551.5
  },
  {
    x: 3961.5,
    y: 1536.5
  },
  {
    x: 3961.5,
    y: 1266.5
  },
  {
    x: 3961.5,
    y: 1221.5
  },
  {
    x: 3961.5,
    y: 1206.5
  },
  {
    x: 3961.5,
    y: 1191.5
  },
  {
    x: 3961.5,
    y: 336.5
  },
  {
    x: 3961.5,
    y: 321.5
  },
  {
    x: 3961.5,
    y: 306.5
  },
  {
    x: 3961.5,
    y: 291.5
  },
  {
    x: 3961.5,
    y: 276.5
  },
  {
    x: 3961.5,
    y: 261.5
  },
  {
    x: 3961.5,
    y: 246.5
  },
  {
    x: 3961.5,
    y: 231.5
  },
  {
    x: 3976.5,
    y: 2046.5
  },
  {
    x: 3976.5,
    y: 2031.5
  },
  {
    x: 3976.5,
    y: 2016.5
  },
  {
    x: 3976.5,
    y: 2001.5
  },
  {
    x: 3976.5,
    y: 1986.5
  },
  {
    x: 3976.5,
    y: 1851.5
  },
  {
    x: 3976.5,
    y: 1836.5
  },
  {
    x: 3976.5,
    y: 1551.5
  },
  {
    x: 3976.5,
    y: 1536.5
  },
  {
    x: 3976.5,
    y: 1521.5
  },
  {
    x: 3976.5,
    y: 336.5
  },
  {
    x: 3976.5,
    y: 321.5
  },
  {
    x: 3976.5,
    y: 306.5
  },
  {
    x: 3976.5,
    y: 291.5
  },
  {
    x: 3976.5,
    y: 276.5
  },
  {
    x: 3976.5,
    y: 261.5
  },
  {
    x: 3976.5,
    y: 246.5
  },
  {
    x: 3976.5,
    y: 231.5
  },
  {
    x: 3991.5,
    y: 2046.5
  },
  {
    x: 3991.5,
    y: 2031.5
  },
  {
    x: 3991.5,
    y: 2016.5
  },
  {
    x: 3991.5,
    y: 2001.5
  },
  {
    x: 3991.5,
    y: 1986.5
  },
  {
    x: 3991.5,
    y: 1536.5
  },
  {
    x: 3991.5,
    y: 1521.5
  },
  {
    x: 3991.5,
    y: 336.5
  },
  {
    x: 3991.5,
    y: 321.5
  },
  {
    x: 3991.5,
    y: 306.5
  },
  {
    x: 3991.5,
    y: 291.5
  },
  {
    x: 3991.5,
    y: 276.5
  },
  {
    x: 3991.5,
    y: 261.5
  },
  {
    x: 3991.5,
    y: 246.5
  },
  {
    x: 3991.5,
    y: 231.5
  },
  {
    x: 4006.5,
    y: 2046.5
  },
  {
    x: 4006.5,
    y: 2031.5
  },
  {
    x: 4006.5,
    y: 2016.5
  },
  {
    x: 4006.5,
    y: 2001.5
  },
  {
    x: 4006.5,
    y: 1986.5
  },
  {
    x: 4006.5,
    y: 1521.5
  },
  {
    x: 4006.5,
    y: 1506.5
  },
  {
    x: 4006.5,
    y: 1491.5
  },
  {
    x: 4006.5,
    y: 321.5
  },
  {
    x: 4006.5,
    y: 306.5
  },
  {
    x: 4006.5,
    y: 291.5
  },
  {
    x: 4006.5,
    y: 276.5
  },
  {
    x: 4006.5,
    y: 261.5
  },
  {
    x: 4006.5,
    y: 246.5
  },
  {
    x: 4006.5,
    y: 231.5
  },
  {
    x: 4021.5,
    y: 2046.5
  },
  {
    x: 4021.5,
    y: 2031.5
  },
  {
    x: 4021.5,
    y: 2016.5
  },
  {
    x: 4021.5,
    y: 2001.5
  },
  {
    x: 4021.5,
    y: 1986.5
  },
  {
    x: 4021.5,
    y: 1506.5
  },
  {
    x: 4021.5,
    y: 1491.5
  },
  {
    x: 4021.5,
    y: 426.5
  },
  {
    x: 4021.5,
    y: 321.5
  },
  {
    x: 4021.5,
    y: 306.5
  },
  {
    x: 4021.5,
    y: 291.5
  },
  {
    x: 4021.5,
    y: 276.5
  },
  {
    x: 4021.5,
    y: 261.5
  },
  {
    x: 4021.5,
    y: 246.5
  },
  {
    x: 4021.5,
    y: 231.5
  },
  {
    x: 4036.5,
    y: 2046.5
  },
  {
    x: 4036.5,
    y: 2031.5
  },
  {
    x: 4036.5,
    y: 2016.5
  },
  {
    x: 4036.5,
    y: 2001.5
  },
  {
    x: 4036.5,
    y: 1986.5
  },
  {
    x: 4036.5,
    y: 1491.5
  },
  {
    x: 4036.5,
    y: 1476.5
  },
  {
    x: 4036.5,
    y: 1461.5
  },
  {
    x: 4036.5,
    y: 1446.5
  },
  {
    x: 4036.5,
    y: 306.5
  },
  {
    x: 4036.5,
    y: 291.5
  },
  {
    x: 4036.5,
    y: 276.5
  },
  {
    x: 4036.5,
    y: 261.5
  },
  {
    x: 4036.5,
    y: 246.5
  },
  {
    x: 4036.5,
    y: 231.5
  },
  {
    x: 4051.5,
    y: 2046.5
  },
  {
    x: 4051.5,
    y: 2031.5
  },
  {
    x: 4051.5,
    y: 2016.5
  },
  {
    x: 4051.5,
    y: 2001.5
  },
  {
    x: 4051.5,
    y: 1986.5
  },
  {
    x: 4051.5,
    y: 1491.5
  },
  {
    x: 4051.5,
    y: 1476.5
  },
  {
    x: 4051.5,
    y: 1461.5
  },
  {
    x: 4051.5,
    y: 306.5
  },
  {
    x: 4051.5,
    y: 291.5
  },
  {
    x: 4051.5,
    y: 276.5
  },
  {
    x: 4051.5,
    y: 261.5
  },
  {
    x: 4051.5,
    y: 246.5
  },
  {
    x: 4051.5,
    y: 231.5
  },
  {
    x: 4066.5,
    y: 2046.5
  },
  {
    x: 4066.5,
    y: 2031.5
  },
  {
    x: 4066.5,
    y: 2016.5
  },
  {
    x: 4066.5,
    y: 2001.5
  },
  {
    x: 4066.5,
    y: 1986.5
  },
  {
    x: 4066.5,
    y: 1461.5
  },
  {
    x: 4066.5,
    y: 306.5
  },
  {
    x: 4066.5,
    y: 291.5
  },
  {
    x: 4066.5,
    y: 276.5
  },
  {
    x: 4066.5,
    y: 261.5
  },
  {
    x: 4066.5,
    y: 246.5
  },
  {
    x: 4066.5,
    y: 231.5
  },
  {
    x: 4081.5,
    y: 2046.5
  },
  {
    x: 4081.5,
    y: 2031.5
  },
  {
    x: 4081.5,
    y: 2016.5
  },
  {
    x: 4081.5,
    y: 2001.5
  },
  {
    x: 4081.5,
    y: 1986.5
  },
  {
    x: 4081.5,
    y: 1221.5
  },
  {
    x: 4081.5,
    y: 306.5
  },
  {
    x: 4081.5,
    y: 276.5
  },
  {
    x: 4081.5,
    y: 261.5
  },
  {
    x: 4081.5,
    y: 246.5
  },
  {
    x: 4081.5,
    y: 216.5
  },
  {
    x: 4096.5,
    y: 2046.5
  },
  {
    x: 4096.5,
    y: 2031.5
  },
  {
    x: 4096.5,
    y: 2016.5
  },
  {
    x: 4096.5,
    y: 2001.5
  },
  {
    x: 4096.5,
    y: 1986.5
  },
  {
    x: 4096.5,
    y: 276.5
  },
  {
    x: 4096.5,
    y: 261.5
  },
  {
    x: 4096.5,
    y: 246.5
  },
  {
    x: 4096.5,
    y: 216.5
  }
]
