import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '../../environments/environment';
import { Project } from '../_models/project';
import { AuthenticationService } from '../_services/auth.service';
import { BehaviorSubject, Observable} from 'rxjs';
import { filter, map, take, toArray } from 'rxjs/operators';
import { Router } from '@angular/router';
import dayjs from 'dayjs';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  private notificationInterval;


  private overviewSubject: BehaviorSubject<any>;
  public overview: Observable<any>;

  private projectsSubject: BehaviorSubject<any>;
  public projects: Observable<any>;

  private notificationsSubject: BehaviorSubject<any>;
  public notifications: Observable<any>;

  userId;

  constructor(private router:Router, private http: HttpClient, private auth:AuthenticationService) {

    this.projectsSubject = new BehaviorSubject<any>([]);
    this.projects = this.projectsSubject.asObservable();

    this.overviewSubject = new BehaviorSubject<any>({
      up: 0,
      warning: 0,
      down: 0
    });
    this.overview = this.overviewSubject.asObservable();

    this.notificationsSubject = new BehaviorSubject<any>([]);
    this.notifications = this.notificationsSubject.asObservable();

    this.auth.currentUser.subscribe(user => {
      if(user) {
        if(!this.userId || (this.userId && user.id && user.id !== this.userId)){
          this.userId = user.id;
          this.getProjects();
          this.getNotifications();
          this.getMonitorsOverview();

          clearInterval(this.notificationInterval);
          this.notificationInterval = setInterval(() => {
            if(this.auth.currentUserValue) {
              this.getNotifications();
              this.getMonitorsOverview();
            }
          }, 15000);
        }
      } else {
        this.projectsSubject.next(null);
        clearInterval(this.notificationInterval);
      }
    });
  }

  public get projectsSubjectValue(): any {
    return this.projectsSubject.value;
  }

  public get overviewSubjectValue(): any {
    return this.overviewSubject.value;
  }



  async create(name){
    const n = await this.http.post(`${environment.apiUrl}/v2/projects`, {name}).toPromise();
    this.projects.pipe(take(1)).subscribe(val => {
      console.log(val);
      const newArr = [...val, n];
      console.log(newArr);
      this.projectsSubject.next(newArr);
    });
    return n;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  async getProjects(monitor_order_by = 'name') { 
    let p:any = await this.http.get(`${environment.apiUrl}/v2/projects?monitor_order_by=${monitor_order_by}`).toPromise();
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return p;
  }

  removeDeletedMonitors(p){
    return p.filter(m => m.isDeleted !== true);
  }

  async addMonitor(id, monitor){
    const m = await this.http.post(`${environment.apiUrl}/v2/projects/${id}/monitor`, monitor).toPromise();
    console.log(m);
    let p = this.projectsSubject.value.map(p1 => {
      if(p1.id===id){
        p1.monitors.push(m);
      }
      return p1;
    });
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return true;
  }

  async addSeleniumMonitor(id, monitor) {
    const m = await this.http.post(`${environment.apiUrl}/projects/${id}/monitor/selenium`, monitor).toPromise();
    console.log(m);
    let p = this.projectsSubject.value.map(p1 => {
      if(p1.id===id){
        p1.monitors.push(m);
      }
      return p1;
    });
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return true;
  }

  async updateSeleniumMonitor(id, monitorId, monitor) {
    const m = await this.http.put(`${environment.apiUrl}/projects/${id}/monitor/${monitorId}/selenium`, monitor).toPromise();
    console.log(m);
    let p = this.projectsSubject.value.map(p1 => {
      if(p1.id===id){
        let p2 = p1.monitors.map(p3 => {
          if(p3.id===monitorId){
            p3 = Object.assign(p3, m);
          }
          return p3;
        });
      }
      return p1;
    });
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return true;
  }



  async updateProject(id, project){
    let m = await this.http.put(`${environment.apiUrl}/v2/projects/${id}`, project).toPromise();
    let p = this.projectsSubject.value.map(p1 => {
     if(p1. id===id){
      p1 = Object.assign(p1, { ...m, monitors: p1.monitors });
     }
      return p1;
    });
    this.projectsSubject.next(p);
    return true;
  }

  async updateMonitor(id, monitor){
    let m = await this.http.put(`${environment.apiUrl}/v2/projects/${id}/monitor/${monitor.id}`, monitor).toPromise();
    let p = this.projectsSubject.value.map(p1 => {
      if(p1.id===id){
        let p2 = p1.monitors.map(p3 => {
          if(p3.id===monitor.id){
            p3 = Object.assign(p3, m);
          }
          return p3;
        });
      }
      return p1;
    });
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return true;
  }

  async forceTest(projectId, monitorId){
    return this.http.put(`${environment.apiUrl}/projects/${projectId}/monitor/${monitorId}/force`, {}).toPromise();
  }

  async removeProject(id){
    const del = await this.http.delete(`${environment.apiUrl}/v2/projects/${id}`).toPromise();
    const p = this.projectsSubject.value.filter((project) => project.id !== id);
    this.projectsSubject.next(p);
    return del;
  }

  async removeMonitor(project, monitor){
    const del = await this.http.delete(`${environment.apiUrl}/v2/projects/${project}/monitor/${monitor}`).toPromise();
    console.log(del);
    let p = this.projectsSubject.value.map(p1 => { p1.monitors = p1.monitors.filter((m) => { return m.id !== monitor}); return p1;});
    p = this.updateMonitorTypes(p);
    this.projectsSubject.next(p);
    return true;
  }

  updateMonitorTypes(p){
    return p.map(p1 => {
      let p2 = { ...p1, monitorTypes: { ping:0, load_time:0, dns_propagation:0}, lastUpdated: new Date(p1.modified) };
      p2.monitors = p2.monitors.filter(m => m.isDeleted !== true).map(m => {
        m.jobGroup = this.getJobTypeGroup(m.type);
        return m;
      });
      p2.monitors.forEach(m => {
        if(m.modified>p2.lastUpdated){ p2.lastUpdated = new Date(m.modified); }
        p2.monitorTypes[m.type]++;
      });
      return p2
    });
  }

  getJobTypeGroup(t){
    const jobGroups = {
      http_get: "http_verbs",
      http_post: "http_verbs",
      http_put: "http_verbs",
      http_delete: "http_verbs",
      http_head: "http_verbs",
      http_options: "http_verbs",
    };
    return jobGroups[t] || t;
  }

  async getMonitorsOverview(){
    let o:any = await this.http.get(`${environment.apiUrl}/projects/overview`).toPromise();
    let stats = {
      up: 0,
      warning: 0,
      down: 0
    };
    o = o.filter(o1 => {
      return o1['time_filled'] === o[0]['time_filled']
    }).forEach(o1 => {
      if(o1.percent_total >=99.5){
        stats.up++;
      } else if(o1.percent_total >= 70) {
        stats.warning++;
      } else {
        stats.down++;
      }
    });
    this.overviewSubject.next(stats);
  }

  async getProjectOverview() {
    let o:any = await this.http.get(`${environment.apiUrl}/v2/projects/overview`).toPromise();
    return o;
  }

  async getRawData(project, monitor, name, timeframe="24 hours") {
    const blob:Blob = await this.http.get(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/data?timeframe=${timeframe}`, {responseType: 'blob' }).toPromise();
    const filename = `${name} ${timeframe} ${Math.floor(new Date().getTime()/1000).toString()}.csv`.split(" ").join("_");
    saveAs(blob, filename);
  }

  async downloadExcelSummary(timeframe, project, name) {
    const requestBody = {
      projects: [project],
      timeframe,
      monitor_types: []
    };
    const response:Blob = await this.http.post(`${environment.apiUrl}/v2/export/excel/summary`, requestBody,
    {headers: new HttpHeaders({
      "Content-Type": "application/json"
    }),
    responseType: 'blob'}).toPromise() as Blob;
    const filename = (`${name} ` + dayjs(new Date()).format('DD_MM_YYYY') + ` ${timeframe} ` + 'data' + '.xlsx').split(" ").join("_");
    saveAs(response, filename);
  } 

  async downloadExcelRaw(timeframe, project, name) {
    const requestBody = {
      projects: [project],
      timeframe,
      monitor_types: []
    };
    const response:Blob = await this.http.post(`${environment.apiUrl}/v2/export/excel/raw`, requestBody,
    {headers: new HttpHeaders({
      "Content-Type": "application/json"
    }),
    responseType: 'blob'}).toPromise() as Blob;
    const filename = (`${name} ` + dayjs(new Date()).format('DD_MM_YYYY') + ` ${timeframe} ` + 'data' + '.xlsx').split(" ").join("_");
    saveAs(response, filename);
  }


  async getProjectReportPDF(timeframe, project, name) {
    const blob:Blob = await this.http.get(`https://app.bitping.com/downloadreports/summary?projectId=${project}&timeframe=${timeframe}`, {
      headers: new HttpHeaders({
        "Accept": "application/pdf"
      }),
      responseType: 'blob'
    }).toPromise();
    const filename = (`${name} ` + dayjs(new Date()).format('DD_MM_YYYY') + ` ${timeframe} ` + 'data' + '.pdf').split(" ").join("_");
    saveAs(blob, filename);
  }

  async getProjectBreakdownReportPDF(timeframe, project, name) {
    const blob:Blob = await this.http.get(`https://app.bitping.com/downloadreports/breakdown?projectId=${project}&timeframe=${timeframe}`, {
      headers: new HttpHeaders({
        "Accept": "application/pdf"
      }),
      responseType: 'blob'
    }).toPromise();
    const filename = (`${name} ` + dayjs(new Date()).format('DD_MM_YYYY') + ` ${timeframe} ` + 'data' + '.pdf').split(" ").join("_");
    saveAs(blob, filename);
  }

  getMonitorData(project, monitor, timeframe = '24 hours', timescale = '1 hour'){
    return this.http.get(`${environment.apiUrl}/v2/projects/${project}/monitor/${monitor}/chart?timescale=${timescale}&timeframe=${timeframe}`)
    .toPromise();
  }

  getMonitorNodeBreakdown(region){
    return this.http.get(`${environment.apiUrl}/v2/nodes/${region}/breakdown`).toPromise();
  }

  getMonitorStats(project, monitor, timeframe = '24 hours'){
    return this.http.get(`${environment.apiUrl}/v2/projects/${project}/monitor/${monitor}/stats?timeframe=${timeframe}`).toPromise();
  }
  
  getMonitorGranularStats(project, monitor, timeframe = '24 hours'){
    return this.http.get(`${environment.apiUrl}/v2/projects/${project}/monitor/${monitor}/granular_stats?timeframe=${timeframe}`).toPromise();
  }

  getMonitorJobs(project, monitor){
    return this.http.get(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/jobs`).toPromise();
  }

  getMonitorFailedJobs(project, monitor){
    return this.http.get(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/failed`).toPromise();
  }

  getScreenshots(screenshots, project, monitor) {
    return this.http.post<string[]>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/selenium/screenshots`,
    { keys: screenshots }).toPromise();
  }

  getHLSScreenshot(key, project, monitor): Promise<{ link: string}> {
    return this.http.post<{ link: string}>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/hls/screenshots`,
    { key }).toPromise();
  }


  async monitorCost(project, type, freq) {
    return this.http.get(`${environment.apiUrl}/projects/${project}/monitor/estimate?type=${type}&freq=${freq}`).toPromise();
  }

  async pauseMonitor(project, monitor){
    try {
      const m = await this.http.put(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/pause`, {}).toPromise();
      let p = this.projectsSubject.value.map(p1 => {
        if(p1.id===project){
          let p2 = p1.monitors.map(p3 => {
            if(p3.id===monitor){
              p3.status = 'paused';
            }
            return p3;
          });
        }
        return p1;
      });
      this.projectsSubject.next(p);
      return m;
    } catch(e) {
      throw(e);
    }
  }

  async startMonitor(project, monitor){
    try {
      const m = await this.http.put(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/start`, {}).toPromise();
      let p = this.projectsSubject.value.map(p1 => {
        if(p1.id===project){
          let p2 = p1.monitors.map(p3 => {
            if(p3.id===monitor){
              p3.status = 'idle';
            }
            return p3;
          });
        }
        return p1;
      });
      this.projectsSubject.next(p);
      return m;
    } catch(e) {
      throw(e);
    }
  }

  async getMonitorRegion(project, monitor){
    let monitorRegions = await this.http.get<any[]>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/regionstats`).toPromise();
    monitorRegions.map(r => {
      if(!r.avg_response_time) {
        // r.avg_response_time = -1;
        r.class = 'down';
      }
    })
    return monitorRegions;
  }

  async getARecordStats(project, monitor){
    let arecord = await this.http.get<any[]>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/dnsstats/arecord`).toPromise();
    return arecord;
  }

  async getNsStats(project, monitor){
    let ns = await this.http.get<any[]>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/dnsstats/ns`).toPromise();
    return ns;
  }

  async getCNameStats(project, monitor){
    let cname = await this.http.get<any[]>(`${environment.apiUrl}/projects/${project}/monitor/${monitor}/dnsstats/cname`).toPromise();
    return cname;
  }

  async getNodeCriteria(body) {
    let n = await this.http.post(`${environment.apiUrl}/v2/nodes/criteria`, body).toPromise();
    return n
  }

  async getScheduledReports() {
    const response = await this.http.get(`${environment.apiUrl}/v2/user/scheduledreports`).toPromise();
    return response;
  }

  async deleteScheduledReport(id) {
    const response = await this.http.delete(`${environment.apiUrl}/v2/user/scheduledreports/${id}`).toPromise();
    return response;
  }

  async createScheduledReport(recipient, project, timeframe, report_type, frequency, next_send_date) {
    const requestBody = {
      recipient,
      recipient_type: "email",
      config: {
        projects: [project],
        report_type,
        timeframe,
        monitor_types: []
      },
      send_frequency: frequency,
      next_send_date: next_send_date
    };
  
    const response = await this.http.post<[string]>(`${environment.apiUrl}/v2/user/scheduledreports`, requestBody).toPromise();
  
    return response;
  }

  async getNotifications(q = '') {
    let url = `${environment.apiUrl}/notifications`;
    if(q!==''){ url=url+'?q='+q }
    try {
      const notifications = await this.http.get(url).toPromise();
      const { count } = await this.http.get<any>(`${environment.apiUrl}/notifications/count`).toPromise();
      this.notificationsSubject.next({ count, notifications});
    } catch (e) {
      console.log({error: e});
    }
  }

  async markSingleAsRead(notificationId) {
    let a = this.http.post(`${environment.apiUrl}/notifications/seen`, [notificationId]).toPromise();
    return a
  }

  async markSingleAsUnread(notificationId) {
    let a = this.http.post(`${environment.apiUrl}/notifications/unseen`, [notificationId]).toPromise();
    return a
  }



  // async markAsUnread(){
  //   let a = this.http.post(`${environment.apiUrl}/notifications/unseen`, undefined).toPromise();
  //   let notifs = this.notificationsSubject.value;
  //   this.notificationsSubject.next(notifs);
  //   return a
  // }

  // async markAsRead(){
  //   let a = this.http.post(`${environment.apiUrl}/notifications/seen`, undefined).toPromise();

  //   let notifs = this.notificationsSubject.value;
  //   notifs.count = 0
  //   this.notificationsSubject.next(notifs);

  //   return a
  // }

  deleteAllNotifications = async () => {
    try {
      this.notificationsSubject.value.notifications = []
      const del = await this.http.delete(`${environment.apiUrl}/notifications/all`).toPromise();
      const notifications = await this.http.get(`${environment.apiUrl}/notifications`).toPromise();
      this.notificationsSubject.next({ count: 0, notifications});
    } catch(e) {
      console.log(e);
    }
  }

  getNotificationSettings(selectedId, selectedMonitor) {
    // console.log(selectedId, selectedMonitor)
    return this.http.get<any[]>(`${environment.apiUrl}/projects/${selectedId}/monitor/${selectedMonitor}/settings/notifications`).toPromise();
  }

  getUserPriceEstimate() {
    try {
      return this.http.get<any[]>(`${environment.apiUrl}/users/priceestimate`).toPromise();
    } catch(e){
      console.log(e);
    }
  }

  search(term) {
    try {
      return this.http.get<any[]>(`${environment.apiUrl}/projects/search/${term}`).toPromise();
    } catch(e){
      console.log(e);
    }
  }




  getPriceEstimate(project){

    try {
      return this.http.get<any[]>(`${environment.apiUrl}/projects/${project}/monitor/estimate?type=0&freq=360000`).toPromise();
    } catch(e){
      console.log(e);
    }
  }
}
