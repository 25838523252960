import { Component, OnInit, TemplateRef, EventEmitter, ViewEncapsulation, HostListener, ChangeDetectionStrategy, ContentChild, OnDestroy } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Project } from '../../_models/project';
import { ModalService } from '../../_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';


@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.pug',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit, OnDestroy {
  single: any[];
  searchText: string;
  multi: any[];
  error;
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  items = ['Australia'];
  autoScale = true;
  view;
  tags;
  projectLoader = [1,2,3,4,5,6]
  projects: any;

  subscriptions = [];

  colorScheme = {
    domain: ['#5D00FF', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  nodeStats: any;
  projectStats;
  optionsSub: any;
  isDark: any;

  constructor(
    private projectService: ProjectService,
    public modal: ModalService,
    private storage: StorageService,

  ) {
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
    // this.projectOverview = this.nodeStats.map(x => x.)
   }

  async projectOverview() {
    this.projectStats = await this.projectService.getProjectOverview();
  }

  ngOnInit() {
    this.projectOverview();
    this.subscriptions = [
      this.projectService.projects.subscribe(projects => {
        this.projects = projects;
      })
    ];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];

  }

  // openModal(template: TemplateRef<any>) {
  //   this.modalRef = this.modalService.show(template);
  // }

}
