import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as shape from 'd3-shape';
// import { scaleLinear, scaleTime, scalePoint } from 'd3-scale';
// import { curveLinear, curveBasis } from 'd3-shape';
import { ModalService } from '../../../_services/modal.service';
import { select } from 'd3';
import { ClockService } from 'src/app/_services/clock.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'monitors-full-view',
  templateUrl: './monitors-full-view.component.pug',
  styleUrls: ['./monitors-full-view.component.scss']
})
export class MonitorsFullViewComponent implements OnInit, OnDestroy {

  @Input() timeframe: any;
  @Input() monDisabled: any;
  @Input() monitors: any;
  @Input() project: any;

  // editModal(type,project,monitor) {
  //   if(type === "http_verbs") {
  //     this.modal.open('verbsmonitor', {project: project, monitor: monitor})
  //   } else if(type === "selenium") {
  //     this.modal.open('seleniummonitor', {project: project, monitor: monitor})
  //   } else {
  //     this.modal.open('standardmonitor', {project: project, monitor: monitor})
  //   }
  // }

  // curve = curveBasis;
  readonly curve: any = shape.curveMonotoneX;
  loading = false;
  view: any[] = [320, 280];

  clock;
  clockSub:any;

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  timeline = true;

  colorScheme = {
    domain: ['#0984E3'] //, '#A10A28', '#C7B42C', '#AAAAAA']
  };
  optionsSub: any;
  isDark: any;

  constructor(
    //public modal: ModalService,
    private clockService: ClockService,
    private storage: StorageService
  ) {
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
   }

  ngOnInit() {
    this.clockSub = this.clockService.clock.subscribe(t => {
      this.clock = t;
    });
  }

  ngOnDestroy() {
    this.clockSub.unsubscribe();
  }

  stop(e) {
    e.stopPropagation();
    e.preventDefault();
  }

}
