import { Component, AfterViewInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AddProjectComponent } from './add-project/add-project.component';
import { ModalService } from '../../_services/modal.service';
import { DeleteProjectComponent } from './delete-project/delete-project.component';
import { AddMonitorComponent } from './add-monitor/add-monitor.component';
import { DeleteMonitorComponent } from './delete-monitor/delete-monitor.component';
import { RemoveMfaComponent } from './remove-mfa/remove-mfa.component';
import { AddWebhookComponent } from './add-webhook/add-webhook.component';
import { PlansComponent } from './plans/plans.component';
import { SeleniumMonitorComponent } from './selenium-monitor/selenium-monitor.component';
import { VerbsMonitorComponent } from './verbs-monitor/verbs-monitor.component';
import { StandardMonitorComponent } from './standard-monitor/standard-monitor.component';
import { PreferenceWizardComponent } from './preference-wizard/preference-wizard.component';
import { EditMonitorNameComponent } from './edit-monitor-name/edit-monitor-name.component';
import { TestDataModalComponent } from './test-data-modal/test-data-modal.component';
import { MapDataComponent } from './map-data/map-data.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AddTeamMemberComponent } from './add-team-member/add-team-member.component';
import { DeleteTeamMemberComponent } from './delete-team-member/delete-team-member.component';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.pug',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements AfterViewInit {
  page: any;
  @ViewChild('vc', { read: ViewContainerRef, static: true}) myRef;

  pages = {
    addproject: AddProjectComponent,
    deleteproject: DeleteProjectComponent,
    deleteaccount: DeleteAccountComponent,
    addmonitor: AddMonitorComponent,
    standardmonitor: StandardMonitorComponent,
    seleniummonitor: SeleniumMonitorComponent,
    verbsmonitor: VerbsMonitorComponent,
    deletemonitor: DeleteMonitorComponent,
    addteammember: AddTeamMemberComponent,
    deleteteammember: DeleteTeamMemberComponent,
    addwebhook: AddWebhookComponent,
    plans: PlansComponent,
    preferencewizard: PreferenceWizardComponent,
    editmonitorname: EditMonitorNameComponent,
    testdatamodal: TestDataModalComponent,
    mapdatamodal: MapDataComponent,
    removemfa: RemoveMfaComponent
  };

  private subscription: Subscription;

  constructor(
    private renderer: Renderer2,
    public modal: ModalService
  ) { }

  ngAfterViewInit() {
    this.subscription = this.modal.getMessage().subscribe(page => {
      this.page = page;
      if(!page){
        this.renderer.removeClass(document.body, 'modal-open');
        this.myRef.clear();
      } else {
        this.renderer.addClass(document.body, 'modal-open');
        const component = this.pages[page];
        if (!component) {
          return;
        }
        const ref = this.myRef.createComponent(component);
      }
    });
  }
}
