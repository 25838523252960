import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { BitpingValidator, MonitorTypes } from "@uptimesv/bitping-common";
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { ModalService } from '../../_services/modal.service';

@Component({
  selector: 'base-add-modal',
  template: ''
})

export class BaseMonitorComponent {
  loading = false;
  loaded = false;
  submitted = false;
  edit = false;
  type: MonitorTypes;
  monitorForm: UntypedFormGroup;
  error;
  items;
  data:any;
  cost;
  getData: Subscription;
  monitor = {};
  projectId:string;
  monitorId:string;

  colour = "#0984e3";
  sec = 1000;
  min = 60*this.sec;
  hour = 60*this.min

  frequency = 1;

  frequencies = [{
    interval: 15*this.sec,
    amount: '15',
    unit: 's',
    milli: 15000
  },
  {
    interval: 30*this.sec,
    label: '30s',
    amount: '30',
    unit: 's',
    milli: 30000,
  },
  {
    interval: 1*this.min,
    label: '1,',
    amount: '1',
    unit: 'm',
    milli:60000,
  },
  {
    interval: 2*this.min,
    label: '2m',
    amount: '2',
    unit: 'm',
    milli: 120000
  },
  {
    interval: 5*this.min,
    label: '5m',
    amount: '5',
    unit: 'm',
    milli: 300000

  },
  {
    interval: 10*this.min,
    label: '10m',
    amount: '10',
    unit: 'm',
    milli: 600000
  },
  {
    interval: 15*this.min,
    label: '15m',
    amount: '15',
    unit: 'm',
    milli: 900000
  },
  {
    interval: 30*this.min,
    label: '30m',
    amount: '30',
    unit: 'm',
    milli: 1800000
  },
  {
    interval: 60*this.min,
    label: '1h',
    amount: '1',
    unit: 'h',
    milli: 3600000
  },
  {
    interval: 360*this.min,
    label: '6h',
    amount: '6',
    unit: 'h',
    milli: 100000000
  },
  {
    interval: 720*this.min,
    label: '12h',
    amount: '12',
    unit: 'h',
    milli: 100000000
  },
  {
    interval: 1440*this.min,
    label: '24h',
    amount: '24',
    unit: 'h',
    milli: 100000000
  },
  {
    interval: 2880*this.min,
    label: '48h',
    amount: '48',
    unit: 'h',
    milli: 100000000
  },

];

  selectFrequency(f){
    this.monitorForm.controls['frequency'].setValue(f);
  }

  hostValidator(f: UntypedFormGroup) {
    //Grab host field value
    const host = f.get('host').value;
    if(!host || !host.length){
      f.controls.host.setErrors({'invalid': true});
    }

    let type = f.get('type').value;
    //Ugly hack to fix the monitortypes enum
    if(type === 'http_verbs'){
      type = "http_get";
    }

    try {
      if(BitpingValidator(type as MonitorTypes, host)===true){
        f.controls.host.setErrors(null);
      } else {
        f.controls.host.setErrors({'regexFailed': true});
      }
    } catch(e) {
      console.log(e);
      f.controls.host.setErrors({'invalidType': true});
    }
  }

  constructor(
    public projectService: ProjectService,
    public modal: ModalService
  ) {

  }

  async priceEstimate(projectId) {
    return await this.projectService.getPriceEstimate(projectId)
  }

  get m() { return this.monitorForm.controls; }

}
