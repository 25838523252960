import { Component, OnInit, OnDestroy } from '@angular/core';
import { async } from '@angular/core/testing';
import { ModalService } from 'src/app/_services/modal.service';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'test-data-modal',
  templateUrl: './test-data-modal.component.pug',
  styleUrls: ['./test-data-modal.component.scss']
})
export class TestDataModalComponent implements OnInit, OnDestroy {
  getData: any;
  data;
  job;
  jobId;
  monitorType: any;
  imageLoading = true;
  screenshot: string;

  constructor(public modal: ModalService, public project: ProjectService) { }

  ngOnInit() {
    this.getData = this.modal.getData().subscribe(data => {
      if (data.job.id !== this.jobId) {
        this.jobId = data.job.id;
        this.job = data.job;
        this.job.status = Number(data.job.status);
        this.monitorType = data.type;
        if (!this.screenshot && this.job?.data?.screenshot && data.projectId && data.monitorId) {
          this.loadScreenshot(this.job.data.screenshot, data.projectId, data.monitorId)
        } else {
          this.imageLoading = false;
        }
      }
    });
    console.log(this.job, this.monitorType)
  }

  loadScreenshot = async (key, project, monitor) => {
    const screenshot = await this.project.getHLSScreenshot(key, project, monitor);
    this.screenshot = screenshot.link;
    // console.log(this.screenshot);
    this.imageLoading = false;
  }

  ngOnDestroy() {
    this.getData.unsubscribe();
  }

}
