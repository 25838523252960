import { Component, Input, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { SidebarService } from "../../_services/sidebar.service";
import * as SvgPanZoom from "svg-pan-zoom";
import { ProjectService } from 'src/app/_services/project.service';
import { logging } from 'protractor';
import { ModalService } from 'src/app/_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'world-map',
  templateUrl: './world-map.component.pug',
  styleUrls: ['./world-map.component.scss']
})

export class WorldMapComponent implements AfterViewInit {

  coords = {
		AF: {
		   lat:34.5328,
		   lon:69.1658
		  },
		AL: {
		   lat:41.33,
		   lon:19.82
		  },
		DZ: {
		   lat:36.7764,
		   lon:3.0586
		  },
		AS: {
		   lat:-14.274,
		   lon:-170.7046
		  },
		AD: {
		   lat:42.5,
		   lon:1.5
		  },
		AO: {
		   lat:-8.8383,
		   lon:13.2344
		  },
		AI: {
		   lat:18.2167,
		   lon:-63.05
		  },
		AG: {
		   lat:17.1211,
		   lon:-61.8447
		  },
		AR: {
		   lat:-34.5997,
		   lon:-58.3819
		  },
		AM: {
		   lat:40.1814,
		   lon:44.5144
		  },
		AW: {
		   lat:12.5186,
		   lon:-70.0358
		  },
		AU: {
		   lat:-35.2931,
		   lon:149.1269
		  },
		AT: {
		   lat:48.2083,
		   lon:16.3725
		  },
		AZ: {
		   lat:40.3667,
		   lon:49.8352
		  },
		BH: {
		   lat:26.225,
		   lon:50.5775
		  },
		BD: {
		   lat:23.7289,
		   lon:90.3944
		  },
		BB: {
		   lat:13.0975,
		   lon:-59.6167
		  },
		BY: {
		   lat:53.9022,
		   lon:27.5618
		  },
		BE: {
		   lat:50.8353,
		   lon:4.3314
		  },
		BZ: {
		   lat:17.25,
		   lon:-88.7675
		  },
		BJ: {
		   lat:6.402,
		   lon:2.518
		  },
		BM: {
		   lat:32.2942,
		   lon:-64.7839
		  },
		BT: {
		   lat:27.4833,
		   lon:89.6333
		  },
		BO: {
		   lat:-16.4942,
		   lon:-68.1475
		  },
		BA: {
		   lat:43.8563,
		   lon:18.4132
		  },
		BW: {
		   lat:-24.6569,
		   lon:25.9086
		  },
		BR: {
		   lat:-15.7939,
		   lon:-47.8828
		  },
		VG: {
		   lat:18.4167,
		   lon:-64.6167
		  },
		BN: {
		   lat:4.9167,
		   lon:114.9167
		  },
		BG: {
		   lat:42.6979,
		   lon:23.3217
		  },
		BF: {
		   lat:12.3686,
		   lon:-1.5275
		  },
		BI: {
		   lat:-3.3825,
		   lon:29.3611
		  },
		CV: {
		   lat:14.9177,
		   lon:-23.5092
		  },
		KH: {
		   lat:11.5696,
		   lon:104.921
		  },
		CM: {
		   lat:3.8578,
		   lon:11.5181
		  },
		CA: {
		   lat:45.4247,
		   lon:-75.695
		  },
		KY: {
		   lat:19.2866,
		   lon:-81.3744
		  },
		CF: {
		   lat:4.3732,
		   lon:18.5628
		  },
		TD: {
		   lat:12.11,
		   lon:15.05
		  },
		CL: {
		   lat:-33.45,
		   lon:-70.6667
		  },
		CN: {
		   lat:39.904,
		   lon:116.4075
		  },
		CX: {
		   lat:-10.4167,
		   lon:105.7167
		  },
		CO: {
		   lat:4.6126,
		   lon:-74.0705
		  },
		KM: {
		   lat:-11.7036,
		   lon:43.2536
		  },
		CG: {
		   lat:-4.2667,
		   lon:15.2833
		  },
		CD: {
		   lat:-4.3317,
		   lon:15.3139
		  },
		CK: {
		   lat:-21.207,
		   lon:-159.771
		  },
		CR: {
		   lat:9.9333,
		   lon:-84.0833
		  },
		CI: {
		   lat:5.3364,
		   lon:-4.0267
		  },
		HR: {
		   lat:45.8131,
		   lon:15.9772
		  },
		CU: {
		   lat:23.1367,
		   lon:-82.3589
		  },
		CW: {
		   lat:12.108,
		   lon:-68.935
		  },
		CY: {
		   lat:35.1725,
		   lon:33.365
		  },
		CZ: {
		   lat:50.0833,
		   lon:14.4167
		  },
		DK: {
		   lat:55.6805,
		   lon:12.5615
		  },
		DJ: {
		   lat:11.595,
		   lon:43.1481
		  },
		DM: {
		   lat:15.3,
		   lon:-61.3833
		  },
		DO: {
		   lat:18.4764,
		   lon:-69.8933
		  },
		EC: {
		   lat:-0.22,
		   lon:-78.5125
		  },
		EG: {
		   lat:30.0444,
		   lon:31.2358
		  },
		SV: {
		   lat:13.6989,
		   lon:-89.1914
		  },
		GQ: {
		   lat:3.7521,
		   lon:8.7737
		  },
		ER: {
		   lat:15.3333,
		   lon:38.9167
		  },
		EE: {
		   lat:59.4372,
		   lon:24.745
		  },
		ET: {
		   lat:9.0272,
		   lon:38.7369
		  },
		FK: {
		   lat:-51.7,
		   lon:-57.85
		  },
		FO: {
		   lat:62,
		   lon:-6.7833
		  },
		FM: {
		   lat:6.9178,
		   lon:158.185
		  },
		FJ: {
		   lat:-18.1333,
		   lon:178.4333
		  },
		FI: {
		   lat:60.1756,
		   lon:24.9342
		  },
		FR: {
		   lat:48.8566,
		   lon:2.3522
		  },
		GF: {
		   lat:4.933,
		   lon:-52.33
		  },
		PF: {
		   lat:-17.5334,
		   lon:-149.5667
		  },
		GA: {
		   lat:0.3901,
		   lon:9.4544
		  },
		XG: {
		   lat:31.5069,
		   lon:34.456
		  },
		GE: {
		   lat:41.7225,
		   lon:44.7925
		  },
		DE: {
		   lat:52.5167,
		   lon:13.3833
		  },
		GH: {
		   lat:5.6037,
		   lon:-0.187
		  },
		GI: {
		   lat:36.1324,
		   lon:-5.3781
		  },
		GR: {
		   lat:37.9842,
		   lon:23.7281
		  },
		GL: {
		   lat:64.175,
		   lon:-51.7333
		  },
		GD: {
		   lat:12.0444,
		   lon:-61.7417
		  },
		GP: {
		   lat:16.0104,
		   lon:-61.7055
		  },
		GU: {
		   lat:13.4745,
		   lon:144.7504
		  },
		GT: {
		   lat:14.6099,
		   lon:-90.5252
		  },
		GN: {
		   lat:9.538,
		   lon:-13.6773
		  },
		GW: {
		   lat:11.8592,
		   lon:-15.5956
		  },
		GY: {
		   lat:6.7833,
		   lon:-58.1667
		  },
		HT: {
		   lat:18.5425,
		   lon:-72.3386
		  },
		HN: {
		   lat:14.0942,
		   lon:-87.2067
		  },
		HK: {
		   lat:22.3069,
		   lon:114.1831
		  },
		HU: {
		   lat:47.4983,
		   lon:19.0408
		  },
		IS: {
		   lat:64.1475,
		   lon:-21.935
		  },
		IN: {
		   lat:28.6139,
		   lon:77.209
		  },
		ID: {
		   lat:-6.2146,
		   lon:106.8451
		  },
		IR: {
		   lat:35.7,
		   lon:51.4167
		  },
		IQ: {
		   lat:33.35,
		   lon:44.4167
		  },
		IE: {
		   lat:53.3497,
		   lon:-6.2603
		  },
		IM: {
		   lat:54.15,
		   lon:-4.4819
		  },
		IL: {
		   lat:31.7833,
		   lon:35.2167
		  },
		IT: {
		   lat:41.8931,
		   lon:12.4828
		  },
		JM: {
		   lat:17.9714,
		   lon:-76.7931
		  },
		JP: {
		   lat:35.6839,
		   lon:139.7744
		  },
		JE: {
		   lat:49.1858,
		   lon:-2.11
		  },
		JO: {
		   lat:31.95,
		   lon:35.9333
		  },
		KZ: {
		   lat:51.1333,
		   lon:71.4333
		  },
		KE: {
		   lat:-1.2864,
		   lon:36.8172
		  },
		KI: {
		   lat:1.3382,
		   lon:173.0176
		  },
		XK: {
		   lat:42.6633,
		   lon:21.1622
		  },
		KW: {
		   lat:29.375,
		   lon:47.98
		  },
		KG: {
		   lat:42.8667,
		   lon:74.5667
		  },
		LA: {
		   lat:17.9667,
		   lon:102.6
		  },
		LV: {
		   lat:56.9475,
		   lon:24.1069
		  },
		LB: {
		   lat:33.8869,
		   lon:35.5131
		  },
		LS: {
		   lat:-29.31,
		   lon:27.48
		  },
		LR: {
		   lat:6.3106,
		   lon:-10.8047
		  },
		LY: {
		   lat:32.8752,
		   lon:13.1875
		  },
		LI: {
		   lat:47.1397,
		   lon:9.5219
		  },
		LT: {
		   lat:54.6833,
		   lon:25.2833
		  },
		LU: {
		   lat:49.6106,
		   lon:6.1328
		  },
		MK: {
		   lat:41.9833,
		   lon:21.4333
		  },
		MG: {
		   lat:-18.9386,
		   lon:47.5214
		  },
		MW: {
		   lat:-13.9833,
		   lon:33.7833
		  },
		MO: {
		   lat:22.1667,
		   lon:113.55
		},
		AX: {
			lat:60.2,
			lon:19.9,
		},
		TW: {
			lat: 23.5,
			lon: 120.96,
		},
		MY: {
		   lat:3.1478,
		   lon:101.6953
		  },
		MV: {
		   lat:4.175,
		   lon:73.5083
		  },
		ML: {
		   lat:12.6458,
		   lon:-7.9922
		  },
		MT: {
		   lat:35.8978,
		   lon:14.5125
		  },
		MH: {
		   lat:7.0918,
		   lon:171.3802
		  },
		MQ: {
		   lat:14.6104,
		   lon:-61.08
		  },
		MR: {
		   lat:18.0858,
		   lon:-15.9785
		  },
		MU: {
		   lat:-20.1667,
		   lon:57.5
		  },
		YT: {
		   lat:-12.7871,
		   lon:45.275
		  },
		MX: {
		   lat:19.4333,
		   lon:-99.1333
		  },
		MD: {
		   lat:47.0228,
		   lon:28.8353
		  },
		MC: {
		   lat:43.7396,
		   lon:7.4069
		  },
		MN: {
		   lat:47.9214,
		   lon:106.9055
		  },
		ME: {
		   lat:42.4397,
		   lon:19.2661
		  },
		MS: {
		   lat:16.7928,
		   lon:-62.2106
		  },
		MA: {
		   lat:34.0253,
		   lon:-6.8361
		  },
		MZ: {
		   lat:-25.9153,
		   lon:32.5764
		  },
		MM: {
		   lat:16.795,
		   lon:96.16
		  },
		NA: {
		   lat:-22.57,
		   lon:17.0836
		  },
		NR: {
		   lat:-0.5477,
		   lon:166.9209
		  },
		NP: {
		   lat:27.7167,
		   lon:85.3667
		  },
		NL: {
		   lat:52.3667,
		   lon:4.8833
		  },
		NC: {
		   lat:-22.2625,
		   lon:166.4443
		  },
		NZ: {
		   lat:-41.2889,
		   lon:174.7772
		  },
		NI: {
		   lat:12.15,
		   lon:-86.2667
		  },
		NE: {
		   lat:13.5086,
		   lon:2.1111
		  },
		NG: {
		   lat:9.0556,
		   lon:7.4914
		  },
		NU: {
		   lat:-19.056,
		   lon:-169.921
		  },
		NF: {
		   lat:-29.0569,
		   lon:167.9617
		  },
		KP: {
		   lat:39.03,
		   lon:125.73
		  },
		MP: {
		   lat:15.2137,
		   lon:145.7546
		  },
		NO: {
		   lat:59.9111,
		   lon:10.7528
		  },
		OM: {
		   lat:23.6139,
		   lon:58.5922
		  },
		PK: {
		   lat:33.6989,
		   lon:73.0369
		  },
		PW: {
		   lat:7.5006,
		   lon:134.6242
		  },
		PA: {
		   lat:9,
		   lon:-79.5
		  },
		PG: {
		   lat:-9.4789,
		   lon:147.1494
		  },
		PY: {
		   lat:-25.3,
		   lon:-57.6333
		  },
		PE: {
		   lat:-12.06,
		   lon:-77.0375
		  },
		PH: {
		   lat:14.6,
		   lon:120.9833
		  },
		PN: {
		   lat:-25.0667,
		   lon:-130.0833
		  },
		PL: {
		   lat:52.23,
		   lon:21.0111
		  },
		PT: {
		   lat:38.708,
		   lon:-9.139
		  },
		PR: {
		   lat:18.4037,
		   lon:-66.0636
		  },
		QA: {
		   lat:25.3,
		   lon:51.5333
		  },
		RE: {
		   lat:-20.8789,
		   lon:55.4481
		  },
		RO: {
		   lat:44.4,
		   lon:26.0833
		  },
		RU: {
		   lat:55.7558,
		   lon:37.6178
		  },
		RW: {
		   lat:-1.9536,
		   lon:30.0606
		  },
		BL: {
		   lat:17.8958,
		   lon:-62.8508
		  },
		SH: {
		   lat:-15.9251,
		   lon:-5.7179
		  },
		KN: {
		   lat:17.2983,
		   lon:-62.7342
		  },
		LC: {
		   lat:14.0167,
		   lon:-60.9833
		  },
		MF: {
		   lat:18.0706,
		   lon:-63.0847
		  },
		PM: {
		   lat:46.7811,
		   lon:-56.1764
		  },
		VC: {
		   lat:13.1667,
		   lon:-61.2333
		  },
		WS: {
		   lat:-13.8333,
		   lon:-171.8333
		  },
		SM: {
		   lat:43.932,
		   lon:12.4484
		  },
		ST: {
		   lat:0.3333,
		   lon:6.7333
		  },
		SA: {
		   lat:24.65,
		   lon:46.71
		  },
		SN: {
		   lat:14.7319,
		   lon:-17.4572
		  },
		RS: {
		   lat:44.8167,
		   lon:20.4667
		  },
		SC: {
		   lat:-4.6236,
		   lon:55.4544
		  },
		SL: {
		   lat:8.4833,
		   lon:-13.2331
		  },
		SG: {
		   lat:1.3,
		   lon:103.8
		  },
		SX: {
		   lat:18.0256,
		   lon:-63.0492
		  },
		SK: {
		   lat:48.1447,
		   lon:17.1128
		  },
		SI: {
		   lat:46.05,
		   lon:14.5167
		  },
		SB: {
		   lat:-9.4333,
		   lon:159.95
		  },
		SO: {
		   lat:2.0408,
		   lon:45.3425
		  },
		ZA: {
		   lat:-25.7464,
		   lon:28.1881
		  },
		GS: {
		   lat:-54.2833,
		   lon:-36.5
		  },
		KR: {
		   lat:37.56,
		   lon:126.99
		  },
		SS: {
		   lat:4.85,
		   lon:31.6
		  },
		ES: {
		   lat:40.4167,
		   lon:-3.7167
		  },
		LK: {
		   lat:6.9167,
		   lon:79.8333
		  },
		SD: {
		   lat:15.6031,
		   lon:32.5265
		  },
		SR: {
		   lat:5.8667,
		   lon:-55.1667
		  },
		XR: {
		   lat:78.2167,
		   lon:15.6333
		  },
		SZ: {
		   lat:-26.4465,
		   lon:31.2064
		  },
		SE: {
		   lat:59.3294,
		   lon:18.0686
		  },
		CH: {
		   lat:46.948,
		   lon:7.4474
		  },
		SY: {
		   lat:33.5131,
		   lon:36.2919
		  },
		TJ: {
		   lat:38.5731,
		   lon:68.7864
		  },
		TZ: {
		   lat:-6.8,
		   lon:39.2833
		  },
		TH: {
		   lat:13.75,
		   lon:100.5167
		  },
		BS: {
		   lat:25.0667,
		   lon:-77.3333
		  },
		GM: {
		   lat:13.4531,
		   lon:-16.5775
		  },
		TL: {
		   lat:-8.5536,
		   lon:125.5783
		  },
		TG: {
		   lat:6.1319,
		   lon:1.2228
		  },
		TO: {
		   lat:-21.1347,
		   lon:-175.2083
		  },
		TT: {
		   lat:10.6667,
		   lon:-61.5167
		  },
		TN: {
		   lat:36.8008,
		   lon:10.18
		  },
		TR: {
		   lat:39.93,
		   lon:32.85
		  },
		TM: {
		   lat:37.95,
		   lon:58.3833
		  },
		TC: {
		   lat:21.4664,
		   lon:-71.136
		  },
		TV: {
		   lat:-8.5243,
		   lon:179.1942
		  },
		VI: {
		   lat:18.3419,
		   lon:-64.9332
		  },
		UG: {
		   lat:0.3136,
		   lon:32.5811
		  },
		UA: {
		   lat:50.45,
		   lon:30.5236
		  },
		AE: {
		   lat:24.4511,
		   lon:54.3969
		  },
		GB: {
		   lat:51.5072,
		   lon:-0.1275
		  },
		US: {
		   lat:38.9047,
		   lon:-77.0163
		  },
		UY: {
		   lat:-34.8667,
		   lon:-56.1667
		  },
		UZ: {
		   lat:41.3,
		   lon:69.2667
		  },
		VU: {
		   lat:-17.7333,
		   lon:168.3167
		  },
		VA: {
		   lat:41.9033,
		   lon:12.4534
		  },
		VE: {
		   lat:10.5,
		   lon:-66.9333
		  },
		VN: {
		   lat:21.0245,
		   lon:105.8412
		  },
		WF: {
		   lat:-13.2825,
		   lon:-176.1736
		  },
		XW: {
		   lat:31.7764,
		   lon:35.2269
		  },
		YE: {
		   lat:15.35,
		   lon:44.2
		  },
		ZM: {
		   lat:-15.4167,
		   lon:28.2833
		  },
		ZW: {
		   lat:-17.8292,
		   lon:31.0522
		  }
  }
  

  @ViewChild('map') map: ElementRef;

  @Input() data: any;

  points;
  hover;

  sidebarSub;
	isDark: any;
	optionsSub: any;
	width: any;
	height: any;

	async getLatLong(event) {

		this.width = (this.map.nativeElement as HTMLImageElement).width
		this.height = (this.map.nativeElement as HTMLImageElement).height

		const dimensions = {
			width: this.width,
			height: this.height,
		}

		this.points = this.data.map(data => {
			const lat = this.coords[data.countryCode]?.lat;
			const lon = this.coords[data.countryCode]?.lon;
			const x = (lon + 180) * (dimensions.width / 360);
			const y = (((lat * -1) + 90) * (dimensions.height / 180))

			return {
				countryCode: data.countryCode,
				percent: data.uptime,
				latitude: y - 5,
				longitude: x - 5,
			}
		})
	}

	ngAfterViewInit() {
		this.getLatLong(event);
	  }

		

  handleZoomEvent($event) {
	console.log($event)
}

  setHover(point) {
    this.hover = point;
  }

  unsetHover(point) {
    if(this.hover === point){
      this.hover = null;
    }
  }

  constructor(public sidebar:SidebarService, public modal: ModalService, private storage: StorageService) {
	this.optionsSub = this.storage.options.subscribe(options => {
        this.isDark = options.darkToggleState;
    });
  }
}
