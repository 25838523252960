/*

UptimeSV has 5 build configurations:

`ng build` or `ng serve` creates an dev build using api.uptimesv.com
`ng build --prod` or `ng serve --prod` creates a production build using api.uptimesv.com
`ng build -c local` or `ng serve -c local` creates a dev build using localhost:1331
`ng build -c devstaging` or `ng serve -c devstaging` creates a dev build using api.staging.uptimesv.com
`ng build -c staging` or `ng serve -c staging` creates a production build using api.staging.uptimesv.com

*/

export const environment = {
  production: true,
  environment: 'prod',
  apiUrl: 'https://app.bitping.com/api',
  stripePublishKey:
    'pk_live_51MVmnIHg8YZAaWp4QcDC6ZgYQcFK4YaqMkOsp9TYKS01EgRHHfRgvFb6Qzbfs2ESjASlDSsDbWR8ciHmccAZQF2H00u7SlNQ26',
  isUnderMaintenance: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
