import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable} from 'rxjs';
import { AddMfaResponse } from '../_models/user';
import { AuthenticationService } from './auth.service';
import { TeamMember, TeamSSOTypesEnum } from '@uptimesv/bitping-common';


@Injectable({ providedIn: 'root' })
export class UserService {
  public notificationSettingSubject: BehaviorSubject<any>;
  public notificationSettings: Observable<any>;


  constructor(private http: HttpClient) {
    this.notificationSettingSubject = new BehaviorSubject<any>([]);
    this.notificationSettings = this.notificationSettingSubject.asObservable();
  }

  changePassword(passwordOld, passwordNew, passwordConfirm) {
    return this.http.put<any>(`${environment.apiUrl}/users/password`, {passwordOld, passwordNew, passwordConfirm}).toPromise();
  }

  changeName(name) {
    return this.http.put<any>(`${environment.apiUrl}/users/name`, {name}).toPromise();
  }

  changePayout(handle) {
    return this.http.put<any>(`${environment.apiUrl}/users/handle`, {handle}).toPromise();
  }

  changeEmail(email){
    return this.http.put<any>(`${environment.apiUrl}/users/email`, {email}).toPromise();
  }

  me() {
    return this.http.get<any[]>(`${environment.apiUrl}/v2/user/me`).toPromise();
  }

  getBilling(){
    return this.http.get(`${environment.apiUrl}/billing`).toPromise();
  }

  userReferrals(){
    return this.http.get(`${environment.apiUrl}/users/referrals`).toPromise();
  }

  getBillingPlans(){
    return this.http.get(`${environment.apiUrl}/billing/plans`).toPromise();
  }

  getSubscription(){
    return this.http.get<any[]>(`${environment.apiUrl}/users/subscription`).toPromise();
  }

  getSubscriptionLimits(){
    return this.http.get(`${environment.apiUrl}/users/subscription/limits`).toPromise();
  }

  getSubscriptionHistory(){
    return this.http.get(`${environment.apiUrl}/users/subscription/history`).toPromise();
  }

  getStripeCheckoutID(planName: string) {
    return this.http.post<any>(`${environment.apiUrl}/billing/stripe/checkout`, {plan: planName}).toPromise();
  }

  getStripeManage() {
    return this.http.get<any>(`${environment.apiUrl}/billing/stripe/customer`).toPromise();
  }

  getStripeUpdateBilling(object) {
    return this.http.post<any>(`${environment.apiUrl}/billing/stripe/billinginfo`, object).toPromise();
  }

  testNotificationWebhook(object){
    return this.http.post(`${environment.apiUrl}/users/settings/notifications/test`, object).toPromise();
  }

  async getNotificationSettings() {
    let p:any = await this.http.get<any>(`${environment.apiUrl}/v2/user/settings/notifications`).toPromise();
    return p;
  }

  async createNewNotificationWebHook(object){
    const n = await this.http.post(`${environment.apiUrl}/users/settings/notifications`, object).toPromise();
    return n;
  }

  async updateNewNotificationWebHook(id, object){
    const n = await this.http.put(`${environment.apiUrl}/users/settings/notifications/${id}`, object).toPromise();
    return n;
  }

  async deleteNotificationWebHook(id){
    const del = await this.http.delete(`${environment.apiUrl}/users/settings/notifications/${id}`).toPromise();
    return del;
  }

  async delete(mfa?: string) {
    await this.http.delete(
      `${environment.apiUrl}/v2/user`, mfa && {
        headers: {
          "x-mfa-token": mfa
        }
      }
    ).toPromise();
  }

  teams() {
    return this.http.get<TeamMember[]>(`${environment.apiUrl}/v2/teams`).toPromise();
  }

  addTeamMember(team_member: TeamMember) {
    return this.http.post(`${environment.apiUrl}/v2/teams`, team_member).toPromise();
  }

  removeTeamMember(sso_id: string, sso_type: TeamSSOTypesEnum) {
    return this.http.delete(`${environment.apiUrl}/v2/teams?sso_id=${sso_id}&sso_type=${sso_type.toString()}`).toPromise();
  }

  // PUT /user/mfa
  // GET /user/mfa
  // DELETE /user/mfa

  // TODO: We should rewrite all our stuff this way tbh
  async addMfa(): Promise<AddMfaResponse> {
    return this.http.get<AddMfaResponse>(`${environment.apiUrl}/v2/user/mfa`).toPromise()
  }

  async confirmMfa(code: string): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/v2/user/mfa`, {}, { headers: { "x-mfa-code": code }}).toPromise()
  }

  async removeMfa(code: string): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/v2/user/mfa`, { headers: { "x-mfa-code": code }}).toPromise()
  }


}
