import { Component, OnInit, OnDestroy } from '@angular/core';
import { NodesService } from 'src/app/_services/nodes.service';
import { UserService } from 'src/app/_services/user.service';
import { ProjectService } from 'src/app/_services/project.service';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { User } from '../../_models/user';
import { ClockService } from 'src/app/_services/clock.service';
import { SSEObserverService } from 'src/app/_services/sseobserver.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.pug',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  
  nodeInterval;
  nodes;
  nodeList: any[] = []
  nodeData;
  outdatedNodes;
  nodeLoading = true;
  showLabels: boolean = false;
  notifications;
  nodelist;
  nodelistmap;

  count = {
    nodes: 0,
    countries: 0
  };
  data;
  spendChart;
  countries;
  now;
  nextBilling;
  timeElapsed;
  nodeStats = {
    up: 0,
    warning: 0,
    down: 0
  };
  currentUser: User;
  plan:any;
  planName:string;
  overview;
  planLimits;
  nodeCoordinates;
  customer;
  subscriptions = [];
  notiLoader = [1,2,3,4,5,6];
  view: any[] = [100, 100];
  colorScheme = {
    domain: ['#2563eb']
  };
  optionsSub: any;
  isDark: any;
  projectsLength: any;
  userRole: any;

  constructor(public nodesservice:NodesService, private storage: StorageService, public modal: ModalService, private projects: ProjectService, public auth: AuthenticationService, private user: UserService, private clock: ClockService, private sseObserver: SSEObserverService) {
    this.subscriptions = [
      this.auth.currentUser.subscribe(x => this.currentUser = x),
      this.projects.notifications.subscribe(noti => {
        this.notifications = noti.notifications;
      }),
      this.projects.overview.subscribe(overview => {
        this.nodeStats = overview;
        this.overview = overview;
      }),
      this.clock.clock.subscribe(n => {
        this.now = n;
      }),
      this.optionsSub = this.storage.options.subscribe(options => {
        this.isDark = options.darkToggleState;
      })
    ];
  }

  ngOnInit() {
    this.nodeInterval = setInterval(() => {
      if(this.nextBilling){
        this.timeElapsed = this.planTimeElapsed(this.nextBilling);
        if(this.timeElapsed>100){
          this.planDetails();
        }
      }
    }, 5000);
    this.getSubscription();
    this.subscriptions = [
      this.projects.projects.subscribe(projects => {
        this.projectsLength = projects.length;
      })
    ];
  }
  ngOnDestroy() {
    clearInterval(this.nodeInterval);
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

//  getBillingInfo() {
//    const billingData = this.userService.getBillingPlans();
//    console.log(billingData)
//  }

async getSubscription() {
  const subscription: any = await this.user.getSubscription();
  const subscriptionLimits: any = await this.user.getSubscriptionLimits();
  this.planLimits = subscriptionLimits;
  this.plan = subscription.plan;
  this.planName = subscription.plan.name;
  this.userRole = this.currentUser.role;
  if(this.plan.id!==0){
    await this.planDetails();
  }
}

async planDetails() {
  if(this.plan.id!==0){
    const session = await this.user.getStripeManage();
    this.customer = session;
    this.nextBilling = session.subscriptionInfo.subscription.nextBillingDate || null;
    this.planTimeElapsed(this.nextBilling);
  }
}

planTimeElapsed(t) {
  const now = new Date().getTime();
  const nextBillingDate = new Date(t);
  const lastBillingDate = new Date(t);
  lastBillingDate.setMonth(lastBillingDate.getMonth() - 1);
  const max = nextBillingDate.getTime() - lastBillingDate.getTime();
  const dt = now - lastBillingDate.getTime();
  return dt / max * 100;
}


getNodes() {
  this.sseObserver.getServerSentEvent('https://api.bitping.com/explorer/sse/nodes').subscribe(event => {
    const n = JSON.parse(event.data);
    let map:any = {};
    let coords = [];
    n.forEach(n1 => {
      if(map[n1.countryCode]){
        map[n1.countryCode]++;
      } else {
        map[n1.countryCode] = 1;
      }
      coords.push({lon: n1.lon, lat: n1.lat});
    });
    this.nodeCoordinates = coords;
    this.nodelistmap = Object.keys(map)
    .map((k) => {
      return { countrycode: k, count: Number(map[k]) }
    })
    .sort((a,b) => (Number(a.count) < Number(b.count)) ? 1 : -1);
    this.data = map;
    this.count.nodes = this.nodelistmap.map(n1 => n1.count).reduce((a,b) => { return a+b; });
    this.count.countries = this.nodelistmap.length;
  });

}
}