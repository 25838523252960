
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class StorageService {

  //Stored language preference
  private optionsSubject: BehaviorSubject<any>;
  public options: Observable<any>;

  constructor() {
    const o = localStorage.getItem('usv_options') || "{}";
    this.optionsSubject = new BehaviorSubject<String>(JSON.parse(o));
    this.options = this.optionsSubject.asObservable();
  }

  public get optionsValue(): any {
    return this.optionsSubject.value;
  }

  updateOptions(u) {
    let t = this.optionsSubject.value;
    let s = Object.assign(t, u);
    localStorage.setItem('usv_options', JSON.stringify(s));
    this.optionsSubject.next(s);
  }
}
