
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
// import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private router:Router, private http: HttpClient, private storage: StorageService) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    setTimeout(() => {
      if(this.currentUserValue){
        this.refresh();
      }
    }, 1000);
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  async refresh() {
    const u = await this.http.get(`${environment.apiUrl}/v2/user/auth/token/refresh`).toPromise();
    // console.log("Refresh:", u);
    if(u){
      this.updateUser(u);
    }
  }

  updateUser(u) {
    localStorage.setItem('currentUser', JSON.stringify(u));
    this.currentUserSubject.next(u);
  }

  forgot(email: string, code?: string){
    return this.http.post(`${environment.apiUrl}/v2/user/auth/forgot`, { email }, code && {
      headers: {
        "x-mfa-code": code
      }
    }).toPromise();
  }


  reset(id: string, email: string, password: string, confirm: string){
    return this.http.post(`${environment.apiUrl}/v2/user/auth/reset/${id}`, { email, password, confirm }).toPromise();
  }

  login(email: string, password: string) {
    return this.http.post(`${environment.apiUrl}/v2/user/auth/login`, { email, password }).toPromise();
  }

  login_mfa(email: string, password: string, otp: string) {
    return this.http.post(`${environment.apiUrl}/v2/user/auth/login`, { email, password }, {
      headers: {
        "x-mfa-code": otp
      }
    }).toPromise();
  }

  register(name: string, email: string, password: string, turnstile_token: string, referral: string = null) {
    let userObject:any = {
      name, email, turnstile_token, password
    };
    if(referral!==null){
      userObject.referral = referral;
    }
    return this.http.post(`${environment.apiUrl}/v2/user/auth/register`, userObject)
    .pipe(map(user => {
        // login successful if there's a jwt token in the response
        console.log(user);
        if (user) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));
            this.currentUserSubject.next(user);
        }
        return user;
    }));
  }

  addUser(name: string, email: string, type: string, usdBalance: number, password: string) {
    return this.http.post(`${environment.apiUrl}/users/register`, { name, email, type, usdBalance, password })
    .pipe(map(user => {
        // login successful if there's a jwt token in the response
        // console.log(user);
        return user;
    }));
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('usv_options');
    // localStorage.clear();
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }
}
