import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { StorageService } from 'src/app/_services/storage.service';



@Component({
  selector: 'languages',
  templateUrl: './language-selector.component.pug',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  languages = [
    {
      id: 'en',
      flag: 'gb',
      name: 'English'
    },
    {
      id: 'ja',
      flag: 'jp',
      name: '日本語'
    },
    {
      id: 'ko',
      flag: 'kr',
      name: '한국어'
    },
    {
      id: 'zh-CN',
      flag: 'cn',
      name: '簡體中文'
    },
    {
      id: 'zh-HK',
      flag: 'hk',
      name: '繁體中文'
    }
  ];
  optionsSub;
  selectedLanguage;
  darkToggleState;

  constructor(
    private translate: TranslateService,
    public storage: StorageService
    ) {
      translate.setDefaultLang('en');
      this.optionsSub = this.storage.options.subscribe(options => {
        if(options.selectedLanguage){
          // console.log(options.selectedLanguage);
          this.selectedLanguage = options.selectedLanguage;
        }else {
          const l = this.translate.getBrowserLang();
          const lang = this.languages.map(function(e) { return e.id; }).indexOf(l);
          this.selectedLanguage = lang>-1 ? this.languages[lang] : this.languages[0];
          this.updateSelectedLanguage(this.selectedLanguage);
        }
        translate.use(this.selectedLanguage.id);
  
        this.darkToggleState = options.darkToggleState;
      });
    }

  
  updateSelectedLanguage(l) {
    this.storage.updateOptions({selectedLanguage: l});
  }

  useLanguage(i) {
    this.updateSelectedLanguage(this.languages[i]);
  }

  ngOnInit() {
  }

}
