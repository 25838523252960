import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EarningsService } from 'src/app/_services/earnings.service';
import { AuthenticationService } from 'src/app/_services/auth.service';
import { NodesService } from 'src/app/_services/nodes.service';
import { UserService } from 'src/app/_services/user.service';
import { Observable, throwError } from 'rxjs';

@Component({
  selector: 'app-earnings',
  templateUrl: './earnings.component.pug',
  styleUrls: ['./earnings.component.scss']
})

export class EarningsComponent implements OnInit, OnDestroy {
  //Cashout limits
  miningFee = 120;
  minimumCashout = 2000;
  selectedCoin;
  walletDropdown = false;
  handleForm: UntypedFormGroup;

  currentEarnings: any = { usdEarnings: 0, satoshiEarnings: 0 };
  jobCount: any = {total: 0};
  earnings: any = {satoshiTotal: 0, usdTotal: 0};
  payouts: any = [];
  referrals = 0;
  earningsSub: any;
  payoutsLoaded = false;
  requestingPayout = false;
  submitted = false;
  payoutError = '';
  payoutMessage = '';
  currentUser;
  outdatedNodes;
  nodeList;
  userCurrency;
  setCoin;

  earningsInterval;
  payoutLoader = [1,2,3,4];

  regexes = [
    {
      type: "HandCash",
      regex: /^[$][\a-zA-Z0-9\-_.]{4,50}$/
    },
    {
      type: "P2PKH",
      regex: /^[1][a-km-zA-HJ-NP-Z1-9]{25,34}$/
    },
    {
      type: "Relay",
      regex: /^[1][\a-zA-Z0-9]*$/
    },
    {
      type: "PayMail",
      regex: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    },
    {
      type: "Bitcoin Cash",
      regex: /(bitcoincash:)?(q|p)[a-z0-9]{41}/
    },
  ]

  currencies = [
    {
      name: "Bitcoin SV",
      logo: "bsv",
      payout_currency: "BSV",
    },
    {
      name: "Bitcoin Cash",
      logo: "bch",
      payout_currency: "BCH",
    },
    {
      name: "Solana",
      logo: "sol",
      payout_currency: "SOL"
    }
  ]; 

  constructor(
    private earningsService: EarningsService,
    public auth:AuthenticationService,
    public nodesservice:NodesService,
    private user:UserService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  async getUserPreferences() {
    const me: any = await this.user.me();
    console.log(me)
    this.userCurrency = me;
  }

  async ngOnInit() {
    this.earningsSub = this.auth.currentUser.subscribe(x => this.currentUser = x);
    await this.getUserPreferences();
    if(this.userCurrency.handle_currency) {
      this.setCoin = this.currencies.findIndex(p => p.payout_currency === this.userCurrency.handle_currency);
      this.selectedCoin = this.currencies[this.setCoin]
    } else {
      this.setCoin  = null;
      this.selectedCoin = null;
    }
    this.getEarningStats();
    this.earningsInterval = setInterval(()=>{
      this.getEarningStats();
    }, 10000);
    this.auth.currentUser.subscribe(x => this.currentUser = x);
    this.getUserReferrals();
    console.log(this.selectedCoin)
    // console.log(this.currentUser);
    this.handleForm = this.formBuilder.group({
      handle: [this.userCurrency.handle || '', Validators.required],
    });
  }

  ngOnDestroy() {
    this.earningsSub.unsubscribe();
    clearInterval(this.earningsInterval);
  }

  updateCurrency(currency) {
    this.selectedCoin = currency;
  }

  // validateAddress(){

  //     switch(this.selectedCoin["logo"].toLowerCase()){
  //       case "bsv":
  //         return this.m.handle.value.match(this.regexes[1]["regex"])
  //         break;
  //       case "bch":
  //         return this.m.handle.value.match(this.regexes[4]["regex"]) || this.m.handle.value.match(this.regexes[1]["regex"]);
  //         break;
  //       case "sol":
  //         return this.m.handle.value.match(this.regexes[1]["regex"]);
  //         break;
  //     }
  // }

  async myNodes() {
    let nodelist = await this.nodesservice.getNodes();
    this.nodeList = nodelist.results;
    this.outdatedNodes = this.nodeList.filter(n => /^0\.[1-8]\./.test(n.nodeinfo.version)).length;
  }

  async getUserReferrals() {
    try {
      const referrals:any = await this.user.userReferrals();
      this.referrals = referrals && referrals.length || 0;
    } catch(e) {
      console.log(e);
      this.referrals = 0;
    }
  }


  async getEarningStats(){
    let earnings = await this.earningsService.getEarningsTotal();
    let currentEarnings = await this.earningsService.currentEarnings();
    let jobCount = await this.earningsService.getJobCount();
    let payouts = await this.earningsService.getPayouts();
    this.myNodes();
    this.earnings = earnings;
    this.currentEarnings = currentEarnings;
    this.jobCount = jobCount;
    this.payouts = payouts;
    this.payoutsLoaded = true;
  }

  testForm() {
    console.log(this.selectedCoin.payout_currency, this.m.handle.value)
  }

  async requestPayout(){
    this.submitted = true;
    if(!this.requestingPayout){
    // if(!this.requestingPayout && this.validateAddress()){
      try {
        this.requestingPayout = true;
        let newPayout = await this.earningsService.requestPayout(this.selectedCoin.payout_currency, this.m.handle.value);

        this.payoutMessage = 'PAYOUT_PROCESSING';
        setTimeout(() => {
          this.requestingPayout = false;
          this.clearMessages();
          this.getEarningStats();
        },5000);
      } catch(e) {
        if(e.response) {}
        console.log(e.response);
        this.requestingPayout = false;
        this.payoutError = e.error.message;
        this.clearMessages();

      }
    }
  }

  clearMessages() {
    setTimeout(() => {
      this.payoutError = '';
      this.payoutMessage = '';
    }, 5000);
  }

  get m() { return this.handleForm.controls; }
}

