import { trigger, transition, style, query, animate, animateChild, group } from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  	transition('* <=> *', [
  		style({ position: 'relative' }),
  	 	query(':enter, :leave', [
  	 		style({
  	 			position: 'absolute',
   			top: 0,
   			left: 0,
   			width: '100%'
  	 		})
       ],
       {optional: true }),
  	query(':enter', [style({ opacity: '0', transform: 'translate3d(0,50px,0)' })], {optional: true }),
  	 	query(':leave', animateChild(), {optional: true }),
   	group([
  	 		query(':leave', [animate('200ms ease-out', style({ opacity:'0', transform: 'translate3d(0,0px,0)' }))], {optional: true }),
  	 		query(':enter', [animate('300ms ease-out', style({ opacity:'1', transform: 'translate3d(0,0px,0)' }))], {optional: true })
  	 	]),
  	 	query(':enter', animateChild(), {optional: true })
   ])
  ]);
