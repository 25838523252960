import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';
import { ModalService } from '../../../_services/modal.service';
import { Router } from '@angular/router';
import { BaseMonitorComponent } from '../base-monitor.component';
import { MonitorTypes } from '@uptimesv/bitping-common';
import { UserService } from 'src/app/_services/user.service';
import { PlanInfoService } from 'src/app/_services/planInfo.service';

@Component({
  selector: 'verbs-monitor',
  templateUrl: './verbs-monitor.component.pug',
  styleUrls: ['./verbs-monitor.component.scss']
})
export class VerbsMonitorComponent extends BaseMonitorComponent implements OnInit, OnDestroy {
  monitorType=1;
  locations:any;
  headerError = false;
  headerSubmitted = false;
  verbsStep = 1;
  verbsStepSubmit = false;
  verbsConfigure = 1;
  nodeCount: any;
  secureJob: boolean;
  selectedProxyFilter;
  secureJobToolTip = false;
  isSecure: boolean;

  dropdownOpen = {
    time: false,
    options: false,
  };

  //Headers
  headerForm = {
    key: '',
    value: ''
  };

  headers = {};
  body;
  regex;
  code = 200;

  //HTTP Verb list
  verbs = [
    {
      display: 'GET',
      httpvalue: 'http_get',
    },
    {
      display: 'POST',
      httpvalue: 'http_post',
    },
    {
      display: 'PUT',
      httpvalue: 'http_put',
    },
    {
      display: 'HEAD',
      httpvalue: 'http_head',
    },
    {
      display: 'OPTIONS',
      httpvalue: 'http_options',
    },
    {
      display: 'DELETE',
      httpvalue: 'http_delete',
    },
    {
      display: 'PATCH',
      httpvalue: 'http_patch',
    },
  ];

  //HTTP Status codes list
  statusCodes = [

    {
      display: '100 Continue',
      value: 100
    },
    {
      display: '101 Switching Protocols',
      value: 101
    },
    {
      display: '102 Processing',
      value: 102
    },
    {
      display: '103 Checkpoint',
      value: 103
    },
    {
      display: '200 OK',
      value: 200
    },
    {
      display: '201 Created',
      value: 201
    },
    {
      display: '202 Accepted',
      value: 202
    },
    {
      display: '203 Non-Authoritative Information',
      value: 203
    },
    {
      display: '204 No Content',
      value: 204
    },
    {
      display: '205 Reset Content',
      value: 205
    },
    {
      display: '206 Partial Content',
      value: 206
    },
    {
      display: '207 Multi-Status',
      value: 207
    },
    {
      display: '300 Multiple Choices',
      value: 300
    },
    {
      display: '301 Moved Permanently',
      value: 301
    },
    {
      display: '302 Found',
      value: 302
    },
    {
      display: '303 See Other',
      value: 303
    },
    {
      display: '304 Not Modified',
      value: 304
    },
    {
      display: '305 Use Proxy',
      value: 305
    },
    {
      display: '306 Switch Proxy',
      value: 306
    },
    {
      display: '307 Temporary Redirect',
      value: 307
    },
    {
      display: '400 Bad Request',
      value: 400
    },
    {
      display: '401 Unauthorized',
      value: 401
    },
    {
      display: '402 Payment Required',
      value: 402
    },
    {
      display: '403 Forbidden',
      value: 403
    },
    {
      display: '404 Not Found',
      value: 404
    },
    {
      display: '405 Method Not Allowed',
      value: 405
    },
    {
      display: '406 Not Acceptable',
      value: 406
    },
    {
      display: '407 Proxy Authentication Required',
      value: 407
    },
    {
      display: '408 Request Timeout',
      value: 408
    },
    {
      display: '409 Conflict',
      value: 409
    },
    {
      display: '410 Gone',
      value: 410
    },
    {
      display: '411 Length Required',
      value: 411
    },
    {
      display: '412 Precondition Failed',
      value: 412
    },
    {
      display: '413 Request Entity Too Large',
      value: 413
    },
    {
      display: '414 Request-URI Too Long',
      value: 414
    },
    {
      display: '415 Unsupported Media Type',
      value: 415
    },
    {
      display: '416 Requested Range Not Satisfiable',
      value: 416
    },
    {
      display: '417 Expectation Failed',
      value: 417
    },
    {
      display: '418 Im a teapot',
      value: 418
    },
    {
      display: '422 Unprocessable Entity',
      value: 422
    },
    {
      display: '423 Locked',
      value: 423
    },
    {
      display: '424 Failed Dependency',
      value: 424
    },
    {
      display: '425 Unordered Collection',
      value: 425
    },
    {
      display: '426 Upgrade Required',
      value: 426
    },
    {
      display: '449 Retry With',
      value: 449
    },
    {
      display: '450 Blocked by Windows Parental Controls',
      value: 450
    },
    {
      display: '500 Internal Server Error',
      value: 500
    },
    {
      display: '501 Not Implemented',
      value: 501
    },
    {
      display: '502 Bad Gateway',
      value: 502
    },
    {
      display: '503 Service Unavailable',
      value: 503
    },
    {
      display: '504 Gateway Timeout',
      value: 504
    },
    {
      display: '505 HTTP Version Not Supported',
      value: 505
    },
    {
      display: '506 Variant Also Negotiates',
      value: 506
    },
    {
      display: '507 Insufficient Storage',
      value: 507
    },
    {
      display: '509 Bandwidth Limit Exceeded',
      value: 509
    },
    {
      display: '510 Not Extended',
      value: 510
    },
  ];
  dialogRef: any;
  ready: boolean;
  selectedLocations: [];
  criteria;
  selectedBandwidth: any;
  currentUser;

  selectMonitorType(t){
    this.type = this.verbs[t-1].httpvalue as MonitorTypes;
    this.monitorType = t;
  }

  getNodeCount($event) {
    this.nodeCount = $event;
  }

  onVerbsStepNext(){
    this.verbsStepSubmit = true;
    if(this.monitorForm.invalid){
      return;
    }else{
      this.verbsStep = 2;
    }
  }

  async getNodeCriteriaData(locations) {
    this.projectService.getNodeCriteria(this.criteria)
    const response : any = await this.projectService.getNodeCriteria(this.criteria)

    this.nodeCount = response.count;
    console.log(response.count)
  }

  constructor(
    public projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public modal: ModalService,
    private user: UserService,
    private planInfo: PlanInfoService,
  ) {
    super(projectService, modal);
  }

  async getCurrentUser() {
    this.planInfo.getUserInfo().subscribe((userInfo) => {
      this.currentUser = userInfo;
    });
    this.frequencies = this.frequencies.filter(frequency => frequency.milli >= this.currentUser?.plan.minimum_frequency)
  }
  
  ngOnInit() {
    this.getCurrentUser();
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
      this.projectId = this.data.project;
      this.selectMonitorType(1);
      if(this.data.monitor && this.data.project){
        this.edit = true;
        this.monitorId = this.data.monitor.id;
        this.colour = this.data.monitor.colour.domain[0];
        this.selectedBandwidth = this.data.monitor.regions?.exclusions?.bandwidth,
        this.locations = this.data.monitor.regions,
        this.type = this.data.monitor.type as MonitorTypes;
        this.isSecure = this.data.monitor.configuration?.secure
        this.monitorType = this.verbs.findIndex(x => x.httpvalue === this.data.monitor.type)+1;
        this.frequency = this.frequencies.findIndex(x => x.interval === this.data.monitor.frequency)+1;
        this.selectedProxyFilter = this.data.monitor?.regions?.requirements?.proxy;
        if(this.data.monitor.configuration){
          if(this.data.monitor.configuration.statusCodes){
            this.code = this.statusCodes.findIndex(x => x.value === this.data.monitor.configuration.statusCodes[0]);
          }
          if(this.data.monitor.configuration.headers){
            this.headers = this.data.monitor.configuration.headers;
          }
          if(this.data.monitor.configuration.body){
            this.body = this.data.monitor.configuration.body;
          }
          if(this.data.monitor.configuration.regex){
            this.regex = this.data.monitor.configuration.regex;
          }
        }
      }
    });
    this.monitorForm = this.formBuilder.group({
      name: [this.data.monitor && this.data.monitor.name || '', Validators.required],
      type: ["http_verbs"],
      host: [this.data.monitor && this.data.monitor.endpoint, Validators.required],
      regex: [this.regex],
      headers: this.headers,
      body: [this.body],
      frequency: [this.frequency, Validators.required],
      statusCodes: this.statusCodes[this.code] });
    this.loaded = true;
  }


  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  addHeader(e) {
    e.preventDefault();
    this.headerError = false;
    this.headerSubmitted = true;
    if(!this.headerForm.key || !this.headerForm.value){
      this.headerError = true;
      return;
    }
    this.headers[this.headerForm.key] = this.headerForm.value;
    this.monitorForm.controls.headers.setValue(this.headers);
    this.headerForm.key = '';
    this.headerForm.value = '';
    this.headerSubmitted = false;
  }

  removeHeader(k){
    delete this.headers[k];
    this.monitorForm.controls.headers.setValue(this.headers);
    this.headerSubmitted = false;
  }

  toggleSecureJob() {
    this.isSecure = !this.isSecure
    console.log(this.isSecure)
  }

  async addMonitor() {
    this.submitted = true;
    if(this.monitorForm.invalid){
      return;
    }
    if(!this.loading){
      this.loading = true;
      const freq = this.frequencies[this.m.frequency.value-1];
      const type = this.verbs[this.monitorType-1];
      const statusCodes = [this.m.statusCodes.value];
      let body;
      try {
        body = JSON.parse(this.m.body.value);
        console.log(body);
      } catch(e) {
        body = this.m.body.value;
        console.log(e);
        console.log(body);
      }
      // console.log(freq);
      const monitor = {
        name: this.m.name.value,
        endpoint: this.m.host.value,
        colour: this.colour,
        type: type.httpvalue,
        regions:  { 
          requirements: {
            countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === false).map(x => x.code),
            continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === false).map(x => x.code),
            cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === false).map(x => x.code),
            operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === false).map(x => x.code),
            isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === false).map(x => x.code),
            proxy: this.selectedProxyFilter?.value || this.selectedProxyFilter
          },
          exclusions: {
            countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === true).map(x => x.code),
            continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === true).map(x => x.code),
            cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === true).map(x => x.code),
            operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === true).map(x => x.code),
            isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === true).map(x => x.code),
            bandwidth: {
              lessThan: this.selectedBandwidth?.lessThan,
              greaterThan: this.selectedBandwidth?.greaterThan
            }
          }
          },
        frequency: freq.interval,
        bandwidth: {
          lessThan: this.selectedBandwidth?.lessThan,
          greaterThan: this.selectedBandwidth?.greaterThan,
        },
        configuration:
        {
          statusCodes: [this.m.statusCodes.value.value],
          regex: this.m.regex.value,
          body: body,
          headers: this.m.headers.value,
          secure: this.isSecure || false
      }};
      try {
        if(this.edit) {
          const updateMonitor = {id: this.data.monitor.id, ...monitor}
          const data = await this.projectService.updateMonitor(this.projectId, updateMonitor)
        } else {
          const data = await this.projectService.addMonitor(this.projectId, monitor)
        }
        this.modal.close();
      } catch(e) {
        console.log(e);
        this.error = e;
        this.loading = false;
      }
    }
  }
}
