import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'colour-picker',
  templateUrl: './colour-picker.component.pug',
  styleUrls: ['./colour-picker.component.scss']
})
export class ColourPickerComponent {

  @Input() selectedColour = "#0984e3";
  @Output() selectedColourChange = new EventEmitter<string>();
  isOpen = false;
  colours = ["#0984e3", "#2ED573", "#FF4757", "#FF6348", "#6C5CE7", "#000000", "#B4B4B4"];

  setColour(c) {
    this.selectedColour = c;
    this.selectedColourChange.emit(this.selectedColour);
    this.isOpen = false;
  }
}
