import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  private subject = new Subject<any>();
  private keepAfterRouteChange = false;

  constructor(private router: Router,private http: HttpClient) {
      router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
              if (this.keepAfterRouteChange) {
                  this.keepAfterRouteChange = false;
              } else {
                  this.clear();
              }
          }
      });
  }

  getNotification(): Observable<any> {
      return this.subject.asObservable();
  }

  notification(type: string, message: string, keepAfterRouteChange = false) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next({ type: type, message: message });
  }

  clear() {
    this.subject.next(null);
  }
}
