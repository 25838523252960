import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'flag',
  templateUrl: './flag.component.pug',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Input() countryCode;
  @Input() rounded;
  constructor() { }

  ngOnInit(): void {
  }

}
