import { Component, OnInit, ElementRef, HostListener, ViewChild} from '@angular/core';
import {fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../_services/auth.service';
import { User } from '../../_models/user';
import { TranslateService } from '@ngx-translate/core';
import { SidebarService } from '../../_services/sidebar.service';
import { StorageService } from 'src/app/_services/storage.service';
import { ProjectService } from '../../_services/project.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.pug',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  showText = false;
  currentUser: User;
  navMenuOpen = false;
  notificationMenuOpen: boolean;
  sidebarIsOpen: boolean;
  attachOutsideOnClick: boolean;
  optionsSub;
  notifications;
  notifCount;
  isDark: boolean;
  notificationCount: any;
  preferences;
  rootUrl: string;
  
  constructor(
    private projects: ProjectService,
    public auth: AuthenticationService,
    private translate: TranslateService,
    public sidebar: SidebarService,
    public storage: StorageService
  ) { 
    
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
      this.preferences = {
        darkToggleState: options.darkToggleState,
        selectedLanguage: options.selectedLanguage,
        accountType: options.accountType
      };
    });

    if (this.preferences.accountType === 'node') {
      this.rootUrl = '/earnings';
    } else {
      this.rootUrl = '/';
    }
  }

  ngOnInit() {
    this.projects.notifications.subscribe(notifications => {
      this.notifications = notifications.notifications;
      if(this.notifications) {
      this.notificationCount = this.notifications.filter(n => n.isAcknowledged === false).length;
      }
    });
  }

}
