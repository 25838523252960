import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ClockService } from 'src/app/_services/clock.service';
import { trigger, state, style, animate, transition, sequence } from '@angular/animations';
import { ModalService } from 'src/app/_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'monitor-job-history',
  templateUrl: './monitor-job-history.component.pug',
  styleUrls: ['./monitor-job-history.component.scss'],
})

export class MonitorJobHistoryComponent implements OnInit, OnDestroy {
  @Input() monitor: any;
  @Input() jobs: any;
  @Input() failed: any;
  @Input() jobsLoading: Boolean;
  @Input() errorsLoading: Boolean;

  jobLoader = [1,2,3,4,5,6,7]
  clock: number;
  clockSub: any;
  selectedTab = 'recent';
  optionsSub: any;
  isDark: any;
  constructor(private storage: StorageService, private clockService: ClockService, public modal: ModalService) {
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
  }

  ngOnInit() {
    this.clockSub = this.clockService.clock.subscribe(t => {
      this.clock = t;
    })
  }
  ngOnDestroy() {
    this.clockSub.unsubscribe();
  }
}
