import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/auth.service';
import { StorageService } from '../_services/storage.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    optionsSub: any;
  isNode: any;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private storage: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        const r = route.queryParams.r || null;
        if(r){
          this.storage.updateOptions({r:r});
        }
        if (currentUser) {
          if(route.data['roles']){
            if(route.data['roles'].indexOf(currentUser.role)>-1) {
              return true
            } else {
              this.router.navigate(['/']);
              return false
            }
          } else {
            // logged in so return true
              return true;
          }
        }
        // not logged in so redirect to login page with the return url
        // console.log(state.url);
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
