import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project.service';
import { ModalService } from 'src/app/_services/modal.service';
import { UserService } from 'src/app/_services/user.service';
import { AuthenticationService } from 'src/app/_services/auth.service';

@Component({
  selector: 'delete-account',
  templateUrl: './delete-account.component.pug',
  styleUrls: ['./delete-account.component.scss']
})

export class DeleteAccountComponent implements OnInit, AfterViewInit, OnDestroy {

  data: {
    cb: Function
  };
  error;
  loading;

  private getData:Subscription;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
    private router: Router,
    public modal: ModalService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  async deleteAccount() {
    try {
      await this.userService.delete();
    } catch(e) {
      console.log(e);
    }
    this.data.cb();
    this.modal.close();
  }


}
