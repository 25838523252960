import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

export interface Filter {
    code: string;
    count: number;
    country?: string;
    city?: string;
    type: string;
    continent?: string;
}

export interface DumbFilter {
    code: string;
    type: string;
    count?: number
    excluded: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class FilterService {

    constructor(private http: HttpClient) {}

    getFilters(term: string = null): Observable<DumbFilter[]> {
        let items = this.http.get<Filter[]>(`https://api.bitping.com/v2/nodes/search/?q=${term}`);
        
        let whatever = items.pipe(map(x => {
            return [
                ...x.filter(f => f.type === 'countries').map(x => ({ type: x.type, code: x.code, count: x.count, excluded: false })),
                ...x.filter(f => f.type === 'continents').map(x => ({ type: x.type, code: x.code, count: x.count, excluded: false})),
                ...x.filter(f => f.type === 'cities').map(x => ({ type: x.type, code: x.code, count: x.count, excluded: false })),
                ...x.filter(f => f.type === 'operatingSystems').map(x => ({ type: x.type, code: x.code, count: x.count, excluded: false })),
                ...x.filter(f => f.type === 'isps').map(x => ({ type: x.type, code: x.code, count: x.count, excluded: false })),
            ] as DumbFilter[]
        }));

        return whatever;
    }
}
