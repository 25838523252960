import { Component, OnInit } from '@angular/core';
import { EarningsService } from 'src/app/_services/earnings.service';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.pug',
  styleUrls: ['./approvals.component.scss']
})
export class ApprovalsComponent implements OnInit {

  //Cashout limits
  miningFee = 120;
  minimumCashout = 2000;

  approvals:any = [];
  approved:any = [];
  tab = 0;
  constructor(private earningsService: EarningsService) { }

  ngOnInit() {
    this.getApprovals();
    this.getApproved();
  }

  getApproved = async() => {
    try {
      let approved:any = await this.earningsService.getAllPayouts(1);
      approved = approved.map(a => {
        if(a.handleType === '1handle') {
          a.payoutAddress = a.payoutAddress + "@relayx.io";
        } else if(a.handleType === '$handle') {
          let newAddress = a.payoutAddress.replace("$", "");
          a.payoutAddress = newAddress + "@handcash.io";
        }
        return a;
      });
      this.approved = approved;
    } catch(e) {
      console.log(e);
    }
  }

  getApprovals = async() => {
    try {
      let approvals:any = await this.earningsService.getAllPayouts();
      approvals = approvals.map(a => {
        if(a.handleType === '1handle') {
          a.payoutAddress = a.payoutAddress + "@relayx.io";
        } else if(a.handleType === '$handle') {
          let newAddress = a.payoutAddress.replace("$", "");
          a.payoutAddress = newAddress + "@handcash.io";
        }
        return a;
      });
      this.approvals = approvals;
    } catch(e) {
      console.log(e);
    }
  }

  approveTX(e){
    console.log(e);
    try {
      let approvedPayout = this.earningsService.approvePayout(e.payout, e.txid);
      console.log(approvedPayout);
      this.approvals = this.approvals.filter(a => a.id !== e.payout);
      setTimeout(() => {
        this.getApproved();
      }, 300);
    } catch(e) {
      console.log(e);
    }
  }

  printLoad(e){
    console.log(e);
  }

}
