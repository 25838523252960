import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgOptionTemplateDirective } from '@ng-select/ng-select';

@Component({
  selector: 'proxy-selector',
  templateUrl: './proxy-selector.component.pug',
  styleUrls: ['./proxy-selector.component.scss']
})
export class ProxySelectorComponent implements OnInit {
  @Input() selectedOption;
  @Output() selectedOptionChange = new EventEmitter<string>();
  
  options = [
    {label: "Allow Proxies", value: "allow"},
    {label: "Disallow Proxies", value: "disallow"},
    {label: "Only Proxies", value: "only"}
  ]

  dropdownOpen = {
    allow: false,
  };

  selectOption(o) {
    this.selectedOption = o;
    this.selectedOptionChange.emit(o)
  }

  ngOnInit() {
    if(this.selectedOption) {
      this.selectedOption = this.options.find(option => option.value === this.selectedOption);
    } else {
      this.selectedOption = this.options[0];
    }
  }

}
