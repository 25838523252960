import { Injectable, NgZone } from "@angular/core";
import { Subscriber } from "rxjs";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SSEObserverService {
  constructor(private zone: NgZone) {}

  getServerSentEvent(url: string) {
    return new Observable<any>((observer) => {
      this.registerEventSource(url, observer);
    });
  }

  private registerEventSource(url: string, observer: Subscriber<any>) {
    let eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      this.zone.run(() => {
        observer.next(event);
      });
    };

    eventSource.addEventListener(
      "error",
      (event) => {
        console.error("EventSource Error", event);
        switch ((event.target as any).readyState) {
          case EventSource.CONNECTING:
            console.log("Reconnecting...");
            break;

          case EventSource.CLOSED:
            console.log("Connection failed, will not reconnect automatically.");
            eventSource.close();

            console.log("Reregistering the EventSource in 5 seconds.")
            setTimeout(() => {
              this.registerEventSource(url, observer);
            }, 5000);
            break;
        }
      },
      false
    );
  }
}
