import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/_services/modal.service';
import { TeamSSOTypesEnum } from '@uptimesv/bitping-common';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'delete-teammember',
  templateUrl: './delete-team-member.component.pug',
  styleUrls: ['./delete-team-member.component.scss']
})

export class DeleteTeamMemberComponent implements OnInit, AfterViewInit, OnDestroy {
  private getData: Subscription;
  error;
  loading;

  sso_id: string;
  sso_type: TeamSSOTypesEnum;

  constructor(
    private router: Router,
    public modal: ModalService,
    private user: UserService,
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData = this.modal.getData().subscribe(data => {
      if(data && data.sso_id && data.sso_type){
        this.sso_id = data.sso_id;
        this.sso_type = data.sso_type;
      }
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  async deleteTeamMember() {
    await this.user.removeTeamMember(this.sso_id, this.sso_type);
    this.modal.close();
    this.router.navigate(['/teams']);
  }
}
