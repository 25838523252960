import { Component, Input, Output, EventEmitter, OnInit, } from '@angular/core';
import { SeleniumBrowsers } from '@uptimesv/bitping-common';

@Component({
  selector: 'browser-selector',
  templateUrl: './browser-selector.component.pug',
  styleUrls: ['./browser-selector.component.scss']
})
export class BrowserSelectorComponent implements OnInit {

  @Input() selectedBrowsers;
  @Output() selectedBrowsersChange = new EventEmitter<[string]>();
  browserList;
  selected;

  updateBrowsers() {
    this.selectedBrowsers = this.selected;
    this.selectedBrowsersChange.emit(this.selectedBrowsers);
  }

  ngOnInit() {
    this.populateBrowsers(this.selectedBrowsers);
  }

  populateBrowsers(l) {
    if(l){
      const l2 = this.browserList
      .filter(l3 => { return l.map(b => { return b.browserName }).indexOf(l3.value.browserName)>-1 })
      .filter(l3 => { return l.map(b => { return b.browserVersion }).indexOf(l3.value.browserVersion)>-1 });
      console.log(l2);
      this.selected = l2;
      this.updateBrowsers();
    }
  }

  pretty = {
    "safari": {
      name: "Safari",
      icon: "safari"
    },
    "MicrosoftEdge": {
      name: "Edge",
      icon: "microsoft-edge"
    },
    "firefox": {
      name: "Firefox",
      icon: "firefox"
    },
    "opera": {
      name: "Opera",
      icon: "opera"
    },
    "chrome": {
      name: "Chrome",
      icon: "chrome"
    },
    "chrome-mobile": {
      name: 'Chrome Mobile',
      icon: "chrome"
    },
     "android": {
     name: "Android",
      icon: "android"
    }
  }


  constructor() {
    this.browserList = [].concat(...Object.keys(SeleniumBrowsers).map(o => {
      return SeleniumBrowsers[o].versions.map(v => {
        return { value: { browserName: o, browserVersion: v }, display: this.pretty[o].name + " " + v, icon: this.pretty[o].icon };
      });
    }));
  }


}
