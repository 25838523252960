import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';
import { ModalService } from '../../../_services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'add-project',
  templateUrl: './add-project.component.pug',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {

  loading = false;
  projectForm: UntypedFormGroup;
  error;
  items;
  submitted;

  constructor(
    private projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public modal: ModalService
  ) {

  }

  ngOnInit() {
    this.projectForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  get p() { return this.projectForm.controls; }

  createProject = async() => {
    let n = await this.projectService.create(this.p.name.value);
    console.log(n);
    this.modal.close();
    this.router.navigate(['/project/' + n['id']]);
  }
}
