import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalService } from '../../../_services/modal.service';
import { UserService } from 'src/app/_services/user.service';
import { PlanInfoService } from 'src/app/_services/planInfo.service';

@Component({
  selector: 'app-addmonitor',
  templateUrl: './add-monitor.component.pug',
  styleUrls: ['./add-monitor.component.scss']
})
export class AddMonitorComponent implements OnInit, OnDestroy{
  data:any;
  private getData: Subscription;
  currentUser;
  plans;

  constructor(
    public modal: ModalService,
    private user: UserService,
    private planInfo: PlanInfoService,
  ) {

  }

  ngOnInit() {
    this.planInfo.getUserInfo().subscribe((userInfo) => {
      this.currentUser = userInfo;
    });
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

}
