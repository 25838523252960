import { Component, OnInit, AfterViewInit, OnDestroy, OnChanges, Input, SimpleChanges } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { ReactiveFormsModule, UntypedFormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ProjectService } from '../../../_services/project.service';
import { ModalService } from '../../../_services/modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { BaseMonitorComponent } from '../base-monitor.component';
import { MonitorTypes } from '@uptimesv/bitping-common';
import { UserService } from 'src/app/_services/user.service';
import { PlanInfoService } from 'src/app/_services/planInfo.service';


@Component({
  selector: 'standard-monitor',
  templateUrl: './standard-monitor.component.pug',
  styleUrls: ['./standard-monitor.component.scss']
})
export class StandardMonitorComponent extends BaseMonitorComponent implements OnInit, OnDestroy{
  nodeCount: any;
  criteria;
  dropdownOpen = {
    time: false,
  };
  requirements;
  locations: any;
  criteriaCount: any;
  selectedLocations: any[];
  selectedProxyFilter;
  regions: any;
  selectedBandwidth: any;
  error = ""
  isHls
  returnStream = false;
  currentUser;

  constructor(
    public projectService: ProjectService,
    private formBuilder: UntypedFormBuilder,
    public modal: ModalService,
    private user: UserService,
    private planInfo: PlanInfoService,
  ) {
    super(projectService, modal)
  }

  getNodeCount($event) {
    this.nodeCount = $event;
  }

  async getCurrentUser() {
    this.planInfo.getUserInfo().subscribe((userInfo) => {
      this.currentUser = userInfo;
    });
    this.frequencies = this.frequencies.filter(frequency => frequency.milli >= this.currentUser?.plan.minimum_frequency)
  }
  

  ngOnInit() {
    this.getCurrentUser();
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
      this.projectId = this.data.project;
      this.type = this.data.type as MonitorTypes;
      if(this.data.monitor && this.data.project){
        this.edit = true;
        this.monitorId = this.data.monitor.id;
        this.colour = this.data.monitor.colour.domain[0];
        this.selectedBandwidth = this.data.monitor.regions?.exclusions?.bandwidth,
        this.locations = this.data.monitor?.regions,
        this.frequency = this.frequencies.findIndex(x => x.interval === this.data.monitor.frequency)+1;
        this.selectedProxyFilter = this.data.monitor?.regions?.requirements?.proxy   
      }
      if(this.type === 'hls') {
          return this.returnStream = true
      }
      //If there's some data, fill in the monitor. If not, gg;
      // this.priceEstimate(this.data.id).then( res => this.cost = res);
      // console.log(this.cost)
    });
    this.monitorForm = this.formBuilder.group({
      name: [this.data.monitor && this.data.monitor.name || '', Validators.required],
      type: [this.type, Validators.required],
      host: [this.data.monitor && this.data.monitor.endpoint || '', Validators.required],
      frequency: [this.frequency, Validators.required],
    });
    this.loaded = true;
  }
  get f() { return this.monitorForm.controls; }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  async addMonitor() {
    this.submitted = true;
    if(this.monitorForm.invalid){
      return;
    }
    if(!this.loading){
      this.loading = true
      const freq = this.frequencies[this.m.frequency.value-1];
      const monitor = {
        name: this.m.name.value,
        endpoint: this.m.host.value,
        colour: this.colour,
        type: this.m.type.value,
        regions:  { 
          requirements: {
            countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === false).map(x => x.code),
            continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === false).map(x => x.code),
            cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === false).map(x => x.code),
            operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === false).map(x => x.code),
            isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === false).map(x => x.code),
            proxy: this.selectedProxyFilter?.value || this.selectedProxyFilter
          },
          exclusions: {
            countries: this.locations?.filter(f => f.type === 'countries' && f.excluded === true).map(x => x.code),
            continents: this.locations?.filter(f => f.type === 'continents' && f.excluded === true).map(x => x.code),
            cities:  this.locations?.filter(f => f.type === 'cities' && f.excluded === true).map(x => x.code),
            operatingSystems:  this.locations?.filter(f => f.type === 'operatingSystems' && f.excluded === true).map(x => x.code),
            isps:  this.locations?.filter(f => f.type === 'isps' && f.excluded === true).map(x => x.code),
            bandwidth: {
              lessThan: this.selectedBandwidth?.lessThan,
              greaterThan: this.selectedBandwidth?.greaterThan
            }
          }
          },
        frequency: freq.interval,
        bandwidth: {
          lessThan: this.selectedBandwidth?.lessThan,
          greaterThan: this.selectedBandwidth?.greaterThan,
        },
        configuration: {
          returnStream: this.returnStream || false
        },
      };
      try {
        if(this.edit){
          const updateMonitor = {
            id: this.monitorId,
            ...monitor
          }
          const data = await this.projectService.updateMonitor(this.projectId, updateMonitor);
        } else {
          const data = await this.projectService.addMonitor(this.projectId, monitor)
        }
        this.modal.close();
      } catch(error) {
        console.log("monitor error",error)
        this.error = error["error"]["message"];
        this.loading = false;
      }
    }
  }
}
