import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectService } from 'src/app/_services/project.service';
import { ModalService } from 'src/app/_services/modal.service';

@Component({
  selector: 'delete-monitor',
  templateUrl: './delete-monitor.component.pug',
  styleUrls: ['./delete-monitor.component.scss']
})

export class DeleteMonitorComponent implements OnInit, AfterViewInit, OnDestroy {

  data;
  error;
  loading;

  private getData:Subscription;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private projectService: ProjectService,
    private router: Router,
    public modal: ModalService
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getData = this.modal.getData().subscribe(data => {
      this.data = data;
      // console.log(data);
    });
  }

  ngOnDestroy(){
    this.getData.unsubscribe();
  }

  async deleteMonitor() {
    await this.projectService.removeMonitor(this.data.project, this.data.monitor);
    this.modal.close();
    if (this.router.url.indexOf('monitor') !== -1) {
      this.location.back();
    }
  }


}
