import { Component, OnInit, OnDestroy} from "@angular/core";
import { AdminService } from "src/app/_services/admin.service";
import { JobPrice } from "./models/JobPrices";
import { SatoshiPrice } from "./models/SatoshiPrice";

@Component({
  selector: "app-diagnostics",
  templateUrl: "./diagnostics.component.pug",
  styleUrls: ["./diagnostics.component.scss"]
})
export class DiagnosticsComponent implements OnInit, OnDestroy {
  jobPrices: Array<JobPrice>;
  jobPricesInterval: number;
  isJobPricesLoading = true;

  satoshiPrice: SatoshiPrice;
  satoshiPriceInterval: number;

  financialInterval: number;
  financials;
  financialsLoading = true;

  constructor(public admin: AdminService) {}

  ngOnInit() {
    this.getFinancials();
    this.getJobPrices();
    this.getSatoshiPrices();
    this.financialInterval = window.setInterval(() => {
      this.getFinancials();
    }, 5000);
    this.jobPricesInterval = window.setInterval(() => {
      this.getJobPrices();
    }, 5000);
    this.satoshiPriceInterval = window.setInterval(() => {
      this.getSatoshiPrices();
    }, 5000);
  }
  ngOnDestroy() {
    clearInterval(this.financialInterval);
    clearInterval(this.jobPricesInterval);
    clearInterval(this.satoshiPriceInterval);
  }

  async getFinancials() {
    this.financials = await this.admin.getFinancials();
    this.financialsLoading = false;
  }

  private async getJobPrices() {
    this.jobPrices = (await this.admin.getJobPrices()).data;
    this.isJobPricesLoading = false;
  }

  private async getSatoshiPrices() {
    this.satoshiPrice = await this.admin.getSatoshiPrice();
  }
}
