import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../_services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.pug',
  styleUrls: ['./reset.component.scss']
})

export class ResetComponent implements OnInit {
  resetForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  message = '';
  error = '';
  sentEmail = '';
  private sub: any;
  resetId = '';
  selectedLanguage = 'en';
  optionsSub;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.resetId = params.id;
    });
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  get r() { return this.resetForm.controls; }

  async reset() {
    this.submitted = true;
    if (this.resetForm.invalid) {
        return;
    }
    this.error = '';
    this.message = '';

    this.loading = true;
    try {
      let user = await this.authenticationService.reset(this.resetId, this.r.email.value, this.r.password.value, this.r.confirm.value);
      this.authenticationService.updateUser(user);
      this.router.navigate(['/']);
    } catch(error) {
      this.error = error;
    }
    this.loading = false;
  }
}
