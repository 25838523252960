import { Component, OnInit, OnDestroy } from '@angular/core';
import { NodesService } from '../../_services/nodes.service';
import { ModalService } from '../../_services/modal.service';
import { ProjectService } from '../../_services/project.service';
import { AuthenticationService } from '../../_services/auth.service';
import { UserService } from '../../_services/user.service';
import { ClockService } from '../../_services/clock.service';
import { SSEObserverService } from '../../_services/sseobserver.service';
import { StorageService } from 'src/app/_services/storage.service';
import { Observable, Subscription } from 'rxjs';
import { TeamMember, TeamSSOTypesEnum } from '@uptimesv/bitping-common';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.pug',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[];
  now;
  currentUser;
  loaded = false;
  notiLoader = [1,2,3,4,5,6,7,8,9,10]
  members: TeamMember[];
  isDark: any;
  modalListener: Subscription;
  
  constructor(public nodesservice:NodesService, private storage: StorageService, public modal: ModalService, private projects: ProjectService, public auth: AuthenticationService, private user: UserService, private clock: ClockService, private sseObserver: SSEObserverService) {
    this.subscriptions = [
      this.auth.currentUser.subscribe(x => this.currentUser = x),
      this.clock.clock.subscribe(n => {
        this.now = n;
      }),
      this.storage.options.subscribe(options => {
        this.isDark = options.darkToggleState;
      })
    ];
  }

  loadTeam = async() => {
    try {
      this.members = await this.user.teams();
    } catch(e) {
      console.log(e); 
    }
    this.loaded = true;
  }

  addTeamMember = async() => {
    this.modal.next("addteammember");
    this.modalListener = this.modal.subject.subscribe(x => {
      console.log(x);
      if(x !== "addteammember" && !this.modalListener.closed) {
        this.modalListener.unsubscribe();
        this.loadTeam();
      }
    })
  }

  removeTeamMember = async(sso_id: string, sso_type: TeamSSOTypesEnum) => {
    this.modal.next("deleteteammember", { sso_id, sso_type });
    this.modalListener = this.modal.subject.subscribe(x => {
      console.log(x);
      if(x !== "deleteteammember" && !this.modalListener.closed) {
        this.modalListener.unsubscribe();
        this.loadTeam();
      }
    })
  }

  ngOnInit(){
    this.loadTeam()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions = [];
  }

}
