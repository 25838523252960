import { Component, OnInit, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from './_services/auth.service';
import { User } from './_models/user';
import { SidebarService } from './_services/sidebar.service';
import { RouterOutlet, Router, NavigationStart } from '@angular/router';
import { routeTransitionAnimations } from './route-transition-animations';
import { StorageService } from './_services/storage.service';
import * as moment from 'moment';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from './_services/modal.service';
import { UserService } from './_services/user.service';
import { HttpClient } from '@angular/common/http';
import { PlanInfoService } from './_services/planInfo.service';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations],
  encapsulation : ViewEncapsulation.None
})

export class AppComponent implements AfterViewInit, OnInit{
  title = 'bitping-dashboard';
  currentUser: User;
  sidebarIsOpen: boolean;
  optionsSub;
  chatInitialised = false;
  show = true;
  isDarkMode = true;
  darkToggleState;
  preferences;
  selectedLanguage: any;
  selectedAccountType: any;
  me;
  pref;
  modalDismissed: any;

  close() {
    this.show = false;
  };


  prepareRoute(outlet: RouterOutlet) {
    return outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState'];
  }

  constructor(
    private auth: AuthenticationService,
    private http: HttpClient,
    public sidebar: SidebarService,
    public storage: StorageService,
    public user: UserService,
    public modal: ModalService,
    private translate: TranslateService,
    private router: Router,
    private planInfo: PlanInfoService,
  ) {

    this.selectedLanguage = this.storage.optionsValue.selectedLanguage;
    this.selectedAccountType = this.storage.optionsValue.accountType;
    this.modalDismissed = this.storage.optionsValue.modalDismissed;
    this.optionsSub = this.storage.options.subscribe(options => {
      translate.setDefaultLang('en');
      translate.use(this.selectedLanguage);
      this.darkToggleState = options.darkToggleState;
      if (options.preferences !== undefined) {
        this.preferences = options.preferences;
      } else {
        options.preferences = true;
      }
    })
    moment.relativeTimeThreshold('s', 60);
    moment.relativeTimeThreshold('m', 60);
    moment.relativeTimeThreshold('h', 24);

    this.sidebar.isOpen.subscribe(x => this.sidebarIsOpen = x);
    this.auth.currentUser.subscribe(async x => {
      this.currentUser = x;
      if(this.currentUser) {
        const me: any = await this.user.me();
        this.pref = me.preferences;
      }
      // If we are logged in and have not dismissed the modal, pop it open
      if ((!this.storage.optionsValue.modalDismissed && this.currentUser && this.pref && this.pref.modalComplete === false)) {
        this.modal.open('preferencewizard');
      }
    });
  }


  ngOnInit() {
    this.planInfo.getUserInfo().subscribe();
  }

  isSettingsRoute() {
    return this.router.url === '/settings';
  }

  isAdminRoute() {
    return this.router.url === '/admin';
  }

  ngAfterViewInit() {
    if(this.router.url === '/' && this.selectedAccountType === 'node') {
      this.router.navigate(['/earnings']);
    }
  }

  /**
   * shouldShowSidebar
   */
  public shouldShowSidebar() {
    if (!this.currentUser) {
      return false;
    }

    if (environment.isUnderMaintenance) {
      return false;
    }

    return true;
  }

  /**
   * shouldShowNavbar
   */
  public shouldShowNavbar() {
    if (!this.currentUser) {
      return false;
    }

    if (environment.isUnderMaintenance) {
      return false;
    }

    return true;
  }


  public underMaintenance() {

    if (environment.isUnderMaintenance) {
      return true;
    }

    return false;
  }
}





