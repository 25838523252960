import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ProjectService } from 'src/app/_services/project.service';
import { ClockService } from 'src/app/_services/clock.service';
import { ModalService } from 'src/app/_services/modal.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'monitor-job-history-hls',
  templateUrl: './monitor-job-history-hls.component.pug',
  styleUrls: ['./monitor-job-history-hls.component.scss']
})
export class MonitorJobHistoryHlsComponent implements OnInit, OnDestroy {
  clock: number;
  clockSub: any;
  hlsData: boolean = false;
  job;
  selectedTab = 'recent';

  @Input() monitor: any;
  @Input() jobs: any;
  @Input() failed: any;
  @Input() projectId: any;
  @Input() jobsLoading: Boolean;
  @Input() errorsLoading: Boolean;

  jobLoader = [1,2,3,4,5,6,7]
  isDark: any;
  optionsSub: any;

  constructor(private storage: StorageService, private projectService: ProjectService, private clockService: ClockService, public modal: ModalService) { 
    this.optionsSub = this.storage.options.subscribe(options => {
      this.isDark = options.darkToggleState;
    });
  }


  toggle(job) {


    job.hlsData = !job.hlsData;


  }

  ngOnInit() {
    this.clockSub = this.clockService.clock.subscribe(t => {
      this.clock = t;
    })
  }

  ngOnDestroy() {
    this.clockSub.unsubscribe();
  }

}
