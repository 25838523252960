import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../_services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.pug',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = "";
  mfa = false;
  minPasswLength = 8;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required, Validators.minLength(this.minPasswLength)]]
    });
    let referrer = this.route.snapshot.queryParams['referrer'] || '';
    if(referrer){
      this.returnUrl = '/node/loggedin';
    } else {
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
  }

  get f() { return this.loginForm.controls; }

  onReset() {
      this.submitted = false;
      this.loginForm.reset();
  }

  async login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    try {
      let user = await this.authenticationService.login(this.f.email.value, this.f.password.value);
      this.authenticationService.updateUser(user);
      this.router.navigate([this.returnUrl]);
    } catch(error) {
      if(error["error"]["message"] === 'NO_MFA_CODE_PROVIDED') {
        this.error = "";
        this.mfa = true;
      } else {
        this.error = error["error"]["message"];
      }
      this.loading = false;
    }
  }

  mfaError = (error: string) => {
    this.error = error;
    this.loading = false;
  }

  login_mfa = async (otp: string) => {
    if(!this.loading) {
      this.loading = true;
      try {
        let user = await this.authenticationService.login_mfa(this.f.email.value, this.f.password.value, otp);
        this.authenticationService.updateUser(user);
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      } catch(error) {
        throw new Error(error['error']['message'] || "SOMETHING_WENT_WRONG")
      }
    }
  }
}
