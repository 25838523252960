import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const error = err?.error?.message || err?.error?.error || err?.statusText;
            // if (err.status === 401) {
            //     // auto logout if 401 response returned from api
            //     // if(this.router.url.startsWith('/login')){
            //     //     return throwError(error);

            //     // //   return;
            //     // }
            //     this.authenticationService.logout();
            //     location.reload();
            //     // return;
            // }
            return throwError(err)
        }))
    }
}
